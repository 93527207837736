import {
  DEFAULT_DEVICE_HEARTBEAT_FREQUENCY,
  DEFAULT_DEVICE_LOOPTIME,
  DEFAULT_VARIABLE_DATA_DELAY,
  DEFAULT_VARIABLE_FREQUENCY,
  EthernetIPDeviceConfig,
} from '@dametis/core';

export const createEthernetIPDeviceConfig = ({
  host = '',
  port = 44818,
  slot = 0,
  loopTime = DEFAULT_DEVICE_LOOPTIME,
  deviceHeartbeatFrequency = DEFAULT_DEVICE_HEARTBEAT_FREQUENCY,
  defaultVariableFrequency = DEFAULT_VARIABLE_FREQUENCY,
  defaultVariableDataDelay = DEFAULT_VARIABLE_DATA_DELAY,
}: Partial<EthernetIPDeviceConfig> = {}): EthernetIPDeviceConfig => ({
  host,
  port,
  slot,
  loopTime,
  deviceHeartbeatFrequency,
  defaultVariableFrequency,
  defaultVariableDataDelay,
});
