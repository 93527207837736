export default {
  input: {
    label: {
      groupBy: 'Sub-periods',
    },
    value: {
      auto: 'Auto',
      pointToPoint: 'Point to point',
      time: 'Time',
      batch: 'Batch',
    },
    tooltip: {
      canAccessBatch: "You don't have access to batch",
    },
  },
  none: 'None',
  auto: 'Auto',
  pointToPoint: 'Point to point',
  pointToPoint_short: 'PTP',
  batch: 'Batch',
};
