import { ActivityType, ActivityVerb } from '@dametis/core';

const verbs: Record<ActivityVerb, string> = {
  [ActivityVerb.CREATE]: 'created',
  [ActivityVerb.RESTORE]: 'restored',
  [ActivityVerb.DUPLICATE]: 'duplicated',
  [ActivityVerb.TRANSFER]: 'transferred',
  [ActivityVerb.UPDATE]: 'updated',
  [ActivityVerb.DELETE]: 'deleted',
  [ActivityVerb.UPLOAD]: 'uploaded',
  [ActivityVerb.IMPORT]: 'imported',
  [ActivityVerb.SIGNIN_PASSWORD]: 'signed in with password',
  [ActivityVerb.SIGNIN_INVITATION]: 'signed in with invitation',
  [ActivityVerb.SIGNIN_SSO]: 'signed in with sso',
  [ActivityVerb.LOGOUT]: 'signed out',
  [ActivityVerb.CREATE_INVITATION]: 'invited',
  [ActivityVerb.FINALIZE_INVITATION]: 'finalized invitation',
  [ActivityVerb.LOST_PASSWORD]: 'lost password',
  [ActivityVerb.RESET_PASSWORD]: 'reset password',
  [ActivityVerb.ANONYMOUSLY_DUPLICATE]: 'has been duplicated anonymously',
  [ActivityVerb.SIGNIN_WEBAUTHN]: 'signed in with WebAuthn',
  [ActivityVerb.CALCULUS_ARMAGEDDON]: 'triggered a Calculus Armageddon',
  [ActivityVerb.SCORE_ARMAGEDDON]: 'triggered a Scores Armageddon',
  [ActivityVerb.DAMCO_ARMAGEDDON]: 'triggered a Damco Armageddon',
  [ActivityVerb.IMPERSONATE]: 'impersonated a user',
  [ActivityVerb.MOVE]: 'move',
  [ActivityVerb.MERGE]: 'merge',
};

const types: Record<ActivityType, string> = {
  [ActivityType.GROUP]: 'the group',
  [ActivityType.SITE]: 'the site',
  [ActivityType.BOX]: 'the box',
  [ActivityType.DEVICE]: 'the equipment',
  [ActivityType.PLAYGROUND]: 'the playground',
  [ActivityType.REPORT]: 'the report',
  [ActivityType.SYNOPTIC]: 'the synoptic',
  [ActivityType.VARIABLE]: 'the variable',
  [ActivityType.USER]: 'the user',
  [ActivityType.ALARM]: 'the alarm',
  [ActivityType.PROJECT]: 'the project',
  [ActivityType.ASSET]: 'a document',
  [ActivityType.TASK]: 'a task',
  [ActivityType.BATCH]: 'a batch',
  [ActivityType.MODEL]: 'a model',
  [ActivityType.COMMENT]: 'a comment',
  [ActivityType.BLOCK]: 'a block',
  [ActivityType.BLOCK_TYPE]: 'a type of block',
  [ActivityType.STYLE_CONFIGURATION]: 'a style configuration',
  [ActivityType.ALIAS]: 'an alias',
  [ActivityType.IMAGE]: 'an image',
  [ActivityType.TAG]: 'a tag',
  [ActivityType.OPCO]: 'a steam mix',
  [ActivityType.SYNOPTIC_WIDGET]: 'a synoptic widget',
  [ActivityType.FILEIMPORT]: 'a file import',
  [ActivityType.STANDARD_BLOCK]: 'a standard block',
  [ActivityType.CALENDAR]: 'a calendar',
  [ActivityType.INVITATION]: 'an invitation',
  [ActivityType.VARIABLE_DATA]: 'variable data',
  [ActivityType.CUSTOMJOB]: 'a custom job',
};

export default {
  title: {
    activityDetails: 'Activity details',
  },
  type: verbs,
  object: types,
  text: {
    activities: 'Activities',
    label: '{{firstName}} {{lastName}} $t(type.{{type}}) $t(object.{{object}})',
    noActivities: 'No activity recorded',
    displayOnlyChangedFields: 'Display only changed fields.',
    variable: {
      noUpdatedVariableFields: 'No modification',
      updatedVariableFields_zero: ' and $t(configuration:input.{{field}})',
      updatedVariableFields_one: ', $t(configuration:input.{{field}}) and an other field',
      updatedVariableFields_other: ', $t(configuration:input.{{field}}) and {{count}} other fields',
    },
  },
  button: {
    close: 'Close',
    restore: 'Restore',
    order: {
      newest: 'Newest first',
      oldest: 'Oldest first',
    },
  },
  toast: {
    restoreVariableSuccess: 'Variable restored.',
  },
};
