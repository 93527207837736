import { ProjectTemplateCategoryType, ProjectTemplateFieldType, RatingType, SelectType } from '@dametis/core';

export default {
  title: {
    template: 'Mes modèles de projets',
    editForm: 'Modifier le modèle',
    newTemplate: 'Nouveau modèle',
    duplicateTemplate: 'Dupliquer le modèle',
    createTemplateModal: 'Nouveau modèle',
    editTemplateModal: 'Modifier le modèle',
  },
  button: {
    newTemplate: 'Modèle',
    create: 'Créer',
    save: 'Enregistrer',
    share: 'Partager',
    edit: 'Modifier',
    duplicate: 'Dupliquer',
    cancel: 'Annuler',
    continue: 'Continuer',
    delete: 'Supprimer',
    add: 'Ajouter',
    close: 'Fermer',
    next: 'Suivant',
    previous: 'Précédent',
    done: 'Valider',
    goToProjects: 'Voir les projets associés',
    addThreshold: 'Seuil',
    addCriteria: 'Critère',
    addThresholdRange: 'Ajouter une plage de seuil.',
  },
  label: {
    name: 'Nom',
    description: 'Description',
    fields: 'Champs',
    categories: 'Sections',
    fieldType: {
      [ProjectTemplateFieldType.STRING]: 'Texte simple',
      [ProjectTemplateFieldType.TEXTAREA]: 'Zone de texte',
      [ProjectTemplateFieldType.NUMBER]: 'Nombre',
      [ProjectTemplateFieldType.DATE]: 'Date',
      [ProjectTemplateFieldType.SELECT]: "Sélecteur d'options",
      // [ProjectTemplateFieldType.PICTOPICKER]: 'Sélecteur de pictogramme',
    },
    inputSpecs: {
      min: 'Minimum',
      max: 'Maximum',
      from: 'Date min',
      to: 'Date max',
    },
    ratingOptions: 'Options',
    ratingType: {
      [`variant_${RatingType.OPEN_GRADE}`]: 'Notation libre',
      [`variant_${RatingType.WEIGHTED_CRITERION}`]: 'Critère pondéré',
    },
    score: 'Score',
    formula: 'Formule de calcul du score',
    maxScore: 'Note globale =',
    selectType: {
      [`variant_${SelectType.DROPDOWN}`]: 'Liste déroulante',
      [`variant_${SelectType.FLAT}`]: 'Liste exhaustive',
    },
    multipleChoice: 'Autoriser la sélection multiple',
    relatedProjects: 'Projets associés ({{count}})',
    thresholds: {
      min: 'De',
      max: 'À',
      thresholdValue: 'Label du seuil',
    },
  },
  placeholder: {
    name: 'Nom',
    number: 'Nombre',
    options: 'Options',
    option: 'Option',
    text: 'Texte',
    statusStep: 'Étape ',
    taskStatus: 'Statut ',
    rating: {
      name: 'Nom',
      min: 'Min',
      max: 'Max',
      importance: 'Poids',
      options: 'Exemple: Faible, Moyen, Fort',
    },
  },
  text: {
    addFirstTemplate: 'Ajouter un nouveau modèle',
    preview: 'Aperçu',
    options: 'Options',
    option: 'Option',
    value: 'valeur',
    settingsDone: 'Configuration finalisée',
    globalGrade: 'Note globale = ',
    thresholds: 'Seuils',
    criteria: 'Critères',
    noCriteria: 'Aucun critère',
    noThreshold: 'Aucun seuil',
    noEditIfProjects: 'Vous ne pouvez pas modifier le modèle car il y a des projets associés.',
    noDeleteIfProjects: 'Vous ne pouvez pas supprimer le modèle car il y a des projets associés.',
    overlap: 'Les seuils se croisent, ce seuil ne sera pas pris en compte.',
  },
  stepper: {
    layout: 'Sections',
    settings: 'Configuration',
  },
  helperText: {
    [`variant_${ProjectTemplateCategoryType.TASKS}`]: "Définissez les statuts disponibles pour suivre l'avancement des tâches d'un projet.",
    [`variant_${ProjectTemplateCategoryType.STATUS}`]: 'Définissez les étapes clés de vos projets.',
    [`variant_${ProjectTemplateCategoryType.RATING}`]: 'Définissez des critères de notation pour vos projets.',
    [`variant_${RatingType.WEIGHTED_CRITERION}`]:
      'Définissez les options, de la plus faible à la plus impactante, séparées par des virgules.',
  },
  tooltip: {
    datePick: 'Sélectionner une date',
    clear: 'Effacer',
    deleteCustomField: 'Supprimer le champ',
    deleteOption: "Supprimer l'option",
    deleteCriteria: 'Supprimer le critère',
    deleteThreshold: 'Supprimer le seuil',
    deleteStatusStep: "Supprimer l'étape",
    statusLimit: 'Le nombre de statuts est limité à 6.',
    taskStatusLimit: 'Le nombre de statuts de tâches est limité à 4.',
  },
  toast: {
    duplicateSuccess: 'Modèle dupliqué avec succès.',
    createSuccess: 'Modèle créé avec succès.',
    editSuccess: 'Modèle modifié avec succès.',
    deleteSuccess: 'Modèle supprimé avec succès.',
  },
};
