import { List, ListItem, ListSubheader, Typography } from '@mui/material';
import { FC, MutableRefObject, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockMetric, BlockParameter, BlockVariable, MetricCategory, ShortBlockInfo } from '@dametis/core';

import { useBlocks } from 'store/api/blocks';
import { useVncStore } from 'zustand/stores/vnc';

import { PropsContext } from '../../../context';
import { useInsertVariable } from '../../../hooks';

import BlockElementParam from './BlockElementParam';
import BlockElementVariable from './BlockElementVariable';
import { BlockListMetric } from './BlockListMetric';

const blocksEmptyArray: ShortBlockInfo[] = [];

export interface BlockElementsListProps {
  containerRef?: MutableRefObject<HTMLDivElement | null>;
}

const BlockElementsList: FC<BlockElementsListProps> = ({ containerRef = undefined }) => {
  const { t } = useTranslation('vnc');

  const selectedBlockUuid = useVncStore(state => state.lego.selectedBlockUuid);
  const insertVariable = useInsertVariable();

  const { data: blocks = blocksEmptyArray } = useBlocks();

  const { defaultVariableOperator, disableMaths, calculatedVariableMode } = useContext(PropsContext);

  const selectedBlock = useMemo(() => blocks.find(block => block.uuid === selectedBlockUuid), [blocks, selectedBlockUuid]);

  const filteredParameters = useMemo(
    () => (selectedBlock?.parameters ?? []).filter(parameter => parameter.isSelected),
    [selectedBlock?.parameters],
  );

  const metrics = useMemo(() => (selectedBlock?.metrics ?? []).filter(metric => metric.isSelected), [selectedBlock]);
  const calculationMetrics = useMemo(() => metrics.filter(metric => metric.category === MetricCategory.METRIC), [metrics]);
  const discrepancyMetrics = useMemo(() => metrics.filter(metric => metric.category === MetricCategory.DISCREPANCY), [metrics]);
  const technicalMetrics = useMemo(() => metrics.filter(metric => metric.category === MetricCategory.TECHNICAL), [metrics]);

  const handleSelectParameter = useCallback(
    (parameter: BlockParameter) => () => {
      if (!selectedBlock?.uuid) return;
      const variable: BlockVariable = {
        blockId: selectedBlock.uuid,
        blockKey: parameter.uuid,
      };
      insertVariable(variable, disableMaths, defaultVariableOperator ?? undefined, calculatedVariableMode);
      if (containerRef?.current) {
        containerRef.current.scrollLeft = containerRef.current.scrollWidth;
      }
    },
    [selectedBlock?.uuid, defaultVariableOperator, insertVariable, disableMaths, calculatedVariableMode, containerRef],
  );

  const handleSelectMetric = useCallback(
    (metric: BlockMetric) => {
      if (!selectedBlock?.uuid) return;
      const variable: BlockVariable = {
        blockId: selectedBlock.uuid,
        blockKey: metric.uuid,
      };
      insertVariable(variable, disableMaths, defaultVariableOperator ?? undefined, calculatedVariableMode);
      if (containerRef?.current) {
        containerRef.current.scrollLeft = containerRef.current.scrollWidth;
      }
    },
    [selectedBlock?.uuid, defaultVariableOperator, insertVariable, disableMaths, calculatedVariableMode, containerRef],
  );

  const handleSelectVariable = useCallback(
    (variableId: string) => () => {
      insertVariable({ variableUuid: variableId }, disableMaths, defaultVariableOperator ?? undefined, calculatedVariableMode);
      if (containerRef?.current) {
        containerRef.current.scrollLeft = containerRef.current.scrollWidth;
      }
    },
    [calculatedVariableMode, containerRef, defaultVariableOperator, disableMaths, insertVariable],
  );

  if (!selectedBlock) {
    return null;
  }
  return (
    <List subheader={<li />} sx={{ position: 'relative', maxHeight: '100%', overflow: 'auto', '& ul': { p: 0 } }}>
      <li>
        <ul>
          <ListSubheader disableGutters>{t('subtitle.parameters')}</ListSubheader>
          {filteredParameters.map(parameter => (
            <BlockElementParam
              key={parameter.uuid}
              blockId={selectedBlock.uuid}
              disabled={!parameter.calculation}
              element={parameter}
              onClick={handleSelectParameter(parameter)}
            />
          ))}
          {filteredParameters.length === 0 && (
            <ListItem>
              <Typography variant="subtitle2">{t('text.noParameter')}</Typography>
            </ListItem>
          )}
        </ul>
      </li>
      <BlockListMetric blockId={selectedBlock.uuid} metrics={calculationMetrics} onSelect={handleSelectMetric} />
      <BlockListMetric blockId={selectedBlock.uuid} metrics={discrepancyMetrics} onSelect={handleSelectMetric} />
      <BlockListMetric blockId={selectedBlock.uuid} metrics={technicalMetrics} onSelect={handleSelectMetric} />
      <li>
        <ul>
          {metrics.length === 0 && (
            <ListItem>
              <Typography variant="subtitle2">{t('text.noMetric')}</Typography>
            </ListItem>
          )}
        </ul>
      </li>
      <li>
        <ul>
          <ListSubheader disableGutters>{t('subtitle.variables')}</ListSubheader>
          {selectedBlock.variableUuids.map(variableId => (
            <BlockElementVariable key={variableId} variableId={variableId} onClick={handleSelectVariable(variableId)} />
          ))}
          {selectedBlock.variableUuids.length === 0 && (
            <ListItem>
              <Typography variant="subtitle2">{t('text.noVariable')}</Typography>
            </ListItem>
          )}
        </ul>
      </li>
    </List>
  );
};

export default BlockElementsList;
