import { Button, ButtonProps, IconButton, IconButtonProps, TooltipProps } from '@mui/material';
import { MouseEventHandler, forwardRef, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { CalculationVariable, UUID } from '@dametis/core';

import { PlaygroundOutlined } from 'assets/icons/PlaygroundOutlined';
import { DateToUrlParam } from 'functions/dateInUrl';
import { getPlaygroundBody } from 'functions/getPlaygroundBody';
import { append } from 'functions/localStorage';
import { PLAYGROUND_STORAGE_KEY, PlaygroundRedirectionInfo } from 'functions/playgroundRedirection';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useDispatch } from 'store';
import { setIsOpen } from 'store/slices/cart';

export type OmittedProps = 'to' | 'component' | 'color';

export type ButtonNavLinkProps = Omit<ButtonProps<typeof NavLink, NavLinkProps>, 'startIcon' | OmittedProps>;

export type IconButtonNavLinkProps = Omit<IconButtonProps<typeof NavLink, NavLinkProps>, 'size' | OmittedProps>;

export const ButtonNavLink = forwardRef<HTMLAnchorElement, ButtonProps<typeof NavLink, NavLinkProps>>((props, ref) => (
  <Button ref={ref} component={NavLink} {...props} />
));

export const IconButtonNavLink = forwardRef<HTMLAnchorElement, IconButtonProps<typeof NavLink, NavLinkProps>>((props, ref) => (
  <IconButton ref={ref} component={NavLink} {...props} />
));

export interface CommonGoToPlaygroundButtonProps {
  calculations: CalculationVariable[];
  date?: {
    from?: Date;
    to?: Date;
  };
  openInNewTab?: boolean;
}

export type GoToPlaygroundButtonProps = CommonGoToPlaygroundButtonProps & ButtonNavLinkProps & { mustClearCart?: boolean };

export type GoToPlaygroundIconButtonProps = CommonGoToPlaygroundButtonProps & {
  tooltipProps?: Omit<TooltipProps, 'children' | 'title'>;
} & IconButtonNavLinkProps & { mustClearCart?: boolean };

export type GoToPlaygroundButtonBaseProps =
  | ({
      isIcon: false;
    } & GoToPlaygroundButtonProps)
  | ({
      isIcon: true;
    } & GoToPlaygroundIconButtonProps);

const GoToPlaygroundButtonBase = forwardRef<HTMLAnchorElement, GoToPlaygroundButtonBaseProps>(
  ({ isIcon, calculations, date, mustClearCart = false, onClick, openInNewTab, ...props }, ref) => {
    const { t } = useTranslation('playground');
    const dispatch = useDispatch();

    const playgroundBodyUuid: UUID = useMemo(() => uuidv4(), []);

    const localStorageKey = useMemo(() => append(PLAYGROUND_STORAGE_KEY, playgroundBodyUuid), [playgroundBodyUuid]);

    const [, addItem] = useLocalStorage<PlaygroundRedirectionInfo | null>(localStorageKey, null);

    const queryParams = useMemo(() => {
      const searchParams = new URLSearchParams();
      searchParams.append('playgroundBodyUuid', playgroundBodyUuid);
      if (date?.from) {
        searchParams.append('from', DateToUrlParam(date.from ?? null));
      }
      if (date?.to) {
        searchParams.append('to', DateToUrlParam(date.to ?? null));
      }
      if (mustClearCart) {
        searchParams.append('mustClearCart', 'true');
      }
      return searchParams.toString();
    }, [date, mustClearCart, playgroundBodyUuid]);

    const addPlaygroundBodyToLocalStorage = useCallback(async () => {
      if (!(calculations?.length > 0)) {
        return;
      }
      const playground = await getPlaygroundBody(calculations);
      addItem({ playground, date: new Date().toISOString() });
    }, [calculations, addItem]);

    const handleClickButton: MouseEventHandler<HTMLAnchorElement> = useCallback(
      e => {
        if (onClick) {
          onClick(e);
        }
        dispatch(setIsOpen(false));
      },
      [dispatch, onClick],
    );

    useEffect(() => {
      void addPlaygroundBodyToLocalStorage();
    }, [addPlaygroundBodyToLocalStorage]);

    return (
      <>
        {!isIcon ? (
          <ButtonNavLink
            ref={ref}
            {...props}
            color="link"
            rel={openInNewTab ? 'noopener noreferrer' : undefined}
            startIcon={<PlaygroundOutlined />}
            target={openInNewTab ? '_blank' : undefined}
            to={`/playgrounds/new?${queryParams}`}
            variant="contained"
            onAuxClick={handleClickButton}
            onClick={handleClickButton}
          >
            {t('button.openInPlayground')}
          </ButtonNavLink>
        ) : (
          <IconButtonNavLink
            ref={ref}
            {...props}
            size="small"
            to={`/playgrounds/new?${queryParams}`}
            onAuxClick={handleClickButton}
            onClick={handleClickButton}
          >
            <PlaygroundOutlined fontSize="small" />
          </IconButtonNavLink>
        )}
      </>
    );
  },
);

ButtonNavLink.displayName = 'ButtonNavLink';

IconButtonNavLink.displayName = 'IconButtonNavLink';

GoToPlaygroundButtonBase.displayName = 'GoToPlaygroundButtonBase';

export default GoToPlaygroundButtonBase;
