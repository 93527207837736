// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.97.1/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("JuliaMono-Light.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("JuliaMono-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("JuliaMono-Medium.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("JuliaMono-SemiBold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("JuliaMono-Bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("JuliaMono-ExtraBold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("JuliaMono-Black.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face{font-family:JuliaMono;font-weight:300;font-style:normal;src:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff2");text-rendering:optimizeLegibility}@font-face{font-family:JuliaMono;font-weight:400;font-style:normal;src:url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff2");text-rendering:optimizeLegibility}@font-face{font-family:JuliaMono;font-weight:500;font-style:normal;src:url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff2");text-rendering:optimizeLegibility}@font-face{font-family:JuliaMono;font-weight:600;font-style:normal;src:url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("woff2");text-rendering:optimizeLegibility}@font-face{font-family:JuliaMono;font-weight:700;font-style:normal;src:url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("woff2");text-rendering:optimizeLegibility}@font-face{font-family:JuliaMono;font-weight:800;font-style:normal;src:url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("woff2");text-rendering:optimizeLegibility}@font-face{font-family:JuliaMono;font-weight:900;font-style:normal;src:url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format("woff2");text-rendering:optimizeLegibility}
`, "",{"version":3,"sources":["webpack://./src/assets/fonts/juliamono/juliamono.css"],"names":[],"mappings":"AAAA,WACE,qBAAa,CACb,eAAa,CACb,iBAAY,CACZ,2DAAyC,CACzC,iCACF,CAEA,WACE,qBAAa,CACb,eAAa,CACb,iBAAY,CACZ,2DAA2C,CAC3C,iCACF,CAEA,WACE,qBAAa,CACb,eAAa,CACb,iBAAY,CACZ,2DAA0C,CAC1C,iCACF,CAEA,WACE,qBAAa,CACb,eAAa,CACb,iBAAY,CACZ,2DAA4C,CAC5C,iCACF,CAEA,WACE,qBAAa,CACb,eAAa,CACb,iBAAY,CACZ,2DAAwC,CACxC,iCACF,CAEA,WACE,qBAAa,CACb,eAAa,CACb,iBAAY,CACZ,2DAA6C,CAC7C,iCACF,CAEA,WACE,qBAAa,CACb,eAAa,CACb,iBAAY,CACZ,2DAAyC,CACzC,iCACF","sourcesContent":["@font-face {\n  font-family: JuliaMono;\n  font-weight: 300;\n  font-style: normal;\n  src: url('JuliaMono-Light.woff2') format('woff2');\n  text-rendering: optimizeLegibility;\n}\n\n@font-face {\n  font-family: JuliaMono;\n  font-weight: 400;\n  font-style: normal;\n  src: url('JuliaMono-Regular.woff2') format('woff2');\n  text-rendering: optimizeLegibility;\n}\n\n@font-face {\n  font-family: JuliaMono;\n  font-weight: 500;\n  font-style: normal;\n  src: url('JuliaMono-Medium.woff2') format('woff2');\n  text-rendering: optimizeLegibility;\n}\n\n@font-face {\n  font-family: JuliaMono;\n  font-weight: 600;\n  font-style: normal;\n  src: url('JuliaMono-SemiBold.woff2') format('woff2');\n  text-rendering: optimizeLegibility;\n}\n\n@font-face {\n  font-family: JuliaMono;\n  font-weight: 700;\n  font-style: normal;\n  src: url('JuliaMono-Bold.woff2') format('woff2');\n  text-rendering: optimizeLegibility;\n}\n\n@font-face {\n  font-family: JuliaMono;\n  font-weight: 800;\n  font-style: normal;\n  src: url('JuliaMono-ExtraBold.woff2') format('woff2');\n  text-rendering: optimizeLegibility;\n}\n\n@font-face {\n  font-family: JuliaMono;\n  font-weight: 900;\n  font-style: normal;\n  src: url('JuliaMono-Black.woff2') format('woff2');\n  text-rendering: optimizeLegibility;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
