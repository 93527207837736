import { CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, PropsWithChildren } from 'react';

import useCircLoaderStyles from './CircularLoader.styles';

interface Props {
  loading?: boolean;
  overlay?: boolean;
  className?: string;
  progress?: { name: string; logs: string; progress: string };
}

const CircularLoader: FC<PropsWithChildren<Props>> = ({
  loading = false,
  overlay = true,
  children = null,
  className = '',
  progress = undefined,
}) => {
  const theme = useTheme();
  const classes = useCircLoaderStyles();
  return (
    <div className={classes.circularLoader}>
      <div className={classes.circularLoader__container} style={{ visibility: loading && overlay ? 'hidden' : 'visible' }}>
        {children}
      </div>
      <AnimatePresence>
        {loading && (
          <motion.div
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0.7 }}
            transition={{
              duration: theme.transitions.duration.standard / 1000,
            }}
          >
            <Grid
              container
              alignItems="center"
              className={overlay ? clsx(classes.circularLoader__overlay, className) : undefined}
              justifyContent="center"
            >
              <Stack alignItems="center" justifyContent="center" sx={{ width: '100%', height: '100%', p: theme.spacing(1) }}>
                <CircularProgress className={classes.circularLoader__overlay__icon} color="secondary" />
                {progress && (
                  <>
                    <Typography
                      noWrap
                      sx={{
                        mt: theme.spacing(2),
                        width: '100%',
                        textAlign: 'center',
                      }}
                      variant="subtitle2"
                    >
                      {progress.name}
                    </Typography>
                    <Typography
                      noWrap
                      sx={{
                        mt: theme.spacing(2),
                        width: '100%',
                        textAlign: 'center',
                      }}
                      variant="subtitle2"
                    >
                      {progress.logs}
                    </Typography>
                    <Typography
                      noWrap
                      sx={{
                        width: '100%',
                        textAlign: 'center',
                      }}
                      variant="subtitle2"
                    >
                      {progress.progress}
                    </Typography>
                  </>
                )}
              </Stack>
            </Grid>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default CircularLoader;
