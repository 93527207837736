import { Grid, InputLabel, Paper, Stack, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { StandardBlockMetadataBody } from '@dametis/core';

import { StandardBlockBody } from 'components/Lego/types';

import MetadataForm from './MetadataForm';

export interface MetadataListStepProps<T extends StandardBlockBody> {
  standardBlockBody: T;
  setStandardBlockBody: Dispatch<SetStateAction<T>>;
}

const MetadataListStep = <T extends StandardBlockBody = StandardBlockBody>({
  standardBlockBody,
  setStandardBlockBody,
}: MetadataListStepProps<T>) => {
  const { t } = useTranslation('lego');

  const handleChangeMetadata = useCallback(
    (metadataIndex: number) => (newValue: StandardBlockMetadataBody) => {
      setStandardBlockBody(state => ({
        ...state,
        standardMetadataList: (state.standardMetadataList ?? []).map((metadata, index) => (index === metadataIndex ? newValue : metadata)),
      }));
    },
    [setStandardBlockBody],
  );

  return (
    <>
      <Stack gap={1} width={1}>
        <InputLabel>{t('label.metadata')}</InputLabel>
        {(standardBlockBody.standardMetadataList ?? []).length > 0 && (
          <Grid container spacing={1}>
            {(standardBlockBody.standardMetadataList ?? []).map((metadata, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Grid key={index} item xs={6}>
                <Paper sx={{ p: 1, background: theme => theme.palette.background.default }}>
                  <MetadataForm<StandardBlockMetadataBody> value={metadata} onChange={handleChangeMetadata(index)} />
                </Paper>
              </Grid>
            ))}
          </Grid>
        )}
        {(standardBlockBody.standardMetadataList ?? []).length === 0 && (
          <Typography align="center" p={2} variant="subtitle2">
            {t('text.noMetadata')}
          </Typography>
        )}
      </Stack>
    </>
  );
};

export default MetadataListStep;
