import { createContext, useContext } from 'react';
import { createStore, useStore } from 'zustand';

import { TagEditActions, createTagEditActions } from 'zustand/actions/tagEdit';
import { TagEditState, initialTagEditState } from 'zustand/states/tagEdit';

export type TagEditStore = TagEditState & TagEditActions;

export const createTagEditStore = () =>
  createStore<TagEditStore>(setState => ({
    ...initialTagEditState,
    ...createTagEditActions(setState),
  }));

export const TagEditStoreContext = createContext(createTagEditStore());

export const useTagEditStore = <U>(selector: (state: TagEditStore) => U) => {
  const store = useContext(TagEditStoreContext);
  return useStore(store, selector);
};
