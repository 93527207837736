import { ArrowRight } from '@mui/icons-material';
import { List, ListItem, Paper, Popper, PopperProps, Stack } from '@mui/material';
import { FC, MouseEvent, useCallback, useMemo, useState } from 'react';

import { NestedTag, ShortTagInfo, UUID } from '@dametis/core';

import { useSelector } from 'store';
import { CorporateTagTreeInfo, useCorporateTagTrees } from 'store/api/tags';

import SiteChip from '../SiteChip/SiteChip';

import TagPopover from './TagPopover';

const tagTreesEmptyArray: CorporateTagTreeInfo[] = [];

export interface CorporateTagPopoverProps extends Omit<PopperProps, 'open'> {
  isOpen: boolean;
  setIsOpen: (newIsOpen: boolean) => void;
  selectedTags?: ShortTagInfo[];
  onSelectTag?: (selectedTag: NestedTag) => void;
  searchFilterValue?: string;
  disabledTagIds?: UUID[];
}

const CorporateTagPopover: FC<CorporateTagPopoverProps> = ({
  isOpen,
  setIsOpen,
  selectedTags = [],
  onSelectTag: userOnSelectTag = undefined,
  searchFilterValue = '',
  disabledTagIds = [],
  sx,
  ...props
}) => {
  const sites = useSelector(state => state.auth.selectedGroup!.sites);

  const { data: tagTrees = tagTreesEmptyArray } = useCorporateTagTrees({ siteIds: sites.map(site => site.uuid) });

  const [hoveredEl, setHoveredEl] = useState<HTMLLIElement | null>(null);
  const [hoveredSiteId, setHoveredSiteId] = useState<UUID | null>(null);
  const [isTagPopoverOpen, setIsTagPopoverOpen] = useState<boolean>(false);

  const tagTreesBySiteId = useMemo(
    () =>
      tagTrees.reduce<Record<UUID, CorporateTagTreeInfo[]>>((result, tagTree) => {
        if (!result[tagTree.siteId]) {
          result[tagTree.siteId] = [];
        }
        result[tagTree.siteId].push(tagTree);
        return result;
      }, {}),
    [tagTrees],
  );

  const handleMouseOverSite = useCallback(
    (siteId: UUID) => (event: MouseEvent<HTMLLIElement>) => {
      setHoveredEl(event.currentTarget);
      setHoveredSiteId(siteId);
      setIsTagPopoverOpen(true);
    },
    [],
  );

  const onCloseTagPopover = useCallback(() => {
    setHoveredEl(null);
    setHoveredSiteId(null);
    setIsTagPopoverOpen(false);
  }, []);

  const handleMouseOutList = useCallback(() => {
    onCloseTagPopover();
  }, [onCloseTagPopover]);

  const handleSelectTags = useCallback(
    (selectedTag: NestedTag) => {
      onCloseTagPopover();
      if (userOnSelectTag) {
        userOnSelectTag(selectedTag);
      }
    },
    [userOnSelectTag, onCloseTagPopover],
  );

  return (
    <>
      <Popper
        open={isOpen}
        placement="bottom-start"
        sx={{ zIndex: theme => theme.zIndex.modal + 1, position: 'relative', ...sx }}
        {...props}
      >
        <Paper elevation={8}>
          <List component="ul" onMouseOut={handleMouseOutList}>
            {sites.map(site => (
              <ListItem
                key={site.uuid}
                component="li"
                sx={{
                  cursor: 'pointer',
                  background: theme => (hoveredSiteId === site.uuid ? `${theme.palette.primary.main}09` : undefined),
                }}
                onMouseOver={handleMouseOverSite(site.uuid)}
              >
                <Stack alignItems="center" direction="row" gap={2} justifyContent="space-between" width="100%">
                  <SiteChip site={site} size="small" variant="outlined" />
                  <ArrowRight fontSize="small" />
                </Stack>
                {hoveredSiteId === site.uuid && (
                  <TagPopover
                    anchorEl={hoveredEl}
                    disabledTagIds={disabledTagIds}
                    isOpen={isTagPopoverOpen && !!hoveredEl}
                    placement="right-start"
                    searchFilterValue={searchFilterValue}
                    selectedTags={selectedTags}
                    setIsOpen={setIsTagPopoverOpen}
                    sx={{ zIndex: theme => theme.zIndex.modal + 1 }}
                    tagTrees={tagTreesBySiteId[hoveredSiteId] ?? []}
                    onSelectTag={handleSelectTags}
                  />
                )}
              </ListItem>
            ))}
          </List>
        </Paper>
      </Popper>
    </>
  );
};

export default CorporateTagPopover;
