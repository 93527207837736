import i18next from 'i18next';

import {
  BlockVariable,
  IsAliasVariable,
  IsBatchVariable,
  IsBlockTypeMetricVariable,
  IsBlockTypeParameterVariable,
  IsBlockVariable,
  IsCalculationVariable,
  IsDataVariable,
  IsModelVariable,
  ModelKeyField,
  ModelVariable,
  Operator,
  PartialCalculationVariable,
} from '@dametis/core';
import { CalculationToString, mathJs } from '@dametis/mathjs';

import { getLocalizedGroupBy } from 'localization/useLocalizedGroupBy';
import store from 'store';
import { selectAliases } from 'store/api/aliases';
import { selectBlockTypes } from 'store/api/blockTypes';
import { selectBlocks } from 'store/api/blocks';
import { selectModelsResult } from 'store/api/models';
import { selectStandardBlocks } from 'store/api/standardBlocks';

import { isModelExplanatoryVariable } from './isModelExplanatoryVariable';
import { numberToSubscript } from './numberToSubscript';

export const blockVariableToString = (variable: BlockVariable, blockPath: boolean, enrichedString: boolean = false): string => {
  const state = store.getState();
  const { data: blocks = [] } = selectBlocks()(state);
  const { data: standardBlocks = [] } = selectStandardBlocks()(state);

  const selectedBlock = blocks.find(block => block.uuid === variable.blockId);
  if (!selectedBlock) {
    return i18next.t('global:text.unknownBlock');
  }
  const selectedVariable = [...selectedBlock.parameters, ...selectedBlock.metrics].find(v => v.uuid === variable.blockKey);
  const selectedStandardBlock = standardBlocks.find(standardBlock => standardBlock.uuid === selectedBlock?.standardBlockId);

  if (!selectedStandardBlock || !selectedVariable) {
    return i18next.t('global:text.unknownVariable');
  }
  if (blockPath) {
    return enrichedString && variable.operator
      ? `${i18next.t('global:operator.opShort', { context: variable.operator }).toUpperCase()}(${i18next.t(
          'lego:text.blockVariableWithPath',
          { block: selectedBlock.name, variable: selectedVariable.name },
        )})`
      : i18next.t('lego:text.blockVariableWithPath', { block: selectedBlock.name, variable: selectedVariable.name });
  }
  return selectedVariable.name;
};

export const modelVariableToString = (variable: ModelVariable, enrichedString = false): string => {
  const { data: models = [] } = selectModelsResult()(store.getState());
  const model = models.find(model => model.uuid === variable.modelUuid);
  if (!model) {
    return i18next.t('global:text.unknownModel');
  }
  let translation = '';
  if (isModelExplanatoryVariable(variable)) {
    const variableIndex = model.xVars.findIndex(xVar => xVar.uuid === variable.modelXVarUuid);
    if (variableIndex < 0) return i18next.t('global:text.unknownVariable');
    const variableName = calculationToString(model.xVars[variableIndex].variable);
    translation = i18next.t(`models:text.modelVariableWithPath.${ModelKeyField.X_VAR}`, {
      variableIndex: numberToSubscript(variableIndex + 1),
      variableName,
      model: model.name,
    });
  } else {
    translation = i18next.t(`models:text.modelVariableWithPath.${variable.modelKey}`, { model: model.name });
  }
  return enrichedString && variable.operator
    ? `${i18next.t('global:operator.opShort', { context: variable.operator }).toUpperCase()}(${translation})`
    : translation;
};

export const calculationToString = (
  calculationVariable: PartialCalculationVariable | null | undefined,
  blockPath = true,
  enrichedString = false,
): string => {
  const state = store.getState();
  if (calculationVariable === undefined || calculationVariable === null) {
    return i18next.t('global:text.unknownVariable');
  }
  if (calculationVariable.nickname?.length) {
    return calculationVariable.nickname;
  }
  const variables = state.variables.byId;
  const { flattenBatches } = state.batch;

  return CalculationToString(mathJs, calculationVariable, varCalc => {
    const options: string = [
      ![Operator.HISTORY, null, undefined].includes(varCalc.operator)
        ? `${i18next.t('global:operator.opShort', { context: varCalc.operator })?.toLocaleUpperCase()}`
        : null,
      varCalc.groupBy && getLocalizedGroupBy(varCalc.groupBy).toLocaleUpperCase(),
      varCalc.timestamp && i18next.t(`global:timestamp.${varCalc.timestamp}`).toLocaleUpperCase(),
    ]
      .filter(option => option !== null && option !== undefined)
      .map(option => option.toString())
      .join('_');

    if (IsCalculationVariable(varCalc)) {
      return enrichedString ? options : '';
    }

    if (IsDataVariable(varCalc)) {
      const variable = variables[varCalc.variableUuid];
      if (variable === undefined) {
        return i18next.t('global:text.unknownVariable');
      }
      return enrichedString && options.length ? `${options}(${variable.name})` : `${variable.name}`;
    }
    if (IsBatchVariable(varCalc)) {
      const batch = flattenBatches?.find(batch => batch.uuid === varCalc.batchUuid);
      if (batch === undefined) {
        return i18next.t('global:text.unknownVariable');
      }
      return enrichedString && options.length ? `${options}(${batch.name})` : `${batch.name}`;
    }
    if (IsBlockVariable(varCalc)) {
      return blockVariableToString(varCalc, blockPath, enrichedString);
    }
    if (IsBlockTypeParameterVariable(varCalc) || IsBlockTypeMetricVariable(varCalc)) {
      const { data: blockTypes = [] } = selectBlockTypes()(state);
      const variableName =
        state.variables.notSavedIdByName[varCalc.blockKey] ??
        [...blockTypes.flatMap(blockType => blockType.parameters), ...blockTypes.flatMap(blockType => blockType.metrics)].find(
          variable => variable.uuid === varCalc.blockKey,
        )?.name;
      if (variableName === undefined) {
        return i18next.t('global:text.unknownVariable');
      }
      return enrichedString && varCalc.operator
        ? `(${i18next.t('global:operator.opShort', { context: varCalc.operator }).toUpperCase()})${variableName}`
        : variableName;
    }
    if (IsModelVariable(varCalc)) {
      return modelVariableToString(varCalc, enrichedString);
    }
    if (IsAliasVariable(varCalc)) {
      const { data: aliases = [] } = selectAliases()(store.getState());
      const alias = aliases.find(alias => alias.uuid === varCalc.aliasUuid);
      if (alias === undefined) {
        return i18next.t('global:text.unknownVariable');
      }
      return enrichedString && options.length ? `${options}(${alias.name})` : `${alias.name}`;
    }
    return i18next.t('global:text.unknownVariable');
  });
};
