import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ReportInfo, ShortTagInfo, UUID } from '@dametis/core';

import { DaChart } from 'classes/DaCharts/DaChart';
import { WidgetInfoChart } from 'classes/DaCharts/types';
import { MacrosResults } from 'types/reports';

export interface WidgetStateShownVariable {
  uuid: string;
  color: string;
  name: string;
  visible: boolean;
}

export interface WidgetsState {
  allIds: string[];
  byId: Record<string, WidgetInfoChart>;
}

export interface ReportsStateCurrent {
  report: ReportInfo | undefined;
  widgets: WidgetsState;
  loadedWidgets: Record<string, boolean>;
  chartsById: Record<string, DaChart>;
  macrosResults: MacrosResults[];
  isCommentaryOpen: boolean;
}

export interface ReportsStateGlobalSettings {
  editMode: boolean;
  editingWidget: string | null;
  editingRow: Record<string, string> | null;
  editingCell: Record<string, any> | null;
  editHeader: boolean;
  save: boolean | null;
}

export interface ReportsState {
  current: ReportsStateCurrent;
  globalSettings: ReportsStateGlobalSettings;
  loading: boolean;
  printing: boolean;
  template: boolean;
  block: string | null;
}

const initialState: ReportsState = {
  current: {
    report: undefined,
    widgets: {
      allIds: [],
      byId: {},
    },
    chartsById: {},
    loadedWidgets: {},
    macrosResults: [],
    isCommentaryOpen: false,
  },
  globalSettings: {
    editMode: false,
    editingWidget: null,
    editingRow: null,
    editingCell: null,
    editHeader: true,
    save: null,
  },
  loading: false,
  printing: false,
  template: false,
  block: null,
};

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setCurrentReport: (state, action: PayloadAction<Partial<ReportsStateCurrent>>) => {
      Object.assign(state.current, action.payload);
    },
    setCurrentReportName: (state, action: PayloadAction<string>) => {
      if (!state.current.report) throw new Error();
      state.current.report.name = action.payload;
    },
    setCurrentReportTags: (state, action: PayloadAction<ShortTagInfo[]>) => {
      if (!state.current.report) throw new Error();
      state.current.report.tags = action.payload;
    },
    setWidgetIsLoaded: (state, action: PayloadAction<UUID>) => {
      state.current.loadedWidgets[action.payload] = true;
    },
    setWidgetIsLoading: (state, action: PayloadAction<UUID>) => {
      state.current.loadedWidgets[action.payload] = false;
    },
    setIsCommentaryOpen: (state, action: PayloadAction<boolean>) => {
      state.current.isCommentaryOpen = action.payload;
    },
    setGlobalSettings: (state, action: PayloadAction<Partial<ReportsStateGlobalSettings>>) => {
      state.globalSettings = { ...state.globalSettings, ...action.payload };
    },
    createWidget: (state, action: PayloadAction<WidgetInfoChart>) => {
      Object.assign(state.current.widgets.byId, { [action.payload.uuid]: action.payload });
      state.current.widgets.allIds.push(action.payload.uuid);
    },
    setWidget: (state, action: PayloadAction<WidgetInfoChart>) => {
      Object.assign(state.current.widgets.byId[action.payload.uuid], action.payload);
    },
    setChartById: (state, action: PayloadAction<{ widgetId: string; chart: DaChart }>) => {
      Object.assign(state.current.chartsById, { [action.payload.widgetId]: action.payload.chart });
    },
    removeWidget: (state, action: PayloadAction<UUID>) => {
      delete state.current.widgets.byId[action.payload];
      state.current.widgets.allIds = state.current.widgets.allIds.filter(widgetId => widgetId !== action.payload);
      if (state.globalSettings && state.globalSettings.editingWidget === action.payload) {
        state.globalSettings.editingWidget = null;
        state.globalSettings.editingCell = null;
        state.globalSettings.editingRow = null;
      }
    },
    setMacrosResults: (state, action: PayloadAction<MacrosResults[]>) => {
      state.current.macrosResults = action.payload;
    },
    setSaving: (state, action: PayloadAction<boolean | null>) => {
      state.globalSettings.save = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setPrinting: (state, action: PayloadAction<boolean>) => {
      state.printing = action.payload;
    },
    setTemplate: (state, action: PayloadAction<boolean>) => {
      state.template = action.payload;
    },
    setBlock: (state, action: PayloadAction<string | null>) => {
      state.block = action.payload;
    },
    clearReport: () => ({ ...initialState }),
  },
});

export const {
  setCurrentReport,
  setCurrentReportName,
  setCurrentReportTags,
  setWidgetIsLoaded,
  setWidgetIsLoading,
  setIsCommentaryOpen,
  setGlobalSettings,
  createWidget,
  setWidget,
  setChartById,
  removeWidget,
  setMacrosResults,
  setSaving,
  setLoading,
  setPrinting,
  setTemplate,
  setBlock,
  clearReport,
} = reportsSlice.actions;

export default reportsSlice.reducer;
