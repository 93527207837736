import { CheckCircleOutlined, WarningAmberOutlined } from '@mui/icons-material';
import { Stack, Tooltip, Typography, styled, useTheme } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { UUID } from '@dametis/core';

import { setColorLightness } from 'functions/color';
import { useTagEditStore } from 'zustand/stores/tagEdit';

import { checkTagDepth } from '../../helpers/checkTagDepth';
import { ShortNestedTag } from '../../helpers/shortNestedTag';
import { MAX_TAG_DEPTH, MAX_TAG_NAME_LENGTH } from '../TagsList';

import { PAPER_HEIGHT } from './ItemPaper';

export const INDICATOR_SIZE = 16;

export enum IndicatorType {
  ERROR = 'error',
  SELECTION = 'selection',
}

export interface IndicatorProps {
  type: IndicatorType;
  count: number;
}

const Indicator: FC<IndicatorProps> = ({ type, count }) => {
  const { t } = useTranslation('tags');

  const theme = useTheme();

  const label = useMemo(
    () => (type === IndicatorType.ERROR ? t('text.hasChildError', { count }) : t('text.hasChildSelected', { count })),
    [type, count, t],
  );

  const color = useMemo(() => (type === IndicatorType.ERROR ? theme.palette.error.main : theme.palette.secondary.main), [type, theme]);

  return (
    <Tooltip title={label}>
      <Stack
        alignItems="center"
        direction="row"
        gap={0.25}
        justifyContent="center"
        px={0.375}
        sx={{
          borderRadius: INDICATOR_SIZE / 2,
          minWidth: INDICATOR_SIZE,
          height: INDICATOR_SIZE,
          backgroundColor: setColorLightness(color, 90),
        }}
      >
        {type === IndicatorType.ERROR && <WarningAmberOutlined color="error" sx={{ fontSize: 13 }} />}
        {type === IndicatorType.SELECTION && <CheckCircleOutlined color="success" sx={{ fontSize: 13 }} />}
        <Typography sx={{ fontSize: count > 9 ? '10px' : undefined, color }} variant="body2">
          {count}
        </Typography>
      </Stack>
    </Tooltip>
  );
};

const Container = styled(Stack)(() => ({
  position: 'absolute',
  right: 'calc(100% + 8px)',
  top: 0,
  height: PAPER_HEIGHT,
}));

export interface StateIndicatorsProps {
  shortTag: ShortNestedTag;
  allChildrenIds: UUID[];
  depth: number;
  displayError?: boolean;
  displaySelection?: boolean;
}

const countInvalidChildren = (tag: ShortNestedTag, currentDepth: number): number =>
  tag.children.reduce(
    (count, child) =>
      count +
      (!checkTagDepth(child, MAX_TAG_DEPTH, currentDepth + 1) && child.children.length > 0 ? 1 : 0) +
      countInvalidChildren(child, currentDepth + 1),
    0,
  );

const StateIndicators: FC<StateIndicatorsProps> = ({ shortTag, allChildrenIds, depth, displayError = true, displaySelection = true }) => {
  const selectedTagIds = useTagEditStore(state => state.selectedTagIds);
  const tagsById = useTagEditStore(state => state.editor.tagsById);

  const childSelected = useMemo(
    () => [...selectedTagIds].filter(selectedTag => allChildrenIds.includes(selectedTag) && shortTag.uuid !== selectedTag),
    [allChildrenIds, selectedTagIds, shortTag.uuid],
  );

  const childDepthErrors = useMemo(() => countInvalidChildren(shortTag, depth), [shortTag, depth]);

  const childNameErrors = useMemo(
    () =>
      [...allChildrenIds].filter(
        childId => tagsById[childId] && (tagsById[childId].name.length > MAX_TAG_NAME_LENGTH || tagsById[childId].name.length === 0),
      ).length,
    [allChildrenIds, tagsById],
  );

  const childErrors = useMemo(() => childDepthErrors + childNameErrors, [childDepthErrors, childNameErrors]);

  const hasIndicators = useMemo(() => childSelected.length !== 0 || childErrors !== 0, [childSelected.length, childErrors]);

  return (
    <>
      {hasIndicators && (
        <Container alignItems="flex-end" gap={0.25} justifyContent="center">
          {childSelected.length !== 0 && displaySelection && <Indicator count={childSelected.length} type={IndicatorType.SELECTION} />}
          {childErrors !== 0 && displayError && <Indicator count={childErrors} type={IndicatorType.ERROR} />}
        </Container>
      )}
    </>
  );
};

export default StateIndicators;
