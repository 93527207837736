import i18next from 'i18next';

import { CreateModelBody, ModelInfo, UUID, UpdateModelBody } from '@dametis/core';

import { sdk } from 'sdk';
import { addToast } from 'store/slices/toast';
import { ToastSeverity } from 'types';

import switchGroupSite from '../helpers/switchGroupSite';
import store, { RootState, useSelector } from '../index';

import { api } from './index';

export const modelsApi = api.injectEndpoints({
  endpoints: build => ({
    getModels: build.query<ModelInfo[], { siteId?: string } | void>({
      queryFn: async (arg, { getState }) => {
        const state = getState() as RootState;
        const siteId = (arg && arg.siteId) ?? state.auth.selectedSite!.uuid;
        try {
          const { data } = await sdk.model.List(siteId);
          return { data };
        } catch (error) {
          return { error };
        }
      },
      providesTags: ['Models'],
    }),
    getModel: build.query<ModelInfo | null, UUID | 'new' | undefined>({
      queryFn: async (uuid, { dispatch }) => {
        try {
          if (uuid === undefined || uuid === 'new') return { data: null };
          const { data } = await sdk.model.Read(uuid);
          await dispatch(switchGroupSite(data));
          return { data };
        } catch (error) {
          return { error };
        }
      },
      providesTags: ['Models'],
    }),
    createModel: build.mutation<ModelInfo, { modelBody: CreateModelBody }>({
      queryFn: async ({ modelBody }, { getState, dispatch }) => {
        const state = getState() as RootState;
        const siteId = state.auth.selectedSite!.uuid;
        try {
          const { data } = await sdk.model.Create(siteId, modelBody);
          dispatch(
            addToast({
              severity: ToastSeverity.SUCCESS,
              message: i18next.t('toast:successCreateModel'),
            }),
          );
          return { data };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ['Models'],
    }),
    updateModel: build.mutation<ModelInfo, { modelBody: UpdateModelBody; uuid: UUID }>({
      queryFn: async ({ modelBody, uuid }, { dispatch }) => {
        try {
          const { data } = await sdk.model.Update(uuid, modelBody);
          dispatch(
            addToast({
              severity: ToastSeverity.SUCCESS,
              message: i18next.t('toast:successUpdateModel'),
            }),
          );
          return { data };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ['Models'],
    }),
    deleteModel: build.mutation<UUID, UUID>({
      queryFn: async (uuid, { dispatch }) => {
        try {
          await sdk.model.Delete(uuid);
          dispatch(
            addToast({
              message: i18next.t('toast:successDeleteModel'),
              severity: ToastSeverity.SUCCESS,
            }),
          );
          return { data: uuid };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ['Models'],
    }),
  }),
});

export const { useGetModelQuery, useCreateModelMutation, useUpdateModelMutation, useDeleteModelMutation } = modelsApi;

export const selectModelsResult: typeof modelsApi.endpoints.getModels.select = arg =>
  modelsApi.endpoints.getModels.select(arg ?? { siteId: store.getState().auth.selectedSite?.uuid });

export const useModels: typeof modelsApi.useGetModelsQuery = (arg, options) => {
  const siteId = useSelector(state => state.auth.selectedSite?.uuid);

  return modelsApi.useGetModelsQuery(arg ?? { siteId }, { skip: !siteId, ...options });
};
