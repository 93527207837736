import { ModelKeyField } from '@dametis/core';

export default {
  title: {
    models: 'Models',
    yVar: 'Response variable',
    vncEquation: 'Equation',
    xVars: 'Explanatory variables',
    others: 'Other response variables',
    editModel: 'Edit the model "{{name}}"',
  },
  subtitle: {
    noModels: 'No models found',
    noYVar: 'No response variable',
    noXVars: 'No explanatory variables',
  },
  button: {
    createModel: 'Model',
    duplicateModel: 'Duplicate',
    deleteModel: 'Delete',
    editModel: 'Rename',
    cancel: 'Cancel',
    save: 'Save',
  },
  tooltip: {
    duplicateModel: 'Duplicate this model',
    deleteModel: 'Delete this model',
    editModel: 'Edit this model',
  },
  typography: {
    period: 'Reference period',
    groupBy: 'Sub-periods',
    formula: 'Equation',
    xVars: 'Explanatory variables',
    r2: 'R²',
    smape: 'MAPE',
    cvrmse: 'CV(RMSE)',
  },
  input: {
    label: {
      name: 'Name',
    },
  },
  text: {
    modelVariableWithPath: {
      [ModelKeyField.MODEL]: '{{model}} ▸ model',
      [ModelKeyField.Y_VAR]: '{{model}} ▸ response variable',
      [ModelKeyField.ABSOLUTE_GAP]: '{{model}} ▸ absolute gap',
      [ModelKeyField.RELATIVE_GAP]: '{{model}} ▸ relative gap',
      [ModelKeyField.UNCERTAINTY]: '{{model}} ▸ uncertainty',
      [ModelKeyField.R2]: '{{model}} ▸ R²',
      [ModelKeyField.SMAPE]: '{{model}} ▸ MAPE',
      [ModelKeyField.CVRMSE]: '{{model}} ▸ CV(RMSE)',
      [ModelKeyField.X_VAR]: '{{model}} ▸ X{{variableIndex}} ({{variableName}})',
      modelCalculation: '{{model}} ▸ model',
    },
    copy: 'Copy',
  },
};
