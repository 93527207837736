import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { Typography, styled } from '@mui/material';

export const StyledContentEditable = styled(ContentEditable, {
  shouldForwardProp: propName => propName !== 'fixedHeight',
})<{ fixedHeight?: boolean }>(({ theme, fixedHeight }) => ({
  height: fixedHeight ? '82px' : '100%',
  width: '100%',
  padding: `0 ${theme.spacing(1)} ${theme.spacing(0.5)}`,
  border: 'none',
  borderRadius: theme.shape.borderRadius,
  background: theme.palette.white,
  overflow: 'auto',
  '&:hover': {
    border: 'none',
  },
  '&:focus': {
    border: 'none',
    outline: 'none',
  },
}));

export const EditorWrapper = styled('div', {
  shouldForwardProp: propName => propName !== 'editing' && propName !== 'focused' && propName !== 'backgroundColor',
})<{ editing?: boolean; focused?: boolean; backgroundColor?: string }>(({ theme, editing, focused, backgroundColor }) => ({
  position: 'relative',
  width: '100%',
  background: backgroundColor ?? theme.palette.white,
  border: editing ? `2px solid ${theme.palette.grey[focused ? 700 : 300]}` : 'none',
  borderRadius: theme.shape.borderRadius,
  '& .editor-paragraph': { margin: '0', marginTop: '8px', position: 'relative' },
  '& .editor-bold': { fontWeight: 'bold' },
  '& .editor-italic': { fontStyle: 'italic' },
  '& .editor-underline': { textDecoration: 'underline' },
  '&:hover': {
    border: editing ? `2px solid ${theme.palette.grey[focused ? 700 : 500]}` : 'none',
  },
  '&:focus': {
    border: editing ? `2px solid ${theme.palette.grey[700]}` : 'none',
    outline: 'none',
  },
}));

export const Placeholder = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: '10px',
  paddingLeft: theme.spacing(1),
  userSelect: 'none',
  pointerEvents: 'none',
}));

export const StyledContentNonEditable = styled(ContentEditable, {
  shouldForwardProp: propName =>
    propName !== 'inline' && propName !== 'overflowValue' && propName !== 'isAlignText' && propName !== 'isBorder',
})<{ inline?: boolean; overflowValue?: string; isAlignText?: boolean; isBorder?: boolean }>(
  ({ theme, inline, overflowValue, isAlignText, isBorder }) => ({
    width: inline || isAlignText || isBorder ? '100%' : 'fit-content',
    height: 'auto',
    borderRadius: theme.shape.borderRadius,
    border: isBorder ? '2px solid #3b3b3b4d' : 'none',
    padding: '1px 8px 8px',
    color: theme.palette.black,
    '& .editor-paragraph': { position: 'unset' },
    '& > p': {
      margin: 0,
      overflow: overflowValue,
      width: inline ? '100%' : 'unset',
      whiteSpace: inline ? 'nowrap' : 'unset',
      textOverflow: inline ? 'ellipsis' : 'unset',
    },
  }),
);
