import { Check, FiberManualRecord } from '@mui/icons-material';
import { IconButton, IconButtonProps, Stack, ToggleButton, ToggleButtonProps, Tooltip, Typography, styled, useTheme } from '@mui/material';
import { ChangeEvent, FC, MouseEventHandler, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Color, ColorChangeHandler, ColorResult, SwatchesPicker } from 'react-color';
import { useTranslation } from 'react-i18next';

import PopperPanel from '../PopperPanel/PopperPanel';

import { useColorPickerStyles } from './ColorPicker.styles';

const colorsDefaultProp: string[][] = [];

const TransparentSelector = styled(Stack)(({ theme }) => ({
  width: 40,
  height: 24,
  border: `1px solid ${theme.palette.divider}`,
  cursor: 'pointer',
  backgroundImage: `repeating-linear-gradient(45deg, ${theme.palette.divider} 25%, transparent 25%, transparent 75%, ${theme.palette.divider} 75%, ${theme.palette.divider}), repeating-linear-gradient(45deg, ${theme.palette.divider} 25%, ${theme.palette.background.default} 25%, ${theme.palette.background.default} 75%, ${theme.palette.divider} 75%, ${theme.palette.divider})`,
  backgroundPosition: `0 0, ${theme.spacing(1)} ${theme.spacing(1)}`,
  backgroundSize: `${theme.spacing(2)} ${theme.spacing(2)}`,
}));

type P = Partial<Omit<IconButtonProps & ToggleButtonProps, 'value'>>;
export interface ColorPickerProps extends P {
  value?: Color;
  onColorChange?: (newColor: ColorResult, e?: ChangeEvent<HTMLInputElement>) => void;
  colors?: string[][];
  icon?: ReactElement;
  stopPropagation?: boolean;
  tooltip?: string;
  variant?: 'icon' | 'toggle';
  enableTransparent?: boolean;
}

const ColorPicker: FC<ColorPickerProps> = ({
  value = '',
  onColorChange = undefined,
  colors = colorsDefaultProp,
  icon = undefined,
  stopPropagation = false,
  tooltip = undefined,
  variant = 'icon',
  enableTransparent = false,
  ...props
}) => {
  const { t } = useTranslation('colorPicker');
  const { gradients } = useTheme().palette;
  const classes = useColorPickerStyles();

  const [pickerOpen, setPickerOpen] = useState<boolean>(false);
  const [colorSelected, setColorSelected] = useState<Color>(value);
  const [colorPickerSize, setColorPickerSize] = useState({
    width: 0,
    height: 0,
  });
  const pickerAnchorEl = useRef<HTMLButtonElement | null>(null);
  const allColors = useMemo(() => (colors.length ? colors : Object.values(gradients).slice(0, 10)), [colors, gradients]);

  const togglePicker = useCallback<MouseEventHandler>(
    e => {
      if (stopPropagation && e?.stopPropagation) {
        e.stopPropagation();
      }
      setPickerOpen(prevPickerOpen => !prevPickerOpen);
    },
    [stopPropagation],
  );

  const handleColorChange = useCallback<ColorChangeHandler>(
    (newColor, e) => {
      if (stopPropagation && e?.stopPropagation) {
        e.stopPropagation();
      }
      if (onColorChange) {
        onColorChange(newColor, e);
        setPickerOpen(false);
      }
    },
    [stopPropagation, onColorChange],
  );

  const handleChangeTransparent: MouseEventHandler<HTMLDivElement> = useCallback(
    event => {
      if (stopPropagation) {
        event.stopPropagation();
      }
      if (onColorChange && enableTransparent) {
        onColorChange({
          hex: 'none',
          hsl: { h: 0, s: 0, l: 100, a: 0 },
          rgb: { r: 255, g: 255, b: 255, a: 0 },
        });
        setPickerOpen(false);
      }
    },
    [enableTransparent, onColorChange, stopPropagation],
  );

  useEffect(() => {
    setColorSelected(value ?? '');
  }, [value]);

  const setSize = useCallback(() => {
    const arrCols = allColors.length;
    const arrRows = allColors[0]?.length;
    const coefHeight = Math.ceil(arrCols / 5);
    const cols = arrCols >= 5 ? 5 : arrCols;
    const height = coefHeight * (25 * arrRows + 10) + 22;
    const width = 50 * cols + 22;
    setColorPickerSize({ width, height });
  }, [allColors]);

  useEffect(() => {
    setSize();
  }, [setSize]);

  const handleClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    e => {
      if (stopPropagation && e?.stopPropagation) {
        e.stopPropagation();
      }
    },
    [stopPropagation],
  );

  return (
    <>
      <Tooltip title={props.disabled ? '' : (tooltip ?? t('tooltip.changeColor'))}>
        {variant === 'toggle' ? (
          <ToggleButton {...props} ref={pickerAnchorEl} aria-label="color picker" value="null" onClick={togglePicker}>
            {icon ?? <FiberManualRecord style={{ color: value as string }} />}
          </ToggleButton>
        ) : (
          <span>
            <IconButton {...props} ref={pickerAnchorEl} onClick={togglePicker}>
              {icon ?? <FiberManualRecord style={{ color: value as string }} />}
            </IconButton>
          </span>
        )}
      </Tooltip>
      <PopperPanel
        anchorEl={pickerAnchorEl.current ?? undefined}
        open={pickerOpen}
        placement={{ vertical: 'bottom', horizontal: 'left' }}
        onClickAway={togglePicker}
      >
        <div
          aria-label="color picker"
          role="button"
          style={{ outline: 'none', overflow: 'hidden', height: colorPickerSize.height }}
          tabIndex={-1}
          onClick={handleClick}
          onKeyPress={undefined}
        >
          <SwatchesPicker
            className={classes.container}
            color={colorSelected}
            colors={allColors}
            height={colorPickerSize.height}
            width={colorPickerSize.width}
            onChange={handleColorChange}
          />
        </div>
        {enableTransparent && (
          <Stack alignItems="center" direction="row" spacing={1} sx={{ ml: 2, mb: 2 }}>
            <TransparentSelector alignItems="center" justifyContent="center" onClick={handleChangeTransparent}>
              {value === null && <Check />}
            </TransparentSelector>
            <Typography variant="body1">{t('text.transparent')}</Typography>
          </Stack>
        )}
      </PopperPanel>
    </>
  );
};

export default ColorPicker;
