import { Grid, Typography } from '@mui/material';
import { ChangeEvent, ChangeEventHandler, Dispatch, FC, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { BacnetDeviceConfig } from '@dametis/core';

import AdvancedTextFieldWithMask from 'components/UI/AdvancedTextFieldWithMask/AdvancedTextFieldWithMask';
import MaskFormat from 'components/UI/InputMask/MaskFormat';
import AdvancedNumberTextField from 'components/UI/Inputs/AdvancedTextField/AdvancedNumberTextField';

import FormPaper, { FormLocation } from '../FormPaper';

export interface BacnetSettingsFormProps {
  bacnet: BacnetDeviceConfig;
  setBacnet: Dispatch<SetStateAction<BacnetDeviceConfig>>;
  isEditing?: boolean;
  location?: `${FormLocation}`;
}

const BacnetSettingsForm: FC<BacnetSettingsFormProps> = ({ bacnet, setBacnet, isEditing = true, location = 'modal' }) => {
  const { t } = useTranslation('devices');

  const handleChangeHost: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (!setBacnet) {
        return;
      }
      setBacnet(state => ({ ...state, host: event.target.value }));
    },
    [setBacnet],
  );

  const handleChangePort = useCallback(
    (event: ChangeEvent<HTMLInputElement>, newValue: number) => {
      if (!setBacnet) {
        return;
      }
      setBacnet(state => ({ ...state, port: newValue }));
    },
    [setBacnet],
  );

  const handleChangeInstance = useCallback(
    (event: ChangeEvent<HTMLInputElement>, newValue: number) => {
      if (!setBacnet) {
        return;
      }
      setBacnet(state => ({ ...state, instance: newValue }));
    },
    [setBacnet],
  );

  const handleChangeLoopTime = useCallback(
    (event: ChangeEvent<HTMLInputElement>, newValue: number) => {
      if (!setBacnet) {
        return;
      }
      setBacnet(state => ({ ...state, loopTime: newValue }));
    },
    [setBacnet],
  );

  return (
    <FormPaper location={location} sx={{ p: 1.5 }}>
      <Typography mb={1.5} variant="h6">
        {t('title.acquisition')}
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={9}>
          <AdvancedTextFieldWithMask
            fullWidth
            required
            editing={isEditing}
            label={t('label.ip')}
            maskedInputProps={MaskFormat.ip}
            value={bacnet.host ?? ''}
            onChange={handleChangeHost}
          />
        </Grid>
        <Grid item xs={3}>
          <AdvancedNumberTextField
            fullWidth
            required
            editing={isEditing}
            InputProps={{ inputProps: { min: 0 } }} // SLE: check that
            label={t('label.port')}
            parser={inputValue => parseInt(inputValue, 10)}
            value={bacnet.port}
            onChange={handleChangePort}
          />
        </Grid>
        <Grid item xs={6}>
          <AdvancedNumberTextField
            fullWidth
            required
            editing={isEditing}
            InputProps={{ inputProps: { step: 1 } }} // SLE: check that
            label={t('label.instance')}
            parser={inputValue => parseInt(inputValue, 10)}
            value={bacnet.instance}
            onChange={handleChangeInstance}
          />
        </Grid>
        <Grid item xs={6}>
          <AdvancedNumberTextField
            fullWidth
            required
            editing={isEditing}
            InputProps={{ inputProps: { step: 100, min: 1000 } }} // SLE: check that // SLE: this step does not count in validator
            label={t('label.loopTime')}
            parser={inputValue => parseInt(inputValue, 10)}
            value={bacnet.loopTime}
            onChange={handleChangeLoopTime}
          />
        </Grid>
      </Grid>
    </FormPaper>
  );
};

export default BacnetSettingsForm;
