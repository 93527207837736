export enum BetterPrsLocation {
  PLAYGROUNDS = 'playgrounds',
  REPORTS = 'reports',
  SYNOPTICS = 'synoptics',
  PROJECTS = 'projects',
  OTHER = 'other',
}

export const isBetterPrsLocation = (location: any): location is BetterPrsLocation => {
  return typeof location === 'string' && Object.values(BetterPrsLocation).includes(location as BetterPrsLocation);
};

export const enum BetterPrsPanel {
  LEFT = 'left',
  RIGHT = 'right',
}

export const enum OpenReplayEvent {
  // NAV
  OPEN_GROUP_SITE_MENU = 'openGroupSiteMenu',
  OPEN_DRAWER_MENU = 'openDrawerMenu',

  // REPORT
  REPORT_LOAD_TIME = 'reportLoadTime',

  // VNC
  VNC_OPEN = 'vncOpen',
  VNC_FILTER_PHYSICAL_QUANTITY = 'vncFilterPhysicalQuantity',
  VNC_FILTER_OTHER = 'vncFilterOther',
  VNC_SLATE_OPERATOR_MENU_CLICK = 'vncSlateOperatorMenuClick',
  VNC_SLATE_OPERATOR_LEFT_CLICK = 'vncSlateOperatorLeftClick',
  VNC_SLATE_VARIABLE_RIGHT_CLICK = 'vncSlateVariableRightClick',
  VNC_SLATE_OPERATOR_CHANGING = 'vncSlateOperatorChanging',
  VNC_HISTORY_INSERTING_ELEMENT = 'vncHistoryInsertingElement',
  VNC_SUBMIT_WITHOUT_MULTISELECT = 'vncSubmitWithoutMultiselect',
  VNC_SUBMIT_WITH_MULTISELECT = 'vncSubmitWithMultiselect',

  // BETTER PRS
  BETTER_PRS_PANELS_INITIAL_STATE = 'betterPrsPanelsInitialState',
  BETTER_PRS_DRAWERS_INITIAL_STATE = 'betterPrsDrawersInitialState',
  BETTER_PRS_TOGGLE_PANEL_CLICK = 'betterPrsTogglePanelClick',
  BETTER_PRS_TOGGLE_DRAWER_CLICK = 'betterPrsToggleDrawerClick',
  BETTER_PRS_USE_FILTER = 'betterPrsUseFilter',
  BETTER_PRS_USE_GROUPBY = 'betterPrsUseGroupBy',
}
