import { GridLocaleText } from '@mui/x-data-grid-premium';

import { FileDeviceSourceType, MQTTPropertyMatcherType } from '@dametis/core';

import { CsvParserStep } from 'components/Configuration/Variables/ImportCalculatedVariablesModal/flam';
import { BoxVersionStatus } from 'types/box';

export default {
  title: {
    variables: 'Variables',
    devices: 'Équipements',
    export: 'Exporter',
    newVariable: 'Nouvelle variable',
    editVariable: 'Modifier la variable',
    editVariable_other: 'Modifier {{count}} variables',
    variableDetails: 'Détails de la variable',
    newDevice: 'Nouvel équipement',
    editDevice: 'Modifier un équipement',
    deleteDevice_one: 'Supprimer un équipement',
    exportVariables: 'Variables',
    exportPeriodicity: 'Période',
    editExp: "Modification d'une variable calculée",
    editExpLabel: 'Quand les modifications doivent-elles prendre effet ?',
    noDelete: 'Suppression impossible',
    newBox: 'Nouvelle box',
    groupSettings: 'Paramètres du groupe',
    groupBoxes: 'Boxes',
    createList: 'Nouvelle liste',
    renameList: 'Renommer la liste',
    preview: 'Aperçu',
    importCalculatedVariablesModal: 'Importer des variables calculées',
    editBoxAlertThresholds: "Modifier les seuils d'alerte de la box",
    connections: 'Connexions',
    exploration: 'Exploration',
    accessibility: 'Accessibilité',
  },
  text: {
    requests: 'Requêtes',
    modbusTcp: 'Modbus',
    opcUa: 'OpcUa',
    sql: 'SQL',
    file: 'Fichier',
    s7: 'S7',
    http: 'HTTP',
    bacnet: 'BACnet',
    mqtt: 'MQTT',
    calculated: 'Calculée',
    real: 'Acquise',
    manual: 'Manuelle',
    constant: 'Constante',
    options: 'Options',
    number: 'Nombre',
    string: 'Chaîne de caractères',
    boolean: 'Booléen',
    selectedVariables_one: 'une variable sélectionnée',
    selectedVariables_other: '{{count}} variables sélectionnées',
    deleteDevice: 'Êtes-vous sûr de vouloir supprimer {{name}} ?',
    customName: 'Équipement Libre',
    previewDisabled: 'Prévisualisation temporairement désactivée pour les tableaux transposés de plus de 100 000 points',
    uploadFileError: {
      incorrectType: 'Type du fichier incorrect, seulement JSON et CSV sont acceptés.',
      parsingError: 'Une erreur est survenue pendant le traitement du fichier, veuillez réessayer plus tard.',
      invalidStructure: 'Le fichier CSV est invalide, téléchargez un modèle pour vérifier la structure requise.',
    },
    allDevices: 'Tous les équipements',
    dateStart: 'Date de début',
    dateEnd: 'Date de fin',
    interval: 'Intervalle',
    unit: 'Unité',
    lastSync: 'Dernière synchronisation : {{datetime}}',
    version: {
      [BoxVersionStatus.SUCCESS]: 'Votre box Dametis est synchronisée',
      [BoxVersionStatus.DEFAULT]: 'La box Dametis est en cours de synchronisation',
      [BoxVersionStatus.WARNING]: 'Votre box Dametis est actuellement hors ligne. Elle se synchronisera à sa prochaine connexion.',
      [BoxVersionStatus.ERROR]: 'La synchronisation avec la box Dametis a échoué. Veuillez contacter le support.',
    },
    deleteVariable:
      "La suppression de cette variable peut affecter le fonctionnement des outils (Rapports, Synoptiques, Playgrounds, etc...) l'utilisant. Vérifiez son usage avant sa suppression",
    noOptions: 'Aucune option',
    clear: 'Vider',
    close: 'Fermer',
    variables: 'Variables',
    open: 'Ouvrir',
    addTagOption: "Ajouter l'option « {{name}} »",
    editExp: {
      confirm: 'les données de {{name}} seront calculées avec :',
      name: 'cette variable',
      dateConfirm: 'à partir du {{date}}, ',
    },
    thisVar: 'cette variable',
    oldExp: 'Ancienne expression',
    newExp: 'Nouvelle expression',
    editExpConfirm: {
      date: 'à partir du {{date}}, ',
      name: 'les données de {{name}} seront calculées avec :',
    },
    from: {
      now: "Les données déjà calculées jusqu'à maintenant seront conservées (ancienne expression).\nLa nouvelle expression prendra effet pour toutes les nouvelles données.",
      date: "Les données déjà calculées jusqu'à la date choisie seront conservées (ancienne expression).\nLa nouvelle expression prendra effet pour toutes les données à partir de cette date.",
      first: 'Toutes les données seront recalculées avec la nouvelle expression.',
    },
    noDelete_one: "Impossible de supprimer cet équipement tant qu'il possède des variables ({{count}} variable associée).",
    noDelete_other: "Impossible de supprimer cet équipement tant qu'il possède des variables ({{count}} variables associées).",
    realVariableInfo: "Une variable acquise est une variable dont les données sont reçues à partir d'un équipement",
    calculatedVariableInfo: "Une variable calculée est une variable basée sur d'autres variables, et dont l'expression détermine sa valeur",
    manualVariableInfo: 'Une variable manuelle est une variable dont les valeurs seront définies suite à un import manuel',
    constantVariableInfo: 'Une variable constante est une variable dont la valeur ne change pas',
    variablesImport: 'Import de variables',
    importVariablesInfo: 'Chargez un fichier de variables respectant un des modèles suivants :',
    importFileTemplatesInfo_one: 'Chargez un fichier respectant le modèle suivant :',
    importFileTemplatesInfo_other: 'Chargez un fichier respectant un des modèles suivants :',
    notAvailable: 'Option non disponible',
    identification: 'Identification',
    dialect: 'Dialecte',
    group: 'Groupe/Site',
    hardwareInfo: 'Materiel',
    lanUsine: 'Lan Usine',
    lanOut: 'Lan Out',
    stepper: {
      step: 'Étape',
      step2: 'Remplissez les informations',
      step3: 'Configuration IP',
      step4: 'Routes personnalisées (optionnel)',
    },
    customRoute: 'Route personnalisée',
    users: 'Utilisateurs',
    noUser: 'Aucun utilisateurs.',
    form: 'Formulaire',
    import: 'Importer',
    canNotChangeKind: "Vous ne pouvez pas modifier le type d'une variable dupliquée.",
    noSelectedVariable: 'Aucune variable sélectionné.',
    activities: 'Activités',
    lastModification: 'dernière modification {{date}}',
    csvExample: {
      name: 'EXEMPLE',
      description: 'supprimez cette ligne',
      utilities: 'séparez vos tags par des / ',
    },
    thisMonth: 'Ce mois-ci',
    thisWeek: 'Cette semaine',
    connectedWithoutInterruption_zero: 'Connectée sans interruption',
    connectedWithoutInterruption_other: 'Deconnectée pendant {{duration}}',
    poweredWithoutInterruption_zero: 'Alimentée sans interruption',
    poweredWithoutInterruption_other: 'Non-alimentée pendant {{duration}}',
    boxStatisticTitle: 'État de la box du {{from}} au {{to}}',
    noVariableSelected: 'Aucune variable sélectionnée',
    noVariableList: 'Aucune liste de variables',
    selectList: 'Sélectionner une liste',
    importList: "Listes d'import",
    exportList: "Listes d'export",
    invalidExport: 'Aperçu indisponible : sélectionnez une liste de variables et une période valide',
    invalidExportVar: 'Aperçu indisponible : aucune liste de variables sélectionnée',
    invalidExportPeriod: 'Aperçu indisponible : aucune période valide sélectionnée',
    boxHealthCheckProgress: "Vérification de l'état de la box en cours...",
    boxSetupProgress: 'Préparation de la box en cours...',
    selectFile: 'Déposez un fichier ou cliquez pour en choisir un',
    dropFile: 'Déposez le fichier ici',
    fileNotAllowed: "Ce type de fichier n'est pas autorisé",
    successCreateCalculatedVariable: 'Variables calculées importées avec succès',
    errorCreateCalculatedVariable: "Une erreur est survenue pendant l'import",
    errors_one: '1 erreur',
    errors_other: '{{count}} erreurs',
    endpoints: 'URLs',
    authentication: 'Authentification',
    jqTransformer: 'Transformateur JQ',
    date: 'Date',
    propertyMatcher: 'Correspondance des propriétés',
    endpoint: 'URL',
    acquisition: 'Acquisition',
    availableVariables: '{{count}} / {{total}} variables disponibles',
    damcoBoxDelay: 'Fréquence attendue : {{label}}',
    monitoring: 'Monitoring - Seuil des alertes',
    connection: 'Connexion',
    templateVariables: 'Variables de template',
    sqlModeExplanation: {
      DEFAULT:
        'Dans le mode par défaut, on définit une requête par variable. On peut également créer des variables globales utilisables dans ces requêtes',
      BULK: "Dans le mode Bulk, on définit une requête globale dans l'équipement, chaque variable est ensuite récupérée grâce à sa référence.",
      TEMPLATE:
        "Dans le mode Template, on définit une requête globale dans l'équipement, cette requête contient des variables de template qui seront peuplées lors de la configuration des variables.",
    },
    availableTemplateVariables: 'Variables disponibles',
    globalVariables: 'Variables globales',
    noBox: 'Aucune boxe',
    accessVariableLists: 'Accéder aux listes de variables',
    newList: 'Nouvelle liste',
    localDate: 'Date locale',
    value: 'Valeur',
    mqttPropertyMatcherExplanation: {
      [MQTTPropertyMatcherType.MAP_ON_TOPIC]: 'En mode Topic, chaque variable a son topic pour référence.',
      [MQTTPropertyMatcherType.MAP_ON_PAYLOAD]:
        'En mode Message, la référence est contenue dans le message et identifiée grace à la correspondance de propriétés.',
    },
    startExploration: "Démarrer une session d'exploration",
    explorationExplanation: "Le module d'exploration permet d'explorer des équipements dans le contexte de la boxe.",
    connectBeforeExplore: "Connectez-vous avant d'explorer le serveur.",
    createVariables: 'Créer des variables',
    exploration: 'Exploration',
    fetch: 'Lire',
    accessibilityInfo: "Vérifiez l'accessibilité de l'hôte avant de continuer.",
  },
  input: {
    name: 'Nom',
    uuid: 'UUID',
    lastValue: 'Dernière valeur',
    reference: 'Référence',
    description: 'Description',
    utilities: 'Utilités',
    defaultValue: 'Valeur par défaut',
    unit: 'Unité',
    nodeId: 'Node Id',
    address: 'Adresse',
    offset: 'Offset',
    offsetDescription: "Décalage de l'adresse des variables",
    offsetData: 'Décalage',
    loopTime: 'Boucle (ms)',
    loopTimeDescription: 'Temps de la boucle totale, en ms',
    pauseAfterRequest: 'Pause',
    pauseAfterRequestDescription: 'Temps de pause entre deux requêtes modbus, en ms',
    maxRegisterToReadByRequest: 'Mots max.',
    maxRegisterToReadByRequestDescription: "Nombre maximal de mots qu'une requête peut récupérer",
    maxUnusedValue: 'Mots inutiles',
    maxUnusedValueDescription: 'Nombre maximal de mots inutiles autorisés pour la conception des requêtes',
    dataType: 'Type',
    function: 'Fonction',
    transformer: 'Transformateur',
    transformerType: 'Type de transformateur',
    transformerValue: 'Valeur du transformateur',
    physicalQuantity: 'Grandeur physique',
    kind: 'Type de variable',
    comment: 'Commentaire',
    favorite: 'Favori',
    accumulate: 'Accumulateur',
    warning: 'Alerte',
    expression: 'Expression',
    params: 'Expression',
    modbusTcp: 'Modbus',
    sql: 'SQL',
    file: 'Fichier',
    opcUa: 'OpcUa',
    mqtt: 'MQTT',
    enabled: 'Activé',
    alarm: 'Alarme',
    value: 'Valeur',
    progress: 'Progression',
    version: 'Version',
    type: 'Type',
    hostModbusTcp: 'IP',
    hostOpcUa: 'Hôte',
    hostSQL: 'IP',
    endpointMode: 'Avec endpoint',
    responseTime: 'Temps de réponse',
    port: 'Port',
    rack: 'Rack',
    slot: 'Slot',
    slotInfo: {
      S71200CPU: '0 ou 1',
      S71500CPU: '0 ou 1',
      CP343: '0 (ou voir la configuration du matériel)',
    },
    timeout: 'Timeout',
    localTSAP: 'TSAP local',
    remoteTSAP: 'TSAP distant',
    protocol: 'Protocole',
    uid: 'Unit ID',
    device: 'Équipement',
    variables: 'Variables',
    prefix: 'Préfixe',
    suffix: 'Suffixe',
    transformerType_multiplier: 'Multiplicateur',
    transformerType_linearInterpolation: 'Interpolation linéaire',
    bit: 'Bit',
    search: 'Rechercher...',
    searchDevicePlaceholder: 'Recherche par nom/IP ...',
    invertDoubleWords: 'Double mots inversés',
    invertWords: 'Mots inversés',
    invertBytes: 'Octets inversés',
    sensorName: 'Nom du capteur',
    periodicityUnit: {
      seconds: 'Secondes',
      minutes: 'Minutes',
      hours: 'Heures',
      days: 'Jours',
      weeks: 'Semaines',
    },
    nowLabel: 'Maintenant',
    dateLabel: 'Depuis une date',
    firstLabel: 'Depuis le début',
    none: 'Aucun',
    placeholderUtilities: 'Utilité',
    placeholderPQ: 'Grandeur physique',
    placeholderKind: 'Type de variable',
    server: 'Serveur',
    endpoint: 'Endpoint',
    securityPolicy: 'Politique de sécurité',
    securityMode: 'Mode de sécurité',
    userIdentityInfo: {
      type: 'Type',
      userName: "Nom d'utilisateur",
      password: 'Mot de passe',
      certificateData: 'Certificat',
      privateKey: 'Clé privée',
    },
    credentials: {
      username: "Nom d'utilisateur",
      password: 'Mot de passe',
    },
    dialect: 'Dialecte',
    request: 'Requête',
    machineId: 'Machine ID',
    group: 'Groupe',
    site: 'Site',
    mode: 'Mode',
    ip: 'Ip',
    mask: 'Masque',
    gateway: 'Passerelle',
    lan: 'Lan',
    subnet: 'Sous-réseau',
    emptySite: 'Tous les sites',
    transpose: 'Transposer',
    shortHost: "Alias Nom d'hôte",
    vpnIp: 'Ip VPN',
    vpnPort: 'Port SSH',
    rsshPort: 'Port RSSH',
    sshClientAlive: 'Maintenir le client SSH connecté',
    addresses: "Plage d'adresses ip",
    mtu: 'MTU',
    nameservers: 'Serveurs de nom',
    from: 'Depuis',
    to: 'Vers',
    via: 'Via',
    boxName: 'Nom de la box',
    boxSetupIp: 'Ip locale de la box',
    date: 'Date',
    timeZone: 'Time Zone',
    dateFormat: 'Format de la date',
    NONE: 'Aucune',
    AUTHORIZATION_HEADER: 'Authorization Header',
    BASIC: 'Basic',
    QUERY_PARAM: 'Query param',
    password: 'Mot de passe',
    username: "Nom d'utilisateur",
    key: 'Nom du paramètre',
    result: 'Résultat',
    authorizationHeader: 'Authorization Header',
    cron: 'Expression cron (UTC)',
    method: 'Méthode HTTP',
    path: 'Chemin',
    schema: 'Schéma',
    id: 'ID',
    instance: 'Instance',
    filterPastPoints: 'Filtrer les points passés',
    syncPoint: 'Point de Sync',
    contentType: 'Type de contenu',
    excel: 'Excel',
    boxDataMaxDelay: 'Fréquence attendue entre deux remontées de données de la box',
    defaultVariableDataDelay: "Fréquence attendue entre deux remontées de données de l'équipement",
    deviceHeartbeatFrequency: "Fréquence attendue entre deux exécutions de l'équipement",
    defaultVariableFrequency: "Fréquence attendue entre deux points d'une variable (par défaut)",
    mute: 'Sourdine',
    database: 'Base de donnée',
    queryStrategy: {
      BULK: 'Bulk',
      DEFAULT: 'Défaut',
      TEMPLATE: 'Template',
    },
    variable: 'Variable',
    variableKey: 'Clé',
    logLevel: 'Journalisation',
    host: 'Hôte',
    topic: 'Topic',
    propertyMatcherType: {
      [MQTTPropertyMatcherType.MAP_ON_TOPIC]: 'Topic',
      [MQTTPropertyMatcherType.MAP_ON_PAYLOAD]: 'Message',
    },
    count: 'Nombre',
  },
  button: {
    ok: 'Ok',
    cancel: 'Annuler',
    applyToAll: 'Appliquer à toutes',
    duplicate: 'Dupliquer',
    recompute: 'Recalculer',
    rename: 'Renommer',
    create: 'Créer',
    new: 'Nouveau',
    save: 'Enregistrer',
    edit: 'Modifier',
    test: 'Tester',
    variable: 'Variable',
    newDevice: 'Équipement',
    delete: 'Supprimer',
    disable: 'Désactiver',
    enable: 'Activer',
    yes: 'Oui',
    no: 'Non',
    uploadFileLabel: 'Téléverser un Fichier',
    uploadFile: 'JSON / CSV',
    downloadFileTemplateLabel: 'Télécharger un exemple {{type}}',
    downloadFileTemplate: 'Modèle {{type}}',
    selectAll: 'Tout sélectionner',
    deselectAll: 'Tout désélectionner',
    download: 'Télécharger',
    add: 'Ajouter',
    addVariable: 'Variable',
    addList: 'Liste',
    refresh: 'Rafraîchir',
    next: 'Suivant',
    back: 'Précédent',
    confirm: 'Confirmer',
    exportVariables: 'dametis_{{group}}_{{site}}_{{date}}',
    newBox: 'Box',
    scan: 'Scanner',
    close: 'Fermer',
    hideInactiveUsers: 'Cacher les utilisateurs inactifs',
    hideDameticianUsers: 'Cacher les utilisateurs Dametis',
    hideHiddenUsers: 'Cacher les utilisateurs cachés',
    clearAllSelection: 'Vider',
    import: 'Importer',
    previous: 'Précédent',
    downloadTemplateCsv: 'Télécharger un exemple .csv',
    syncBox: 'Forcer la synchronisation',
    addEndpoint: 'Ajouter un serveur',
    run: 'Lancer',
    sourceType: {
      [FileDeviceSourceType.FILESYSTEM]: 'Fichier (Ancien)',
      [FileDeviceSourceType.MINIO]: 'Amazon S3',
      [FileDeviceSourceType.SFTP]: 'SFTP',
      [FileDeviceSourceType.SFTP_INTERNAL]: 'SFTP Dametis',
    },
    startSession: 'Démarrer la session',
    createVariable_one: 'Créer {{count}} variable',
    createVariable_other: 'Créer {{count}} variables',
    connect: 'Se connecter',
    readBuffer: 'Lire le buffer',
    start: 'Démarrer',
    checkPort: 'Vérifier le port',
  },
  label: {
    username: "Nom d'utilisateur",
    password: 'Mot de passe',
    host: 'Hôte',
    port: 'Port',
    path: 'Chemin',
    existingFileImport: 'Configurations existantes',
    source: 'Source de données',
    useSSL: 'Connexion sécurisée',
    accessKey: 'Access Key ID',
    secretKey: 'Secret Access Key',
    bucket: 'Bucket',
    internalSftpCreds: 'Vos identifiants SFTP Dametis',
    ssl: 'Connexion sécurisée',
  },
  helper: {
    favorite: 'Indiquez si cette variable est importante',
    accumulate: "Indiquez si cette variable est un compteur (chaque valeur vient s'ajouter à la précédente)",
    alarm: 'Indiquez si un problème sur cet équipement doit alerter les équipes DAMETIS',
    warning: 'Indiquez un problème de configuration',
    hasWarning: 'Problème de configuration',
    editedVariable_one: '{{count}} modification non enregistrée',
    editedVariable_other: '{{count}} modifications non sauvegardées',
    indicativeValues: 'Valeurs indicatives',
  },
  xgrid: {
    noRowsLabel: 'Aucune variable',
    noRowsLabelImport: 'Aucune donnée',
    noDevicesLabel: 'Aucun équipement',
    errorOverlayDefaultLabel: 'Une erreur est survenue.',
    toolbarDensity: 'Présentation',
    toolbarDensityLabel: 'Présentation',
    toolbarDensityCompact: 'Compacte',
    toolbarDensityStandard: 'Standard',
    toolbarDensityComfortable: 'Large',
    toolbarColumns: 'Colonnes',
    toolbarColumnsLabel: 'Afficher le sélecteur de colonnes',
    toolbarFilters: 'Filtres',
    toolbarFiltersLabel: 'Afficher les filtres',
    toolbarFiltersTooltipHide: 'Masquer les filtres',
    toolbarFiltersTooltipShow: 'Afficher les filtres',
    // toolbarFiltersTooltipActive: (count) => `${count} active filter(s)`,
    columnsPanelTextFieldLabel: 'Chercher une colonne',
    columnsPanelTextFieldPlaceholder: 'Nom de colonne',
    columnsPanelDragIconLabel: 'Réorganiser les colonnes',
    columnsPanelShowAllButton: 'Afficher tout',
    columnsPanelHideAllButton: 'Masquer tout',
    filterPanelAddFilter: 'Ajouter un filtre',
    filterPanelDeleteIconLabel: 'Supprimer',
    filterPanelOperators: 'Opérateur',
    filterPanelOperatorAnd: 'Et',
    filterPanelOperatorOr: 'Ou',
    filterPanelColumns: 'Colonne',
    columnMenuLabel: 'Menu',
    columnMenuShowColumns: 'Afficher les colonnes',
    columnMenuFilter: 'Filtrer',
    columnMenuHideColumn: 'Masquer',
    columnMenuUnsort: 'Ne pas trier',
    columnMenuSortAsc: 'Trier par ordre croissant',
    columnMenuSortDesc: 'Trier par ordre décroissant',
    // columnHeaderFiltersTooltipActive: (count) => `${count} active filter(s)`,
    columnHeaderFiltersLabel: 'Afficher les filtres',
    columnHeaderSortIconLabel: 'Trier',
    // footerRowSelected: (count) =>
    //   count !== 1
    //     ? `${count.toLocaleString()} rows selected`
    //     : `${count.toLocaleString()} row selected`,
    footerTotalRows: 'Total variables :',
    footerPaginationRowsPerPage: 'Variables par page :',
    footerTotalRowsImport: 'Total données :',
    footerTotalDevices: 'Total équipements :',
    footerPaginationDevicesPerPage: 'Équipements par page :',
    filterOperatorContains: 'contient',
    filterOperatorEquals: 'vaut',
    filterOperatorStartsWith: 'commence par',
    filterOperatorEndsWith: 'finit par',
    filterOperatorIs: 'est',
    filterOperatorNot: "n'est pas",
    // filterOperatorAfter: 'is after',
    // filterOperatorOnOrAfter: 'is on or after',
    // filterOperatorBefore: 'is before',
    // filterOperatorOnOrBefore: 'is on or before',
    filterPanelInputLabel: 'Valeur',
    filterPanelInputPlaceholder: 'Valeur du filtre',
    toolbarExport: 'Exporter',
    toolbarExportLabel: 'Exporter',
    toolbarExportCSV: 'Télécharger le CSV',
    toolbarExportExcel: 'Télécharger au format Excel',
    pinToLeft: 'Épingler à gauche',
    pinToRight: 'Épingler à droite',
    unpin: 'Désépingler',
  } as Partial<GridLocaleText>,
  graph: {
    internet: 'Internet',
    power: 'Alimentation',
  },
  tooltip: {
    createBox: 'créer une box',
    createDevice: 'créer un équipement',
    exportVariables: 'exporter des variables',
    editBoxAlertThresholds: "Modifier les seuils d'alerte de la box",
    editDevice: "Modifier l'équipement",
    resetDevice: "Changer d'équipement",
    emptyVariableList: 'Supprimer la liste de variables',
    editVariables: 'modifier les variables',
    addVariable: 'ajouter une variable',
    addTemplateVariable: 'Ajouter une variable de template',
    editExp: 'modifier les anciennes valeurs',
    deleteList: 'Supprimer la liste',
    saveList: 'Enregistrer la liste',
    createList: 'Créer une liste',
    seePassword: 'Voir le mot de passe',
    hidePassword: 'Masquer le mot de passe',
    copy: 'Copier',
    addEndpoint: 'Ajouter une URL',
    addGlobalVariable: 'Ajouter une variable globale',
  },
  messageSecurityMode: {
    Invalid: 'Invalide',
    None: 'Aucun',
    Sign: 'Signé',
    SignAndEncrypt: 'Signé et chiffré',
  },
  securityPolicy: {
    None: 'Aucune',
    Invalid: 'Invalide',
  },
  userTokenType: {
    Anonymous: 'Anonyme',
    Certificate: 'Certificat',
    UserName: 'Utilisateur',
  },
  toast: {
    successRecompute: 'Demande prise en compte, recalcul en cours...',
    copyToClipboard: 'UUID de la variable copié dans le presse-papier.',
    valueCopyToClipboard: 'La valeur à été copié dans le presse-papier',
  },
  boxItem: {
    boxVersion: 'Version',
    kernelVersion: 'Kernel',
    lanUsine: 'LAN USINE',
    lanOut: 'LAN OUT',
    ip: 'IP',
    gateway: 'Passerelle',
    undefined: 'Indéfini',
    mode: 'Mode',
  },
  grid: {
    interface: 'Interface',
    from: 'Depuis',
    to: 'Vers',
    via: 'Via',
    priority: 'Priorité (MTU)',
  },
  error: {
    noSelectedDevice: "Vous devez sélectionner un équipement avant d'importer des variables",
  },
  tagAutocomplete: {
    newTag: 'Nouveau tag',
  },
  legend: {
    connected: 'Connectée',
    partiallyDisconnected: 'Partiellement déconnectée',
    disconnected: 'Déconnectée',
  },
  table: {
    columns: {
      variable: 'Variable',
      date: 'Date',
    },
  },
  stepper: {
    importing: 'Importation',
    creating: 'Création',
  },
  loader: {
    [CsvParserStep.PARSING]: 'Analyse du fichier...',
    [CsvParserStep.BUILDING]: 'Création des variables...',
    [CsvParserStep.POSTING]: 'Ajout dans la base de données...',
  },
};
