export default {
  title: {
    debuggus: 'Debuggus',
  },
  text: {
    dependencies: 'Dependencies variables',
    dependants: 'Dependants variables',
    toInspect: 'Variable to inspect',
    general: 'General',
    properties: 'Properties',
    expression: 'Expression',
    graph: 'Graph',
    unknown: 'Unknown',
    noVariables: "You haven't selected a variable yet.",
    uuid: 'UUID:',
    name: 'Name:',
    owner: 'Owner:',
    createdAt: 'Created At:',
    updatedAt: 'Updated At:',
    progress: 'Progress:',
    chunks: 'Chunks:',
    from: 'From',
    to: 'To',
    retryAt: 'Retry At',
    action: 'Action',
    isDone: 'Is Done',
    isLive: 'Is Live',
    jobUuid: 'Job UUID',
    lastSeen: 'Last point:',
    lastValue: 'Last value:',
    alarmUuid: 'Alarm UUID',
    batchUuid: 'Batch UUID',
    raw: 'Raw:',
    changeOnly: 'Change only:',
    accumulate: 'Accumulate:',
    rootPointToPoint: 'Root point to point:',
    delay: 'Delay:',
    syncMad: 'Median absolute deviation (regularity):',
    lastSync: 'Last Sync:',
    version: 'Version',
    tree: 'Tree',
  },
  toast: {
    readError: 'Error reading data from api',
    copyToClipboard: "Variable's UUID copied to clipboard",
    copyToClipboardJSON: 'JSON copied to clipboard',
  },
  buttons: {
    vnc: 'VNC',
    json: 'JSON',
    chunks: 'CHUNKS',
    close: 'Close',
    copy: 'Copy',
    refresh: 'Refresh',
    recomputeDependants: 'Recompute',
  },
  tooltip: {
    buttonJSON: 'Display data in JSON format',
    recomputeDependants: 'Recompute the dependants variables',
    copy: 'Copy the UUID',
  },
  input: {
    label: {
      message: 'Message',
    },
  },
};
