import { ArrowRight } from '@mui/icons-material';
import { List, ListItem, ListItemText, Typography } from '@mui/material';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ModelInfo } from '@dametis/core';

import { CategorizedModels, useCategorizedModels } from 'components/Models/useCategorizedModels';
import { useModels } from 'store/api/models';
import { useVncStore } from 'zustand/stores/vnc';

import { ListItemButtonWithArrow } from '../BlockDiagramList/ListItemButtonWithArrow';

const emptyModels: ModelInfo[] = [];

const YVarsColumn: FC = () => {
  const { t } = useTranslation('vnc');

  const { data: allModels = emptyModels } = useModels();

  const categories = useCategorizedModels(allModels);

  const selectedCategory = useVncStore(state => state.models.selectedCategory);
  const setSelectedCategory = useVncStore(state => state.setSelectedCategory);

  const selectModels = useCallback(
    (category: keyof CategorizedModels) => () => {
      setSelectedCategory(category);
    },
    [setSelectedCategory],
  );

  return (
    <List>
      {categories.map(([category]) => (
        <ListItemButtonWithArrow key={category} disableGutters selected={category === selectedCategory} onClick={selectModels(category)}>
          <ListItemText primary={category} />
          <ArrowRight />
        </ListItemButtonWithArrow>
      ))}
      {categories.length === 0 && (
        <ListItem>
          <Typography variant="subtitle2">{t('text.noModel')}</Typography>
        </ListItem>
      )}
    </List>
  );
};

export default YVarsColumn;
