import { EntitiesGroupsView } from 'components/UI/Entities/EntitiesGroups/EntitiesGroupsViewPicker/useEntitiesGroupsViewLocalStorage';
import { EntityType } from 'types/entity';

export default {
  title: {
    favoriteEntities: 'Mis favoritos',
    myEntities: {
      [EntityType.ALARM]: 'Mis alarmas',
      [EntityType.PLAYGROUND]: 'Mis playgrounds',
      [EntityType.PROJECT]: 'Mis proyectos',
      [EntityType.REPORT]: 'Mis informes',
      [EntityType.SYNOPTIC]: 'Mis sinópticos',
    },
    sharedEntities: 'Compartidos',
    privateEntities: 'Privados',
    untaggedEntities: 'Sin etiqueta',
    quickAccess: 'Acceso rápido',
    filters: 'Filtros',
    groupBy: 'Agrupar por',
    sortBy: 'Ordenar por',
    settings: 'Ajustes',
    leftDrawer: 'Filtros',
    layout: 'Vista',
    rightDrawer: 'Acceso rápido',
    noTags: 'Sin etiqueta',
    noOwner: 'Sin propietario',
  },
  groupBy: {
    all: 'Todos',
    sharings: 'Compartidos',
    tags: 'Etiquetas',
    owner: 'Propietario',
  },
  sortBy: {
    nameAsc: 'Nombre A-Z',
    nameDesc: 'Nombre Z-A',
    createdAtAsc: 'Creado más antiguo',
    createdAtDesc: 'Creado más reciente',
    updatedAtAsc: 'Modificado más antiguo',
    updatedAtDesc: 'Modificado más reciente',
  },
  placeholder: {
    groupBy: 'Agrupar por',
    sortBy: 'Ordenar por',
    owners: 'Buscar usuario...',
  },
  tooltip: {
    expandAll: 'Expandir todo',
    collapseAll: 'Contraer todo',
    hideFilters: 'Ocultar filtros',
    displayFilters: 'Mostrar filtros',
    open: 'Abrir',
    close: 'Cerrar',
    sortByOrder: {
      asc: 'Ascendente',
      desc: 'Descendente',
    },
    reset: 'Restablecer',
  },
  text: {
    createdBy: 'Creado por {{name}}',
    updatedAt: 'Actualizado {{date}}',
    noEntity: 'Sin elementos',
    loading: 'Cargando',
    emptyGroup: 'No hay entidades en este grupo',
    noResults: 'Tu búsqueda no produjo resultados',
    noShortcuts: 'Sin accesos directos',
  },
  button: {
    backToTop: 'Haut de la page',
    closeLeftPanel: 'Cerrar filtros',
    openLeftPanel: 'Filtros',
    leftPanel: 'Filtros',
    closeRightPanel: 'Cerrar acceso rápido',
    openRightPanel: 'Acceso rápido',
    rightPanel: 'Acceso rápido',
    expandAll: 'Expandir todo',
    collapseAll: 'Contraer todo',
    reset: 'Restablecer',
  },
  label: {
    search: 'Buscar',
    tags: 'Etiquetas',
    groupBy: 'Agrupar por',
    sortBy: 'Ordenar por',
    allEntities: 'Todas las entidades',
    me: 'Yo',
    owners: 'Propietario',
    favorites: 'Favoritos',
  },
  view: {
    [EntitiesGroupsView.TILES]: 'Mosaicos',
    [EntitiesGroupsView.GRID]: 'Tabla',
  },
  toast: {
    defaultGridViewChanged: 'Vista predeterminada cambiada.',
  },
};
