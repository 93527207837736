import { ArrowRight } from '@mui/icons-material';
import { FC, Fragment } from 'react';

export interface ChipPathProps {
  path: string[];
  displayEndArrow?: boolean;
}

const ChipPath: FC<ChipPathProps> = ({ path, displayEndArrow = false }) => (
  <>
    {path.map((parentName, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Fragment key={index}>
        <>{parentName}</>
        {(displayEndArrow || index !== path.length - 1) && <ArrowRight sx={{ verticalAlign: 'text-top', fontSize: 'unset' }} />}
      </Fragment>
    ))}
  </>
);

export default ChipPath;
