import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockTypeInfo, BlockTypeMetadata, UUID, UpdateBlockTypeBody } from '@dametis/core';

import { createUpdateBlockTypeBody } from 'components/Lego/helpers/blockType/createUpdateBlockTypeBody';
import StepperModal from 'components/UI/StepperModal/StepperModal';
import { useDispatch } from 'store';
import { useUpdateBlockTypeMutation } from 'store/api/blockTypes';
import { addToast } from 'store/slices/toast';
import { ToastSeverity } from 'types';

import ConnectionsStep from '../CreateBlockTypeModal/ConnectionsStep';
import GeneralStep from '../CreateBlockTypeModal/GeneralStep';
import MetadataListsStep, { MetadataListName } from '../CreateBlockTypeModal/MetadataListsStep';
import MetricsStep from '../CreateBlockTypeModal/MetricsStep';
import ParametersStep from '../CreateBlockTypeModal/ParametersStep';
import { getIsConnectionsStepValid } from '../CreateBlockTypeModal/helpers/getIsConnectionsStepValid';
import { getIsGeneralStepValid } from '../CreateBlockTypeModal/helpers/getIsGeneralStepValid';
import { getIsMetadataListsStepValid } from '../CreateBlockTypeModal/helpers/getIsMetadataListsStepValid';
import { getIsMetricsStepValid } from '../CreateBlockTypeModal/helpers/getIsMetricsStepValid';
import { getIsParametersStepValid } from '../CreateBlockTypeModal/helpers/getIsParametersStepValid';

export interface EditBlockTypeModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  folderId?: UUID;
  blockType: BlockTypeInfo;
  onUpdate?: (updatedBlockType: BlockTypeInfo) => Promise<void> | void;
}

const EditBlockTypeModal: FC<EditBlockTypeModalProps> = ({ isOpen, setIsOpen, blockType, folderId = undefined, onUpdate = undefined }) => {
  const { t } = useTranslation('lego');
  const dispatch = useDispatch();

  const [updateBlockType] = useUpdateBlockTypeMutation();

  const [updateBlockTypeBody, setUpdateBlockTypeBody] = useState<UpdateBlockTypeBody>(createUpdateBlockTypeBody);
  const [metadataList, setMetadataList] = useState<BlockTypeMetadata[]>([]);
  const [listNameByMetadataId, setListNameByMetadataId] = useState<Record<UUID, MetadataListName>>({});

  const isGeneralStepValid = useMemo(() => getIsGeneralStepValid(updateBlockTypeBody), [updateBlockTypeBody]);

  const isMetadataListsStepValid = useMemo(() => getIsMetadataListsStepValid(metadataList), [metadataList]);

  const isParametersStepValid = useMemo(() => getIsParametersStepValid(updateBlockTypeBody), [updateBlockTypeBody]);

  const isConnectionsStepValid = useMemo(() => getIsConnectionsStepValid(updateBlockTypeBody), [updateBlockTypeBody]);

  const isMetricsStepValid = useMemo(() => getIsMetricsStepValid(updateBlockTypeBody), [updateBlockTypeBody]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const handleSubmit = useCallback(async () => {
    try {
      const blockTypeBody: UpdateBlockTypeBody = {
        ...updateBlockTypeBody,
        blockMetadataList: metadataList.filter(metadata => listNameByMetadataId[metadata.uuid] === MetadataListName.BLOCK),
        standardMetadataList: metadataList.filter(metadata => listNameByMetadataId[metadata.uuid] === MetadataListName.STANDARD),
      };

      const data = await updateBlockType({ uuid: blockType?.uuid, body: blockTypeBody }).unwrap();
      if (onUpdate) {
        await onUpdate(data);
      }
      setIsOpen(false);
      dispatch(addToast({ message: t('toast.updateBlockTypeSuccess'), severity: ToastSeverity.SUCCESS }));
    } catch (error) {
      console.error(error);
    }
  }, [blockType, updateBlockTypeBody, metadataList, dispatch, setIsOpen, t, updateBlockType, onUpdate, listNameByMetadataId]);

  useEffect(() => {
    if (isOpen) {
      setUpdateBlockTypeBody(createUpdateBlockTypeBody({ ...blockType, folderId }));
      setMetadataList([...blockType.standardMetadataList, ...blockType.blockMetadataList]);
      const newListNameByMetadataId: Record<UUID, MetadataListName> = {};
      blockType.standardMetadataList.forEach(metadata => {
        newListNameByMetadataId[metadata.uuid] = MetadataListName.STANDARD;
      });
      blockType.blockMetadataList.forEach(metadata => {
        newListNameByMetadataId[metadata.uuid] = MetadataListName.BLOCK;
      });
      setListNameByMetadataId(newListNameByMetadataId);
    }
  }, [isOpen, blockType, folderId]);

  const steps = useMemo(
    () => [
      {
        label: t('stepper.general'),
        component: <GeneralStep<UpdateBlockTypeBody> blockTypeBody={updateBlockTypeBody} setBlockTypeBody={setUpdateBlockTypeBody} />,
        isInvalid: !isGeneralStepValid,
      },
      {
        label: t('stepper.metadatas'),
        component: (
          <MetadataListsStep
            listNameByMetadataId={listNameByMetadataId}
            metadataList={metadataList}
            setListNameByMetadataId={setListNameByMetadataId}
            setMetadataList={setMetadataList}
          />
        ),
        isInvalid: !isMetadataListsStepValid,
      },
      {
        label: t('stepper.parameters'),
        component: <ParametersStep<UpdateBlockTypeBody> blockTypeBody={updateBlockTypeBody} setBlockTypeBody={setUpdateBlockTypeBody} />,
        isInvalid: !isParametersStepValid,
      },
      {
        label: t('stepper.connections'),
        component: <ConnectionsStep<UpdateBlockTypeBody> blockTypeBody={updateBlockTypeBody} setBlockTypeBody={setUpdateBlockTypeBody} />,
        isInvalid: !isConnectionsStepValid,
      },
      {
        label: t('stepper.metrics'),
        component: <MetricsStep<UpdateBlockTypeBody> blockTypeBody={updateBlockTypeBody} setBlockTypeBody={setUpdateBlockTypeBody} />,
        isInvalid: !isMetricsStepValid,
      },
    ],
    [
      isConnectionsStepValid,
      isGeneralStepValid,
      isMetadataListsStepValid,
      isMetricsStepValid,
      isParametersStepValid,
      listNameByMetadataId,
      metadataList,
      t,
      updateBlockTypeBody,
    ],
  );

  return (
    <StepperModal
      nonLinear
      open={isOpen}
      steps={steps}
      title={t('title.editBlockTypeModal')}
      onClose={handleClose}
      onSubmit={handleSubmit}
    />
  );
};

export default EditBlockTypeModal;
