import accountInitialization from './accountInitialization';
import acquisition from './acquisition';
import activities from './activities';
import admin from './admin';
import advancedEntry from './advancedEntry';
import alarm from './alarm';
import alarms from './alarms';
import backups from './backups';
import batch from './batch';
import batches from './batches';
import billing from './billing';
import calculationMenu from './calculationMenu';
import codeEditor from './codeEditor';
import colorPicker from './colorPicker';
import comment from './comment';
import configuration from './configuration';
import corporate from './corporate';
import dachart from './dachart';
import data from './data';
import dataFilter from './dataFilter';
import dataHistory from './dataHistory';
import dateTimePicker from './dateTimePicker';
import debuggus from './debuggus';
import delayPicker from './delayPicker';
import device from './device';
import devices from './devices';
import editData from './editData';
import energies from './energies';
import entities from './entities';
import entitySelector from './entitySelector';
import entityTile from './entityTile';
import errors from './errors';
import fileImport from './fileImport';
import filters from './filters';
import form from './form';
import format from './format';
import global from './global';
import gridViewPicker from './gridViewPicker';
import groupBy from './groupBy';
import groupSettings from './groupSettings';
import header from './header';
import home from './home';
import iconPicker from './iconPicker';
import importParser from './importParser';
import invitations from './invitations';
import kbm from './kbm';
import lego from './lego';
import lexical from './lexical';
import login from './login';
import macro from './macro';
import macroLib from './macroLib';
import macros from './macros';
import manualEntry from './manualEntry';
import model from './model';
import models from './models';
import monitoring from './monitoring';
import myAccount from './myAccount';
import navbar from './navbar';
import newsletter from './newsletter';
import notify from './notify';
import password from './password';
import period from './period';
import playground from './playground';
import playgrounds from './playgrounds';
import popup from './popup';
import projects from './projects';
import projectstasks from './projectstasks';
import projectstemplate from './projectstemplate';
import prompts from './prompts';
import reports from './reports';
import routes from './routes';
import share from './share';
import shortcut from './shortcut';
import siteSettings from './siteSettings';
import smoothing from './smoothing';
import stats from './stats';
import steamMixes from './steamMixes';
import stepModal from './stepModal';
import styledRule from './styledRule';
import synoptics from './synoptics';
import table from './table';
import tags from './tags';
import toast from './toast';
import unit from './unit';
import upload from './upload';
import validations from './validations';
import variables from './variables';
import vnc from './vnc';

export default {
  accountInitialization,
  acquisition,
  activities,
  admin,
  advancedEntry,
  alarm,
  alarms,
  backups,
  batch,
  batches,
  billing,
  calculationMenu,
  codeEditor,
  colorPicker,
  configuration,
  corporate,
  fileImport,
  dachart,
  data,
  dataHistory,
  dataFilter,
  dateTimePicker,
  debuggus,
  delayPicker,
  device,
  devices,
  editData,
  energies,
  entities,
  entitySelector,
  entityTile,
  errors,
  filters,
  form,
  format,
  global,
  gridViewPicker,
  groupBy,
  groupSettings,
  header,
  home,
  iconPicker,
  importParser,
  invitations,
  kbm,
  lego,
  lexical,
  login,
  macro,
  macroLib,
  macros,
  manualEntry,
  model,
  models,
  monitoring,
  myAccount,
  navbar,
  newsletter,
  comment,
  password,
  period,
  playground,
  playgrounds,
  popup,
  projects,
  projectstasks,
  projectstemplate,
  prompts,
  reports,
  routes,
  share,
  shortcut,
  siteSettings,
  stepModal,
  styledRule,
  synoptics,
  table,
  tags,
  toast,
  unit,
  upload,
  validations,
  stats,
  steamMixes,
  variables,
  vnc,
  notify,
  smoothing,
};
