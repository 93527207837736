import {
  CreateStandardBlockBody,
  FolderInfo,
  ShortStandardBlockInfo,
  ShortcutCategory,
  StandardBlockInfo,
  StandardBlockUsagesInfo,
  UUID,
  UpdateStandardBlockBody,
} from '@dametis/core';

import { sdk } from 'sdk';
import { useSelector } from 'store';

import { api } from './index';

export const standardBlocksApi = api.injectEndpoints({
  endpoints: build => ({
    readStandardBlocks: build.query<ShortStandardBlockInfo[], void>({
      providesTags: ['StandardBlocks'],
      queryFn: async () => {
        try {
          const { data } = await sdk.standardBlock.List();
          return { data };
        } catch (error) {
          return { data: [], error };
        }
      },
    }),
    readStandardBlocksUsage: build.query<StandardBlockUsagesInfo, { standardBlockId: string }>({
      providesTags: ['StandardBlocks'],
      queryFn: async ({ standardBlockId }) => {
        try {
          const { data } = await sdk.standardBlock.ListUsages(standardBlockId);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    createStandardBlock: build.mutation<StandardBlockInfo, CreateStandardBlockBody>({
      invalidatesTags: ['StandardBlocks', 'GlobalFolders'],
      queryFn: async standardBlockBody => {
        try {
          const { data } = await sdk.standardBlock.Create(standardBlockBody);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    duplicateStandardBlock: build.mutation<StandardBlockInfo, { uuid: UUID; name: string; folderId?: UUID }>({
      invalidatesTags: ['StandardBlocks', 'GlobalFolders'],
      queryFn: async ({ uuid, name, folderId }) => {
        try {
          const { data } = await sdk.standardBlock.Duplicate(uuid, { name, folderId });
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    updateStandardBlock: build.mutation<StandardBlockInfo, { uuid: UUID; body: UpdateStandardBlockBody }>({
      invalidatesTags: ['StandardBlocks', 'Blocks'],
      queryFn: async ({ uuid, body }) => {
        try {
          const { data } = await sdk.standardBlock.Update(uuid, body);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    moveStandardBlock: build.mutation<FolderInfo, { standardBlockId: UUID; sourceId: UUID; targetId: UUID }>({
      invalidatesTags: ['StandardBlocks', 'GlobalFolders'],
      queryFn: async ({ standardBlockId, sourceId, targetId }) => {
        try {
          const { data } = await sdk.folder.UpdateInGlobal(sourceId, {
            operation: {
              name: 'move',
              params: {
                sourceFolderId: sourceId,
                targetFolderId: targetId,
                shortcut: { category: ShortcutCategory.STANDARD_BLOCK, uuid: standardBlockId },
              },
            },
          });
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    deleteStandardBlock: build.mutation<void, UUID>({
      invalidatesTags: ['StandardBlocks', 'GlobalFolders'],
      queryFn: async standardBlockId => {
        try {
          const { data } = await sdk.standardBlock.Delete(standardBlockId);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    // restoreStandardBlock: build.mutation<UUID, { activityId: UUID; standardBlockId: UUID }>({
    //   invalidatesTags: ['StandardBlocks'],
    //   queryFn: async ({ activityId, standardBlockId }) => {
    //     try {
    //       await sdk.standardBlock.Restore(standardBlockId, activityId);
    //       return { data: standardBlockId };
    //     } catch (error) {
    //       console.error(error);
    //       return { error };
    //     }
    //   },
    // }),
  }),
});

export const {
  useReadStandardBlocksUsageQuery,
  useCreateStandardBlockMutation,
  useMoveStandardBlockMutation,
  useDeleteStandardBlockMutation,
  useDuplicateStandardBlockMutation,
  useUpdateStandardBlockMutation,
} = standardBlocksApi;

export const selectStandardBlocks: typeof standardBlocksApi.endpoints.readStandardBlocks.select = () =>
  standardBlocksApi.endpoints.readStandardBlocks.select();

export const useStandardBlocks: typeof standardBlocksApi.useReadStandardBlocksQuery = (arg, options) => {
  const siteId = useSelector(state => state.auth.selectedSite?.uuid);

  // for now blocks are not accessible in corpo
  return standardBlocksApi.useReadStandardBlocksQuery(arg, options ?? { skip: !siteId });
};
