import { Energies, Operator, PhysicalQuantity, ProtocolKind, Timestamp } from '@dametis/core';

import { EntityTitleCategory } from 'hooks/useEntityTitle';

import { VarCalcKinds } from '../../functions/tada/helpers.ts';

const energy: Record<Energies, string> = {
  [Energies.WATER]: 'Water',
  [Energies.ELECTRICITY]: 'Electricity',
  [Energies.FUEL]: 'Fuel Oil',
  [Energies.GAS]: 'Gas',
  [Energies.PRODUCT]: 'Product',
  [Energies.OTHER]: 'Other',
  [Energies.AIR]: 'Air',
  [Energies.COOLANT_FLUID]: 'Coolant fluid',
  [Energies.COLD]: 'Cold',
  [Energies.STEAM]: 'Steam',
  [Energies.COMPRESSED_AIR]: 'Compressed air',
};

const variableType: Record<VarCalcKinds, string> = {
  dataVariable: 'Configured variable',
  pointsVariable: 'Points variable',
  batchVariable: 'Batch variable',
  blockVariable: 'Block variable',
  calculationVariable: 'Calculation',
  blockTypeMetricVariable: 'Block type metric variable',
  blockTypeParameterVariable: 'Block type parameter variable',
  modelVariable: 'Model variable',
  alarmVariable: 'Alarm variable',
  aliasVariable: 'Alias variable',
};

const timestamp: Record<Timestamp, string> = {
  [Timestamp.START]: 'Start',
  [Timestamp.MIDDLE]: 'Middle',
  [Timestamp.END]: 'End',
  [Timestamp.KEEP]: 'Keep',
};

export default {
  text: {
    pageTitleEntity: '{{name}} - Dametis',
    [`pageTitleEntity_${EntityTitleCategory.ALARM}`]: '{{name}} - Alarm - Dametis',
    [`pageTitleEntity_${EntityTitleCategory.PLAYGROUND}`]: '{{name}} - Playground - Dametis',
    [`pageTitleEntity_${EntityTitleCategory.PROJECT}`]: '{{name}} - Project - Dametis',
    [`pageTitleEntity_${EntityTitleCategory.REPORT}`]: '{{name}} - Report - Dametis',
    [`pageTitleEntity_${EntityTitleCategory.SYNOPTIC}`]: '{{name}} - Synoptic - Dametis',
    [`pageTitleEntity_${EntityTitleCategory.MODEL}`]: '{{name}} - Model - Dametis',
    loading: 'Loading',
    noValue: 'n/a',
    appliedValue: 'Applied value: {{value}}',
    loadingValue: '...',
    noElement: 'No element',
    unknownVariable: 'Unknown variable',
    unknownBlock: 'Unknown block',
    unknownModel: 'Unknown model',
    unknownBatch: 'Unknown batch',
    subtitle: {
      createdAt: 'Created {{date}}',
      createdAtFeminine: 'Created {{date}}',
      createdAtBy: 'Created {{date}} by {{owner}}',
      createdAtByFeminine: 'Created {{date}} by {{owner}}',
      createdBy: 'Created by {{owner}}',
      createdByFeminine: 'Created by {{owner}}',
      updatedAt: 'Last modification {{date}}',
    },
    health: {
      title: 'Health check',
      lastChecked: 'Last checked {{duration}}.',
      systemsHealthy: ' All Systems Operational.',
      systemsUnhealthy: 'Some systems are unhealthy.',
      details: 'Details',
    },
  },
  number: {
    toLocale: '{{value, localized}}',
  },
  unit: {
    input: {
      label: 'Unit',
      placeholder: 'None',
    },
    time_millisecond_one: 'millisecond',
    time_millisecond_other: 'milliseconds',
    time_second_one: 'second',
    time_second_other: 'seconds',
    time_minute_one: 'minute',
    time_minute_other: 'minutes',
    time_hour_one: 'hour',
    time_hour_other: 'hours',
    time_day_one: 'day',
    time_day_other: 'days',
    time_week_one: 'week',
    time_week_other: 'weeks',
    time_month_one: 'month',
    time_month_other: 'months',
    time_year_one: 'year',
    time_year_other: 'years',
    time_millisecond_short_one: 'ms',
    time_millisecond_short_other: 'ms',
    time_second_short_one: 's',
    time_second_short_other: 's',
    time_minute_short_one: 'm',
    time_minute_short_other: 'm',
    time_hour_short_one: 'h',
    time_hour_short_other: 'h',
    time_day_short_one: 'd',
    time_day_short_other: 'd',
    time_week_short_one: 'w',
    time_week_short_other: 'w',
    time_month_short_one: 'mo',
    time_month_short_other: 'mo',
    time_year_short_one: 'yr',
    time_year_short_other: 'yr',
  },
  date: {
    month: {
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'October',
      november: 'November',
      december: 'December',
      january_short: 'Jan',
      february_short: 'Feb',
      march_short: 'Mar',
      april_short: 'Apr',
      may_short: 'May',
      june_short: 'Jun',
      july_short: 'Jul',
      august_short: 'Aug',
      september_short: 'Sep',
      october_short: 'Oct',
      november_short: 'Nov',
      december_short: 'Dec',
    },
    at: ' at ',
  },
  physicalQuantity: {
    pQ_none: 'none',
    pQ_other: 'other',
    pQ_force: 'force',
    pQ_pressure: 'pressure',
    pQ_energy: 'energy',
    pQ_specificEnergy: 'specific energy',
    pQ_power: 'power',
    pQ_temperature: 'temperature',
    pQ_speed: 'speed',
    pQ_acceleration: 'acceleration',
    pQ_density: 'density',
    pQ_time: 'time',
    pQ_mass: 'mass',
    pQ_massDensity: 'mass density',
    pQ_volume: 'volume',
    pQ_flow: 'flow',
    pQ_dewPoint: 'dew point',
    pQ_humidity: 'humidity',
    pQ_thermalConductivity: 'thermal conductivity',
    pQ_voltage: 'voltage',
    pQ_current: 'current',
    pQ_frequency: 'frequency',
    pQ_length: 'length',
    pQ_surface: 'surface',
    pQ_currency: 'currency',
    pQ_fileSize: 'file size',
    pQ_viscosity: 'viscosity',
  } as Record<`pQ_${PhysicalQuantity}`, string>,
  operator: {
    op_MIN: 'min',
    op_MAX: 'max',
    op_FIRST: 'first value',
    op_LAST: 'last value',
    op_INDEX: 'index',
    op_HISTORY: 'value',
    op_MEAN: 'mean',
    op_ARITHMETIC_MEAN: 'arithmetic mean',
    op_DELTA: 'delta',
    op_SELECT: 'value',
    op_INTEGRAL: 'integral',
    op_LEFT_RECTANGLE_INTEGRAL: 'left rectangle integral',
    op_TRAPEZOIDAL_INTEGRAL: 'trapezoidal integral',
    op_DERIVATIVE: 'derivative',
    op_STDDEV: 'standard deviation',
    op_GAP: 'gap',
    op_VARIANCE: 'variance',
    op_MOVING_AVERAGE: 'moving average',
    op_SUM: 'sum',
    op_TIME_WEIGHTED_MEAN: 'time weighted mean',
    op_COUNT: 'count',
    op_DISTINCT: 'distinct',
    op_TIME: 'time',
    op_GROUP_BY_DURATION: 'period duration',
    op_LINEAR_CADENCE: 'linear cadence',
    opShort_MIN: 'min',
    opShort_MAX: 'max',
    opShort_FIRST: 'first',
    opShort_LAST: 'last',
    opShort_INDEX: 'index',
    opShort_HISTORY: 'value',
    opShort_MEAN: 'mean',
    opShort_ARITHMETIC_MEAN: 'amean',
    opShort_DELTA: 'delta',
    opShort_SELECT: 'value',
    opShort_INTEGRAL: 'integ',
    opShort_LEFT_RECTANGLE_INTEGRAL: 'rinteg',
    opShort_TRAPEZOIDAL_INTEGRAL: 'tinteg',
    opShort_DERIVATIVE: 'deriv',
    opShort_STDDEV: 'stdev',
    opShort_GAP: 'gap',
    opShort_VARIANCE: 'var',
    opShort_MOVING_AVERAGE: 'ma',
    opShort_SUM: 'sum',
    opShort_TIME_WEIGHTED_MEAN: 'twm',
    opShort_COUNT: 'count',
    opShort_DISTINCT: 'dist',
    opShort_TIME: 'time',
    opShort_GROUP_BY_DURATION: 'dur',
    opShort_LINEAR_CADENCE: 'linear',
  } as Record<`op_${Operator}` | `opShort_${Operator}`, string>,
  button: {
    add: 'Add',
    edit: 'Edit',
    delete: 'Delete',
    regenerate: 'Regenerate',
    save: 'Save',
    cancel: 'Cancel',
    disable: 'Disable',
    enable: 'Enable',
    duplicate: 'Duplicate',
    previous: 'Previous',
    next: 'Next',
  },
  tooltip: {
    moreOptions: 'More options',
    changeCountry: 'Change country',
    edit: 'Edit',
    delete: 'Delete',
    add: 'Add',
    hide: 'Hide',
    show: 'Show',
    return: 'Return',
    noRight: 'You do not have the right to ',
    editMin: 'edit ',
    deleteMin: 'delete ',
    addMin: 'add ',
    duplicateMin: 'duplicate ',
    disableMin: 'disable ',
    enableMin: 'enable ',
    regenerateMin: 'regenerate ',
    shareMin: 'share ',
    back: 'Back',
    close: 'Close',
    rename: 'rename',
    cancel: 'cancel',
    submit: 'submit',
    details: 'Show details',
    refresh: 'Refresh',
    learnMore: 'Learn more',
  },
  protocol: {
    modbusTcp: 'Modbus TCP',
    opcUa: 'OPC UA',
    opcDa: 'OPC DA',
    sql: 'SQL',
    file: 'File',
    s7: 'Siemens S7',
    http: 'HTTP',
    bacnet: 'BACnet',
    mqtt: 'MQTT',
    ethernetIp: 'EtherNet/IP',
    custom: 'Custom',
  } as Record<ProtocolKind, string>,
  covariance: 'Influence index',
  energy,
  variableType,
  timestamp,
  helper: {
    min: 'min = {{min}}',
    max: 'max = {{max}}',
  },
};
