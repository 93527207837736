export enum WipFlags {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  WIP = 'WIP',
  WIP_EMAAS = 'WIP_EMAAS',
}

export enum WipFeatures {
  // Navigation
  //  Drawer && AppBar
  ENABLE_ADMIN = 'ENABLE_ADMIN',
  //  Header
  ENABLE_HEALTHCHECK = 'ENABLE_HEALTHCHECK',

  // Alarm
  //  AlarmWhenRule
  ENABLE_LEGO = 'ENABLE_LEGO',
  ENABLE_MODELS = 'ENABLE_MODELS',

  // Architecture
  //  Devices
  ENABLE_BOX_SYNC = 'ENABLE_BOX_SYNC',
  //  NewDeviceForm && DevicesGrid
  ENABLE_ALARM = 'ENABLE_ALARM',
  //  BrandDeviceSelect
  ENABLE_WIP_BRAND_DEVICES = 'ENABLE_WIP_BRAND_DEVICES',
  //  CreateDeviceModal && InformationsPanel
  ENABLE_SYNC_DELAY = 'ENABLE_SYNC_DELAY',
  ENABLE_SYNC_POINT = 'ENABLE_SYNC_POINT',

  // Variable
  //  SingleVariableData
  ENABLE_RECOMPUTE = 'ENABLE_RECOMPUTE',
  //  SingleVariablePanel
  ENABLE_DEBUG = 'ENABLE_DEBUG',
  //  SingleVariablePanel && DuplicateVariableModal
  ENABLE_VARIABLE_PREVIEW = 'ENABLE_VARIABLE_PREVIEW',

  // Project
  //  InformationModal && NewProjectForm
  ENABLE_REFERENCE_PERIOD = 'ENABLE_REFERENCE_PERIOD',

  // VNC
  //  Slate && Textarea
  ENABLE_FORMAT_SWITCH = 'ENABLE_FORMAT_SWITCH',
  //  Textarea
  ENABLE_FUNCTION = 'ENABLE_FUNCTION',
  //  Preview
  ENABLE_CALCULATION = 'ENABLE_CALCULATION',

  // UnitInput
  ENABLE_PHYSICAL_PARAMETER = 'ENABLE_PHYSICAL_PARAMETER',

  // Modèles
  WIP_MODEL = 'WIP_MODEL',
}

export const featureFlags: Record<WipFeatures, WipFlags> = {
  ENABLE_ADMIN: WipFlags.WIP_EMAAS,
  ENABLE_HEALTHCHECK: WipFlags.WIP_EMAAS,

  ENABLE_LEGO: WipFlags.WIP_EMAAS,
  ENABLE_MODELS: WipFlags.ENABLED,

  ENABLE_BOX_SYNC: WipFlags.WIP_EMAAS,
  ENABLE_ALARM: WipFlags.WIP_EMAAS,
  ENABLE_WIP_BRAND_DEVICES: WipFlags.WIP,
  ENABLE_SYNC_DELAY: WipFlags.WIP_EMAAS,
  ENABLE_SYNC_POINT: WipFlags.WIP_EMAAS,

  ENABLE_RECOMPUTE: WipFlags.WIP_EMAAS,
  ENABLE_DEBUG: WipFlags.WIP_EMAAS,
  ENABLE_VARIABLE_PREVIEW: WipFlags.WIP_EMAAS,

  ENABLE_REFERENCE_PERIOD: WipFlags.WIP_EMAAS,

  ENABLE_FORMAT_SWITCH: WipFlags.WIP_EMAAS,
  ENABLE_FUNCTION: WipFlags.WIP_EMAAS,
  ENABLE_CALCULATION: WipFlags.WIP,

  ENABLE_PHYSICAL_PARAMETER: WipFlags.WIP,

  WIP_MODEL: WipFlags.WIP,
};
