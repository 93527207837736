import { ArrowRight } from '@mui/icons-material';
import { ListItemButton, Stack, Typography, styled } from '@mui/material';
import { FC, MouseEventHandler, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { NestedTag } from '@dametis/core';

import Counter from '../Counter/Counter';
import NestedTagChip, { NestedTagChipProps } from '../TagChip/NestedTagChip';

const StyledListItemButton = styled(ListItemButton, { shouldForwardProp: prop => prop !== 'isHovered' && prop !== 'isSelected' })<{
  isSelected?: boolean;
  isHovered?: boolean;
}>(({ theme, isSelected, isHovered }) => ({
  width: '100%',
  paddingLeft: theme.spacing(1),
  ...(isSelected
    ? { background: `${theme.palette.primary.main}${isHovered ? 22 : 11}!important` }
    : { background: isHovered ? `${theme.palette.primary.main}09!important` : undefined }),
}));

export interface TagsColumnItemProps {
  tag: NestedTag;
  isDisabled: boolean;
  isHovered: boolean;
  isSelected: boolean;
  canAccessChildren: boolean;
  isNewColumnElement: boolean;
  chipProps?: Omit<NestedTagChipProps, 'tag' | 'ref'>;
  onClick?: MouseEventHandler;
  onMouseOver?: MouseEventHandler;
}

const TagsColumnItem: FC<TagsColumnItemProps> = ({
  tag,
  isDisabled,
  isHovered,
  isSelected,
  isNewColumnElement,
  onClick = undefined,
  onMouseOver = undefined,
  canAccessChildren,
  chipProps = {},
}) => {
  const { t } = useTranslation('tags');

  return (
    <StyledListItemButton
      disableGutters
      disabled={isDisabled}
      isHovered={isHovered}
      isSelected={isSelected}
      onClick={onClick}
      onMouseOver={onMouseOver}
    >
      <Stack gap={0.5} width="100%">
        {isNewColumnElement && <Typography variant="caption">{t('label.newTag')}</Typography>}
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Stack alignItems="center" direction="row" gap={0.5}>
            <NestedTagChip tag={tag} {...chipProps} />
          </Stack>
          <Stack alignItems="center" direction="row" gap={0.5}>
            {canAccessChildren && tag.children.length > 0 && <Counter count={tag.children.length} />}
            {canAccessChildren && tag.children.length > 0 && <ArrowRight fontSize="small" />}
          </Stack>
        </Stack>
      </Stack>
    </StyledListItemButton>
  );
};

export default memo(TagsColumnItem);
