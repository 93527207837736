import { Close, Done, ErrorOutlineOutlined, Tag } from '@mui/icons-material';
import { Checkbox, Stack, TextField, Typography, styled, useTheme } from '@mui/material';
import { ChangeEventHandler, FC, KeyboardEventHandler, MouseEventHandler, memo, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-key-enum';

import { useDispatch } from 'store';
import { addToast } from 'store/slices/toast';
import { ToastSeverity } from 'types';
import { useTagEditStore } from 'zustand/stores/tagEdit';

import { MAX_TAG_NAME_LENGTH } from '../TagsList';

import SimpleColorPicker from './SimpleColorPicker';

const ErrorTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
}));

export const StyledCheckbox = styled(Checkbox)({
  padding: 0,
});

export const StyledTagLabelDoneIcon = styled(Done)({
  width: '15px',
  height: '15px',
});

export const StyledTagLabelCloseIcon = styled(Close)({
  width: '15px',
  height: '15px',
});

export interface ItemLabelProps {
  tagId: string;
  name: string;
  color: string | null;
}

const ItemLabel: FC<ItemLabelProps> = ({ tagId, name, color }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation('tags');

  const isEditingTags = useTagEditStore(state => state.isEditing);
  const selectedTagIds = useTagEditStore(state => state.selectedTagIds);
  const toggleSelectedTagId = useTagEditStore(state => state.toggleSelectedTagId);
  const setEditorTag = useTagEditStore(state => state.setEditorTag);
  const addEditorOperation = useTagEditStore(state => state.addEditorOperation);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const isSelected = useMemo(() => selectedTagIds.includes(tagId), [tagId, selectedTagIds]);

  const isNameInvalid = useMemo(() => name.length > MAX_TAG_NAME_LENGTH || name.length === 0, [name]);

  const handleChangeNewLabel = useCallback<NonNullable<ChangeEventHandler<HTMLInputElement>>>(
    e => {
      try {
        const data = { uuid: tagId, name: e.target.value };
        setEditorTag(data);
        addEditorOperation({
          kind: 'update',
          data,
        });
      } catch (error) {
        console.error(error);
        dispatch(addToast({ message: t('error.updateTag'), severity: ToastSeverity.ERROR }));
      }
    },
    [tagId, setEditorTag, addEditorOperation, dispatch, t],
  );

  const handleChangeColor = useCallback(
    (newColor: string | null) => {
      try {
        const data = { uuid: tagId, color: newColor };
        setEditorTag(data);
        addEditorOperation({
          kind: 'update',
          data,
        });
      } catch (error) {
        console.error(error);
        dispatch(addToast({ message: t('error.updateTag'), severity: ToastSeverity.ERROR }));
      }
    },
    [tagId, setEditorTag, addEditorOperation, dispatch, t],
  );

  const handleClickSelection = useCallback<MouseEventHandler<HTMLInputElement>>(e => {
    e.stopPropagation();
  }, []);

  const handleLabelClick = useCallback<NonNullable<MouseEventHandler<HTMLDivElement>>>(e => {
    e.stopPropagation();
  }, []);

  const handleChangeSelection = useCallback<NonNullable<ChangeEventHandler<HTMLInputElement>>>(
    e => {
      e.stopPropagation();
      toggleSelectedTagId(tagId);
    },
    [toggleSelectedTagId, tagId],
  );

  const handleKeyDown = useCallback<NonNullable<KeyboardEventHandler<HTMLInputElement>>>(event => {
    if (event.key === Key.Enter || event.key === Key.Escape) {
      event.preventDefault();
      event.stopPropagation();
      inputRef.current?.blur();
    }
  }, []);

  return (
    <Stack alignItems="center" direction="row" spacing={1}>
      {isEditingTags && (
        <StyledCheckbox
          checked={isSelected}
          color="secondary"
          inputProps={{ onClick: handleClickSelection }}
          size="small"
          onChange={handleChangeSelection}
        />
      )}
      <SimpleColorPicker
        defaultValue={theme.palette.background.paper}
        isEditing={isEditingTags}
        value={color}
        onChange={handleChangeColor}
      />
      <Tag sx={{ color, fontSize: 15 }} />
      {isEditingTags ? (
        <TextField
          color="secondary"
          error={isNameInvalid}
          inputRef={inputRef}
          margin="none"
          size="medium"
          slotProps={{
            input: {
              sx: {
                '&:before': {
                  borderBottomColor: 'transparent',
                },
                '&:hover:not(:disabled):before': {
                  borderBottomColor: theme.palette.grey[500],
                },
              },
            },
          }}
          sx={{ width: 200 }}
          value={name}
          variant="standard"
          onChange={handleChangeNewLabel}
          onClick={handleLabelClick}
          onKeyDown={handleKeyDown}
        />
      ) : (
        <Typography variant="h6">{name}</Typography>
      )}
      {isNameInvalid && (
        <Stack alignItems="end" direction="row" ml={1} spacing={0.5}>
          <ErrorOutlineOutlined color="error" sx={{ fontSize: 15 }} />
          <ErrorTypography lineHeight={1} variant="body2">
            {t('error.invalidName', { count: name.length, max: MAX_TAG_NAME_LENGTH })}
          </ErrorTypography>
        </Stack>
      )}
    </Stack>
  );
};

export default memo(ItemLabel);
