import { Stack, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockInfo, BlockTypeInfo } from '@dametis/core';

import { BlocksOutlined } from 'assets/icons/BlocksOutlined';
import { isBlockConnectionValid } from 'components/Lego/helpers/block/isBlockConnectionValid';
import { isBlockMetadataValid } from 'components/Lego/helpers/block/isBlockMetadataValid';
import { useBlockPath } from 'hooks/useBlockPath';

import LegoPreviewPaper from '../../LegoPreviewPaper';

import BlockElementLinearProgress from './BlockElementLinearProgress';

export interface BlockPreviewProps {
  block: BlockInfo;
  blockType?: BlockTypeInfo;
  redirect?: boolean;
}

const BlockPreview: FC<BlockPreviewProps> = ({ block, blockType = undefined, redirect = false }) => {
  const { t } = useTranslation('lego');

  const blockPath = useBlockPath(block.uuid);
  const stringifiedBlockPath = useMemo(() => [t('text.home'), ...blockPath.map(path => path.name)].join(' › '), [blockPath, t]);

  const elementsProgress = useMemo(() => {
    if (!blockType) {
      return 0;
    }
    const selectedParameters = block.parameters.filter(parameter => parameter.isSelected);
    const validParameters = selectedParameters.filter(parameter => !!parameter.calculation);

    const selectedMetrics = block.metrics.filter(metric => metric.isSelected);
    const validMetrics = selectedMetrics.filter(metric => !!metric.templatedCalculation);

    const validConnections = block.connections.filter(connection => isBlockConnectionValid(connection));

    const validMetadataList = block.blockMetadataList.filter(metadata => isBlockMetadataValid(metadata));

    const totalElements = selectedParameters.length + selectedMetrics.length + block.connections.length + block.blockMetadataList.length;
    if (totalElements === 0) {
      return 0;
    }

    return ((validParameters.length + validMetrics.length + validConnections.length + validMetadataList.length) / totalElements) * 100;
  }, [block, blockType]);

  return (
    <LegoPreviewPaper
      redirect={redirect}
      sx={{ textDecoration: 'inherit', background: theme => theme.palette.background.default, p: 1 }}
      url={`/lego/${block.uuid}`}
    >
      <Stack width="100%">
        <Stack alignItems="center" direction="row" spacing={1} width="100%">
          <BlocksOutlined sx={{ color: block.ui.color }} />
          <Stack flexGrow={1} width={0}>
            <Typography variant="caption">{stringifiedBlockPath}</Typography>
            <Typography>{block.name}</Typography>
          </Stack>
        </Stack>
        {blockType && <BlockElementLinearProgress sx={{ mt: 0.5 }} value={elementsProgress} variant="determinate" />}
      </Stack>
    </LegoPreviewPaper>
  );
};

export default BlockPreview;
