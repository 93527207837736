import { FC, useMemo } from 'react';

import { ShortStandardBlockInfo } from '@dametis/core';

import { getStandardBlockUrl } from 'components/Lego/helpers/getStandardBlockUrl';
import { useReadGlobalRootFolderQuery } from 'store/api/globalFolders';

import LegoPreviewPaper from '../../LegoPreviewPaper';

import StandardBlockInnerPreview from './StandardBlockInnerPreview';

export interface StandardBlockPreviewProps {
  standardBlock: ShortStandardBlockInfo;
  redirect?: boolean;
}

const StandardBlockPreview: FC<StandardBlockPreviewProps> = ({ standardBlock, redirect = false }) => {
  const { data: globalRootFolder = null } = useReadGlobalRootFolderQuery();

  const url = useMemo(() => getStandardBlockUrl(globalRootFolder, standardBlock.uuid) ?? '#', [globalRootFolder, standardBlock.uuid]);

  return (
    <>
      <LegoPreviewPaper
        redirect={redirect}
        sx={{ textDecoration: 'inherit', background: theme => theme.palette.background.default, p: 1 }}
        url={url}
      >
        <StandardBlockInnerPreview standardBlock={standardBlock} />
      </LegoPreviewPaper>
    </>
  );
};

export default StandardBlockPreview;
