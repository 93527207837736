import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { ChangeEvent, ChangeEventHandler, Dispatch, FC, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ModbusTcpDeviceConfig } from '@dametis/core';

import AdvancedTextFieldWithMask from 'components/UI/AdvancedTextFieldWithMask/AdvancedTextFieldWithMask';
import MaskFormat from 'components/UI/InputMask/MaskFormat';
import AdvancedNumberTextField from 'components/UI/Inputs/AdvancedTextField/AdvancedNumberTextField';

import FormPaper, { FormLocation } from '../FormPaper';

export interface ModbusTcpSettingsFormProps {
  modbusTcp: ModbusTcpDeviceConfig;
  setModbusTcp: Dispatch<SetStateAction<ModbusTcpDeviceConfig>>;
  isEditing?: boolean;
  location?: `${FormLocation}`;
}

const ModbusTcpSettingsForm: FC<ModbusTcpSettingsFormProps> = ({ modbusTcp, setModbusTcp, isEditing = true, location = 'modal' }) => {
  const { t } = useTranslation('devices');

  const handleChangeHost: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (!setModbusTcp) {
        return;
      }
      setModbusTcp(state => ({ ...state, host: event.target.value }));
    },
    [setModbusTcp],
  );

  const handleChangePort = useCallback(
    (event: ChangeEvent<HTMLInputElement>, newValue: number) => {
      if (!setModbusTcp) {
        return;
      }
      setModbusTcp(state => ({ ...state, port: newValue }));
    },
    [setModbusTcp],
  );

  const handleChangeUid = useCallback(
    (event: ChangeEvent<HTMLInputElement>, newValue: number) => {
      if (!setModbusTcp) {
        return;
      }
      setModbusTcp(state => ({ ...state, uid: newValue }));
    },
    [setModbusTcp],
  );

  const handleChangeLoopTime = useCallback(
    (event: ChangeEvent<HTMLInputElement>, newValue: number) => {
      if (!setModbusTcp) {
        return;
      }
      setModbusTcp(state => ({ ...state, loopTime: newValue }));
    },
    [setModbusTcp],
  );

  const handleChangePauseAfterRequest = useCallback(
    (event: ChangeEvent<HTMLInputElement>, newValue: number) => {
      if (!setModbusTcp) {
        return;
      }
      setModbusTcp(state => ({ ...state, pauseAfterRequest: newValue }));
    },
    [setModbusTcp],
  );

  const handleChangeMaxRegisterToReadByRequest = useCallback(
    (event: ChangeEvent<HTMLInputElement>, newValue: number) => {
      if (!setModbusTcp) {
        return;
      }
      setModbusTcp(state => ({ ...state, maxRegisterToReadByRequest: newValue }));
    },
    [setModbusTcp],
  );

  const handleChangeMaxUnusedValue = useCallback(
    (event: ChangeEvent<HTMLInputElement>, newValue: number) => {
      if (!setModbusTcp) {
        return;
      }
      setModbusTcp(state => ({ ...state, maxUnusedValue: newValue }));
    },
    [setModbusTcp],
  );

  const handleChangeInvertBytes: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (!setModbusTcp) {
        return;
      }
      setModbusTcp(state => ({ ...state, invertBytes: event.target.checked }));
    },
    [setModbusTcp],
  );

  const handleChangeInvertWords: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (!setModbusTcp) {
        return;
      }
      setModbusTcp(state => ({ ...state, invertWords: event.target.checked }));
    },
    [setModbusTcp],
  );

  const handleChangeInvertDoubleWords: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (!setModbusTcp) {
        return;
      }
      setModbusTcp(state => ({ ...state, invertDoubleWords: event.target.checked }));
    },
    [setModbusTcp],
  );

  return (
    <>
      <FormPaper location={location} sx={{ p: 1.5 }}>
        <Typography mb={1.5} variant="h6">
          {t('title.acquisition')}
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <AdvancedTextFieldWithMask
              fullWidth
              required
              editing={isEditing}
              label={t('label.ip')}
              maskedInputProps={MaskFormat.ip}
              value={modbusTcp.host ?? ''}
              onChange={handleChangeHost}
            />
          </Grid>
          <Grid item xs={3}>
            <AdvancedNumberTextField
              fullWidth
              required
              editing={isEditing}
              InputProps={{ inputProps: { min: 0 } }} // SLE: check that
              label={t('label.port')}
              parser={inputValue => parseInt(inputValue, 10)}
              value={modbusTcp.port}
              onChange={handleChangePort}
            />
          </Grid>
          <Grid item xs={3}>
            <AdvancedNumberTextField
              fullWidth
              required
              editing={isEditing}
              InputProps={{ inputProps: { min: 0, max: 255 } }} // SLE: check that
              label={t('label.uid')}
              parser={inputValue => parseInt(inputValue, 10)}
              value={modbusTcp.uid}
              onChange={handleChangeUid}
            />
          </Grid>
        </Grid>
      </FormPaper>
      <FormPaper location={location} sx={{ p: 1.5 }}>
        <Typography mb={1.5} variant="h6">
          {t('title.queries')}
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <AdvancedNumberTextField
              fullWidth
              required
              description={t('description.loopTime')}
              editing={isEditing}
              InputProps={{ inputProps: { step: 100, min: 1000 } }} // SLE: check that // SLE: this step does not count in validator
              label={t('label.loopTime')}
              parser={inputValue => parseInt(inputValue, 10)}
              value={modbusTcp.loopTime}
              onChange={handleChangeLoopTime}
            />
          </Grid>
          <Grid item xs={6}>
            <AdvancedNumberTextField
              fullWidth
              required
              description={t('description.pauseAfterRequest')}
              editing={isEditing}
              InputProps={{ inputProps: { step: 100, min: 0, max: 5000 } }} // SLE: check that
              label={t('label.pauseAfterRequest')}
              parser={inputValue => parseInt(inputValue, 10)}
              value={modbusTcp.pauseAfterRequest}
              onChange={handleChangePauseAfterRequest}
            />
          </Grid>
          <Grid item xs={6}>
            <AdvancedNumberTextField
              fullWidth
              required
              description={t('description.maxRegisterToReadByRequest')}
              editing={isEditing}
              InputProps={{ inputProps: { step: 1, min: 1, max: 255 } }} // SLE: check that
              label={t('label.maxRegisterToReadByRequest')}
              parser={inputValue => parseInt(inputValue, 10)}
              value={modbusTcp.maxRegisterToReadByRequest}
              onChange={handleChangeMaxRegisterToReadByRequest}
            />
          </Grid>
          <Grid item xs={6}>
            <AdvancedNumberTextField
              fullWidth
              required
              description={t('description.maxUnusedValue')}
              editing={isEditing}
              InputProps={{ inputProps: { step: 1, min: 0, max: 255 } }} // SLE: check that
              label={t('label.maxUnusedValue')}
              parser={inputValue => parseInt(inputValue, 10)}
              value={modbusTcp.maxUnusedValue}
              onChange={handleChangeMaxUnusedValue}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={modbusTcp.invertBytes}
                  sx={!isEditing ? { pointerEvents: 'none' } : undefined}
                  onChange={isEditing ? handleChangeInvertBytes : undefined}
                />
              }
              label={t('label.invertBytes')}
              labelPlacement="end"
              sx={{ mr: 0, cursor: isEditing ? 'pointer' : 'default' }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={modbusTcp.invertWords}
                  sx={!isEditing ? { pointerEvents: 'none' } : undefined}
                  onChange={isEditing ? handleChangeInvertWords : undefined}
                />
              }
              label={t('label.invertWords')}
              labelPlacement="end"
              sx={{ mr: 0, cursor: isEditing ? 'pointer' : 'default' }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={modbusTcp.invertDoubleWords}
                  sx={!isEditing ? { pointerEvents: 'none' } : undefined}
                  onChange={isEditing ? handleChangeInvertDoubleWords : undefined}
                />
              }
              label={t('label.invertDoubleWords')}
              labelPlacement="end"
              sx={{ mr: 0, cursor: isEditing ? 'pointer' : 'default' }}
            />
          </Grid>
        </Grid>
      </FormPaper>
    </>
  );
};

export default ModbusTcpSettingsForm;
