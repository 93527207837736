import { EntityType } from 'types/entity';

export default {
  title: {
    entitySelector: 'Sélection',
    [`tab_${EntityType.ALARM}`]: 'Alarmes',
    [`tab_${EntityType.PLAYGROUND}`]: 'Playgrounds',
    [`tab_${EntityType.PROJECT}`]: 'Projets',
    [`tab_${EntityType.REPORT}`]: 'Rapports',
    [`tab_${EntityType.SYNOPTIC}`]: 'Synoptiques',
  },
  button: {
    reset: 'Réinitialiser',
    cancel: 'Annuler',
    select: 'Sélectionner',
  },
  text: {
    noSelection: 'Pas de sélection',
    selected: 'Sélectionné : {{context}}',
    selectedCount_one: '{{count}} entité sélectionnée',
    selectedCount_other: '{{count}} entités sélectionnées',
    selectedPrivate:
      "L'entité que vous avez sélectionnée est privée. Passez-la en public pour qu'elle puisse être vue par tous vos collaborateurs.",
    [`no_${EntityType.ALARM}`]: 'Aucune alarme',
    [`no_${EntityType.PLAYGROUND}`]: 'Aucun playground',
    [`no_${EntityType.PROJECT}`]: 'Aucun projet',
    [`no_${EntityType.REPORT}`]: 'Aucun rapport',
    [`no_${EntityType.SYNOPTIC}`]: 'Aucun synoptique',
  },
};
