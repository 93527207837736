import { Paper, Typography, styled } from '@mui/material';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useTagEditStore } from 'zustand/stores/tagEdit';

const Container = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  width: 'fit-content',
  position: 'absolute',
  top: -1000,
}));

const DragImage: FC = () => {
  const { t } = useTranslation('tags');

  const setDragImageElement = useTagEditStore(state => state.setDragImageElement);

  return (
    <Container ref={element => setDragImageElement(element)} elevation={8}>
      <Typography variant="h6">{t('text.draggingTags')}</Typography>
    </Container>
  );
};

export default memo(DragImage);
