import { Tag } from '@mui/icons-material';
import { forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { NestedTag, NestedTag_Path } from '@dametis/core';

import SimpleTagChip, { SimpleTagChipProps } from './SimpleTagChip';

export interface NestedTagChipProps extends Omit<SimpleTagChipProps, 'name' | 'path'> {
  tag: NestedTag | undefined;
  displayPath?: boolean;
}

const NestedTagChip = forwardRef<HTMLDivElement, NestedTagChipProps>(({ tag, displayPath = false, ...props }, ref) => {
  const { t } = useTranslation('tags');

  const path = useMemo(() => (tag ? NestedTag_Path(tag) : []), [tag]);
  const parents = useMemo(() => (path.length > 0 ? path.slice(0, -1) : []), [path]);
  const parentsPath = useMemo(() => (parents.length > 0 ? parents.map(parent => parent.name) : undefined), [parents]);
  const parsedTagName = useMemo(() => tag?.name ?? t('text.unknownTag'), [tag, t]);

  const color = useMemo(() => {
    if (path.length === 0) {
      return undefined;
    }
    let foundColor: string | null = null;
    for (let index = path.length - 1; index >= 0; index -= 1) {
      if (path[index].color) {
        foundColor = path[index].color;
        break;
      }
    }
    return foundColor ?? undefined;
  }, [path]);

  return (
    <SimpleTagChip
      ref={ref}
      color={color}
      icon={Tag}
      name={parsedTagName}
      path={displayPath ? parentsPath : undefined}
      size="small"
      {...props}
    />
  );
});

NestedTagChip.displayName = 'NestedTagChip';

NestedTagChip.defaultProps = {
  displayPath: false,
};

export default NestedTagChip;
