import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Paper,
  Stack,
  Step,
  StepButton,
  StepConnector,
  StepLabel,
  Stepper,
  stepConnectorClasses,
  useTheme,
} from '@mui/material';
import { FC, MouseEventHandler, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface Step {
  label: string;
  component: ReactNode;
  isInvalid?: boolean;
  isUnavailable?: boolean;
  isWithoutPaper?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export interface StepperModalProps {
  nonLinear?: boolean;
  open: boolean;
  onClose: () => void;
  onSubmit: () => void | Promise<void>;
  startingStep?: number;
  steps: Step[];
  title: string;
}

const StepperModal: FC<StepperModalProps> = ({ nonLinear = false, open, onClose, onSubmit, steps, startingStep = 0, title }) => {
  const theme = useTheme();
  const { t } = useTranslation('global');

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [activeStepIndex, setActiveStepIndex] = useState<number>(startingStep);

  const activeStep = useMemo(() => steps[activeStepIndex], [activeStepIndex, steps]);
  const isDisabled = useMemo(() => steps.some(step => step.isInvalid), [steps]);

  const submit = useCallback(async () => {
    setIsSubmitting(true);
    await onSubmit();
    setIsSubmitting(false);
  }, [onSubmit]);

  const goToPreviousStep: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    setActiveStepIndex(state => state - 1);
  }, []);

  const goToNextStep: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    setActiveStepIndex(state => state + 1);
  }, []);

  const makeChangeStepIndex = useCallback(
    (stepIndex: number) => () => {
      setActiveStepIndex(stepIndex);
    },
    [],
  );

  useEffect(() => {
    if (open) {
      setActiveStepIndex(startingStep);
    }
  }, [open, startingStep]);

  return (
    <Dialog fullWidth maxWidth={activeStep.maxWidth ?? 'md'} open={open} onClose={onClose}>
      <DialogTitle sx={{ pb: 1 }}>{title}</DialogTitle>
      <Stack direction="row" overflow="hidden" px={3} spacing={1}>
        <Box flexShrink={0} width="250px">
          <Stepper
            activeStep={activeStepIndex}
            connector={
              <StepConnector
                sx={{
                  [`& .${stepConnectorClasses.line}`]: { transition: theme.transitions.create(['min-height'], { duration: 172 }) },
                  [`&.${stepConnectorClasses.active} .${stepConnectorClasses.line}, &.${stepConnectorClasses.completed} .${stepConnectorClasses.line}`]:
                    { minHeight: 0 },
                }}
              />
            }
            nonLinear={nonLinear}
            orientation="vertical"
          >
            {steps.map((step, index) => (
              <Step key={step.label} completed={index < activeStepIndex}>
                <StepButton
                  disabled={step.isUnavailable || index === activeStepIndex || (!nonLinear && index > activeStepIndex)}
                  onClick={makeChangeStepIndex(index)}
                >
                  <StepLabel error={step.isInvalid}>{step.label}</StepLabel>
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Stack
          alignItems="stretch"
          boxSizing="content-box"
          component={activeStep.isWithoutPaper ? 'div' : Paper}
          direction="row"
          flexGrow={1}
          justifyContent="center"
          overflow="auto"
          p={2}
        >
          {activeStep.component}
        </Stack>
      </Stack>
      <DialogActions>
        {activeStepIndex > 0 && (
          <Button
            color="primary"
            disabled={isSubmitting || steps[activeStepIndex - 1].isUnavailable}
            startIcon={<ArrowBackIosNew />}
            variant="text"
            onClick={goToPreviousStep}
          >
            {t('button.previous')}
          </Button>
        )}
        {activeStepIndex < steps.length - 1 && (
          <Button
            color="primary"
            disabled={isSubmitting || steps[activeStepIndex + 1].isUnavailable}
            endIcon={<ArrowForwardIos />}
            variant="text"
            onClick={goToNextStep}
          >
            {t('button.next')}
          </Button>
        )}
        <LoadingButton color="secondary" disabled={isDisabled} loading={isSubmitting} variant="contained" onClick={submit}>
          {t('button.save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default StepperModal;
