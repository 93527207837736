import { GridView } from 'types/grid';

export default {
  title: {
    playgrounds: 'Playgrounds',
    myPlaygrounds: 'My playgrounds',
    sharedPlaygrounds: 'Shared with me',
    privatePlaygrounds: 'Private',
    newPlayground: 'New playground',
    editPlayground: 'Edit playground',
    duplicatePlayground: 'Duplicate playground',
    createTemplate: 'New playground template',
    editTemplate: 'Edit playground template',
  },
  button: {
    newPlayground: 'Playground',
    cancel: 'Cancel',
    create: 'Create',
    save: 'Save',
    edit: 'Edit',
    delete: 'Delete',
    duplicate: 'Duplicate',
    share: 'Share',
    editTags: 'Edit tags',
    createPlayground: 'Create un blank playground',
  },
  text: {
    tag: 'Tags',
    shortCreatedAt: 'Created',
    shortUpdatedAt: 'Updated',
    updatedAt: 'Updated {{date}}',
    me: 'me',
    sharedBy: 'Shared by {{name}}',
    ownedBy: 'Owned by {{name}}',
    noPlaygrounds: 'You have no playgrounds yet',
    copy: 'Copy',
    name: 'Name',
    owner: 'Owner',
    tabs: 'Tabs',
    public: 'Public',
    private: 'Private',
    shared: 'Shared',
    view: {
      [GridView.TILE]: 'Tile view',
      [GridView.LIST]: 'List view',
    },
    allPlaygrounds: 'All playgrounds',
  },
  input: {
    label: {
      newPlayground: 'Name',
      type: 'Type',
      tags: 'Tags',
    },
  },
  form: {
    label: {
      confidentiality: 'Confidentiality',
      applyToAllBlocks: 'Apply this model to...',
    },
    value: {
      confidentiality_private: 'Private',
      confidentiality_shared: 'Shared',
      applyToAllBlocks_one: 'Only the block',
      applyToAllBlocks_all: 'All blocks of the block type',
    },
    tooltip: {
      owner_shared: 'If this option is checked, this playground be visible for all the users',
      owner_private: 'If this option is checked, this playground will be only visible by you ',
      not_owner: 'You cannot change the confidentiality of this playground because you are not the owner',
    },
  },
  tooltip: {
    createPlayground: 'create a playground',
    renamePlayground: 'rename this playground',
    editTags: 'edit tags of this playground',
    duplicatePlayground: 'duplicate this playground',
    sharePlayground: 'share this playground',
    deletePlayground: 'delete this playground',
  },
  toast: {
    create: {
      success: 'The playground was successfully created.',
      failure: 'An error occured while creating this playground.',
    },
    save: {
      success: 'The playground was successfully saved.',
      failure: 'An error occured while saving this playground.',
    },
    delete: {
      success: 'The playground was successfully deleted.',
      failure: 'An error occured while deleting this playground.',
    },
    duplicate: {
      success: 'The playground was successfully duplicated.',
      failure: 'An error occured while duplicating this playground.',
    },
  },
};
