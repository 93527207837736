import { TagUsagesKey } from '@dametis/core';

import { TagTab } from 'types/tags';

const entityType: Record<TagUsagesKey, string> = {
  blocks: 'Blocs',
  playgrounds: 'Playgrounds',
  projects: 'Projets',
  reports: 'Rapports',
  synoptics: 'Synoptiques',
  variables: 'Variables',
  opcos: 'Mix Vapeurs',
  models: 'Modèles',
};

export default {
  title: {
    editTags: 'Tags',
    tags: 'Tags',
    tagsDetails: 'Détails des tags',
    tagEditing: 'Édition de tags',
    actions: 'Actions disponibles',
    selectedTags: 'Tags sélectionnés',
    column: {
      name: 'Nom',
      dependencies: 'Dépendances',
    },
    entityType,
    tab: {
      [TagTab.TOOLS]: 'Outils',
      [TagTab.DEPENDENCIES]: 'Dépendances',
    },
  },
  label: {
    dependencies: 'Dépendances',
    newTag: 'Nouveau tag :',
    noTagFound: 'Aucun tag trouvé',
    searchingInAllTags: 'Recherche dans tous les tags',
    searchingIn: 'Recherche dans « {{name}} »',
    tags: 'Tags',
    searchTagsDescription:
      "Si plusieurs tags sont choisis, seuls les éléments correspondant à l'ensemble des critères seront affichés (opérateur logique « ET »)",
  },
  placeholder: {
    noSelectedTag: 'Aucun tag sélectionné',
    searchTag: 'Rechercher un tag...',
  },
  input: {
    tags: 'Tags',
    tagName: 'Nom du tag',
    targetTagName: 'Nom du tag cible',
  },
  button: {
    tag: 'Tag',
    editTags: 'Éditer les tags',
    close: 'Fermer',
    cancel: 'Annuler',
    cancelChanges: 'Annuler les changements',
    save: 'Enregistrer',
    validate: 'Valider',
    create: 'Créer',
    merge: 'Fusionner',
    move: 'Déplacer',
    addDependencies: 'Taguer',
    moveTags: 'Déplacer',
    moveToRoot: 'Déplacer à la racine',
    deleteTags: 'Supprimer',
    confirmDelete: 'Oui, supprimer',
    createTag: 'Créer un tag',
    createChildTag: 'Créer un tag enfant',
    editTag: 'Modifier',
    mergeTags: 'Fusionner',
    deleteTag: 'Supprimer',
    deleteTag_one: 'Supprimer le tag',
    deleteTag_other: 'Supprimer les {{count}} tags',
    moveTag: 'Déplacer',
    moveTag_one: 'Déplacer le tag',
    moveTag_other: 'Déplacer les {{count}} tags',
    undo: 'Revenir en arrière',
    selectAll: 'Tout sélectionner',
    unselectAll: 'Tout désélectionner',
    expandAll: 'Tout développer',
    collapseAll: 'Tout réduire',
    noTags: 'Créez votre premier tag',
  },
  tagAutocomplete: {
    newTag: 'Nouveau tag',
    unknownTag: 'Tag inconnu',
    emptyTag: 'Aucun tag sélectionné',
  },
  text: {
    noDependency: 'Aucune dépendance',
    noOptions: 'Aucune option',
    deleteTagsAdvice:
      'Supprimer $t(text.thisDeletedTag, {"count": {{tagCount}} }) supprimera le tag de $t(text.entity, {"count": {{dependenciesCount}} }). Vérifiez les dépendances avant la suppression.',
    mergeTagsAdvice:
      'Fusionner $t(text.thisTag, {"count": {{tagCount}} }) ajoutera $t(text.entity, {"count": {{dependenciesCount}} }) dans le tag cible et supprimera les tags source. Vérifiez les dépendances avant la fusion.',
    thisDeletedTag_one: 'le tag et ses enfants',
    thisDeletedTag_other: 'les {{count}} tags et leurs enfants',
    thisTag_one: 'le tag',
    thisTag_other: 'les {{count}} tags',
    entity_one: '{{count}} entité',
    entity_other: '{{count}} entités',
    updatedAt: 'Modifié {{date}}',
    tagSeparator: '▸',
    hasAnyChildSelected: 'Au moins un enfant sélectionné',
    hasChildSelected_one: '{{count}} enfant sélectionné',
    hasChildSelected_other: '{{count}} enfants sélectionnés',
    hasAnyChildDepthError: 'Au moins un enfant a une erreur',
    hasAnyChildError: 'Au moins un enfant a une erreur',
    hasChildError_one: '{{count}} erreur dans les enfants',
    hasChildError_other: '{{count}} erreurs dans les enfants',
    draggingTags: 'Déplacer plusieurs tags',
    noTags: "Ce site n'a pas de tags",
    unknownTag: 'Tag inconnu',
  },
  error: {
    maxTagAllowed: 'Nombre maximum de tags atteint',
    moveTags_one: 'Une erreur est survenue pendant le déplacement du tag',
    moveTags_other: 'Une erreur est survenue pendant le déplacement des tags',
    mergeTags_one: 'Une erreur est survenue pendant la fusion du tag',
    mergeTags_other: 'Une erreur est survenue pendant la fusion des tags',
    deleteTag_one: 'Une erreur est survenue pendant la suppression du tag',
    deleteTag_other: 'Une erreur est survenue pendant la suppression des tags',
    updateTag: 'Une erreur est survenue pendant la modification du tag',
    createTag: 'Une erreur est survenue pendant la création du tag',
    undo: 'Une erreur est survenue pendant le retour en arrière',
    maxDepthExceed: "L'arborescence ne peut excéder {{max}} niveaux de profondeur",
    maxNameLengthExceed: 'Le nom ne doit pas faire plus de {{max}} caractères',
    errorInTags: 'Vos tags contiennent des erreurs',
    invalidName_zero: 'Le nom du tag ne peut être vide',
    invalidName_other: 'Le nom du tag ne peut être plus long que {{max}} caractères',
  },
  toast: {
    success: 'Nouveaux tags sauvegardés.',
    error: 'Une erreur est survenue pendant la sauvegarde.',
    createTagSuccess: 'Le tag a bien été créé.',
    deleteTagSuccess_one: 'Le tag a bien été supprimé.',
    deleteTagSuccess_other: 'Les tags ont bien été supprimés.',
    mergeTagsSuccess: 'Les tags ont bien été fusionnés.',
    mergeTagsItselfFailure: 'Impossible de fusionner un tag uniquement avec lui-même',
    updateTagSuccess: 'Le tag a bien été modifié.',
  },
  modal: {
    cancelEdition: 'Êtes-vous sûr ? Vous êtes en train de fermer le mode édition.',
  },
  helper: {
    selectTag: 'Sélectionner un tag',
    click: 'Clic',
    moveTagsWithDnd: 'Vous pouvez déplacer les tags avec un glisser-déposer',
  },
};
