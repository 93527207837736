import { AddOutlined, DeleteOutlined } from '@mui/icons-material';
import { Grid, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { ChangeEventHandler, Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ConnectionType, FolderInfo, IsOneOfEnum, ShortcutCategory } from '@dametis/core';

import { createBlockTypeConnection } from 'components/Lego/helpers/blockType/createBlockTypeConnection';
import { BlockTypeBody } from 'components/Lego/types';
import ActionButton from 'components/UI/Buttons/ActionButton/ActionButton';
import { useReadGlobalRootFolderQuery } from 'store/api/globalFolders';

import FolderExplorerPicker from '../../FolderExplorer/FolderExplorerPicker';
import { ExplorerItem, ExplorerItemType, IsExplorerFolder, IsExplorerShortcut } from '../../FolderExplorer/types';

export interface ConnectionsStepProps<T extends BlockTypeBody> {
  blockTypeBody: T;
  setBlockTypeBody: Dispatch<SetStateAction<T>>;
}

const ConnectionsStep = <T extends BlockTypeBody = BlockTypeBody>({ blockTypeBody, setBlockTypeBody }: ConnectionsStepProps<T>) => {
  const { t } = useTranslation('lego');

  const { data: globalRootFolder = null } = useReadGlobalRootFolderQuery();

  const parsedGlobalRootFolder: FolderInfo | null = useMemo(
    () => (globalRootFolder ? { ...globalRootFolder, name: t('lego:text.factory') } : null),
    [globalRootFolder, t],
  );

  const handleAddConnection = useCallback(() => {
    setBlockTypeBody(state => ({ ...state, connections: [...(state.connections ?? []), createBlockTypeConnection()] }));
  }, [setBlockTypeBody]);

  const handleDeleteConnection = useCallback(
    (connectionIndex: number) => () => {
      setBlockTypeBody(state => ({
        ...state,
        connections: (state.connections ?? []).filter((_connection, index) => index !== connectionIndex),
      }));
    },
    [setBlockTypeBody],
  );

  const handleChangeBlockKey = useCallback(
    (connectionIndex: number): ChangeEventHandler<HTMLInputElement> =>
      event => {
        setBlockTypeBody(state => ({
          ...state,
          connections: (state.connections ?? []).map((connection, index) =>
            index === connectionIndex ? { ...connection, blockKey: event.target.value } : connection,
          ),
        }));
      },
    [setBlockTypeBody],
  );

  const handleChangeConnectionType = useCallback(
    (connectionIndex: number) => (event: SelectChangeEvent<ConnectionType>) => {
      setBlockTypeBody(state => ({
        ...state,
        connections: (state.connections ?? []).map((connection, index) =>
          index === connectionIndex
            ? { ...connection, type: IsOneOfEnum(event.target.value, ConnectionType) ? event.target.value : ConnectionType.MULTIPLE }
            : connection,
        ),
      }));
    },
    [setBlockTypeBody],
  );

  const isExplorerConnectionBlockTypeValid = useCallback(
    (selectedItem: ExplorerItem | null) => selectedItem !== null && !IsExplorerFolder(selectedItem),
    [],
  );

  const handleChangeExplorerConnectionBlockType = useCallback(
    (connectionIndex: number) => (newSelectedBlockType: ExplorerItem | null) => {
      setBlockTypeBody(state => ({
        ...state,
        connections: (state.connections ?? []).map((connection, index) =>
          index === connectionIndex
            ? {
                ...connection,
                blockTypeId: newSelectedBlockType && IsExplorerShortcut(newSelectedBlockType) ? newSelectedBlockType.content.uuid : null,
              }
            : connection,
        ),
      }));
    },
    [setBlockTypeBody],
  );

  return (
    <Stack gap={1} width={1}>
      <Stack alignItems="flex-start" direction="row" justifyContent="space-between">
        <InputLabel>{t('label.connections')}</InputLabel>
        <ActionButton startIcon={<AddOutlined />} onClick={handleAddConnection}>
          {t('button.add')}
        </ActionButton>
      </Stack>
      {(blockTypeBody.connections ?? []).length > 0 && (
        <Stack gap={1}>
          {(blockTypeBody.connections ?? []).map((connection, index) => (
            <Grid key={connection.uuid} container spacing={1}>
              <Grid item xs>
                <TextField
                  fullWidth
                  placeholder={t('placeholder.blockKey')}
                  value={connection.blockKey}
                  onChange={handleChangeBlockKey(index)}
                />
              </Grid>
              {parsedGlobalRootFolder && (
                <Grid item xs>
                  <FolderExplorerPicker
                    canSelectNone
                    isEditing
                    advancedTextFieldProps={{ fullWidth: true }}
                    displayedShortcut={[ShortcutCategory.BLOCK_TYPE]}
                    isValid={isExplorerConnectionBlockTypeValid}
                    rootFolder={parsedGlobalRootFolder}
                    value={
                      connection.blockTypeId !== null && connection.blockTypeId.length > 0
                        ? {
                            content: { uuid: connection.blockTypeId, category: ShortcutCategory.BLOCK_TYPE },
                            type: ExplorerItemType.SHORTCUT,
                          }
                        : null
                    }
                    onChange={handleChangeExplorerConnectionBlockType(index)}
                  />
                </Grid>
              )}
              <Grid item xs={3}>
                <Select<ConnectionType> fullWidth value={connection.type} onChange={handleChangeConnectionType(index)}>
                  <MenuItem value={ConnectionType.MULTIPLE}>{t(`connectionType.${ConnectionType.MULTIPLE}`)}</MenuItem>
                  <MenuItem value={ConnectionType.UNIQUE}>{t(`connectionType.${ConnectionType.UNIQUE}`)}</MenuItem>
                </Select>
              </Grid>
              <Grid item alignSelf="center" xs={false}>
                <Tooltip title={t('tooltip.delete')}>
                  <IconButton size="small" onClick={handleDeleteConnection(index)}>
                    <DeleteOutlined fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          ))}
        </Stack>
      )}
      {(blockTypeBody.connections ?? []).length === 0 && (
        <Typography align="center" p={2} variant="subtitle2">
          {t('text.noConnection')}
        </Typography>
      )}
    </Stack>
  );
};

export default ConnectionsStep;
