import { Add, AddOutlined, Done } from '@mui/icons-material';
import {
  Box,
  Button,
  Collapse,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Popover,
  Stack,
  SvgIcon,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEventHandler, Dispatch, FormEventHandler, MouseEventHandler, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BlockTypeMetric,
  BlockTypeMetricBody,
  IsBlockKeyUsedByAnyMetric,
  MetricCategory,
  PartialCalculationVariable,
  ShortcutCategory,
} from '@dametis/core';

import { createBlockTypeMetricBody } from 'components/Lego/helpers/blockType/createBlockTypeMetricBody';
import { BlockTypeBody } from 'components/Lego/types';
import ActionButton from 'components/UI/Buttons/ActionButton/ActionButton';
import UnitPicker from 'components/UI/UnitPicker/UnitPicker';
import { UnitResult } from 'components/UI/UnitPicker/types';
import VncInput from 'components/VNC/VncInput';
import { ListTab } from 'components/VNC/types';
import { createCalculationVariable } from 'functions/createCalculationVariable';
import { useDispatch } from 'store';
import { addNotSavedIdByName, removeNotSavedIdByName } from 'store/slices/variables';

import LegoMetricPreview from '../../LegoMetricPreview/LegoMetricPreview';

export interface MetricsStepProps<T extends BlockTypeBody> {
  blockTypeBody: T;
  setBlockTypeBody: Dispatch<SetStateAction<T>>;
}

const MetricsStep = <T extends BlockTypeBody = BlockTypeBody>({ blockTypeBody, setBlockTypeBody }: MetricsStepProps<T>) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('lego');

  const [metricBody, setMetricBody] = useState<BlockTypeMetricBody>(createBlockTypeMetricBody);
  const [metricMenuAnchorEl, setMetricMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [editingMetricIndex, setEditingMetricIndex] = useState<number | null>(null);

  const metrics = useMemo(() => blockTypeBody.metrics ?? [], [blockTypeBody.metrics]);
  const calculationMetrics = useMemo(() => metrics.filter(metric => metric.category === MetricCategory.METRIC), [metrics]);
  const discrepancyMetrics = useMemo(() => metrics.filter(metric => metric.category === MetricCategory.DISCREPANCY), [metrics]);
  const technicalMetrics = useMemo(() => metrics.filter(metric => metric.category === MetricCategory.TECHNICAL), [metrics]);

  const availableMetricCategories = useMemo(() => Object.values(MetricCategory), []);

  const isMetricMenuOpen = useMemo(() => Boolean(metricMenuAnchorEl), [metricMenuAnchorEl]);

  const isValidMetricBody = useMemo(
    () =>
      metricBody.name.trim().length > 0 &&
      metrics.every((metric, index) => metric.name.trim() !== metricBody.name.trim() || editingMetricIndex === index),
    [editingMetricIndex, metricBody.name, metrics],
  );

  const metricMenuIcon = useMemo(() => (editingMetricIndex === null ? Add : Done), [editingMetricIndex]);

  const unusedParameters = useMemo(
    () =>
      (blockTypeBody.parameters ?? []).filter(
        parameter => !IsBlockKeyUsedByAnyMetric(parameter.uuid as string, (blockTypeBody.metrics as BlockTypeMetricBody[]) ?? []),
      ),
    [blockTypeBody.parameters, blockTypeBody.metrics],
  );

  const handleAddMetric: MouseEventHandler<HTMLButtonElement> = useCallback(event => {
    setMetricBody(createBlockTypeMetricBody());
    setEditingMetricIndex(null);
    setMetricMenuAnchorEl(event.currentTarget);
  }, []);

  const handleEditMetric = useCallback(
    (metric: Required<BlockTypeBody>['metrics'][0], index: number): MouseEventHandler<HTMLButtonElement> =>
      event => {
        setMetricBody(createBlockTypeMetricBody(metric));
        setEditingMetricIndex(index);
        setMetricMenuAnchorEl(event.currentTarget);
      },
    [],
  );

  const handleDeleteMetric = useCallback(
    (index: number): MouseEventHandler<HTMLButtonElement> =>
      () => {
        setBlockTypeBody(state => ({
          ...state,
          metrics: (state.metrics ?? []).filter((_metric, stateIndex) => index !== stateIndex),
        }));
        dispatch(removeNotSavedIdByName(metricBody.uuid));
      },
    [dispatch, metricBody.uuid, setBlockTypeBody],
  );

  const handleClosePopover = useCallback(() => {
    setMetricMenuAnchorEl(null);
  }, []);

  const handleChangeMetricName: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    setMetricBody(state => ({ ...state, name: event.target.value }));
  }, []);

  const handleChangeMetricUnit = useCallback((unit: UnitResult) => {
    setMetricBody(state => ({ ...state, unit: unit !== null ? unit : undefined }));
  }, []);

  const handleChangeMetricCategory: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    setMetricBody(state => ({ ...state, category: event.target.value as MetricCategory }));
  }, []);

  const handleChangeMetricCalculation = useCallback((newCalcVar: PartialCalculationVariable) => {
    setMetricBody(state => ({ ...state, calculation: newCalcVar }));
  }, []);

  const handleValidMetric: FormEventHandler = useCallback(
    event => {
      event.preventDefault();
      setMetricMenuAnchorEl(null);
      const parsedMetricBody: BlockTypeMetricBody = {
        ...metricBody,
        calculation: metricBody.isVirtual ? createCalculationVariable() : metricBody.calculation,
      };
      if (editingMetricIndex !== null) {
        setBlockTypeBody(state => ({
          ...state,
          metrics: (state.metrics ?? []).map((metric, stateIndex) => (editingMetricIndex === stateIndex ? parsedMetricBody : metric)),
        }));
      } else {
        setBlockTypeBody(state => ({ ...state, metrics: [...(state.metrics ?? []), parsedMetricBody] }));
      }
      dispatch(addNotSavedIdByName({ uuid: parsedMetricBody.uuid, name: parsedMetricBody.name }));
    },
    [dispatch, editingMetricIndex, metricBody, setBlockTypeBody],
  );

  const handleChangeMetricIsVirtual: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    setMetricBody(state => ({ ...state, isVirtual: event.target.checked }));
  }, []);

  return (
    <>
      <Stack gap={1} width={1}>
        <Stack alignItems="flex-start" direction="row" justifyContent="space-between">
          <InputLabel>{t('label.metrics')}</InputLabel>
          <ActionButton startIcon={<AddOutlined />} onClick={handleAddMetric}>
            {t('button.add')}
          </ActionButton>
        </Stack>
        {metrics.length > 0 ? (
          <Stack spacing={2}>
            {calculationMetrics.length > 0 && (
              <Stack spacing={1}>
                <Typography variant="h6">{t('label.metricCategory', { context: MetricCategory.METRIC })}</Typography>
                <Stack gap={1}>
                  {calculationMetrics.map((metric, index) => (
                    <LegoMetricPreview<BlockTypeMetric>
                      key={metric.uuid}
                      displayCalculation
                      metric={metric}
                      onDelete={!IsBlockKeyUsedByAnyMetric(metric.uuid, metrics) ? handleDeleteMetric(index) : undefined}
                      onEdit={!IsBlockKeyUsedByAnyMetric(metric.uuid, metrics) ? handleEditMetric(metric, index) : undefined}
                    />
                  ))}
                </Stack>
              </Stack>
            )}
            {discrepancyMetrics.length > 0 && (
              <Stack spacing={1}>
                <Typography variant="h6">{t('label.metricCategory', { context: MetricCategory.DISCREPANCY })}</Typography>
                <Stack gap={1}>
                  {discrepancyMetrics.map((metric, index) => (
                    <LegoMetricPreview<BlockTypeMetric>
                      key={metric.uuid}
                      displayCalculation
                      metric={metric}
                      onDelete={handleDeleteMetric(index)}
                      onEdit={handleEditMetric(metric, index)}
                    />
                  ))}
                </Stack>
              </Stack>
            )}
            {technicalMetrics.length > 0 && (
              <Stack spacing={1}>
                <Typography variant="h6">{t('label.metricCategory', { context: MetricCategory.TECHNICAL })}</Typography>
                <Stack gap={1}>
                  {technicalMetrics.map((metric, index) => (
                    <LegoMetricPreview<BlockTypeMetric>
                      key={metric.uuid}
                      displayCalculation
                      metric={metric}
                      onDelete={handleDeleteMetric(index)}
                      onEdit={handleEditMetric(metric, index)}
                    />
                  ))}
                </Stack>
              </Stack>
            )}
          </Stack>
        ) : (
          <Typography p={2} textAlign="center" variant="subtitle2">
            {t('text.noMetric')}
          </Typography>
        )}
        {unusedParameters.length > 0 && (
          <Typography mt="auto" variant="caption">
            {`${t('text.unusedParameters')} ${unusedParameters.map(unusedParameter => unusedParameter.name).join(', ')}`}
          </Typography>
        )}
      </Stack>
      <Popover
        anchorEl={metricMenuAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={isMetricMenuOpen}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClosePopover}
      >
        <form onSubmit={handleValidMetric}>
          <Stack direction="row" maxWidth={600} p={2} spacing={1}>
            <Stack direction="column" overflow="hidden" spacing={1}>
              <Stack direction="row" spacing={1} zIndex={1}>
                <TextField
                  autoFocus
                  fullWidth
                  placeholder={t('placeholder.name')}
                  size="small"
                  value={metricBody.name}
                  onChange={handleChangeMetricName}
                />
                <UnitPicker freeMode value={metricBody.unit ?? null} onChange={handleChangeMetricUnit} />
                <TextField fullWidth select size="small" value={metricBody.category} onChange={handleChangeMetricCategory}>
                  {availableMetricCategories.map(availableMetricCategory => (
                    <MenuItem key={availableMetricCategory} value={availableMetricCategory}>
                      {t('select.metricCategory', { context: availableMetricCategory })}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              <Stack alignItems="center" direction="row" gap={1}>
                <FormControlLabel
                  control={<Switch checked={metricBody.isVirtual} onChange={handleChangeMetricIsVirtual} />}
                  label={<Typography noWrap>{t('label.isVirtual')}</Typography>}
                />
                <Box width={1}>
                  <Collapse in={!metricBody.isVirtual}>
                    <Box zIndex={0}>
                      <VncInput
                        standardBlockMetricMode
                        editingBlockTypeMetricUuid={metricBody.uuid}
                        label=""
                        listTab={ListTab.BLOCKS}
                        sourceCategory={ShortcutCategory.BLOCK}
                        unPostedBlockType={blockTypeBody}
                        value={metricBody.calculation}
                        onChange={handleChangeMetricCalculation}
                      />
                    </Box>
                  </Collapse>
                </Box>
              </Stack>
            </Stack>
            <Button
              color="secondary"
              disabled={!isValidMetricBody}
              sx={{ minWidth: 'unset', padding: '6px' }}
              type="submit"
              variant="contained"
            >
              <SvgIcon component={metricMenuIcon} />
            </Button>
          </Stack>
        </form>
      </Popover>
    </>
  );
};

export default MetricsStep;
