import { List, ListItem, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ModelInfo } from '@dametis/core';

import { useModels } from 'store/api/models';
import { useVncStore } from 'zustand/stores/vnc';

import { useCategorizedModels } from '../../../../Models/useCategorizedModels';

import ModelsColumnModel from './ModelsColumnModel';

const emptyModels: ModelInfo[] = [];

const ModelsColumn: FC = () => {
  const { t } = useTranslation('vnc');

  const { data: allModels = emptyModels } = useModels();

  const categories = useCategorizedModels(allModels);

  const selectedCategory = useVncStore(state => state.models.selectedCategory);

  const selectedCategoryAndModels = useMemo(
    () => categories.find(([category]) => category === selectedCategory),
    [categories, selectedCategory],
  );

  return (
    <List>
      {selectedCategoryAndModels?.[1]?.map(model => <ModelsColumnModel key={model.uuid} model={model} />)}
      {selectedCategoryAndModels?.[1]?.length === 0 && (
        <ListItem>
          <Typography variant="subtitle2">{t('text.noModel')}</Typography>
        </ListItem>
      )}
    </List>
  );
};

export default ModelsColumn;
