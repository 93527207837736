import { AddOutlined, CloseOutlined } from '@mui/icons-material';
import { IconButton, Stack, Tab, Tabs, Typography, tabsClasses } from '@mui/material';
import { ChangeEvent, Dispatch, FC, MouseEventHandler, SetStateAction, SyntheticEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OpcUaDeviceConfig, OpcUaDeviceConnection } from '@dametis/core';

import { createOpcUaDeviceConnection } from 'components/Configuration/Devices/helpers/createOpcUaDeviceConfig';
import ActionButton from 'components/UI/Buttons/ActionButton/ActionButton';
import AdvancedNumberTextField from 'components/UI/Inputs/AdvancedTextField/AdvancedNumberTextField';

import FormPaper, { FormLocation } from '../../FormPaper';

import OpcUaConnectionForm from './OpcUaConnectionForm';

export interface OpcUaSettingsFormProps {
  opcUa: OpcUaDeviceConfig;
  setOpcUa: Dispatch<SetStateAction<OpcUaDeviceConfig>>;
  isEditing?: boolean;
  location?: `${FormLocation}`;
}

const OpcUaSettingsForm: FC<OpcUaSettingsFormProps> = ({ opcUa, setOpcUa, isEditing = true, location = 'modal' }) => {
  const { t } = useTranslation('devices');

  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  const handleChangeLoopTime = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, newValue: number) => {
      if (!setOpcUa) {
        return;
      }
      setOpcUa(state => ({ ...state, loopTime: newValue }));
    },
    [setOpcUa],
  );

  const handleChangeConnection = useCallback(
    (connectionIndex: number) => (newConnection: OpcUaDeviceConnection) => {
      if (!setOpcUa) {
        return;
      }
      setOpcUa(state => ({
        ...state,
        connections: state.connections.map((connection, index) => (index === connectionIndex ? newConnection : connection)),
      }));
    },
    [setOpcUa],
  );

  const handleAddConnection = useCallback(() => {
    const newSelectedTabIndex = opcUa.connections.length;
    setOpcUa(state => ({
      ...state,
      connections: [...state.connections, createOpcUaDeviceConnection()],
    }));
    setSelectedTabIndex(newSelectedTabIndex);
  }, [setOpcUa, opcUa]);

  const handleChangeSelectedTabIndex = useCallback((_event: SyntheticEvent, newSelectedTabIndex: number) => {
    setSelectedTabIndex(newSelectedTabIndex);
  }, []);

  const handleMouseDownDeleteConnection: MouseEventHandler<HTMLButtonElement> = useCallback(event => {
    event.stopPropagation();
  }, []);

  const handleDeleteConnection = useCallback(
    (deletedConnexionIndex: number): MouseEventHandler<HTMLButtonElement> =>
      event => {
        event.stopPropagation();
        setOpcUa(state => ({
          ...state,
          connections: state.connections.slice(0, deletedConnexionIndex).concat(state.connections.slice(deletedConnexionIndex + 1)),
        }));
        if (deletedConnexionIndex === selectedTabIndex) {
          setSelectedTabIndex(0);
        } else if (deletedConnexionIndex < selectedTabIndex) {
          setSelectedTabIndex(state => state - 1);
        }
      },
    [selectedTabIndex, setOpcUa],
  );

  return (
    <>
      <FormPaper location={location} sx={{ p: 1.5 }}>
        <Typography mb={1.5} variant="h6">
          {t('title.acquisition')}
        </Typography>
        <AdvancedNumberTextField
          fullWidth
          required
          description={t('description.loopTime')}
          editing={isEditing}
          InputProps={{ inputProps: { step: 100, min: 500 } }} // SLE: this step does not count in validator
          label={t('label.loopTime')}
          parser={inputValue => parseInt(inputValue, 10)}
          value={opcUa.loopTime}
          onChange={handleChangeLoopTime}
        />
      </FormPaper>
      <FormPaper location={location} sx={{ p: 1.5 }}>
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Typography variant="h6">{t('title.connections')}</Typography>
          {isEditing && (
            <ActionButton disabled={opcUa.connections.length > 4} startIcon={<AddOutlined />} onClick={handleAddConnection}>
              {t('button.addConnection')}
            </ActionButton>
          )}
        </Stack>
        <Tabs
          sx={{ [`& .${tabsClasses.flexContainer}`]: { gap: 1 }, mb: 1 }}
          value={selectedTabIndex}
          onChange={handleChangeSelectedTabIndex}
        >
          {opcUa.connections.map((_connection, connectionIndex) => (
            <Tab
              // eslint-disable-next-line react/no-array-index-key
              key={connectionIndex}
              label={
                <Stack alignItems="center" direction="row" gap={0}>
                  <Typography>{t('label.connectionNumber', { number: connectionIndex + 1 })}</Typography>
                  {isEditing && opcUa.connections.length > 1 && (
                    <IconButton
                      size="small"
                      onClick={handleDeleteConnection(connectionIndex)}
                      onMouseDown={handleMouseDownDeleteConnection}
                    >
                      <CloseOutlined fontSize="small" />
                    </IconButton>
                  )}
                </Stack>
              }
              sx={{ p: 0, textTransform: 'none' }}
              value={connectionIndex}
            />
          ))}
        </Tabs>
        {opcUa.connections[selectedTabIndex] && (
          <Stack gap={1.5}>
            <OpcUaConnectionForm
              connection={opcUa.connections[selectedTabIndex]}
              isEditing={isEditing}
              location={location}
              setConnection={handleChangeConnection(selectedTabIndex)}
            />
          </Stack>
        )}
      </FormPaper>
      {/* {opcUa.connections.map((connection, connectionIndex) => (
        <OpcUaConnectionForm connection={connection} setConnection={handleChangeConnection(connectionIndex)} isEditing={isEditing} />
      ))} */}
    </>
  );
};

export default OpcUaSettingsForm;
