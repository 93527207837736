import { ArrowRight } from '@mui/icons-material';
import { Grid2, ListItemText, Typography } from '@mui/material';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ModelInfo, UUID } from '@dametis/core';

import { useStats } from 'components/Model/Results/useStats';
import { localizedFormat } from 'localization/localizedDateFns';
import localizedGroupBy from 'localization/localizedGroupBy';
import { useVncStore } from 'zustand/stores/vnc';

import { ListItemButtonWithArrow } from '../BlockDiagramList/ListItemButtonWithArrow';

interface ModelsColumnModelProps {
  model: ModelInfo;
}

const ModelsColumnModel: FC<ModelsColumnModelProps> = ({ model }) => {
  const { t } = useTranslation('vnc');

  const selectedModelUuid = useVncStore(state => state.models.selectedModelUuid);
  const setSelectedModelUuid = useVncStore(state => state.setSelectedModelUuid);

  const { formattedR2, r2Color } = useStats(model.metrics.r2, model.metrics.smape, model.metrics.cvrmse);

  const handleSelectModel = useCallback(
    (modelUuid: UUID) => () => {
      setSelectedModelUuid(modelUuid);
    },
    [setSelectedModelUuid],
  );

  return (
    <ListItemButtonWithArrow
      key={model.uuid}
      disableGutters
      selected={model.uuid === selectedModelUuid}
      onClick={handleSelectModel(model.uuid)}
    >
      <ListItemText
        primary={
          <Typography noWrap variant="h6">
            {model.name}
          </Typography>
        }
        secondary={
          <Grid2 container alignItems="center" mt={0.5} spacing={1}>
            <Grid2 size={6}>
              <Typography variant="body2">
                {localizedFormat(new Date(model.period.from), 'eee PPpp')}
                <br />
                {localizedFormat(new Date(model.period.to), 'eee PPpp')}
              </Typography>
            </Grid2>
            <Grid2 size={3}>
              <Typography variant="body2">{localizedGroupBy(model.groupBy)}</Typography>
            </Grid2>
            <Grid2 size={3}>
              <Typography variant="caption">{t('text.modelR2')}</Typography>
              <Typography sx={{ color: r2Color }} variant="body2">
                {formattedR2}
              </Typography>
            </Grid2>
          </Grid2>
        }
      />
      <ArrowRight />
    </ListItemButtonWithArrow>
  );
};

export default ModelsColumnModel;
