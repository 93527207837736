import { Typography, TypographyProps } from '@mui/material';
import { FC } from 'react';

export type VariableValueProps = TypographyProps;

const VariableValue: FC<VariableValueProps> = ({ children = undefined, sx, ...props }) =>
  children !== undefined && children !== null ? (
    <Typography
      noWrap
      flexShrink={0}
      sx={{
        backgroundColor: theme => theme.palette.background.default,
        p: 0.5,
        lineHeight: 1,
        my: 0.5,
        borderRadius: 0.5,
        ...sx,
      }}
      {...props}
    >
      {children}
    </Typography>
  ) : null;

export default VariableValue;
