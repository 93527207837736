import { DEFAULT_ERROR } from './errors';

export default {
  button: {
    ok: 'Ok',
    closeAll: 'Cerrar todo',
  },
  error: DEFAULT_ERROR,
  errorNoSite: 'Ningún sitio seleccionado.',
  errorNoGroup: 'Ningún grupo seleccionado.',

  successCreateVariable: 'La variable ha sido creada correctamente.',
  successUpdateVariable: 'La variable ha sido modificada correctamente.',
  successUpdateVariables: 'Las variables han sido modificadas correctamente.',
  successCreateModel: 'El modelo ha sido creado correctamente.',
  successUpdateModel: 'El modelo ha sido modificado correctamente.',
  successCreateVariables: 'Las variables han sido creadas correctamente.',
  successDeleteVariable_one: 'Se ha eliminado {{ count }} variable correctamente.',
  successDeleteVariable_other: 'Se han eliminado {{ count }} variables correctamente.',
  successCreateVariableList: 'La lista ha sido creada correctamente.',
  successEditVariableList: 'La lista ha sido modificada correctamente.',
  successDeleteVariableList: 'La lista ha sido eliminada correctamente.',
  successImportData: 'Los datos han sido importados correctamente.',
  successSaveChanges: 'Las modificaciones han sido guardadas correctamente.',
  successCreateNewDevice: 'El equipo ha sido creado correctamente.',
  successEditDevice: 'El equipo ha sido modificado correctamente.',
  successDeleteDevice: 'El equipo ha sido eliminado correctamente.',
  successDisableDevice: 'El equipo ha sido desactivado correctamente.',
  successEnableDevice: 'El equipo ha sido reactivado correctamente.',
  successCreateSynopticWidget: 'El elemento personalizado ha sido creado correctamente.',
  successDeleteSynopticWidget: 'El elemento personalizado ha sido eliminado correctamente.',
  successCreateSynoptic: 'El sinóptico ha sido guardado correctamente.',
  successDeleteSynoptic: 'El sinóptico ha sido eliminado correctamente.',
  successAddCart: 'La variable ha sido añadida al carrito correctamente',
  successAddCartItems: 'Las variables han sido añadidas al carrito correctamente',
  successRemoveCart: 'La variable ha sido eliminada del carrito correctamente',

  errorNotAllowed: 'Operación no permitida.',
  errorExport: 'Se produjo un error al exportar los datos.',
  errorExportData: 'Demasiados datos para procesar, por favor reduzca el intervalo de tiempo y/o aumente el subperíodo.',
  errorGroupBy: 'Demasiados datos para mostrar, por favor reduzca el intervalo de tiempo y/o aumente el subperíodo.',
  errorBoxVersion: 'Conexión con la caja imposible.',
  errorVariableDependants_one: '{{ count }} variable tiene dependencias.',
  errorVariableDependants_other: '{{ count }} variables tienen dependencias.',
  errorDisableDevice: 'Se ha producido un error.',
  errorEnableDevice: 'Se ha producido un error.',

  warningAddCart: 'La variable ya ha sido añadida al carrito.',
  alreadyInCartSomeItems: 'Algunas variables ya están en el carrito.',
  alreadyInCartAllItems: 'Todas las variables ya están en el carrito',

  successCreateBatch: 'El batch ha sido creado correctamente.',
  successDeleteBatch: 'El batch ha sido eliminado correctamente.',

  successCreateProject: 'El proyecto ha sido creado correctamente.',
  successDeleteProject: 'El proyecto ha sido eliminado correctamente.',
  successCreateTask: 'La tarea ha sido creada correctamente.',
  successUploadAsset: 'El documento ha sido importado correctamente.',
  successDownloadAsset: 'El documento ha sido descargado correctamente.',

  successCreatePlayground: 'El playground ha sido guardado correctamente.',
  successDuplicatePlayground: 'El playground ha sido duplicado correctamente.',
  successDeletePlayground: 'El playground ha sido eliminado correctamente.',

  errorTime: 'El reloj de su sistema parece no estar sincronizado.\nEsto puede afectar la visualización de los datos.',
  errorBrowser: 'La versión de su navegador es demasiado antigua.\nSu experiencia de navegación puede verse severamente afectada.',

  successCreateContract: 'El contrato ha sido creado correctamente.',
  successDeleteContract: 'El contrato ha sido eliminado correctamente.',
  successCreateBill: 'La factura ha sido creada correctamente.',
  successDeleteBill: 'La factura ha sido eliminada correctamente.',
  defaultGridViewChanged: 'La vista por defecto ha cambiado.',

  newVersion: '¡Una nueva versión está disponible!',
  reload: 'Refrescar',

  successUserUpdate: 'El usuario ha sido actualizado correctamente.',
  successUserCreate: 'El usuario ha sido creado correctamente.',
  errorUserUpdate: 'El usuario no pudo ser actualizado.',
  errorUserCreate: 'El usuario no pudo ser creado.',
  userAlreadyExists: 'Esta dirección de correo electrónico ya está asociada a una cuenta.',

  successUserDelete: 'El usuario ha sido eliminado correctamente.',
  errorUserDelete: 'El usuario no pudo ser eliminado.',

  successBoxUpdate: 'La caja ha sido actualizada correctamente.',
  successBoxCreate: 'La caja ha sido creada correctamente.',
  successBoxSetup: 'La caja ha sido configurada correctamente.',
  errorBoxUpdate: 'La caja no pudo ser actualizada.',
  errorBoxCreate: 'La caja no pudo ser creada.',
  errorBoxSetup: 'La caja no pudo ser configurada.',

  successGroupUpdate: 'El grupo ha sido actualizado correctamente.',
  successGroupCreate: 'El grupo ha sido creado correctamente.',
  errorGroupUpdate: 'El grupo no pudo ser actualizado.',
  errorGroupCreate: 'El grupo no pudo ser creado.',

  successSiteUpdate: 'El sitio ha sido actualizado correctamente.',
  successSiteCreate: 'El sitio ha sido creado correctamente.',
  errorSiteUpdate: 'El sitio no pudo ser actualizado.',
  errorSiteCreate: 'El sitio no pudo ser creado.',

  successSendInvitationEmailAgain: 'Se acaba de enviar un correo electrónico de invitación.',
  errorSendInvitationEmailAgain: 'Se ha producido un error.',

  successCreateComment: 'El comentario ha sido creado correctamente.',
  successUpdateComment: 'El comentario ha sido modificado correctamente.',
  successDeleteComment: 'El comentario ha sido eliminado correctamente.',
  writeChartComment: 'Haga clic en el gráfico para escribir un comentario.',
  noCommentForCalculation: 'No se puede dejar un comentario en un cálculo.',

  onDeploy: 'La API está siendo desplegada.',
  successDeploy: 'La API está despliega.',
  errorDeploy: 'Se ha producido un error.',

  successComputeScores: 'Se calculan las puntuaciones de los grupos.',
  errorComputeScores: 'An error has occurred.',

  successClearCache: 'El caché de la API ha sido vaciado.',
  errorClearCache: 'Se ha producido un error.',

  successSyncConsul: 'Los grupos han sido sincronizados en Consul.',
  errorSyncConsul: 'Se ha producido un error.',

  successCreateProjectTemplate: 'El modelo de proyectos ha sido creado correctamente.',
  successDeleteProjectTemplate: 'El modelo de proyectos ha sido eliminado correctamente.',

  successArmageddon: 'Optimización y recalculo en curso... Por favor, sea paciente',
  successDamcoArmageddon: 'Los datos de monitoring han sido eliminados',
  errorArmageddon: 'Se ha producido un error.',

  successSyncConfigBox: 'La caja ha sido sincronizada correctamente.',
  waitingSyncConfigBox: 'Intento de sincronización de la caja...',
  canceledSyncBoxConfig: 'La sincronización fue cancelada por otra solicitud de sincronización',
  upToDateSyncConfigBox: 'La configuración de la caja ya está actualizada.',

  entityKind: {
    playground: 'el playground',
    variable: 'la variable',
    report: 'el informe',
    synoptic: 'el sinóptico',
    alarme: 'la alarma',
    project: 'el proyecto',
  },
  sharedWithYou: '{{firstName}} {{lastName}} te ha compartido $t(toast:entityKind.{{kind}}) « {{name}} »',
  assignedYouTask: '{{firstName}} {{lastName}} te ha asignado una tarea en $t(toast:entityKind.{{kind}}) « {{name}} »',
  assignedYouAlarm: '{{firstName}} {{lastName}} te ha asignado a $t(toast:entityKind.{{kind}}) « {{name}} »',
  mentionnedYou: '{{firstName}} {{lastName}} te ha mencionado en un comentario sobre $t(toast:entityKind.{{kind}}) « {{name}} »',
  openToast: 'Abrir',

  warningMustConfigureTwoFA: 'Se requiere autenticación de doble factor en este sitio. Puede configurarlo en Mi cuenta.',
  successDeleteTask: 'La tarea ha sido eliminada correctamente.',

  notExistingTemplate: 'Se ha producido un error.',
  successUploadImage: 'La imagen ha sido importada correctamente.',
  successDeleteBlock: 'El bloque ha sido eliminado correctamente',

  successCreateUserList: 'La lista de usuarios ha sido creado correctamente.',
  successUpdateUserList: 'La lista de usuarios ha sido actualizada correctamente.',
  successDeleteUserList: 'La lista de usuarios ha sido eliminado correctamente.',
};
