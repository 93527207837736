import { Checkbox, FormControlLabel, Grid, MenuItem, Typography } from '@mui/material';
import { ChangeEvent, ChangeEventHandler, Dispatch, FC, SetStateAction, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useResizeObserver } from 'usehooks-ts';

import { AllHttpContentType, AllHttpMethods, HttpContentType, HttpDeviceConfig, HttpMethod, SyncDelay, SyncPoint } from '@dametis/core';

import DateTimeFormatPicker from 'components/UI/DateTimeFormatPicker/DateTimeFormatPicker';
import AdvancedNumberTextField from 'components/UI/Inputs/AdvancedTextField/AdvancedNumberTextField';
import AdvancedTextField from 'components/UI/Inputs/AdvancedTextField/AdvancedTextField';

import FormPaper, { FormLocation } from '../../FormPaper';
import SyncDelayInput from '../MQTTSettingsForm/SyncDelayInput';

import EndpointsPicker from './EndpointsPicker';
import HttpAuthentificationForm from './HttpAuthenticationForm';

export interface HttpSettingsFormProps {
  http: HttpDeviceConfig;
  setHttp: Dispatch<SetStateAction<HttpDeviceConfig>>;
  isEditing?: boolean;
  syncPoint?: SyncPoint;
  onChangeSyncPoint?: (newSyncPoint: SyncPoint) => void;
  syncDelay?: SyncDelay;
  onChangeSyncDelay?: (newSyncDelay: SyncDelay) => void;
  filterPastPoints?: boolean;
  onChangeFilterPastPoints?: (newFilterPastPoints: boolean) => void;
  location?: `${FormLocation}`;
}

const HttpSettingsForm: FC<HttpSettingsFormProps> = ({
  http,
  setHttp,
  isEditing = true,
  syncPoint = undefined,
  onChangeSyncPoint = undefined,
  syncDelay = undefined,
  onChangeSyncDelay = undefined,
  filterPastPoints = undefined,
  onChangeFilterPastPoints = undefined,
  location = 'modal',
}) => {
  const { t } = useTranslation('devices');

  const papersContainerRef = useRef<HTMLDivElement | null>(null);

  const { width: papersContainerWidth = 0 } = useResizeObserver({
    ref: papersContainerRef,
  });

  const availableSyncPoints: SyncPoint[] = useMemo(() => Object.values(SyncPoint), []);

  const isSyncPointFieldAvailable = useMemo(
    () => syncPoint !== undefined && onChangeSyncPoint !== undefined,
    [syncPoint, onChangeSyncPoint],
  );

  const isSyncDelayFieldAvailable = useMemo(
    () => syncDelay !== undefined && onChangeSyncDelay !== undefined,
    [syncDelay, onChangeSyncDelay],
  );

  const isFilterPastPointsFieldAvailable = useMemo(
    () => filterPastPoints !== undefined && onChangeFilterPastPoints !== undefined,
    [filterPastPoints, onChangeFilterPastPoints],
  );

  const handleChangeCron: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setHttp(state => ({ ...state, cron: event.target.value }));
    },
    [setHttp],
  );

  const handleChangeTimeout = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, newValue: number) => {
      if (!setHttp) {
        return;
      }
      setHttp(state => ({ ...state, timeout: newValue }));
    },
    [setHttp],
  );

  const handleChangeMethod: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setHttp(state => ({ ...state, method: event.target.value as HttpMethod }));
    },
    [setHttp],
  );

  const handleChangeContentType: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setHttp(state => ({ ...state, contentType: event.target.value as HttpContentType }));
    },
    [setHttp],
  );

  const handleChangeSyncPoint: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (onChangeSyncPoint) {
        onChangeSyncPoint(event.target.value as SyncPoint);
      }
    },
    [onChangeSyncPoint],
  );

  const handleChangeSyncDelay = useCallback(
    (newSyncDelay: SyncDelay) => {
      if (onChangeSyncDelay) {
        onChangeSyncDelay(newSyncDelay);
      }
    },
    [onChangeSyncDelay],
  );

  const handleChangeFilterPastPoints: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (onChangeFilterPastPoints) {
        onChangeFilterPastPoints(event.target.checked);
      }
    },
    [onChangeFilterPastPoints],
  );

  const handleChangeEndpoints = useCallback(
    (newEndpoints: string[]) => {
      setHttp(state => ({ ...state, endpoints: newEndpoints }));
    },
    [setHttp],
  );

  const handleChangeJQTransformer: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setHttp(state => ({ ...state, jqTransformer: event.target.value }));
    },
    [setHttp],
  );

  const handleChangePropertyMatcherReference: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setHttp(state => ({ ...state, propertyMatcher: { ...state.propertyMatcher, reference: event.target.value } }));
    },
    [setHttp],
  );

  const handleChangePropertyMatcherDate: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setHttp(state => ({ ...state, propertyMatcher: { ...state.propertyMatcher, date: event.target.value } }));
    },
    [setHttp],
  );

  const handleChangePropertyMatcherValue: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setHttp(state => ({ ...state, propertyMatcher: { ...state.propertyMatcher, value: event.target.value } }));
    },
    [setHttp],
  );

  const handleChangeTimeZone: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setHttp(state => ({ ...state, timeZone: event.target.value }));
    },
    [setHttp],
  );

  const handleChangeDateFormat: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setHttp(state => ({ ...state, dateFormat: event.target.value }));
    },
    [setHttp],
  );

  return (
    <>
      <FormPaper location={location} sx={{ p: 1.5 }}>
        <Typography mb={1.5} variant="h6">
          {t('title.acquisition')}
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={papersContainerWidth > 400 ? 6 : 12}>
            <AdvancedTextField
              fullWidth
              required
              editing={isEditing}
              label={t('label.cron')}
              value={http.cron}
              onChange={handleChangeCron}
            />
          </Grid>
          <Grid item xs={papersContainerWidth > 400 ? 6 : 12}>
            <AdvancedNumberTextField
              fullWidth
              required
              editing={isEditing}
              label={t('label.timeout')}
              parser={inputValue => parseInt(inputValue, 10)}
              value={http.timeout}
              onChange={handleChangeTimeout}
            />
          </Grid>
          <Grid item xs={6}>
            <AdvancedTextField
              fullWidth
              select
              editing={isEditing}
              label={t('label.method')}
              value={http.method}
              onChange={handleChangeMethod}
            >
              {AllHttpMethods.map(httpMethod => (
                <MenuItem key={httpMethod} value={httpMethod}>
                  {httpMethod}
                </MenuItem>
              ))}
            </AdvancedTextField>
          </Grid>
          <Grid item xs={6}>
            <AdvancedTextField
              fullWidth
              select
              editing={isEditing}
              label={t('label.contentType')}
              value={http.contentType}
              onChange={handleChangeContentType}
            >
              {AllHttpContentType.map(httpContentType => (
                <MenuItem key={httpContentType} value={httpContentType}>
                  {httpContentType}
                </MenuItem>
              ))}
            </AdvancedTextField>
          </Grid>
          {isSyncPointFieldAvailable && (
            <Grid item xs={papersContainerWidth > 400 ? 6 : 12}>
              <AdvancedTextField
                fullWidth
                select
                editing={isEditing}
                label={t('label.syncPoint')}
                value={syncPoint}
                onChange={handleChangeSyncPoint}
              >
                {availableSyncPoints.map(availableSyncPoint => (
                  <MenuItem key={availableSyncPoint} value={availableSyncPoint}>
                    {availableSyncPoint}
                  </MenuItem>
                ))}
              </AdvancedTextField>
            </Grid>
          )}
          {isSyncDelayFieldAvailable && syncDelay && (
            <Grid item xs={papersContainerWidth > 400 ? 6 : 12}>
              <SyncDelayInput isEditing={isEditing} label={t('label.syncDelay')} value={syncDelay} onChange={handleChangeSyncDelay} />
            </Grid>
          )}
          {isFilterPastPointsFieldAvailable && (
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterPastPoints}
                    sx={!isEditing ? { pointerEvents: 'none' } : undefined}
                    onChange={isEditing ? handleChangeFilterPastPoints : undefined}
                  />
                }
                label={t('label.filterPastPoints')}
                labelPlacement="end"
                sx={{ mr: 0, cursor: isEditing ? 'pointer' : 'default' }}
              />
            </Grid>
          )}
        </Grid>
      </FormPaper>
      <FormPaper location={location} sx={{ p: 1.5 }}>
        <EndpointsPicker isEditing={isEditing} prefix="https://" value={http.endpoints} onChange={handleChangeEndpoints} />
      </FormPaper>
      <FormPaper location={location} sx={{ p: 1.5 }}>
        <Typography mb={1.5} variant="h6">
          {t('title.jqTransformer')}
        </Typography>
        <AdvancedTextField
          fullWidth
          multiline
          editing={isEditing}
          // label={t('label.jqTransformer')}
          margin="none"
          minRows={2}
          value={http.jqTransformer}
          onChange={handleChangeJQTransformer}
        />
      </FormPaper>
      <Grid ref={papersContainerRef} container spacing={1.5}>
        <Grid item xs={papersContainerWidth > 400 ? 6 : 12}>
          <FormPaper location={location} sx={{ p: 1.5, height: 1 }}>
            <Typography mb={1.5} variant="h6">
              {t('title.propertyMatcher')}
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <AdvancedTextField
                  fullWidth
                  required
                  editing={isEditing}
                  label={t('label.reference')}
                  value={http.propertyMatcher.reference}
                  onChange={handleChangePropertyMatcherReference}
                />
              </Grid>
              <Grid item xs={12}>
                <AdvancedTextField
                  fullWidth
                  required
                  editing={isEditing}
                  label={t('label.date')}
                  value={http.propertyMatcher.date}
                  onChange={handleChangePropertyMatcherDate}
                />
              </Grid>
              <Grid item xs={12}>
                <AdvancedTextField
                  fullWidth
                  required
                  editing={isEditing}
                  label={t('label.value')}
                  value={http.propertyMatcher.value}
                  onChange={handleChangePropertyMatcherValue}
                />
              </Grid>
            </Grid>
          </FormPaper>
        </Grid>
        <Grid item xs={papersContainerWidth > 400 ? 6 : 12}>
          <FormPaper location={location} sx={{ p: 1.5, height: 1 }}>
            <Typography mb={1.5} variant="h6">
              {t('title.date')}
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <AdvancedTextField
                  fullWidth
                  required
                  editing={isEditing}
                  label={t('label.timeZone')}
                  value={http.timeZone}
                  onChange={handleChangeTimeZone}
                />
              </Grid>
              <Grid item xs={12}>
                <DateTimeFormatPicker
                  fullWidth
                  required
                  editing={isEditing}
                  label={t('label.dateFormat')}
                  value={http.dateFormat}
                  onChange={handleChangeDateFormat}
                />
              </Grid>
            </Grid>
          </FormPaper>
        </Grid>
      </Grid>
      <FormPaper location={location} sx={{ p: 1.5 }}>
        <Typography mb={1.5} variant="h6">
          {t('title.authentification')}
        </Typography>
        <HttpAuthentificationForm containerWidth={papersContainerWidth} http={http} isEditing={isEditing} setHttp={setHttp} />
      </FormPaper>
    </>
  );
};

export default HttpSettingsForm;
