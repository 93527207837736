import { makeStyles } from '@mui/styles';

const useCircLoaderStyles = makeStyles(theme => ({
  circularLoader: {
    position: 'relative',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  circularLoader__container: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  circularLoader__overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.default,
  },
  circularLoader__overlay__icon: {},
}));

export default useCircLoaderStyles;
