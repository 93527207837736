import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateStandardBlockBody, StandardBlockInfo, UUID } from '@dametis/core';

import { getStandardBlockBodyDataFromBlockType } from 'components/Lego/helpers/getStandardBlockBodyDataFromBlockType';
import { createCreateStandardBlockBody } from 'components/Lego/helpers/standardBlock/createCreateStandardBlockBody';
import StepperModal from 'components/UI/StepperModal/StepperModal';
import { useDispatch } from 'store';
import { useBlockTypes } from 'store/api/blockTypes';
import { useCreateStandardBlockMutation } from 'store/api/standardBlocks';
import { addToast } from 'store/slices/toast';
import { ToastSeverity } from 'types';

import GeneralStep from './GeneralStep';
import MetadataListStep from './MetadataListStep/MetadataListStep';
import ParametersAndMetricsStep from './ParametersAndMetricsStep/ParametersAndMetricsStep';
import { getIsGeneralStepValid } from './helpers/getIsGeneralStepValid';
import { getIsMetadataListStepValid } from './helpers/getIsMetadataListStepValid';
import { getIsParametersAndMetricsStepValid } from './helpers/getIsParametersAndMetricsStepValid';

const emptyArray: [] = [];

export interface CreateStandardBlockModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  blockTypeId?: UUID;
  folderId?: UUID;
  onCreate?: (createdStandardBlock: StandardBlockInfo, folderId: UUID) => Promise<void> | void;
}

const CreateStandardBlockModal: FC<CreateStandardBlockModalProps> = ({
  isOpen,
  setIsOpen,
  folderId = undefined,
  blockTypeId = undefined,
  onCreate = undefined,
}) => {
  const { t } = useTranslation('lego');
  const dispatch = useDispatch();

  const { data: blockTypes = emptyArray } = useBlockTypes();
  const [createStandardBlock] = useCreateStandardBlockMutation();

  const [createStandardBlockBody, setCreateStandardBlockBody] = useState<CreateStandardBlockBody>(createCreateStandardBlockBody);

  const isGeneralStepValid = useMemo(() => getIsGeneralStepValid(createStandardBlockBody), [createStandardBlockBody]);

  const isMetadataListStepValid = useMemo(() => getIsMetadataListStepValid(createStandardBlockBody), [createStandardBlockBody]);

  const isParametersAndMetricsStepValid = useMemo(() => getIsParametersAndMetricsStepValid(), []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const handleSubmit = useCallback(async () => {
    try {
      const data = await createStandardBlock(createStandardBlockBody).unwrap();
      if (onCreate && createStandardBlockBody.folderId) {
        await onCreate(data, createStandardBlockBody.folderId);
      }
      setIsOpen(false);
      dispatch(addToast({ message: t('toast.createStandardBlockSuccess'), severity: ToastSeverity.SUCCESS }));
    } catch (error) {
      console.error(error);
    }
  }, [createStandardBlockBody, createStandardBlock, dispatch, setIsOpen, t, onCreate]);

  useEffect(() => {
    if (isOpen) {
      setCreateStandardBlockBody(createCreateStandardBlockBody({ folderId, blockTypeId }));
    }
  }, [isOpen, folderId, blockTypeId]);

  useEffect(() => {
    const selectedBlockType = blockTypes.find(blockType => blockType.uuid === createStandardBlockBody.blockTypeId);
    if (selectedBlockType) {
      setCreateStandardBlockBody(state => ({ ...state, ...getStandardBlockBodyDataFromBlockType(selectedBlockType) }));
    }
  }, [blockTypes, createStandardBlockBody.blockTypeId]);

  const steps = useMemo(() => {
    return [
      {
        label: t('stepper.general'),
        component: (
          <GeneralStep<CreateStandardBlockBody>
            setStandardBlockBody={setCreateStandardBlockBody}
            standardBlockBody={createStandardBlockBody}
          />
        ),
        isInvalid: !isGeneralStepValid,
      },
      {
        label: t('stepper.metadatas'),
        component: (
          <MetadataListStep<CreateStandardBlockBody>
            setStandardBlockBody={setCreateStandardBlockBody}
            standardBlockBody={createStandardBlockBody}
          />
        ),
        isInvalid: !isMetadataListStepValid,
      },
      {
        label: t('stepper.parameters'),
        component: (
          <ParametersAndMetricsStep<CreateStandardBlockBody>
            setStandardBlockBody={setCreateStandardBlockBody}
            standardBlockBody={createStandardBlockBody}
          />
        ),
        isInvalid: !isParametersAndMetricsStepValid,
      },
    ];
  }, [createStandardBlockBody, isGeneralStepValid, isMetadataListStepValid, isParametersAndMetricsStepValid, t]);

  return (
    <StepperModal open={isOpen} steps={steps} title={t('title.createStandardBlockModal')} onClose={handleClose} onSubmit={handleSubmit} />
  );
};

export default CreateStandardBlockModal;
