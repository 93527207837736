import { ListItemNode, ListNode } from '@lexical/list';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { HeadingNode } from '@lexical/rich-text';
import { SerializedEditorState, SerializedElementNode } from 'lexical';
import { FC, useEffect, useMemo, useRef, useState } from 'react';

import { Config, lexicalTheme, onError } from 'config/lexical';
import { isLexicalFormat } from 'functions/isLexicalFormat';
import { useDispatch } from 'store';
import { setCommentDisplayedRef } from 'store/slices/playground';

import EditorLexicalComposer from '../../../Lexical/EditorLexicalComposer.tsx';
import { MentionNode } from '../Edit/plugins/MentionPlugin/MentionNode';
import { StyledContentNonEditable } from '../StyledCommentElements.styled';

interface CommentMessageProps {
  editorState: SerializedEditorState<SerializedElementNode>;
  backgroundColor?: string;
  inline?: boolean;
}

const CommentMessage: FC<CommentMessageProps> = ({ editorState, backgroundColor = undefined, inline = false }) => {
  const dispatch = useDispatch();
  const editorRef = useRef(null);

  const [textAreaHeight, setTextAreaHeight] = useState(100);

  const initialConfig: Config = useMemo(
    () => ({
      editable: false,
      namespace: 'MyEditor',
      theme: lexicalTheme,
      onError,
      nodes: [HeadingNode, ListNode, ListItemNode, MentionNode],
      editorState: isLexicalFormat(editorState) ? JSON.stringify(editorState) : undefined,
    }),
    [editorState],
  );

  useEffect(() => {
    if (editorRef.current) {
      dispatch(setCommentDisplayedRef(editorRef.current));
    }
  }, [dispatch]);

  return (
    <EditorLexicalComposer
      ref={editorRef}
      backgroundColor={backgroundColor}
      height={textAreaHeight}
      initialConfig={initialConfig}
      setHeight={setTextAreaHeight}
    >
      <RichTextPlugin
        contentEditable={<StyledContentNonEditable inline={inline} />}
        ErrorBoundary={LexicalErrorBoundary}
        placeholder={null}
      />
      <ListPlugin />
    </EditorLexicalComposer>
  );
};

export default CommentMessage;
