import { DeleteOutlined, DriveFileMoveOutlined, EditOutlined, FileCopyOutlined } from '@mui/icons-material';
import { FormLabel, Grid, Stack, Typography } from '@mui/material';
import { FC, MouseEventHandler, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useResizeObserver } from 'usehooks-ts';

import {
  BlockTypeInfo,
  MetricCategory,
  ShortStandardBlockInfo,
  StandardBlockInfo,
  StandardBlockMetric,
  StandardBlockParameter,
  UUID,
} from '@dametis/core';

import BlockMetricsGroup from 'components/Lego/BlockDiagram/BlockList/BlockRightPanel/BlockMetricsGroup';
import BlockTypePreview from 'components/Lego/UI/BlockType/BlockTypePreview/BlockTypePreview';
import LegoMetricPreview from 'components/Lego/UI/LegoMetricPreview/LegoMetricPreview';
import LegoParameterPreview from 'components/Lego/UI/LegoParameterPreview/LegoParameterPreview';
import EditStandardBlockModal from 'components/Lego/UI/StandardBlock/EditStandardBlockModal/EditStandardBlockModal';
import MoveToStandardBlockModal from 'components/Lego/UI/StandardBlock/MoveToStandardBlockModal/MoveToStandardBlockModal';
import StandardBlockMetadataPreview from 'components/Lego/UI/StandardBlock/StandardBlockMetadataPreview/StandardBlockMetadataPreview';
import ActionButton from 'components/UI/Buttons/ActionButton/ActionButton';
import PromptBeforeDeleting from 'components/UI/PromptBeforeDeleting/PromptBeforeDeleting';
import { RightPanelActions, RightPanelBody, RightPanelSection, RightPanelSectionContent } from 'components/UI/RightPanel';
import { useDispatch } from 'store';
import { useDeleteStandardBlockMutation, useDuplicateStandardBlockMutation } from 'store/api/standardBlocks';
import { addToast } from 'store/slices/toast';
import { ToastSeverity } from 'types';

export interface InformationsTabProps {
  standardBlock: ShortStandardBlockInfo;
  blockType?: BlockTypeInfo;
}

const InformationsTab: FC<InformationsTabProps> = ({ standardBlock, blockType = undefined }) => {
  const { t } = useTranslation('lego');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { folderId } = useParams<{ folderId?: UUID }>();

  const [deleteStandardBlock, { isLoading: isDeleting }] = useDeleteStandardBlockMutation();
  const [duplicateStandardBlock, { isLoading: isDuplicating }] = useDuplicateStandardBlockMutation();

  const [isPromptBeforeDeletingOpen, setIsPromptBeforeDeletingOpen] = useState<boolean>(false);
  const [isEditStandardBlockModalOpen, setIsEditStandardBlockModalOpen] = useState<boolean>(false);
  const [isMoveToStandardBlockModalOpen, setIsMoveToStandardBlockModalOpen] = useState<boolean>(false);

  const panelRef = useRef<HTMLDivElement>(null);

  const filteredParameters = useMemo(() => standardBlock.parameters.filter(parameter => parameter.isSelected), [standardBlock.parameters]);
  const metrics = useMemo(() => standardBlock.metrics.filter(metric => metric.isSelected), [standardBlock.metrics]);
  const calculationMetrics = useMemo(() => metrics.filter(metric => metric.category === MetricCategory.METRIC), [metrics]);
  const discrepancyMetrics = useMemo(() => metrics.filter(metric => metric.category === MetricCategory.DISCREPANCY), [metrics]);
  const technicalMetrics = useMemo(() => metrics.filter(metric => metric.category === MetricCategory.TECHNICAL), [metrics]);

  const { width: panelWidth = 0 } = useResizeObserver({
    ref: panelRef,
  });

  // SLE: getCopyName function to have "entity name - copy 3" insead of "entity name - copy - copy - copy"
  const handleDuplicate: MouseEventHandler<HTMLButtonElement> = useCallback(async () => {
    try {
      const data = await duplicateStandardBlock({
        uuid: standardBlock.uuid,
        name: `${standardBlock.name} - ${t('text.copy')}`,
        folderId,
      }).unwrap();
      navigate(`/blockTypes/${folderId}/${data.uuid}`, { state: { noScrollTop: true } });
      dispatch(addToast({ message: t('toast.duplicateStandardBlockSuccess'), severity: ToastSeverity.SUCCESS }));
    } catch (error) {
      console.error(error);
    }
  }, [standardBlock, dispatch, duplicateStandardBlock, folderId, navigate, t]);

  const handleEdit: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    setIsEditStandardBlockModalOpen(true);
  }, []);

  const handleDelete: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    setIsPromptBeforeDeletingOpen(true);
  }, []);

  const handleDeleteConfirm = useCallback(async () => {
    try {
      await deleteStandardBlock(standardBlock.uuid).unwrap();
      navigate(`/blockTypes/${folderId}`, { state: { noScrollTop: true } });
      dispatch(addToast({ message: t('toast.deleteStandardBlockSuccess'), severity: ToastSeverity.SUCCESS }));
    } catch (error) {
      console.error(error);
    }
  }, [standardBlock.uuid, deleteStandardBlock, dispatch, folderId, navigate, t]);

  const handleUpdateStandardBlock = useCallback(
    (updatedStandardBlock: StandardBlockInfo) => {
      navigate(`/blockTypes/${folderId}/${updatedStandardBlock.uuid}`, { state: { noScrollTop: true } });
    },
    [navigate, folderId],
  );

  const handleMoveTo = useCallback(() => {
    setIsMoveToStandardBlockModalOpen(true);
  }, []);

  const getMetricPreview = useCallback((metric: StandardBlockMetric) => {
    return <LegoMetricPreview<StandardBlockMetric> metric={metric} />;
  }, []);

  return (
    <>
      <RightPanelActions>
        <ActionButton disabled={isDeleting || isDuplicating} startIcon={<FileCopyOutlined />} onClick={handleDuplicate}>
          {t('button.duplicate')}
        </ActionButton>
        <ActionButton disabled={isDeleting || isDuplicating} startIcon={<EditOutlined />} onClick={handleEdit}>
          {t('button.edit')}
        </ActionButton>
        <ActionButton disabled={isDeleting || isDuplicating} startIcon={<DeleteOutlined />} onClick={handleDelete}>
          {t('button.delete')}
        </ActionButton>
        <ActionButton disabled={isDeleting || isDuplicating} startIcon={<DriveFileMoveOutlined />} onClick={handleMoveTo}>
          {t('button.moveTo')}
        </ActionButton>
        <PromptBeforeDeleting open={isPromptBeforeDeletingOpen} setOpen={setIsPromptBeforeDeletingOpen} onDelete={handleDeleteConfirm} />
      </RightPanelActions>
      <RightPanelBody>
        {blockType && (
          <RightPanelSection>
            <RightPanelSectionContent>
              <Stack gap={0.5}>
                <FormLabel>{t('label.blockType')}</FormLabel>
                <BlockTypePreview redirect blockType={blockType} />
              </Stack>
            </RightPanelSectionContent>
          </RightPanelSection>
        )}
        <RightPanelSection>
          <RightPanelSectionContent>
            <FormLabel>{t('label.name')}</FormLabel>
            <Typography>{standardBlock.name}</Typography>
          </RightPanelSectionContent>
        </RightPanelSection>
        <RightPanelSection>
          <RightPanelSectionContent>
            <FormLabel>{t('label.description')}</FormLabel>
            <Typography>
              {standardBlock.description.trim().length > 0 ? standardBlock.description.trim() : t('global:text.noValue')}
            </Typography>
          </RightPanelSectionContent>
        </RightPanelSection>
        <RightPanelSection>
          <RightPanelSectionContent>
            <FormLabel>{t('label.metadata')}</FormLabel>
            <Grid container mt={0.5} spacing={1}>
              {standardBlock.standardMetadataList.map(metadata => (
                <Grid key={metadata.uuid} item xs={panelWidth > 400 ? 6 : 12}>
                  <StandardBlockMetadataPreview metadata={metadata} />
                </Grid>
              ))}
            </Grid>
            {standardBlock.standardMetadataList.length === 0 && (
              <Typography align="center" variant="subtitle2">
                {t('text.noMetadata')}
              </Typography>
            )}
          </RightPanelSectionContent>
        </RightPanelSection>
        <RightPanelSection ref={panelRef}>
          <RightPanelSectionContent>
            <FormLabel>{t('label.parameters')}</FormLabel>
            <Grid container mt={0.5} spacing={1}>
              {filteredParameters.map(parameter => (
                <Grid key={parameter.uuid} item xs={panelWidth > 400 ? 6 : 12}>
                  <LegoParameterPreview<StandardBlockParameter> parameter={parameter} />
                </Grid>
              ))}
            </Grid>
            {filteredParameters.length === 0 && (
              <Typography align="center" variant="subtitle2">
                {t('text.noParameter')}
              </Typography>
            )}
          </RightPanelSectionContent>
        </RightPanelSection>
        <RightPanelSection>
          <RightPanelSectionContent>
            <Stack spacing={1}>
              <FormLabel>{t('label.metrics')}</FormLabel>
              <Stack>
                <BlockMetricsGroup getMetricPreview={getMetricPreview} metrics={calculationMetrics} panelWidth={panelWidth} />
                <BlockMetricsGroup getMetricPreview={getMetricPreview} metrics={discrepancyMetrics} panelWidth={panelWidth} />
                <BlockMetricsGroup getMetricPreview={getMetricPreview} metrics={technicalMetrics} panelWidth={panelWidth} />
                {standardBlock.metrics.length === 0 && (
                  <Typography align="center" variant="subtitle2">
                    {t('text.noMetric')}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </RightPanelSectionContent>
        </RightPanelSection>
      </RightPanelBody>
      <EditStandardBlockModal
        folderId={folderId}
        isOpen={isEditStandardBlockModalOpen}
        setIsOpen={setIsEditStandardBlockModalOpen}
        standardBlock={standardBlock}
        onUpdate={handleUpdateStandardBlock}
      />
      <MoveToStandardBlockModal
        isOpen={isMoveToStandardBlockModalOpen}
        setIsOpen={setIsMoveToStandardBlockModalOpen}
        standardBlock={standardBlock}
      />
    </>
  );
};

export default InformationsTab;
