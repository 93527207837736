import { AddOutlined, ErrorOutlineOutlined } from '@mui/icons-material';
import { Stack, TextField, Typography, styled } from '@mui/material';
import { ChangeEventHandler, FC, KeyboardEventHandler, MouseEventHandler, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-key-enum';

import { CreateTagOperationData, NewUUID, Normalize, UUID } from '@dametis/core';

import ActionButton from 'components/UI/Buttons/ActionButton/ActionButton';
import { Hotkeys } from 'components/UI/Hotkeys/Hotkeys';
import { setColorLightness } from 'functions/color';
import { useDispatch } from 'store';
import { addToast } from 'store/slices/toast';
import { ToastSeverity } from 'types';
import { useTagEditStore } from 'zustand/stores/tagEdit';

import { MAX_TAG_NAME_LENGTH } from '../TagsList';

import ItemDropzone from './ItemDropzone';
import ItemPaper from './ItemPaper';

const AddOutlinedIcon = styled(AddOutlined)<{ disabled: boolean }>(({ theme, disabled }) => ({
  fontSize: '15px',
  width: '15px',
  height: '15px',
  color: disabled ? setColorLightness(theme.palette.grey[500], 60) : theme.palette.primary.main,
}));

const ErrorTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
}));

export const CreateTagItemPaper = styled(ItemPaper)<{ marginBottom: number; marginTop: number; error: boolean; isTyping: boolean }>(
  ({ theme, marginBottom, marginTop, error, isTyping }) => ({
    position: 'relative',
    margin: 0,
    // eslint-disable-next-line no-nested-ternary
    backgroundColor: error
      ? setColorLightness(theme.palette.error.main, 95)
      : isTyping
        ? setColorLightness(theme.palette.primary.main, 95)
        : 'transparent',
    '&:hover': {
      backgroundColor: error ? setColorLightness(theme.palette.error.main, 95) : setColorLightness(theme.palette.primary.main, 95),
    },
    '&:focus-within': {
      backgroundColor: setColorLightness(error ? theme.palette.error.main : theme.palette.primary.main, 92),
    },
    marginBottom: theme.spacing(marginBottom),
    marginTop: theme.spacing(marginTop),
  }),
);

export const CreateTagItemIcon = styled(AddOutlined)(({ theme }) => ({
  fontSize: '15px',
  width: '15px',
  height: '15px',
  color: setColorLightness(theme.palette.primary.main, 60),
}));

export interface CreateTagItemProps {
  parentId: UUID | null;
  index: number;
  marginBottom?: number;
  marginTop?: number;
  autoFocus?: boolean;
}

const CreateTagItem: FC<CreateTagItemProps> = ({ parentId, index, marginBottom = 0, marginTop = 0, autoFocus = false }) => {
  const { t } = useTranslation('tags');

  const dispatch = useDispatch();

  const addEditorTag = useTagEditStore(state => state.addEditorTag);
  const addEditorOperation = useTagEditStore(state => state.addEditorOperation);
  const disabledDropIds = useTagEditStore(state => state.disabledDropIds);

  const [newLabel, setNewLabel] = useState<string>('');

  const inputRef = useRef<HTMLInputElement | null>(null);

  const isNameInvalid = useMemo(() => newLabel.length > MAX_TAG_NAME_LENGTH, [newLabel]);
  const isDropDisabled = useMemo(() => parentId !== null && disabledDropIds.includes(parentId), [disabledDropIds, parentId]);

  const handleChangeNewLabel = useCallback<NonNullable<ChangeEventHandler<HTMLInputElement>>>(e => {
    setNewLabel(e.target.value);
  }, []);

  const handleSubmitAddTag = useCallback(() => {
    try {
      if (newLabel.trim().length === 0 || isNameInvalid) {
        return;
      }
      const data: CreateTagOperationData = {
        index,
        uuid: NewUUID(),
        name: newLabel,
        normalizedName: Normalize(newLabel),
        color: null,
        icon: null,
        parentId,
      };
      addEditorTag(data);
      addEditorOperation({
        kind: 'create',
        data,
      });
      setNewLabel('');
    } catch (error) {
      console.error(error);
      dispatch(addToast({ message: t('error.createTag'), severity: ToastSeverity.ERROR }));
    }
  }, [newLabel, isNameInvalid, parentId, addEditorTag, addEditorOperation, index, dispatch, t]);

  const handleLabelKeyboardEvents = useCallback<NonNullable<KeyboardEventHandler<HTMLDivElement>>>(
    event => {
      event.stopPropagation();
      if (event.key === 'Enter') {
        handleSubmitAddTag();
      } else if (event.key === 'Escape') {
        inputRef.current?.blur();
        setNewLabel('');
      }
    },
    [handleSubmitAddTag],
  );

  const handleLabelClick = useCallback<NonNullable<MouseEventHandler<HTMLDivElement>>>(event => {
    event.stopPropagation();
    inputRef.current?.focus();
  }, []);

  return (
    <CreateTagItemPaper
      error={isNameInvalid}
      isSelected={false}
      isTyping={newLabel.length > 0}
      marginBottom={marginBottom}
      marginTop={marginTop}
      onClick={handleLabelClick}
    >
      <Stack alignItems="center" direction="row" justifyContent="space-between" width={1}>
        <Stack alignItems="center" direction="row" flexGrow={1} spacing={1}>
          <AddOutlinedIcon disabled={isNameInvalid} />
          <TextField
            autoFocus={autoFocus}
            color="secondary"
            inputRef={inputRef}
            placeholder={t('button.createTag')}
            slotProps={{
              input: {
                autoComplete: 'off',
                disableUnderline: true,
              },
            }}
            value={newLabel}
            variant="standard"
            onChange={handleChangeNewLabel}
            onClick={handleLabelClick}
            onKeyDown={handleLabelKeyboardEvents}
          />
          {isNameInvalid && (
            <Stack alignItems="end" direction="row" ml={1} spacing={0.5}>
              <ErrorOutlineOutlined color="error" sx={{ fontSize: 15 }} />
              <ErrorTypography lineHeight={1} variant="body2">
                {t('error.invalidName', { count: newLabel.length, max: MAX_TAG_NAME_LENGTH })}
              </ErrorTypography>
            </Stack>
          )}
        </Stack>
        {newLabel.length > 0 && (
          <Stack alignItems="center" direction="row" spacing={1}>
            <ActionButton
              color="primary"
              disabled={isNameInvalid}
              endIcon={<Hotkeys hotkeys={Key.Enter} size="small" />}
              size="small"
              startIcon={<AddOutlined />}
              sx={{
                backgroundColor: theme => `${theme.palette.background.paper}!important`,
              }}
              onClick={handleSubmitAddTag}
            >
              {t('button.createTag')}
            </ActionButton>
          </Stack>
        )}
      </Stack>
      {!isDropDisabled && <ItemDropzone index={index} isExpanded={false} parentId={parentId} tagId={null} />}
    </CreateTagItemPaper>
  );
};

export default CreateTagItem;
