import { Stack, styled } from '@mui/material';

import { TAG_PICKER_ACTIONS_CLASSNAME } from './TagPicker';

export enum TagPickerInputVariant {
  OUTLINED = 'outlined',
  STANDARD = 'standard',
  FILTER = 'filter',
}

export interface TagPickerInputProps {
  variant?: `${TagPickerInputVariant}`;
  isEditing?: boolean;
}

const TagPickerInput = styled(Stack, {
  shouldForwardProp: propName => propName !== 'variant' && propName !== 'isEditing',
})<TagPickerInputProps>(({ theme, variant = 'standard', isEditing = true }) => ({
  position: 'relative',
  overflow: 'hidden',
  borderRadius: `${theme.shape.borderRadius}px`,
  padding: theme.spacing(isEditing ? 0.5 : 0),
  ...(variant === 'filter' && {
    borderRadius: 20,
    backgroundColor: theme.palette.background.default,
    borderWidth: '1.5px',
    borderStyle: 'solid',
    borderColor: theme.palette.daBlue.light,
    [`& .${TAG_PICKER_ACTIONS_CLASSNAME}`]: {
      opacity: 0,
      backgroundColor: theme.palette.background.default,
    },
    [`&:hover .${TAG_PICKER_ACTIONS_CLASSNAME}`]: {
      opacity: 1,
    },
  }),
  ...(!isEditing
    ? {
        [`& .${TAG_PICKER_ACTIONS_CLASSNAME}`]: {
          opacity: 0,
        },
        [`&:hover .${TAG_PICKER_ACTIONS_CLASSNAME}`]: {
          opacity: 1,
        },
      }
    : {
        ...(variant === 'standard' && {
          backgroundColor: theme.palette.background.paper,
          '&:hover': {
            backgroundColor: theme.palette.background.paper,
          },
          '&:focus-within': {
            backgroundColor: theme.palette.grey[100],
          },
          [`& .${TAG_PICKER_ACTIONS_CLASSNAME}`]: {
            opacity: 0,
            backgroundColor: theme.palette.background.paper,
          },
          [`&:hover .${TAG_PICKER_ACTIONS_CLASSNAME}`]: {
            opacity: 1,
            backgroundColor: theme.palette.background.paper,
          },
          [`&:focus-within .${TAG_PICKER_ACTIONS_CLASSNAME}`]: {
            backgroundColor: theme.palette.grey[100],
          },
        }),
        ...(variant === 'outlined' && {
          borderWidth: 2,
          borderStyle: 'solid',
          [`& .${TAG_PICKER_ACTIONS_CLASSNAME}`]: {
            opacity: 0,
            backgroundColor: theme.palette.background.default,
          },
          [`&:hover .${TAG_PICKER_ACTIONS_CLASSNAME}`]: {
            opacity: 1,
          },
          backgroundColor: theme.palette.background.default,
          borderColor: theme.palette.grey[300],
          '&:hover': {
            borderColor: theme.palette.grey[500],
          },
          '&:focus-within': {
            borderColor: theme.palette.grey[900],
          },
        }),
      }),
}));

export default TagPickerInput;
