import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ShortStandardBlockInfo, StandardBlockInfo, UUID, UpdateStandardBlockBody } from '@dametis/core';

import { createUpdateStandardBlockBody } from 'components/Lego/helpers/standardBlock/createUpdateStandardBlockBody';
import StepperModal from 'components/UI/StepperModal/StepperModal';
import { useDispatch } from 'store';
import { useUpdateStandardBlockMutation } from 'store/api/standardBlocks';
import { addToast } from 'store/slices/toast';
import { ToastSeverity } from 'types';

import GeneralStep from '../CreateStandardBlockModal/GeneralStep';
import MetadataListStep from '../CreateStandardBlockModal/MetadataListStep/MetadataListStep';
import ParametersAndMetricsStep from '../CreateStandardBlockModal/ParametersAndMetricsStep/ParametersAndMetricsStep';
import { getIsGeneralStepValid } from '../CreateStandardBlockModal/helpers/getIsGeneralStepValid';
import { getIsMetadataListStepValid } from '../CreateStandardBlockModal/helpers/getIsMetadataListStepValid';
import { getIsParametersAndMetricsStepValid } from '../CreateStandardBlockModal/helpers/getIsParametersAndMetricsStepValid';

export interface EditStandardBlockModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  standardBlock: ShortStandardBlockInfo;
  folderId?: UUID;
  onUpdate?: (updatedStandardBlock: StandardBlockInfo) => Promise<void> | void;
}

const EditStandardBlockModal: FC<EditStandardBlockModalProps> = ({
  isOpen,
  setIsOpen,
  standardBlock,
  folderId = undefined,
  onUpdate = undefined,
}) => {
  const { t } = useTranslation('lego');
  const dispatch = useDispatch();

  const [updateStandardBlock] = useUpdateStandardBlockMutation();

  const [updateStandardBlockBody, setUpdateStandardBlockBody] = useState<UpdateStandardBlockBody>(createUpdateStandardBlockBody);

  const isGeneralStepValid = useMemo(() => getIsGeneralStepValid(updateStandardBlockBody), [updateStandardBlockBody]);

  const isMetadataListStepValid = useMemo(() => getIsMetadataListStepValid(updateStandardBlockBody), [updateStandardBlockBody]);

  const isParametersAndMetricsStepValid = useMemo(() => getIsParametersAndMetricsStepValid(), []);

  const handleCloseModal = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const handleSubmit = useCallback(async () => {
    try {
      const data = await updateStandardBlock({ uuid: standardBlock.uuid, body: updateStandardBlockBody }).unwrap();
      if (onUpdate) {
        await onUpdate(data);
      }
      setIsOpen(false);
      dispatch(addToast({ message: t('toast.updateStandardBlockSuccess'), severity: ToastSeverity.SUCCESS }));
    } catch (error) {
      console.error(error);
    }
  }, [updateStandardBlockBody, standardBlock, updateStandardBlock, dispatch, setIsOpen, t, onUpdate]);

  useEffect(() => {
    if (isOpen) {
      setUpdateStandardBlockBody(createUpdateStandardBlockBody({ ...standardBlock, folderId }));
    }
  }, [isOpen, folderId, standardBlock]);

  const steps = useMemo(
    () => [
      {
        label: t('stepper.general'),
        component: (
          <GeneralStep<UpdateStandardBlockBody>
            isCreating={false}
            setStandardBlockBody={setUpdateStandardBlockBody}
            standardBlockBody={updateStandardBlockBody}
          />
        ),
        isInvalid: !isGeneralStepValid,
      },
      {
        label: t('stepper.metadatas'),
        component: (
          <MetadataListStep<UpdateStandardBlockBody>
            setStandardBlockBody={setUpdateStandardBlockBody}
            standardBlockBody={updateStandardBlockBody}
          />
        ),
        isInvalid: !isMetadataListStepValid,
      },
      {
        label: t('stepper.parameters'),
        component: (
          <ParametersAndMetricsStep<UpdateStandardBlockBody>
            setStandardBlockBody={setUpdateStandardBlockBody}
            standardBlockBody={updateStandardBlockBody}
          />
        ),
        isInvalid: !isParametersAndMetricsStepValid,
      },
    ],
    [isGeneralStepValid, isMetadataListStepValid, isParametersAndMetricsStepValid, t, updateStandardBlockBody],
  );

  return (
    <StepperModal
      nonLinear
      open={isOpen}
      steps={steps}
      title={t('title.editStandardBlockModal')}
      onClose={handleCloseModal}
      onSubmit={handleSubmit}
    />
  );
};

export default EditStandardBlockModal;
