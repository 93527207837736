import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockVariable } from '@dametis/core';

import { BlocksOutlined } from 'assets/icons/BlocksOutlined';

import LinkButton from '../../../../UI/Buttons/LinkButton/LinkButton';

interface Props {
  variable: Partial<BlockVariable>;
}

const BlockVariableDetails: FC<Props> = ({ variable }) => {
  const { t } = useTranslation('vnc');
  return (
    <div>
      <LinkButton size="small" startIcon={<BlocksOutlined />} to={`/lego/${variable.blockId}`}>
        {t('playground:button.configuration')}
      </LinkButton>
    </div>
  );
};

export default BlockVariableDetails;
