import { ConnectionType, MetadataType, MetricCategory } from '@dametis/core';

import { BlockTypePanelTab } from 'components/Lego/LegoCatalog/CatalogRightPanel/BlockTypeRightPanel/BlockTypeRightPanel';
import { StandardBlockPanelTab } from 'components/Lego/LegoCatalog/CatalogRightPanel/StandardBlockRightPanel/StandardBlockRightPanel';
import { MetadataListName } from 'components/Lego/UI/BlockType/CreateBlockTypeModal/MetadataListsStep';

export default {
  title: {
    lego: 'Diagrama de bloques',
    typesCatalog: 'Catálogo',
    createBlockTypeModal: 'Nuevo tipo de bloque',
    createStandardBlockModal: 'Nuevo bloque estándar',
    editBlockTypeModal: 'Editar tipo de bloque',
    blockTypePanel: 'Tipo de bloque',
    blocks: 'Bloques',
    folders: 'Carpetas',
    createBlockModal: 'Nuevo bloque',
    createFolderModal: 'Nueva carpeta',
    updateBlockModal: 'Editar bloque',
    blockFilters: 'Filtrar parámetros y métricas',
    nextAction: 'Acabas de crear un bloque. ¿Qué te gustaría hacer ahora?',
    createBlock: 'Crear bloque',
    editBlock: 'Editar bloque "{{blockName}}"',
    moveTo: 'Mover a...',
    chooseBlock_one: 'Elegir un bloque',
    chooseBlock_other: 'Elegir bloques',
    chooseBlockFromBlockType: 'Elegir un bloque "{{blockTypeName}}"',
    navigation: 'Navegación',
    editFolder: 'Editar carpeta',
    tools: 'Herramientas',
    editStandardBlockModal: 'Editar bloque estándar',
    addIncomingConnections: 'Añadir conexiones entrantes',
    removeIncomingConnection: 'Eliminar conexión entrante',
    addMissingConnection: 'Añadir conexión entrante',
  },
  subtitle: {
    folders: 'Carpetas',
    blockTypes: 'Tipos',
    standardBlocks: 'Bloques estándar',
  },
  button: {
    add: 'Añadir',
    linkBlock_one: 'Vincular bloque',
    linkBlock_other: 'Vincular bloques',
    editLink_one: 'Editar vínculo',
    editLink_other: 'Editar vínculos',
    newBlockType: 'Tipo de bloque',
    newBlock: 'Bloque',
    newFolder: 'Carpeta',
    close: 'Cerrar',
    cancel: 'Cancelar',
    create: 'Crear',
    update: 'Editar',
    duplicate: 'Duplicar',
    edit: 'Editar',
    submit: 'Enviar',
    delete: 'Eliminar',
    blocks: 'Bloques',
    goToBlocks: 'Ir a bloques',
    open: 'Abrir',
    openInPlayground: 'Abrir en playground',
    save: 'Guardar',
    sortedBy: 'Ordenar por: $t(lego:label.sortedBy.{{value}})',
    new: 'Nuevo',
    createBlock: 'Crear bloque',
    createFolder: 'Carpeta',
    moveTo: 'Mover a',
    move: 'Mover',
    newStandardBlock: 'Nuevo bloque estándar',
    confirm: 'Confirmar',
    select: 'Seleccionar',
    selectNone: 'No seleccionar',
  },
  label: {
    name: 'Nombre',
    description: 'Descripción',
    parameters: 'Parámetros',
    metrics: 'Métricas',
    connections: 'Conexiones',
    blockTypes: 'Tipos de bloque',
    comment: 'Comentario',
    parametersLength_zero: 'Sin parámetros',
    parametersLength_one: '{{count}} parámetro',
    parametersLength_other: '{{count}} parámetros',
    metricsLength_zero: 'Sin métricas',
    metricsLength_one: '{{count}} métrica',
    metricsLength_other: '{{count}} métricas',
    connectionsLength_zero: 'Sin conexiones',
    connectionsLength_one: '{{count}} conexión',
    connectionsLength_other: '{{count}} conexiones',
    standardBlocksLength_zero: 'Sin bloques estándar',
    standardBlocksLength_one: '{{count}} bloque estándar',
    standardBlocksLength_other: '{{count}} bloques estándar',
    blockTypesLength_zero: 'Sin tipos',
    blockTypesLength_one: '{{count}} tipo',
    blockTypesLength_other: '{{count}} tipos',
    foldersLength_zero: 'Sin carpetas',
    foldersLength_one: '{{count}} carpeta',
    foldersLength_other: '{{count}} carpetas',
    newBlock: 'Nuevo bloque',
    newBlockType: 'Nuevo tipo',
    newFolder: 'Nueva carpeta',
    tags: 'Etiquetas',
    options: 'Opciones',
    isVirtual: 'Métrica virtual',
    folder: 'Carpeta',
    blockType: 'Tipo de bloque',
    newStandardBlock: 'Nuevo bloque estándar',
    standardBlock: 'Bloque estándar',
    standardBlocks: 'Bloques estándar',
    groupByType: 'Agrupar por tipo',
    metadata: 'Metadatos',
    standardBlockMetadata: 'Metadatos de bloque estándar',
    blockMetadata: 'Metadatos de bloque',
    variables: 'Variables',
    [`metricCategory_${MetricCategory.METRIC}`]: 'Cálculos',
    [`metricCategory_${MetricCategory.DISCREPANCY}`]: 'Discrepancias',
    [`metricCategory_${MetricCategory.TECHNICAL}`]: 'Técnicos',
    valueType: 'Tipo de valor',
    type: 'Tipo',
    unit: 'Unidad',
    defaultValue: 'Valor por defecto',
  },
  placeholder: {
    name: 'Nombre',
    calculation: 'Cálculo',
    blockKey: 'Identificador',
    unit: 'Unidad',
    blockType: 'Tipo de bloque',
    defaultValue: 'Valor predeterminado',
    value: 'Valor',
    searchStandardBlocks: 'Buscar bloques estándar...',
    goToBlock: 'Ir al bloque',
  },
  stepper: {
    general: 'General',
    metrics: 'Métricas',
    parameters: 'Parámetros',
    blockType: 'Tipo de bloque',
    informations: 'Información',
    connections: 'Conexiones',
    metadatas: 'Metadatos',
    parametersAndMetrics: 'Parámetros y métricas',
    variables: 'Variables',
  },
  stepperContent: {
    name: 'Nombre: {{name}}',
    standardMetadataListLength_zero: 'Sin catálogo',
    standardMetadataListLength_one: '1 catálogo',
    standardMetadataListLength_other: '{{count}} catálogos',
    blockMetadataListLength_zero: 'Sin bloque',
    blockMetadataListLength_one: '1 bloque',
    blockMetadataListLength_other: '{{count}} bloques',
    parametersLength_zero: 'Sin parámetro',
    parametersLength_one: '1 parámetro',
    parametersLength_other: '{{count}} parámetros',
    connectionsLength_zero: 'Sin conexión',
    connectionsLength_one: '1 conexión',
    connectionsLength_other: '{{count}} conexiones',
    metricsLength_zero: 'Sin métrica',
    metricsLength_one: '1 métrica',
    metricsLength_other: '{{count}} métricas',
  },
  text: {
    noBlock: 'Sin bloque',
    noStandardBlock: 'Sin bloque estándar',
    noParameter: 'Sin parámetro',
    noMetric: 'Sin métrica',
    availableParameter: '{{blockKey}} ({{unit}})',
    otherParametersLength: '+{{count}} parámetro(s)',
    otherMetricsLength: '+{{count}} métrica(s)',
    metricsCount_zero: 'Sin métrica',
    metricsCount_one: 'Una métrica',
    metricsCount_other: '{{count}} métricas',
    openInPlayground: 'Abrir en playground.',
    blockVariableWithPath: '{{block}} ▸ {{variable}}',
    unusedParameters: 'Parámetros no utilizados:',
    noMetricWithHelper: 'No hay métricas disponibles para este bloque. Puedes añadir parámetros para usar más métricas.',
    parameterInvalid_one: 'Este parámetro es necesario para el funcionamiento del bloque',
    parameterInvalid_other: 'Estos parámetros son necesarios para el funcionamiento del bloque',
    parameterUndefined_one: 'Este parámetro no es obligatorio pero no está definido',
    parameterUndefined_other: 'Estos parámetros no son obligatorios pero no están definidos',
    parameterUnsetVnc: 'Este parámetro no ha sido establecido',
    home: 'Inicio',
    factory: 'Fábrica',
    noFolder: 'Sin carpeta',
    noParameterToDefine: 'Sin parámetros para definir',
    copy: 'Copiar',
    noConnection: 'Sin conexión',
    noConnectedBlock: 'Sin bloque vinculado',
    noMetadata: 'Sin metadatos',
    noOption: 'Sin opciones disponibles',
    noSelectedVariable: 'Sin variable seleccionada',
    connectionName: 'Conexión "{{name}}"',
    unknownBlock: 'Bloque desconocido',
    unknownType: 'Tipo desconocido',
    noAvailableBlock: 'Sin bloques disponibles',
    connectedBlocks: 'Bloques vinculados',
    childBlocks: 'Bloques hijos',
    emptyFolder: 'Carpeta vacía',
    noFolderAvailable: 'Sin carpetas disponibles',
    canNotLoadFolders: 'No se pueden cargar las carpetas',
    unknownName: 'Nombre desconocido',
    noSelectedOption: 'Sin opción seleccionada',
    [`noMetrics_${MetricCategory.METRIC}`]: 'Sin cálculo',
    [`noMetrics_${MetricCategory.DISCREPANCY}`]: 'Sin discrepancia',
    [`noMetrics_${MetricCategory.TECHNICAL}`]: 'Sin técnico',
    noElement: 'Sin elemento',
    deleteBlockTypeWarning_one:
      'Este tipo de bloque está vinculado a otro tipo de bloque. Su eliminación puede afectar al correcto funcionamiento de algunas herramientas.',
    deleteBlockTypeWarning_other:
      'Este tipo de bloque está vinculado a {{count}} otros tipos de bloque. Su eliminación puede afectar al correcto funcionamiento de algunas herramientas.',
    deleteChildrenWarning_one: 'Este bloque tiene un bloque hijo. Será eliminado.',
    deleteChildrenWarning_other: 'Este bloque tiene {{count}} bloques hijos. Serán eliminados.',
    deleteConnectedWarning_one:
      'Este bloque está vinculado a otro bloque. Su eliminación puede afectar al correcto funcionamiento de algunas herramientas.',
    deleteConnectedWarning_other:
      'Este bloque está vinculado a {{count}} otros bloques. Su eliminación puede afectar al correcto funcionamiento de algunas herramientas.',
    addIncomingConnections:
      'Acabas de añadir la primera conexión desde este bloque al bloque "{{name}}". Selecciona las conexiones desde el bloque "{{name}}" a este bloque.',
    removeIncomingConnection:
      'Acabas de eliminar la última conexión desde este bloque al bloque "{{name}}". ¿También quieres eliminar la conexión desde el bloque "{{name}}" a este bloque?',
    chooseBlock_one: 'Elige un bloque para vincular a este bloque "{{name}}"',
    chooseBlock_other: 'Elige bloques para vincular a este bloque "{{name}}"',
    blockConnections: 'Puedes seleccionar bloques asociados y conexiones dirigidas a este bloque.',
    addMissingConnection: 'No hay conexión a este bloque desde "{{name}}". Puedes añadir una.',
    uniqueConnectionLimitReached: 'Este bloque solo tiene conexiones únicas llenas.',
    allBlocks: 'Todos los bloques',
    anyBlockType: 'Cualquier tipo de bloque',
    metadataOptions_one: '1 opción',
    metadataOptions_other: '{{count}} opciones',
    metadataOptionsWithDefaultValue_one: '1 opción, "{{defaultValue}}" por defecto',
    metadataOptionsWithDefaultValue_other: '{{count}} opciones, "{{defaultValue}}" por defecto',
  },
  toast: {
    // FOLDER
    createFolderSuccess: 'La carpeta se ha creado correctamente',
    updateFolderSuccess: 'La carpeta se ha actualizado correctamente',
    deleteFolderSuccess: 'La carpeta se ha eliminado correctamente',
    duplicateFolderSuccess: 'La carpeta se ha duplicado correctamente',
    moveFolderSuccess: 'La carpeta se ha movido correctamente',
    // BLOCK
    createBlockSuccess: 'El bloque se ha creado correctamente',
    updateBlockSuccess: 'El bloque se ha actualizado correctamente',
    deleteBlockSuccess: 'El bloque se ha eliminado correctamente',
    duplicateBlockSuccess: 'El bloque se ha duplicado correctamente',
    moveBlockSuccess: 'El bloque se ha movido correctamente',
    // BLOCK TYPE
    createBlockTypeSuccess: 'El tipo de bloque se ha creado correctamente',
    updateBlockTypeSuccess: 'El tipo de bloque se ha actualizado correctamente',
    deleteBlockTypeSuccess: 'El tipo de bloque se ha eliminado correctamente',
    duplicateBlockTypeSuccess: 'El tipo de bloque se ha duplicado correctamente',
    moveBlockTypeSuccess: 'El tipo de bloque se ha movido correctamente',
    // STANDARD BLOCK
    createStandardBlockSuccess: 'El bloque estándar se ha creado correctamente',
    updateStandardBlockSuccess: 'El bloque estándar se ha actualizado correctamente',
    deleteStandardBlockSuccess: 'El bloque estándar se ha eliminado correctamente',
    duplicateStandardBlockSuccess: 'El bloque estándar se ha duplicado correctamente',
    moveStandardBlockSuccess: 'El bloque estándar se ha movido correctamente',
  },
  tooltip: {
    createEntity: 'crear una entidad',
    seeCalculation: 'Ver expresión',
    createBlock: 'crear un bloque',
    createFolder: 'crear una carpeta',
    createBlockType: 'crear un tipo de bloque',
    createStandardBlock: 'crear un bloque estándar',
    addBlock_one: 'Vincular bloque',
    addBlock_other: 'Vincular bloques',
    editBlock_one: 'Editar vínculo',
    editBlock_other: 'Editar vínculos',
    showIncomingConnections: 'Mostrar conexiones entrantes',
    hideIncomingConnections: 'Ocultar conexiones entrantes',
    blockDoesNotExist: 'No se puede recuperar este bloque',
    edit: 'Editar',
    delete: 'Eliminar',
    settings: 'Ajustes',
    unknownBlockType: 'Tipo de bloque desconocido',
    notWorkingStandardBlockMetric:
      'Esta métrica necesita parámetros no seleccionados sin valor predeterminado para funcionar correctamente.',
    notWorkingBlockMetric: 'Esta métrica no se puede plantillar. Asegúrate de que sus parámetros están correctamente configurados.',
    folderContentLength:
      '$t(lego:label.blockTypesLength, { "count": {{blockTypesLength}} }) / $t(lego:label.standardBlocksLength, { "count": {{standardBlocksLength}} }) / $t(lego:label.foldersLength, { "count": {{foldersLength}} })',
    withOutgoingConnection: 'Con conexión saliente',
    withIncomingConnection: 'Con conexión entrante',
    uniqueConnectionFilled: 'Esta conexión única ya está llena',
    seeBlock: 'Ver bloque',
    setAsDefault: 'Establecer por defecto',
    unsetAsDefault: 'No establecer por defecto',
  },
  connectionType: {
    [ConnectionType.UNIQUE]: 'Única',
    [ConnectionType.MULTIPLE]: 'Múltiple',
  },
  panelTab: {
    [BlockTypePanelTab.INFORMATIONS]: 'Información',
    [BlockTypePanelTab.STANDARD_BLOCKS]: 'Bloques estándar',
    [StandardBlockPanelTab.BLOCKS]: 'Bloques',
  },
  metadataType: {
    [MetadataType.STRING]: 'Cadena',
    [MetadataType.OPTION]: 'Lista de opciones',
    [MetadataType.NUMBER]: 'Valor',
  },
  metadataListName: {
    [MetadataListName.STANDARD]: 'Catálogo',
    [MetadataListName.BLOCK]: 'Bloque',
  },
  select: {
    [`metricCategory_${MetricCategory.METRIC}`]: 'Cálculo',
    [`metricCategory_${MetricCategory.DISCREPANCY}`]: 'Discrepancia',
    [`metricCategory_${MetricCategory.TECHNICAL}`]: 'Técnico',
  },
};
