import { DeleteOutlined, SettingsOutlined } from '@mui/icons-material';
import { Box, Button, Grid2, IconButton, MenuItem, Popover, Stack, TextField, Tooltip } from '@mui/material';
import { ChangeEvent, ChangeEventHandler, FC, MouseEventHandler, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BlockTypeMetadata,
  BlockTypeMetadataNumber,
  IsBlockTypeMetadataNumber,
  IsBlockTypeMetadataOption,
  IsBlockTypeMetadataString,
  IsOneOfEnum,
  MetadataType,
} from '@dametis/core';

import {
  createBlockTypeMetadataNumberContent,
  createBlockTypeMetadataOptionContent,
  createBlockTypeMetadataStringContent,
} from 'components/Lego/helpers/blockType/createBlockTypeMetadata';
import TypographyEllipse from 'components/UI/TypographyEllipse/TypographyEllipse';
import UnitPicker from 'components/UI/UnitPicker/UnitPicker';
import { UnitResult } from 'components/UI/UnitPicker/types';

import { MetadataListName } from './MetadataListsStep';
import MetadataOptionSettings from './MetadataOptionSettings';

export interface MetadataFormProps {
  value: BlockTypeMetadata;
  listName: MetadataListName;
  onChange: (newValue: BlockTypeMetadata) => void;
  onChangeListName: (newListName: MetadataListName) => void;
  onDelete: () => void;
}

const MetadataForm: FC<MetadataFormProps> = ({ value, listName, onChange, onChangeListName, onDelete }) => {
  const { t } = useTranslation('lego');

  const [settingsMenuAnchorEl, setSettingsMenuAnchorEl] = useState<HTMLButtonElement | null>(null);

  const isSettingsMenuOpen = useMemo(() => Boolean(settingsMenuAnchorEl), [settingsMenuAnchorEl]);
  const availableTypes = useMemo(() => Object.values(MetadataType), []);
  const availableListNames = useMemo(() => Object.values(MetadataListName), []);

  const handleChangeName: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      const newValue = { ...value, name: event.target.value };
      onChange(newValue);
    },
    [value, onChange],
  );

  const handleChangeType: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      const newType = event.target.value;
      if (IsOneOfEnum(newType, MetadataType) === false) {
        return;
      }
      let newContent = null;
      if (newType === MetadataType.STRING) {
        newContent = createBlockTypeMetadataStringContent();
      }
      if (newType === MetadataType.NUMBER) {
        newContent = createBlockTypeMetadataNumberContent();
      }
      if (newType === MetadataType.OPTION) {
        newContent = createBlockTypeMetadataOptionContent();
      }
      if (newContent) {
        const newValue: BlockTypeMetadata = { ...value, type: newType, content: newContent };
        onChange(newValue);
      }
    },
    [value, onChange],
  );

  const handleChangeListName: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (IsOneOfEnum(event.target.value, MetadataListName) === false) {
        return;
      }
      onChangeListName(event.target.value);
    },
    [onChangeListName],
  );

  const handleChangeUnit = useCallback(
    (unit: UnitResult) => {
      if (IsBlockTypeMetadataNumber(value)) {
        const newValue: BlockTypeMetadataNumber = { ...value, content: { ...value.content, unit } };
        onChange(newValue);
      }
    },
    [value, onChange],
  );

  const handleChangeDefaultValue = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (typeof event.target.value === 'string' || IsBlockTypeMetadataString(value)) {
        onChange({ ...value, content: { ...value.content, defaultValue: event.target.value } });
      }
      if (typeof event.target.value === 'number' || IsBlockTypeMetadataOption(value)) {
        onChange({ ...value, content: { ...value.content, defaultValue: event.target.value } });
      }
    },
    [value, onChange],
  );

  const handleDelete = useCallback(() => {
    onDelete();
  }, [onDelete]);

  const handleOpenSettingsMenu: MouseEventHandler<HTMLButtonElement> = useCallback(event => {
    setSettingsMenuAnchorEl(event.currentTarget);
  }, []);

  const handleCloseSettingsMenu = useCallback(() => {
    setSettingsMenuAnchorEl(null);
  }, []);

  return (
    <Stack alignItems="center" direction="row" spacing={1}>
      <Stack flexGrow={1} spacing={1}>
        <Grid2 container spacing={1}>
          <Grid2 size={{ xs: 5 }}>
            <TextField fullWidth select label={t('label.type')} value={listName} onChange={handleChangeListName}>
              {availableListNames.map(availableListName => (
                <MenuItem key={availableListName} value={availableListName}>
                  {t(`metadataListName.${availableListName}`)}
                </MenuItem>
              ))}
            </TextField>
          </Grid2>
          <Grid2 size={{ xs: 'grow' }}>
            <TextField fullWidth label={t('label.name')} value={value.name} onChange={handleChangeName} />
          </Grid2>
        </Grid2>
        <Grid2 container spacing={1}>
          <Grid2 size={{ xs: 5 }}>
            <TextField fullWidth select label={t('label.valueType')} value={value.type} onChange={handleChangeType}>
              {availableTypes.map(availableType => (
                <MenuItem key={availableType} value={availableType}>
                  {t(`metadataType.${availableType}`)}
                </MenuItem>
              ))}
            </TextField>
          </Grid2>
          {IsBlockTypeMetadataString(value) && (
            <Grid2 size={{ xs: 'grow' }}>
              <TextField fullWidth label={t('label.defaultValue')} value={value.content.defaultValue} onChange={handleChangeDefaultValue} />
            </Grid2>
          )}
          {IsBlockTypeMetadataNumber(value) && (
            <>
              <Grid2 size={{ xs: 'grow' }}>
                <UnitPicker freeMode label={t('label.unit')} value={value.content.unit} onChange={handleChangeUnit} />
              </Grid2>
              <Grid2 size={{ xs: 'grow' }}>
                <TextField
                  fullWidth
                  label={t('label.defaultValue')}
                  type="number"
                  value={value.content.defaultValue}
                  onChange={handleChangeDefaultValue}
                />
              </Grid2>
            </>
          )}
          {IsBlockTypeMetadataOption(value) && (
            <>
              <Grid2 alignItems="center" display="flex" size={{ xs: 'grow' }}>
                <Stack alignItems="center" direction="row" flexGrow={1} pl={1} pt={3} spacing={1}>
                  <Box flexGrow={1} width={0}>
                    <TypographyEllipse noWrap variant="subtitle2">
                      {t(
                        value.content.availableOptions.find(option => option.uuid === value.content.defaultSelectedOptionId) !== undefined
                          ? 'text.metadataOptionsWithDefaultValue'
                          : 'text.metadataOptions',
                        {
                          count: value.content.availableOptions.length,
                          defaultValue: value.content.availableOptions.find(option => option.uuid === value.content.defaultSelectedOptionId)
                            ?.value,
                        },
                      )}
                    </TypographyEllipse>
                  </Box>
                  <Tooltip title={t('tooltip.settings')}>
                    <IconButton size="small" onClick={handleOpenSettingsMenu}>
                      <SettingsOutlined fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Grid2>
            </>
          )}
        </Grid2>
      </Stack>
      <Tooltip title={t('tooltip.delete')}>
        <IconButton size="small" sx={{ height: 'fit-content' }} onClick={handleDelete}>
          <DeleteOutlined />
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={settingsMenuAnchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        open={isSettingsMenuOpen}
        transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={handleCloseSettingsMenu}
      >
        <Stack alignItems="flex-end">
          <Box p={1} width={300}>
            {IsBlockTypeMetadataOption(value) && <MetadataOptionSettings value={value} onChange={onChange} />}
          </Box>
          <Button color="primary" variant="text" onClick={handleCloseSettingsMenu}>
            {t('button.close')}
          </Button>
        </Stack>
      </Popover>
    </Stack>
  );
};

export default MetadataForm;
