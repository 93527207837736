import { Stack, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FolderInfo, ShortBlockTypeInfo } from '@dametis/core';

import { getBlockTypePath } from 'components/Lego/helpers/getBlockTypePath';
import { getBlockTypeUrl } from 'components/Lego/helpers/getBlockTypeUrl';
import TypographyEllipse from 'components/UI/TypographyEllipse/TypographyEllipse';
import { useReadGlobalRootFolderQuery } from 'store/api/globalFolders';

import LegoPreviewPaper from '../../LegoPreviewPaper';
import BlockTypeIcon from '../BlockTypeIcon';

export interface BlockTypePreviewProps {
  blockType: ShortBlockTypeInfo;
  redirect?: boolean;
}

const BlockTypePreview: FC<BlockTypePreviewProps> = ({ blockType, redirect = false }) => {
  const { t } = useTranslation('lego');

  const { data: globalRootFolder = null } = useReadGlobalRootFolderQuery();

  const parsedGlobalRootFolder: FolderInfo | null = useMemo(
    () => (globalRootFolder ? { ...globalRootFolder, name: t('lego:text.factory') } : null),
    [globalRootFolder, t],
  );

  const url = useMemo(() => getBlockTypeUrl(globalRootFolder, blockType.uuid) ?? '#', [globalRootFolder, blockType.uuid]);

  const foldersPath = useMemo(
    () => (parsedGlobalRootFolder ? getBlockTypePath(parsedGlobalRootFolder, blockType.uuid) : []),
    [parsedGlobalRootFolder, blockType.uuid],
  );

  const path = useMemo(() => foldersPath.map(folder => folder.name).join(' › '), [foldersPath]);

  return (
    <>
      <LegoPreviewPaper
        redirect={redirect}
        sx={{ textDecoration: 'inherit', background: theme => theme.palette.background.default, p: 1 }}
        url={url}
      >
        <Stack alignItems="center" direction="row" gap={1}>
          <BlockTypeIcon primaryIconColor={blockType.content.color} />
          <Stack flexGrow={1} width={0}>
            <TypographyEllipse variant="caption">{path}</TypographyEllipse>
            <Typography>{blockType.name}</Typography>
          </Stack>
        </Stack>
      </LegoPreviewPaper>
    </>
  );
};

export default BlockTypePreview;
