import i18next from 'i18next';

import { BoxInfo, SyncBoxProgress, SyncBoxStatus, WorkerImplementedFeature } from '@dametis/core';

import { boxStatus, subscribeSyncProgress, unsubscribeSyncProgress } from 'functions/socketIo';
import { sdk } from 'sdk';
import { boxesEndpoints, selectBoxes } from 'store/api/boxes';
import { setBoxesStatuses } from 'store/slices/configuration';

import { ToastSeverity } from '../../types/toast';
import { TypedThunk } from '../index';
import { setInfos, setIsSynchronizing, setLastSyncId } from '../slices/currentBox';
import { addToast } from '../slices/toast';

import { displaySdkErrorToast } from './toasts';

export const getCurrentBox =
  (boxId: string): TypedThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const oldBox = getState()?.currentBox?.infos;
    if (oldBox && oldBox.uuid !== boxId && oldBox.implementedFeatures.includes(WorkerImplementedFeature.SYNCV2)) {
      try {
        await unsubscribeSyncProgress(oldBox.uuid);
      } catch (err) {
        console.error(err);
      }
    }

    let box: BoxInfo;

    try {
      const { data } = await sdk.box.Read(boxId);
      box = data;
    } catch (err) {
      console.error(err);
      return;
    }

    try {
      dispatch(setInfos(box));
      const { data: boxes = [] } = selectBoxes()(getState());
      await dispatch(getBoxesStatus(boxes));
      if (box.implementedFeatures.includes(WorkerImplementedFeature.SYNCV2)) {
        await subscribeSyncProgress(box.uuid, progress => dispatch(handleSyncBoxV2Progress(box.uuid, progress)));
      }
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
    }
  };

export const syncBox =
  (boxId?: string, force = false): TypedThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const uuid = boxId ?? getState().currentBox.infos?.uuid;

    if (!uuid) throw new Error('Current box is undefined');

    const { data: boxes = [] } = selectBoxes()(getState());
    const box = boxes.find(box => box.uuid === uuid);
    if (!box) {
      return;
    }

    try {
      dispatch(setIsSynchronizing(true));
      dispatch(addToast({ severity: ToastSeverity.INFO, message: i18next.t('toast:waitingSyncConfigBox') }));
      if (box.implementedFeatures.includes(WorkerImplementedFeature.SYNCV2)) {
        const { syncId } = await dispatch(
          boxesEndpoints.synchronizeBoxV2.initiate({ uuid, ...(force && { request: { params: { force: true } } }) }),
        ).unwrap();
        dispatch(setLastSyncId(syncId));
      } else {
        await dispatch(boxesEndpoints.synchronizeBox.initiate({ uuid, ...(force && { request: { params: { force: true } } }) })).unwrap();
        await dispatch(getCurrentBox(uuid));
        dispatch(setIsSynchronizing(false));
        dispatch(addToast({ severity: ToastSeverity.SUCCESS, message: i18next.t('toast:successSyncConfigBox') }));
      }
    } catch (err) {
      console.error(err);
      dispatch(setIsSynchronizing(false));
    }
  };

// TODO: SLE
export const handleSyncBoxV2Progress =
  (boxId: string, progress: SyncBoxProgress): TypedThunk<Promise<void>> =>
  async (dispatch, getState) => {
    if (progress.syncId !== getState().currentBox.lastSyncId) {
      // we receive all syncProgresses, even those triggered by other users
      return;
    }
    switch (progress.status) {
      case SyncBoxStatus.PENDING: {
        break;
      }
      case SyncBoxStatus.CANCELED: {
        dispatch(addToast({ severity: ToastSeverity.INFO, message: i18next.t('toast:canceledSyncConfigBox') }));
        dispatch(setIsSynchronizing(false));
        break;
      }
      case SyncBoxStatus.UP_TO_DATE: {
        dispatch(addToast({ severity: ToastSeverity.INFO, message: i18next.t('toast:upToDateSyncConfigBox') }));
        dispatch(setIsSynchronizing(false));
        break;
      }
      case SyncBoxStatus.ERROR: {
        dispatch(addToast({ severity: ToastSeverity.ERROR, message: i18next.t('toast:errorSyncConfigBox') }));
        dispatch(setIsSynchronizing(false));
        break;
      }
      case SyncBoxStatus.FINISHED: {
        dispatch(addToast({ severity: ToastSeverity.SUCCESS, message: i18next.t('toast:successSyncConfigBox') }));
        await dispatch(getCurrentBox(boxId));
        dispatch(setIsSynchronizing(false));
        break;
      }
      case SyncBoxStatus.IN_PROGRESS: {
        // TODO SLE
        break;
      }
      default: {
        // Seems like exhaustive check isnt safe enough for ESlint :')
      }
    }
  };

export const updateBoxData =
  (boxId: string, data: BoxInfo): TypedThunk<void> =>
  (dispatch, getState) => {
    const { infos: currentBox } = getState().currentBox;
    if (currentBox?.uuid === boxId) {
      dispatch(setInfos(data));
    }
  };

export const getBoxesStatus =
  (boxes: BoxInfo[]): TypedThunk<Promise<void>> =>
  async dispatch => {
    try {
      // eslint-disable-next-line @dametis/no-promise-all-map
      const statuses = await Promise.allSettled(boxes.map(box => boxStatus(box.uuid)));
      dispatch(setBoxesStatuses(statuses.reduce((a, v, i) => ({ ...a, [boxes[i].uuid]: v.status === 'fulfilled' ? v.value : null }), {})));
    } catch (err) {
      console.error(err);
      dispatch(
        addToast({
          severity: ToastSeverity.WARNING,
          message: i18next.t('toast:errorBoxVersion'), // wtf
        }),
      );
    }
  };
