import { ErrorName } from '@dametis/core';

export const DEFAULT_ERROR = 'An error occurred. Please try again later.';

const alerts: Record<ErrorName, string> = {
  USER_NOT_FOUND: 'User not found',
  USER_BAD_SIGNIN: 'Wrong password',
  // Common
  UNKNOWN: DEFAULT_ERROR,
  BAD_JSON: DEFAULT_ERROR,
  ROUTE_NOT_FOUND: DEFAULT_ERROR,
  VALIDATION: DEFAULT_ERROR,
  TOO_MANY_REQUESTS: 'Too many requests',
  NOT_IMPLEMENTED: DEFAULT_ERROR,
  // Session
  SESSION_ID_MISSING: DEFAULT_ERROR,
  SESSION_UNAUTHORIZED: DEFAULT_ERROR,
  SESSION_EXPIRED: 'Session has expired, please reconnect',
  // PreSession
  PRESESSION_EXPIRED: 'Session has expired, please reconnect',
  PRESESSION_ID_MISSING: DEFAULT_ERROR,
  PRESESSION_UNAUTHORIZED: DEFAULT_ERROR,
  // Token
  TOKEN_MISSING: DEFAULT_ERROR,
  TOKEN_UNAUTHORIZED: DEFAULT_ERROR,
  TOKEN_INVALID: DEFAULT_ERROR,
  TOKEN_ALREADY_EXISTS: DEFAULT_ERROR,
  // Jwt
  JWT_INVALID: DEFAULT_ERROR,

  CANNOT_SELF_IMPERSONATE: DEFAULT_ERROR,

  // 2FA
  USER_HAS_NO_TWOFA: 'Something wrong happened',
  TWOFA_ALREADY_ACTIVATED: 'This 2FA method is already activated with the same email/phone number',
  TWOFA_INVALID_TYPE: DEFAULT_ERROR,
  WRONG_ENROLL_TWOFA_CODE: 'Code verification failed',
  EXPIRED_ENROLL_TWOFA_CODE: 'Code has expired, please retry with a new code',
  TWOFA_CHANGE_UNAUTHORIZED: 'Cannot change 2FA, user needs at least 2 activated methods to be able to change one',
  USER_HAS_TWOFA: 'User needs to authenticate via 2FA',
  USER_TWO_FA_NOT_ACTIVATED: 'This 2FA method is not enabled so you cannot disable it',
  // CORS
  UNAUTHORIZED_CORS_ORIGIN: DEFAULT_ERROR,
  // Browser
  UNSUPPORTED_BROWSER: 'Browser is not supported',
  // User
  USER_INVALID_PASSWORD: 'Please choose a stronger password. Use a mix of letters, numbers and symbols',
  USER_INVALID_EMAIL: 'User email address seems invalid',
  USER_ALREADY_EXISTS: 'This user already exists',
  USER_ACCOUNT_NOT_ACTIVE: 'User account is not active',
  USER_ACCOUNT_NOT_INVITING: 'User is not invited',
  USER_BAD_PASSWORD: 'Authentication failed',
  USER_HAS_NO_PASSWORD: 'User has no password',
  USER_HAS_NO_PASSWORD_POLICY: DEFAULT_ERROR,
  USER_HAS_NOT_FINALIZED_INVITATION: 'User has not finalized their invitation',
  USER_ALREADY_HAS_GROUP: 'User already has a group',
  USER_MISMATCH_GROUP_SITE: DEFAULT_ERROR,
  USER_CANNOT_DELETE_CURRENT_SESSION: DEFAULT_ERROR,
  INVALID_ID: DEFAULT_ERROR,
  USER_HAS_NO_GROUP: DEFAULT_ERROR,
  USER_MISMATCH_HEIR_GROUP: DEFAULT_ERROR,
  USER_STILL_HAS_ENTITIES: 'User still has associated entities',
  // Permission
  USER_ACCESS_DENIED: 'You do not have the appropriate permissions to access the requested resource',
  INVALID_ACL: DEFAULT_ERROR,
  // Group
  GROUP_NOT_FOUND: 'Requested group could not be found',
  // Site
  SITE_NOT_FOUND: 'Requested site could not be found',
  SITE_MISMTACH: DEFAULT_ERROR,
  SITE_HAS_NO_GROUP: DEFAULT_ERROR,
  // Site invitation
  SITE_INVITATION_NOT_FOUND: 'Invitation could not be found',
  SITE_NO_DEFAULT_ROLE: 'Site has no default role',
  SITE_INVITATION_BAD_EXPIRES_AT: 'Invitation must expire within one month',
  SITE_INVITATION_EXPIRED: 'Invitation has expired',
  // Variable list
  VARIABLE_LIST_NOT_FOUND: 'Requested variable list could not be found',
  // Box
  BOX_NOT_FOUND: 'Requested box could not be found',
  BOX_HAS_NO_SITE: DEFAULT_ERROR,
  BOX_FAILED_TO_SYNCHRONIZE: 'Box synchronization failed',
  BOX_FEATURE_NOT_IMPLEMENTED: 'This feature is not implemented in this box',
  BOX_UNREACHABLE: 'The box seems to be offline',
  BOX_EXPLORATION_DEVICE: 'Error in request to device',

  // Ansible Box
  ANSIBLEBOX_NOT_FOUND: 'Requested ansible box could not be found',
  ANSIBLEBOX_LOCKED: 'Requested ansible box is already in setup or previous setup failed',
  ANSIBLEBOX_NOT_READY: 'Ansible box is not ready for setup, try later',

  // Awx
  AWX_JOB_NOT_CREATED: DEFAULT_ERROR,
  UNKNOWN_AWX_JOB: DEFAULT_ERROR,
  AWX_HOST_DELETION_FAILURE: DEFAULT_ERROR,
  AWX_HOST_INSERTION_FAILURE: DEFAULT_ERROR,
  UNKNOWN_AWX_INVENTORY: DEFAULT_ERROR,
  // Device
  DEVICE_NOT_FOUND: 'Requested device could not be found',
  DEVICE_INVALID_PROTOCOL: DEFAULT_ERROR,
  DEVICE_HAS_VARIABLES: 'Device has associated variables',
  DEVICE_AND_VARIABLE_INCOMPATIBLE: DEFAULT_ERROR,
  CANNOT_CHANGE_QUERYSTRATEGY: 'Change device mode of an existing device is forbidden',
  // Brand device
  BRAND_DEVICE_NOT_FOUND: 'Requested brand device could not be found',
  BRAND_DEVICE_HAS_DEVICES: 'Brand device has devices',
  // Variable
  VARIABLE_NOT_FOUND: 'Requested variable was not found',
  VARIABLE_UNKNOWN_KIND: 'Requested variable type is unknown',
  VARIABLE_MEASUREMENT_NOT_FOUND: DEFAULT_ERROR,
  VARIABLE_BAD_REQUEST: DEFAULT_ERROR,
  VARIABLE_HAS_DEPENDANTS: 'Variable has dependants and cannot be deleted',
  VARIABLE_HAS_CIRCUIT: 'Creation failed, the variable references itself in the calculation or in a sub-dependency of the calculation',
  VARIABLE_MCO_OF_SITE: DEFAULT_ERROR,
  VARIABLE_MCO_INVALID_RANGE: DEFAULT_ERROR,
  VARIABLE_HAS_NO_REAL_NOR_GROUP_BY:
    'Creation failed, calculated variables must have at least one acquired variable or one groupBy as dependency',
  VARIABLE_IMPORT_INVALID_COLUMNS: 'Columns of the imported variable are invalid',
  VARIABLE_MISSING_VERSION: 'Variable has missing version',
  VARIABLE_PREVIEW_FAILED: 'Preview failed',
  VARIABLE_IS_NOT_CALCULATED_KIND: 'Variable is not calculated kind',
  VARIABLE_INTERMEDIATE_NOT_EDITABLE: 'Deleting an intermediate variable is forbidden',
  // Variable set
  VARIABLE_SET_INVALID_ARGS: DEFAULT_ERROR,
  PLAYGROUND_SET_INVALID_ARGS: DEFAULT_ERROR,
  REPORT_SET_INVALID_ARGS: DEFAULT_ERROR,
  SYNOPTIC_SET_INVALID_ARGS: DEFAULT_ERROR,
  MODEL_SET_INVALID_ARGS: DEFAULT_ERROR,
  // Data
  DATA_NOT_FOUND: DEFAULT_ERROR,
  MISSING_MEASUREMENT: DEFAULT_ERROR,
  PARSING_VARCALC: DEFAULT_ERROR,
  UNKNOWN_NODE_TYPE: DEFAULT_ERROR,
  UNKNOWN_FUNCTION: 'Unknown function',
  UNKNOWN_SYMBOL: 'Unknown symbol',
  GROUPBY_FORBIDDEN: 'Sub-period is not allowed on this variable',
  BAD_GROUPBY_CALCULATION: 'Invalid sub-period calculation',
  MISSING_MOVING_AVERAGE_ARGS: DEFAULT_ERROR,
  MISSING_INDEX_ARGS: DEFAULT_ERROR,
  FILL_SETTING_UNKNOWN: 'Fill setting is unknown',
  FORBIDDEN_FILL_SETTING: 'Requested fill setting is forbidden',
  FORBIDDEN_OPERATOR: 'Requested operator is forbidden',
  MISSING_TADA_ARGUMENT: DEFAULT_ERROR,
  UNDEFINED_TIME: DEFAULT_ERROR,
  TOO_MANY_VARS: 'Too many variables are being requested',
  INVALID_GROUPBY_PERIODS: DEFAULT_ERROR,
  INVALID_FLAG: 'Flag is invalid',
  INVALID_OPERATOR_ARGS: DEFAULT_ERROR,
  INVALID_VARIABLE: DEFAULT_ERROR,
  UNIT_INVALID: DEFAULT_ERROR,
  UNIT_INVALID_CONVERSION: DEFAULT_ERROR,
  UNKNOWN_OPERATOR: 'Unknown operator',
  // Alarm
  ALARM_NOT_FOUND: 'Requested alarm was not found',
  ALARM_MCO_NOT_WRITABLE: 'This alarm is not writable',
  CONDITION_NOT_FOUND: DEFAULT_ERROR,
  INCIDENT_NOT_FOUND: 'Requested incident was not found',
  INCIDENT_ALREADY_ACKED: 'Incident has already been acknowledged',
  INCIDENT_INVALID: DEFAULT_ERROR,
  INVALID_EXTENDED_CONDITION: DEFAULT_ERROR,
  EVENT_NOT_FOUND: 'Event was not found',
  EVENT_INVALID: DEFAULT_ERROR,
  // Synoptic
  SYNOPTIC_NOT_FOUND: 'Requested synoptic was not found',
  // Synoptic widget
  SYNOPTIC_WIDGET_NOT_FOUND: 'Requested synoptic widget was not found',
  // Playground
  PLAYGROUND_NOT_FOUND: 'Playground not found',
  PLAYGROUND_NOT_WRITABLE: 'Playground is not writable',
  // Report
  REPORT_NOT_FOUND: 'Report not found',
  REPORT_NOT_WRITABLE: 'Report is not writable',
  REPORT_VARIABLE_NOT_FOUND: 'Variable in report not found',
  REPORT_NOT_IN_GROUP_SCOPE: 'Report does not belong to this group',
  REPORT_NOT_IN_SITE_SCOPE: 'Report does not belong to this site',
  // Data
  INVALID_MATH_EXPRESSION: 'Math expression is invalid',
  MATH_EVALUATION: 'Error occurred during math evaluation',
  INCOMPLETE_DATA: 'Data provided is incomplete',
  MATH_MISSING_DATA: 'Missing data on some variables',
  INVALID_GROUP_BY: DEFAULT_ERROR,
  NO_VARS_PROVIDED: DEFAULT_ERROR,
  DIFFERENT_DATA_SIZE: DEFAULT_ERROR,
  INVALID_PERIOD: DEFAULT_ERROR,
  PERIOD_REQUIRED: DEFAULT_ERROR,
  AN_ERROR_OCCURRED: DEFAULT_ERROR,
  // Math Data
  UNKNOWN_MATH_DATA_OPERATOR: DEFAULT_ERROR,
  REQUIRED_GROUPBY: DEFAULT_ERROR,
  GROUPBY_MUST_BE_EQUALS: DEFAULT_ERROR,
  MISSING_RETRO_CALCULATED_SINCE: DEFAULT_ERROR,
  MISSING_GLOBAL_GROUPBY_CALC: DEFAULT_ERROR,
  // Clickouse
  INVALID_QUERY: DEFAULT_ERROR,
  CREDENTIALS_NOT_FOUND: DEFAULT_ERROR,
  NO_POINTS_RETURNED: DEFAULT_ERROR,
  MAX_BUCKET_COUNT_EXCEEDED: 'Too much data, please reduce time range and/or increase sub-period',
  MAX_PERIODS_COUNT_EXCEEDED: 'Maximum time periods exceeded',
  MOVING_AVERAGE_EXPECTS_ARGS: DEFAULT_ERROR,
  FORBIDDEN_HISTORY_WITH_GROUP_BY: DEFAULT_ERROR,
  CLICKHOUSE_UNAVAILABLE: DEFAULT_ERROR,
  CLICKHOUSE_NETWORK_ERROR: DEFAULT_ERROR,
  // Rabbit
  RABBIT_UNAVAILABLE: DEFAULT_ERROR,
  // Minio
  MINIO_UNAVAILABLE: DEFAULT_ERROR,
  BAD_PREVIEW_TYPE: 'Invalid preview type',
  CORRUPTED_PREVIEW: 'Corrupted image',
  NO_PREVIEW_DEFINED: 'No preview defined',
  HEAVY_PREVIEW: 'Image is too heavy',
  // Colossus
  COLOSSUS_UNAVAILABLE: DEFAULT_ERROR,
  // Email
  EMAIL_FAILURE: DEFAULT_ERROR,
  EMAIL_MESSAGE_NOT_FOUND: DEFAULT_ERROR,
  // Streaming
  STREAMING_INVALID_BODY: DEFAULT_ERROR,
  STREAMING_HANDLER_ALREAD_USED: DEFAULT_ERROR,
  STREAMING_AUTHENTICATION_FAILURE: DEFAULT_ERROR,
  STREAMING_REQUEST_LIMIT_REACHED: DEFAULT_ERROR,
  STREAMING_HANDLER_NOT_FOUND: DEFAULT_ERROR,
  // Project
  PROJECT_NOT_FOUND: 'Project not found',
  PROJECT_INVALID: 'Project invalid',
  PROJECT_TEMPLATE_NOT_FOUND: 'Project template not found',
  PROJECT_TEMPLATE_HAS_PROJECTS: 'Template project has projects',
  ASSET_NOT_FOUND: 'Asset not found',
  TASK_NOT_FOUND: 'Task not found',
  IMAGE_NOT_FOUND: 'Image not found',
  // Calendar
  CALENDAR_NOT_FOUND: 'Calendar not found',
  // Role
  ROLE_NOT_FOUND: DEFAULT_ERROR,
  ROLE_FORBIDDEN: DEFAULT_ERROR,
  // Batch
  BATCH_NOT_FOUND: 'Batch not found',
  BATCH_HEIGHT_TOO_HIGH: 'Batch height is too high',
  // Comment
  COMMENT_NOT_FOUND: 'Comment not found',
  COMMENT_INVALID: 'Comment invalid',
  // Users List
  USER_LIST_NOT_FOUND: 'User list not found',
  // API
  API_UNAVAILABLE: DEFAULT_ERROR,
  // BULL
  BULL_UNAVAILABLE: DEFAULT_ERROR,
  // MONGO
  MONGO_UNAVAILABLE: DEFAULT_ERROR,
  // Model
  MODEL_NOT_FOUND: 'Requested model was not found',
  // Activity
  ACTIVITY_NOT_FOUND: 'Requested activity was not found',
  ACTIVITY_NOT_RESTORABLE: 'Requested activity cannot be restored',
  // Tag
  TAG_NOT_FOUND: 'Requested tag was not found',
  TAG_ALREADY_EXISTS: 'A tag with that name already exists',
  TAG_NAME_EMPTY: 'A tag cannot be empty',
  TAG_EDITOR_FAILURE: DEFAULT_ERROR,
  // Macro
  MACRO_NOT_FOUND: 'Requested macro was not found',
  MACRO_JOB_NOT_FOUND: 'Requested macro job was not found',
  MACRO_JOB_NOT_CREATED: 'macro job could not be created',
  // Macro File
  MACRO_FILE_NOT_FOUND: 'Requested macro file was not found', // Macro Library
  MACROLIB_NOT_FOUND: 'Requested macro library was not found',
  // Streaming
  SOCKET_IO: DEFAULT_ERROR,
  // Billing
  CONTRACT_NOT_FOUND: 'Requested contract was not found',
  CONTRACT_HAS_BILLS: 'Requested contract has associated bills and cannot be deleted',
  BILL_NOT_FOUND: 'Requested bill was not found',
  BILL_INVALID_DATES: 'Bill dates are not included in contract dates',
  // AlarmConnector
  ALARM_CONNECTOR_NOT_FOUND: 'Requested alarm connector was not found',
  ALARM_CONNECTOR_HAS_DEPENDANTS: 'Requested alarm connector has dependants and cannot be deleted',
  // Block
  BLOCK_NOT_FOUND: 'Requested block was not found',
  BLOCK_METRIC_TEMPLATING_FAILURE: DEFAULT_ERROR,
  METRIC_NOT_FOUND: DEFAULT_ERROR,
  METRIC_NOT_VIRTUAL: DEFAULT_ERROR,
  // Block Type
  BLOCK_TYPE_NOT_FOUND: 'Requested block type was not found.',
  BLOCK_TYPE_DELETE_USED_PARAMETER: 'Requested block type parameter is in use and cannot be deleted.',
  BLOCK_TYPE_DELETE_USED_METRIC: 'Requested block type metric is in use and cannot be deleted.',
  BLOCK_TYPE_PARAMETERS_METRICS_NON_UNIQUE: 'block type parameters and metrics must be unique.',
  // Stantard block
  STANDARD_BLOCK_NOT_FOUND: 'Requested standard block was not found',
  MISSING_VERSION: DEFAULT_ERROR,
  TOO_MANY_POINTS: DEFAULT_ERROR,
  UNKNOWN_VAR_CALC: DEFAULT_ERROR,
  UNSUPPORTED_CALCULATION: DEFAULT_ERROR,
  MODEL_KEY_NOT_FOUND: DEFAULT_ERROR,
  ALARM_SET_INVALID_ARGS: DEFAULT_ERROR,
  // Consul
  CONSUL_UPDATE_CONFIG: DEFAULT_ERROR,
  // Folder
  FOLDER_NOT_FOUND: 'Folder not found',
  FOLDER_ROOT_ALREADY_EXISTS: 'Root folder already exists',
  FOLDER_INVALID_OPERATION: 'Invalid operation',
  // Authentication
  AUTHENTICATION_ALREADY_EXISTS: "The authentication's name is already used",
  AUTHENTICATION_NOT_FOUND: 'Requested authentication not found',
  AUTHENTICATION_USED_AS_FALLBACK: 'You cannot remove an authentication which is used as fallback',
  AUTHENTICATION_USES_ITSELF_AS_FALLBACK: 'Authentication cannot use itself as fallback',
  CANNOT_DELETE_DEFAULT_PASSWORD: 'You cannot delete default authentication method',
  // Twilio
  TWILIO_MESSAGE_NOT_FOUND: DEFAULT_ERROR,
  TWILIO_MESSAGE_NOT_SEND: DEFAULT_ERROR,
  LOCALE_NOT_FOUND: DEFAULT_ERROR,
  INVALID_PHONE_NUMBER: 'Phone number incorrect',
  // StyleConfiguration
  STYLE_CONFIGURATION_NOT_FOUND: 'Requested Style configuration was not found.',
  // Alias
  ALIAS_NOT_FOUND: 'Requested Alias was not found.',
  // DataOperation
  DATAOPERATION_NOT_FOUND: 'Requested Data Operation was not found',
  CANNOT_RETRY_DATA_OPERATION: 'Cannot retry Data Operation',
  CANNOT_REIMPORT_DATA_OPERATION: 'Cannot retry Import Data Operation',
  CANNOT_REVERT_DATA_OPERATION: 'Cannot revert Data Operation',
  // EditVariablesData
  EDIT_VARIABLES_DATA_BAD_REQUEST: DEFAULT_ERROR,
  // FileImport
  FILEIMPORT_NOT_FOUND: 'File import configuration not found',
  FILE_ALREADY_EXISTS: 'File already exists',
  FILE_NOT_CREATED: 'File has not been created',
  FILE_MAX_SIZE_EXCEEDED: 'File exceed allowed maximum size',
  FILE_DOES_NOT_EXISTS: 'File does not exist',
  FILE_PARSE_ERROR: 'Unable to parse file',
  // Webauthn
  WEBAUTHN_ERROR: DEFAULT_ERROR,
  WEBAUTHN_EXPIRED_CHALLENGE: 'Challenge expired. Please try again',
  WEBAUTHN_WRONG_PASSKEY: 'The key provided is not known by the server',
  WEBAUTHN_INVALID_SIGNATURE: "The key's signature could not be verified by the server",
  WEBAUTHN_PASSKEY_REGISTRATION: 'The key provided could not be registered by the server',

  BLOCK_TYPE_HAS_STANDARD_BLOCKS: 'The Block Type has Standard Blocks',
  STANDARD_BLOCK_HAS_BLOCKS: 'The Standard Block has Blocks',

  // Opco
  OPCO_NOT_FOUND: 'Vapor blend not found',
  OPCO_UNAVAILABLE_ON_SITE: 'Vapor blend unavailable on your site',
  OPCO_INPUT_FILE_ALREADY_UPLOADED: 'Input file has already been uploaded',
  OPCO_INPUT_FILE_WRONG_MIMETYPE: 'Input file extension is invalid',
  OPCO_DELETE_RUNNING_OPCO: 'Cannot delete a running vapor blend',
  OPCO_FILE_NOT_FOUND: 'File not found',
  OPCO_DOWNLOAD_TEMPLATE_ERROR: 'An error occurred while downloading the template',
  CREATE_REPORT_OPCO_STATUS_NOT_SUCCESS: 'Report creation failed',
  OPCO_REPORT_TEMPLATE_NOT_FOUND: 'Report template not found',
  OPCO_REPORT_VARIABLE_NOT_FOUND: 'A variable is missing',

  TADA_TEMPLATING_VARIABLE_NOT_FOUND: DEFAULT_ERROR,

  // Custom Job
  CUSTOMJOB_NOT_FOUND: 'Custom Job was not found',
  CUSTOMJOB_DUPLICATE_QUEUE_NAME: 'QueueName already exists',
  CUSTOMJOB_INVALID_CRON_EXPRESSION: 'Cron expression is invalid',
  CUSTOMJOB_CALL_FAILED: 'Custom Job call failed',

  TADA_TEMPLATING: DEFAULT_ERROR,
};

export default {
  pageTitle: {
    403: 'Access denied - Dametis',
    404: 'Page not found - Dametis',
  },
  title: {
    crash: DEFAULT_ERROR,
    notFound: 'Page not found',
    accessDenied: 'Access denied',
    requestAccessTo: 'Request access to',
  },
  text: {
    crash1: 'Please make sure your browser is up to date. If the error subsists, you can',
    crash2: 'contact our support',
    notFound: 'The page you are looking for does not exist',
    accessDenied: 'You are not allowed to access to',
    askAccess: 'You can ask access by mail to',
    notFoundOrAccessDenied: 'The page you are looking for does not exist, or you are not allowed to access it',
    requestAccessTo: 'You are going to request access by mail to',
  },
  button: {
    back: 'Go back',
    learnMore: 'Learn more',
    requestAccess: 'Request access',
    cancel: 'Cancel',
    submit: 'Submit',
  },
  label: {
    comment: 'Comment',
  },
  toast: {
    accessRequested: 'Access requested',
  },
  alerts,
};
