import { TagUsagesKey } from '@dametis/core';

import { TagTab } from 'types/tags';

const entityType: Record<TagUsagesKey, string> = {
  blocks: 'Bloques',
  playgrounds: 'Playgrounds',
  projects: 'Proyectos',
  reports: 'Informes',
  synoptics: 'Sinópticos',
  variables: 'Variables',
  opcos: 'Mezcla de vapores',
  models: 'Modelos',
};

export default {
  title: {
    editTags: 'Tags',
    tags: 'Tags',
    tagsDetails: 'Detalles de los tags',
    tagEditing: 'Edición de tags',
    actions: 'Acciones disponibles',
    selectedTags: 'Tags seleccionados',
    column: {
      name: 'Nombre',
      dependencies: 'Dependencias',
    },
    entityType,
    tab: {
      [TagTab.TOOLS]: 'Herramientas',
      [TagTab.DEPENDENCIES]: 'Dependencias',
    },
  },
  label: {
    dependencies: 'Dependencias',
    tags: 'Tags',
  },
  input: {
    tags: 'Tags',
    tagName: 'Nombre del tag',
    targetTagName: 'Nombre del tag de destino',
  },
  button: {
    tag: 'Tag',
    editTags: 'Editar tags',
    close: 'Cerrar',
    cancel: 'Cancelar',
    cancelChanges: 'Cancelar',
    save: 'Guardar',
    validate: 'Validar',
    create: 'Crear',
    merge: 'Fusionar',
    addDependencies: 'Etiquetar',
    moveTags: 'Mover',
    moveToRoot: 'Mover a la raíz',
    deleteTags: 'Eliminar',
    confirmDelete: 'Sí, eliminar',
    createTag: 'Crear un tag',
    createChildTag: 'Crear un tag hijo',
    editTag: 'Editar',
    mergeTags: 'Fusionar',
    deleteTag: 'Eliminar',
    deleteTag_one: 'Eliminar el tag',
    deleteTag_other: 'Eliminar los {{count}} tags',
    undo: 'Deshacer',
    expandAll: 'Expandir todo',
    collapseAll: 'Contraer todo',
    selectAll: 'Seleccionar todos',
    unselectAll: 'Deseleccionar todos',
    noTags: 'Crea tu primer tag',
  },
  tagAutocomplete: {
    newTag: 'Nueva tag',
    unknownTag: 'Tag desconocida',
    emptyTag: 'Ninguna tag seleccionada',
  },
  text: {
    noOptions: 'Sin opciones',
    deleteTagsAdvice:
      'Eliminar $t(text.thisDeletedTag, {"count": {{tagCount}} }) eliminará el tag de $t(text.entity, {"count": {{dependenciesCount}} }). Verifique las dependencias antes de eliminar.',
    mergeTagsAdvice:
      'Fusionar $t(text.thisTag, {"count": {{tagCount}} }) agregará $t(text.entity, {"count": {{dependenciesCount}} }) a la etiqueta de destino y eliminará las etiquetas de origen. Verifique las dependencias antes de fusionar.',
    thisDeletedTag_one: 'el tag y sus hijos',
    thisDeletedTag_other: 'los {{count}} tags y sus hijos',
    thisTag_one: 'el tag',
    thisTag_other: 'los {{count}} tags',
    entity_one: '{{count}} entidad',
    entity_other: '{{count}} entidades',
    updatedAt: 'Modificado {{date}}',
    noTags: 'Este sitio no tiene tags',
    unknownTag: 'Tag desconocido',
  },
  error: {
    maxTagAllowed: 'Se ha alcanzado el número máximo de tags',
    moveTags_one: 'Se ha producido un error al mover el tag',
    moveTags_other: 'Se ha producido un error al mover los tags',
    mergeTags_one: 'Se ha producido un error al fusionar el tag',
    mergeTags_other: 'Se ha producido un error al fusionar los tags',
    deleteTag_one: 'Se ha producido un error al eliminar el tag',
    deleteTag_other: 'Se ha producido un error al eliminar los tags',
    updateTag: 'Se ha producido un error al actualizar el tag',
    createTag: 'Se ha producido un error al crear el tag',
    undo: 'Se ha producido un error al deshacer',
    errorInTags: 'Los tags contienen errores',
    invalidName_zero: 'El nombre del tag no puede estar vacío',
    invalidName_other: 'El nombre del tag no puede ser mayor de {{max}} caracteres',
  },
  toast: {
    success: 'Nuevas tags guardadas.',
    error: 'Se produjo un error durante la guardado.',
    createTagSuccess: 'El tag se ha creado correctamente.',
    deleteTagSuccess_one: 'El tag se ha eliminado correctamente.',
    deleteTagSuccess_other: 'Los tags se han eliminado correctamente.',
    mergeTagsSuccess: 'Los tags se han fusionado correctamente.',
    mergeTagsItselfFailure: 'No se puede fusionar un tag solo consigo misma',
    updateTagSuccess: 'El tag se ha actualizado correctamente.',
  },
  modal: {
    cancelEdition: '¿Está seguro? Está a punto de cerrar el modo de edición.',
  },
  helper: {
    selectTag: 'Seleccionar tag',
    click: 'Clic',
    moveTagsWithDnd: 'Puede mover tags con arrastrar y soltar',
  },
};
