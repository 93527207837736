import { Box, BoxProps, styled } from '@mui/material';
import { FC } from 'react';

const BorderBoxBase = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    border: 'solid 3px',
    borderColor: 'secondary.light',
    borderRadius: 'inherit',
    bottom: 0,
    left: 0,
    pointerEvents: 'none',
    position: 'absolute',
    right: 0,
    top: 0,
  }),
);

export interface BorderBoxProps extends BoxProps {
  active?: boolean;
  wrapperProps?: BoxProps;
}

export const BorderBox: FC<BorderBoxProps> = ({
  active = undefined,
  border,
  borderColor,
  borderRadius,
  bottom,
  children = undefined,
  left,
  position,
  right,
  sx: rawSx,
  top,
  wrapperProps = undefined,
  ...props
}) => {
  const sx = [{ border, borderColor, borderRadius, bottom, left, position, right, top }, ...(Array.isArray(rawSx) ? rawSx : [rawSx])];
  if (active === false) return children;
  if (!children) return <BorderBoxBase {...props} sx={sx} />;
  return (
    <Box position="relative" {...wrapperProps}>
      {children}
      <BorderBoxBase {...props} sx={sx} />
    </Box>
  );
};
