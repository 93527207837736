import { Box, Chip, Stack } from '@mui/material';
import { CSSProperties, FC, useCallback, useMemo, useRef, useState } from 'react';
import { useResizeObserver } from 'usehooks-ts';

import { ShortTagInfo } from '@dametis/core';

import NestedTagTooltip from 'components/UI/NestedTagTooltip/NestedTagTooltip';
import NestedTagChip from 'components/UI/TagChip/NestedTagChip';
import { useTagsDict } from 'hooks/useTagsDict';
import { useSelector } from 'store';

export interface TagListProps {
  tags: ShortTagInfo[];
  fontSize?: CSSProperties['fontSize'];
}

const TagList: FC<TagListProps> = ({ tags, fontSize = undefined }) => {
  const siteId = useSelector(state => state.auth.selectedSite?.uuid);

  const tagsWrapperRef = useRef<HTMLDivElement>(null);
  const tagsContainerRef = useRef<HTMLDivElement>(null);

  const { tagsDict, isFetching } = useTagsDict(siteId);
  const [lastDisplayedTagIndex, setLastDisplayedTagIndex] = useState(-1);

  // const nestedTags = useMemo(
  //   () =>
  //     tags.reduce<NestedTag[]>((result, tag) => {
  //       if (tagsDict[tag.uuid]) {
  //       result.push(tagsDict[tag.uuid]);
  //       }
  //       return result;
  //     }, []),
  //   [tags, tagsDict],
  // );

  const displayedTags = useMemo(() => tags.slice(0, lastDisplayedTagIndex + 1), [tags, lastDisplayedTagIndex]);

  const missingTagsLength = useMemo(() => tags.length - (lastDisplayedTagIndex + 1), [tags.length, lastDisplayedTagIndex]);
  const missingTags = useMemo(() => tags.slice(lastDisplayedTagIndex + 1), [tags, lastDisplayedTagIndex]);

  const handleResize = useCallback(() => {
    const wrapperWidth = tagsWrapperRef.current?.clientWidth ?? 0;
    let currentWidth = 0;
    let currentIndex = -1;
    const tagsNodes = tagsContainerRef.current?.querySelectorAll(`.tagNode`) ?? [];
    for (let index = 0; index < tagsNodes.length; index += 1) {
      const tagNode = tagsNodes[index];
      if (currentWidth + tagNode.clientWidth + 20 <= wrapperWidth) {
        currentWidth = currentWidth + tagNode.clientWidth + 20;
        currentIndex = index;
      } else {
        break;
      }
    }
    setLastDisplayedTagIndex(currentIndex);
  }, []);

  useResizeObserver({ ref: tagsWrapperRef, onResize: handleResize });

  return (
    <Box ref={tagsWrapperRef} overflow="hidden" width={1}>
      {!isFetching && (
        <Stack ref={tagsContainerRef} alignItems="center" direction="row" gap={0.5} width={1}>
          {displayedTags.map(tag => (
            <NestedTagChip
              key={tag.uuid}
              displayPath
              className="tagNode"
              pathPosition="row"
              sx={fontSize ? { fontSize } : undefined}
              tag={tagsDict[tag.uuid]}
            />
          ))}
          {missingTagsLength > 0 && (
            <NestedTagTooltip PopperProps={{ placement: 'bottom-start' }} tags={missingTags}>
              <Chip label={`+${missingTagsLength}`} size="small" sx={fontSize ? { fontSize } : undefined} />
            </NestedTagTooltip>
          )}
          {missingTags.map(tag => (
            <NestedTagChip
              key={tag.uuid}
              displayPath
              className="tagNode"
              pathPosition="row"
              sx={{ opacity: 0, fontSize }}
              tag={tagsDict[tag.uuid]}
            />
          ))}
        </Stack>
      )}
    </Box>
  );
};

export default TagList;
