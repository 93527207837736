import { FormLabel, TextField, TextFieldProps, TooltipProps, Typography, TypographyProps } from '@mui/material';
import clsx from 'clsx';
import { FC, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useATFStyles } from './ATF.styles';
import LabelWithDescription, { LabelWithDescriptionProps } from './LabelWithDescription';

const typographyPropsDefaultProp: Omit<TypographyProps, 'children'> = {};
const labelWithDescriptionPropsDefaultProp: Omit<LabelWithDescriptionProps, 'label' | 'description'> = {};

export type AdvancedTextFieldProps = TextFieldProps & {
  editing?: boolean;
  emptyLabel?: string;
  typographyProps?: Omit<TypographyProps, 'children'>;
  description?: TooltipProps['title'];
  labelWithDescriptionProps?: Omit<LabelWithDescriptionProps, 'label' | 'description'>;
};

const AdvancedTextField: FC<AdvancedTextFieldProps> = ({
  editing = true,
  typographyProps = typographyPropsDefaultProp,
  label,
  value,
  SelectProps,
  fullWidth,
  margin = 'none',
  disabled,
  children = undefined,
  emptyLabel = undefined,
  description = undefined,
  labelWithDescriptionProps = labelWithDescriptionPropsDefaultProp,
  required = false,
  ...props
}) => {
  const { t } = useTranslation('global');

  const classes = useATFStyles();
  const notEditingClass = useMemo(() => {
    switch (margin) {
      case 'normal':
        return classes['atf__notEditing--marginNormal'];
      case 'dense':
        return classes['atf__notEditing--marginDense'];
      case 'none':
        return classes['atf__notEditing--marginNone'];
      default:
        return classes['atf__notEditing--marginNone'];
    }
  }, [classes, margin]);
  if (!editing) {
    const text = SelectProps?.renderValue ? SelectProps.renderValue(value) : value;

    return (
      <div className={clsx(classes.atf__notEditing, fullWidth && classes['atf__notEditing--fullWidth'], notEditingClass)}>
        <FormLabel disabled={disabled}>{label}</FormLabel>
        <Typography color={disabled ? 'rgba(0, 0, 0, 0.38)' : undefined} sx={{ wordBreak: 'break-word' }} {...typographyProps}>
          {typeof text === 'string' && !text.length ? emptyLabel || t('text.noValue') : <>{text as ReactNode}</>}
        </Typography>
      </div>
    );
  }
  return (
    <TextField
      disabled={disabled}
      variant="outlined"
      {...{ value, SelectProps, fullWidth, margin }}
      label={
        description ? (
          <LabelWithDescription description={description} label={label} {...labelWithDescriptionProps} required={required} />
        ) : (
          label
        )
      }
      required={!description ? required : undefined}
      {...props}
    >
      {children}
    </TextField>
  );
};

export default AdvancedTextField;
