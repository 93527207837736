import i18next from 'i18next';

import {
  CommentInfo,
  CreateCommentInfo,
  IsCommentOnProjectBody,
  IsCommentOnReportBody,
  IsCommentOnTaskBody,
  IsCommentOnVariableBody,
  UUID,
  UpdateCommentBody,
} from '@dametis/core';

import { generateNotifications } from 'components/UI/Comments/Comment/Edit/plugins/MentionPlugin/functions/MentionPluginFunctions';
import { addToast } from 'store/slices/toast';
import { ToastSeverity } from 'types';
import { EntityType } from 'types/comment';

import { sdk } from '../../sdk';

import { api } from './index';

export const commentsApi = api.injectEndpoints({
  endpoints: build => ({
    readComments: build.query<CommentInfo[], { entity: EntityType; entityUuid?: UUID; groupId: UUID; siteId?: UUID }>({
      queryFn: async ({ entity, entityUuid, groupId, siteId }) => {
        if (!entityUuid || (!groupId && !siteId)) {
          return { data: [] };
        }
        try {
          const { data } = siteId
            ? await sdk.comment.ListComments(siteId, { [`${entity}Id`]: entityUuid })
            : await sdk.comment.ListCommentsOfGroup(groupId, { [`${entity}Id`]: entityUuid });
          return { data: entity === EntityType.PROJECT ? data.filter(comment => !comment.taskId) : data };
        } catch (error) {
          console.log('coucou', error.response.headers.get('x-request-id'));
          return { error };
        }
      },
      providesTags: (result, error, args) => [{ type: 'Comments', id: args.entityUuid }],
    }),
    createComment: build.mutation<CommentInfo | null, { comment: CreateCommentInfo; entity: EntityType; entityUuid: UUID }>({
      queryFn: async ({ comment, entity, entityUuid }, { dispatch }) => {
        try {
          let res: CommentInfo | null = null;
          const notify = generateNotifications(comment.message);
          if (entity === EntityType.REPORT && IsCommentOnReportBody(comment)) {
            const { data } = await sdk.report.Comment(entityUuid, { ...comment, notify });
            res = data;
          } else if (entity === EntityType.PROJECT && IsCommentOnProjectBody(comment)) {
            const { data } = await sdk.project.Comment(entityUuid, { ...comment, notify });
            res = data;
          } else if (entity === EntityType.VARIABLE && IsCommentOnVariableBody(comment)) {
            const { data } = await sdk.variable.Comment(entityUuid, { ...comment, notify });
            res = data;
          } else if (entity === EntityType.TASK && IsCommentOnTaskBody(comment)) {
            const { data } = await sdk.task.Comment(entityUuid, { ...comment, notify });
            res = data;
          }
          if (res) {
            dispatch(
              addToast({
                severity: ToastSeverity.SUCCESS,
                message: i18next.t('toast:successCreateComment'),
              }),
            );
          }
          return { data: res };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: (result, error, args) => [{ type: 'Comments', id: args.entityUuid }],
    }),
    updateComment: build.mutation<CommentInfo, { comment: UpdateCommentBody; commentId: UUID; entityUuid: UUID }>({
      queryFn: async ({ comment, commentId }, { dispatch }) => {
        try {
          const { data } = await sdk.comment.Update(commentId, comment);
          // ADD HERE CALL TO NOTIFY
          // const notify = generateNotifications(comment.message);
          dispatch(
            addToast({
              severity: ToastSeverity.SUCCESS,
              message: i18next.t('toast:successUpdateComment'),
            }),
          );
          return { data };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: (result, error, args) => [{ type: 'Comments', id: args.entityUuid }],
    }),
    deleteComment: build.mutation<UUID, { commentId: UUID; entityUuid: UUID }>({
      queryFn: async ({ commentId }, { dispatch }) => {
        try {
          await sdk.comment.Delete(commentId);
          dispatch(
            addToast({
              severity: ToastSeverity.SUCCESS,
              message: i18next.t('toast:successDeleteComment'),
            }),
          );
          return { data: commentId };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: (result, error, args) => [{ type: 'Comments', id: args.entityUuid }],
    }),
  }),
});

export const { useReadCommentsQuery, useCreateCommentMutation, useUpdateCommentMutation, useDeleteCommentMutation } = commentsApi;

export const useComments: typeof useReadCommentsQuery = (arg, options) => {
  return useReadCommentsQuery(arg, options);
};
