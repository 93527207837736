/* eslint-disable react/no-unstable-nested-components */

import { ListSubheader, Typography } from '@mui/material';
import { FC, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GroupedVirtuoso } from 'react-virtuoso';

import { ShortAliasInfo, ShortCorporateAliasInfo } from '@dametis/core';

import Counter from 'components/UI/Counter/Counter';
import { useVncStore } from 'zustand/stores/vnc';

import { PropsContext } from '../../../context';

import AliasesListItem from './AliasesListItem';

type Alias = ShortAliasInfo | ShortCorporateAliasInfo;
type Categorized = {
  sameEntity: Alias[];
  sameCategory: Alias[];
  others: Alias[];
};
type Category = keyof Categorized;

interface AliasesListProps {
  filteredAliases: (ShortAliasInfo | ShortCorporateAliasInfo)[];
}

const AliasesList: FC<AliasesListProps> = ({ filteredAliases }) => {
  const { t } = useTranslation('vnc');

  const { sourceUuid, sourceCategory } = useContext(PropsContext);

  const search = useVncStore(state => state.search);

  const categories = useMemo<Categorized>(() => {
    return filteredAliases.reduce<Categorized>(
      (acc, cur) => {
        if (cur.source.uuid && sourceUuid && cur.source.uuid === sourceUuid) {
          acc.sameEntity.push(cur);
        } else if (cur.source.category === sourceCategory) {
          acc.sameCategory.push(cur);
        } else {
          acc.others.push(cur);
        }
        return acc;
      },
      { sameEntity: [], sameCategory: [], others: [] },
    );
  }, [filteredAliases, sourceCategory, sourceUuid]);

  const groupCounts = useMemo(
    // We filter out empty categories to avoid showing empty groups
    () => [categories.sameEntity.length, categories.sameCategory.length, categories.others.length].filter(Boolean),
    [categories],
  );
  const groupLastsIndexes = useMemo(
    () => groupCounts.map((count, index) => count - 1 + groupCounts.slice(0, index).reduce((acc, cur) => acc + cur, 0)),
    [groupCounts],
  );

  const flattenedCategories = useMemo(() => [...categories.sameEntity, ...categories.sameCategory, ...categories.others], [categories]);

  const getCategoryLabel = useCallback(
    (category: number, count: number): string => {
      // We list the non-empty categories names to match groupCounts length
      const nonEmptyCategories = Object.entries(categories).reduce<Category[]>((acc, [curCategory, curAliases]) => {
        if (curAliases.length) {
          acc.push(curCategory as Category);
        }
        return acc;
      }, []);
      return t(`list.subheader.${nonEmptyCategories[category]}`, { category: sourceCategory, count });
    },
    [categories, sourceCategory, t],
  );

  if (!filteredAliases.length) {
    return (
      <Typography align="center" sx={{ mt: 4, px: 6 }} variant="subtitle2">
        {t(search.length ? 'subtitle.noAliasesForSearch' : 'subtitle.noAliases', { search })}
      </Typography>
    );
  }
  return (
    <GroupedVirtuoso
      groupContent={index => (
        <ListSubheader>
          {getCategoryLabel(index, groupCounts[index])} <Counter count={groupCounts[index]} />
        </ListSubheader>
      )}
      groupCounts={groupCounts}
      itemContent={(index, groupIndex) => (
        <AliasesListItem isLast={groupLastsIndexes[groupIndex] === index} item={flattenedCategories[index]} />
      )}
    />
  );
};

export default AliasesList;
