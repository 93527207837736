import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { Box } from '@mui/material';
import { Dispatch, FC, PropsWithChildren, Ref, SetStateAction, useCallback, useState } from 'react';
import { Resizable, ResizableProps } from 'react-resizable';

import { EditorWrapper } from 'components/UI/Lexical/StyledCommentElements.styled';

import { Config } from '../../../config/lexical.ts';

interface EditorLexicalComposerProps {
  height: number;
  setHeight: Dispatch<SetStateAction<number>>;
  toolbarHeight?: number;
  disableResize?: boolean;
  initialConfig: Config;
  editing?: boolean;
  focused?: boolean;
  backgroundColor?: string;
  ref?: Ref<HTMLDivElement>;
}

const EditorLexicalComposer: FC<PropsWithChildren<EditorLexicalComposerProps>> = ({
  height,
  setHeight,
  toolbarHeight = 0,
  disableResize = true,
  initialConfig,
  editing = undefined,
  focused = undefined,
  backgroundColor = undefined,
  ref = undefined,
  children = undefined,
}) => {
  const [isResizing, setIsResizing] = useState<boolean>(false);

  const handleResizeStart = useCallback(() => {
    setIsResizing(true);
  }, []);

  const handleResizeStop = useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = useCallback<NonNullable<ResizableProps['onResize']>>((_, { size }) => {
    setHeight(size.height);
  }, []);

  return (
    <>
      {disableResize ? (
        <EditorWrapper ref={ref} backgroundColor={backgroundColor} editing={editing} focused={focused}>
          <LexicalComposer initialConfig={initialConfig}>{children}</LexicalComposer>
        </EditorWrapper>
      ) : (
        <Box sx={{ position: 'relative', height: 1 }}>
          <Resizable
            handle={
              <Box
                sx={{
                  position: 'absolute',
                  left: '50%',
                  ml: '-35px',
                  height: '20px',
                  width: '80px',
                  cursor: 'row-resize',
                  bottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  '&:hover .resize-handle': {
                    backgroundColor: 'black',
                  },
                }}
              >
                <Box
                  sx={{
                    backgroundColor: isResizing ? 'black' : 'lightgray',
                    borderRadius: '16px',
                    height: '5px',
                    width: '50px',
                  }}
                />
              </Box>
            }
            height={height}
            maxConstraints={[9999, 500]}
            minConstraints={[9999, 100]}
            resizeHandles={['s']}
            width={9999}
            onResize={resize}
            onResizeStart={handleResizeStart}
            onResizeStop={handleResizeStop}
          >
            <Box>
              <EditorWrapper ref={ref} backgroundColor={backgroundColor} editing={editing} focused={focused}>
                <Box sx={{ ...(height && { height: height + toolbarHeight, minHeight: 100, maxHeight: 700 }) }}>
                  <LexicalComposer initialConfig={initialConfig}>{children}</LexicalComposer>
                </Box>
              </EditorWrapper>
            </Box>
          </Resizable>
        </Box>
      )}
    </>
  );
};

export default EditorLexicalComposer;
