import { PermissionKey } from '@dametis/core';

import { RootState } from 'store';

export const getPermissionFromId = (
  state: RootState,
  permission: PermissionKey,
  { groupId, siteId }: { groupId?: string; siteId?: string },
) => {
  const { groups } = state.auth;
  const site = groups.flatMap(group => group.sites).find(site => site.uuid === siteId);
  const group = groups.find(group => group.uuid === groupId);
  return state.auth.user!.acl.HasPermission(permission, group?.uuid, site);
};
