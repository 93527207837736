import { MoreVert } from '@mui/icons-material';
import { Box, IconButton, MenuProps, SxProps, Tooltip } from '@mui/material';
import { FC, HTMLAttributes, PropsWithChildren, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Operator, VarCalc } from '@dametis/core';

import { setColorLightness } from 'functions/color';
import { useVariableColor, useVariableName } from 'hooks/useVariableProp';
import { useLocalizedGroupBy } from 'localization/useLocalizedGroupBy';
import { openReplayEvent } from 'openreplay/openreplay';
import { OpenReplayEvent } from 'openreplay/types';

import AlertTooltip from '../../../../UI/AlertTooltip/AlertTooltip';
import { KBM } from '../../../../UI/Hotkeys/KBM';
import { KBMs } from '../../../../UI/Hotkeys/KBMs';
import VariableMenu, { VariableMenuProps } from '../VariableMenu/VariableMenu';
import { OperatorSpan, VariableSpan } from '../styled';

import InlineChromiumBugfix from './InlineChromiumBugfix';

export interface VariableProps {
  variable: VarCalc;
  onVariableChange?: VariableMenuProps['onVariableChange'];
  selected?: boolean;
  disableVariableMenu?: boolean;
  sx?: SxProps;
  VariableSpanProps?: HTMLAttributes<HTMLSpanElement>;
}

const Variable: FC<PropsWithChildren<VariableProps>> = ({
  variable,
  onVariableChange = undefined,
  selected = false,
  disableVariableMenu = false,
  sx = undefined,
  children = undefined,
  VariableSpanProps = undefined,
}) => {
  const { t } = useTranslation(['vnc', 'global', 'lego']);

  const [buttonMenu, setButtonMenu] = useState(false);
  const [contextualMenu, setContextualMenu] = useState<MenuProps['anchorPosition']>(undefined);
  const [rightClickTooltip, setRightClickTooltip] = useState(false);

  const menuButtonRef = useRef<HTMLButtonElement>(null);

  const variableName = useVariableName(variable);
  const variableColor = useVariableColor(variable);
  const darkVariableColor = useMemo(() => setColorLightness(variableColor, 45), [variableColor]);
  const groupBy = useLocalizedGroupBy(variable.groupBy, true);

  const openButtonMenu = useCallback(() => {
    openReplayEvent(OpenReplayEvent.VNC_SLATE_OPERATOR_MENU_CLICK);
    setButtonMenu(true);
  }, []);

  const closeMenu = useCallback(() => {
    setButtonMenu(false);
    setContextualMenu(undefined);
  }, []);

  const openContextualMenu = useCallback<NonNullable<HTMLAttributes<HTMLSpanElement>['onContextMenu']>>(
    event => {
      event.preventDefault();
      if (!contextualMenu) {
        openReplayEvent(OpenReplayEvent.VNC_SLATE_VARIABLE_RIGHT_CLICK);
      }
      setContextualMenu(prevState => (prevState ? undefined : { left: event.clientX, top: event.clientY }));
    },
    [contextualMenu],
  );

  const showRightClickTooltip = useCallback(() => {
    openReplayEvent(OpenReplayEvent.VNC_SLATE_OPERATOR_LEFT_CLICK);
    setRightClickTooltip(true);
    setTimeout(() => {
      setRightClickTooltip(false);
    }, 2000);
  }, []);

  const changeVariable = useCallback<VariableMenuProps['onVariableChange']>(
    varCalc => {
      onVariableChange?.(varCalc);
    },
    [onVariableChange],
  );

  return (
    <VariableSpan
      pqColor={variableColor}
      selected={selected}
      sx={[!disableVariableMenu && { cursor: 'context-menu' }, ...(Array.isArray(sx) ? sx : [sx])]}
      onContextMenu={disableVariableMenu ? undefined : openContextualMenu}
      {...VariableSpanProps}
    >
      <InlineChromiumBugfix />
      {Boolean(variable.operator) && variable.operator !== Operator.HISTORY && (
        <AlertTooltip
          AlertProps={{ severity: 'info' }}
          open={rightClickTooltip}
          title={
            <>
              <KBMs>
                <KBM>{t('tooltip.rightClickToOpenVariableMenu1')}</KBM>
              </KBMs>{' '}
              {t('tooltip.rightClickToOpenVariableMenu2')}
            </>
          }
        >
          <Box component="span" onClick={disableVariableMenu ? undefined : showRightClickTooltip}>
            <OperatorSpan pqColor={variableColor} selected={selected}>
              {t('global:operator.opShort', { context: variable.operator })}
              {groupBy ? ` ${groupBy}` : ''}
            </OperatorSpan>
          </Box>
        </AlertTooltip>
      )}
      {children}
      {variableName}
      {!disableVariableMenu && (
        <>
          <Tooltip title={t('tooltip.variableMenu')}>
            <IconButton
              ref={menuButtonRef}
              sx={{ width: 18, height: 18, color: darkVariableColor, transform: 'translateY(-1px)' }}
              onClick={openButtonMenu}
            >
              <MoreVert sx={{ fontSize: 12 }} />
            </IconButton>
          </Tooltip>
          <VariableMenu
            anchorEl={menuButtonRef.current}
            anchorPosition={contextualMenu}
            anchorReference={contextualMenu ? 'anchorPosition' : 'anchorEl'}
            open={buttonMenu || Boolean(contextualMenu)}
            variable={variable}
            onClose={closeMenu}
            onVariableChange={changeVariable}
          />
        </>
      )}
      <InlineChromiumBugfix />
    </VariableSpan>
  );
};

export default Variable;
