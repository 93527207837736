export default {
  title: {
    newModel: 'Nouveau modèle',
    responseVariable: 'Variable expliquée',
    explanatoryVar: 'Variables explicatives',
    params: 'Paramètres',
    equation: 'Équation',
    variables: 'Variables',
    vncEquation: 'Équation',
    newModelDialog: 'Nouveau modèle',
  },
  subtitle: {
    noMode: "Ce modèle n'est pas encore paramétré",
  },
  text: {
    noVariables: 'Pas de variables',
    noYVariable: 'Pas de variable expliquée',
    noXVariables: 'Pas de variables explicatives',
  },
  typography: {
    formula: 'Équation',
    r2: 'R²',
    smape: 'MAPE',
    cvrmse: 'CV(RMSE)',
    min: 'Min',
    max: 'Max',
    coefficient: 'Coefficient',
    dropImportance: 'Poids',
    pValue: 'Valeur-p',
    standardDeviation: 'Écart type',
    covariance: 'Covariance',
  },
  button: {
    create: 'Créer',
    cancel: 'Annuler',
    rlmMode: "Modéliser cette variable avec d'autres variables",
    rlmMode_short: 'Variable',
    toggleRlmMode: 'Choisir des variables plutôt',
    equationMode: "Écrire l'équation directement",
    equationMode_short: 'Équation',
    toggleEquationMode: "Écrire l'équation plutôt",
    editModel: 'Modifier ce modèle',
    saveModel: 'Enregistrer ce modèle',
    createModel: 'Créer ce modèle',
    duplicateModel: 'Dupliquer ce modèle',
    deleteModel: 'Supprimer ce modèle',
    cancelChanges: 'Annuler les modifications',
    modifyYVariable: 'Choisir',
    modifyYVariable_long: 'Choisir la variable expliquée',
    addXVariable: 'Ajouter',
    chooseCalculationVariable: 'Choisir une variable existante',
    chooseCalculationVariable_short: 'Variable',
    choosePointsVariable: 'Insérer des données manuelles',
    choosePointsVariable_short: 'Données',
  },
  input: {
    label: {
      groupBy: 'Sous-périodes',
      name: 'Nom',
      period: 'Période de référence',
      uncertainty: 'Incertitude',
    },
  },
  tooltip: {
    createModel: 'créer un modèle',
    createInstanceModel: 'créer une instance du modèle',
    duplicateModel: 'dupliquer ce modèle',
    editModel: 'modifier ce modèle',
    deleteModel: 'supprimer ce modèle',
    kindRequired: "Vous ne pouvez pas enregistrer un modèle sans choisir sa variante d'abord",
    yVarRequired: 'Vous ne pouvez pas enregistrer un modèle sans variable expliquée',
    hideXVar: 'Variable affichée sur le graphique',
    showXVar: 'Variable cachée sur le graphique',
    enableXVar: 'Variable non prise en compte dans le modèle',
    disableXVar: 'Variable prise en compte dans le modèle',
    deleteXVar: 'Supprimer cette variable',
    preview: {
      line: 'Courbe',
      column: 'Diagramme à barres',
    },
    r2: "Coefficient de détermination. Le R² mesure la qualité d'un modèle de régression. Plus il est proche de 1, mieux le modèle explique les variations des données. S'il est proche de 0, le modèle est peu performant. Et s'il est inférieur à 0, le modèle est moins précis qu'une prédiction basée sur la moyenne.",
    smape:
      "Erreur moyenne absolue en pourcentage. Le MAPE donne une idée de l'erreur en pourcentage. Plus faible est le pourcentage, meilleur est le modèle.",
    cvrmse:
      "Coefficient de variation de la racine de l'erreur quadratique moyenne. Le RMSE mesure la précision du modèle. Il indique à quel point, en moyenne, les prédictions s'éloignent des valeurs réelles. Le CV(RMSE) normalise le RMSE par la moyenne des valeurs. Plus il est proche de 0, plus le modèle est performant.",
    dropImportance:
      "Le poids mesure l'importance de cette variable en la retirant du calcul et en analysant l'impact sur le R². Plus l'impact est important, plus le poids est élevé, plus la variable est importante.",
    pValue:
      "La valeur-p mesure la probabilité que l'impact de cette variable soit lié au hasard. Elle est comprise entre 0 et 1, et doit être la plus faible possible. Le seuil de 0,05 est souvent utilisé comme seuil de confiance.",
    standardDeviation:
      "Écart type. Il mesure la dispersion des valeurs autour de la moyenne. Plus l'écart type est faible, plus les valeurs sont proches de la moyenne.",
  },
  highcharts: {
    modelSeries: 'Modèle',
    uncertaintySeries: 'Incertitude',
  },
  dataGrid: {
    model: 'Modèle',
  },
};
