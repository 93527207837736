import { useTheme } from '@mui/material';
import { DetailedHTMLProps, FC, HTMLAttributes, forwardRef, useMemo } from 'react';

type HTMLDivElementProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export interface ListProps extends HTMLDivElementProps {
  minWidth: string;
  spacing?: number | string;
}

export const List = forwardRef<HTMLDivElement, ListProps>(({ children, minWidth, spacing, style, ...props }, ref) => {
  const globalTheme = useTheme();

  return (
    <div
      ref={ref}
      style={{
        display: 'grid',
        gap: typeof spacing === 'number' ? globalTheme.spacing(spacing) : spacing,
        gridTemplateColumns: `repeat(auto-fill, minmax(${minWidth}, 1fr))`,
        ...style,
      }}
      {...props}
    >
      {children}
    </div>
  );
});

List.defaultProps = {
  spacing: undefined,
};

List.displayName = 'List';

export interface ItemProps extends HTMLDivElementProps {
  spacing?: number | string;
}

export const Item: FC<ItemProps> = ({ children = undefined, style, ...props }) => {
  return (
    <div
      style={{
        alignContent: 'stretch',
        boxSizing: 'border-box',
        display: 'flex',
        ...style,
      }}
      {...props}
    >
      {children}
    </div>
  );
};

const useGridComponents = (minWidth: string, spacing?: string | number) =>
  useMemo(() => {
    const MutableList = forwardRef<HTMLDivElement, HTMLDivElementProps>((props, ref) => (
      <List ref={ref} minWidth={minWidth} spacing={spacing} {...props} />
    ));
    MutableList.displayName = 'MutableList';
    const MutableItem = forwardRef<HTMLDivElement, HTMLDivElementProps>((props, ref) => <Item ref={ref} spacing={spacing} {...props} />);
    MutableItem.displayName = 'MutableList';
    return { List: MutableList, Item: MutableItem };
  }, [minWidth, spacing]);

export default useGridComponents;
