import { WarningAmberOutlined } from '@mui/icons-material';
import { Checkbox, Collapse, Tooltip } from '@mui/material';
import { ChangeEventHandler, FC, MouseEventHandler, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { StandardBlockMetric } from '@dametis/core';

import LegoMetricPreview, { LegoMetricPreviewProps } from 'components/Lego/UI/LegoMetricPreview/LegoMetricPreview';

export interface MetricListElementProps extends LegoMetricPreviewProps<StandardBlockMetric> {
  isWorking: boolean;
  onChangeIsSelected: (metricBlockKey: string) => ChangeEventHandler<HTMLInputElement>;
}

const MetricListElement: FC<MetricListElementProps> = ({ metric, isWorking, onChangeIsSelected, sx, ...props }) => {
  const { t } = useTranslation('lego');

  const handleMouseDownCheckbox: MouseEventHandler<HTMLButtonElement> = useCallback(event => {
    event.stopPropagation();
  }, []);

  return (
    <>
      <LegoMetricPreview<StandardBlockMetric>
        metric={metric}
        sx={{ flexGrow: 1, transition: theme => theme.transitions.create(['opacity']), ...sx }}
        {...props}
      />
      <Collapse in={isWorking} orientation="horizontal">
        <Tooltip title={t('tooltip.notWorkingStandardBlockMetric')}>
          <WarningAmberOutlined color="warning" />
        </Tooltip>
      </Collapse>
      <Checkbox checked={metric.isSelected} onChange={onChangeIsSelected(metric.uuid)} onMouseDown={handleMouseDownCheckbox} />
    </>
  );
};

export default MetricListElement;
