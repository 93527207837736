import { makeStyles } from '@mui/styles';

const useReconnectStyles = makeStyles(theme => ({
  reconnect: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 9999,
    opacity: 1,
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.secondary.main,
  },
  logo: {
    width: '5rem',
    marginBottom: '1rem',
  },
  dametis: {
    width: '10rem',
    marginBottom: '2rem',
  },
}));

export default useReconnectStyles;
