import { Paper, Stack } from '@mui/material';
import { FC } from 'react';

import { BlockTypeMetadata, IsBlockTypeMetadataNumber, IsBlockTypeMetadataOption, IsBlockTypeMetadataString } from '@dametis/core';

import VariableValue from 'components/UI/VariableValue/VariableValue';

import MetadataHeader from './MetadataHeader';

export interface BlockTypeMetadataPreviewProps {
  metadata: BlockTypeMetadata;
}

const BlockTypeMetadataPreview: FC<BlockTypeMetadataPreviewProps> = ({ metadata }) => (
  <Paper sx={{ p: 1, background: theme => theme.palette.background.default }}>
    <Stack alignItems="center" direction="row" justifyContent="space-between">
      <MetadataHeader name={metadata.name} type={metadata.type} />
      <Stack alignItems="center" direction="row" spacing={1}>
        {IsBlockTypeMetadataString(metadata) && (
          <VariableValue sx={{ background: theme => theme.palette.background.paper }}>{metadata.content.defaultValue}</VariableValue>
        )}
        {IsBlockTypeMetadataNumber(metadata) && metadata.content.unit && (
          <>
            <VariableValue sx={{ background: theme => theme.palette.background.paper }}>{metadata.content.defaultValue}</VariableValue>
            <VariableValue sx={{ background: theme => theme.palette.background.paper }}>{metadata.content.unit}</VariableValue>
          </>
        )}
        {IsBlockTypeMetadataOption(metadata) && (
          <VariableValue sx={{ background: theme => theme.palette.background.paper }}>
            {metadata.content.availableOptions.find(option => option.uuid === metadata.content.defaultSelectedOptionId)?.value}
          </VariableValue>
        )}
      </Stack>
    </Stack>
  </Paper>
);

export default BlockTypeMetadataPreview;
