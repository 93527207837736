import {
  BugReportOutlined,
  ErrorOutlineOutlined,
  FeedOutlined,
  InfoOutlined,
  NotificationImportantOutlined,
  NotificationsOffOutlined,
  SvgIconComponent,
  WarningAmberOutlined,
} from '@mui/icons-material';
import { Box, Checkbox, Chip, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Stack, SvgIcon } from '@mui/material';
import { ChangeEventHandler, Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AllLogLevels, BrandDeviceInfo, CreateCommonDeviceBody, LogLevel, ProtocolKind } from '@dametis/core';

import ChipSelect from 'components/UI/ChipSelect/ChipSelect';
import ChipSelectItem, { ChipSelectItemProps } from 'components/UI/ChipSelect/ChipSelectItem';
import AdvancedTextField from 'components/UI/Inputs/AdvancedTextField/AdvancedTextField';
import ProtocolChip from 'components/UI/ProtocolChip/ProtocolChip';
import { WipFeatures } from 'config/featureFlags';
import getProtocols from 'config/protocols';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useSelector } from 'store';
import { useReadBrandDevicesQuery } from 'store/api/brandDevices';

import BrandDevicePicker from '../BrandDevicePicker/BrandDevicePicker';

const brandDevicesEmptyArray: BrandDeviceInfo[] = [];

export interface DeviceCommonFormProps {
  body: CreateCommonDeviceBody;
  setBody: Dispatch<SetStateAction<CreateCommonDeviceBody>>;
  isEditing?: boolean;
  displayBrandDevicePicker?: boolean;
  canChangeProtocol?: boolean;
  onChangeName?: (newName: string) => void;
  onChangeBrandDevice?: (newBrandDevice: BrandDeviceInfo | undefined) => void;
}

const DeviceCommonForm: FC<DeviceCommonFormProps> = ({
  body,
  setBody,
  isEditing = true,
  displayBrandDevicePicker = true,
  canChangeProtocol = true,
  onChangeName = undefined,
  onChangeBrandDevice = undefined,
}) => {
  const { t } = useTranslation('devices');

  const { data: brandDevices = brandDevicesEmptyArray } = useReadBrandDevicesQuery();

  const isLogLevelEnable = useSelector(state => state.auth.user?.acl.global.HasAnyRole(['EMaaS', 'SUPER_ADMIN']) ?? false);
  const canCreateFileDevice = useSelector(state => state.auth.user?.acl.global.HasAnyRole(['SITE_ADMIN', 'EMaaS', 'SUPER_ADMIN']) ?? false);

  const isAlarmEnable = useFeatureFlags(WipFeatures.ENABLE_ALARM);

  const availableProtocols = useMemo(
    () => Object.values(ProtocolKind).filter(kind => kind !== ProtocolKind.FILE || canCreateFileDevice),
    [canCreateFileDevice],
  );

  const protocols = useMemo(() => getProtocols(t), [t]);

  const logLevelColors: Record<LogLevel, ChipSelectItemProps['color']> = useMemo(
    () => ({
      error: 'error',
      warn: 'warning',
      info: 'info',
      verbose: 'default',
      debug: 'default',
      skip: 'default',
    }),
    [],
  );

  const logLevelIcons: Record<LogLevel, SvgIconComponent> = useMemo(
    () => ({
      error: ErrorOutlineOutlined,
      warn: WarningAmberOutlined,
      info: InfoOutlined,
      verbose: FeedOutlined,
      debug: BugReportOutlined,
      skip: NotificationsOffOutlined,
    }),
    [],
  );

  const brandDevicesByProtocol = useMemo(
    () =>
      brandDevices.reduce<Partial<Record<ProtocolKind, BrandDeviceInfo[]>>>((result, brandDevice) => {
        if (!result[brandDevice.protocol]) {
          result[brandDevice.protocol] = [];
        }
        result[brandDevice.protocol]?.push(brandDevice);
        return result;
      }, {}),
    [brandDevices],
  );

  const withBrandDevicePicker = useMemo(
    () => isEditing && displayBrandDevicePicker && body?.protocol && (brandDevicesByProtocol[body.protocol] ?? []).length > 0,
    [isEditing, displayBrandDevicePicker, brandDevicesByProtocol, body?.protocol],
  );

  const handleChangeName: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setBody(state => ({ ...state, name: event.target.value }));
      if (onChangeName) {
        onChangeName(event.target.value);
      }
    },
    [setBody, onChangeName],
  );

  const handleChangeProtocol: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setBody(state => ({ ...state, protocol: event.target.value as ProtocolKind, brandDeviceId: undefined }));
    },
    [setBody],
  );

  const handleChangeLogLevel = useCallback(
    (newLogLevel: LogLevel | null) => () => {
      setBody(state => ({ ...state, logLevel: newLogLevel }));
    },
    [setBody],
  );

  const handleChangeAlarm: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setBody(state => ({ ...state, alarm: event.target.checked }));
    },
    [setBody],
  );

  const handleChangeBrandDevice = useCallback(
    (brandDevice: BrandDeviceInfo | undefined) => {
      setBody(state => ({ ...state, brandDeviceId: brandDevice?.uuid ?? undefined }));
      if (onChangeBrandDevice) {
        onChangeBrandDevice(brandDevice);
      }
    },
    [setBody, onChangeBrandDevice],
  );

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <AdvancedTextField
            autoFocus
            fullWidth
            required
            editing={isEditing}
            label={t('label.name')}
            placeholder={t('label.name')}
            value={body.name}
            onChange={handleChangeName}
          />
        </Grid>
        <Grid item xs>
          {isEditing ? (
            <AdvancedTextField
              fullWidth
              required
              select
              disabled={!canChangeProtocol}
              editing={isEditing}
              label={t('label.protocol')}
              value={body.protocol ?? ''}
              onChange={handleChangeProtocol}
            >
              {!body.protocol && (
                <MenuItem disabled value="">
                  {t('text.noSelection')}
                </MenuItem>
              )}
              {availableProtocols.map(protocol => (
                <MenuItem key={protocol} value={protocol}>
                  {protocols[protocol].name}
                </MenuItem>
              ))}
            </AdvancedTextField>
          ) : (
            <Stack alignItems="flex-start">
              <InputLabel sx={{ mb: 0.5 }}>{t('label.protocol')}</InputLabel>
              <ProtocolChip protocol={body.protocol} />
            </Stack>
          )}
        </Grid>
        {withBrandDevicePicker && body.protocol && (
          <Grid item xs>
            <BrandDevicePicker
              label={t('label.brandDevice')}
              protocol={body.protocol}
              value={body.brandDeviceId ?? null}
              onChange={handleChangeBrandDevice}
            />
          </Grid>
        )}
        {isAlarmEnable && (
          <Grid item xs={false}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={body.alarm}
                  checkedIcon={<NotificationImportantOutlined />}
                  color="warning"
                  icon={<NotificationsOffOutlined />}
                  onChange={handleChangeAlarm}
                />
              }
              label={<FormLabel>{t('label.alarm')}</FormLabel>}
              labelPlacement="top"
              sx={{ pointerEvents: !isEditing ? 'none' : undefined }}
            />
          </Grid>
        )}
      </Grid>
      {isLogLevelEnable && (
        <>
          {isEditing && (
            <ChipSelect title={t('label.logLevel')}>
              <ChipSelectItem
                label={t('text.default')}
                selected={body.logLevel === null}
                size="small"
                onClick={handleChangeLogLevel(null)}
              />
              {AllLogLevels.map(logLevel => (
                <ChipSelectItem
                  key={logLevel}
                  color={logLevelColors[logLevel]}
                  icon={<SvgIcon component={logLevelIcons[logLevel]} />}
                  label={t(`logLevel.${logLevel}`)}
                  selected={logLevel === body.logLevel}
                  size="small"
                  onClick={handleChangeLogLevel(logLevel)}
                />
              ))}
            </ChipSelect>
          )}
          {!isEditing && (
            <Stack alignItems="flex-start" gap={0.5}>
              <FormLabel>{t('label.logLevel')}</FormLabel>
              {!body.logLevel && <Chip label={t('text.default')} size="small" variant="outlined" />}
              {body.logLevel && (
                <Chip
                  color={logLevelColors[body.logLevel]}
                  icon={<SvgIcon component={logLevelIcons[body.logLevel]} />}
                  label={t(`logLevel.${body.logLevel}`)}
                  size="small"
                  variant="outlined"
                />
              )}
            </Stack>
          )}
        </>
      )}
    </Box>
  );
};

export default DeviceCommonForm;
