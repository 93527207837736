import { TextFieldVariants } from '@mui/material';
import { ChangeEvent, ChangeEventHandler, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AdvancedTextField, { AdvancedTextFieldProps } from './AdvancedTextField';

export const defaultParser = (inputValue: string) => parseFloat(inputValue);

export interface AdvancedNumberTextFieldBaseProps extends Omit<AdvancedTextFieldProps, 'value' | 'onChange' | 'variant'> {
  parser?: (inputValue: string) => number;
  variant?: TextFieldVariants;
  min?: number;
  max?: number;
}

export interface NullableProps {
  isNullable: true;
  value: number | null;
  onChange: (event: ChangeEvent<HTMLInputElement>, newValue: number | null) => void;
}

export interface NonNullableProps {
  isNullable?: false;
  value: number;
  onChange: (event: ChangeEvent<HTMLInputElement>, newValue: number) => void;
}

export type AdvancedNumberTextFieldProps = AdvancedNumberTextFieldBaseProps & (NullableProps | NonNullableProps);

const AdvancedNumberTextField: FC<AdvancedNumberTextFieldProps> = ({
  isNullable = false,
  value,
  onChange,
  parser = defaultParser,
  variant = 'outlined',
  min = undefined,
  max = undefined,
  ...props
}) => {
  const { t } = useTranslation('global');

  const [inputValue, setInputValue] = useState<string>(`${value}`);

  const areSameValues = useMemo(
    () => (isNullable && value === null && inputValue.trim() === '') || inputValue.trim() === `${value}`,
    [inputValue, value, isNullable],
  );

  const minMaxHelper = useMemo(() => {
    const helpers: string[] = [];
    if (min !== undefined) {
      helpers.push(t('helper.min', { min }));
    }
    if (max !== undefined) {
      helpers.push(t('helper.max', { max }));
    }
    return helpers.join(' ; ');
  }, [min, max, t]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setInputValue(event.target.value);
      const parsedValue = isNullable && event.target.value.trim() === '' ? null : parser(event.target.value);
      if (isNullable && parsedValue === null) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onChange(event, parsedValue);
      } else if (
        parsedValue !== null &&
        !Number.isNaN(parsedValue) &&
        (min === undefined || min <= parsedValue) &&
        (max === undefined || max >= parsedValue)
      ) {
        onChange(event, parsedValue);
      }
    },
    [onChange, isNullable, parser, min, max],
  );

  useEffect(() => {
    setInputValue(state => (state === '-0' && value === 0 ? '-0' : `${value === null ? '' : value}`));
  }, [value]);

  return (
    <AdvancedTextField
      error={!areSameValues}
      helperText={!areSameValues ? t('text.appliedValue', { value: value === null ? t('text.noValue') : value }) : minMaxHelper}
      required={!isNullable}
      slotProps={{ htmlInput: { min, max } }}
      type="number"
      value={inputValue}
      variant={variant}
      onChange={handleChange}
      {...props}
    />
  );
};

export default AdvancedNumberTextField;
