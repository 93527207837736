import { EntitiesGroupsView } from 'components/UI/Entities/EntitiesGroups/EntitiesGroupsViewPicker/useEntitiesGroupsViewLocalStorage';
import { EntityType } from 'types/entity';

export default {
  title: {
    favoriteEntities: 'Mes favoris',
    myEntities: {
      [EntityType.ALARM]: 'Mes alarmes',
      [EntityType.PLAYGROUND]: 'Mes playgrounds',
      [EntityType.PROJECT]: 'Mes projets',
      [EntityType.REPORT]: 'Mes rapports',
      [EntityType.SYNOPTIC]: 'Mes synoptiques',
    },
    sharedEntities: 'Partagés avec moi',
    privateEntities: 'Privés',
    untaggedEntities: 'Sans tag',
    quickAccess: 'Accès rapides',
    filters: 'Filtres',
    groupBy: 'Grouper par',
    sortBy: 'Trier par',
    settings: 'Paramètres',
    leftDrawer: 'Filtres',
    layout: 'Affichage',
    rightDrawer: 'Accès rapides',
    noTags: 'Sans tag',
    noOwner: 'Sans auteur',
  },
  groupBy: {
    all: 'Tout',
    sharings: 'Partage',
    tags: 'Tags',
    owner: 'Auteur',
  },
  sortBy: {
    nameAsc: 'Nom A-Z',
    nameDesc: 'Nom Z-A',
    createdAtAsc: 'Anciennement créés',
    createdAtDesc: 'Récemment créés',
    updatedAtAsc: 'Anciennement modifiés',
    updatedAtDesc: 'Récemment modifiés',
  },
  placeholder: {
    groupBy: 'Grouper par',
    sortBy: 'Trier par',
    owners: 'Rechercher un utilisateur...',
  },
  tooltip: {
    expandAll: 'Tout développer',
    collapseAll: 'Tout réduire',
    hideFilters: 'Cacher les filtres',
    displayFilters: 'Afficher les filtres',
    open: 'Ouvrir',
    close: 'Fermer',
    sortByOrder: {
      asc: 'Croissant',
      desc: 'Décroissant',
    },
    reset: 'Réinitialiser',
  },
  text: {
    createdBy: 'Créé par {{name}}',
    updatedAt: 'Modifié {{date}}',
    noEntity: 'Aucun élément',
    loading: 'Chargement',
    emptyGroup: 'Aucun élément dans ce groupe',
    noResults: 'Aucun résultat pour votre recherche',
    noShortcuts: 'Aucun accès rapide',
  },
  button: {
    backToTop: 'Haut de la page',
    closeLeftPanel: 'Fermer les filtres',
    openLeftPanel: 'Filtres',
    leftPanel: 'Filtres',
    closeRightPanel: 'Fermer les accès rapides',
    openRightPanel: 'Accès rapides',
    rightPanel: 'Accès rapides',
    expandAll: 'Tout développer',
    collapseAll: 'Tout réduire',
    reset: 'Réinitialiser',
  },
  label: {
    search: 'Rechercher',
    tags: 'Tags',
    groupBy: 'Grouper par',
    sortBy: 'Trier par',
    allEntities: 'Toutes les entités',
    me: 'Moi',
    owners: 'Propriétaire',
    favorites: 'Favoris',
  },
  view: {
    [EntitiesGroupsView.TILES]: 'Tuiles',
    [EntitiesGroupsView.GRID]: 'Grille',
  },
  toast: {
    defaultGridViewChanged: 'La vue par défaut a changé.',
  },
};
