import { CheckCircleOutlineOutlined, ContentCopyOutlined, DeleteOutlined, EditOutlined, HideSourceOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Button, FormLabel, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BacnetDeviceConfig,
  BrandDeviceInfo,
  CreateCommonDeviceBody,
  EthernetIPDeviceConfig,
  FileDeviceConfig,
  HttpDeviceConfig,
  IsBacnetDevice,
  IsEthernetIPDevice,
  IsFileDevice,
  IsHttpDevice,
  IsMQTTDevice,
  IsModbusTcpDevice,
  IsOpcUaDevice,
  IsS7Device,
  IsSQLBigQDeviceConfig,
  IsSQLDefaultDeviceConfig,
  IsSQLDevice,
  IsUserIdentityInfoUserName,
  MQTTDeviceConfig,
  ModbusTcpDeviceConfig,
  OpcUaDeviceConfig,
  ProtocolKind,
  S7DeviceConfig,
  SQLDeviceConfig,
  ShortDeviceInfo,
  SyncDelay,
  SyncPoint,
  UpdateDeviceBody,
  WorkerImplementedFeature,
} from '@dametis/core';

import ActionButton from 'components/UI/Buttons/ActionButton/ActionButton';
import PromptBeforeDisabling from 'components/UI/PromptBeforeDisabling/PromptBeforeDisabling';
import PromptBeforeLeaving from 'components/UI/PromptBeforeLeaving/PromptBeforeLeaving';
import { RightPanelActions, RightPanelBody, RightPanelFooter, RightPanelSection, RightPanelSectionContent } from 'components/UI/RightPanel';
import { WipFeatures } from 'config/featureFlags';
import makePermissionLabel from 'functions/makePermissionLabel';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { usePermission } from 'hooks/usePermission';
import { useDispatch, useSelector } from 'store';
import { syncBox } from 'store/actions/currentBox';
import { displaySdkErrorToast } from 'store/actions/toasts';
import { useReadBrandDevicesQuery } from 'store/api/brandDevices';
import { useDeleteDeviceMutation, useUpdateDeviceMutation } from 'store/api/devices';
import { addToast } from 'store/slices/toast';
import { ToastSeverity } from 'types';

import BrandDevicePreview from '../CreateDeviceModal/BrandDevicePreview';
import DeviceCommonForm from '../CreateDeviceModal/DeviceCommonForm';
import DeviceSettingsForm from '../CreateDeviceModal/DeviceSettingsForm';
import { createBacnetDeviceConfig } from '../helpers/createBacnetDeviceConfig';
import { createCreateCommonDeviceBody } from '../helpers/createCreateCommonDeviceBody';
import { createEthernetIPDeviceConfig } from '../helpers/createEthernetIPDeviceConfig';
import { createFileDeviceConfig } from '../helpers/createFileDeviceConfig';
import { createHttpDeviceConfig } from '../helpers/createHttpDeviceConfig';
import { createMQTTDeviceConfig } from '../helpers/createMQTTDeviceConfig';
import { createModbusTcpDeviceConfig } from '../helpers/createModbusTcpDeviceConfig';
import { createOpcUaDeviceConfig } from '../helpers/createOpcUaDeviceConfig';
import { createS7DeviceConfig } from '../helpers/createS7DeviceConfig';
import { createSQLBigQDeviceConfig, createSQLDefaultDeviceConfig } from '../helpers/createSQLDeviceConfig';

import PromptBeforeDeletingDevice from './PromptBeforeDeletingDevice';

const brandDevicesEmptyArray: BrandDeviceInfo[] = [];

export interface InformationsPanelProps {
  device: ShortDeviceInfo;
}

const InformationsPanel: FC<InformationsPanelProps> = ({ device }) => {
  const { t } = useTranslation('devices');
  const dispatch = useDispatch();

  const canEditConfiguration = usePermission('canEditConfiguration');
  const canAccessVariableUuid = usePermission('canAccessVariableUuid');

  const { data: brandDevices = brandDevicesEmptyArray } = useReadBrandDevicesQuery();

  const [updateDevice, { isLoading: isUpdatingDevice }] = useUpdateDeviceMutation();
  const [deleteDevice, { isLoading: isDeletingDevice }] = useDeleteDeviceMutation();

  const boxId = useSelector(state => state.currentBox?.infos?.uuid);
  const workerImplementsSyncDelay = useSelector(
    state => state?.currentBox?.infos?.implementedFeatures?.includes(WorkerImplementedFeature.SYNC_DELAY) ?? false,
  );

  const enableSyncDelay = useFeatureFlags(WipFeatures.ENABLE_SYNC_DELAY);
  const enableSyncPoint = useFeatureFlags(WipFeatures.ENABLE_SYNC_POINT);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isPromptBeforeDisableOpen, setIsPromptBeforeDisableOpen] = useState<boolean>(false);
  const [isPromptBeforeDeleteOpen, setIsPromptBeforeDeleteOpen] = useState<boolean>(false);

  const brandDevice = useMemo(
    () => brandDevices.find(storeBrandDevice => storeBrandDevice.uuid === device.brandDeviceId),
    [brandDevices, device],
  );

  /**
   * Common
   */
  const [commonDeviceBody, setCommonDeviceBody] = useState<CreateCommonDeviceBody>(createCreateCommonDeviceBody);

  /**
   * Protocols
   */
  const [modbusTcp, setModbusTcp] = useState<ModbusTcpDeviceConfig>(createModbusTcpDeviceConfig);
  const [opcUa, setOpcUa] = useState<OpcUaDeviceConfig>(createOpcUaDeviceConfig);
  const [sql, setSQL] = useState<SQLDeviceConfig>(createSQLDefaultDeviceConfig);
  const [file, setFile] = useState<FileDeviceConfig>(createFileDeviceConfig);
  const [s7, setS7] = useState<S7DeviceConfig>(createS7DeviceConfig);
  const [http, setHttp] = useState<HttpDeviceConfig>(createHttpDeviceConfig);
  const [bacnet, setBacnet] = useState<BacnetDeviceConfig>(createBacnetDeviceConfig);
  const [mqtt, setMQTT] = useState<MQTTDeviceConfig>(createMQTTDeviceConfig);
  const [ethernetIp, setEthernetIP] = useState<EthernetIPDeviceConfig>(createEthernetIPDeviceConfig);

  const areActionsDisabled = useMemo(
    () => isEditing || isUpdatingDevice || isDeletingDevice,
    [isEditing, isUpdatingDevice, isDeletingDevice],
  );

  const handleEditDevice = useCallback(() => {
    setIsEditing(true);
  }, [setIsEditing]);

  const handleCancelEdit = useCallback(() => {
    setIsEditing(false);
  }, [setIsEditing]);

  const handleChangeSyncPoint = useCallback((newSyncPoint: SyncPoint) => {
    setCommonDeviceBody(state => ({ ...state, syncPoint: newSyncPoint }));
  }, []);

  const handleChangeSyncDelay = useCallback((newSyncDelay: SyncDelay) => {
    setCommonDeviceBody(state => ({ ...state, syncDelay: newSyncDelay }));
  }, []);

  const handleChangeFilterPastPoints = useCallback((newFilterPastPoints: boolean) => {
    setCommonDeviceBody(state => ({ ...state, filterPastPoints: newFilterPastPoints }));
  }, []);

  const handleOpenPromptBeforeDisable = useCallback(() => {
    setIsPromptBeforeDisableOpen(true);
  }, []);

  const handleSyncronizeBox = useCallback(async () => {
    if (!boxId) {
      return;
    }
    try {
      await dispatch(syncBox(boxId));
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
    }
  }, [dispatch, boxId]);

  const handleDisableDevice = useCallback(async () => {
    try {
      await updateDevice({ uuid: device.uuid, body: { enabled: false } });
      dispatch(addToast({ severity: ToastSeverity.SUCCESS, message: t('toast:successDisableDevice') }));
      void handleSyncronizeBox();
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
    }
    setIsPromptBeforeDisableOpen(false);
  }, [dispatch, device.uuid, t, updateDevice, handleSyncronizeBox]);

  const handleEnableDevice = useCallback(async () => {
    try {
      await updateDevice({ uuid: device.uuid, body: { enabled: true } });
      dispatch(addToast({ severity: ToastSeverity.SUCCESS, message: t('toast:successEnableDevice') }));
      void handleSyncronizeBox();
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
    }
  }, [dispatch, device.uuid, t, updateDevice, handleSyncronizeBox]);

  const handleOpenPromptBeforeDelete = useCallback(() => {
    setIsPromptBeforeDeleteOpen(true);
  }, []);

  const handleDeleteDevice = useCallback(async () => {
    try {
      await deleteDevice(device.uuid);
      dispatch(addToast({ severity: ToastSeverity.SUCCESS, message: t('toast:successDeleteDevice') }));
      void handleSyncronizeBox();
    } catch (err) {
      dispatch(displaySdkErrorToast(err));
      throw err;
    }
    setIsPromptBeforeDeleteOpen(false);
  }, [dispatch, device.uuid, t, deleteDevice, handleSyncronizeBox]);

  const getProtocolBody = useCallback(
    (protocol: ProtocolKind | undefined) => {
      if (protocol === ProtocolKind.MODBUS_TCP) {
        return modbusTcp;
      }
      if (protocol === ProtocolKind.OPC_UA) {
        const parsedConnections = opcUa.connections.map(connection =>
          IsUserIdentityInfoUserName(connection.userIdentityInfo)
            ? {
                ...connection,
                userIdentityInfo: {
                  ...connection.userIdentityInfo,
                  userName: connection.userIdentityInfo.userName.trim().length === 0 ? undefined : connection.userIdentityInfo.userName,
                  password: connection.userIdentityInfo.password.trim().length === 0 ? undefined : connection.userIdentityInfo.password,
                },
              }
            : connection,
        );
        return { ...opcUa, connections: parsedConnections };
      }
      if (protocol === ProtocolKind.SQL) {
        return sql;
      }
      if (protocol === ProtocolKind.FILE) {
        return file;
      }
      if (protocol === ProtocolKind.S7) {
        return s7;
      }
      if (protocol === ProtocolKind.HTTP) {
        return http;
      }
      if (protocol === ProtocolKind.BACNET) {
        return bacnet;
      }
      if (protocol === ProtocolKind.MQTT) {
        return mqtt;
      }
      if (protocol === ProtocolKind.CUSTOM) {
        return {};
      }
      if (protocol === ProtocolKind.ETHERNET_IP) {
        return ethernetIp;
      }
      return undefined;
    },
    [modbusTcp, opcUa, sql, file, s7, http, bacnet, mqtt, ethernetIp],
  );

  const handleSaveDevice = useCallback(async () => {
    if (!boxId) {
      return;
    }
    const protocolBody = getProtocolBody(commonDeviceBody.protocol);
    if (!protocolBody || !commonDeviceBody.protocol) {
      return;
    }

    const updateDeviceBody: UpdateDeviceBody = {
      ...commonDeviceBody,
      [commonDeviceBody.protocol]: protocolBody,
    };

    try {
      await updateDevice({ uuid: device.uuid, body: updateDeviceBody });
      dispatch(addToast({ severity: ToastSeverity.SUCCESS, message: t('toast:successEditDevice') }));
      void handleSyncronizeBox();
      setIsEditing(false);
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
    }
  }, [boxId, device.uuid, commonDeviceBody, dispatch, getProtocolBody, t, updateDevice, handleSyncronizeBox]);

  const initData = useCallback(() => {
    setCommonDeviceBody(createCreateCommonDeviceBody({ ...device, brandDeviceId: device.brandDeviceId ?? undefined }));
    if (IsModbusTcpDevice(device)) {
      setModbusTcp(createModbusTcpDeviceConfig(device.modbusTcp));
    }
    if (IsOpcUaDevice(device)) {
      setOpcUa(createOpcUaDeviceConfig(device.opcUa));
    }
    if (IsSQLDevice(device)) {
      if (IsSQLBigQDeviceConfig(device.sql)) {
        setSQL(createSQLBigQDeviceConfig(device.sql));
      }
      if (IsSQLDefaultDeviceConfig(device.sql)) {
        setSQL(createSQLDefaultDeviceConfig(device.sql));
      }
    }
    if (IsFileDevice(device)) {
      setFile(createFileDeviceConfig(device.file));
    }
    if (IsS7Device(device)) {
      setS7(createS7DeviceConfig(device.s7));
    }
    if (IsHttpDevice(device)) {
      setHttp(createHttpDeviceConfig(device.http));
    }
    if (IsBacnetDevice(device)) {
      setBacnet(createBacnetDeviceConfig(device.bacnet));
    }
    if (IsMQTTDevice(device)) {
      setMQTT(createMQTTDeviceConfig(device.mqtt));
    }
    if (IsEthernetIPDevice(device)) {
      setEthernetIP(createEthernetIPDeviceConfig(device.ethernetIp));
    }
  }, [device]);

  useEffect(() => {
    if (!isEditing) {
      initData();
    }
  }, [isEditing, initData]);

  useEffect(() => {
    if (device.uuid) {
      setIsEditing(false);
    }
  }, [device.uuid]);

  const copyToClipboard = useCallback(
    (text: string) => async () => {
      try {
        await navigator.clipboard.writeText(text);
        dispatch(
          addToast({
            message: t('toast.copyToClipboard'),
            severity: ToastSeverity.INFO,
          }),
        );
      } catch (err) {
        console.error(err);
      }
    },
    [t, dispatch],
  );

  return (
    <>
      <RightPanelActions>
        <Tooltip title={makePermissionLabel(t('tooltip.editDevice'), canEditConfiguration)}>
          <span>
            <ActionButton disabled={areActionsDisabled || !canEditConfiguration} startIcon={<EditOutlined />} onClick={handleEditDevice}>
              {t('button.edit')}
            </ActionButton>
          </span>
        </Tooltip>
        {device.enabled && (
          <Tooltip title={makePermissionLabel(t('tooltip.disableDevice'), canEditConfiguration)}>
            <span>
              <ActionButton
                disabled={areActionsDisabled || !canEditConfiguration}
                startIcon={<HideSourceOutlined />}
                sx={{ letterSpacing: '-0.1px' }} // SLE: quick win because missing only one pixel
                onClick={handleOpenPromptBeforeDisable}
              >
                {t('button.disable')}
              </ActionButton>
            </span>
          </Tooltip>
        )}
        {!device.enabled && (
          <Tooltip title={makePermissionLabel(t('tooltip.enableDevice'), canEditConfiguration)}>
            <span>
              <ActionButton
                disabled={areActionsDisabled || !canEditConfiguration}
                startIcon={<CheckCircleOutlineOutlined />}
                onClick={handleEnableDevice}
              >
                {t('button.enable')}
              </ActionButton>
            </span>
          </Tooltip>
        )}
        <Tooltip title={makePermissionLabel(t('tooltip.deleteDevice'), canEditConfiguration)}>
          <span>
            <ActionButton
              disabled={areActionsDisabled || !canEditConfiguration}
              startIcon={<DeleteOutlined />}
              onClick={handleOpenPromptBeforeDelete}
            >
              {t('button.delete')}
            </ActionButton>
          </span>
        </Tooltip>
      </RightPanelActions>
      <RightPanelBody>
        <RightPanelSection>
          <RightPanelSectionContent>
            {!device.enabled && (
              <Alert severity="warning" sx={{ mb: 1.5 }}>
                {t('text.deviceDisabled')}
              </Alert>
            )}
            {brandDevice && (
              <BrandDevicePreview
                brandDevice={brandDevice}
                sx={{ background: theme => theme.palette.background.default, mb: 1.5, width: 'fit-content' }}
              />
            )}
            <Box mb={1.5}>
              <DeviceCommonForm
                body={commonDeviceBody}
                canChangeProtocol={false}
                displayBrandDevicePicker={false}
                isEditing={isEditing}
                setBody={setCommonDeviceBody}
              />
            </Box>
            {canAccessVariableUuid && (
              <Grid item mb={1} xs={12}>
                <Box width="fit-content">
                  <FormLabel>{t('label.uuid')}</FormLabel>
                  <Stack alignItems="center" direction="row" gap={1}>
                    <Typography variant="body1">{device.uuid}</Typography>
                    <Tooltip title={t('tooltip.copyToClipboard')}>
                      <span>
                        <IconButton size="small" onClick={copyToClipboard(device.uuid)}>
                          <ContentCopyOutlined fontSize="small" />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Stack>
                </Box>
              </Grid>
            )}
            <DeviceSettingsForm
              bacnet={bacnet}
              ethernetIp={ethernetIp}
              file={file}
              filterPastPoints={commonDeviceBody.filterPastPoints}
              http={http}
              isEditing={isEditing}
              location="panel"
              modbusTcp={modbusTcp}
              mode="edit"
              mqtt={mqtt}
              opcUa={opcUa}
              protocol={commonDeviceBody.protocol}
              s7={s7}
              setBacnet={setBacnet}
              setEthernetIP={setEthernetIP}
              setFile={setFile}
              setHttp={setHttp}
              setModbusTcp={setModbusTcp}
              setMQTT={setMQTT}
              setOpcUa={setOpcUa}
              setS7={setS7}
              setSQL={setSQL}
              sql={sql}
              syncDelay={enableSyncDelay && workerImplementsSyncDelay ? commonDeviceBody.syncDelay : undefined}
              syncPoint={enableSyncPoint ? commonDeviceBody.syncPoint : undefined}
              onChangeFilterPastPoints={handleChangeFilterPastPoints}
              onChangeSyncDelay={handleChangeSyncDelay}
              onChangeSyncPoint={handleChangeSyncPoint}
            />
          </RightPanelSectionContent>
        </RightPanelSection>
      </RightPanelBody>
      {isEditing && (
        <RightPanelFooter sx={{ gap: 1 }}>
          <Button disabled={isUpdatingDevice} onClick={handleCancelEdit}>
            {t('button.cancel')}
          </Button>
          <LoadingButton
            color="secondary"
            // disabled={!isCommonValid || !isSettingsValid}
            loading={isUpdatingDevice}
            variant="contained"
            onClick={handleSaveDevice}
          >
            {t('button.save')}
          </LoadingButton>
        </RightPanelFooter>
      )}
      <PromptBeforeDisabling open={isPromptBeforeDisableOpen} setOpen={setIsPromptBeforeDisableOpen} onDisable={handleDisableDevice} />
      <PromptBeforeDeletingDevice
        device={device}
        open={isPromptBeforeDeleteOpen}
        setOpen={setIsPromptBeforeDeleteOpen}
        onDelete={handleDeleteDevice}
      />
      <PromptBeforeLeaving when={isEditing} />
    </>
  );
};

export default InformationsPanel;
