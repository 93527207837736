import {
  AccordionProps,
  AccordionSummary,
  Checkbox,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
} from '@mui/material';
import { FC, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { usePermission } from 'hooks/usePermission';
import { openReplayEvent } from 'openreplay/openreplay.ts';
import { OpenReplayEvent } from 'openreplay/types/index.ts';
import { useDispatch } from 'store';
import { AllVariableKind, FakeVariableKind, IsFakeKind, IsStandardKind } from 'types/variables';
import { useVncStore } from 'zustand/stores/vnc';

import { VncFilters } from '../../../../zustand/states/vnc.ts';
import Counter from '../../../UI/Counter/Counter';
import VariableKindIcon from '../../../UI/VariableKindIcon/VariableKindIcon';
import { PropsContext } from '../../context';

import { FilterElementProps } from './Filters';
import { FilterAccordion, FilterAccordionDetails, FilterAccordionSummaryStack, FilterList, FilterListSubheader } from './styled';

interface SortedKind {
  key: AllVariableKind;
  name: string;
  enabled: boolean;
}

const KindsFilters: FC<FilterElementProps> = ({ expanded, setExpanded, accordionsNumber }) => {
  const { t } = useTranslation(['vnc', 'data']);
  const dispatch = useDispatch();

  const canAccessAlarm = usePermission('canAccessAlarm');
  const canAccessBatch = usePermission('canAccessBatch');
  const canAccessOpco = usePermission('canAccessOpco');

  const disabled = useContext(PropsContext).disableFilters.includes('kinds');

  const kinds = useVncStore(state => state.filters.kinds);
  const setFiltersValues = useVncStore(state => state.setFiltersValues);
  const getResults = useVncStore(state => state.getResults);

  const sortedKinds = useMemo<SortedKind[]>(
    () =>
      Object.entries(kinds)
        .filter(
          ([key]) =>
            (key !== FakeVariableKind.ALARM || canAccessAlarm) &&
            (key !== FakeVariableKind.BATCH || canAccessBatch) &&
            (key !== FakeVariableKind.VAPOR_MIX || canAccessOpco),
        )
        .map(([key, enabled]) => ({ key: key as AllVariableKind, name: t(`data:variableKind.${key}`), enabled })),
    [canAccessAlarm, canAccessBatch, canAccessOpco, kinds, t],
  );

  const selectedKindsCount = useMemo(() => sortedKinds.filter(kind => kind.enabled).length, [sortedKinds]);

  const changeExpanded = useCallback<NonNullable<AccordionProps['onChange']>>(
    (_event, isExpanded) => {
      setExpanded(isExpanded ? 'kinds' : false);
    },
    [setExpanded],
  );

  const toggleKind = useCallback(
    (newKind: AllVariableKind) => async () => {
      setFiltersValues({
        kinds: IsFakeKind(newKind)
          ? Object.keys(kinds).reduce<VncFilters['kinds']>((accu, kind) => {
              accu[kind as AllVariableKind] = newKind === kind;
              return accu;
            }, {})
          : Object.entries(kinds).reduce<VncFilters['kinds']>((accu, [kindKey, kindEnabled]) => {
              accu[kindKey as AllVariableKind] =
                kindKey === newKind ? !kindEnabled : IsStandardKind(kindKey as AllVariableKind) && kindEnabled;
              return accu;
            }, {}),
      });
      openReplayEvent(OpenReplayEvent.VNC_FILTER_OTHER);
      await dispatch(getResults());
    },
    [dispatch, getResults, kinds, setFiltersValues],
  );

  const standardKinds = useMemo(() => sortedKinds.filter(kind => IsStandardKind(kind.key)), [sortedKinds]);
  const fakeKinds = useMemo(() => sortedKinds.filter(kind => IsFakeKind(kind.key)), [sortedKinds]);

  const isAllStandardChecked = useMemo(() => standardKinds.every(standardKind => standardKind.enabled), [standardKinds]);
  const isAllStandardIndeterminate = useMemo(
    () => !isAllStandardChecked && standardKinds.some(standardKind => standardKind.enabled),
    [isAllStandardChecked, standardKinds],
  );

  const toggleAllStandardKinds = useCallback(async () => {
    setFiltersValues({
      kinds: Object.keys(kinds).reduce<VncFilters['kinds']>((accu, kindKey) => {
        accu[kindKey as AllVariableKind] =
          !isAllStandardChecked && !isAllStandardIndeterminate ? IsStandardKind(kindKey as AllVariableKind) : false;
        return accu;
      }, {}),
    });
    openReplayEvent(OpenReplayEvent.VNC_FILTER_OTHER);
    await dispatch(getResults());
  }, [dispatch, getResults, isAllStandardChecked, isAllStandardIndeterminate, kinds, setFiltersValues]);

  return (
    <FilterAccordion expanded={expanded} filterAccordionsNumber={accordionsNumber} onChange={changeExpanded}>
      <AccordionSummary>
        <FilterAccordionSummaryStack>
          <FilterListSubheader>{t('accordion.kinds')}</FilterListSubheader>
          {selectedKindsCount > 0 && <Counter count={selectedKindsCount} />}
        </FilterAccordionSummaryStack>
      </AccordionSummary>
      <FilterAccordionDetails>
        <FilterList>
          {standardKinds.length > 1 && (
            <ListItem
              disablePadding
              secondaryAction={
                <Checkbox checked={isAllStandardChecked} disabled={disabled} edge="end" indeterminate={isAllStandardIndeterminate} />
              }
              onClick={toggleAllStandardKinds}
            >
              <ListItemButton disabled={disabled} selected={isAllStandardChecked} onClick={toggleAllStandardKinds}>
                <ListItemText primary={t('variables:text.selectAll')} />
              </ListItemButton>
            </ListItem>
          )}
          {standardKinds.map(kind => (
            <ListItem
              key={kind.key}
              disablePadding
              secondaryAction={<Checkbox checked={kind.enabled} disabled={disabled} edge="end" onClick={toggleKind(kind.key)} />}
            >
              <ListItemButton disabled={disabled} selected={kind.enabled} onClick={toggleKind(kind.key)}>
                <ListItemIcon>
                  <VariableKindIcon kind={kind.key} />
                </ListItemIcon>
                <ListItemText primary={kind.name} />
              </ListItemButton>
            </ListItem>
          ))}
          {Boolean(standardKinds.length && fakeKinds.length) && <Divider />}
          {fakeKinds.map(kind => (
            <ListItem
              key={kind.key}
              disablePadding
              secondaryAction={<Radio checked={kind.enabled} disabled={disabled} edge="end" onClick={toggleKind(kind.key)} />}
            >
              <ListItemButton disabled={disabled} selected={kind.enabled} onClick={toggleKind(kind.key)}>
                <ListItemIcon>
                  <VariableKindIcon kind={kind.key} />
                </ListItemIcon>
                <ListItemText primary={kind.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </FilterList>
      </FilterAccordionDetails>
    </FilterAccordion>
  );
};

export default KindsFilters;
