import { Box, Stack } from '@mui/material';
import { FC, useCallback, useContext } from 'react';

import BlockTree from 'components/UI/Block/BlockTree';
import { PropsContext } from 'components/VNC/context';
import { useVncStore } from 'zustand/stores/vnc';

import BlockElementsList from './BlockElementsList';
import BlockTypeElementList from './BlockTypeElementList';

const BlockDiagramList: FC = () => {
  const { standardBlockMetricMode } = useContext(PropsContext);
  const selectedBlockId = useVncStore(state => state.lego.selectedBlockUuid);
  const setSelectedBlockUuid = useVncStore(state => state.setSelectedBlockUuid);

  const click = useCallback(
    (blockId: string) => {
      setSelectedBlockUuid(blockId);
    },
    [setSelectedBlockUuid],
  );

  return standardBlockMetricMode ? (
    <BlockTypeElementList />
  ) : (
    <Stack direction="row" height="100%" width="100%">
      <Box flexGrow={1} width={0}>
        <BlockTree hideEmptyChildren selectedId={selectedBlockId} onClick={click} />
      </Box>
      {selectedBlockId && (
        <Box sx={{ height: '100%', overflowY: 'auto', width: '300px' }}>
          <BlockElementsList />
        </Box>
      )}
    </Stack>
  );
};

export default BlockDiagramList;
