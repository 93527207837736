import { Checkbox, ListItem, ListItemAvatar, ListItemButton, ListItemButtonProps, ListItemText, Stack, Tooltip } from '@mui/material';
import { FC, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockMetric, BlockVariable, UUID } from '@dametis/core';

import CalculationSlate from 'components/UI/CalculationSlate/CalculationSlate';
import PhysicalQuantityAvatar from 'components/UI/PhysicalQuantity/PhysicalQuantityAvatar';
import TypographyEllipse from 'components/UI/TypographyEllipse/TypographyEllipse.tsx';
import { PropsContext } from 'components/VNC/context';
import { getPhysicalQuantities } from 'config';
import { useVncStore } from 'zustand/stores/vnc';

import { AreSameVarCalc } from '../../../../../functions/tada/helpers.ts';
import { TypographyNoBreak } from '../TypographyNoBreak';

interface Props extends ListItemButtonProps {
  blockId: UUID;
  element: BlockMetric;
  metricCalcVisible?: boolean;
  calcVisible?: boolean;
}

const BlockElementMetric: FC<Props> = ({ blockId, element, disabled, calcVisible = false, ...props }) => {
  const { t } = useTranslation('vnc');

  const { multiple, defaultVariableOperator, defaultGlobalOperator } = useContext(PropsContext);

  const physicalQuantityFilter = useVncStore(state => state.filters.physicalQuantities);
  const selection = useVncStore(state => state.selection);
  const someSelected = useVncStore(state => Boolean(state.selection.length));
  const addToSelection = useVncStore(state => state.addToSelection);
  const removeByUuidFromSelection = useVncStore(state => state.removeByUuidFromSelection);

  const selected = useMemo(
    () => selection.some(varCalc => AreSameVarCalc(varCalc, { blockId, blockKey: element.uuid })),
    [selection, blockId, element.uuid],
  );

  const color = useMemo(() => getPhysicalQuantities()[element.physicalQuantity]?.color, [element.physicalQuantity]);
  const isPhysicalQuantityFilterActive = useMemo(
    () => Object.values(physicalQuantityFilter).filter(isActive => isActive === true).length > 0,
    [physicalQuantityFilter],
  );

  const toggleElementInSelection = useCallback(() => {
    const calculation: BlockVariable = {
      blockId,
      blockKey: element.uuid,
      operator: defaultVariableOperator ?? defaultGlobalOperator ?? undefined,
    };
    if (selected) {
      removeByUuidFromSelection(calculation);
    } else {
      addToSelection(calculation);
    }
  }, [blockId, element.uuid, defaultVariableOperator, defaultGlobalOperator, selected, removeByUuidFromSelection, addToSelection]);

  return (
    <ListItem
      disablePadding
      secondaryAction={
        multiple &&
        !disabled && (
          <Tooltip placement="right" title={t(selected ? 'tooltip.removeFromSelection' : 'tooltip.addToSelection')}>
            <Checkbox
              checked={selected}
              edge="end"
              sx={[color !== undefined && { color: `${color} !important` }]}
              onClick={toggleElementInSelection}
            />
          </Tooltip>
        )
      }
    >
      <ListItemButton
        {...props}
        disableGutters
        disabled={disabled || (isPhysicalQuantityFilterActive && !physicalQuantityFilter[element.physicalQuantity])}
        selected={selected}
        onClick={someSelected ? toggleElementInSelection : props.onClick}
      >
        <Stack alignItems="left" flexGrow={1} justifyContent="center" spacing={0.5} width={0}>
          <Stack alignItems="center" direction="row">
            <ListItemAvatar>
              <Stack alignItems="center" spacing={0.5} sx={{ width: 40 }}>
                <PhysicalQuantityAvatar physicalQuantity={element.physicalQuantity} size="default" />
              </Stack>
            </ListItemAvatar>
            <ListItemText
              primary={
                <TypographyEllipse noWrap variant="h6">
                  {element.name}
                </TypographyEllipse>
              }
              secondary={calcVisible && element.calculation && <CalculationSlate calculation={element.calculation} />}
            />
          </Stack>
          <TypographyNoBreak variant="caption">{t('list.text.variable')}</TypographyNoBreak>
        </Stack>
      </ListItemButton>
    </ListItem>
  );
};

export default BlockElementMetric;
