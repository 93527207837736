import { CircularProgress, Stack } from '@mui/material';
import { FC } from 'react';

const IsCreatingLoader: FC = () => (
  <Stack
    alignItems="center"
    bottom={0}
    height="100%"
    justifyContent="center"
    position="absolute"
    sx={{ background: theme => theme.palette.background.default, opacity: 0.5 }}
    top={0}
    width="100%"
    zIndex={1}
  >
    <CircularProgress color="secondary" size={25} />
  </Stack>
);

export default IsCreatingLoader;
