import { FindFolder, FindFolderPath, FolderInfo, ShortcutCategory, UUID } from '@dametis/core';

export const getStandardBlockPath = (globalRootFolder: FolderInfo | null, standardBlockId: UUID): FolderInfo[] => {
  if (!globalRootFolder) {
    return [];
  }
  const parentFolder = FindFolder(
    globalRootFolder,
    folder =>
      folder.shortcuts.find(shortcut => shortcut.category === ShortcutCategory.STANDARD_BLOCK && shortcut.uuid === standardBlockId) !==
      undefined,
  );
  if (!parentFolder) {
    return [];
  }
  try {
    return FindFolderPath(globalRootFolder, parentFolder);
  } catch {
    return [];
  }
};
