import {
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUp,
  KeyboardCommandKey,
  KeyboardReturn,
} from '@mui/icons-material';
import { StackProps } from '@mui/material';
import { useUserAgent } from '@oieduardorabelo/use-user-agent';
import { FC, Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-key-enum';

import { KBM } from './KBM';
import { KBMPlus } from './KBMPlus';
import { KBMs } from './KBMs';

interface HotKeysProps extends StackProps {
  hotkeys: string;
  size?: 'small' | 'medium';
}

export const Hotkeys: FC<HotKeysProps> = ({ hotkeys, size = 'medium', ...props }) => {
  const { t, i18n } = useTranslation('kbm');
  const os = useUserAgent()?.os.name;

  const keys = useMemo(() => hotkeys.split('+'), [hotkeys]);

  const displayKey = useCallback(
    (key: string) => {
      switch (key) {
        case 'mod':
          return os === 'Mac OS' ? <KeyboardCommandKey fontSize="inherit" /> : 'Ctrl';
        case Key.ArrowUp:
          return <KeyboardArrowUp fontSize="inherit" />;
        case Key.ArrowDown:
          return <KeyboardArrowDown fontSize="inherit" />;
        case Key.ArrowLeft:
          return <KeyboardArrowLeft fontSize="inherit" />;
        case Key.ArrowRight:
          return <KeyboardArrowRight fontSize="inherit" />;
        case Key.Enter:
          return <KeyboardReturn fontSize="inherit" sx={{ mx: '0.2em' }} />;
        default:
          return i18n.exists(key) ? t(key) : key;
      }
    },
    [i18n, os, t],
  );

  return (
    <KBMs {...props}>
      {keys.map((key, index) => (
        <Fragment key={key}>
          <KBM size={size}>{displayKey(key)}</KBM>
          {index !== keys.length - 1 && <KBMPlus size={size} />}
        </Fragment>
      ))}
    </KBMs>
  );
};
