import { List, ListItem, ListItemButton, ListItemText, ListSubheader, Typography } from '@mui/material';
import { FC, MouseEventHandler, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { MetricCategory } from '@dametis/core';

import { PropsContext } from 'components/VNC/context';
import { calculationToString } from 'functions/calculationToString';
import { useVncStore } from 'zustand/stores/vnc';

import { useInsertVariable } from '../../../hooks';

const BlockTypeElementList: FC = () => {
  const { t } = useTranslation('vnc');

  const insertVariable = useInsertVariable();
  const { editingBlockTypeMetricUuid, disableMaths, defaultVariableOperator, calculatedVariableMode } = useContext(PropsContext);

  const unPostedBlockType = useVncStore(state => state.lego.unPostedBlockType);

  const metrics = useMemo(() => unPostedBlockType?.metrics ?? [], [unPostedBlockType]);
  const calculationMetrics = useMemo(() => metrics.filter(metric => metric.category === MetricCategory.METRIC), [metrics]);
  const discrepancyMetrics = useMemo(() => metrics.filter(metric => metric.category === MetricCategory.DISCREPANCY), [metrics]);
  const technicalMetrics = useMemo(() => metrics.filter(metric => metric.category === MetricCategory.TECHNICAL), [metrics]);

  const handleSelectParameter = useCallback(
    (index: number): MouseEventHandler<HTMLDivElement> =>
      () => {
        const parameter = unPostedBlockType?.parameters?.[index];
        if (parameter) {
          insertVariable(
            { ...parameter, blockId: undefined, blockKey: parameter.uuid },
            disableMaths,
            defaultVariableOperator ?? undefined,
            calculatedVariableMode,
          );
        }
      },
    [disableMaths, insertVariable, unPostedBlockType?.parameters, defaultVariableOperator, calculatedVariableMode],
  );

  const handleSelectMetric = useCallback(
    (index: number): MouseEventHandler<HTMLDivElement> =>
      () => {
        const metric = unPostedBlockType?.metrics?.[index];
        if (metric?.uuid !== undefined) {
          insertVariable(
            { blockKey: metric.uuid, blockId: undefined },
            disableMaths,
            defaultVariableOperator ?? undefined,
            calculatedVariableMode,
          );
        }
      },
    [disableMaths, insertVariable, unPostedBlockType?.metrics, defaultVariableOperator, calculatedVariableMode],
  );

  const renderMetrics = useCallback(
    (scopeMetrics: typeof metrics, category: MetricCategory) =>
      scopeMetrics.length > 0 && (
        <li>
          <ul>
            <ListSubheader disableGutters>{t('subtitle.metricsWithCategory', { context: category })}</ListSubheader>
            {scopeMetrics.map((metric, index) => (
              <ListItemButton
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                disableGutters
                disabled={metric.uuid === editingBlockTypeMetricUuid}
                onClick={handleSelectMetric(index)}
              >
                <ListItemText
                  primary={metric.name.trim().length > 0 ? metric.name : t('text.unNamedMetric')}
                  secondary={calculationToString(metric.calculation)}
                  sx={{ fontStyle: metric.name.trim().length > 0 ? 'normal' : 'italic' }}
                />
              </ListItemButton>
            ))}
          </ul>
        </li>
      ),
    [editingBlockTypeMetricUuid, handleSelectMetric, t],
  );

  return (
    <>
      <List subheader={<li />} sx={{ position: 'relative', maxHeight: '100%', overflow: 'auto', '& ul': { p: 0 } }}>
        <li>
          <ul>
            <ListSubheader disableGutters>{t('subtitle.parameters')}</ListSubheader>
            {unPostedBlockType?.parameters?.map((parameter, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <ListItemButton key={index} disableGutters onClick={handleSelectParameter(index)}>
                <ListItemText
                  primary={
                    parameter.unit?.length !== 0
                      ? t('text.availableParameter', { blockKey: parameter.name, unit: parameter.unit })
                      : parameter.name
                  }
                />
              </ListItemButton>
            ))}
            {!unPostedBlockType?.parameters?.length && (
              <ListItem>
                <Typography variant="subtitle2">{t('text.noParameter')}</Typography>
              </ListItem>
            )}
          </ul>
        </li>
        {metrics.length === 0 && (
          <li>
            <ul>
              <ListSubheader disableGutters>{t('subtitle.metrics')}</ListSubheader>
              <ListItem>
                <Typography variant="subtitle2">{t('text.noMetric')}</Typography>
              </ListItem>
            </ul>
          </li>
        )}
        {renderMetrics(calculationMetrics, MetricCategory.METRIC)}
        {renderMetrics(discrepancyMetrics, MetricCategory.DISCREPANCY)}
        {renderMetrics(technicalMetrics, MetricCategory.TECHNICAL)}
      </List>
    </>
  );
};

export default BlockTypeElementList;
