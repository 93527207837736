import { GridLocaleText } from '@mui/x-data-grid-premium';

import { FileDeviceSourceType, MQTTPropertyMatcherType } from '@dametis/core';

import { CsvParserStep } from 'components/Configuration/Variables/ImportCalculatedVariablesModal/flam';
import { BoxVersionStatus } from 'types/box';

export default {
  title: {
    variables: 'Variables',
    devices: 'Devices',
    export: 'Export',
    newVariable: 'New variable',
    editVariable: 'Edit variable',
    variableDetails: 'Variable details',
    newDevice: 'New device',
    editDevice: 'Edit device',
    deleteDevice_one: 'Delete device',
    editVariable_other: 'Edit {{count}} variables',
    exportVariables: 'Variables',
    exportPeriodicity: 'Dates',
    editExp: 'Edit calculated variable',
    editExpLabel: 'When does the modification have to take effect?',
    noDelete: 'Unable to delete',
    newBox: 'New box',
    groupSettings: 'Group settings',
    groupBoxes: 'Boxes',
    createList: 'Create a list',
    renameList: 'Rename list',
    preview: 'Preview',
    importCalculatedVariablesModal: 'Import calculated variables',
    editBoxAlertThresholds: 'Edit box alert thresholds',
    connections: 'Connections',
    exploration: 'Exploration',
    accessibility: 'Accessibility',
  },
  text: {
    requests: 'Requests',
    modbusTcp: 'Modbus',
    sql: 'SQL',
    file: 'File',
    opcUa: 'OpcUa',
    s7: 'S7',
    http: 'HTTP',
    bacnet: 'BACnet',
    mqtt: 'MQTT',
    calculated: 'Calculated',
    real: 'Acquired',
    manual: 'Manual',
    constant: 'Constant',
    options: 'Options',
    number: 'Number',
    string: 'String',
    boolean: 'Bool',
    selectedVariables_one: 'selected variable',
    selectedVariables_other: 'selected variables',
    deleteDevice: 'Are you sure you want to delete {{name}} ?',
    customName: 'Custom Device',
    previewDisabled: 'Preview temporarily disabled for transposed tables with more than 100,000 points',
    uploadFileError: {
      incorrectType: 'Incorrect file type, only JSON and CSV accepted.',
      parsingError: 'An error occurred while parsing your file, please try again.',
      invalidStructure: 'Your CSV file is invalid, download a template to check required structure.',
    },
    allDevices: 'All devices',
    dateStart: 'Date start',
    dateEnd: 'Date end',
    interval: 'Interval',
    unit: 'Unit',
    lastSync: 'Last synchronization: {{datetime}}',
    version: {
      [BoxVersionStatus.SUCCESS]: 'Dametis box is synchronized',
      [BoxVersionStatus.DEFAULT]: 'Dametis box is synchronizing',
      [BoxVersionStatus.WARNING]: 'Dametis box is currently offline. It will synchronize at its next connection.',
      [BoxVersionStatus.ERROR]: 'Dametis box synchronization failed. Please contact support.',
    },
    deleteVariable: 'Deleting this variable can affect the good functionning of tools (Reports, Synoptics, Playgrounds, ...) using it.',
    noOptions: 'No options',
    clear: 'Clear',
    close: 'Close',
    variables: 'Variables',
    open: 'Open',
    addTagOption: 'Add "{{name}}" option',
    editExp: {
      confirm: 'data of {{name}} will be calculated with :',
      name: 'this variable',
      dateConfirm: 'from {{date}}, ',
    },
    thisVar: 'this variable',
    oldExp: 'Old expression',
    newExp: 'New expression',
    editExpConfirm: {
      date: 'from {{date}}, ',
      name: 'data of {{name}} will be calculated with:',
    },
    from: {
      now: 'Data already calculated until now will stay unchanged (old expression). The new expression will take effect for all new data.',
      date: 'Data already calculated until the chosen date will stay unchanged (old expression). The new expression will take effect for all new data from this date.',
      first: 'All the data will be recalculated with the new expression.',
    },
    noDelete_one: "You can't delete this device while it still has variables ({{count}} associated variable).",
    noDelete_other: "You can't delete this device while it still has variables ({{count}} associated variables).",
    realVariableInfo: 'An acquired variable is a variable whose data is received from a device',
    calculatedVariableInfo: 'A calculated variable is a variable based on other variables, and whose expression determines its value',
    manualVariableInfo: 'A manual variable is a variable whose values will be defined following a manual import',
    constantVariableInfo: 'A constant variable is a variable whose value does not change',
    variablesImport: 'Import of variables',
    importVariablesInfo: 'Load a variables file respecting one of the following models:',
    importFileTemplatesInfo_one: 'Load a file respecting one the following model:',
    importFileTemplatesInfo_other: 'Load a file respecting one of the following models:',
    notAvailable: 'Not available option',
    identification: 'Identification',
    dialect: 'Dialect',
    group: 'Group/Site',
    hardwareInfo: 'Hardware',
    lanUsine: 'Lan Usine',
    lanOut: 'Lan Out',
    stepper: {
      step: 'Step',
      step2: 'Fill the informations',
      step3: 'IP configuration',
      step4: 'Custom routes (optional)',
    },
    customRoute: 'Custom route',
    users: 'Users',
    noUser: 'No user.',
    form: 'Form',
    import: 'Import',
    canNotChangeKind: 'You cannot change the type of a duplicated variable.',
    noSelectedVariable: 'No selected variable.',
    activities: 'Activities',
    lastModification: 'last modification {{date}}',
    csvExample: {
      name: 'EXAMPLE',
      description: 'delete this line',
      utilities: 'separate your tags with / ',
    },
    thisMonth: 'This month',
    thisWeek: 'This week',
    connectedWithoutInterruption_zero: 'Connected without interruption',
    connectedWithoutInterruption_other: 'Disconnected during {{duration}}',
    poweredWithoutInterruption_zero: 'Powered without interruption',
    poweredWithoutInterruption_other: 'Unpowered during {{duration}}',
    boxStatisticTitle: 'Condition of the box from {{from}} to {{to}}',
    noVariableSelected: 'No variable selected',
    noVariableList: 'No variable list',
    selectList: 'Select a list',
    importList: 'Import lists',
    exportList: 'Export lists',
    invalidExport: 'Preview not available: select a list of variables and a valid period',
    invalidExportVar: 'Preview not available : no variables selected',
    invalidExportPeriod: 'Preview unavailable : no valid period selected',
    boxHealthCheckProgress: 'Box healthcheck in progress...',
    boxSetupProgress: 'Box setup in progress...',
    selectFile: 'Drop a file or click here to choose one',
    dropFile: 'Drop the file here',
    fileNotAllowed: 'This type of file is not allowed',
    successCreateCalculatedVariable: 'Calculated variables imported with success',
    errorCreateCalculatedVariable: 'An error occured during import',
    errors_one: '1 error',
    errors_other: '{{count}} errors',
    endpoints: 'URLs',
    authentication: 'Authentication',
    jqTransformer: 'JQ transformer',
    date: 'Date',
    propertyMatcher: 'Property matcher',
    endpoint: 'URL',
    acquisition: 'Acquisition',
    availableVariables: '{{count}} / {{total}} available variables',
    damcoBoxDelay: 'Expected frequency : {{label}}',
    monitoring: 'Monitoring - Alert threshold',
    connection: 'Connection',
    templateVariables: 'Template variables',
    sqlModeExplanation: {
      DEFAULT:
        'In default mode, the requests are defined in the variables of the device. It is also possible to create global variables that can be used in these requests.',
      BULK: 'In bulk mode, the request is defined in the device and is global to each variables, the variables will be retrieve with their reference.',
      TEMPLATE:
        'In template mode, the request is defined in the device and contains template variables which are populated during the configuration of the variable of the device.',
    },
    availableTemplateVariables: 'Available variables',
    globalVariables: 'Global variables',
    noBox: 'No box',
    accessVariableLists: 'Access variable lists',
    newList: 'New list',
    localDate: 'Local date',
    value: 'Value',
    mqttPropertyMatcherExplanation: {
      [MQTTPropertyMatcherType.MAP_ON_TOPIC]: 'In Topic mode, each variable has its topic as reference.',
      [MQTTPropertyMatcherType.MAP_ON_PAYLOAD]:
        'In Message mode, the reference is contained in the message and identified by property matching.',
    },
    startExploration: 'Start an exploration session',
    explorationExplanation: 'The exploration module provides the ability to explore devices within the box context.',
    connectBeforeExplore: 'Please connect before exploring the server.',
    createVariables: 'Create variables',
    exploration: 'Exploration',
    fetch: 'Fetch',
    accessibilityInfo: 'Check host accessibilitity before continuing.',
  },
  input: {
    name: 'Name',
    uuid: 'UUID',
    lastValue: 'Last value',
    reference: 'Reference',
    description: 'Description',
    utilities: 'Utilities',
    defaultValue: 'Default value',
    unit: 'Unit',
    nodeId: 'Node Id',
    address: 'Address',
    offset: 'Offset',
    offsetDescription: 'Variable address offset',
    offsetData: 'Offset',
    loopTime: 'Loop (ms)',
    loopTimeDescription: 'Total loop time, in ms',
    pauseAfterRequest: 'Pause',
    pauseAfterRequestDescription: 'Break time after each request, in ms',
    maxRegisterToReadByRequest: 'Max reg.',
    maxRegisterToReadByRequestDescription: 'Maximal number of registers that could be read by request',
    maxUnusedValue: 'Unused reg.',
    maxUnusedValueDescription: 'Maximal unused registers allowed to read to prevent generation of multiple requests',
    dataType: 'Type',
    function: 'Function',
    transformer: 'Transformer',
    transformerType: 'Transformer type',
    transformerValue: 'Transformer value',
    physicalQuantity: 'Physical quantity',
    kind: 'Variable type',
    comment: 'Comment',
    favorite: 'Favorite',
    accumulate: 'Accumulator',
    warning: 'Flag',
    expression: 'Expression',
    params: 'Expression',
    modbusTcp: 'Modbus',
    sql: 'SQL',
    mqtt: 'MQTT',
    file: 'File',
    opcUa: 'OpcUa',
    enabled: 'Enabled',
    alarm: 'Alarm',
    value: 'Value',
    progress: 'Progress',
    version: 'Version',
    type: 'Type',
    hostModbusTcp: 'IP',
    hostOpcUa: 'Host',
    hostSQL: 'IP',
    endpointMode: 'With endpoint',
    responseTime: 'Response time',
    port: 'Port',
    rack: 'Rack',
    slot: 'Slot',
    slotInfo: {
      S71200CPU: '0 or 1',
      S71500CPU: '0 or 1',
      CP343: '0 (or follow hardware configuration)',
    },
    timeout: 'Timeout',
    localTSAP: 'LocalTSAP',
    remoteTSAP: 'RemoteTSAP',
    protocol: 'Protocol',
    uid: 'Unit ID',
    device: 'Device',
    variables: 'Variables',
    prefix: 'Prefix',
    suffix: 'Suffix',
    transformerType_multiplier: 'Multiplier',
    transformerType_linearInterpolation: 'Linear interpolation',
    bit: 'Bit',
    search: 'Search...',
    searchDevicePlaceholder: 'Research by Name/IP ...',
    invertDoubleWords: 'Invert double words',
    invertWords: 'Invert words',
    invertBytes: 'Invert bytes',
    sensorName: 'Sensor name',
    periodicityUnit: {
      seconds: 'Seconds',
      minutes: 'Minutes',
      hours: 'Hours',
      days: 'Days',
      weeks: 'Weeks',
    },
    nowLabel: 'From now',
    dateLabel: 'From a date',
    firstLabel: 'From the beginning',
    none: 'None',
    placeholderUtilities: 'Utility',
    placeholderPQ: 'Physical quantity',
    placeholderKind: 'Variable type',
    server: 'Server',
    endpoint: 'Endpoint',
    securityPolicy: 'Security policy',
    securityMode: 'Security mode',
    userIdentityInfo: {
      type: 'Type',
      userName: 'Username',
      password: 'Password',
      certificateData: 'Certificate',
      privateKey: 'Private key',
    },
    credentials: {
      username: 'Username',
      password: 'Password',
    },
    dialect: 'Dialect',
    request: 'Request',
    machineId: 'Machine ID',
    group: 'Group',
    site: 'Site',
    mode: 'Mode',
    ip: 'Ip',
    mask: 'Mask',
    gateway: 'Gateway',
    lan: 'Lan',
    subnet: 'Subnet',
    emptySite: 'Every sites',
    tags: 'Tags',
    transpose: 'Transpose',
    shortHost: 'Short hostname',
    vpnIp: 'Ip VPN',
    vpnPort: 'SSH Port',
    rsshPort: 'RSSH Port',
    sshClientAlive: 'Keep alive ssh client',
    addresses: 'Ip Subnets',
    mtu: 'MTU',
    nameservers: 'DNS',
    from: 'From',
    to: 'To',
    via: 'Via',
    boxName: 'Box Name',
    boxSetupIp: 'Box local ip',
    date: 'Date',
    timeZone: 'Time Zone',
    dateFormat: 'Date format',
    NONE: 'None',
    AUTHORIZATION_HEADER: 'Authorization Header',
    BASIC: 'Basic',
    QUERY_PARAM: 'Query param',
    password: 'Mot de passe',
    username: "Nom d'utilisateur",
    key: 'Parameter name',
    result: 'Result',
    authorizationHeader: 'Authorization Header',
    cron: 'Cron expression (UTC)',
    method: 'HTTP Method',
    path: 'Path',
    schema: 'Schema',
    id: 'ID',
    instance: 'Instance',
    filterPastPoints: 'Filter past points',
    syncPoint: 'Sync Point',
    contentType: 'Content type',
    excel: 'Excel',
    boxDataMaxDelay: 'Expected frequency between two box runs',
    deviceHeartbeatFrequency: 'Expected frequency between two equipment runs',
    defaultVariableFrequency: 'Expected frequency between two points (by default)',
    defaultVariableDataDelay: 'Expected frequency for data feedback',
    mute: 'Muted',
    database: 'Database',
    queryStrategy: {
      BULK: 'Bulk',
      DEFAULT: 'Default',
      TEMPLATE: 'Template',
    },
    variable: 'Variable',
    variableKey: 'Key',
    logLevel: 'Log',
    host: 'Host',
    topic: 'Topic',
    propertyMatcherType: {
      [MQTTPropertyMatcherType.MAP_ON_TOPIC]: 'Topic',
      [MQTTPropertyMatcherType.MAP_ON_PAYLOAD]: 'Message',
    },
    count: 'Count',
  },
  button: {
    ok: 'Ok',
    cancel: 'Cancel',
    applyToAll: 'Apply to all',
    duplicate: 'Duplicate',
    recompute: 'Recompute',
    rename: 'Rename',
    create: 'Create',
    new: 'New',
    save: 'Save',
    edit: 'Edit',
    test: 'Test',
    variable: 'Variable',
    newDevice: 'Device',
    delete: 'Delete',
    disable: 'Disable',
    enable: 'Enable',
    yes: 'Yes',
    no: 'No',
    uploadFileLabel: 'Upload File',
    uploadFile: 'JSON / CSV',
    downloadFileTemplateLabel: 'Download {{type}} example',
    downloadFileTemplate: '{{type}} Template',
    selectAll: 'Select all',
    deselectAll: 'Unselect all',
    download: 'Download',
    add: 'Add',
    addVariable: 'Variable',
    addList: 'List',
    refresh: 'Refresh',
    next: 'Next',
    back: 'Back',
    confirm: 'Confirm',
    exportVariables: 'dametis_{{group}}_{{site}}_{{date}}',
    newBox: 'Box',
    scan: 'Scan',
    hideInactiveUsers: 'Hide inactive users',
    hideDameticianUsers: 'Hide Dametis users',
    hideHiddenUsers: 'Hide hidden users',
    clearAllSelection: 'Clear',
    import: 'Import',
    previous: 'Previous',
    downloadTemplateCsv: 'Download a .csv example',
    syncBox: 'Force Synchronization',
    addEndpoint: 'Add server',
    sourceType: {
      [FileDeviceSourceType.FILESYSTEM]: 'File (Legacy)',
      [FileDeviceSourceType.MINIO]: 'Amazon S3',
      [FileDeviceSourceType.SFTP]: 'SFTP',
      [FileDeviceSourceType.SFTP_INTERNAL]: 'Dametis SFTP',
    },
    startSession: 'Start session',
    createVariable_one: 'Create {{count}} variable',
    createVariable_other: 'Create {{count}} variables',
    connect: 'Connect',
    readBuffer: 'Read buffer',
    start: 'Start',
    checkPort: 'Check port',
  },
  label: {
    username: 'Username',
    password: 'Password',
    host: 'Host',
    port: 'Port',
    path: 'Path',
    existingFileImport: 'Existing configurations',
    source: 'Data source',
    useSSL: 'Secured connection',
    accessKey: 'Access Key ID',
    secretKey: 'Secret Access Key',
    bucket: 'Bucket',
    internalSftpCreds: 'Your Dametis SFTP credentials',
    ssl: 'Secure connection',
  },
  helper: {
    favorite: 'Check if this variable is important',
    accumulate: 'Check if this variable is a counter (each value is added to the previous one)',
    warning: 'Check if there is a setting problem',
    alarm: 'Check if a problem on this equipment should alert DAMETIS teams',
    hasWarning: 'Setting problem',
    editedVariable_one: '{{count}} unsaved change',
    editedVariable_other: '{{count}} unsaved changes',
    indicativeValues: 'Indicative values',
  },
  xgrid: {
    noRowsLabel: 'No variables',
    noRowsLabelImport: 'No data',
    noDevicesLabel: 'No devices',
    errorOverlayDefaultLabel: 'An error occurred.',
    toolbarDensity: 'Density',
    toolbarDensityLabel: 'Density',
    toolbarDensityCompact: 'Compact',
    toolbarDensityStandard: 'Standard',
    toolbarDensityComfortable: 'Comfortable',
    toolbarColumns: 'Columns',
    toolbarColumnsLabel: 'Show column selector',
    toolbarFilters: 'Filters',
    toolbarFiltersLabel: 'Show filters',
    toolbarFiltersTooltipHide: 'Hide filters',
    toolbarFiltersTooltipShow: 'Show filters',
    // toolbarFiltersTooltipActive: (count) => `${count} active filter(s)`,
    columnsPanelTextFieldLabel: 'Find column',
    columnsPanelTextFieldPlaceholder: 'Column title',
    columnsPanelDragIconLabel: 'Reorder column',
    columnsPanelShowAllButton: 'Show all',
    columnsPanelHideAllButton: 'Hide all',
    filterPanelAddFilter: 'Add filter',
    filterPanelDeleteIconLabel: 'Delete',
    filterPanelOperators: 'Operators',
    filterPanelOperatorAnd: 'And',
    filterPanelOperatorOr: 'Or',
    filterPanelColumns: 'Columns',
    columnMenuLabel: 'Menu',
    columnMenuShowColumns: 'Show columns',
    columnMenuFilter: 'Filter',
    columnMenuHideColumn: 'Hide',
    columnMenuUnsort: 'Unsort',
    columnMenuSortAsc: 'Sort by ascending',
    columnMenuSortDesc: 'Sort by descending',
    // columnHeaderFiltersTooltipActive: (count) => `${count} active filter(s)`,
    columnHeaderFiltersLabel: 'Show filters',
    columnHeaderSortIconLabel: 'Sort',
    // footerRowSelected: (count) =>
    //   count !== 1
    //     ? `${count.toLocaleString()} rows selected`
    //     : `${count.toLocaleString()} row selected`,
    footerTotalRows: 'Total rows:',
    footerPaginationRowsPerPage: 'Rows per page:',
    footerTotalRowsImport: 'Total rows :',
    footerTotalDevices: 'Total devices:',
    footerPaginationDevicesPerPage: 'Devices per page:',
    filterOperatorContains: 'contains',
    filterOperatorEquals: 'equals',
    filterOperatorStartsWith: 'starts with',
    filterOperatorEndsWith: 'ends with',
    filterOperatorIs: 'is',
    filterOperatorNot: 'is not',
    filterOperatorAfter: 'is after',
    filterOperatorOnOrAfter: 'is on or after',
    filterOperatorBefore: 'is before',
    filterOperatorOnOrBefore: 'is on or before',
    filterPanelInputLabel: 'Value',
    filterPanelInputPlaceholder: 'Filter value',
    toolbarExport: 'Export',
    toolbarExportLabel: 'Export',
    toolbarExportCSV: 'Download as CSV',
    toolbarExportExcel: 'Download as Excel',
    pinToLeft: 'Pin to left',
    pinToRight: 'Pin to right',
    unpin: 'Unpin',
  } as Partial<GridLocaleText>,
  graph: {
    internet: 'Internet',
    power: 'Power',
  },
  tooltip: {
    createBox: 'create a box',
    createDevice: 'create a device',
    exportVariables: 'export variables',
    editBoxAlertThresholds: 'Edit box alert thresholds',
    editDevice: 'Edit device',
    resetDevice: 'Change device',
    emptyVariableList: 'Empty variables list',
    editVariables: 'edit variables',
    addTemplateVariable: 'Add a template variable',
    editExp: 'edit old values',
    deleteList: 'Delete list',
    saveList: 'Save list',
    createList: 'Create list',
    seePassword: 'Show password',
    hidePassword: 'Hide password',
    copy: 'Copy',
    addEndpoint: 'Add URL',
    addGlobalvariable: 'Add global variable',
  },
  messageSecurityMode: {
    Invalid: 'Invalid',
    None: 'None',
    Sign: 'Sign',
    SignAndEncrypt: 'Sign and encrypt',
  },
  securityPolicy: {
    None: 'None',
    Invalid: 'Invalid',
  },
  userTokenType: {
    Anonymous: 'Anonymous',
    Certificate: 'Certificate',
    UserName: 'User',
  },
  toast: {
    successRecompute: 'Your request has been received, recalculation in progress...',
    copyToClipboard: "Variable's UUID copied to clipboard",
    valueCopyToClipboard: 'Value copied to clipboard',
  },
  boxItem: {
    boxVersion: 'Version',
    kernelVersion: 'Kernel',
    lanUsine: 'LAN USINE',
    lanOut: 'LAN OUT',
    ip: 'IP',
    gateway: 'Gateway',
    undefined: 'Undefined',
    mode: 'Mode',
  },
  grid: {
    interface: 'Interface',
    ip: 'IP',
    mask: 'Mask',
    gateway: 'Gateway',
    from: 'From',
    to: 'To',
    via: 'Via',
    priority: 'Priority (MTU)',
  },
  legend: {
    connected: 'Connected',
    partiallyDisconnected: 'Partially disconnected',
    disconnected: 'Disconnected',
  },
  table: {
    columns: {
      variable: 'Variable',
      date: 'Date',
    },
  },
  stepper: {
    importing: 'Importing',
    creating: 'Creating',
  },
  loader: {
    [CsvParserStep.PARSING]: 'Parsing file...',
    [CsvParserStep.BUILDING]: 'Creating variables...',
    [CsvParserStep.POSTING]: 'Adding to database...',
  },
};
