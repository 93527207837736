import { Divider, Stack } from '@mui/material';
import { FC, ReactNode, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import sleep from 'functions/sleep';
import useBidirectionalBlocks from 'hooks/useBidirectionalBlocks';
import { BidirectionalBlockInfo } from 'types/lego';

import BlockFloor from './BlockFloor';

const emptyArray: [] = [];

export interface BlockTreeProps {
  childId?: string | null;
  hideEmptyChildren?: boolean;
  onClick: (id: string) => void;
  selectedId?: string | null;
}

const BlockTree: FC<BlockTreeProps> = ({ childId = null, hideEmptyChildren = false, onClick, selectedId = null }) => {
  const { t } = useTranslation('lego');

  const { data: blocs = emptyArray } = useBidirectionalBlocks();

  const containerRef = useRef<HTMLDivElement>(null);

  const selectedBlock = useMemo(() => blocs?.find(block => block.uuid === selectedId), [blocs, selectedId]);

  const click = useCallback(
    async (id: string) => {
      onClick(id);
      if (containerRef.current) {
        await sleep(1);
        containerRef.current.scrollLeft = containerRef.current.scrollWidth;
      }
    },
    [onClick],
  );

  const blockFloors = useMemo(() => {
    const floors: ReactNode[] = [];
    let currentBlock: BidirectionalBlockInfo | undefined = selectedBlock;
    let currentSelectedId: string | null = null;
    while (currentBlock) {
      const children = currentBlock.children.filter(block => block.uuid !== childId);
      if (!hideEmptyChildren || children.length > 0) {
        floors.unshift(
          <BlockFloor
            key={currentBlock.uuid}
            childId={childId}
            emptyText={t('text.noBlock')}
            list={children}
            selectedId={currentSelectedId}
            onClick={click}
          />,
        );
      }
      currentSelectedId = currentBlock.uuid;
      currentBlock = currentBlock.parent;
    }
    floors.unshift(
      <BlockFloor
        childId={childId}
        emptyText={t('text.noBlock')}
        list={blocs.filter(block => block.parent === undefined && block.uuid !== childId)}
        selectedId={currentSelectedId}
        onClick={click}
      />,
    );
    return floors;
  }, [blocs, childId, click, hideEmptyChildren, selectedBlock, t]);

  return (
    <Stack
      ref={containerRef}
      direction="row"
      flexBasis={0}
      flexGrow={1}
      flexShrink={1}
      height="100%"
      spacing={1}
      sx={{ overflowX: 'auto' }}
      width="100%"
    >
      {blockFloors.map((floor, index) => (
        <>
          {floor}
          {index < blockFloors.length - 1 && <Divider flexItem orientation="vertical" />}
        </>
      ))}
    </Stack>
  );
};

export default BlockTree;
