import { GridView } from 'types/grid';

export default {
  title: {
    playgrounds: 'Playgrounds',
    myPlaygrounds: 'Mis playgrounds',
    sharedPlaygrounds: 'Compartido conmigo',
    privatePlaygrounds: 'Privado',
    newPlayground: 'Nueva playground',
    editPlayground: 'Editar el playground',
    duplicatePlayground: 'Duplicar el playground',
    createTemplate: 'Nuevo modelo de playground',
    editTemplate: 'Modificar el modelo de playground',
  },
  button: {
    newPlayground: 'Playground',
    cancel: 'Cancelar',
    create: 'Crear',
    save: 'Guardar',
    edit: 'Editar',
    delete: 'Suprimir',
    duplicate: 'Duplicar',
    share: 'Compartir',
    editTags: 'Editar las tags',
  },
  text: {
    tag: 'Tags',
    shortCreatedAt: 'Creado',
    shortUpdatedAt: 'Modificado',
    updatedAt: 'Modificado el {{date}}',
    me: 'yo',
    sharedBy: 'Compartido por {{name}}',
    ownedBy: 'Pertenece a {{name}}',
    noPlaygrounds: 'Todavía no tienes ninguna playground',
    copy: 'Copia',
    name: 'Nombre',
    owner: 'Propietario',
    tabs: 'Pestañas',
    public: 'Público',
    private: 'Privado',
    shared: 'Compartido',
    view: {
      [GridView.TILE]: 'Vista en mosaico',
      [GridView.LIST]: 'Vista en lista',
    },
    allPlaygrounds: 'Todos los playgrounds',
  },
  input: {
    label: {
      newPlayground: 'Nombre',
      type: 'Tipo',
      tags: 'Tags',
    },
  },
  form: {
    label: {
      confidentiality: 'Privacidad',
      applyToAllBlocks: 'Aplicar este modelo a...',
    },
    value: {
      confidentiality_private: 'Privado',
      confidentiality_shared: 'Compartido',
      applyToAllBlocks_one: 'Solo este bloque',
      applyToAllBlocks_all: 'Todos los bloques del mismo tipo',
    },
    tooltip: {
      owner_shared: 'Si esta opción está marcada, esta playground será visible para otros usuarios',
      owner_private: 'Si esta opción está marcada, esta playground será visible solo para ti',
      not_owner: 'No puedes cambiar la privacidad de esta playground porque no eres el propietario',
    },
  },
  tooltip: {
    createPlayground: 'Crear un playground',
    renamePlayground: 'Renombrar el playground',
    editTags: 'Modificar las tags del playground',
    duplicatePlayground: 'Modificar el playground',
    sharePlayground: 'Compartir el playground',
    deletePlayground: 'Suprimir el playground',
  },
  toast: {
    create: {
      success: 'El playground se creó correctamente.',
      failure: 'Se produjo un error al crear esta playground.',
    },
    save: {
      success: 'El playground se guardó correctamente.',
      failure: 'Se produjo un error al guardar esta playground.',
    },
    delete: {
      success: 'El playground se eliminó correctamente.',
      failure: 'Se produjo un error al eliminar esta playground.',
    },
    duplicate: {
      success: 'El playground se duplicó correctamente.',
      failure: 'Se produjo un error al duplicar esta playground.',
    },
  },
};
