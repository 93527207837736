import { CloseOutlined, UnfoldLess, UnfoldMore } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  IconButton,
  InputBase,
  InputLabel,
  Stack,
  StackProps,
  SvgIcon,
  Typography,
  inputBaseClasses,
} from '@mui/material';
import { ChangeEventHandler, FC, KeyboardEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-key-enum';

import { CreateTagBody, NestedTag, TagInfo, UUID } from '@dametis/core';

import { useCreateTagMutation } from 'store/api/tags';
import { HotKeysScope } from 'types/hotkeys';

import TagList from '../Entities/Entity/Tile/TagList';
import NestedTagTooltip from '../NestedTagTooltip/NestedTagTooltip';
import NestedTagChip from '../TagChip/NestedTagChip';
import SimpleTagChip from '../TagChip/SimpleTagChip';

import TagPickerInput, { TagPickerInputProps } from './TagPickerInput';
import TagPopover, { TagPopoverProps } from './TagPopover';

export const TAG_PICKER_ACTIONS_CLASSNAME = 'actions';
export const TAG_PICKER_HOTKEYS_OPTIONS = { scopes: HotKeysScope.TAG_PICKER, enableOnFormTags: true, preventDefault: true };

export const DISPLAYED_TAGS_LENGTH = 1;

export interface TagPickerProps extends Pick<TagPopoverProps, 'disabledTagIds' | 'onCreateTag' | 'tagTrees'> {
  value: NestedTag[];
  onChange: (newValue: NestedTag[]) => Promise<void> | void;
  label?: string;
  variant?: TagPickerInputProps['variant'];
  isEditing?: boolean;
  isDisabled?: boolean;
  // suggestions?: boolean;
  isExpanded?: boolean;
  isExpandable?: boolean;
  disabledCreation?: boolean;
  containerProps?: Omit<StackProps, 'children'>;
}

const TagPicker: FC<TagPickerProps> = ({
  value: selectedTags,
  onChange,
  label = undefined,
  variant = 'standard',
  isEditing = true,
  isDisabled = false,
  // suggestions = false,
  isExpanded: userIsExpanded = false,
  isExpandable = false,
  disabledCreation = false,
  onCreateTag: onUserCreateTag = undefined,
  containerProps = {},
  ...props
}) => {
  const { t } = useTranslation('tags');

  const [createTag] = useCreateTagMutation();

  // const { suggestions } = useTagSuggestion(selectedTags, true, 1);

  const [searchFilterValue, setSearchFilterValue] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isCreatingTag, setIsCreatingTag] = useState<boolean>(false);

  const anchorEl = useRef<HTMLDivElement | null>(null);
  const tagListEl = useRef<HTMLDivElement | null>(null);

  const displayedSelectedTags = useMemo(
    () => (isExpandable && isExpanded ? selectedTags : selectedTags.slice(0, DISPLAYED_TAGS_LENGTH)),
    [selectedTags, isExpandable, isExpanded],
  );

  const hiddenSelectedTags = useMemo(
    () => (isExpandable && isExpanded ? [] : selectedTags.slice(DISPLAYED_TAGS_LENGTH)),
    [selectedTags, isExpandable, isExpanded],
  );

  // const selectedTagIds = useMemo(() => selectedTags.map(selectedTag => selectedTag.uuid), [selectedTags]);

  // const filteredSuggestions = useMemo(
  //   () => suggestions.filter(suggestion => !selectedTagIds.includes(suggestion.uuid)),
  //   [selectedTagIds, suggestions],
  // );

  const handleToggleIsExpanded = useCallback(() => {
    setIsExpanded(state => !state);
  }, []);

  const handleSelectTag = useCallback(
    async (newTag: NestedTag) => {
      const foundedTag = selectedTags.find(selectedTag => selectedTag.uuid === newTag.uuid);
      if (!foundedTag) {
        await onChange([...selectedTags, newTag]);
        setIsOpen(false);
        setSearchFilterValue('');
        if (tagListEl.current) {
          tagListEl.current.scrollTo({ behavior: 'smooth', left: tagListEl.current.clientWidth });
        }
      } else {
        await onChange(selectedTags.filter(selectedTag => selectedTag.uuid !== newTag.uuid));
        setIsOpen(false);
        setSearchFilterValue('');
      }
    },
    [selectedTags, onChange],
  );

  // const handleSelectSuggestion = useCallback(
  //   (suggestion: NestedTag) => async () => {
  //     await onChange([...selectedTags, suggestion]);
  //   },
  //   [selectedTags, onChange],
  // );

  const handleChangeSearchFilterValue: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    setSearchFilterValue(event.target.value);
    setIsOpen(true);
  }, []);

  const handleClickAway = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleFocusInput = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (anchorEl.current && event.key === Key.Escape) {
        event.currentTarget.blur();
      }
      if (anchorEl.current && event.key === Key.Enter) {
        setIsOpen(true);
      }
      if (searchFilterValue.length === 0 && event.key === Key.Backspace && selectedTags.length > 0) {
        void onChange(selectedTags.slice(0, -1));
      }
    },
    [searchFilterValue, selectedTags, onChange],
  );

  const handleClearSelectedTags = useCallback(async () => {
    await onChange([]);
  }, [onChange]);

  const handleDeleteSelectedTag = useCallback(
    (tagId: UUID) => async () => {
      await onChange(selectedTags.filter(tag => tag.uuid !== tagId));
    },
    [selectedTags, onChange],
  );

  const onLocalCreateTag = useCallback(
    async (newTag: NestedTag): Promise<TagInfo | null> => {
      try {
        const body: CreateTagBody = {
          name: newTag.name.trim(),
          parentId: newTag.parentId,
        };
        const { data } = await createTag(body);
        return data ?? null;
      } catch (err) {
        console.error(err);
        return null;
      }
    },
    [createTag],
  );

  const parsedOnCreateTag = useCallback(
    async (newTag: NestedTag): Promise<NestedTag | null> => {
      let createdTag = null;
      if (!disabledCreation) {
        setIsCreatingTag(true);
        if (onUserCreateTag) {
          createdTag = await onUserCreateTag(newTag);
        } else {
          const createdTagInfo = await onLocalCreateTag(newTag);
          createdTag = createdTagInfo ? { ...createdTagInfo, usages: newTag.usages, children: [], parent: newTag.parent } : null;
        }
        setIsCreatingTag(false);
      }
      return createdTag;
    },
    [disabledCreation, onUserCreateTag, onLocalCreateTag],
  );

  useEffect(() => {
    setIsExpanded(userIsExpanded);
  }, [userIsExpanded]);

  return (
    <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleClickAway}>
      <Stack gap={0.5} {...containerProps}>
        {!!label && variant !== 'filter' && <InputLabel>{label}</InputLabel>}
        <TagPickerInput
          ref={anchorEl}
          alignItems="center"
          direction="row"
          flexWrap={isExpandable && isExpanded ? 'wrap' : 'nowrap'}
          gap={0.5}
          isEditing={isEditing}
          variant={variant}
        >
          {!!label && variant === 'filter' && (
            <Typography mx={1} sx={{ opacity: isDisabled ? 0.3 : 1 }} variant="h6">
              {label}
            </Typography>
          )}

          {/* SLE: When expandable, use an other component because must not be wrapped */}
          {isExpandable && (
            <>
              {displayedSelectedTags.map(selectedTag => (
                <NestedTagChip
                  key={selectedTag.uuid}
                  displayPath
                  pathPosition="row"
                  sx={{ fontSize: 12 }}
                  tag={selectedTag}
                  onDelete={isEditing ? handleDeleteSelectedTag(selectedTag.uuid) : undefined}
                />
              ))}
              {!isExpanded && hiddenSelectedTags.length > 0 && (
                <NestedTagTooltip PopperProps={{ placement: 'top' }} tags={hiddenSelectedTags}>
                  <SimpleTagChip name={`+${hiddenSelectedTags.length}`} size="small" />
                </NestedTagTooltip>
              )}
            </>
          )}

          {/* SLE: not expandable */}
          {!isExpandable && (
            <>
              {isEditing && (
                <Stack ref={tagListEl} direction="row" gap={0.5} overflow="auto" sx={{ scrollbarWidth: 'thin' }}>
                  {selectedTags.map(selectedTag => (
                    <NestedTagChip
                      key={selectedTag.uuid}
                      displayPath
                      pathPosition="row"
                      sx={{ fontSize: 12 }}
                      tag={selectedTag}
                      onDelete={isEditing ? handleDeleteSelectedTag(selectedTag.uuid) : undefined}
                    />
                  ))}
                </Stack>
              )}
              {!isEditing && selectedTags.length > 0 && <TagList tags={selectedTags} />}
            </>
          )}

          {isEditing && (
            <Box flexBasis={100} flexGrow={1} minWidth={100}>
              <InputBase
                fullWidth
                autoComplete="off"
                disabled={isCreatingTag || isDisabled}
                endAdornment={
                  isCreatingTag ? (
                    <Stack alignItems="center" justifyContent="center">
                      <CircularProgress color="secondary" size={15} thickness={4.5} />
                    </Stack>
                  ) : undefined
                }
                placeholder={selectedTags.length === 0 ? t('placeholder.noSelectedTag') : undefined}
                size="small"
                sx={{
                  ml: variant === 'filter' && !label ? 1 : 0,
                  mr: variant === 'filter' ? 1 : 0,
                  [`& .${inputBaseClasses.input}`]: { padding: 0, height: 22 },
                }}
                value={searchFilterValue}
                onChange={handleChangeSearchFilterValue}
                onFocus={handleFocusInput}
                onKeyDown={handleKeyDown}
              />
            </Box>
          )}
          {!isEditing && displayedSelectedTags.length === 0 && (
            <Typography variant="subtitle2">{t('placeholder.noSelectedTag')}</Typography>
          )}
          <Stack
            alignItems="center"
            className={TAG_PICKER_ACTIONS_CLASSNAME}
            direction="row"
            height={1}
            position="absolute"
            right={0}
            top={0}
          >
            {isEditing && selectedTags.length > 0 && (
              <IconButton size="small" onClick={handleClearSelectedTags}>
                <CloseOutlined fontSize="small" />
              </IconButton>
            )}
            {isExpandable && selectedTags.length > 1 && (
              <IconButton size="small" onClick={handleToggleIsExpanded}>
                <SvgIcon component={isExpanded ? UnfoldLess : UnfoldMore} fontSize="small" />
              </IconButton>
            )}
          </Stack>
        </TagPickerInput>
        <TagPopover
          // disablePortal
          {...props}
          anchorEl={anchorEl.current}
          isOpen={isOpen}
          searchFilterValue={searchFilterValue}
          selectedTags={selectedTags}
          setIsOpen={setIsOpen}
          sx={{ zIndex: theme => theme.zIndex.modal + 1 }}
          onCreateTag={disabledCreation ? undefined : parsedOnCreateTag}
          onSelectTag={handleSelectTag}
        />
      </Stack>
    </ClickAwayListener>
  );
};

export default TagPicker;
