import { Alert, AlertProps as MuiAlertProps, PopperProps as MuiPopperProps, Tooltip, TooltipProps } from '@mui/material';

const AlertPropsDefaultProp: MuiAlertProps = {};

const PopperPropsDefaultProp: Partial<MuiPopperProps> = {};

interface Props extends TooltipProps {
  AlertProps?: MuiAlertProps;
  PopperProps?: Partial<MuiPopperProps>;
  noMaxWidth?: boolean;
  open?: boolean;
}

const AlertTooltip = ({
  title,
  children,
  AlertProps = AlertPropsDefaultProp,
  PopperProps = PopperPropsDefaultProp,
  noMaxWidth = false,
  open = undefined,
  ...props
}: Props) => (
  <Tooltip
    disableInteractive={false}
    {...(open !== undefined ? { open } : {})}
    slotProps={{
      tooltip: { sx: { backgroundColor: 'transparent', p: 0, maxWidth: noMaxWidth ? 'none' : undefined } },
      popper: { ...PopperProps },
    }}
    title={
      <Alert elevation={8} {...AlertProps}>
        {title}
      </Alert>
    }
    {...props}
  >
    {children}
  </Tooltip>
);

export default AlertTooltip;
