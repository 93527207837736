import { ReactNode } from 'react';

import { PermissionKey } from '@dametis/core';

import lazyWithRetry from 'functions/lazyWithRetry';

const Home = lazyWithRetry(() => import('../components/Home/Home'));
const Alarms = lazyWithRetry(() => import('../components/Alarms/Alarms'));
const BlockDiagram = lazyWithRetry(() => import('../components/Lego/BlockDiagram/BlockDiagram'));
const ActiveAlarms = lazyWithRetry(() => import('../components/Alarms/ActiveAlarms/ActiveAlarms'));
const AlarmsHistory = lazyWithRetry(() => import('../components/Alarms/AlarmsHistory/AlarmsHistory'));
const AlarmsSettings = lazyWithRetry(() => import('../components/Alarms/AlarmsSettings/AlarmsSettings'));
const Alarm = lazyWithRetry(() => import('../components/Alarms/Alarm/Alarm'));
const AdminMonitoring = lazyWithRetry(() => import('components/Admin/Monitoring/Monitoring'));
const Batches = lazyWithRetry(() => import('../components/Batches/Batches'));
const Batch = lazyWithRetry(() => import('../components/Batches/Batch/Batch'));
const Model = lazyWithRetry(() => import('../components/Model/Model'));
const Models = lazyWithRetry(() => import('../components/Models/Models'));
const Playgrounds = lazyWithRetry(() => import('../components/Playgrounds/Playgrounds'));
const Playground = lazyWithRetry(() => import('../components/Playground/Playground'));
const Report = lazyWithRetry(() => import('../components/Report/Report'));
const Synoptics = lazyWithRetry(() => import('../components/Synoptics/Synoptics'));
const Synoptic = lazyWithRetry(() => import('../components/Synoptic/Synoptic'));
const Reports = lazyWithRetry(() => import('../components/Reports/Reports'));
const ManualEntry = lazyWithRetry(() => import('../components/Data/ManualEntry/ManualEntry'));
const AdvancedEntry = lazyWithRetry(() => import('../components/Data/AdvancedEntry/AdvancedEntry'));
const Export = lazyWithRetry(() => import('../components/Data/Export/Export'));
// const ManageUserLogin = lazyWithRetry(() => import('../components/OldLogin/ManageUserLogin'));
const Monitoring = lazyWithRetry(() => import('../components/Monitoring/Monitoring'));
const MonitoringVariables = lazyWithRetry(() => import('../components/Monitoring/MonitoringVariables/MonitoringVariables'));
const Devices = lazyWithRetry(() => import('../components/Configuration/Devices/Devices'));
const GroupBoxes = lazyWithRetry(() => import('../components/Configuration/GroupBoxes/GroupBoxes'));
const Group = lazyWithRetry(() => import('../components/Configuration/Group/Group'));
const Variables = lazyWithRetry(() => import('../components/Configuration/Variables/Variables'));
const Configuration = lazyWithRetry(() => import('../components/Configuration/Configuration'));
const FileImportHome = lazyWithRetry(() => import('../components/Data/FileImport/Home/Home'));
const FileImportFileConfig = lazyWithRetry(() => import('../components/Data/FileImport/FileConfig/FileConfig'));
const ProjectsHome = lazyWithRetry(() => import('../components/Projects/ProjectsHome'));
const Projects = lazyWithRetry(() => import('../components/Projects/Projects'));
const Project = lazyWithRetry(() => import('../components/Project/Project'));
const ProjectsTemplates = lazyWithRetry(() => import('../components/ProjectsTemplate/ProjectsTemplates'));
const ProjectsTasks = lazyWithRetry(() => import('../components/ProjectTasks/ProjectTasks'));

// const Lego = lazyWithRetry(() => import('../components/Lego/Lego'));
const LegoCatalog = lazyWithRetry(() => import('../components/Lego/LegoCatalog/LegoCatalog'));
const Energies = lazyWithRetry(() => import('../components/Energies/Energies'));
const Account = lazyWithRetry(() => import('../components/Account/Index'));
const Site = lazyWithRetry(() => import('../components/Configuration/Site/Site'));
const Boxes = lazyWithRetry(() => import('../components/Admin/Boxes/Boxes/Boxes'));
const Box = lazyWithRetry(() => import('../components/Admin/Boxes/Box/Box'));
const Backups = lazyWithRetry(() => import('../components/Admin/Backup/Backups'));
const Billing = lazyWithRetry(() => import('../components/Billing/Billing'));
const Bills = lazyWithRetry(() => import('../components/Billing/Bills/Bills'));
const Bill = lazyWithRetry(() => import('../components/Billing/Bills/BillForm/Bill'));
const Contracts = lazyWithRetry(() => import('../components/Billing/Contracts/Contracts'));
const Contract = lazyWithRetry(() => import('../components/Billing/Contracts/NewContractHome'));
const Debuggus = lazyWithRetry(() => import('../components/Admin/Debuggus/Debuggus'));
const Exploration = lazyWithRetry(() => import('../components/Admin/Exploration/Exploration'));
const DataHistoryAdmin = lazyWithRetry(() => import('../components/Admin/DataHistoryAdmin/DataHistoryAdmin'));
const DataHistory = lazyWithRetry(() => import('../components/Data/DataHistory/DataHistory'));
const Data = lazyWithRetry(() => import('../components/Data/Data'));
const Misc = lazyWithRetry(() => import('../components/Admin/Misc/Misc'));
const Maintenance = lazyWithRetry(() => import('../components/Admin/Maintenance/Maintenance'));
const Tags = lazyWithRetry(() => import('../components/Configuration/Tags/Tags'));
const TestAdvancedNumberTextField = lazyWithRetry(() => import('../components/Configuration/TestAdvancedNumberTextField'));
const TestUnitPicker = lazyWithRetry(() => import('../components/UI/UnitPicker/TestUnitPicker'));
const TestVnc = lazyWithRetry(() => import('../components/VNC/Test'));
const TestDaChart = lazyWithRetry(() => import('../components/DaChart/TestDaChart'));
const TestBatch = lazyWithRetry(() => import('../components/UI/BatchFilter/TestBatch'));
const TestSlate = lazyWithRetry(() => import('../components/TestSlate/TestSlate'));
const Activities = lazyWithRetry(() => import('../components/Admin/Activities/Activities'));
const Roles = lazyWithRetry(() => import('../components/Admin/Roles/Roles'));
const Users = lazyWithRetry(() => import('../components/Admin/Users/Users/Users'));
const User = lazyWithRetry(() => import('../components/Admin/Users/User/User'));
const Groups = lazyWithRetry(() => import('../components/Admin/Groups/Groups/Groups'));
const GroupAdmin = lazyWithRetry(() => import('../components/Admin/Groups/Group/Group'));
const Sites = lazyWithRetry(() => import('../components/Admin/Sites/Sites/Sites'));
const SiteAdmin = lazyWithRetry(() => import('../components/Admin/Sites/Site/Site'));
const AdminVariables = lazyWithRetry(() => import('../components/Admin/Variables/Variables'));
const Authentications = lazyWithRetry(() => import('../components/Admin/Authentications/Authentications/Authentications'));
const AuthenticationAdmin = lazyWithRetry(() => import('../components/Admin/Authentications/Authentication/Authentication'));
const SteamMixes = lazyWithRetry(() => import('../components/SteamMixes/SteamMixes'));
const SteamMix = lazyWithRetry(() => import('../components/SteamMixes/SteamMix/SteamMix'));
const LoginRouter = lazyWithRetry(() => import('../components/Login/LoginComponents/LoginLayout'));
const Error403And404 = lazyWithRetry(() => import('../components/Errors/Error403And404'));

export interface RouteConfig {
  name: string;
  path: string;
  component: ReactNode;
  permission: PermissionKey | null;
  auth: boolean | null; // null = doesn't matter, both ; false = only logged out ; true = only logged in
  corporate: boolean | null; // null = doesn't matter, both ; false = not in corpo ; true = only in corpo
}

const route = (
  name: string,
  path: string,
  component: ReactNode,
  permission: PermissionKey | null,
  auth: boolean | null,
  corporate: boolean | null,
): RouteConfig => ({
  name,
  path,
  component,
  permission,
  auth,
  corporate,
});

export default [
  // route('routes:login', '/login/*', null, true, <ManageUserLogin />, false, '/'),

  route('routes:login', '/login/*', <LoginRouter />, null, false, null),

  route('routes:home', '/', <Home />, null, true, null),

  route('routes:alarms', '/alarms', <Alarms />, 'canAccessAlarm', true, false),
  route('routes:activeAlarms', '/alarms/active', <ActiveAlarms />, 'canAccessAlarm', true, false),
  route('routes:alarmsHistory', '/alarms/history', <AlarmsHistory />, 'canAccessAlarm', true, false),
  route('routes:alarmsSettings', '/alarms/settings', <AlarmsSettings />, 'canAccessAlarm', true, false),
  route('routes:alarm', '/alarms/:alarmId', <Alarm />, 'canAccessAlarm', true, false),

  route('routes:playgrounds', '/playgrounds', <Playgrounds />, 'canAccessPlayground', true, null),
  route('routes:playground', '/playgrounds/:uuid', <Playground />, 'canAccessPlayground', true, null),

  route('routes:reports', '/reports', <Reports />, 'canAccessReport', true, null),
  route('routes:report', '/reports/:uuid', <Report />, 'canAccessReport', true, null),

  route('routes:synoptics', '/synoptics', <Synoptics />, 'canAccessSynoptic', true, false),
  route('routes:synoptic', '/synoptics/:uuid', <Synoptic />, 'canAccessSynoptic', true, false),

  route('routes:configuration', '/configuration', <Configuration />, 'canAccessConfiguration', true, null),

  route('routes:variables', '/configuration/variables', <Variables />, 'canAccessConfiguration', true, null),
  route('routes:devices', '/configuration/devices', <Devices />, 'canAccessConfiguration', true, false),
  route('routes:devices', '/configuration/devices/:deviceId', <Devices />, 'canAccessConfiguration', true, false),
  route('routes:groupBoxes', '/configuration/boxes', <GroupBoxes />, 'canAccessConfiguration', true, true),

  route('routes:fileImport', '/data', <Data />, 'canAccessConfiguration', true, false),
  route('routes:fileImport', '/data/import', <FileImportHome />, 'canAccessConfiguration', true, false),
  route('routes:fileImport', '/data/import/:uuid', <FileImportFileConfig />, 'canAccessConfiguration', true, false),
  route('routes:dataExport', '/data/export', <Export />, 'canAccessConfiguration', true, false),
  route('routes:advancedEntry', '/data/correction', <AdvancedEntry />, 'canAccessAdvancedEntry', true, false),
  route('routes:manualEntry', '/data/manualEntry', <ManualEntry />, 'canEditConfiguration', true, false),
  route('routes:dataHistory', '/data/history', <DataHistory />, 'canAccessDataHistory', true, false),

  route('routes:site', '/site/*', <Site />, 'canAccessConfiguration', true, false),
  route('routes:group', '/group/*', <Group />, 'canAccessConfiguration', true, true),

  route('routes:batches', '/configuration/batches', <Batches />, 'canAccessBatch', true, false),
  route('routes:batch', '/configuration/batches/:batchId', <Batch />, 'canAccessBatch', true, false),

  route('routes:tags', '/configuration/tags', <Tags />, 'canAccessTag', true, null),

  route('routes:models', '/models', <Models />, 'canAccessModel', true, false),
  route('routes:models', '/models/:uuid', <Model />, 'canAccessModel', true, false),

  route('routes:projects', '/projects', <ProjectsHome />, 'canAccessProject', true, null),
  route('routes:projects', '/projects/list', <Projects />, 'canAccessProject', true, null),
  route('routes:project', '/projects/:uuid', <Project />, 'canAccessProject', true, null),
  route('routes:project', '/projects/:uuid/task/:taskUuid', <Project />, 'canAccessProject', true, null),
  route('routes:projects', '/projects/template', <ProjectsTemplates />, 'canEditProjectTemplate', true, false),
  route('routes:projects', '/projects/template/:templateId', <ProjectsTemplates />, 'canEditProjectTemplate', true, false),
  route('routes:projects', '/projects/tasks', <ProjectsTasks />, 'canAccessProject', true, null),

  route('routes:blockTypes', '/blockTypes', <LegoCatalog />, 'canAccessLego', true, false),
  route('routes:blockTypes', '/blockTypes/:folderId', <LegoCatalog />, 'canAccessLego', true, false),
  route('routes:blockTypes', '/blockTypes/:folderId/:entityId', <LegoCatalog />, 'canAccessLego', true, false),
  route('routes:lego', '/lego', <BlockDiagram />, 'canAccessLego', true, false),
  route('routes:lego', '/lego/:blockId', <BlockDiagram />, 'canAccessLego', true, false),

  route('routes:energies', '/energies', <Energies />, 'canAccessEnergy', true, false),
  route('routes:energies', '/energies/:reportId', <Energies />, 'canAccessEnergy', true, false),

  route('routes:monitoring', '/monitoring', <Monitoring />, 'canAccessDamco', true, false),
  route('routes:monitoring', '/monitoring/:deviceId', <MonitoringVariables />, 'canAccessDamco', true, false),
  route('routes:monitoring', '/monitoring/box/:boxId', <Monitoring />, 'canAccessDamco', true, false),

  route('routes:account', '/account', <Account />, null, true, null),

  route('routes:billing', '/billing', <Billing />, 'canAccessBilling', true, false),
  route('routes:bills', '/billing/bills', <Bills />, 'canAccessBilling', true, false),
  route('routes:bill', '/billing/bills/:billId', <Bill />, 'canAccessBilling', true, false),
  route('routes:contracts', '/billing/contracts', <Contracts />, 'canAccessBilling', true, false),
  route('routes:contract', '/billing/contracts/:contractId', <Contract />, 'canAccessBilling', true, false),

  route('routes:steamMixes', '/steamMixes', <SteamMixes />, 'canAccessOpco', true, false),
  route('routes:steamMix', '/steamMixes/:steamMixId', <SteamMix />, 'canAccessOpco', true, false),

  // Admin

  route('routes:admin.groups', '/admin/groups', <Groups />, 'canAccessBo', true, null),
  route('routes:admin.groups', '/admin/groups/:groupId', <GroupAdmin />, 'canAccessBo', true, null),
  route('routes:admin.sites', '/admin/sites', <Sites />, 'canAccessBo', true, null),
  route('routes:admin.sites', '/admin/sites/:siteId', <SiteAdmin />, 'canAccessBo', true, null),
  route('routes:admin.users', '/admin/users', <Users />, 'canAccessBo', true, null),
  route('routes:admin.users', '/admin/users/:userId', <User />, 'canAccessBo', true, null),
  route('routes:admin.roles', '/admin/roles', <Roles />, 'canAccessBo', true, false),
  route('routes:admin.boxes', '/admin/boxes', <Boxes />, 'canAccessBo', true, false),
  route('routes:admin.boxes', '/admin/boxes/:boxId', <Box />, 'canAccessBo', true, false),
  route('routes:admin.variables', '/admin/variables', <AdminVariables />, 'canAccessConfiguration', true, false),
  route('routes:admin.activities', '/admin/activities', <Activities />, 'canAccessActivity', true, false),
  route('routes:admin.debuggus', '/admin/debuggus', <Debuggus />, 'canAccessConfiguration', true, false),
  route('routes:admin.exploration', '/admin/exploration', <Exploration />, 'canAccessWIP', true, false),
  route('routes:admin.authentications', '/admin/authentications', <Authentications />, 'canAccessBo', true, false),
  route('routes:admin.authentications', '/admin/authentications/:authenticationId', <AuthenticationAdmin />, 'canAccessBo', true, false),
  route('routes:admin.dataHistory', '/admin/dataHistory', <DataHistoryAdmin />, 'canAccessBo', true, false),
  route('routes:monitoring', '/admin/monitoring', <AdminMonitoring />, 'canAccessBo', true, false),
  route('routes:admin.misc', '/admin/misc', <Misc />, 'canAccessBo', true, false),
  route('routes:admin.maintenance', '/admin/maintenance', <Maintenance />, 'canAccessBo', true, false),
  route('routes:admin.backup', '/admin/backup', <Backups />, 'canAccessBo', true, false),

  route('', '/testvnc', <TestVnc />, 'canAccessWIP', true, null),
  route('', '/testunitpicker', <TestUnitPicker />, 'canAccessWIP', true, null),
  route('', '/testdachart', <TestDaChart />, 'canAccessWIP', true, null),
  route('', '/testbatch', <TestBatch />, 'canAccessWIP', true, null),
  route('', '/testslate', <TestSlate />, 'canAccessWIP', true, null),
  route('', '/testadvancednumbertextfield', <TestAdvancedNumberTextField />, 'canAccessWIP', true, null),

  route('routes:404', '*', <Error403And404 />, null, true, null),
];
