import i18next from 'i18next';

import { Ecma376Format } from '@dametis/core';
import { MultipleName, MultipleSymbol, UnitConverter } from '@dametis/unit';

import { formatValueFromFormat } from 'components/UI/FormatPicker/functions/formatValueFromFormat';

import { isExistingUnit } from './isExistingUnit';

export const getFormattedValueUncomposed = ({
  value,
  userUnit = null,
  baseUnit = null,
}: {
  value: number;
  userUnit?: string | null;
  baseUnit?: string | null;
}): { value: number; unit: UnitConverter; multiple?: MultipleSymbol | MultipleName } | undefined => {
  const parsedBaseUnit = UnitConverter.Parse(baseUnit?.trim() !== undefined ? baseUnit.trim() : null);
  const parsedUserUnit = UnitConverter.Parse(
    userUnit?.trim() !== undefined ? userUnit.trim() : null,
    !isExistingUnit(parsedBaseUnit.unitName) ? parsedBaseUnit.raw : null,
  );
  try {
    // Si l'unité de base existe ou est vide, on prend celle-ci pour convertir
    if (
      parsedBaseUnit.raw === '' ||
      isExistingUnit(parsedBaseUnit.unitName) ||
      (parsedUserUnit.raw === null && !isExistingUnit(parsedUserUnit.unitName))
    ) {
      const convertedValue = parsedBaseUnit.ConvertValueToUnit(value, parsedUserUnit);
      return { value: convertedValue ?? value, unit: parsedUserUnit.raw !== null ? parsedUserUnit : parsedBaseUnit };
    }
    // si notre unité de base n'existe pas, on prend l'unité définie par l'utilisateur si possible ainsi que son multiple si elle est identique a l'unité de base
    const parsedUnit = parsedUserUnit.raw === undefined ? parsedBaseUnit : parsedUserUnit;
    const multiple = parsedUserUnit.unitName === parsedBaseUnit.unitName ? parsedUnit.multiple.symbol : undefined;
    const convertedValue = parsedBaseUnit.ConvertValueToMultipleSymbol(value, multiple ?? MultipleSymbol['']);
    return { value: convertedValue, unit: parsedUnit, multiple };
  } catch {
    // Si la conversion échoue, on prend simplement l'unité définie par l'utilisateur si possible
    const parsedUnit = parsedUserUnit.raw === null ? parsedBaseUnit : parsedUserUnit;
    return { value, unit: parsedUnit };
  }
};

export const getFormattedValue = ({
  value,
  userUnit = null,
  baseUnit = null,
  format,
  maximumFractionDigits,
}: {
  value: number | null | undefined;
  userUnit?: string | null;
  baseUnit?: string | null;
  format?: Partial<Ecma376Format> | null;
  maximumFractionDigits?: number;
}): string => {
  if (value === null || value === undefined || !Number.isFinite(value)) {
    return '-';
  }
  const formattedValueUncomposed = getFormattedValueUncomposed({ value, userUnit, baseUnit });
  if (!formattedValueUncomposed) return '';
  const { value: parsedValue, unit: parsedUnit, multiple } = formattedValueUncomposed;
  if (format) {
    if (!format.hideUnit && parsedUnit.raw) {
      return `${formatValueFromFormat(parsedValue, format)} ${parsedUnit.raw}`;
    }
    return formatValueFromFormat(parsedValue, format);
  }

  return parsedUnit.Format({
    value: parsedValue,
    ...(multiple ? { multiple } : { rawTargetUnit: parsedUnit.raw ?? undefined }),
    locale: i18next.language,
    maximumFractionDigits,
  });
};
