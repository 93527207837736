import { TFunction } from 'i18next';

import { ProtocolKind } from '@dametis/core';

import logoBacnet from 'assets/images/protocols/logo_bacnet.jpg';
import logoCSV from 'assets/images/protocols/logo_csv.png';
import logoCustom from 'assets/images/protocols/logo_custom.jpg';
import logoEthernetIp from 'assets/images/protocols/logo_ethernetip.jpg';
import logoHttp from 'assets/images/protocols/logo_http.jpg';
import logoModbusTcp from 'assets/images/protocols/logo_modbustcp.jpg';
import logoMQTT from 'assets/images/protocols/logo_mqtt.png';
import logoOpcUa from 'assets/images/protocols/logo_opcua.jpg';
import logoS7 from 'assets/images/protocols/logo_s7.jpg';
import logoSQL from 'assets/images/protocols/logo_sql.jpg';

export interface ProtocolConfig {
  name: string;
  preview: string;
  enabled: boolean;
}

const getProtocols = (t: TFunction): Record<ProtocolKind, ProtocolConfig> => ({
  [ProtocolKind.MODBUS_TCP]: {
    name: t('global:protocol.modbusTcp'),
    preview: logoModbusTcp,
    enabled: true,
  },
  [ProtocolKind.OPC_UA]: {
    name: t('global:protocol.opcUa'),
    preview: logoOpcUa,
    enabled: true,
  },
  [ProtocolKind.SQL]: {
    name: t('global:protocol.sql'),
    preview: logoSQL,
    enabled: false,
  },
  [ProtocolKind.FILE]: {
    name: t('global:protocol.file'),
    preview: logoCSV,
    enabled: true,
  },
  [ProtocolKind.S7]: {
    name: t('global:protocol.s7'),
    preview: logoS7,
    enabled: true,
  },
  [ProtocolKind.HTTP]: {
    name: t('global:protocol.http'),
    preview: logoHttp,
    enabled: true,
  },
  [ProtocolKind.BACNET]: {
    name: t('global:protocol.bacnet'),
    preview: logoBacnet,
    enabled: true,
  },
  [ProtocolKind.MQTT]: {
    name: t('global:protocol.mqtt'),
    preview: logoMQTT,
    enabled: true,
  },
  [ProtocolKind.ETHERNET_IP]: {
    name: t('global:protocol.ethernetIp'),
    preview: logoEthernetIp,
    enabled: true,
  },
  [ProtocolKind.CUSTOM]: {
    name: t('global:protocol.custom'),
    preview: logoCustom,
    enabled: false,
  },
});

export default getProtocols;
