import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { RegressionMetrics } from '@dametis/core';

import { getColorFromCvrmse, getColorFromR2, getColorFromSmape, setColorLightness } from 'functions/color';

import { getFormattedValue } from '../../UI/UnitPicker/functions/getFormattedValue';

export const useStats = (r2: RegressionMetrics['r2'], smape: RegressionMetrics['smape'], cvrmse: RegressionMetrics['cvrmse']) => {
  const { t } = useTranslation('global');

  const formattedR2 = useMemo(() => (r2 === null ? t('text.noValue') : getFormattedValue({ value: r2, baseUnit: '' })), [r2, t]);
  const formattedSmape = useMemo(
    () => (smape === null ? t('text.noValue') : getFormattedValue({ value: smape * 100, baseUnit: '%' })),
    [smape, t],
  );
  const formattedCvrmse = useMemo(() => (cvrmse === null ? t('text.noValue') : getFormattedValue({ value: cvrmse })), [cvrmse, t]);

  const r2Color = useMemo(() => (r2 === null ? undefined : setColorLightness(getColorFromR2(r2), 35)), [r2]);
  const smapeColor = useMemo(() => (smape === null ? undefined : setColorLightness(getColorFromSmape(smape * 100), 35)), [smape]);
  const cvrmseColor = useMemo(() => (cvrmse === null ? undefined : setColorLightness(getColorFromCvrmse(cvrmse), 35)), [cvrmse]);

  return useMemo(
    () => ({
      formattedR2,
      formattedSmape,
      formattedCvrmse,
      r2Color,
      smapeColor,
      cvrmseColor,
    }),
    [formattedR2, formattedSmape, formattedCvrmse, r2Color, smapeColor, cvrmseColor],
  );
};
