import { CreateCommonDeviceBody, DEFAULT_SYNC_DELAY, ProtocolKind, SyncPoint } from '@dametis/core';

export const createCreateCommonDeviceBody = ({
  name = '',
  enabled = true,
  protocol = null as unknown as ProtocolKind,
  alarm = false,
  brandDeviceId = undefined,
  variables = [],
  // SLE: resolve this
  filterPastPoints = false,
  syncPoint = SyncPoint.LAST,
  syncDelay = DEFAULT_SYNC_DELAY,
  logLevel = null,
}: Partial<CreateCommonDeviceBody> = {}): CreateCommonDeviceBody => ({
  name,
  enabled,
  protocol,
  alarm,
  brandDeviceId,
  variables,
  filterPastPoints,
  syncPoint,
  syncDelay,
  logLevel,
});
