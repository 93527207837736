import { Box, Stack, Typography } from '@mui/material';
import { FC, forwardRef, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Virtuoso } from 'react-virtuoso';

import noFileImg from 'assets/images/others/no-file.svg';
import BigAddButton from 'components/UI/BigAddButton/BigAddButton';
import { usePermission } from 'hooks/usePermission';
import { useTagEditStore } from 'zustand/stores/tagEdit';

import { ShortNestedTag } from '../helpers/shortNestedTag';

import DragImage from './DragImage';
import CreateTagItem from './TagsListItem/CreateTagItem';
import ItemContainer from './TagsListItem/ItemContainer';

export const MAX_TAG_DEPTH = 3;
export const MAX_TAG_NAME_LENGTH = 24;
export const SUGGESTED_TAG_NAME_LENGTH = 16;

const ListComponent = forwardRef<HTMLDivElement>((props, ref) => <Stack ref={ref} {...props} gap={1} />);

ListComponent.displayName = 'ListComponent';

const TagsList: FC = () => {
  const { t } = useTranslation('tags');

  const canEditTag = usePermission('canEditTag');

  const trees = useTagEditStore(state => state.editor.trees);
  const isEditing = useTagEditStore(state => state.isEditing);
  const setIsEditing = useTagEditStore(state => state.setIsEditing);
  const getItemContent = useCallback(
    (index: number, tree: ShortNestedTag) => <ItemContainer key={tree.uuid} depth={1} index={index} shortTag={tree} />,
    [],
  );

  const handleToggleEdit = useCallback(() => {
    setIsEditing(true);
  }, [setIsEditing]);

  return (
    <>
      {trees.length === 0 && !isEditing && (
        <Stack alignItems="center" justifyContent="center" my={2} spacing={1} width={1}>
          <Box component="img" height={150} m={2} src={noFileImg} width={1} />
          {!canEditTag ? (
            <Typography color="textSecondary" variant="h5">
              {t('text.noTags')}
            </Typography>
          ) : (
            <BigAddButton label={t('button.noTags')} onClick={handleToggleEdit} />
          )}
        </Stack>
      )}
      {isEditing && (
        <Box>
          <CreateTagItem autoFocus index={0} marginBottom={1} parentId={null} />
        </Box>
      )}
      <Virtuoso<ShortNestedTag>
        useWindowScroll
        components={{
          List: ListComponent,
        }}
        data={trees}
        itemContent={getItemContent}
      />
      {isEditing && trees.length > 0 && (
        <Box>
          <CreateTagItem index={trees.length} marginTop={1} parentId={null} />
        </Box>
      )}
      <DragImage />
    </>
  );
};

export default memo(TagsList);
