import { AddCircle, Cancel, SvgIconComponent } from '@mui/icons-material';
import { Box, Stack, StackProps, SvgIcon, Typography, alpha, styled, useTheme } from '@mui/material';
import { forwardRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { setColorLightness, setColorSaturation } from 'functions/color';

type TagChipContainerProps = StackProps;

const TagChipContainer = styled(Stack)<TagChipContainerProps>(() => ({
  display: 'inline-flex',
  flexDirection: 'row',
  alignItems: 'center',
  verticalAlign: 'bottom',
  borderRadius: '1.25em',
  whiteSpace: 'nowrap',
  borderWidth: 1,
  borderStyle: 'dashed',
  // paddingLeft: '0.25em',
  // paddingRight: '0.25em',
  paddingTop: '0.25em',
  paddingBottom: '0.25em',
}));

export enum TagChipVariant {
  DEFAULT = 'default',
  SUGGESTION = 'suggestion',
}

export enum TagChipPathPosition {
  ROW = 'row',
  COLUMN = 'column',
}

export interface AdvancedTagChipProps extends TagChipContainerProps {
  name: string;
  pathPosition?: `${TagChipPathPosition}`;
  path?: string;
  icon?: SvgIconComponent;
  color?: string;
  variant?: `${TagChipVariant}`;
  onAdd?: () => Promise<void> | void;
  onDelete?: () => Promise<void> | void;
  isInline?: boolean;
}

const AdvancedTagChip = forwardRef<HTMLDivElement, AdvancedTagChipProps>(
  (
    {
      name,
      path = undefined,
      pathPosition = 'row',
      icon = undefined,
      variant = 'default',
      color = undefined,
      onAdd = undefined,
      onDelete = undefined,
      isInline = false,
      ...props
    },
    ref,
  ) => {
    const { t } = useTranslation('tags');
    const theme = useTheme();

    const iconColor = useMemo(
      () => (color === undefined ? alpha(theme.palette.black, 0.28) : setColorLightness(setColorSaturation(color, 70), 75)),
      [color, theme.palette.black],
    );

    const backgroundColor = useMemo(
      () =>
        color === undefined
          ? alpha(theme.palette.black, 0.08)
          : alpha(setColorLightness(setColorSaturation(color, 70), variant === TagChipVariant.SUGGESTION ? 96 : 90), 0.8),
      [color, theme.palette.black, variant],
    );

    const borderColor = useMemo(() => {
      if (variant !== TagChipVariant.SUGGESTION) {
        return 'transparent';
      }
      return color === undefined ? alpha(theme.palette.black, 0.18) : setColorLightness(setColorSaturation(color, 70), 93);
    }, [color, variant, theme.palette.black]);

    const handleAdd = useCallback(() => {
      if (onAdd) {
        onAdd();
      }
    }, [onAdd]);

    const handleDelete = useCallback(() => {
      if (onDelete) {
        onDelete();
      }
    }, [onDelete]);

    return (
      <TagChipContainer
        ref={ref}
        bgcolor={backgroundColor}
        borderColor={borderColor}
        color="text.primary"
        my={isInline ? '-0.25em' : undefined}
        {...props}
      >
        {icon && <SvgIcon component={icon} fontSize="inherit" sx={{ ml: '0.25em', mr: '-0.35em', color: iconColor }} />}
        <Stack alignItems={pathPosition === TagChipPathPosition.ROW ? 'center' : undefined} direction={pathPosition} mx="0.5em">
          {path && pathPosition === TagChipPathPosition.COLUMN && (
            <Typography fontSize="0.7em" lineHeight={1} variant="caption">
              {path}
            </Typography>
          )}
          {path && pathPosition === TagChipPathPosition.ROW && (
            <>
              <Box display="inline" sx={{ opacity: 0.5 }}>
                {`${path} ${t('text.tagSeparator')} `}
              </Box>
              &nbsp;
            </>
          )}
          <Box display="inline">{name}</Box>
        </Stack>
        {!!onAdd && <AddCircle sx={{ color: iconColor, fontSize: '1.3em', my: '-100%', mr: '0.25em' }} onClick={handleAdd} />}
        {!!onDelete && <Cancel sx={{ color: iconColor, fontSize: '1.3em', my: '-100%', mr: '0.25em' }} onClick={handleDelete} />}
      </TagChipContainer>
    );
  },
);

AdvancedTagChip.displayName = 'TagChip';

AdvancedTagChip.defaultProps = {
  path: undefined,
  pathPosition: 'row',
  icon: undefined,
  variant: 'default',
  color: undefined,
  onAdd: undefined,
  onDelete: undefined,
  isInline: false,
};

export default AdvancedTagChip;
