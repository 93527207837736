import type { ParseError as PapaParseError } from 'papaparse';
import type { ErrorCode as DropzoneErrorCode } from 'react-dropzone';

import { CSVDelimiter, ColumnType, FileImportStatus, FileParseErrorCodes, ReferencedBy, VirtualColumnElementType } from '@dametis/core';

import { MappingView } from 'components/Data/FileImport/Home/CreateFileImportModal/MappingSettings/MappingSettings';
import { SourceValue } from 'components/Data/FileImport/UI/SourceValuePicker/SourceValuePicker';

export default {
  title: {
    fileImport: 'Import de données',
    createFileConfigModal: 'Nouvelle configuration',
    createFileImportModal: 'Télécharger des documents',
    settings: 'Paramètres',
    virtualColumns: 'Colonnes virtuelles',
    createVirtualColumnModal: 'Nouvelle colonne virtuelle',
    dateTime: 'Date & heure',
    reference: 'Références des variables',
    values: 'Valeurs',
    value: 'Valeur',
    preview: 'Prévisualisation',
    configurations: 'Configurations',
    files: 'Documents',
    selectedFiles: 'Documents sélectionnés',
    mapping: 'Correspondance des colonnes',
    skippedColumns: 'Colonnes ignorées',
    saveFileImportModal: 'Que voulez-vous faire ?',
    uploadsPanel: 'Téléchargements',
    fixedVariables: 'Variables permanentes',
    informations: 'Informations',
    errors: 'Erreurs',
    promptBeforeCanceling: 'Êtes-vous sûr ?',
  },
  button: {
    createFileConfig: 'Configuration',
    close: 'Fermer',
    next: 'Suivant',
    previous: 'Précédent',
    add: 'Ajouter',
    create: 'Créer',
    refresh: 'Rafraîchir',
    refreshLong: 'Rafraîchir la prévisualisation',
    upload: 'Télécharger',
    see: 'Voir',
    importData: 'Importer les données',
    save: 'Enregistrer',
    uploads: 'Téléchargements',
    cancel: 'Annuler',
    validate: 'Valider',
    closeCanceling: 'Annuler',
    confirmCanceling: 'Fermer sans sauvegarder',
  },
  label: {
    referencedBy: 'Comment sont référencées les variables de vos documents ?',
    typicalFile: 'Fichier type',
    delimiter: 'Délimiteur',
    jq: 'Transformateur JQ',
    chooseFileConfig: 'Quelle configuration pour importer vos documents ?',
    name: 'Nom',
    description: 'Description',
    elements: 'Éléments',
    dateTimeSource: 'Source',
    dateTimeFormat: 'Format de la source',
    dateTimeZone: 'Fuseau horaire de la source',
    referenceSource: 'Source',
    valuesSource: 'Source',
    valueSource: 'Source',
    skippedColumnWithIndex: 'Colonne ignorée {{index}}',
    startLine: 'Début du document',
    stopLine: 'Fin du document',
    associatedFiles_zero: 'Aucun fichier associé',
    associatedFiles_one: '{{count}} fichier associé',
    associatedFiles_other: '{{count}} fichiers associés',
    [`useHeader_${ReferencedBy.COLUMNS}`]: 'Les documents utilisent un entête contenant les références des variables',
    [`useHeader_${ReferencedBy.ROWS}`]: 'Les documents utilisent un entête',
    ignoreEmptyLines: 'Ignorer les lignes vides',
    chooseExistingFileImport: 'Choisir une configuration existante',
    existingFileImport: 'Configurations existantes',
    uploadsInProgress_zero: 'Aucun téléchargement en cours',
    uploadsInProgress_one: 'Un téléchargement en cours',
    uploadsInProgress_other: '{{count}} téléchargements en cours',
    doImportData: 'Importer les données de ces documents',
    doSaveFileImport: "Sauvegarder cette configuration d'import",
    createdAt: 'Création',
    updatedAt: 'Mise à jour',
    size: 'Taille',
    owner: 'Propriétaire',
    charset: 'Charset',
    globalErrors_zero: 'Aucune erreur globale',
    globalErrors_one: '{{count}} erreur globale',
    globalErrors_other: '{{count}} erreurs globales',
    rowWithNumber_zero: 'Ligne {{number}} (aucune erreur)',
    rowWithNumber_one: 'Ligne {{number}} ({{count}} erreur)',
    rowWithNumber_other: 'Ligne {{number}} ({{count}} erreurs)',
    variable: 'Variable',
  },
  referencedBy: {
    [ReferencedBy.COLUMNS]: 'Par colonnes',
    [ReferencedBy.ROWS]: 'Par lignes',
  },
  helper: {
    referencedBy: {
      [ReferencedBy.COLUMNS]: 'Les variables sont référencées dans les en-têtes des colonnes valeurs de vos documents.',
      [ReferencedBy.ROWS]: 'Les variables sont référencées dans chaque ligne de valeur de vos documents.',
    },
    dateTimeFormatDescription: 'Retrouvez la documentation du format ',
    dateTimeFormatRedirection: 'ici.',
    chooseConfigurationBeforeFiles: 'Vous devez sélectionner une configuration avant de choisir les documents à envoyer.',
    createConfigurationBeforeFiles: "Vous devez créer une première configuration d'import pour commencer à envoyer vos documents.",
    typicalFile:
      "Choisissez un fichier type de cette configuration. Celui-ci servira de base pour paramétrer l'analyse des futurs documents importés.",
    virtualColumns:
      'Les colonnes virtuelles vous permettent de simuler des colonnes dans vos documents à partir de son contenu. Exemple : la colonne virtuelle « date et heure » est la concaténation des colonnes existantes « date » et « heure ».',
    fixedVariables:
      "Associer de façon permanente une variable à une colonne permet l'utilisation de document sans en-tête ou avec une référence de variable erronée.",
  },
  csvDelimiter: {
    [CSVDelimiter.AUTO]: 'Auto',
    [CSVDelimiter.COMMA]: '« , »',
    [CSVDelimiter.SEMICOLON]: '« ; »',
    [CSVDelimiter.SPACE]: 'Espace',
    [CSVDelimiter.TAB]: 'Tabulation',
  },
  tooltip: {
    seeMore: 'Voir {{count}} autres configurations',
    createFileConfig: 'Nouvelle configuration',
    deleteConfig: 'Supprimer la configuration',
    cannotDeleteConfig: 'Vous ne pouvez pas supprimer cette configuration car elle est utilisée par un équipement',
    seeFiles: 'Voir les documents',
    correspondingVariable: 'Variable correspondante : {{variableName}}',
    notCorrespondingVariable: 'La référence « {{reference}} » ne semble correspondre à aucune variable du site',
    duplicatedReference: 'La référence "{{reference}}" est dupliquée, ce qui peut entraîner un import erroné',
    view: {
      [MappingView.COLUMNS]: 'Paramètre par colonnes',
      [MappingView.SOURCES]: 'Paramètre par sources',
    },
    jqDesc: 'JQ est un langage qui permet de manipuler de façon avancée les données de votre fichier.',
    docLink: 'Documentation',
  },
  virtualColumnElementType: {
    [VirtualColumnElementType.COLUMN]: 'Colonne',
    [VirtualColumnElementType.TEXT]: 'Texte',
    [VirtualColumnElementType.SEPARATOR]: 'Séparateur',
  },
  columnType: {
    [ColumnType.REAL_BY_INDEX]: 'Colonnes existantes (par numéro)',
    [ColumnType.REAL_BY_HEADER]: 'Colonnes existantes (par nom)',
    [ColumnType.VIRTUAL]: 'Colonnes virtuelles',
  },
  importStatusDescription: {
    [FileImportStatus.IMPORTED]: 'Données du document importées avec succès',
    [FileImportStatus.READY]: 'Les données de ce document ne semblent pas avoir été importées',
    [FileImportStatus.FAILED]: "L'import des données a échoué, une erreur est survenue",
    [FileImportStatus.IN_PROGRESS]: 'Import des données du document en cours',
    missingFile: "Ce document n'est plus disponible",
    unknownStatus: 'Statut inconnu',
  },
  column: {
    notSelected: 'Non sélectionnée',
    skipped: 'Ignorée',
    valueSourceDefault: 'Valeur (par défaut)',
    skippedSourceDefault: 'Ignorée (par défaut)',
    dateTimeSource: 'Date & heure',
    referenceSource: 'Références des variables',
    valuesSource: 'Valeurs',
    valueSource: 'Valeur',
    valuesSourceWithIndex: 'Valeurs {{index}}',
  },
  stepper: {
    csvConfig: 'Documents',
    mapping: 'Valeurs',
  },
  text: {
    noVirtualColumn: 'Aucune colonne virtuelle.',
    noVirtualColumnElement: 'Aucun élément',
    separator: '« {{value}} »',
    realByIndexColumn: 'Colonne nº{{index}}',
    realByHeaderColumn: 'Colonne « {{name}} »',
    virtualColumn: 'Colonne « {{name}} »',
    separatorMustFollowColumnOrText: 'Un séparateur doit suivre un élément Colonne ou Texte',
    virtualColumnNameIsAlreayUsed: 'Une autre colonne virtuelle porte ce nom',
    noDateTimeZoneFound: 'Aucun fuseau horaire correspondant',
    noDateTimeFormatFound: 'Aucun format correspondant',
    useOption: 'Utiliser « {{option}} »',
    lineNumber: 'ligne nº',
    lineNumberWithValue: 'ligne nº{{value}}',
    noPreview: 'Aucune prévisualisation',
    noConfiguration: 'Aucune configuration',
    globalParseErrors: "Erreurs d'analyse",
    documentContainsErrors: 'Ce document contient des erreurs',
    alreadyUploadedFile: 'Fichier déjà en cours de traitement : {{fileName}}',
    noFile: 'Aucun document',
    noSkippedColumn: 'Aucune colonne ignorée',
    importFromNewFileImport: "Télécharger des documents à partir d'une nouvelle configuration",
    unknownSize: 'Taille inconnue',
    noUpload: 'Aucun téléchargement',
    noFixedVariable: 'Aucune variable permanente',
    loadingForms: 'Chargement des colonnes...',
    dateTimeFormatExample: 'Ex: {{example}}',
    unknownParseError: 'Erreur inconnue',
    promptBeforeCanceling: "Vous n'avez pas sauvegardé cette configuration.",
    unknownUser: 'Utilisateur inconnu',
    configUsed: "Cette configuration est utilisée par l'équipement ",
    emptyJq: 'Aucun',
  },
  dropzone: {
    dropFiles: 'Déposez un ou plusieurs documents à télécharger',
    or: 'ou',
    browse: 'Parcourir',
    acceptedFiles: 'Fichiers supportés :',
    maxSize: 'Taille maximale : {{size}}Mo',
    dropFilesHere: 'Déposez vos documents ici',
    fileNotAllowed: "Ce type de fichier n'est pas autorisé",
  },
  toast: {
    uploadInProgress_one: 'Téléchargement du document en cours.',
    uploadInProgress_other: 'Téléchargement des documents en cours.',
    uploadInProgressWithRedirect_one:
      'Téléchargement du document en cours, vous pouvez consulter son avancée sur la page de la configuration associée.',
    uploadInProgressWithRedirect_other:
      'Téléchargement des documents en cours, vous pouvez consulter leur avancée sur la page de la configuration associée.',
    fileImportSaved: 'Configuration sauvegardée',
    deleteFileImportSuccess: 'Configuration supprimée avec succès.',
  },
  sourceValue: {
    [`${SourceValue.DEFAULT}_useHeader_${ReferencedBy.COLUMNS}`]: 'Valeur (depuis référence de variable)', // default referenced by rows useHeader
    [`${SourceValue.DEFAULT}_${ReferencedBy.COLUMNS}`]: 'Ignorée (défaut)', // default referenced by rows not useHeader
    [`${SourceValue.DEFAULT}_useHeader_${ReferencedBy.ROWS}`]: 'Ignorée (défaut)', // default referenced by rows useHeader
    [`${SourceValue.DEFAULT}_${ReferencedBy.ROWS}`]: 'Ignorée (défaut)', // default referenced by rows notUseHeader
    [SourceValue.DATE_TIME]: 'Date & heure',
    [SourceValue.REFERENCE]: 'Référence',
    [SourceValue.VALUE]: 'Valeur',
    [SourceValue.SKIPPED_COLUMN]: 'Colonne ignorée',
    [SourceValue.FIXED_VARIABLE]: 'Valeur (depuis sélection de variable)',
  },
  errors: {
    jqError: 'Votre code JQ contient des erreurs.',
    fileParse: {
      [FileParseErrorCodes.InvalidDate]: 'La date ou son format est invalide',
      [FileParseErrorCodes.InvalidValue]: 'La valeur est invalide',
      [FileParseErrorCodes.InvalidReference]: 'La référence est invalide',
      [FileParseErrorCodes.MissingColumn]: 'Colonne manquante',
      [FileParseErrorCodes.MissingReference]: 'Référence manquante',
    } as Record<FileParseErrorCodes, string>,
    papaParse: {
      MissingQuotes: 'Guillemets manquantes',
      UndetectableDelimiter: 'Impossible de détecter le délimiteur',
      TooFewFields: 'Trop peu de champs',
      TooManyFields: 'Trop de champs',
      InvalidQuotes: 'Guillemets invalides',
    } as Record<PapaParseError['code'], string>,
    upload: {
      'file-too-large': 'Le fichier dépasse la taille maximale autorisée',
      'file-too-small': 'Le fichier est trop petit',
      'file-invalid-type': "Ce type de fichier n'est pas autorisé",
      'too-many-files': 'Trop de fichiers selectionnés',
    } as Record<DropzoneErrorCode, string>,
  },
};
