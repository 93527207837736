import {
  AccountTreeOutlined,
  DashboardOutlined,
  ScatterPlotOutlined,
  SvgIconComponent,
  TroubleshootOutlined,
  WorkOutline,
} from '@mui/icons-material';
import { Stack, SvgIcon, Typography } from '@mui/material';
import { FC } from 'react';

import { TagUsagesKey } from '@dametis/core';

import { BlocksOutlined } from 'assets/icons/BlocksOutlined';
import { PlaygroundOutlined } from 'assets/icons/PlaygroundOutlined';
import { SteamMixOutlined } from 'assets/icons/SteamMixOutlined';

const TAG_USAGE_ICON: Record<TagUsagesKey, SvgIconComponent> = {
  playgrounds: PlaygroundOutlined,
  reports: DashboardOutlined,
  synoptics: AccountTreeOutlined,
  projects: WorkOutline,
  blocks: BlocksOutlined,
  variables: ScatterPlotOutlined,
  opcos: SteamMixOutlined,
  models: TroubleshootOutlined,
};

export interface ItemUsageLabelProps {
  usageKey: TagUsagesKey;
  count: number;
}

const ItemUsageLabel: FC<ItemUsageLabelProps> = ({ usageKey, count }) =>
  count > 0 ? (
    <Stack alignItems="center" direction="row" spacing={0.5}>
      <SvgIcon component={TAG_USAGE_ICON[usageKey]} fontSize="small" sx={{ width: '18px', height: '18px' }} />
      <Typography color="inherit" variant="caption">
        {count}
      </Typography>
    </Stack>
  ) : null;

export default ItemUsageLabel;
