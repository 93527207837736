export default {
  text: {
    createdBy: 'Created by {{name}}',
    createdAt: 'Created {{date}}',
    updatedAt: 'Updated {{date}}',
    me: 'me',
    notFound: 'Element not found',
  },
  action: {
    addToFavorite: 'Add to favorite',
    removeFromFavorite: 'Remove from favorite',
    openInNew: 'Open in new tab',
    rename: 'Rename',
    renameAndTag: 'Rename and tag',
    edit: 'Edit',
    duplicate: 'Duplicate',
    share: 'Share',
    delete: 'Delete',
  },
  tooltip: {
    notFound: 'The element you are looking for does not exist, or you are not allowed to access it',
  },
};
