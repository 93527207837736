import { Box, styled } from '@mui/material';
import { DragEventHandler, FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UUID } from '@dametis/core';

import { useDispatch } from 'store';
import { addToast } from 'store/slices/toast';
import { ToastSeverity } from 'types';
import { useTagEditStore } from 'zustand/stores/tagEdit';

import { PAPER_HEIGHT } from './ItemPaper';

const Container = styled(Box, {
  shouldForwardProp: propName => propName !== 'isDragging',
})<{ isDragging: boolean }>(({ isDragging }) => ({
  position: 'absolute',
  height: PAPER_HEIGHT + 8,
  bottom: 0,
  left: 0,
  width: '100%',
  zIndex: 1,
  pointerEvents: !isDragging ? 'none' : undefined,
  // border: '1px solid red',
}));

const Placeholder = styled(Box)(({ theme }) => ({
  height: 4,
  // marginTop: `${PAPER_HEIGHT / 2 + 2}px`,
  borderRadius: theme.shape.borderRadius,
  background: theme.palette.secondary.light,
}));

export interface ItemDropzoneProps {
  isExpanded: boolean;
  parentId: UUID | null;
  tagId: UUID | null;
  index: number;
}

const ItemDropzone: FC<ItemDropzoneProps> = ({ isExpanded, parentId, tagId, index }) => {
  const { t } = useTranslation('tags');
  const dispatch = useDispatch();

  const moveEditorTags = useTagEditStore(state => state.moveEditorTags);
  const addEditorOperation = useTagEditStore(state => state.addEditorOperation);
  const setDraggedTagIds = useTagEditStore(state => state.setDraggedTagIds);
  const setDisabledDropIds = useTagEditStore(state => state.setDisabledDropIds);
  const toggleExpandedItem = useTagEditStore(state => state.toggleExpandedItem);
  const draggedTagIds = useTagEditStore(state => state.draggedTagIds);

  const [isDraggingOver, setIsDraggingOver] = useState<boolean>(false);

  const isDragging = useMemo(() => draggedTagIds.length > 0, [draggedTagIds]);

  const forceOpenDebounce = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleDragOver: DragEventHandler<HTMLDivElement> = useCallback(event => {
    event.preventDefault();
    setIsDraggingOver(true);
  }, []);

  const handleDragLeave: DragEventHandler<HTMLDivElement> = useCallback(event => {
    event.preventDefault();
    setIsDraggingOver(false);
  }, []);

  const handleDrop: DragEventHandler<HTMLDivElement> = useCallback(
    event => {
      event.preventDefault();
      if (draggedTagIds.length === 0) {
        return;
      }
      setIsDraggingOver(false);
      try {
        const data = {
          tagIds: draggedTagIds,
          targetTagId: parentId,
          index,
        };
        moveEditorTags(data);
        addEditorOperation({
          kind: 'move',
          data,
        });
      } catch (error) {
        console.error(error);
        dispatch(addToast({ message: t('error.moveTags', { count: 1 }), severity: ToastSeverity.ERROR }));
      } finally {
        setDraggedTagIds([]);
        setDisabledDropIds([]);
      }
    },
    [draggedTagIds, moveEditorTags, dispatch, setDraggedTagIds, t, parentId, setDisabledDropIds, index, addEditorOperation],
  );

  useEffect(() => {
    if (forceOpenDebounce.current) {
      clearTimeout(forceOpenDebounce.current);
    }
    if (isDraggingOver && !isExpanded && tagId) {
      forceOpenDebounce.current = setTimeout(() => {
        toggleExpandedItem(tagId);
      }, 700);
    }
  }, [isDraggingOver, isExpanded, toggleExpandedItem, tagId]);

  return (
    <Container isDragging={isDragging} onDragLeave={handleDragLeave} onDragOver={handleDragOver} onDrop={handleDrop}>
      {isDraggingOver && <Placeholder />}
    </Container>
  );
};

export default memo(ItemDropzone);
