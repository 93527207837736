import { SvgIconComponent } from '@mui/icons-material';
import { Box, Chip, ChipProps, Stack, SvgIcon, Typography, alpha, useTheme } from '@mui/material';
import { forwardRef, memo, useMemo } from 'react';

import { setColorLightness, setColorSaturation } from 'functions/color';

import { TagChipPathPosition, TagChipVariant } from './AdvancedTagChip';
import ChipPath from './ChipPath';

export interface SimpleTagChipProps extends Omit<ChipProps, 'color' | 'variant' | 'icon'> {
  name: string;
  pathPosition?: `${TagChipPathPosition}`;
  path?: string[];
  icon?: SvgIconComponent;
  color?: string;
  variant?: `${TagChipVariant}`;
}

const SimpleTagChip = forwardRef<HTMLDivElement, SimpleTagChipProps>(
  ({ name, path = undefined, icon = undefined, pathPosition = 'row', variant = 'default', color = undefined, sx = {}, ...props }, ref) => {
    const theme = useTheme();

    const iconColor = useMemo(
      () => (color === undefined ? alpha(theme.palette.black, 0.28) : setColorLightness(setColorSaturation(color, 70), 75)),
      [color, theme.palette.black],
    );

    const backgroundColor = useMemo(
      () =>
        color === undefined
          ? alpha(theme.palette.black, 0.08)
          : alpha(setColorLightness(setColorSaturation(color, 70), variant === TagChipVariant.SUGGESTION ? 96 : 90), 0.8),
      [color, theme.palette.black, variant],
    );

    const borderColor = useMemo(() => {
      if (variant !== TagChipVariant.SUGGESTION) {
        return 'transparent';
      }
      return color === undefined ? alpha(theme.palette.black, 0.18) : setColorLightness(setColorSaturation(color, 70), 93);
    }, [color, variant, theme.palette.black]);

    return (
      <Chip
        ref={ref}
        icon={icon ? <SvgIcon component={icon} sx={{ color: `${iconColor}!important` }} /> : undefined}
        label={
          <Stack alignItems={pathPosition === TagChipPathPosition.ROW ? 'center' : undefined} direction={pathPosition}>
            {path && pathPosition === TagChipPathPosition.COLUMN && (
              <Typography fontSize="0.8em" variant="caption">
                <ChipPath path={path} />
              </Typography>
            )}
            {path && pathPosition === TagChipPathPosition.ROW && <ChipPath displayEndArrow path={path} />}
            <Box display="inline">{name}</Box>
          </Stack>
        }
        sx={{
          backgroundColor,
          borderColor,
          borderWidth: 1,
          borderStyle: 'dashed',
          height: pathPosition === 'column' ? 40 : undefined,
          ...sx,
        }}
        {...props}
      />
    );
  },
);

SimpleTagChip.displayName = 'TagChip';

SimpleTagChip.defaultProps = {
  path: undefined,
  icon: undefined,
  pathPosition: 'row',
  variant: 'default',
  color: undefined,
};

export default memo(SimpleTagChip);
