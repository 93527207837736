import { TroubleshootOutlined } from '@mui/icons-material';
import { Avatar, List, ListItem, ListSubheader, Typography } from '@mui/material';
import { cloneDeep } from 'lodash-es';
import { FC, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CalculationVariable,
  ExplanatoryVariable,
  ModelInfo,
  ModelInfoToCalculationVariable,
  ModelKeyField,
  ModelVariable,
} from '@dametis/core';

import { useStats } from 'components/Model/Results/useStats';
import { getFormattedValue } from 'components/UI/UnitPicker/functions/getFormattedValue';
import { PropsContext } from 'components/VNC/context';
import { useVncStore } from 'zustand/stores/vnc';

import ModelVariableAvatar from '../../../../Model/ModelVariableAvatar';
import CalculationSlate from '../../../../UI/CalculationSlate/CalculationSlate';
import { useInsertCalculation, useInsertVariable } from '../../../hooks';

import ModelColumnXVar from './ModelColumnXVar';
import ModelItem from './ModelItem';

interface Props {
  selectedModel: ModelInfo;
}

const ModelColumn: FC<Props> = ({ selectedModel }) => {
  const { t } = useTranslation('vnc');

  const { defaultVariableOperator, disableMaths, calculatedVariableMode } = useContext(PropsContext);

  const insertVariable = useInsertVariable();
  const insertCalculation = useInsertCalculation();
  const setCalcVarProp = useVncStore(state => state.setCalcVarProp);

  const modelVariable = useMemo<ModelVariable>(
    () => ({
      modelUuid: selectedModel.uuid,
      modelKey: ModelKeyField.MODEL,
    }),
    [selectedModel.uuid],
  );
  const modelCalculation = useMemo<CalculationVariable>(() => {
    return ModelInfoToCalculationVariable(cloneDeep(selectedModel));
  }, [selectedModel]);
  const yVar = useMemo<ModelVariable>(
    () => ({
      modelUuid: selectedModel.uuid,
      modelKey: ModelKeyField.Y_VAR,
    }),
    [selectedModel.uuid],
  );
  // const absoluteGap = useMemo<ModelVariable>(
  //   () => ({
  //     modelUuid: selectedModel.uuid,
  //     modelKey: ModelKeyField.ABSOLUTE_GAP,
  //   }),
  //   [selectedModel.uuid],
  // );
  // const relativeGap = useMemo<ModelVariable>(
  //   () => ({
  //     modelUuid: selectedModel.uuid,
  //     modelKey: ModelKeyField.RELATIVE_GAP,
  //   }),
  //   [selectedModel.uuid],
  // );
  const uncertainty = useMemo<ModelVariable>(
    () => ({
      modelUuid: selectedModel.uuid,
      modelKey: ModelKeyField.UNCERTAINTY,
    }),
    [selectedModel.uuid],
  );
  const r2 = useMemo<ModelVariable>(
    () => ({
      modelUuid: selectedModel.uuid,
      modelKey: ModelKeyField.R2,
    }),
    [selectedModel.uuid],
  );
  const smape = useMemo<ModelVariable>(
    () => ({
      modelUuid: selectedModel.uuid,
      modelKey: ModelKeyField.SMAPE,
    }),
    [selectedModel.uuid],
  );
  const cvrmse = useMemo<ModelVariable>(
    () => ({
      modelUuid: selectedModel.uuid,
      modelKey: ModelKeyField.CVRMSE,
    }),
    [selectedModel.uuid],
  );

  const { formattedR2, formattedSmape, formattedCvrmse } = useStats(
    selectedModel.metrics.r2,
    selectedModel.metrics.smape,
    selectedModel.metrics.cvrmse,
  );

  const formattedUncertainty = useMemo(
    () => getFormattedValue({ value: selectedModel.uncertainty, baseUnit: '%' }),
    [selectedModel.uncertainty],
  );

  const handleSelectModelVariable = useCallback(() => {
    insertVariable(modelVariable, disableMaths, defaultVariableOperator ?? undefined, calculatedVariableMode);
  }, [insertVariable, modelVariable, disableMaths, defaultVariableOperator, calculatedVariableMode]);

  const handleSelectYVar = useCallback(() => {
    insertVariable(yVar, disableMaths, defaultVariableOperator ?? undefined, calculatedVariableMode);
  }, [insertVariable, yVar, disableMaths, defaultVariableOperator, calculatedVariableMode]);

  const handleSelectModelCalculation = useCallback(() => {
    setCalcVarProp({
      nickname: t('models:text.modelVariableWithPath.modelCalculation', { model: selectedModel.name }),
    });
    insertCalculation(modelCalculation);
  }, [setCalcVarProp, selectedModel.name, t, insertCalculation, modelCalculation]);

  // const handleSelectAbsoluteGap = useCallback(() => {
  //   insertVariable(absoluteGap, disableMaths, defaultVariableOperator ?? undefined, calculatedVariableMode);
  // }, [insertVariable, absoluteGap, disableMaths, defaultVariableOperator, calculatedVariableMode]);

  // const handleSelectRelativeGap = useCallback(() => {
  //   insertVariable(relativeGap, disableMaths, defaultVariableOperator ?? undefined, calculatedVariableMode);
  // }, [insertVariable, relativeGap, disableMaths, defaultVariableOperator, calculatedVariableMode]);

  const handleSelectUncertainty = useCallback(() => {
    insertVariable(uncertainty, disableMaths, defaultVariableOperator ?? undefined, calculatedVariableMode);
  }, [insertVariable, uncertainty, disableMaths, defaultVariableOperator, calculatedVariableMode]);

  const handleSelectR2 = useCallback(() => {
    insertVariable(r2, disableMaths, defaultVariableOperator ?? undefined, calculatedVariableMode);
  }, [insertVariable, r2, disableMaths, defaultVariableOperator, calculatedVariableMode]);

  const handleSelectSmape = useCallback(() => {
    insertVariable(smape, disableMaths, defaultVariableOperator ?? undefined, calculatedVariableMode);
  }, [insertVariable, smape, disableMaths, defaultVariableOperator, calculatedVariableMode]);

  const handleSelectCvrmse = useCallback(() => {
    insertVariable(cvrmse, disableMaths, defaultVariableOperator ?? undefined, calculatedVariableMode);
  }, [insertVariable, cvrmse, disableMaths, defaultVariableOperator, calculatedVariableMode]);

  const handleSelectXVar = useCallback(
    (explanatoryVariable: ExplanatoryVariable) => () => {
      const modelExplanatoryVariable: ModelVariable = {
        modelUuid: selectedModel?.uuid,
        modelKey: ModelKeyField.X_VAR,
        modelXVarUuid: explanatoryVariable.uuid,
      };
      insertVariable(modelExplanatoryVariable, disableMaths, defaultVariableOperator ?? undefined, calculatedVariableMode);
    },
    [defaultVariableOperator, disableMaths, insertVariable, selectedModel?.uuid, calculatedVariableMode],
  );

  return (
    <List subheader={<li />} sx={{ position: 'relative', maxHeight: '100%', overflow: 'auto', '& ul': { p: 0 } }}>
      <li>
        <ul>
          <ModelItem
            avatar={
              <Avatar>
                <TroubleshootOutlined />
              </Avatar>
            }
            caption={t('list.text.variable')}
            primary={t('text.modelVariable')}
            variable={modelVariable}
            onClick={handleSelectModelVariable}
          />
          {selectedModel.yVar && (
            <ModelItem
              avatar={<ModelVariableAvatar kind="Y" />}
              caption={t('list.text.variable')}
              primary={t('text.yVar')}
              secondary={<CalculationSlate calculation={selectedModel.yVar.variable} />}
              variable={yVar}
              onClick={handleSelectYVar}
            />
          )}
          {/* {selectedModel.yVar && (
            <ModelItem
              avatar={
                <Avatar>
                  <TroubleshootOutlined />
                </Avatar>
              }
              caption={t('list.text.variable')}
              primary={t('text.modelAbsoluteGap')}
              secondary={t('text.modelAbsoluteGapFormula')}
              variable={absoluteGap}
              onClick={handleSelectAbsoluteGap}
            />
          )}
          {selectedModel.yVar && (
            <ModelItem
              avatar={
                <Avatar>
                  <TroubleshootOutlined />
                </Avatar>
              }
              caption={t('list.text.variable')}
              primary={t('text.modelRelativeGap')}
              secondary={t('text.modelRelativeGapFormula')}
              variable={relativeGap}
              onClick={handleSelectRelativeGap}
            />
          )} */}
          <ModelItem
            avatar={
              <Avatar>
                <TroubleshootOutlined />
              </Avatar>
            }
            caption={t('list.text.variable')}
            primary={t('text.modelR2')}
            secondary={formattedR2}
            variable={r2}
            onClick={handleSelectR2}
          />
          <ModelItem
            avatar={
              <Avatar>
                <TroubleshootOutlined />
              </Avatar>
            }
            caption={t('list.text.variable')}
            primary={t('text.modelSmape')}
            secondary={formattedSmape}
            variable={smape}
            onClick={handleSelectSmape}
          />
          <ModelItem
            avatar={
              <Avatar>
                <TroubleshootOutlined />
              </Avatar>
            }
            caption={t('list.text.variable')}
            primary={t('text.modelCvrmse')}
            secondary={formattedCvrmse}
            variable={cvrmse}
            onClick={handleSelectCvrmse}
          />
          <ModelItem
            avatar={
              <Avatar>
                <TroubleshootOutlined />
              </Avatar>
            }
            caption={t('list.text.variable')}
            primary={t('text.modelUncertainty')}
            secondary={formattedUncertainty}
            variable={uncertainty}
            onClick={handleSelectUncertainty}
          />
          <ModelItem
            avatar={
              <Avatar>
                <TroubleshootOutlined />
              </Avatar>
            }
            caption={t('list.text.model')}
            primary={t('text.modelCalculation')}
            secondary={<CalculationSlate calculation={modelCalculation} />}
            onClick={handleSelectModelCalculation}
          />
        </ul>
      </li>
      <li>
        <ul>
          <ListSubheader disableGutters>{t('subtitle.explanatoryVariables')}</ListSubheader>
          {selectedModel.xVars.map((explanatoryVariable, index) => (
            <ModelColumnXVar
              key={explanatoryVariable.uuid}
              explanatoryVariable={explanatoryVariable}
              selectedModelUuid={selectedModel.uuid}
              xVarIndex={index}
              onSelect={handleSelectXVar(explanatoryVariable)}
            />
          ))}
          {selectedModel.xVars.length === 0 && (
            <ListItem>
              <Typography variant="subtitle2">{t('text.noExplanatoryVariable')}</Typography>
            </ListItem>
          )}
        </ul>
      </li>
    </List>
  );
};

export default ModelColumn;
