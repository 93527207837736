import { ArrowRight } from '@mui/icons-material';
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { FC, useCallback } from 'react';

import { BlocksOutlined } from 'assets/icons/BlocksOutlined';
import { ListItemButtonWithArrow } from 'components/VNC/components/List/BlockDiagramList/ListItemButtonWithArrow';
import { BidirectionalBlockInfo } from 'types/lego';

export interface BlockFloorProps {
  childId?: string | null;
  emptyText: string;
  list: BidirectionalBlockInfo[];
  onClick: (id: string) => void;
  selectedId?: string | null;
}

const BlockFloor: FC<BlockFloorProps> = ({ childId = null, emptyText, list, onClick, selectedId = null }) => {
  const sortedList = list.sort((a, b) => a.name.localeCompare(b.name));

  const makeClick = useCallback(
    (id: string) => () => {
      onClick(id);
    },
    [onClick],
  );

  return (
    <List sx={{ maxHeight: '100%', minWidth: '200px', overflow: 'auto', position: 'relative', width: '100%' }}>
      {sortedList.map(child => {
        const hasChildren = child.children.filter(block => block.uuid !== childId).length > 0;
        return (
          <ListItem key={child.uuid} disablePadding>
            <Box
              disableGutters
              component={hasChildren ? ListItemButtonWithArrow : ListItemButton}
              selected={child.uuid === selectedId}
              onClick={makeClick(child.uuid)}
            >
              <ListItemIcon sx={{ mr: 2, minWidth: 'unset' }}>
                <BlocksOutlined sx={{ color: child.ui.color }} />
              </ListItemIcon>
              <ListItemText>{child.name}</ListItemText>
              {hasChildren && <ArrowRight />}
            </Box>
          </ListItem>
        );
      })}
      {sortedList.length === 0 && (
        <ListItem>
          <Typography variant="subtitle2">{emptyText}</Typography>
        </ListItem>
      )}
    </List>
  );
};

export default BlockFloor;
