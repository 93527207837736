import { EntityKind, IsOneOfEnum, Rights, ShortSharingsInfo, TinyUserInfo, UUID, UserListInfo } from '@dametis/core';

import { EntityType } from './entity';

export enum ShareType {
  REPORT = EntityType.REPORT,
  PLAYGROUND = EntityType.PLAYGROUND,
  SYNOPTIC = EntityType.SYNOPTIC,
  PROJECT = EntityType.PROJECT,
  ALARM = EntityType.ALARM,
}

export const isShareType = (value: string): value is ShareType => IsOneOfEnum(value, ShareType);

export type ShareTypeWithSSO = ShareType.PLAYGROUND | ShareType.REPORT | ShareType.SYNOPTIC;

export enum NotifyItemType {
  REPORT = EntityType.REPORT,
  PLAYGROUND = EntityType.PLAYGROUND,
  SYNOPTIC = EntityType.SYNOPTIC,
  PROJECT = EntityType.PROJECT,
  ALARM = EntityType.ALARM,
  PROJECT_TASK = 'projectTask',
}

export const entityToShareTypes = {
  [EntityType.ALARM]: ShareType.ALARM as const,
  [EntityType.PLAYGROUND]: ShareType.PLAYGROUND as const,
  [EntityType.PROJECT]: ShareType.PROJECT as const,
  [EntityType.REPORT]: ShareType.REPORT as const,
  [EntityType.SYNOPTIC]: ShareType.SYNOPTIC as const,
};

export const shareTypeToEntityKinds = {
  [ShareType.PLAYGROUND]: EntityKind.PLAYGROUND as const,
  [ShareType.REPORT]: EntityKind.REPORT as const,
  [ShareType.SYNOPTIC]: EntityKind.SYNOPTIC as const,
};

export interface SharedUser {
  user: TinyUserInfo;
  rights: Rights;
}

export interface SharedUserList {
  list: UserListInfo;
  rights: Rights;
}

export type ShareEntity = { sharings: ShortSharingsInfo<Rights>; owner?: TinyUserInfo; uuid: UUID; name: string };
