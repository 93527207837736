import { GridLocaleText } from '@mui/x-data-grid-premium';

import { AlarmPriority } from '@dametis/core';

import { SortOption } from 'types';

export default {
  button: {
    acknowledge: 'Acknowledge',
    createAlarm: 'Alarm',
    advancedSearch: 'Advanced search',
    deleteAlarmConfirmation_yes: 'Yes, delete',
    deleteAlarmConfirmation_cancel: 'Cancel',
    muteAlarm: 'Mute this alarm',
    unmuteAlarm: 'Unmute this alarm',
    close: 'close',
    create: 'create',
    previous: 'Previous',
    next: 'Next',
    submit: 'Submit',
    seeAllAlarms: 'See all alarms',
  },
  title: {
    alarms: 'Alarms',
    activeAlarms: 'Active alarms',
    alarmsHistory: 'History',
    alarmsSettings: 'Alarms settings',
    acknowledgeAlarmModal: 'Acknowledgement',
  },
  subtitle: {
    updatedAt: 'Updated {{date}}',
    updatedAtBy: 'Updated {{date}} by {{name}}',
  },
  text: {
    when: 'When',
    do: 'Do',
    deleteAlarmConfirmation: 'Do you confirm this alarm deletion?',
    events: 'Events history',
    for: 'for',
    day: 'd',
    noResults: 'No results',
    noActiveAlarms: 'No active alarms',
    noHistoryIncidents: 'No incidents',
    noAlarms: 'No alarms',
    allSelected: 'All selected',
    requiredComment: 'Leave a comment is required for this alarm',
    projectFromAlarmName: 'Project from "{{name}}"',
    commentDateIsCurrentDate: 'Comment date is alarm acknowledgement date',
    alarmAck: 'Acknowledged',
    alarmNotAck: 'Not acknowledged',
  },
  condition: {
    'equation_>': '{{variable}} is greater than {{value}}',
    'equation_>=': '{{variable}} is greater or equal to {{value}}',
    'equation_<': '{{variable}} is inferior to {{value}}',
    'equation_<=': '{{variable}} is inferior or equal to {{value}}',
    'equation_==': '{{variable}} is equal to {{value}}',
    'equation_!=': '{{variable}} is different than {{value}}',
    forXUnitOfTime: 'for {{count}} $t(global:unit.time_{{unit}}, { "count": {{count}} })',
  },
  action: {
    sendEmail: 'Send an email to {{email}}',
    sendText: 'Send a text to {{phone}}',
    sendEmail_notSet: '$t(action.sendNotification)',
    sendNotification: 'Notify in-app',
  },
  snackbar: {
    message: {
      acknowledge: {
        success: 'Alert was successfully acknowledged',
      },
      duplicate: 'Alarm successfully duplicated',
    },
  },
  table: {
    cells: {
      state: 'State',
      name: 'Name',
      criticality: 'Criticality',
      extremeValue: 'Extreme',
      triggerDate: 'Triggered at',
      activeDuration: 'Active for',
      actions: 'Action',
      acknowledgementDate: 'Acknowledged at',
      acknowledgementActor: 'Acknowledged by',
      resolveDate: 'Resolved at',
      date: 'Date',
      event: 'Event',
      comment: 'Comment',
      commentLinkedProject: 'linked project',
      resOn: 'Acknowledged on ',
      resBy: 'by ',
    },
    rows: {
      acknowledgedBy: '{{name}}',
      event_resolved: 'Resolved',
      event_triggered: 'Triggered',
      event_notify: 'Notification sent',
      event_ack: 'Acknowledged',
      event_ackBy: 'Acknowledged by {{name}}',
    },
  },
  criticality: {
    text: 'Criticality',
    [`variant_${AlarmPriority.NOTIFICATION}`]: 'Notification',
    [`variant_${AlarmPriority.WARNING}`]: 'Warning',
    [`variant_${AlarmPriority.CRITICAL}`]: 'Critical',
  },
  priority: {
    [AlarmPriority.NOTIFICATION]: 'Notification',
    [AlarmPriority.WARNING]: 'Warning',
    [AlarmPriority.CRITICAL]: 'Critical',
  },
  sortBy: {
    [SortOption.ALPHA_ASC]: 'Sort by ascending',
    [SortOption.ALPHA_DESC]: 'Sort by descending',
    [SortOption.DATE_ASC]: 'Sort by oldest',
    [SortOption.DATE_DESC]: 'Sort by newest',
  },
  filters: {
    variablesFilter: 'Variables',
    criticalityFilter: 'Criticality',
  },
  input: {
    timeRange: {
      label: 'Date',
      value: {
        lastXUnitOfTime: 'Last {{count}} $t(global:unit.time_{{unit}}, { "count": {{count}} })',
        value_all: 'All',
      },
    },
    criticality: {
      label: 'Criticality',
      value: {
        variant: '$t(criticality.variant)',
      },
    },
    alarms: {
      label: 'Name',
    },
    variable: {
      label: 'Variable',
      error: {
        empty: 'Required',
      },
    },
    name: {
      label: 'Alarm name',
      error: {
        empty: 'Required',
        over60characters: 'Too long',
      },
    },
    operand: {
      error: {
        empty: 'Required',
      },
    },
    notificationDelay: {
      error: {
        empty: 'Required',
        notPositive: 'Cannot be negative',
      },
    },
    email: {
      placeholder: 'Send an email to',
      error: {
        invalid: 'Invalid',
      },
    },
    phone: {
      placeholder: 'Send a message to',
      error: {
        invalid: 'Invalid',
      },
    },
    operator: {
      label: 'Operator',
    },
    emptyAlarmFilter: 'No alarm filter',
  },
  xgrid: {
    noAlarmsLabel: 'No Alarm',
    footerTotalAlarms: 'Total alarms :',
    footerPaginationAlarmsPerPage: 'Alarms per page :',
    noIncidentsLabel: 'No incident',
    footerTotalIncidents: 'Total incidents :',
    footerPaginationIncidentsPerPage: 'Incidents per page :',
  } as Partial<GridLocaleText>,
  stepper: {
    comment: 'Comment',
    project: 'Project',
  },
  placeholder: {
    acknowledgeAlarmComment: 'Write a comment...',
  },
  label: {
    createProjectFromAlarm: 'Create a project from this alarm',
  },
  tooltip: {
    alarm: 'alarm',
    createAlarm: 'create an alarm',
    acknowledgeAlarm: 'acknowledge this alarm',
    acknowledgedActiveAlarm: 'Acknowledged active alarm',
    unacknowledgedActiveAlarm: 'Unacknowledged active alarm',
    unacknowledgedInactiveAlarm: 'Unacknowledged inactive alarm',
  },
};
