import { CreateReportBody, DuplicateReportBody, ReportInfo, ShortReportInfo, UUID, UpdateReportBody } from '@dametis/core';

import { removeLastEntity } from 'components/Layouts/Drawer/hooks';

import { sdk } from '../../sdk';
import { LastEntityType } from '../../types/drawer';
import { RootState, useSelector } from '../index';

import { api } from './index';

const reportsApi = api.injectEndpoints({
  endpoints: build => ({
    readReports: build.query<ShortReportInfo[], { groupId?: UUID; siteId?: UUID } | void>({
      providesTags: ['Reports'],
      queryFn: async (arg, { getState }) => {
        const state = getState() as RootState;
        const groupId = (arg && arg.groupId) ?? state.auth.selectedGroup!.uuid;
        const siteId = (arg && arg.siteId) ?? state.auth.selectedSite?.uuid;
        try {
          const { data } = await (siteId
            ? sdk.report.List(siteId)
            : sdk.corporate.ListReports(groupId, { params: { isCorporate: 'true' } }));
          return { data };
        } catch (error) {
          console.error(error);
          return { error };
        }
      },
    }),
    readReport: build.query<ReportInfo, UUID>({
      providesTags: ['Reports'],
      queryFn: async uuid => {
        try {
          const { data } = await sdk.report.Read(uuid);
          return { data };
        } catch (error) {
          console.error(error);
          return { error };
        }
      },
    }),
    createReport: build.mutation<ShortReportInfo, CreateReportBody>({
      invalidatesTags: ['Reports', 'TagTreesUsages'],
      queryFn: async (body, { getState }) => {
        const state = getState() as RootState;
        const groupId = state.auth.selectedGroup!.uuid;
        const siteId = state.auth.selectedSite?.uuid;
        try {
          const { data } = await (siteId ? sdk.report.Create(siteId, body) : sdk.corporate.CreateReport(groupId, body));
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    deleteReport: build.mutation<void, UUID>({
      invalidatesTags: ['Reports', 'TagTreesUsages'],
      queryFn: async (uuid, { dispatch }) => {
        try {
          const { data } = await sdk.report.Delete(uuid);
          dispatch(removeLastEntity(LastEntityType.REPORTS, uuid));
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    duplicateReport: build.mutation<ReportInfo, { uuid: UUID; body: DuplicateReportBody }>({
      invalidatesTags: ['Reports', 'TagTreesUsages'],
      queryFn: async ({ uuid, body }) => {
        try {
          const { data } = await sdk.report.Duplicate(uuid, body);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    updateReport: build.mutation<ReportInfo, { uuid: UUID; body: UpdateReportBody }>({
      invalidatesTags: ['Reports', 'TagTreesUsages'],
      queryFn: async ({ uuid, body }) => {
        try {
          const { data } = await sdk.report.Update(uuid, body);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
  }),
});

export const {
  useReadReportQuery,
  useLazyReadReportQuery,
  useReadReportsQuery,
  useLazyReadReportsQuery,
  useCreateReportMutation,
  useUpdateReportMutation,
  useDeleteReportMutation,
  useDuplicateReportMutation,
} = reportsApi;

export const useReports: typeof useReadReportsQuery = (arg, options) => {
  const groupId = useSelector(state => state.auth.selectedGroup?.uuid);
  const siteId = useSelector(state => state.auth.selectedSite?.uuid);

  return useReadReportsQuery(arg ?? { groupId, siteId }, { skip: !siteId && !groupId, ...options });
};
