import { CreateProjectBody, ProjectInfo, ShortProjectInfo, UUID, UpdateProjectBody } from '@dametis/core';

import { removeLastEntity } from 'components/Layouts/Drawer/hooks';
import { LastEntityType } from 'types/drawer';

import { sdk } from '../../sdk';
import { RootState, useSelector } from '../index';

import { api } from './index';

const projectsApi = api.injectEndpoints({
  endpoints: build => ({
    readProjects: build.query<ShortProjectInfo[], { groupId?: UUID; siteId?: UUID } | void>({
      providesTags: ['Projects'],
      queryFn: async (arg, { getState }) => {
        const state = getState() as RootState;
        const groupId = (arg && arg.groupId) ?? state.auth.selectedGroup!.uuid;
        const siteId = (arg && arg.siteId) ?? state.auth.selectedSite?.uuid;
        try {
          const { data } = await (siteId ? sdk.project.List(siteId) : sdk.corporate.ListProjects(groupId));
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    readProject: build.query<ProjectInfo, UUID>({
      providesTags: ['Projects'],
      queryFn: async uuid => {
        try {
          const { data } = await sdk.project.Read(uuid);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    createProject: build.mutation<ProjectInfo, CreateProjectBody>({
      invalidatesTags: ['Projects', 'TagTreesUsages'],
      queryFn: async (body, { getState }) => {
        const state = getState() as RootState;
        const siteId = state.auth.selectedSite!.uuid;
        try {
          const { data } = await sdk.project.Create(siteId, body);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    deleteProject: build.mutation<void, UUID>({
      invalidatesTags: ['Projects', 'TagTreesUsages'],
      queryFn: async (uuid, { dispatch }) => {
        try {
          const { data } = await sdk.project.Delete(uuid);
          dispatch(removeLastEntity(LastEntityType.PROJECTS, uuid));
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    updateProject: build.mutation<ProjectInfo | null, { uuid: UUID; body: UpdateProjectBody }>({
      invalidatesTags: ['Projects', 'TagTreesUsages'],
      queryFn: async ({ uuid, body }) => {
        try {
          const { data } = await sdk.project.Update(uuid, body);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
  }),
});

export const {
  useReadProjectsQuery,
  useReadProjectQuery,
  useLazyReadProjectsQuery,
  useCreateProjectMutation,
  useDeleteProjectMutation,
  useUpdateProjectMutation,
  endpoints: projectEndpoints,
} = projectsApi;

export const useProjects: typeof useReadProjectsQuery = (arg, options) => {
  const groupId = useSelector(state => state.auth.selectedGroup?.uuid);
  const siteId = useSelector(state => state.auth.selectedSite?.uuid);

  return useReadProjectsQuery(arg ?? { groupId, siteId }, { skip: !siteId && !groupId, ...options });
};
