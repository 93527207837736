export default {
  title: 'Invitar mediante enlace',
  text: {
    expiration: 'Este enlace de invitación caducará',
  },
  select: {
    one_day: 'en 24 horas',
    two_days: 'en 48 horas',
    one_week: 'en 7 días',
    one_month: 'en 30 días',
    never: 'nunca',
  },
  button: {
    generate: 'GENERAR',
    close: 'OK',
    invite: 'Invitar mediante enlace',
  },
  alert: 'Todos los que tengan este enlace pueden crear una cuenta.',
  table: {
    kind: 'Redirige a',
    owner: 'Creado por',
    link: 'Enlace de invitación',
    invitation: 'Invitación',
    expiration: 'Caducidad',

    site: 'La plataforma',
    noInvitation: 'Sin invitación',
    unknown: 'Usuario eliminado',
  },
  toast: {
    copy: 'Enlace de invitación copiado al portapapeles',
    revoke: 'Invitación revocada con éxito',
  },
};
