import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid2,
  Stack,
  Typography,
  accordionDetailsClasses,
  accordionSummaryClasses,
} from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockTypeMetric } from '@dametis/core';

import Counter from 'components/UI/Counter/Counter';

export interface BlockMetricsGroupProps<T> {
  getMetricPreview: (metric: T) => ReactNode;
  metrics: T[];
  panelWidth: number;
}

const BlockMetricsGroup = <T extends BlockTypeMetric>({ getMetricPreview, metrics, panelWidth }: BlockMetricsGroupProps<T>) => {
  const { t } = useTranslation('lego');

  if (metrics.length === 0) {
    return null;
  }

  return (
    <Accordion
      defaultExpanded
      sx={theme => ({
        bgcolor: 'transparent !important',
        mb: '0 !important',
        mt: `${theme.spacing(0.5)} !important`,
        '&.Mui-expanded': { mb: `${theme.spacing(0.5)} !important` },
      })}
    >
      <AccordionSummary
        sx={theme => ({
          minHeight: 'auto !important',
          px: '0 !important',
          py: `${theme.spacing(0.5)} !important`,
          [`& .${accordionSummaryClasses.content}`]: { m: '0 !important' },
        })}
      >
        <Stack alignItems="center" direction="row" spacing={1}>
          <Typography variant="h6">{t('label.metricCategory', { context: metrics[0].category })}</Typography>
          <Counter count={metrics.length} />
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ p: '0 !important', [`& .${accordionDetailsClasses.root}`]: { p: '0 !important' } }}>
        <Stack spacing={1}>
          <Grid2 container spacing={1}>
            {metrics.map(metric => (
              <Grid2 key={metric.uuid} size={{ xs: panelWidth > 400 ? 6 : 12 }}>
                {getMetricPreview(metric)}
              </Grid2>
            ))}
          </Grid2>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default BlockMetricsGroup;
