import { Add, SvgIconComponent } from '@mui/icons-material';
import {
  ButtonBase,
  ButtonBaseProps,
  IconButton,
  IconButtonProps,
  SvgIcon,
  SvgIconProps,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import { FC, MouseEventHandler, useCallback } from 'react';

export interface BigAddButtonProps extends ButtonBaseProps {
  label: string;
  icon?: SvgIconComponent;
  iconProps?: SvgIconProps;
  typographyProps?: TypographyProps;
  iconButtonProps?: IconButtonProps;
}

const BigAddButton: FC<BigAddButtonProps> = ({
  label,
  icon = Add,
  iconProps = {},
  typographyProps = {},
  iconButtonProps = {},
  onClick = undefined,
  disabled = false,
  sx = {},
  ...props
}) => {
  const theme = useTheme();

  const handleIconButtonMouseDown: MouseEventHandler<HTMLButtonElement> = useCallback(event => {
    event.stopPropagation();
  }, []);

  return (
    <ButtonBase disabled={disabled} sx={{ gap: 1, pl: 2, borderRadius: 999, ...sx }} onClick={onClick} {...props}>
      <Typography color="textSecondary" variant="h5" {...typographyProps}>
        {label}
      </Typography>
      <IconButton
        color="primary"
        disabled={disabled}
        size="large"
        sx={{ backgroundColor: `${theme.palette.primary.light}11` }}
        onClick={onClick}
        onMouseDown={handleIconButtonMouseDown}
        {...iconButtonProps}
      >
        <SvgIcon component={icon} {...iconProps} />
      </IconButton>
    </ButtonBase>
  );
};

export default BigAddButton;
