import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface LegoState {
  navigationTools: {
    groupByType: boolean;
  };
}

export const initialState: LegoState = {
  navigationTools: {
    groupByType: true,
  },
};

export const legoSlice = createSlice({
  name: 'lego',
  initialState,
  reducers: {
    setNavigationGroupByType: (state, action: PayloadAction<boolean>) => {
      state.navigationTools.groupByType = action.payload;
    },
    clearLegoStore: () => initialState,
  },
});

export const { setNavigationGroupByType } = legoSlice.actions;

export default legoSlice.reducer;
