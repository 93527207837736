import { Paper, PaperProps, styled } from '@mui/material';

import { setColorLightness } from 'functions/color';

export const PAPER_HEIGHT = 36;

export interface ItemPaperProps extends PaperProps {
  isSelected: boolean;
  isDragging?: boolean;
  hasError?: boolean;
  isDropDisabled?: boolean;
  isExpandable?: boolean;
}

const ItemPaper = styled(Paper, {
  shouldForwardProp: propName =>
    propName !== 'isSelected' &&
    propName !== 'isDragging' &&
    propName !== 'hasError' &&
    propName !== 'isDropDisabled' &&
    propName !== 'isExpandable',
})<ItemPaperProps>(({ theme, isSelected, isDragging, hasError, isDropDisabled, isExpandable }) => ({
  display: 'flex',
  cursor: isExpandable ? 'pointer' : undefined,
  alignItems: 'center',
  gap: theme.spacing(1),
  flex: 1,
  boxShadow: `0 0 0 0px ${theme.palette.secondary.light}`,
  transition: theme.transitions.create(['box-shadow']),
  ...(isSelected && {
    boxShadow: `0 0 0 2px ${theme.palette.secondary.light}`,
  }),
  backgroundColor: hasError ? setColorLightness(theme.palette.error.main, 95) : theme.palette.background.paper,
  padding: theme.spacing(1),
  height: `${36}px`,
  opacity: isDragging || isDropDisabled ? 0.4 : 1,
}));

export default ItemPaper;
