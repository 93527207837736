import { AddOutlined, EditOutlined } from '@mui/icons-material';
import { IconButton, InputLabel, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockConnection, BlockTypeConnection, BlockTypeInfo, ConnectionType, IsBlockConnection } from '@dametis/core';

import { setColorLightness } from 'functions/color';

import BlockTypeIcon from './BlockType/BlockTypeIcon';

export const CONNECTION_ACTIONS_CLASSNAME = 'connectionActions';

export interface ConnectionPreviewTitleProps {
  blockType: BlockTypeInfo | undefined | null;
  connection: BlockTypeConnection | BlockConnection;
  onOpenPicker?: () => Promise<void> | void;
}

const ConnectionPreviewTitle: FC<ConnectionPreviewTitleProps> = ({ blockType, connection, onOpenPicker = undefined }) => {
  const { t } = useTranslation('lego');
  const theme = useTheme();

  const [isAddingBlock, setIsAddingBlock] = useState<boolean>(false);

  const color = useMemo(() => (blockType ? blockType.content.color : theme.palette.text.primary), [blockType, theme]);

  const hasBlocks = useMemo(() => IsBlockConnection(connection) && connection.blockIds.length > 0, [connection]);

  const handleAddBlock = useCallback(async () => {
    if (!onOpenPicker) {
      return;
    }
    setIsAddingBlock(true);
    await onOpenPicker();
    setIsAddingBlock(false);
  }, [onOpenPicker]);

  return (
    <Stack
      alignItems="center"
      direction="row"
      p={1}
      position="relative"
      spacing={1}
      sx={{ [`& .${CONNECTION_ACTIONS_CLASSNAME}`]: { opacity: 0 }, [`&:hover .${CONNECTION_ACTIONS_CLASSNAME}`]: { opacity: 1 } }}
    >
      <BlockTypeIcon primaryIconColor={blockType ? blockType.content.color : undefined} sx={{ fontSize: 18 }} />
      <Stack gap={0.5}>
        <Stack direction="row" gap={1}>
          <Typography variant="caption">{blockType ? blockType.name : t('text.anyBlockType')}</Typography>
        </Stack>
        <InputLabel sx={{ color: setColorLightness(color, 30) }}>{connection.blockKey}</InputLabel>
      </Stack>
      {!!onOpenPicker && (
        <Stack
          alignItems="center"
          className={CONNECTION_ACTIONS_CLASSNAME}
          direction="row"
          height={1}
          position="absolute"
          pr={1}
          right={0}
          sx={{
            background: setColorLightness(color, 93),
            borderRadius: '6px',
          }}
          top={0}
        >
          <Tooltip
            title={t(hasBlocks ? 'tooltip.editBlock' : 'tooltip.addBlock', {
              count: connection.type === ConnectionType.MULTIPLE ? Infinity : 1,
            })}
          >
            <IconButton disabled={isAddingBlock} size="small" onClick={handleAddBlock}>
              {hasBlocks ? <EditOutlined fontSize="small" /> : <AddOutlined fontSize="small" />}
            </IconButton>
          </Tooltip>
        </Stack>
      )}
    </Stack>
  );
};

export default ConnectionPreviewTitle;
