import type { editor } from 'monaco-editor';

import {
  CalculationVariableProps,
  CreateBlockTypeBody,
  DataVariable,
  MathFunctionName,
  PhysicalQuantity,
  UUID,
  UpdateBlockTypeBody,
  VarCalc,
} from '@dametis/core';

import { CategorizedModels } from 'components/Models/useCategorizedModels';
import { AllVariableKind } from 'types/variables';

import { InfluentVariablesState } from '../../components/VNC/components/Filters/InfluentVariablesFilters';
import { VariableResult } from '../../components/VNC/types';

export interface VncFilters {
  physicalQuantities: Partial<Record<PhysicalQuantity, boolean>>;
  sites: Record<UUID, boolean>;
  isCorporate: boolean;
  kinds: Partial<Record<AllVariableKind, boolean>>;
  standardBlocks: Record<UUID, boolean>;
  tags: Record<UUID, boolean>;
  influentVariables: Partial<Record<InfluentVariablesState, boolean>>;
}

export interface AvailableVncFilters {
  // physicalQuantities: PhysicalQuantity[];
  // sites: UUID[];
  // isCorporate: boolean;
  kinds: AllVariableKind[];
  // blockTypes: UUID[];
  // tags: UUID[];
  // influentVariables: InfluentVariablesState[];
}

export type TextareaMode = 'slate' | 'json';

export interface FocusedFunction {
  functionName: MathFunctionName;
  anchorEl: Element;
  focusedArgument: number;
}

export interface VncState {
  calcVarProps: CalculationVariableProps;
  search: string;
  textareaMode: TextareaMode;
  monacoEditor: editor.IStandaloneCodeEditor | null;
  results: {
    variables: VariableResult[];
  };
  loadingResults: boolean;
  lego: {
    selectedStandardBlockUuid: UUID | null;
    selectedBlockUuid: UUID | null;
    selectedFolderUuid: UUID | null;
    unPostedBlockType: CreateBlockTypeBody | UpdateBlockTypeBody | null;
  };
  models: {
    selectedCategory: keyof CategorizedModels | null;
    selectedModelUuid: UUID | null;
    selectedModelSimulationUuid: UUID | null;
  };
  filters: VncFilters;
  loadingFilters: boolean;
  selection: VarCalc[];
  isCalculationValid: boolean;
  covarianceVariable: DataVariable | null;
  focusedFunction: FocusedFunction | null;
}

export const initialVncState: VncState = {
  calcVarProps: {},
  search: '',
  textareaMode: 'slate',
  monacoEditor: null,
  results: {
    variables: [],
  },
  loadingResults: true,
  lego: {
    selectedStandardBlockUuid: null,
    selectedFolderUuid: null,
    selectedBlockUuid: null,
    unPostedBlockType: null,
  },
  models: {
    selectedCategory: null,
    selectedModelUuid: null,
    selectedModelSimulationUuid: null,
  },
  filters: {
    physicalQuantities: {},
    isCorporate: false,
    sites: {},
    kinds: {},
    standardBlocks: {},
    tags: {},
    influentVariables: {},
  },
  loadingFilters: true,
  selection: [],
  isCalculationValid: false,
  covarianceVariable: null,
  focusedFunction: null,
};
