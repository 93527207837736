export default {
  user: 'Usuario',
  button: {
    validate: 'Validar',
    cancel: 'Cancelar',
    previous: 'Anterior',
    next: 'Siguiente',
    configureTwoFA: 'Activar',
    generate: 'Generar',
    regenerate: 'Regenerar',
    copy: 'Copiar',
    save: 'Guardar',
    addPasskey: 'Crear una clave de acceso',
  },
  label: {
    site: 'Sitio',
  },
  personalInformation: {
    title: 'Información personal',
    firstname: 'Nombre',
    lastname: 'Apellido',
    phone: 'Teléfono',
    email: 'Correo electrónico',
  },
  settings: {
    title: 'Ajustes',
    vnc: 'Selector de variables',
    searchHighlight: 'Resaltar palabras coincidentes en la búsqueda',
  },
  security: {
    title: 'Seguridad',
    password: 'Contraseña',
    currentPassword: 'Contraseña actual',
    newPassword: 'Nueva contraseña',
    confirmation: 'Confirmación',
  },
  preference: {
    title: 'Preferencias',
    language: 'Idioma',
    languageDico: {
      es: 'Español',
      fr: 'Français',
      en: 'English',
    },
    timezone: {
      title: 'Huso horario',
      local: 'Hora local',
      site: 'Hora del sitio industrial',
    },
  },
  twofa: {
    title: 'Doble autenticación',
    phone: 'Teléfono',
    mail: 'Correo electrónico',
    totp: 'Aplicación de autenticación',
    passkey: 'Llaves de acceso',
    configure: 'Configurar',
    modal: {
      phoneTitle: 'Introduce tu número de teléfono',
      phoneError: 'Número de teléfono no válido',
      emailTitle: 'Introduce tu correo electrónico',
      email: 'Correo electrónico',
      emailError: 'Correo electrónico no válido',
      OTPTitle: 'Configura tu aplicación de autenticación',
      OTPHelp: 'Escanee el código QR en su aplicación de autenticación favorita e ingrese el código de verificación',
      OTPKey: 'Clave',
      OTPTimeBased: 'Basado en el tiempo',
      OTPBackToQRCode: 'Escanear código QR',
      type: 'Tipo',
      cantScanHelper: 'Para agregar la entrada manualmente, proporcione los siguientes detalles a la aplicación en su teléfono',
      cantScan: '¿No puede escanear el código?',
      codeTitle: 'Introduce el código de verificación',
      code: 'Código de verificación',
      codeError: 'Código no válido',
      codeSentToEmail1: '¡Código enviado!',
      codeSentToEmail2: 'Gracias por confirmar la activación de la doble autenticación ingresando el código que le enviamos a {{email}}.',
      codeSentToEmail2_noEmail: 'Por favor introduce el código que te enviamos por <strong>correo electrónico</strong>.',
      codeSentToPhone1: '¡Código enviado!',
      codeSentToPhone2: 'Gracias por confirmar la activación de la doble autenticación ingresando el código que le enviamos al {{phone}}.',
      codeSentToPhone2_noPhone: 'Por favor introduce el código que te enviamos por <strong>SMS</strong>.',
      pleaseWaitForCode: 'La recepción de su código puede tardar un minuto. Su código sigue siendo válido durante 5 minutos.',
      sendCodeAgain: 'Recibir un nuevo código.',
      enrollError: 'Se produjo un error al enviar el código a {{email}}. Por favor, inténtalo de nuevo más tarde.',
    },
    createdAt: 'Fecha de creacion',
    lastUsed: 'Utilizado por última vez',
    notYetUsed: 'Aún no usado',
    modify: 'Modificar',
    delete: 'Vaciar',
    active: 'Activado',
    inactive: 'Desactivar',
    editForbidden: 'No puedes modificar tu último modo de autenticación de dos factores',
    deleteForbidden: 'No puedes vaciar tu último modo de autenticación de dos factores',
    webauthnHelper:
      'Las claves de acceso te permiten iniciar sesión de forma segura en tu cuenta sin necesidad de contraseña y con autenticación de dos factores, utilizando tu huella digital, rostro o llave de seguridad hardware.',
  },
  token: {
    title: 'API',
    key: 'Llaves de API personales',
  },
  sessions: {
    title: 'Tus dispositivos',
    nbByDeviceType: '{{value}} dispositivo(s) de {{deviceType}}',
    current: 'Sesión actual',
    unknownBrowser: 'Desconocido',
    unknownOS: 'Otros',
    unknownDevice: 'Desconocido',
    browser: 'Navegador',
    userAgent: 'User agent',
    addressIp: 'Dirección IP',
    buttonDelete: 'Desconectar',
    device_one: '{{value}} dispositivo de {{deviceType}}',
    device_other: '{{value}} dispositivos de {{deviceType}}',
  },
  toast: {
    copyToClipboard: 'UUID de la clave copiado al portapapeles',
  },
  error: {
    notSamePassword: 'Las contraseñas no coinciden',
    incorrectPassword: 'Contraseña incorrecta',
    invalidStrength: 'Por favor, elija una contraseña más segura. Intente con una combinación de letras, números y símbolos.',
    emptyField: 'Por favor, complete todos los campos',
    apiProblem: 'Problema de conexión',
    authenticator: 'Su autenticador no pudo generar claves de acceso',
    webauthnUnsupported: 'Su navegador no soporta WebAuthn',
  },
  tooltip: {
    showPassword: 'Mostrar contraseña',
    hidePassword: 'Ocultar contraseña',
    token: 'clave',
  },
};
