import i18next from 'i18next';

import { CreateVariableListBody, UpdateVariableListBody, VariableListInfo, VariableListKind } from '@dametis/core';

import { setCurrentExport, setMacros, setVariableLists } from 'store/slices/configuration';
import { setCurrentVariableList } from 'store/slices/manualEntry';

import { sdk } from '../../sdk';
import { ToastSeverity } from '../../types';
import { TypedThunk } from '../index';
import { addToast } from '../slices/toast';

import { displaySdkErrorToast } from './toasts';

export const fetchAllVariableLists = (): TypedThunk<Promise<void>> => async (dispatch, getState) => {
  const siteId = getState().auth.selectedSite?.uuid;
  if (!siteId) {
    dispatch(
      addToast({
        severity: ToastSeverity.ERROR,
        message: i18next.t('toast:errorNoSite'),
      }),
    );
    return;
  }
  try {
    const { data } = await sdk.variableList.List(siteId);
    dispatch(setVariableLists(data));
  } catch (err) {
    console.error(err);
    dispatch(displaySdkErrorToast(err));
  }
};

export const fetchMacros = (): TypedThunk<Promise<void>> => async (dispatch, getState) => {
  const state = getState();
  const { user } = state.auth;
  const site = state.auth.selectedSite;
  const groupId = state.auth.selectedGroup?.uuid;
  if (!user || !groupId) throw new Error();
  if (!site) return;
  const canAccessMacro = user.acl.HasPermission('canAccessMacro', groupId, site);
  if (!canAccessMacro) return;

  try {
    const { data } = await sdk.macro.List(site.uuid, {});
    dispatch(setMacros(data));
  } catch (err) {
    console.error(err);
    dispatch(displaySdkErrorToast(err));
  }
};

export const createNewVariableList =
  (createListBody: CreateVariableListBody): TypedThunk<Promise<VariableListInfo | null>> =>
  async (dispatch, getState) => {
    const siteId = getState().auth.selectedSite?.uuid;
    if (!siteId) {
      return null;
    }
    try {
      const { data } = await sdk.variableList.Create(siteId, createListBody);
      if (data.kind === VariableListKind.IMPORT) {
        dispatch(setCurrentVariableList(data));
      } else if (data.kind === VariableListKind.EXPORT) {
        dispatch(setCurrentExport(data));
      }
      await dispatch(fetchAllVariableLists());
      dispatch(
        addToast({
          severity: ToastSeverity.SUCCESS,
          message: i18next.t('toast:successCreateVariableList'),
        }),
      );
      return data;
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
      return null;
    }
  };

export const editVariableList =
  (listUuid: string, updateListBody: UpdateVariableListBody): TypedThunk<Promise<void>> =>
  async dispatch => {
    try {
      const { data } = await sdk.variableList.Update(listUuid, updateListBody);
      await dispatch(fetchAllVariableLists());
      if (data.kind === VariableListKind.IMPORT) {
        dispatch(setCurrentVariableList(data));
      } else if (data.kind === VariableListKind.EXPORT) {
        dispatch(setCurrentExport(data));
      }
      dispatch(
        addToast({
          severity: ToastSeverity.SUCCESS,
          message: i18next.t('toast:successEditVariableList'),
        }),
      );
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
    }
  };

export const deleteVariableList =
  (listId: string): TypedThunk<Promise<void>> =>
  async dispatch => {
    try {
      await sdk.variableList.Delete(listId);
      await dispatch(fetchAllVariableLists());
      dispatch(
        addToast({
          severity: ToastSeverity.SUCCESS,
          message: i18next.t('toast:successDeleteVariableList'),
        }),
      );
    } catch (err) {
      dispatch(displaySdkErrorToast(err));
      throw err;
    }
  };
