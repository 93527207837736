import { ButtonBase, ClickAwayListener, Paper, Popper, Stack, Theme, styled, useTheme } from '@mui/material';
import { FC, MouseEventHandler, useCallback, useMemo, useState } from 'react';

export const DEFAULT_SIZE = 16;

export const getDefaultColors = (theme: Theme): string[] => [
  theme.palette.picker.red,
  theme.palette.picker.pink,
  theme.palette.picker.green,
  theme.palette.picker.indigo,
  theme.palette.picker.blue,
  theme.palette.picker.lime,
  theme.palette.picker.yellow,
  theme.palette.picker.orange,
  theme.palette.picker.cyan,
  theme.palette.picker.peach,
];

export interface ColorContainerProps {
  pickerValue: string | null;
  size: number;
  isSelected?: boolean;
}

export const ColorContainer = styled(ButtonBase, {
  shouldForwardProp: propName => propName !== 'pickerValue',
})<ColorContainerProps>(({ pickerValue, size, isSelected, theme }) => ({
  height: size,
  width: size,
  borderRadius: size / 2,
  backgroundPosition: `0 0, ${size / 4}px ${size / 4}px`,
  backgroundSize: `${size / 2}px ${size / 2}px`,
  backgroundImage:
    pickerValue === null
      ? `repeating-linear-gradient(45deg, ${theme.palette.divider} 25%, transparent 25%, transparent 75%, ${theme.palette.divider} 75%, ${theme.palette.divider}), repeating-linear-gradient(45deg, ${theme.palette.divider} 25%, ${theme.palette.background.default} 25%, ${theme.palette.background.default} 75%, ${theme.palette.divider} 75%, ${theme.palette.divider})`
      : undefined,
  backgroundColor: pickerValue ?? undefined,
  boxShadow: `0 0 0 ${isSelected ? 2 : 0}px ${theme.palette.secondary.light}`,
  cursor: 'pointer',
  outline: `1px solid ${theme.palette.grey[300]}`,
}));

export interface SimpleColorPickerProps {
  value: string | null;
  onChange: (newValue: string | null) => void;
  defaultValue?: string | null;
  colors?: string[];
  isEditing?: boolean;
  size?: number;
}

const SimpleColorPicker: FC<SimpleColorPickerProps> = ({
  value,
  onChange,
  defaultValue = null,
  isEditing = true,
  colors = [],
  size = DEFAULT_SIZE,
}) => {
  const theme = useTheme();

  const [popperAnchorEl, setPopperAnchorEl] = useState<HTMLButtonElement | null>(null);

  const isPopperOpen = useMemo(() => Boolean(popperAnchorEl), [popperAnchorEl]);
  const parsedColors = useMemo(() => (colors.length > 0 ? colors : getDefaultColors(theme)), [colors, theme]);

  const handleClosePopper = useCallback(() => {
    setPopperAnchorEl(null);
  }, []);

  const handleOpenPopper = useCallback<MouseEventHandler<HTMLButtonElement>>(event => {
    event.preventDefault();
    event.stopPropagation();
    setPopperAnchorEl(event.currentTarget);
  }, []);

  const handleChangeValue = useCallback(
    (newValue: string | null): MouseEventHandler<HTMLButtonElement> =>
      event => {
        event.preventDefault();
        event.stopPropagation();
        setPopperAnchorEl(null);
        onChange(newValue);
      },
    [onChange],
  );

  return (
    <>
      <ColorContainer
        disableRipple={!isEditing}
        pickerValue={value ?? defaultValue}
        size={size}
        sx={{ cursor: isEditing ? undefined : 'default', flexShrink: 0 }}
        onClick={isEditing ? handleOpenPopper : undefined}
      />

      <ClickAwayListener onClickAway={handleClosePopper}>
        <Popper anchorEl={popperAnchorEl} open={isPopperOpen} placement="bottom-start">
          <Paper elevation={8}>
            <Stack direction="row" gap={1} p={1}>
              {parsedColors.map(color => (
                <ColorContainer
                  key={color}
                  isSelected={color === value}
                  pickerValue={color}
                  size={size * 1.5}
                  onClick={handleChangeValue(color)}
                />
              ))}
              <ColorContainer isSelected={value === null} pickerValue={defaultValue} size={size * 1.5} onClick={handleChangeValue(null)} />
            </Stack>
          </Paper>
        </Popper>
      </ClickAwayListener>
    </>
  );
};

export default SimpleColorPicker;
