import { DeleteOutlined } from '@mui/icons-material';
import { FC, MouseEventHandler, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { TagUsagesInfo, UUID } from '@dametis/core';

import HeaderPrimaryRightAction from 'components/UI/Header/HeaderPrimaryRightAction';
import HeaderPrimaryRightActions from 'components/UI/Header/HeaderPrimaryRightActions';
import { useDispatch } from 'store';
import { addToast } from 'store/slices/toast';
import { ToastSeverity } from 'types';
import { useTagEditStore } from 'zustand/stores/tagEdit';

import { getUsagesCount } from '../../helpers/getUsagesCount';

import ItemUsagesButton from './ItemUsagesButton';

export interface ItemActionsProps {
  tagId: UUID;
  usages: TagUsagesInfo;
}

const ItemActions: FC<ItemActionsProps> = ({ tagId, usages }) => {
  const { t } = useTranslation('tags');
  const dispatch = useDispatch();

  const isEditing = useTagEditStore(state => state.isEditing);
  const setDeletedTagIds = useTagEditStore(state => state.setDeletedTagIds);
  const selectedTagIds = useTagEditStore(state => state.selectedTagIds);
  const setSelectedTagIds = useTagEditStore(state => state.setSelectedTagIds);
  const deleteEditorTags = useTagEditStore(state => state.deleteEditorTags);
  const addEditorOperation = useTagEditStore(state => state.addEditorOperation);

  const deleteTag = useCallback(() => {
    try {
      const data = { tagIds: [tagId] };
      deleteEditorTags(data);
      addEditorOperation({
        kind: 'delete',
        data,
      });
      setSelectedTagIds(selectedTagIds.filter(selectedTagId => selectedTagId !== tagId));
      setDeletedTagIds([]);
    } catch (error) {
      console.error(error);
      dispatch(addToast({ message: t('error.deleteTag', { count: 1 }), severity: ToastSeverity.ERROR }));
      setDeletedTagIds([]);
    }
  }, [deleteEditorTags, dispatch, addEditorOperation, setDeletedTagIds, tagId, t, setSelectedTagIds, selectedTagIds]);

  const handleDeleteClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    event => {
      event.stopPropagation();
      const count = getUsagesCount(usages);
      if (count > 0) {
        // setSelectedTagIds([]);
        setDeletedTagIds([tagId]);
      } else {
        deleteTag();
      }
    },
    [usages, setDeletedTagIds, tagId, deleteTag],
  );

  return (
    <HeaderPrimaryRightActions>
      <ItemUsagesButton tagId={tagId} usages={usages} />
      {isEditing && (
        <HeaderPrimaryRightAction color="delete" icon={DeleteOutlined} size={24} text={t('button.deleteTag')} onClick={handleDeleteClick} />
      )}
    </HeaderPrimaryRightActions>
  );
};

export default memo(ItemActions);
