import { LoadingButton } from '@mui/lab';
import { Button, ButtonBase, Dialog, DialogActions, DialogTitle, FormLabel, Grid, Stack, Typography } from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BrandDeviceInfo, ProtocolKind, UUID } from '@dametis/core';

import AnimatedHeightDialogContent from 'components/UI/AnimatedHeightDialogContent/AnimatedHeightDialogContent';
import { useReadBrandDevicesQuery } from 'store/api/brandDevices';

import BrandDevicePreview, { BrandDevicePaper } from '../CreateDeviceModal/BrandDevicePreview';

import BrandDevicePickerPreview from './BrandDevicePickerPreview';

const brandDevicesEmptyArray: BrandDeviceInfo[] = [];

export interface BrandDevicePickerProps {
  value: UUID | null;
  onChange: (brandDevice: BrandDeviceInfo | undefined) => Promise<void> | void;
  protocol: ProtocolKind;
  isEditing?: boolean;
  label?: string;
}

const BrandDevicePicker: FC<BrandDevicePickerProps> = ({ value, onChange, protocol, isEditing = true, label = undefined }) => {
  const { t } = useTranslation('devices');

  const { data: brandDevices = brandDevicesEmptyArray } = useReadBrandDevicesQuery();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [dialogSelectedBrandDevice, setDialogSelectedBrandDevice] = useState<BrandDeviceInfo | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const availableBrandDevices = useMemo(
    () => brandDevices.filter(brandDevice => brandDevice.protocol === protocol),
    [protocol, brandDevices],
  );

  const selectedBrandDevice = useMemo(() => brandDevices.find(brandDevice => brandDevice.uuid === value) ?? null, [brandDevices, value]);

  const handleOpenDialog = useCallback(() => {
    setDialogSelectedBrandDevice(selectedBrandDevice);
    setIsDialogOpen(true);
  }, [selectedBrandDevice]);

  const handleCloseDialog = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  const handleChangeDialogSelectedBrandDevice = useCallback(
    (newDialogSelectedBrandDevice: BrandDeviceInfo | null) => () => {
      setDialogSelectedBrandDevice(newDialogSelectedBrandDevice);
    },
    [],
  );

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true);
    await onChange(dialogSelectedBrandDevice ?? undefined);
    setIsSubmitting(false);
    setIsDialogOpen(false);
  }, [onChange, dialogSelectedBrandDevice]);

  return (
    <>
      <Stack gap={0.5}>
        {label && <FormLabel>{label}</FormLabel>}
        <BrandDevicePickerPreview brandDevice={selectedBrandDevice} onClick={isEditing ? handleOpenDialog : undefined} />
      </Stack>
      <Dialog fullWidth maxWidth="md" open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>{t('title.brandDevicePicker', { protocol })}</DialogTitle>{' '}
        <AnimatedHeightDialogContent sx={{ pt: '4px!important', mt: '-4px!important' }}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <BrandDevicePaper
                component={ButtonBase}
                selected={!dialogSelectedBrandDevice}
                sx={{ height: 1 }}
                onClick={handleChangeDialogSelectedBrandDevice(null)}
              >
                <Typography variant="h6" width={1}>
                  {t('text.noBrandDevice')}
                </Typography>
              </BrandDevicePaper>
            </Grid>
            {availableBrandDevices.map(brandDevice => (
              <Grid key={brandDevice.uuid} item xs={4}>
                <BrandDevicePreview
                  brandDevice={brandDevice}
                  component={ButtonBase}
                  selected={brandDevice.uuid === dialogSelectedBrandDevice?.uuid}
                  onClick={handleChangeDialogSelectedBrandDevice(brandDevice)}
                />
              </Grid>
            ))}
          </Grid>
        </AnimatedHeightDialogContent>
        <DialogActions>
          <Button disabled={isSubmitting} onClick={handleCloseDialog}>
            {t('button.cancel')}
          </Button>
          <LoadingButton color="secondary" loading={isSubmitting} variant="contained" onClick={handleSubmit}>
            {t('button.select')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BrandDevicePicker;
