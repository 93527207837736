import { HelpOutlineOutlined } from '@mui/icons-material';
import { AccordionProps, AccordionSummary, Stack, Tooltip, Typography } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { NestedTag, NestedTagTrees_Flatten, NestedTag_BuildTrees, TagTreeInfo, UUID } from '@dametis/core';

import Counter from 'components/UI/Counter/Counter';
import NestedTagChip from 'components/UI/TagChip/NestedTagChip';
import SearchTagPicker from 'components/UI/TagPicker/SearchTagPicker';
import { useDispatch, useSelector } from 'store';
import { useCorporateTagTrees } from 'store/api/tags';
import { useVncStore } from 'zustand/stores/vnc';

import { FilterElementProps } from '../Filters';
import { FilterAccordion, FilterAccordionDetails, FilterAccordionSummaryStack, FilterListSubheader } from '../styled';

const tagTreesEmptyArray: TagTreeInfo[] = [];

const TagsFilters: FC<FilterElementProps> = ({ expanded, setExpanded, accordionsNumber }) => {
  const { t } = useTranslation('vnc');
  const dispatch = useDispatch();

  const tagsFilters = useVncStore(state => state.filters.tags);
  const setFilters = useVncStore(state => state.setFilters);
  const setFilterValue = useVncStore(state => state.setFilterValue);
  const getResults = useVncStore(state => state.getResults);

  const sites = useSelector(state => state.auth.selectedGroup!.sites);
  const siteId = useSelector(state => state.auth.selectedSite?.uuid);

  const isCorporate = useMemo(() => !siteId, [siteId]);

  const { data: tagTrees = tagTreesEmptyArray } = useCorporateTagTrees({ siteIds: siteId ? [siteId] : undefined });

  const buildedTagTrees = useMemo(() => NestedTag_BuildTrees(tagTrees), [tagTrees]);
  const flatTagTrees = useMemo(() => NestedTagTrees_Flatten(buildedTagTrees), [buildedTagTrees]);
  const selectedTags = useMemo(() => flatTagTrees.filter(tag => tagsFilters[tag.uuid] === true), [flatTagTrees, tagsFilters]);

  const handleDeleteTag = useCallback(
    (tagId: UUID) => async () => {
      setFilterValue('tags', tagId, false);
      await dispatch(getResults());
    },
    [setFilterValue, dispatch, getResults],
  );

  const handleChangeExpanded = useCallback<NonNullable<AccordionProps['onChange']>>(
    (_event, isExpanded) => {
      setExpanded(isExpanded ? 'tags' : false);
    },
    [setExpanded],
  );

  const handleChangeSelectedTags = useCallback(
    async (newSelectedTags: NestedTag[]) => {
      const newSelectedItemsFilter = newSelectedTags.reduce<Record<UUID, boolean>>((result, newSelectedTag) => {
        result[newSelectedTag.uuid] = true;
        return result;
      }, {});
      setFilters({ tags: newSelectedItemsFilter });
      await dispatch(getResults());
    },
    [setFilters, dispatch, getResults],
  );

  return (
    <>
      <FilterAccordion expanded={expanded} filterAccordionsNumber={accordionsNumber} onChange={handleChangeExpanded}>
        <AccordionSummary>
          <FilterAccordionSummaryStack>
            <FilterListSubheader>{t('accordion.tags')}</FilterListSubheader>
            {selectedTags.length > 0 && <Counter count={selectedTags.length} />}
          </FilterAccordionSummaryStack>
        </AccordionSummary>
        <FilterAccordionDetails>
          {(!siteId ? sites.length === 0 : flatTagTrees.length === 0) ? (
            <Typography align="center" p={1} pt={0} variant="subtitle2">
              {t('text.noTagFilter')}
            </Typography>
          ) : (
            <Stack pb={1} px={2} spacing={1}>
              <Stack alignItems="center" direction="row" gap={1}>
                <SearchTagPicker
                  isCorporate={isCorporate}
                  tagPopoverProps={{ tagTrees, sx: { zIndex: theme => theme.zIndex.modal + 1 } }}
                  value={selectedTags}
                  onChange={handleChangeSelectedTags}
                />
                <Tooltip placement="top" title={t('tags:label.searchTagsDescription')}>
                  <HelpOutlineOutlined color="icon" sx={{ fontSize: 18 }} />
                </Tooltip>
              </Stack>
              {selectedTags.length === 0 && (
                <Typography alignContent="center" height={24} variant="subtitle2">
                  {t('text.noDisplayedTagFilters')}
                </Typography>
              )}
              {selectedTags.length > 0 && (
                <Stack direction="row" flexWrap="wrap" spacing={1} width={1}>
                  {selectedTags.map(tag => (
                    <NestedTagChip key={tag.uuid} displayPath pathPosition="column" tag={tag} onDelete={handleDeleteTag(tag.uuid)} />
                  ))}
                </Stack>
              )}
            </Stack>
          )}
        </FilterAccordionDetails>
      </FilterAccordion>
    </>
  );
};

export default TagsFilters;
