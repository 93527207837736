export default {
  text: {
    createdBy: 'Creado por {{name}}',
    createdAt: 'Creado {{date}}',
    updatedAt: 'Actualizado {{date}}',
    me: 'mí',
    notFound: 'Elemento no encontrado',
  },
  action: {
    addToFavorite: 'Añadir a favoritos',
    removeFromFavorite: 'Retirar de favoritos',
    openInNew: 'Abrir en una nueva pestaña',
    rename: 'Renombrar',
    renameAndTag: 'Renombrar y etiquetar',
    edit: 'Editar',
    duplicate: 'Duplicar',
    share: 'Compartir',
    delete: 'Eliminar',
  },
  tooltip: {
    notFound: 'El elemento que busca no existe o no tiene acceso a él',
  },
};
