import { BlockTypeParameterBody, NewUUID } from '@dametis/core';

export const createBlockTypeParameterBody = ({
  uuid = NewUUID(),
  unit = '',
  name = '',
  defaultValue = null,
}: Partial<BlockTypeParameterBody> = {}): BlockTypeParameterBody => ({
  uuid,
  unit,
  name,
  defaultValue,
});
