export default {
  title: {
    newModel: 'New model',
    responseVariable: 'Response variable',
    explanatoryVar: 'Explanatory variables',
    params: 'Settings',
    equation: 'Equation',
    variables: 'Variables',
    vncEquation: 'Equation',
    newModelDialog: 'New model',
  },
  subtitle: {
    noMode: 'This model is not yet configured',
  },
  text: {
    noVariables: 'No variables',
    noYVariable: 'No response variable',
    noXVariables: 'No explanatory variables',
  },
  typography: {
    formula: 'Equation',
    r2: 'R²',
    smape: 'MAPE',
    cvrmse: 'CV(RMSE)',
    min: 'Min',
    max: 'Max',
    coefficient: 'Coefficient',
    dropImportance: 'Weight',
    pValue: 'P-value',
    standardDeviation: 'Std. dev.',
    covariance: 'Covariance',
  },
  button: {
    create: 'Create',
    cancel: 'Cancel',
    rlmMode: 'Model this variable using other variables',
    rlmMode_short: 'Variables',
    toggleRlmMode: 'Choose variables instead',
    equationMode: 'Write the equation directly',
    equationMode_short: 'Equation',
    toggleEquationMode: 'Write the equation instead',
    editModel: 'Edit this model',
    saveModel: 'Save this model',
    createModel: 'Create this model',
    deleteModel: 'Delete this model',
    duplicateModel: 'Duplicate this model',
    cancelChanges: 'Cancel the changes',
    modifyYVariable: 'Choose',
    modifyYVariable_long: 'Choose the response variable',
    addXVariable: 'Add',
    chooseCalculationVariable: 'Choose an existing variable',
    chooseCalculationVariable_short: 'Variable',
    choosePointsVariable: 'Input manual data',
    choosePointsVariable_short: 'Data',
  },
  input: {
    label: {
      groupBy: 'Sub-periods',
      name: 'Name',
      period: 'Reference period',
      uncertainty: 'Uncertainty',
    },
  },
  tooltip: {
    createModel: 'create a model',
    createInstanceModel: 'create an instance of this model',
    duplicateModel: 'duplicate this model',
    editModel: 'edit this model',
    deleteModel: 'delete this model',
    kindRequired: 'You cannot save a model without choosing its kind first',
    yVarRequired: 'You cannot save a model without a response variable',
    hideXVar: 'Variable displayed on the chart',
    showXVar: 'Variable hidden on the chart',
    enableXVar: 'Variable not taken into account in the model',
    disableXVar: 'Variable taken into account in the model',
    deleteXVar: 'Delete this variable',
    preview: {
      line: 'Line chart',
      column: 'Bar chart',
    },
    r2: 'Coefficient of determination. R² measures the quality of a regression model. The closer it is to 1, the better the model explains the variations in the data. If it is close to 0, the model is performing poorly. And if it is less than 0, the model is less accurate than a prediction based on the mean.',
    smape:
      'Mean absolute percentage error. The MAPE gives an idea of the error in percentage. The lower the percentage, the better the model.',
    cvrmse:
      'Coefficient of variation of the root mean square error. RMSE measures the accuracy of the model. It indicates how much, on average, the predictions deviate from the actual values. The CV(RMSE) normalizes the RMSE by the mean of the values. The closer it is to 0, the better the model performance.',
    dropImportance:
      'The weight measures the importance of this variable by removing it from the calculation and analyzing the impact on the R². The greater the impact, the higher the weight, the more the variable is important.',
    pValue:
      'The p-value measures the probability that the impact of this variable is linked to chance. It is between 0 and 1, and must be as low as possible. The threshold of 0.05 is often used as a confidence threshold.',
    standardDeviation:
      'Standard deviation. It measures the dispersion of the data around the mean. The lower the standard deviation, the more the data is concentrated around the mean.',
  },
  highcharts: {
    modelSeries: 'Model',
    uncertaintySeries: 'Uncertainty',
  },
  dataGrid: {
    model: 'Model',
  },
};
