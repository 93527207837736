import { Popover, PopoverOrigin, PopoverProps } from '@mui/material';
import clsx from 'clsx';
import { FC, PropsWithChildren, useMemo } from 'react';

import usePopperPanelStyles from './PopperPanel.styles';

interface Props {
  open?: boolean;
  anchorEl?: Element;
  className?: string;
  onClickAway?: PopoverProps['onClose'];
  transform?: PopoverProps['transformOrigin'];
  placement?: {
    vertical: 'top' | 'center' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
  };
  BackdropProps?: PopoverProps['BackdropProps'];
  PaperProps?: PopoverProps['PaperProps'];
  disablePortal?: boolean;
  keepMounted?: boolean;
  action?: PopoverProps['action'];
}

export type PopperPanelProps = Props;

const PopperPanel: FC<PropsWithChildren<Props>> = ({
  open = false,
  anchorEl = undefined,
  className = undefined,
  onClickAway = undefined,
  children = null,
  transform = undefined,
  placement = { vertical: 'bottom', horizontal: 'left' },
  BackdropProps = undefined,
  PaperProps = undefined,
  disablePortal = false,
  keepMounted = false,
  action = undefined,
}) => {
  const transformOrigin = useMemo<PopoverOrigin>(
    () =>
      transform ??
      ({
        vertical: { top: 'bottom', bottom: 'top', center: 'center' }[placement.vertical],
        horizontal: placement?.horizontal,
      } as PopoverOrigin),
    [transform, placement?.horizontal, placement?.vertical],
  );

  const classes = usePopperPanelStyles();
  return (
    <Popover
      disableEnforceFocus
      action={action}
      anchorEl={anchorEl}
      anchorOrigin={placement as PopoverProps['anchorOrigin']}
      BackdropProps={{ ...BackdropProps, invisible: true }}
      classes={{
        paper: clsx(
          classes.popper__paper,
          placement.horizontal === 'right' && classes['popper__paper--leftPadding'],
          placement.horizontal === 'left' && classes['popper__paper--rightPadding'],
          placement.vertical === 'bottom' && classes['popper__paper--topPadding'],
          placement.vertical === 'top' && classes['popper__paper--bottomPadding'],
        ),
      }}
      className={clsx(classes.popper, className)}
      disablePortal={disablePortal}
      keepMounted={keepMounted}
      open={open}
      PaperProps={PaperProps}
      role={undefined}
      transformOrigin={transformOrigin}
      onClose={onClickAway}
    >
      {children}
    </Popover>
  );
};

export default PopperPanel;
