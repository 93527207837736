import {
  FileDeviceSourceType,
  HttpAuthenticationType,
  MQTTPropertyMatcherType,
  MessageSecurityMode,
  OpcUaConnectionMode,
  QueryStrategy,
  UserTokenType,
} from '@dametis/core';

import { HTTP_AUTHENTICATION_TYPE_NONE } from 'components/Configuration/Devices/CreateDeviceModal/ProtocolSettingsForm/HttpSettingsForm/HttpAuthenticationForm';
import { PanelTab } from 'components/Configuration/Devices/DeviceRightPanel/DeviceRightPanel';

export default {
  title: {
    createDeviceModal: 'Nouvel équipement',
    connections: 'Connexions',
    importedVariables: 'Variables importées',
    mcoSettings: 'Monitoring - Seuil des alertes',
    acquisition: 'Acquisition',
    jqTransformer: 'Transformeur JQ',
    propertyMatcher: 'Correspondance des propriétés',
    date: 'Date',
    authentification: 'Authentification',
    connection: 'Connexion',
    mode: 'Mode',
    query: 'Requête',
    queries: 'Requêtes',
    noDelete: 'Suppression impossible',
    brandDevicePicker: "Préconfigurations d'équipement $t(global:protocol.{{protocol}})",
  },
  stepper: {
    common: 'Général',
    settings: 'Réglages',
    variables: 'Variables',
  },
  label: {
    name: 'Nom',
    protocol: 'Protocole',
    alarm: 'Alarme',
    brandDevice: 'Préconfiguration',
    host: 'Hôte',
    ip: 'Adresse IP',
    port: 'Port',
    uid: 'Unit ID',
    loopTime: 'Boucle (ms)',
    pauseAfterRequest: 'Pause',
    maxRegisterToReadByRequest: 'Mots max.',
    maxUnusedValue: 'Mots inutiles',
    invertBytes: 'Octets inversés',
    invertWords: 'Mots inversés',
    invertDoubleWords: 'Double mots inversés',
    connectMethod: 'Méthode de connexion',
    endpoint: 'URL',
    securityPolicy: 'Politique de sécurité',
    securityMode: 'Mode de sécurité',
    userTokenType: "Type d'authentification",
    userName: "Nom d'utilisateur",
    password: 'Mot de passe',
    connectionNumber: 'Connexion nº{{number}}',
    prefix: 'Préfixe',
    suffix: 'Suffixe',
    offset: 'Décalage',
    addressOffset: "Décalage de l'adresse",
    defaultVariableDataDelay: "Fréquence attendue entre deux remontées de données de l'équipement",
    deviceHeartbeatFrequency: "Fréquence attendue entre deux exécutions de l'équipement",
    defaultVariableFrequency: "Fréquence attendue entre deux points d'une variable (par défaut)",
    instance: 'Instance',
    cron: 'Expression cron (UTC)',
    timeout: 'Timeout',
    method: 'Méthode HTTP',
    contentType: 'Type de contenu',
    syncPoint: 'Point de sync.',
    syncDelay: 'Délai de sync.',
    filterPastPoints: 'Filtrer les points passés',
    endpoints: 'URLs',
    jqTransformer: 'Transformeur JQ',
    reference: 'Référence',
    date: 'Date',
    value: 'Valeur',
    timeZone: 'Fuseau horaire',
    dateFormat: 'Format',
    type: 'Type',
    username: "Nom d'utilisateur",
    key: 'Clé',
    path: 'Chemin',
    dialect: 'Dialecte',
    database: 'Base de données',
    mode: 'Mode',
    query: 'Requête',
    variables: 'Variables',
    rack: 'Rack',
    slot: 'Slot',
    localTSAP: 'TSAP local',
    remoteTSAP: 'TSAP distant',
    indicativeValues: 'Valeurs indicatives',
    logLevel: 'Journalisation',
    address: 'Adresse',
    nodeId: 'Node ID',
    request: 'Requête',
    id: 'ID',
    unit: 'Unité',
    disableSQLFormatting: 'Désactiver le formattage',
    enableSQLFormatting: 'Activer le formattage',
    fileImport: "Configuration d'import",
    source: 'Source de données',
    useSSL: 'Connexion sécurisée',
    accessKey: 'Access Key ID',
    secretKey: 'Secret Access Key',
    bucket: 'Bucket',
    internalSftpCreds: 'Vos identifiants SFTP Dametis',
    topic: 'Topic',
    uuid: 'UUID',
  },
  toast: {
    copyToClipboard: 'La valeur a été copié dans le presse-papier',
  },
  placeholder: {
    value: 'Valeur',
    key: 'Clé',
    name: 'Nom',
    defaultValue: 'Valeur par défaut',
  },
  description: {
    addressOffset: "Décalage de l'adresse des variables",
    loopTime: 'Temps de la boucle totale, en ms',
    pauseAfterRequest: 'Temps de pause entre deux requêtes modbus, en ms',
    maxRegisterToReadByRequest: "Nombre maximal de mots qu'une requête peut récupérer",
    maxUnusedValue: 'Nombre maximal de mots inutiles autorisés pour la conception des requêtes',
  },
  button: {
    close: 'Fermer',
    previous: 'Précédent',
    next: 'Suivant',
    create: 'Créer',
    addConnection: 'Ajouter une connexion',
    downloadJSONTemplate: 'Modèle JSON',
    downloadCSVTemplate: 'Modèle CSV',
    upload: 'Télécharger',
    add: 'Ajouter',
    edit: 'Modifier',
    disable: 'Désactiver',
    enable: 'Activer',
    delete: 'Supprimer',
    cancel: 'Annuler',
    save: 'Enregistrer',
    select: 'Sélectionner',
    configuration: 'Configuration',
    refresh: 'Rafraîchir',
    sourceType: {
      [FileDeviceSourceType.FILESYSTEM]: 'Fichier',
      [FileDeviceSourceType.MINIO]: 'Minio / S3',
      [FileDeviceSourceType.SFTP]: 'SFTP',
      [FileDeviceSourceType.SFTP_INTERNAL]: 'SFTP Dametis',
    },
  },
  text: {
    noSelection: 'Aucune sélection',
    noBrandDevice: 'Aucune préconfiguration',
    noEndpoints: 'Aucune valeur renseignée',
    noVariable: 'Aucune variable',
    availableQueryVariables: 'Variables disponibles:',
    noDelete_one: "Impossible de supprimer cet équipement tant qu'il possède une variable.",
    noDelete_other: "Impossible de supprimer cet équipement tant qu'il possède des variables ({{count}} variables associées).",
    default: 'Défaut',
    variables: 'Variables',
    deviceDisabled: 'Cet équipement est désactivé.',
    brandDeviceVariablesLength_zero: 'Aucune variable',
    brandDeviceVariablesLength_one: '1 variable',
    brandDeviceVariablesLength_other: '{{count}} variables',
    unknownType: 'Type inconnu',
    unknownBrand: 'Marque inconnue',
    chooseTemplateBeforeAcquisitionSettings:
      "Choisissez un protocole pour accéder aux paramètres d'acquisition et à l'import de variables.",
    noFileImportsAvailable: 'Aucune configuration disponible',
    noFileImport: 'Aucune configuration',
  },
  tooltip: {
    hidePassword: 'Cacher le mot de passe',
    seePassword: 'Voir le mot de passe',
    hideSecretKey: 'Cacher la secret access key',
    seeSecretKey: 'Voir la secret access key',
    delete: 'Supprimer',
    copy: 'Copier',
    copyToClipboard: 'Copier dans le presse-papier',
    editDevice: "modifier l'équipement",
    disableDevice: "désactiver l'équpement",
    enableDevice: "activer l'équipement",
    deleteDevice: "supprimer l'équipement",
  },
  opcUaConnectionMode: {
    [OpcUaConnectionMode.HOST_AND_PORT]: 'Hôte & Port',
    [OpcUaConnectionMode.ENDPOINT]: 'URL',
  },
  securityPolicy: {
    Invalid: 'Invalide',
    None: 'Aucune',
  },
  messageSecurityMode: {
    [`messageSecurityMode_${MessageSecurityMode.Invalid}`]: 'Invalide',
    [`messageSecurityMode_${MessageSecurityMode.None}`]: 'Aucun',
    [`messageSecurityMode_${MessageSecurityMode.Sign}`]: 'Signé',
    [`messageSecurityMode_${MessageSecurityMode.SignAndEncrypt}`]: 'Signé et encrypté',
  },
  userTokenType: {
    [`userTokenType_${UserTokenType.UserName}`]: 'Utilisateur',
    [`userTokenType_${UserTokenType.Certificate}`]: 'Certificat',
    [`userTokenType_${UserTokenType.Anonymous}`]: 'Anonyme',
    [`userTokenType_${UserTokenType.IssuedToken}`]: 'IssuedToken',
    [`userTokenType_${UserTokenType.Invalid}`]: 'Invalide',
  },
  httpAuthenticationType: {
    [HttpAuthenticationType.BASIC]: 'Basique',
    [HttpAuthenticationType.AUTHORIZATION_HEADER]: "En-tête d'authorisation",
    [HttpAuthenticationType.QUERY_PARAM]: 'Paramètre de requête',
    [HTTP_AUTHENTICATION_TYPE_NONE]: 'Aucune authentification',
  },
  queryStrategyMode: {
    [QueryStrategy.DEFAULT]: 'Défaut',
    [QueryStrategy.BULK]: 'Bulk',
    [QueryStrategy.TEMPLATE]: 'Template',
  },
  mqttPropertyMatcherType: {
    [MQTTPropertyMatcherType.MAP_ON_PAYLOAD]: 'Message',
    [MQTTPropertyMatcherType.MAP_ON_TOPIC]: 'Topic',
  },
  slotInfo: {
    S71200CPU: '0 ou 1',
    S71500CPU: '0 ou 1',
    CP343: '0 (ou voir la configuration du matériel)',
  },
  helper: {
    queryStrategyMode: {
      [QueryStrategy.DEFAULT]:
        'Dans le mode par défaut, on définit une requête par variable. On peut également créer des variables globales utilisables dans ces requêtes',
      [QueryStrategy.BULK]:
        "Dans le mode Bulk, on définit une requête globale dans l'équipement, chaque variable est ensuite récupérée grâce à sa référence.",
      [QueryStrategy.TEMPLATE]:
        "Dans le mode Template, on définit une requête globale dans l'équipement, cette requête contient des variables de template qui seront peuplées lors de la configuration des variables.",
    },
    mqttPropertyMatcherType: {
      [MQTTPropertyMatcherType.MAP_ON_PAYLOAD]:
        'En mode Message, la référence est contenue dans le message et identifiée grâce à la correspondance de propriétés.',
      [MQTTPropertyMatcherType.MAP_ON_TOPIC]: 'En mode Topic, chaque variable a son topic pour référence.',
    },
  },
  logLevel: {
    error: 'Error',
    warn: 'Warn',
    info: 'Info',
    verbose: 'Verbose',
    debug: 'Debug',
    skip: 'Skip',
  },
  panelTab: {
    [PanelTab.INFORMATIONS]: 'Informations',
    [PanelTab.REAL_VARIABLES]: 'Variables Acquises',
    [PanelTab.CREDENTIALS]: 'Identification',
  },
  dropzone: {
    dropFile: 'Déposez un document à télécharger',
    or: 'ou',
    browse: 'Parcourir',
    acceptedFiles: 'Fichiers supportés : .csv, .json',
    dropFileHere: 'Déposez votre document ici',
    fileNotAllowed: "Ce type de fichier n'est pas autorisé",
    csvTemplate: 'Modèle .csv',
    jsonTemplate: 'Modèle .json',
  },
  columns: {
    name: 'Nom',
    lastPointValue: 'Dernier point',
    unit: 'Unité',
    lastPointDate: 'Date',
  },
  dataGrid: {
    noRowsLabel: 'Aucune variable',
    footerTotalRows: 'Total variables :',
  },
};
