import { Energies, MathFunctionHelper, MathFunctionName } from '@dametis/core';
import { MultipleSymbol, UnitName } from '@dametis/unit';

export const mathFunctions: Record<MathFunctionName, MathFunctionHelper> = {
  AC_Debit: {
    name: 'AC_Debit',
    category: Energies.COMPRESSED_AIR,
    inputs: [
      {
        name: 'temperature',
        unit: UnitName.CELSIUS,
        type: 'number',
        required: true,
      },
      {
        name: 'pressure',
        unit: UnitName.BAR,
        type: 'number',
        required: true,
      },
      {
        name: 'delta_pressure',
        unit: `${MultipleSymbol.m}${UnitName.BAR}`,
        type: 'number',
        required: true,
      },
      {
        name: 'threshold_delta_pressure',
        unit: `${MultipleSymbol.m}${UnitName.BAR}`,
        type: 'number',
        required: true,
      },
      {
        name: 'diameter_in',
        unit: `${MultipleSymbol.c}${UnitName.METER}`,
        type: 'number',
        required: true,
      },
      {
        name: 'diameter_out',
        unit: `${MultipleSymbol.c}${UnitName.METER}`,
        type: 'number',
        required: true,
      },
      {
        name: 'reynolds',
        unit: '',
        type: 'boolean',
        required: false,
      },
      {
        name: 'altitude',
        unit: 'm',
        type: 'number',
        required: false,
      },
    ],
    output: {
      unit: 'Nm3/h',
      type: 'number',
    },
  },
  AC_PointDeRoseeSousPression: {
    name: 'AC_PointDeRoseeSousPression',
    category: Energies.COMPRESSED_AIR,
    inputs: [
      {
        name: 'temperature',
        unit: UnitName.CELSIUS,
        type: 'number',
        required: true,
      },
      {
        name: 'pressure',
        unit: UnitName.BAR,
        type: 'number',
        required: true,
      },
      {
        name: 'atmospheric_pressure',
        unit: `${MultipleSymbol.m}${UnitName.BAR}`,
        type: 'number',
        required: true,
      },
    ],
    output: {
      unit: UnitName.CELSIUS,
      type: 'number',
    },
  },
  AC_ConsommationSpecifique: {
    name: 'AC_ConsommationSpecifique',
    category: Energies.COMPRESSED_AIR,
    inputs: [
      {
        name: 'power',
        unit: `${MultipleSymbol.k}${UnitName.WATT}`,
        type: 'number',
        required: true,
      },
      {
        name: 'flow',
        unit: UnitName.NORMAL_CUBIC_METER_PER_HOUR,
        type: 'number',
        required: true,
      },
    ],
    output: {
      unit: '',
      type: 'number',
    },
  },
  EAU_Debit: {
    name: 'EAU_Debit',
    category: Energies.WATER,
    inputs: [
      {
        name: 'temperature',
        unit: UnitName.CELSIUS,
        type: 'number',
        required: true,
      },
      {
        name: 'pressure',
        unit: UnitName.BAR,
        type: 'number',
        required: true,
      },
      {
        name: 'delta_pressure',
        unit: `${MultipleSymbol.m}${UnitName.BAR}`,
        type: 'number',
        required: true,
      },
      {
        name: 'threshold_delta_pressure',
        unit: `${MultipleSymbol.m}${UnitName.BAR}`,
        type: 'number',
        required: true,
      },
      {
        name: 'diameter_in',
        unit: `${MultipleSymbol.m}${UnitName.METER}`,
        type: 'number',
        required: true,
      },
      {
        name: 'diameter_out',
        unit: `${MultipleSymbol.m}${UnitName.METER}`,
        type: 'number',
        required: true,
      },
      {
        name: 'reynolds',
        unit: '',
        type: 'boolean',
        required: false,
      },
      {
        name: 'altitude',
        unit: UnitName.METER,
        type: 'number',
        required: false,
      },
    ],
    output: {
      unit: UnitName.CUBIC_METER_PER_HOUR,
      type: 'number',
    },
  },
  EAU_MasseVolumique: {
    name: 'EAU_MasseVolumique',
    category: Energies.WATER,
    inputs: [
      {
        name: 'temperature',
        unit: UnitName.CELSIUS,
        type: 'number',
        required: true,
      },
    ],
    output: {
      unit: `${MultipleSymbol.k}${UnitName.GRAM_PER_CUBIC_METER}`,
      type: 'number',
    },
  },
  FROID_TemperatureSaturation: {
    name: 'FROID_TemperatureSaturation',
    category: Energies.COLD,
    inputs: [
      {
        name: 'fluid',
        unit: '',
        type: 'number',
        required: true,
      },
      {
        name: 'pressure',
        unit: UnitName.BAR,
        type: 'number',
        required: true,
      },
    ],
    output: {
      unit: UnitName.CELSIUS,
      type: 'number',
    },
  },
  FROID_TemperatureHumide: {
    name: 'FROID_TemperatureHumide',
    category: Energies.COLD,
    inputs: [
      {
        name: 'dry_temperature',
        unit: UnitName.CELSIUS,
        type: 'number',
        required: true,
      },
      {
        name: 'relative_humidity',
        unit: UnitName.PERCENT,
        type: 'number',
        required: true,
      },
      {
        name: 'atmospheric_pressure',
        unit: `${MultipleSymbol.m}${UnitName.BAR}`,
        type: 'number',
        required: true,
      },
    ],
    output: {
      unit: UnitName.CELSIUS,
      type: 'number',
    },
  },
  FROID_PuissanceFluideThermique: {
    name: 'FROID_PuissanceFluideThermique',
    category: Energies.COLD,
    inputs: [
      {
        name: 'fluid',
        unit: '',
        type: 'number',
        required: true,
      },
      {
        name: 'volume_flow_rate',
        unit: UnitName.CUBIC_METER_PER_HOUR,
        type: 'number',
        required: true,
      },
      {
        name: 'delta_temperature',
        unit: UnitName.CELSIUS,
        type: 'number',
        required: true,
      },
      {
        name: 'mass_density',
        unit: `${MultipleSymbol.k}${UnitName.GRAM_PER_CUBIC_METER}`,
        type: 'number',
        required: true,
      },
      {
        name: 'heat_capacity',
        unit: `${MultipleSymbol.k}${UnitName.JOULE}/(${MultipleSymbol.k}${UnitName.GRAM}.${UnitName.KELVIN})`,
        type: 'number',
        required: true,
      },
    ],
    output: {
      unit: `${MultipleSymbol.k}${UnitName.WATT}`,
      type: 'number',
    },
  },
  THERMIQUE_DeltaT: {
    name: 'THERMIQUE_DeltaT',
    category: Energies.OTHER,
    inputs: [
      {
        name: 'hot_temperature',
        unit: UnitName.CELSIUS,
        type: 'number',
        required: true,
      },
      {
        name: 'cold_temperature',
        unit: UnitName.CELSIUS,
        type: 'number',
        required: true,
      },
    ],
    output: {
      unit: UnitName.CELSIUS,
      type: 'number',
    },
  },
  THERMIQUE_DebitToutLiquide: {
    name: 'THERMIQUE_DebitToutLiquide',
    category: Energies.OTHER,
    inputs: [
      {
        name: 'temperature',
        unit: UnitName.CELSIUS,
        type: 'number',
        required: true,
      },
      {
        name: 'pressure',
        unit: UnitName.BAR,
        type: 'number',
        required: true,
      },
      {
        name: 'delta_pressure',
        unit: `${MultipleSymbol.m}${UnitName.BAR}`,
        type: 'number',
        required: true,
      },
      {
        name: 'threshold_delta_pressure',
        unit: `${MultipleSymbol.m}${UnitName.BAR}`,
        type: 'number',
        required: true,
      },
      {
        name: 'diameter_in',
        unit: `${MultipleSymbol.m}${UnitName.METER}`,
        type: 'number',
        required: true,
      },
      {
        name: 'diameter_out',
        unit: `${MultipleSymbol.m}${UnitName.METER}`,
        type: 'number',
        required: true,
      },
      {
        name: 'mass_density',
        unit: `${MultipleSymbol.k}${UnitName.GRAM_PER_CUBIC_METER}`,
        type: 'number',
        required: true,
      },
      {
        name: 'viscosity',
        unit: `${MultipleSymbol.c}${UnitName.STOKE}`,
        type: 'number',
        required: true,
      },
      {
        name: 'reynolds',
        unit: '',
        type: 'boolean',
        required: false,
      },
    ],
    output: {
      unit: UnitName.CUBIC_METER_PER_HOUR,
      type: 'number',
    },
  },
  THERMIQUE_DebitToutGaz: {
    name: 'THERMIQUE_DebitToutGaz',
    category: Energies.GAS,
    inputs: [
      {
        name: 'temperature',
        unit: UnitName.CELSIUS,
        type: 'number',
        required: true,
      },
      {
        name: 'pressure',
        unit: UnitName.BAR,
        type: 'number',
        required: true,
      },
      {
        name: 'delta_pressure',
        unit: `${MultipleSymbol.m}${UnitName.BAR}`,
        type: 'number',
        required: true,
      },
      {
        name: 'threshold_delta_pressure',
        unit: `${MultipleSymbol.m}${UnitName.BAR}`,
        type: 'number',
        required: true,
      },
      {
        name: 'diameter_in',
        unit: `${MultipleSymbol.m}${UnitName.METER}`,
        type: 'number',
        required: true,
      },
      {
        name: 'diameter_out',
        unit: `${MultipleSymbol.m}${UnitName.METER}`,
        type: 'number',
        required: true,
      },
      {
        name: 'mass_density',
        unit: `${MultipleSymbol.k}${UnitName.GRAM_PER_NORMAL_CUBIC_METER}`,
        type: 'number',
        required: true,
      },
      {
        name: 'viscosity',
        unit: `${MultipleSymbol.c}${UnitName.STOKE}`,
        type: 'number',
        required: true,
      },
      {
        name: 'reynolds',
        unit: '',
        type: 'boolean',
        required: false,
      },
    ],
    output: {
      unit: `${MultipleSymbol.k}${UnitName.GRAM_PER_NORMAL_CUBIC_METER}`,
      type: 'number',
    },
  },
  VAP_Debit: {
    name: 'VAP_Debit',
    category: Energies.STEAM,
    inputs: [
      {
        name: 'pressure',
        unit: UnitName.BAR,
        type: 'number',
        required: true,
      },
      {
        name: 'delta_pressure',
        unit: `${MultipleSymbol.m}${UnitName.BAR}`,
        type: 'number',
        required: true,
      },
      {
        name: 'threshold_delta_pressure',
        unit: `${MultipleSymbol.m}${UnitName.BAR}`,
        type: 'number',
        required: true,
      },
      {
        name: 'diameter_in',
        unit: `${MultipleSymbol.m}${UnitName.METER}`,
        type: 'number',
        required: true,
      },
      {
        name: 'diameter_out',
        unit: `${MultipleSymbol.m}${UnitName.METER}`,
        type: 'number',
        required: true,
      },
      {
        name: 'reynolds',
        unit: '',
        type: 'boolean',
        required: false,
      },
    ],
    output: {
      unit: `${MultipleSymbol.k}${UnitName.GRAM_PER_HOUR}`,
      type: 'number',
    },
  },
  VAP_DebitSurchauffee: {
    name: 'VAP_DebitSurchauffee',
    category: Energies.STEAM,
    inputs: [
      {
        name: 'pressure',
        unit: UnitName.BAR,
        type: 'number',
        required: true,
      },
      {
        name: 'temperature',
        unit: UnitName.CELSIUS,
        type: 'number',
        required: true,
      },
      {
        name: 'delta_pressure',
        unit: `${MultipleSymbol.m}${UnitName.BAR}`,
        type: 'number',
        required: true,
      },
      {
        name: 'threshold_delta_pressure',
        unit: `${MultipleSymbol.m}${UnitName.BAR}`,
        type: 'number',
        required: true,
      },
      {
        name: 'diameter_in',
        unit: `${MultipleSymbol.m}${UnitName.METER}`,
        type: 'number',
        required: true,
      },
      {
        name: 'diameter_out',
        unit: `${MultipleSymbol.m}${UnitName.METER}`,
        type: 'number',
        required: true,
      },
      {
        name: 'reynolds',
        unit: '',
        type: 'boolean',
        required: false,
      },
    ],
    output: {
      unit: `${MultipleSymbol.k}${UnitName.GRAM_PER_HOUR}`,
      type: 'number',
    },
  },
  VAP_Enthalpie: {
    name: 'VAP_Enthalpie',
    category: Energies.STEAM,
    inputs: [
      {
        name: 'pressure',
        unit: UnitName.BAR,
        type: 'number',
        required: true,
      },
    ],
    output: {
      unit: `${MultipleSymbol.k}${UnitName.JOULE_PER_KILOGRAM}`,
      type: 'number',
    },
  },
  VAP_RendementCombustion: {
    name: 'VAP_RendementCombustion',
    category: Energies.STEAM,
    inputs: [
      {
        name: 'fuel_type',
        unit: UnitName.NO_UNIT,
        type: 'number',
        required: true,
      },
      {
        name: 'intake_air_temperature',
        unit: UnitName.CELSIUS,
        type: 'number',
        required: true,
      },
      {
        name: 'flue_gas_temperature',
        unit: UnitName.CELSIUS,
        type: 'number',
        required: true,
      },
      {
        name: 'oxygen_levels',
        unit: UnitName.PERCENT,
        type: 'number',
        required: true,
      },
    ],
    output: {
      unit: UnitName.PERCENT, // % PCI
      type: 'number',
    },
  },
  BORNESUP: {
    name: 'BORNESUP',
    category: Energies.OTHER,
    inputs: [
      {
        name: 'value',
        unit: '',
        type: 'number',
        required: true,
      },
      {
        name: 'threshold',
        unit: '',
        type: 'number',
        required: true,
      },
    ],
    output: {
      unit: '',
      type: 'number',
    },
  },
  BORNEINF: {
    name: 'BORNEINF',
    category: Energies.OTHER,
    inputs: [
      {
        name: 'value',
        unit: '',
        type: 'number',
        required: true,
      },
      {
        name: 'threshold',
        unit: '',
        type: 'number',
        required: true,
      },
    ],
    output: {
      unit: '',
      type: 'number',
    },
  },
  ONOFF: {
    name: 'ONOFF',
    category: Energies.OTHER,
    inputs: [
      {
        name: 'value',
        unit: '',
        type: 'number',
        required: true,
      },
      {
        name: 'threshold',
        unit: '',
        type: 'number',
        required: true,
      },
    ],
    output: {
      unit: '',
      type: 'number',
    },
  },
  BORNES: {
    name: 'BORNES',
    category: Energies.OTHER,
    inputs: [
      {
        name: 'value',
        unit: '',
        type: 'number',
        required: true,
      },
      {
        name: 'threshold_min',
        unit: '',
        type: 'number',
        required: true,
      },
      {
        name: 'threshold_max',
        unit: '',
        type: 'number',
        required: true,
      },
    ],
    output: {
      unit: '',
      type: 'number',
    },
  },
  INFO_Bit: {
    name: 'INFO_Bit',
    category: Energies.OTHER,
    inputs: [
      {
        name: 'value',
        unit: '',
        type: 'number',
        required: true,
      },
      {
        name: 'bit',
        unit: '',
        type: 'number',
        required: true,
      },
    ],
    output: {
      unit: '',
      type: 'number',
    },
  },
  EXTRACT_Byte: {
    name: 'EXTRACT_Byte',
    category: Energies.OTHER,
    inputs: [
      {
        name: 'value',
        unit: '',
        type: 'number',
        required: true,
      },
      {
        name: 'bit',
        unit: '',
        type: 'number',
        required: true,
      },
    ],
    output: {
      unit: '',
      type: 'number',
    },
  },
  MERGE_64_Bit: {
    name: 'MERGE_64_Bit',
    category: Energies.OTHER,
    inputs: [
      {
        name: 'high_32_bit',
        unit: '',
        type: 'number',
        required: true,
      },
      {
        name: 'low_32_bit',
        unit: '',
        type: 'number',
        required: true,
      },
    ],
    output: {
      unit: '',
      type: 'number',
    },
  },
  IF: {
    name: 'IF',
    category: Energies.OTHER,
    inputs: [
      {
        name: 'condition',
        unit: '',
        type: 'boolean',
        required: true,
      },
      {
        name: 'value_true',
        unit: '',
        type: 'number',
        required: true,
      },
      {
        name: 'value_false',
        unit: '',
        type: 'number',
        required: true,
      },
    ],
    output: {
      unit: '',
      type: 'number',
    },
  },
  getDate: {
    name: 'getDate',
    category: Energies.OTHER,
    inputs: [
      {
        name: 'datetime',
        unit: '',
        type: 'number',
        required: true,
      },
      {
        name: 'datetimeKey',
        unit: '',
        type: 'string',
        required: true,
      },
    ],
    output: {
      unit: '',
      type: 'number',
    },
  },
  time: {
    name: 'time',
    category: Energies.OTHER,
    inputs: [
      {
        name: 'variable',
        unit: '',
        type: 'string',
        required: true,
      },
    ],
    output: {
      unit: `${MultipleSymbol.m}${UnitName.SECOND}`,
      type: 'number',
    },
  },
  now: {
    name: 'now',
    category: Energies.OTHER,
    inputs: [],
    output: {
      unit: `${MultipleSymbol.m}${UnitName.SECOND}`,
      type: 'number',
    },
  },
};
