import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Socket } from 'socket.io-client';

import { ACL, ArchStats, GroupInfo, Health, ShortGroupInfo, SiteInfo, UUID, UserInfo } from '@dametis/core';

import { AuthStatus, User } from 'types/auth';

export interface AuthState {
  user: User | null;
  groups: GroupInfo[];
  selectedSite: SiteInfo | null;
  selectedGroup: GroupInfo | null;
  status: AuthStatus;
  WIPeMaaS: boolean;
  socket?: Socket;
  archStats: ArchStats;
  siteHealth: {
    health: Health | null;
    loading: boolean;
  };
  isSwitchingGroupSite: boolean;
}

const initialState: AuthState = {
  user: null,
  groups: [],
  selectedSite: null,
  selectedGroup: null,
  status: AuthStatus.UNKNOWN,
  WIPeMaaS: false,
  socket: undefined,
  archStats: {
    dataManager: null,
  },
  siteHealth: {
    health: null,
    loading: true,
  },
  isSwitchingGroupSite: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<UserInfo>) => {
      state.user = { ...action.payload, acl: ACL.Build(action.payload.acl) };
    },
    setGroups: (state, action: PayloadAction<GroupInfo[]>) => {
      state.groups = action.payload;
    },
    setFavoriteGroups: (state, action: PayloadAction<string[]>) => {
      if (!state.user) throw new Error();
      state.user.favorites.groups = action.payload;
    },
    setSelectedSite: (state, action: PayloadAction<SiteInfo>) => {
      state.selectedSite = action.payload;
    },
    setSelectedSiteName: (state, action: PayloadAction<{ uuid: UUID; name: string }>) => {
      if (!state.selectedSite) throw new Error();
      state.selectedSite.name = action.payload.name;
      let siteIndex: number | undefined;
      const groupIndex = state.groups.findIndex(group => {
        const foundSiteIndex = group.sites.findIndex(site => site.uuid === action.payload.uuid);
        if (foundSiteIndex !== -1) {
          siteIndex = foundSiteIndex;
          return true;
        }
        return false;
      });
      if (groupIndex !== -1 && siteIndex !== undefined && siteIndex !== -1) {
        state.groups[groupIndex].sites[siteIndex].name = action.payload.name;
      }
    },
    setSelectedSiteDefaultHomeReport: (state, action: PayloadAction<UUID | null>) => {
      if (!state.selectedSite) throw new Error();
      state.selectedSite.defaultHomeReport = action.payload;
    },
    setSelectedGroup: (state, action: PayloadAction<GroupInfo>) => {
      state.selectedGroup = action.payload;
    },
    setAuthStatus: (state, action: PayloadAction<AuthStatus>) => {
      state.status = action.payload;
    },
    setSocketio: (state, action: PayloadAction<any>) => ({ ...state, socket: action.payload }),
    setUserGroupAndSite: (state, action: PayloadAction<{ selectedGroup: ShortGroupInfo; selectedSite?: SiteInfo | null }>) => {
      state.WIPeMaaS = !!state.user?.acl.HasAnyRole(action.payload.selectedGroup, action.payload.selectedSite ?? null, [
        'EMaaS',
        'SUPER_ADMIN',
      ]);
      Object.assign(state, action.payload);
    },
    setSiteHealth: (state, action: PayloadAction<Health>) => {
      state.siteHealth = {
        ...state.siteHealth,
        health: action.payload,
      };
    },
    setSiteHealthLoading: (state, action: PayloadAction<boolean>) => {
      state.siteHealth.loading = action.payload;
    },
    setSiteStats: (state, action: PayloadAction<ArchStats>) => {
      state.archStats = action.payload;
    },
    setIsSwitchingGroupSite: (state, action: PayloadAction<boolean>) => {
      state.isSwitchingGroupSite = action.payload;
    },
    clearAuthStore: () => initialState,
  },
});

export const {
  setCurrentUser,
  setGroups,
  setFavoriteGroups,
  setSelectedSite,
  setSelectedSiteName,
  setSelectedSiteDefaultHomeReport,
  setSelectedGroup,
  setAuthStatus,
  setSocketio,
  setUserGroupAndSite,
  setSiteHealth,
  setSiteHealthLoading,
  setSiteStats,
  setIsSwitchingGroupSite,
  clearAuthStore,
} = authSlice.actions;

export default authSlice.reducer;
