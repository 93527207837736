import { BoxType, Locale, OrderBy, UserAccountStatus } from '@dametis/core';

export default {
  title: {
    activities: 'Activities',
    users: 'Users',
    user: 'User',
    roles: 'Roles',
  },

  tooltip: {
    copy: 'Copier',
  },

  activities: {
    order: {
      [OrderBy.ASC]: 'Ascending',
      [OrderBy.DESC]: 'Descending',
    },
    allSelected: 'All selected',
    ignorePeriod: 'Ignore period',
    label: {
      objectTypes: 'Object types',
      types: 'Verbs',
      users: 'Users',
    },
    columns: {
      published: 'Date',
      type: 'Verb',
      actor: 'Actor',
      objectType: 'Object type',
      objectName: 'Name',
    },
    searchParamsChanged: 'Research parameters have changed.',
    detailTitlePanel: 'Details',
    closeDetailPanel: 'Close',
    restoreEntity: 'Restore',
    successfullyRestore: "L'entité a été restauré",
    impossibleRestoreOnDeletedEntites: 'Restoration impossible for deleted entities',
  },

  users: {
    inviteUser: 'User',
    emailFirstNameLastName: 'Email, firstname, lastname',
    canAccessPermission: 'Has permission',
    newUser: 'New user',
    columns: {
      name: 'Name',
      email: 'Email',
      password: 'Password',
      firstName: 'Firstname',
      lastName: 'Lastname',
      status: 'Status',
      preferences: {
        locale: 'Language',
        useSiteTimeZone: "Use site's timezone",
      },
      statusActive: 'Active',
      statusLocked: 'Locked',
      statusInviting: 'Inviting',
      global: 'Global',
      group: 'Group',
      site: 'Site',
      hidden: 'Hide to non-dameticians',
    },
    status: {
      status: 'status',
      [UserAccountStatus.ACTIVE]: 'Active',
      [UserAccountStatus.INVITING]: 'Inviting',
      [UserAccountStatus.LOCKED]: 'Locked',
    },
    locale: {
      [Locale.FR]: 'French',
      [Locale.EN]: 'English',
      [Locale.ES]: 'Spanish',
    },
    tabs: {
      information: 'Information',
      permissions: 'Permissions',
      entities: 'Entities',
    },
    edit: 'Edit',
    save: 'Save',
    delete: 'Delete',
    cancel: 'Cancel',
    impersonate: 'Impersonate',
    sendInvitationEmailAgain: 'Resend invitation email',
    global: 'Global',
    groups: 'Groups',
    sites: 'Sites',
    roles: 'Roles',
    permissions: 'Permissions',
    addGroup: 'GROUP',
    addSite: 'SITE',
    addRole: 'ROLE',
    add: 'Add',
    noGroup: 'Empty groups',
    noSite: 'Empty sites',
    noRole: 'Empty roles',
  },

  roles: {
    roles: 'Roles',
    filterByPermission: 'Filter by permission',
    permissions: 'Permissions',
    text: {
      noOption: 'No selection',
    },
  },

  misc: {
    clearCache: 'Clear API Cache (LRU + Mongo)',
    syncConsul: 'Sync Consul Groups',
    deploy: 'Deploy API',
    computeScores: 'Compute all scores',
    ping: 'Ping',
    cleanFileImportFiles: 'Clean file imports',
    cleanFileImportFilesDelay: 'Clean files older than',
  },

  boxes: {
    save: 'Save',
    edit: 'Edit',
    cancel: 'Cancel',
    create: 'Create',
    newBox: 'New box',
    refresh: 'Refresh',
    addBox: 'Box',
    title: {
      boxes: 'Boxes',
    },
    nameReferenceMachineId: 'Name, reference, machine id, feature',
    columns: {
      name: 'Name',
      uuid: 'UUID',
      reference: 'Reference',
      status: 'Status',
      machineId: 'MachineID',
      nbDevice: 'Devices',
      site: 'Site',
      lastSeen: 'Last seen',
      type: 'Type',
    },
    types: {
      [BoxType.DUMMY]: 'Dummy',
      [BoxType.PHYSICAL]: 'Physical',
      [BoxType.VM]: 'Virtual Machine',
      [BoxType.DAMETIS_VM]: 'Dametis VM',
    },
  },

  groups: {
    save: 'Save',
    edit: 'Edit',
    armageddon: 'Armageddon',
    cancel: 'Cancel',
    create: 'Create',
    newGroup: 'New group',
    refresh: 'Refresh',
    addGroup: 'Group',
    title: {
      groups: 'Groups',
    },
    prompt: {
      armageddon:
        'Armageddon mode optimizes SQL tables and performs a complete recalculation of all variables; use this feature only if you are sure of what you are doing',
    },
    search: 'Name, normalized name, uuid...',
    columns: {
      uuid: 'UUID',
      name: 'Name',
      normalizedName: 'Normalized name',
      description: 'Description',
      nbSites: 'Site count',
      region: 'Region',
      calculus: 'Calculus',
      nodes: 'Nodes',
    },
    clickHouse: {
      mainNode: 'Main ClickHouse server',
      nodes: 'Servers',
      node: {
        host: 'Clickhouse host',
        replicaName: 'Replica name',
      },
      add: 'Add ClickHouse server',
      noNodes: 'No ClickHouse servers',
    },
    calculusConfig: {
      customMaxComputeDays: 'Custom maximum compute days',
      maxComputeDays: 'Maximum compute days',
      optimizeOnReset: 'Optimize before armageddon',
    },
  },

  sites: {
    save: 'Save',
    edit: 'Edit',
    cancel: 'Cancel',
    create: 'Create',
    newGroup: 'New group',
    refresh: 'Refresh',
    addSite: 'Site',
    title: {
      sites: 'Sites',
    },
    search: 'Name, Measurement, uuid...',
    columns: {
      name: 'Name',
      uuid: 'UUID',
      createdAt: 'Creation date',
      updatedAt: 'Last update',
      group: 'Group',
      address: 'Address',
      measurement: 'Measurement',
      defaultRole: 'Default role',
      allowedRoles: 'Allowed roles',
      dataDelay: 'Data delay',
      timeZone: 'Timezone',
    },
    dataManagerConfig: {
      customPrefetch: 'Custom Prefetch',
      prefetch: 'Prefetch',
      backpedalingEnabled: 'Enable Backpedaling',
      backpedalingCustomRecomputeDelay: 'Backpedaling custom recompute delay',
      backpedalingRecomputeDelay: 'Backpedaling recompute delay',
    },
  },

  authentications: {
    save: 'Save',
    edit: 'Edit',
    cancel: 'Cancel',
    create: 'Create',
    delete: 'Delete',
    addAuthentication: 'Authentication',
    title: {
      authentications: 'Authentications',
    },
    allSitesAllowed: 'All',
    search: 'Name, Measurement, uuid...',

    selectType: 'Select a type of Authentication',
    addFallback: 'Add a fallback',

    toast: {
      successAuthenticationUpdate: 'Authentication successfully updated',
      errorAuthenticationUpdate: 'Failed to update Authentication',
      successDelete: 'Authentication deleted',
      errorDelete: 'An error ocurred while deleting Authentication',
    },

    twoFA: {
      enforced: '2FA mandatory',
      graceDuration: 'Grace duration',
      enabled: 'enabled',
      disabled: 'disabled',
    },

    columns: {
      name: 'Name',
      uuid: 'UUID',
      createdAt: 'Creation date',
      updatedAt: 'Last update',
      type: 'Type',
      emailDomains: "Email Domains (comma separated ',')",
      sites: 'Allowed sites',
      fallbacks: 'Fallbacks',

      params: {
        type: 'Type',
        issuer: 'Issuer',
        callback: 'Callback',

        // OAuth2

        clientId: 'Client ID',
        clientSecret: 'Client secret',
        accessType: 'Access Type',
        scope: 'Scope',
        prompt: 'Prompt',
        state: 'State',
        hd: 'hd',

        // Password

        strength: 'Strength',

        // SAML

        entryPoint: 'Entry point',
        cert: 'Cert',
        signatureAlgorithm: 'Signature algorithm',
        digestAlgorithm: 'Digest algorithm',
        responseSigned: 'Response signed',
        assertionsSigned: 'Assertions signed',
        identifierFormat: 'Identifier format',
        authnContext: 'AuthnContext',
        disableRequestedAuthnContext: 'Disable Requested AuthnContext',
      },
    },
  },

  maintenance: {
    title: {
      maintenance: 'Maintenance',
      beforeSaving: 'Are you sure to set this maintenance status?',
    },
    label: {
      global: 'Global',
      messageType: 'Message type',
      customMessage: 'Custom message',
    },
    button: {
      preview: 'Preview',
      cancel: 'Cancel',
      save: 'Save',
      enable: 'Enable',
    },
    toast: {
      updateMaintenanceSuccess: 'Maintenance status updated successfully',
      updateMaintenanceError: 'Error during updating maintenance status',
      fetchGroupError: 'Error fetching groups',
      fetchMaintenanceError: 'Error fetching maintenance status',
    },
  },

  placeholder: {
    defaultValue: 'Default value',
  },
};
