import { BlockInfo, CreateBlockBody, ShortBlockInfo, UUID, UpdateBlockBody } from '@dametis/core';

import { getPermissionFromId } from 'functions/getPermissionFromId';

import { sdk } from '../../sdk';
import { RootState } from '../index';

import { api } from './index';

export const blocksApi = api.injectEndpoints({
  endpoints: build => ({
    readBlock: build.query<BlockInfo, { blockId: string }>({
      providesTags: ['Blocks'],
      queryFn: async ({ blockId }) => {
        const { data } = await sdk.block.Read(blockId);
        return { data };
      },
    }),
    readBlocks: build.query<BlockInfo[] | undefined, { siteId?: string } | void>({
      providesTags: ['Blocks'],
      queryFn: async (arg, { getState }) => {
        const state = getState() as RootState;
        const siteId = (arg && arg.siteId) ?? state.auth.selectedSite?.uuid;
        const canAccessLego = getPermissionFromId(state, 'canAccessLego', { siteId });
        if (siteId === undefined || !canAccessLego) {
          return { data: undefined };
        }
        try {
          const { data } = await sdk.block.List(siteId);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    createBlock: build.mutation<ShortBlockInfo, CreateBlockBody>({
      invalidatesTags: ['Blocks', 'TagTreesUsages'],
      queryFn: async (block, { getState }) => {
        const state = getState() as RootState;
        const siteId = state.auth.selectedSite?.uuid;
        if (!siteId) {
          throw new Error('No site ID');
        }
        try {
          const { data } = await sdk.block.Create(siteId, block);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    updateBlock: build.mutation<ShortBlockInfo, { uuid: UUID; body: UpdateBlockBody }>({
      invalidatesTags: ['Blocks', 'TagTreesUsages'],
      queryFn: async ({ uuid, body }) => {
        try {
          const { data } = await sdk.block.Update(uuid, body);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    deleteBlock: build.mutation<void, UUID>({
      invalidatesTags: ['Blocks', 'TagTreesUsages'],
      queryFn: async blockId => {
        try {
          const { data } = await sdk.block.Delete(blockId);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    restoreBlock: build.mutation<UUID, { activityId: UUID; blockId: UUID }>({
      invalidatesTags: ['Blocks', 'TagTreesUsages'],
      queryFn: async ({ activityId, blockId }) => {
        try {
          await sdk.block.Restore(blockId, activityId);
          return { data: blockId };
        } catch (error) {
          return { error };
        }
      },
    }),
  }),
});

export const { useLazyReadBlockQuery, useCreateBlockMutation, useDeleteBlockMutation, useUpdateBlockMutation, useRestoreBlockMutation } =
  blocksApi;

export const selectBlocks: typeof blocksApi.endpoints.readBlocks.select = arg => {
  return blocksApi.endpoints.readBlocks.select(arg);
};

export const useBlocks: typeof blocksApi.useReadBlocksQuery = (arg, options) => {
  return blocksApi.useReadBlocksQuery(arg, options);
};
