import { Box, List, ListItem } from '@mui/material';
import { DetailedHTMLProps, FC, HTMLAttributes, MouseEventHandler, forwardRef, useCallback, useEffect, useRef } from 'react';
import { ItemContent, Virtuoso, VirtuosoHandle } from 'react-virtuoso';

import { UUID } from '@dametis/core';

import { NestedTagChipProps } from '../TagChip/NestedTagChip';

import TagsColumnItem from './TagsColumnItem';
import { ColumnElement, IsNewColumnElement } from './types';

type HTMLDivElementProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const ListComponent = forwardRef<HTMLDivElement, HTMLDivElementProps>((props, ref) => <List ref={ref} component="div" {...props} />);

ListComponent.displayName = 'ListComponent';

const ListItemComponent = forwardRef<HTMLDivElement, HTMLDivElementProps>((props, ref) => (
  <ListItem ref={ref} disablePadding component="div" {...props} />
));

ListItemComponent.displayName = 'ListItemComponent';

const isHoveredDefaultProp = () => false;

export interface TagsColumnProps {
  elements: ColumnElement[];
  selectedTagIds?: UUID[];
  chipProps?: Omit<NestedTagChipProps, 'tag' | 'ref'>;
  canAccessChildren?: boolean;
  isHovered?: (element: ColumnElement) => boolean;
  onClickElement?: (element: ColumnElement) => MouseEventHandler;
  onMouseOverElement?: (element: ColumnElement) => MouseEventHandler;
  disabledTagIds?: UUID[];
  focusedIndex?: number;
  disabledScrolling?: boolean;
}

const TagsColumn: FC<TagsColumnProps> = ({
  elements,
  selectedTagIds = [],
  chipProps = {},
  canAccessChildren = false,
  onClickElement = undefined,
  onMouseOverElement = undefined,
  isHovered = isHoveredDefaultProp,
  disabledTagIds = [],
  focusedIndex = -1,
  disabledScrolling = false,
}) => {
  const listRef = useRef<VirtuosoHandle | null>(null);

  const getVirtuosoItemContent: ItemContent<ColumnElement, any> = useCallback(
    (_index, element) => (
      <TagsColumnItem
        canAccessChildren={canAccessChildren}
        chipProps={chipProps}
        isDisabled={disabledTagIds.includes(element.tag.uuid)}
        isHovered={isHovered(element)}
        isNewColumnElement={IsNewColumnElement(element)}
        isSelected={selectedTagIds.includes(element.tag.uuid)}
        tag={element.tag}
        onClick={onClickElement?.(element)}
        onMouseOver={onMouseOverElement?.(element)}
      />
    ),
    [isHovered, onClickElement, onMouseOverElement, selectedTagIds, chipProps, canAccessChildren, disabledTagIds],
  );

  useEffect(() => {
    if (listRef.current) {
      if (focusedIndex > -1 && !disabledScrolling) {
        listRef.current.scrollToIndex({ index: focusedIndex, behavior: 'smooth', align: 'center' });
      }
    }
  }, [focusedIndex, disabledScrolling]);

  return (
    <Box width={250}>
      <Virtuoso<ColumnElement>
        ref={listRef}
        components={{ List: ListComponent, Item: ListItemComponent }}
        data={elements}
        itemContent={getVirtuosoItemContent}
        style={{ height: 285 }}
      />
    </Box>
  );
};

export default TagsColumn;
