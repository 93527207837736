import { MenuItem, MenuItemProps, Tooltip, TooltipProps } from '@mui/material';
import { FC } from 'react';

export interface MenuItemTooltipProps extends MenuItemProps {
  tooltipProps: Omit<TooltipProps, 'children'>;
}

const MenuItemTooltip: FC<MenuItemTooltipProps> = ({ tooltipProps, ...props }) => {
  return (
    <Tooltip {...tooltipProps}>
      <span>
        <MenuItem {...props} />
      </span>
    </Tooltip>
  );
};

export default MenuItemTooltip;
