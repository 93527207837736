export default {
  input: {
    label: {
      groupBy: 'Sous-périodes',
    },
    value: {
      auto: 'Auto',
      pointToPoint: 'Point à point',
      time: 'Temps',
      batch: 'Batch',
    },
    tooltip: {
      canAccessBatch: "Vous n'avez pas accès aux batchs",
    },
  },
  none: 'Aucun',
  auto: 'Auto',
  pointToPoint: 'Point à point',
  pointToPoint_short: 'PÀP',
  batch: 'Batch',
};
