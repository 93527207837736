import { Theme, keyframes } from '@mui/material';

export const PULSE_ANIMATION_DURATION = 1000;

export const PULSE_ANIMATION_CLASSNAME = 'pulseAnimation';

export const getPulseAnimation = (theme: Theme) => keyframes`
  0% {
    box-shadow: 0 0 0 0 ${theme.palette.secondary.light}80;
  }

  70% {
    box-shadow: 0 0 0 10px ${theme.palette.secondary.light}00;
  }

  100% {
    box-shadow: 0 0 0 0 ${theme.palette.secondary.light}00;
  }
`;
