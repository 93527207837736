import { FormLabel, Grid2, MenuItem, Stack, Typography } from '@mui/material';
import { ChangeEvent, FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ParseSyncDelay, ParsedSyncDelay, SyncDelay, SyncDelayUnit } from '@dametis/core';

import AdvancedNumberTextField from 'components/UI/Inputs/AdvancedTextField/AdvancedNumberTextField';
import AdvancedTextField from 'components/UI/Inputs/AdvancedTextField/AdvancedTextField';
import { shortUnitToLongUnit } from 'localization/useLocalizedGroupBy';

export const AVAILABLE_UNITS: SyncDelayUnit[] = ['ms', 's', 'm', 'h', 'd'];

export interface SyncDelayInputProps {
  value: SyncDelay;
  onChange: (newSyncDelay: SyncDelay) => void;
  label?: string;
  isEditing?: boolean;
}

const SyncDelayInput: FC<SyncDelayInputProps> = ({ value, onChange, label = undefined, isEditing = true }) => {
  const { t } = useTranslation('global');

  const parsedSyncDelay: ParsedSyncDelay = useMemo(() => {
    try {
      return ParseSyncDelay(value);
    } catch {
      return { value: 0, unit: 'ms' };
    }
  }, [value]);

  const handleChangeValue = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, newValue: number) => {
      onChange(`${newValue}${parsedSyncDelay.unit}`);
    },
    [onChange, parsedSyncDelay.unit],
  );

  const handleChangeUnit = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(`${parsedSyncDelay.value}${event.target.value as SyncDelayUnit}`);
    },
    [onChange, parsedSyncDelay.value],
  );

  return (
    <Stack gap={0.5}>
      {label && <FormLabel>{label}</FormLabel>}
      {isEditing && (
        <Grid2 container spacing={1}>
          <Grid2 size={5}>
            <AdvancedNumberTextField
              fullWidth
              parser={textValue => parseInt(textValue, 10)}
              value={parsedSyncDelay.value}
              onChange={handleChangeValue}
            />
          </Grid2>
          <Grid2 size={7}>
            <AdvancedTextField fullWidth select sx={{ minWidth: 70 }} value={parsedSyncDelay.unit} onChange={handleChangeUnit}>
              {AVAILABLE_UNITS.map(availableUnit => (
                <MenuItem key={availableUnit} value={availableUnit}>
                  {t('global:unit.time', { count: parsedSyncDelay.value, context: shortUnitToLongUnit[availableUnit] })}
                </MenuItem>
              ))}
            </AdvancedTextField>
          </Grid2>
        </Grid2>
      )}
      {!isEditing && <Typography>{value}</Typography>}
    </Stack>
  );
};

export default SyncDelayInput;
