import { Locale, OrderBy, UserAccountStatus } from '@dametis/core';

export default {
  title: {
    activities: 'Actividades',
    users: 'Usuarios',
    roles: 'Permisos',
  },

  tooltip: {
    copy: 'Copiar',
  },

  activities: {
    order: {
      [OrderBy.ASC]: 'Ascendente',
      [OrderBy.DESC]: 'Descendente',
    },
    allSelected: 'Seleccionar todo',
    ignorePeriod: 'Ignorar periodo',
    label: {
      objectTypes: 'Tipos de objeto',
      types: 'Verbos',
      users: 'Usuarios',
    },
    columns: {
      published: 'Fecha',
      type: 'Verbo',
      actor: 'Actor',
      objectType: 'Tipo de objeto',
      objectName: 'Nombre',
    },
    searchParamsChanged: 'Los parámetros de búsqueda han cambiado.',
    detailTitlePanel: 'Detalles',
    closeDetailPanel: 'Cerrar',
    restoreEntity: 'Restaurar',
    successfullyRestore: 'La entidad ha sido restaurada',
    impossibleRestoreOnDeletedEntites: 'No se puede restaurar entidades eliminadas',
  },

  users: {
    inviteUser: 'Usuario',
    emailFirstNameLastName: 'Email, nombre, apellido',
    canAccessPermission: 'Con permiso',
    newUser: 'Nuevo usuario',
    columns: {
      name: 'Nombre',
      email: 'Email',
      password: 'Contraseña',
      firstName: 'Nombre',
      lastName: 'Apellido',
      status: 'Estatus',
      preferences: {
        locale: 'Idioma',
        useSiteTimeZone: 'Usar la zona horaria del sitio',
      },
      global: 'Global',
      group: 'Grupo',
      site: 'Sitio',
      hidden: 'Ocultar a no dameticios',
    },
    status: {
      status: 'estatus',
      [UserAccountStatus.ACTIVE]: 'Activo',
      [UserAccountStatus.INVITING]: 'Invitación',
      [UserAccountStatus.LOCKED]: 'Bloqueado',
    },
    locale: {
      [Locale.FR]: 'Francés',
      [Locale.EN]: 'Inglés',
      [Locale.ES]: 'Español',
    },
    tabs: {
      information: 'Información',
      permissions: 'Permisos',
      entities: 'Entidades',
    },
    edit: 'Modificar',
    save: 'Guardar',
    supprimer: 'Suprimir',
    cancel: 'Cancelar',
    impersonate: 'Tomar la identidad',
    sendInvitationEmailAgain: 'Reenviar un email de invitación',
    global: 'Global',
    groups: 'Grupo',
    sites: 'Sitios',
    roles: 'Funciones',
    permissions: 'Permisos',
    addGroup: 'GRUPO',
    addSite: 'SITIO',
    addRole: 'Función',
    add: 'Añadir',
    noGroup: 'Sin grupo',
    noSite: 'Sin sitio',
    noRole: 'Sin función',
  },

  roles: {
    roles: 'Funciones',
    filterByPermission: 'Filtrar por permiso',
    permissions: 'Permisos',
    text: {
      noOption: 'Sin selección',
    },
  },

  misc: {
    clearCache: 'Limpiar caché API (LRU + Mongo)',
    syncConsul: 'Sincronizar grupos Consul',
    deploy: 'Desplegar la API',
  },

  boxes: {
    newBox: 'Nueva caja',
    refresh: 'Actualizar',
    addBox: 'Caja',
    save: 'Guardar',
    edit: 'Modificar',
    cancel: 'Cancelar',
    create: 'Crear',
    title: {
      boxes: 'Cajas',
    },
    nameReferenceMachineId: 'Nombre, referencia, ID de máquina, funcionalidad',
    columns: {
      name: 'Nombre',
      uuid: 'UUID',
      reference: 'Referencia',
      status: 'Estatus',
      machineId: 'MáquinaID',
      nbDevice: 'Equipos',
      site: 'Sitio',
      lastSeen: 'Últimos datos',
    },
  },

  groups: {
    newGroup: 'Nuevo grupo',
    refresh: 'Actualizar',
    addGroup: 'Grupo',
    save: 'Guardar',
    edit: 'Modificar',
    armageddon: 'Armageddon',
    cancel: 'Cancelar',
    create: 'Crear',
    title: {
      groups: 'Grupos',
    },
    prompt: {
      armageddon:
        'El armageddon de puntuaciones recalcula las correlaciones en todo el historial del grupo. El recalculo puede tardar varias horas. Utilice esta funcionalidad solo si está seguro de lo que está haciendo',
      scoresArmageddon:
        'Score Armageddon recalcula las correlaciones a lo largo de toda la historia del grupo. El nuevo cálculo puede tardar varias horas. Utilice esta función sólo si está seguro de lo que está haciendo',
    },
    nameNormalizedNameDescription: 'Nombre, nombre normalizado, uuid...',
    columns: {
      uuid: 'UUID',
      name: 'Nombre',
      normalizedName: 'Nombre normalizado',
      description: 'Descripción',
      nbSites: 'Número de sitios',
      region: 'Región',
      calculus: 'Calculus',
    },
    scores: {
      lastScore: 'Última puntuación',
      resetScores: 'Reiniciar puntuaciones',
      computeScores: 'Calcular puntuaciones',
      working: 'En progreso',
    },
  },

  sites: {
    save: 'Guardar',
    edit: 'Modificar',
    cancel: 'Cancelar',
    create: 'Crear',
    newSite: 'Nuevo sitio',
    refresh: 'Actualizar',
    addSite: 'Sitio',
    title: {
      sites: 'Sitios',
    },
    nameUuid: 'Nombre, Measurement, uuid...',
    columns: {
      name: 'Nombre',
      uuid: 'UUID',
      group: 'Grupo',
      createdAt: 'Fecha de creación',
      updatedAt: 'Última modificación',
      address: 'Dirección',
      measurement: 'Measurement',
      dataDelay: 'Data delay',
      timeZone: 'Timezone',
    },
  },

  authentications: {
    save: 'Guardar',
    edit: 'Modificar',
    cancel: 'Cancelar',
    create: 'Crear',
    newAuthentication: 'Nueva autenticación',
    delete: 'Suprimir',
    addAuthentication: 'Autenticación',
    title: {
      authentications: 'Autenticaciones',
    },
    nameUuid: 'Nombre, uuid...',
    selectType: 'Seleccione un tipo',
    addFallback: 'Agregar una alternativa',

    toast: {
      successAuthenticationUpdate: 'Autenticación actualizada',
      errorAuthenticationUpdate: 'Error al actualizar la autenticación',
      successDelete: 'Autenticación eliminada',
      errorDelete: 'Error al vaciar la autenticación',
    },

    twoFA: {
      enforced: '2FA Obligatorio',
      graceDuration: 'Período de gracia',
      enabled: 'Activado',
      disabled: 'Desactivado',
    },

    columns: {
      name: 'Nombre',
      uuid: 'UUID',
      createdAt: 'Fecha de creación',
      updatedAt: 'Última modificación',
      type: 'tipo',
      emailDomains: "Dominios de emails (separados por coma ',')",
      fallbacks: 'Alternativas',
      params: {
        type: 'Tipo',
        issuer: 'Issuer',
        callback: 'Callback',

        // OAuth2

        clientId: 'ID de cliente',
        clientSecret: 'Secreto de cliente',
        accessType: 'Tipo de acceso',
        scope: 'Scope',
        prompt: 'Prompt',
        state: 'State',
        hd: 'hd',

        // Password

        strength: 'Fortaleza',

        // SAML

        entryPoint: 'Entry point',
        cert: 'Cert',
        signatureAlgorithm: 'Signature algorithm',
        digestAlgorithm: 'Digest algorithm',
        responseSigned: 'Response signed',
        assertionsSigned: 'Assertions signed',
        identifierFormat: 'Identifier format',
        authnContext: 'AuthnContext',
        disableRequestedAuthnContext: 'Disable Requested AuthnContext',
      },
    },
  },
};
