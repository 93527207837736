import { Components, Theme, tableRowClasses } from '@mui/material';

export default {
  styleOverrides: {
    root: ({ theme }) => ({
      [`&.${tableRowClasses.selected})`]: {
        backgroundColor: theme.palette.background.default,
      },
      [`&:hover:not(.${tableRowClasses.hover})`]: {
        backgroundColor: theme.palette.background.default,
      },
    }),
  },
} as Components<Theme>['MuiTableRow'];
