import { ConnectionType, MetadataType, MetricCategory } from '@dametis/core';

import { BlockTypePanelTab } from 'components/Lego/LegoCatalog/CatalogRightPanel/BlockTypeRightPanel/BlockTypeRightPanel';
import { StandardBlockPanelTab } from 'components/Lego/LegoCatalog/CatalogRightPanel/StandardBlockRightPanel/StandardBlockRightPanel';
import { MetadataListName } from 'components/Lego/UI/BlockType/CreateBlockTypeModal/MetadataListsStep';

export default {
  title: {
    lego: 'Block diagram',
    typesCatalog: 'Catalog',
    createBlockTypeModal: 'New block type',
    createStandardBlockModal: 'New standard block',
    editBlockTypeModal: 'Edit block type',
    blockTypePanel: 'Block type',
    blocks: 'Blocks',
    folders: 'Folders',
    createBlockModal: 'New block',
    createFolderModal: 'New folder',
    updateBlockModal: 'Edit block',
    blockFilters: 'Filter parameters and metrics',
    nextAction: 'You just created a block. What would you like to do now?',
    createBlock: 'Create block',
    editBlock: 'Edit block "{{blockName}}"',
    moveTo: 'Move to...',
    chooseBlock_one: 'Choose a block',
    chooseBlock_other: 'Choose blocks',
    chooseBlockFromBlockType: 'Choose a "{{blockTypeName}}" block',
    navigation: 'Navigation',
    editFolder: 'Edit folder',
    tools: 'Tools',
    editStandardBlockModal: 'Edit standard block',
    addIncomingConnections: 'Add incoming connections',
    removeIncomingConnection: 'Remove incoming connection',
    addMissingConnection: 'Add incoming connection',
  },
  subtitle: {
    folders: 'Folders',
    blockTypes: 'Types',
    standardBlocks: 'Standard blocks',
  },
  button: {
    add: 'Add',
    linkBlock_one: 'Link block',
    linkBlock_other: 'Link blocks',
    editLink_one: 'Edit link',
    editLink_other: 'Edit links',
    newBlockType: 'Block type',
    newBlock: 'Block',
    newFolder: 'Folder',
    close: 'Close',
    cancel: 'Cancel',
    create: 'Create',
    update: 'Edit',
    duplicate: 'Duplicate',
    edit: 'Edit',
    submit: 'Submit',
    delete: 'Delete',
    blocks: 'Blocks',
    goToBlocks: 'Go to blocks',
    open: 'Open',
    openInPlayground: 'Open in playground',
    save: 'Save',
    sortedBy: 'Sort by: $t(lego:label.sortedBy.{{value}})',
    new: 'New',
    createBlock: 'Create block',
    createFolder: 'Folder',
    moveTo: 'Move to',
    move: 'Move',
    newStandardBlock: 'New standard block',
    confirm: 'Confirm',
    select: 'Select',
    selectNone: 'Select none',
  },
  label: {
    name: 'Name',
    description: 'Description',
    parameters: 'Parameters',
    metrics: 'Metrics',
    connections: 'Connections',
    blockTypes: 'Block types',
    comment: 'Comment',
    parametersLength_zero: 'No parameter',
    parametersLength_one: '{{count}} parameter',
    parametersLength_other: '{{count}} parameters',
    metricsLength_zero: 'No metric',
    metricsLength_one: '{{count}} metric',
    metricsLength_other: '{{count}} metrics',
    connectionsLength_zero: 'No connection',
    connectionsLength_one: '{{count}} connection',
    connectionsLength_other: '{{count}} connections',
    standardBlocksLength_zero: 'No standard block',
    standardBlocksLength_one: '{{count}} standard block',
    standardBlocksLength_other: '{{count}} standard blocks',
    blockTypesLength_zero: 'No type',
    blockTypesLength_one: '{{count}} type',
    blockTypesLength_other: '{{count}} types',
    foldersLength_zero: 'No folder',
    foldersLength_one: '{{count}} folder',
    foldersLength_other: '{{count}} folders',
    newBlock: 'New block',
    newBlockType: 'New type',
    newFolder: 'New folder',
    tags: 'Tags',
    options: 'Options',
    isVirtual: 'Virtual metric',
    folder: 'Folder',
    blockType: 'Block type',
    newStandardBlock: 'New standard block',
    standardBlock: 'Standard block',
    standardBlocks: 'Standard blocks',
    groupByType: 'Group by type',
    metadata: 'Metadata',
    standardBlockMetadata: 'Standard block metadata',
    blockMetadata: 'Block metadata',
    variables: 'Variables',
    [`metricCategory_${MetricCategory.METRIC}`]: 'Calculations',
    [`metricCategory_${MetricCategory.DISCREPANCY}`]: 'Discrepancies',
    [`metricCategory_${MetricCategory.TECHNICAL}`]: 'Technical',
    valueType: 'Value type',
    type: 'Type',
    unit: 'Unit',
    defaultValue: 'Default value',
  },
  placeholder: {
    name: 'Name',
    calculation: 'Calculation',
    blockKey: 'Identifier',
    unit: 'Unit',
    blockType: 'Block type',
    defaultValue: 'Default value',
    value: 'Value',
    searchStandardBlocks: 'Search standard blocks...',
    goToBlock: 'Go to block',
  },
  stepper: {
    general: 'General',
    metrics: 'Metrics',
    parameters: 'Parameters',
    blockType: 'Block type',
    informations: 'Informations',
    connections: 'Connections',
    metadatas: 'Metadatas',
    parametersAndMetrics: 'Parameters & metrics',
    variables: 'Variables',
  },
  stepperContent: {
    name: 'Name: {{name}}',
    standardMetadataListLength_zero: 'No catalog',
    standardMetadataListLength_one: '1 catalog',
    standardMetadataListLength_other: '{{count}} catalogs',
    blockMetadataListLength_zero: 'No block',
    blockMetadataListLength_one: '1 block',
    blockMetadataListLength_other: '{{count}} blocks',
    parametersLength_zero: 'No parameter',
    parametersLength_one: '1 parameter',
    parametersLength_other: '{{count}} parameters',
    connectionsLength_zero: 'No connection',
    connectionsLength_one: '1 connection',
    connectionsLength_other: '{{count}} connections',
    metricsLength_zero: 'No metric',
    metricsLength_one: '1 metric',
    metricsLength_other: '{{count}} metrics',
  },
  text: {
    noBlock: 'No block',
    noStandardBlock: 'No standard block',
    noParameter: 'No parameter',
    noMetric: 'No metric',
    availableParameter: '{{blockKey}} ({{unit}})',
    otherParametersLength: '+{{count}} parameter(s)',
    otherMetricsLength: '+{{count}} metric(s)',
    metricsCount_zero: 'No metric',
    metricsCount_one: 'One metric',
    metricsCount_other: '{{count}} metrics',
    openInPlayground: 'Open in playground.',
    blockVariableWithPath: '{{block}} ▸ {{variable}}',
    unusedParameters: 'Unused parameters:',
    noMetricWithHelper: 'No metrics are available for this block. You can add parameters to use more metrics.',
    parameterInvalid_one: 'This parameter is required for the block to function',
    parameterInvalid_other: 'These parameters are required for the block to function',
    parameterUndefined_one: 'This parameter is not required but is not defined',
    parameterUndefined_other: 'These parameters are not required but are not defined',
    parameterUnsetVnc: 'This parameter has not been set',
    home: 'Home',
    factory: 'Factory',
    noFolder: 'No folder',
    noParameterToDefine: 'No parameter to define',
    copy: 'Copy',
    noConnection: 'No connection',
    noConnectedBlock: 'No linked block',
    noMetadata: 'No metadata',
    noOption: 'No option available',
    noSelectedVariable: 'No variable selected',
    connectionName: 'Connection "{{name}}"',
    unknownBlock: 'Unknown block',
    unknownType: 'Unknown type',
    noAvailableBlock: 'No block available',
    connectedBlocks: 'Linked blocks',
    childBlocks: 'Child blocks',
    emptyFolder: 'Empty folder',
    noFolderAvailable: 'No folder available',
    canNotLoadFolders: 'Unable to load folders',
    unknownName: 'Unknown name',
    noSelectedOption: 'No selected option',
    [`noMetrics_${MetricCategory.METRIC}`]: 'No calculation',
    [`noMetrics_${MetricCategory.DISCREPANCY}`]: 'No discrepancy',
    [`noMetrics_${MetricCategory.TECHNICAL}`]: 'No technical',
    noElement: 'No element',
    deleteBlockTypeWarning_one:
      'This block type is linked to another block type. Its deletion may affect the proper functioning of some tools.',
    deleteBlockTypeWarning_other:
      'This block type is linked to {{count}} other block types. Its deletion may affect the proper functioning of some tools.',
    deleteChildrenWarning_one: 'This block has one child block. It will be deleted.',
    deleteChildrenWarning_other: 'This block has {{count}} child blocks. They will be deleted.',
    deleteConnectedWarning_one: 'This block is linked to another block. Its deletion may affect the proper functioning of some tools.',
    deleteConnectedWarning_other:
      'This block is linked to {{count}} other blocks. Its deletion may affect the proper functioning of some tools.',
    addIncomingConnections:
      'You just added the first connection from this block to block "{{name}}". Select the connections from block "{{name}}" to this block.',
    removeIncomingConnection:
      'You just removed the last connection from this block to block "{{name}}". Do you also want to remove the connection from block "{{name}}" to this block?',
    chooseBlock_one: 'Choose a block to link to this block "{{name}}"',
    chooseBlock_other: 'Choose blocks to link to this block "{{name}}"',
    blockConnections: 'You can select associated blocks and connections directed to this block.',
    addMissingConnection: 'There is no connection to this block from "{{name}}". You can add one.',
    uniqueConnectionLimitReached: 'This block only has filled unique connections.',
    allBlocks: 'All blocks',
    anyBlockType: 'Any block type',
    metadataOptions_one: '1 option',
    metadataOptions_other: '{{count}} options',
    metadataOptionsWithDefaultValue_one: '1 option, "{{defaultValue}}" by default',
    metadataOptionsWithDefaultValue_other: '{{count}} options, "{{defaultValue}}" by default',
  },
  toast: {
    // FOLDER
    createFolderSuccess: 'The folder was successfully created',
    updateFolderSuccess: 'The folder was successfully updated',
    deleteFolderSuccess: 'The folder was successfully deleted',
    duplicateFolderSuccess: 'The folder was successfully duplicated',
    moveFolderSuccess: 'The folder was successfully moved',
    // BLOCK
    createBlockSuccess: 'The block was successfully created',
    updateBlockSuccess: 'The block was successfully updated',
    deleteBlockSuccess: 'The block was successfully deleted',
    duplicateBlockSuccess: 'The block was successfully duplicated',
    moveBlockSuccess: 'The block was successfully moved',
    // BLOCK TYPE
    createBlockTypeSuccess: 'The block type was successfully created',
    updateBlockTypeSuccess: 'The block type was successfully updated',
    deleteBlockTypeSuccess: 'The block type was successfully deleted',
    duplicateBlockTypeSuccess: 'The block type was successfully duplicated',
    moveBlockTypeSuccess: 'The block type was successfully moved',
    // STANDARD BLOCK
    createStandardBlockSuccess: 'The standard block was successfully created',
    updateStandardBlockSuccess: 'The standard block was successfully updated',
    deleteStandardBlockSuccess: 'The standard block was successfully deleted',
    duplicateStandardBlockSuccess: 'The standard block was successfully duplicated',
    moveStandardBlockSuccess: 'The standard block was successfully moved',
  },
  tooltip: {
    createEntity: 'create an entity',
    seeCalculation: 'See expression',
    createBlock: 'create a block',
    createFolder: 'create a folder',
    createBlockType: 'create a block type',
    createStandardBlock: 'create a standard block',
    addBlock_one: 'Link block',
    addBlock_other: 'Link blocks',
    editBlock_one: 'Edit link',
    editBlock_other: 'Edit links',
    showIncomingConnections: 'Show incoming connections',
    hideIncomingConnections: 'Hide incoming connections',
    blockDoesNotExist: 'Unable to retrieve this block',
    edit: 'Edit',
    delete: 'Delete',
    settings: 'Settings',
    unknownBlockType: 'Unknown block type',
    notWorkingStandardBlockMetric: 'This metric needs unselected parameters without default value to work correctly.',
    notWorkingBlockMetric: 'This metric can not be templated. Be sure that its parameters are correctly set.',
    folderContentLength:
      '$t(lego:label.blockTypesLength, { "count": {{blockTypesLength}} }) / $t(lego:label.standardBlocksLength, { "count": {{standardBlocksLength}} }) / $t(lego:label.foldersLength, { "count": {{foldersLength}} })',
    withOutgoingConnection: 'With outgoing connection',
    withIncomingConnection: 'With incoming connection',
    uniqueConnectionFilled: 'This unique connection is already filled',
    seeBlock: 'See block',
    setAsDefault: 'Set as default',
    unsetAsDefault: 'Unset as default',
  },
  connectionType: {
    [ConnectionType.UNIQUE]: 'Unique',
    [ConnectionType.MULTIPLE]: 'Multiple',
  },
  panelTab: {
    [BlockTypePanelTab.INFORMATIONS]: 'Informations',
    [BlockTypePanelTab.STANDARD_BLOCKS]: 'Standard blocks',
    [StandardBlockPanelTab.BLOCKS]: 'Blocks',
  },
  metadataType: {
    [MetadataType.STRING]: 'String',
    [MetadataType.OPTION]: 'Options list',
    [MetadataType.NUMBER]: 'Value',
  },
  metadataListName: {
    [MetadataListName.STANDARD]: 'Catalog',
    [MetadataListName.BLOCK]: 'Block',
  },
  select: {
    [`metricCategory_${MetricCategory.METRIC}`]: 'Calculation',
    [`metricCategory_${MetricCategory.DISCREPANCY}`]: 'Discrepancy',
    [`metricCategory_${MetricCategory.TECHNICAL}`]: 'Technical',
  },
};
