import { differenceInDays, differenceInHours, differenceInMonths, differenceInWeeks, differenceInYears } from 'date-fns';

import { AUTO_GROUPBY, CalculationVariable, Operator, RawPeriod, Smoothing, timeGroupBy } from '@dametis/core';

import { addLevel } from './helpers';

export const createDefaultSmoothing = (
  operator: Operator = Operator.MEAN,
  period: RawPeriod | undefined = undefined,
): NonNullable<Smoothing> => ({
  groupBy: period ? getSmoothingGroupBy(period) : AUTO_GROUPBY,
  operator,
});

export const applySmoothing = (calcVar: CalculationVariable, smoothing: Smoothing): CalculationVariable =>
  smoothing ? addLevel(calcVar, smoothing) : calcVar;

export const getSmoothingGroupBy = (period?: RawPeriod): timeGroupBy => {
  if (!period) throw new Error();
  const from = new Date(period.from);
  const to = new Date(period.to);
  const diffInYears = differenceInYears(to, from);
  if (diffInYears >= 2) {
    return '1d';
  }
  const diffInMonths = differenceInMonths(to, from);
  if (diffInMonths >= 6) {
    return '12h';
  }
  if (diffInMonths >= 3) {
    return '6h';
  }
  if (diffInMonths >= 1) {
    return '1h';
  }
  const diffInWeeks = differenceInWeeks(to, from);
  if (diffInWeeks >= 2) {
    return '30m';
  }
  if (diffInWeeks >= 1) {
    return '15m';
  }
  const diffInDays = differenceInDays(to, from);
  if (diffInDays >= 3) {
    return '5m';
  }
  if (diffInDays >= 1) {
    return '1m';
  }
  const diffInHours = differenceInHours(to, from);
  if (diffInHours >= 12) {
    return '30s';
  }
  if (diffInHours >= 6) {
    return '15s';
  }
  if (diffInHours >= 3) {
    return '10s';
  }
  return '5s';
};
