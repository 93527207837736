import { v4 as uuidv4 } from 'uuid';

import { EmptyTagUsages, NestedTag } from '@dametis/core';

export const createNestedTag = ({
  uuid = uuidv4(),
  name = '',
  normalizedName = '',
  icon = null,
  color = null,
  parent = null,
  parentId = null,
  usages = EmptyTagUsages(),
  children = [],
}: Partial<NestedTag> = {}): NestedTag => ({
  uuid,
  name,
  normalizedName,
  icon,
  color,
  parent,
  children,
  parentId,
  usages,
});
