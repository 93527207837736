import { ConnectionType, MetadataType, MetricCategory } from '@dametis/core';

import { BlockTypePanelTab } from 'components/Lego/LegoCatalog/CatalogRightPanel/BlockTypeRightPanel/BlockTypeRightPanel';
import { StandardBlockPanelTab } from 'components/Lego/LegoCatalog/CatalogRightPanel/StandardBlockRightPanel/StandardBlockRightPanel';
import { MetadataListName } from 'components/Lego/UI/BlockType/CreateBlockTypeModal/MetadataListsStep';

export default {
  title: {
    lego: 'Schéma-bloc',
    typesCatalog: 'Catalogue',
    createBlockTypeModal: 'Nouveau type de bloc',
    createStandardBlockModal: 'Nouveau bloc standard',
    editBlockTypeModal: 'Modifier le type de bloc',
    blockTypePanel: 'Type de bloc',
    blocks: 'Blocs',
    folders: 'Dossiers',
    createBlockModal: 'Nouveau bloc',
    createFolderModal: 'Nouveau dossier',
    updateBlockModal: 'Modifier le bloc',
    blockFilters: 'Filtrer les paramètres et les métriques',
    nextAction: 'Vous venez de créer un bloc. Que voulez-vous faire maintenant ?',
    createBlock: 'Créer un bloc',
    editBlock: 'Modifier le bloc «\xa0{{blockName}}\xa0»',
    moveTo: 'Déplacer vers...',
    chooseBlock_one: 'Choisissez un bloc',
    chooseBlock_other: 'Choisissez des blocs',
    chooseBlockFromBlockType: 'Choisissez un bloc «\xa0{{blockTypeName}}\xa0»',
    navigation: 'Navigation',
    editFolder: 'Modifier le dossier',
    tools: 'Outils',
    editStandardBlockModal: 'Modifier le bloc standard',
    addIncomingConnections: 'Ajouter des liaisons entrantes',
    removeIncomingConnection: 'Retirer la liaison entrante',
    addMissingConnection: 'Ajouter une liaison entrante',
  },
  subtitle: {
    folders: 'Dossiers',
    blockTypes: 'Types',
    standardBlocks: 'Blocs standards',
  },
  button: {
    add: 'Ajouter',
    linkBlock_one: 'Lier un bloc',
    linkBlock_other: 'Lier des blocs',
    editLink_one: 'Modifier la liaison',
    editLink_other: 'Modifier les liaisons',
    newBlockType: 'Type de bloc',
    newBlock: 'Bloc',
    newFolder: 'Dossier',
    close: 'Fermer',
    cancel: 'Annuler',
    create: 'Créer',
    update: 'Modifier',
    duplicate: 'Dupliquer',
    edit: 'Modifier',
    submit: 'Valider',
    delete: 'Supprimer',
    blocks: 'Blocs',
    goToBlocks: 'Aller aux blocs',
    open: 'Ouvrir',
    openInPlayground: 'Ouvrir dans un playground',
    save: 'Enregistrer',
    sortedBy: 'Trier par : $t(lego:label.sortedBy.{{value}})',
    new: 'Nouveau',
    createBlock: 'Créer un bloc',
    createFolder: 'Dossier',
    moveTo: 'Déplacer vers',
    move: 'Déplacer',
    newStandardBlock: 'Nouveau bloc standard',
    confirm: 'Confirmer',
    select: 'Sélectionner',
    selectNone: 'Tout type de bloc',
  },
  label: {
    name: 'Nom',
    description: 'Description',
    parameters: 'Paramètres',
    metrics: 'Métriques',
    connections: 'Liaisons',
    blockTypes: 'Types de bloc',
    comment: 'Commentaire',
    parametersLength_zero: 'Aucun paramètre',
    parametersLength_one: '{{count}} paramètre',
    parametersLength_other: '{{count}} paramètres',
    metricsLength_zero: 'Aucune métrique',
    metricsLength_one: '{{count}} métrique',
    metricsLength_other: '{{count}} métriques',
    connectionsLength_zero: 'Aucune liaison',
    connectionsLength_one: '{{count}} liaison',
    connectionsLength_other: '{{count}} liaisons',
    standardBlocksLength_zero: 'Aucun bloc standard',
    standardBlocksLength_one: '{{count}} bloc standard',
    standardBlocksLength_other: '{{count}} blocs standards',
    blockTypesLength_zero: 'Aucun type',
    blockTypesLength_one: '{{count}} type',
    blockTypesLength_other: '{{count}} types',
    foldersLength_zero: 'Aucun dossier',
    foldersLength_one: '{{count}} dossier',
    foldersLength_other: '{{count}} dossiers',
    newBlock: 'Nouveau bloc',
    newBlockType: 'Nouveau type',
    newFolder: 'Nouveau dossier',
    tags: 'Tags',
    options: 'Options',
    isVirtual: 'Métrique virtuelle',
    folder: 'Dossier',
    blockType: 'Type de bloc',
    newStandardBlock: 'Nouveau bloc standard',
    standardBlock: 'Bloc standard',
    standardBlocks: 'Blocs standards',
    groupByType: 'Grouper par type',
    metadata: 'Métadonnées',
    standardBlockMetadata: 'Métadonnées de bloc standard',
    blockMetadata: 'Métadonnées de bloc',
    variables: 'Variables',
    [`metricCategory_${MetricCategory.METRIC}`]: 'Calculs',
    [`metricCategory_${MetricCategory.DISCREPANCY}`]: 'Discordances',
    [`metricCategory_${MetricCategory.TECHNICAL}`]: 'Métiers',
    valueType: 'Type de valeur',
    type: 'Type',
    unit: 'Unité',
    defaultValue: 'Valeur par défaut',
  },
  placeholder: {
    name: 'Nom',
    calculation: 'Calcul',
    blockKey: 'Identifiant',
    unit: 'Unité',
    blockType: 'Type de bloc',
    defaultValue: 'Valeur par défaut',
    value: 'Valeur',
    searchStandardBlocks: 'Rechercher des blocs standards...',
    goToBlock: 'Aller au bloc',
  },
  stepper: {
    general: 'Général',
    metrics: 'Métriques',
    parameters: 'Paramètres',
    blockType: 'Type de bloc',
    informations: 'Informations',
    connections: 'Liaisons',
    metadatas: 'Métadonnées',
    parametersAndMetrics: 'Paramètres & métriques',
    variables: 'Variables',
  },
  stepperContent: {
    name: 'Nom : {{name}}',
    standardMetadataListLength_zero: 'Aucune catalogue',
    standardMetadataListLength_one: '1 catalogue',
    standardMetadataListLength_other: '{{count}} catalogue',
    blockMetadataListLength_zero: 'Aucune bloc',
    blockMetadataListLength_one: '1 bloc',
    blockMetadataListLength_other: '{{count}} bloc',
    parametersLength_zero: 'Aucun paramètre',
    parametersLength_one: '1 paramètre',
    parametersLength_other: '{{count}} paramètres',
    connectionsLength_zero: 'Aucune liaison',
    connectionsLength_one: '1 liaison',
    connectionsLength_other: '{{count}} liaisons',
    metricsLength_zero: 'Aucune métrique',
    metricsLength_one: '1 métrique',
    metricsLength_other: '{{count}} métriques',
  },
  text: {
    noBlock: 'Aucun bloc',
    noStandardBlock: 'Aucun bloc standard',
    noParameter: 'Aucun paramètre',
    noMetric: 'Aucun métrique',
    availableParameter: '{{blockKey}} ({{unit}})',
    otherParametersLength: '+{{count}} paramètre(s)',
    otherMetricsLength: '+{{count}} métrique(s)',
    metricsCount_zero: 'Aucun métrique',
    metricsCount_one: 'Un métrique',
    metricsCount_other: '{{count}} métriques',
    openInPlayground: 'Ouvrir dans un playground.',
    blockVariableWithPath: '{{block}} ▸ {{variable}}',
    unusedParameters: 'Paramètres inutilisés :',
    noMetricWithHelper: "Aucun métrique n'est disponible pour ce bloc. Vous pouvez ajouter des paramètres pour utiliser plus de métriques.",
    parameterInvalid_one: 'Ce paramètre est requis pour le fonctionnement du bloc',
    parameterInvalid_other: 'Ces paramètres sont requis pour le fonctionnement du bloc',
    parameterUndefined_one: "Ce paramètre n'est pas requis mais n'est pas défini",
    parameterUndefined_other: 'Ces paramètres ne sont pas requis mais ne sont pas définis',
    parameterUnsetVnc: `Ce paramètre n'a pas été défini`,
    home: 'Accueil',
    factory: 'Usine',
    noFolder: 'Aucun dossier',
    noParameterToDefine: 'Aucun paramètre à définir',
    copy: 'Copie',
    noConnection: 'Aucune liaison',
    noConnectedBlock: 'Aucun bloc lié',
    noMetadata: 'Aucune métadonnée',
    noOption: 'Aucune option disponible',
    noSelectedVariable: 'Aucune variable sélectionnée',
    connectionName: 'Liaison « {{name}} »',
    unknownBlock: 'Bloc inconnu',
    unknownType: 'Type inconnu',
    noAvailableBlock: 'Aucun bloc disponible',
    connectedBlocks: 'Blocs liés',
    childBlocks: 'Blocs enfants',
    emptyFolder: 'Dossier vide',
    noFolderAvailable: 'Aucun dossier disponible',
    canNotLoadFolders: 'Impossible de charger les dossiers',
    unknownName: 'Nom inconnu',
    noSelectedOption: 'Aucune option sélectionnée',
    [`noMetrics_${MetricCategory.METRIC}`]: 'Aucun calcul',
    [`noMetrics_${MetricCategory.DISCREPANCY}`]: 'Aucune discordance',
    [`noMetrics_${MetricCategory.TECHNICAL}`]: 'Aucune métier',
    noElement: 'Aucun élément',
    deleteBlockTypeWarning_one:
      'Ce type de bloc est lié à un autre type de bloc. Sa suppression peut affecter le bon fonctionnement de certains outils.',
    deleteBlockTypeWarning_other:
      'Ce type de bloc est lié à {{count}} autres types de bloc. Sa suppression peut affecter le bon fonctionnement de certains outils.',
    deleteChildrenWarning_one: 'Ce bloc a un bloc enfant. Il sera supprimé.',
    deleteChildrenWarning_other: 'Ce bloc a {{count}} blocs enfants. Ils seront supprimés.',
    deleteConnectedWarning_one: 'Ce bloc est lié à un autre bloc. Sa suppression peut affecter le bon fonctionnement de certains outils.',
    deleteConnectedWarning_other:
      'Ce bloc est lié à {{count}} autres blocs. Sa suppression peut affecter le bon fonctionnement de certains outils.',
    addIncomingConnections:
      "Vous venez d'ajouter la première liaison de ce bloc vers le bloc «\xa0{{name}}\xa0». Sélectionnez les liaisons du bloc «\xa0{{name}}\xa0» vers ce bloc.",
    removeIncomingConnection:
      'Vous venez de retirer la dernière liaison de ce bloc vers le bloc «\xa0{{name}}\xa0». Voulez-vous également retirer la liaison du bloc «\xa0{{name}}\xa0» vers ce bloc ?',
    chooseBlock_one: 'Choisissez un bloc à lier à ce bloc «\xa0{{name}}\xa0»',
    chooseBlock_other: 'Choisissez des blocs à lier à ce bloc «\xa0{{name}}\xa0»',
    blockConnections: 'Vous pouvez sélectionner les blocs associés et les liaisons dirigées vers ce bloc.',
    addMissingConnection: "Il n'y a pas de liaison vers ce bloc depuis «\xa0{{name}}\xa0». Vous pouvez en ajouter une.",
    uniqueConnectionLimitReached: 'Ce bloc possède uniquement des liaisons uniques remplies.',
    allBlocks: 'Tous les blocs',
    anyBlockType: 'Tout type de bloc',
    metadataOptions_one: '1 option',
    metadataOptions_other: '{{count}} options',
    metadataOptionsWithDefaultValue_one: '1 option, "{{defaultValue}}" par défaut',
    metadataOptionsWithDefaultValue_other: '{{count}} options, "{{defaultValue}}" par défaut',
  },
  toast: {
    // FOLDER
    createFolderSuccess: 'Le dossier a bien été créé',
    updateFolderSuccess: 'Le dossier a bien été mis à jour',
    deleteFolderSuccess: 'Le dossier a bien été supprimé',
    duplicateFolderSuccess: 'Le dossier a bien été dupliqué',
    moveFolderSuccess: 'Le dossier a bien été déplacé',
    // BLOCK
    createBlockSuccess: 'Le bloc a bien été créé',
    updateBlockSuccess: 'Le bloc a bien été mis à jour',
    deleteBlockSuccess: 'Le bloc a bien été supprimé',
    duplicateBlockSuccess: 'Le bloc a bien été dupliqué',
    moveBlockSuccess: 'Le bloc a bien été déplacé',
    // BLOCK TYPE
    createBlockTypeSuccess: 'Le type de bloc a bien été créé',
    updateBlockTypeSuccess: 'Le type de bloc a bien été mis à jour',
    deleteBlockTypeSuccess: 'Le type de bloc a bien été supprimé',
    duplicateBlockTypeSuccess: 'Le type de bloc a bien été dupliqué',
    moveBlockTypeSuccess: 'Le type de bloc a bien été déplacé',
    // STANDARD BLOCK
    createStandardBlockSuccess: 'Le bloc standard a bien été créé',
    updateStandardBlockSuccess: 'Le bloc standard a bien été mis à jour',
    deleteStandardBlockSuccess: 'Le bloc standard a bien été supprimé',
    duplicateStandardBlockSuccess: 'Le bloc standard a bien été dupliqué',
    moveStandardBlockSuccess: 'Le bloc standard a bien été déplacé',
  },
  tooltip: {
    createEntity: 'créer une entité',
    seeCalculation: "Voir l'expression",
    createBlock: 'créer un bloc',
    createFolder: 'créer un dossier',
    createBlockType: 'créer un type de bloc',
    createStandardBlock: 'créer un bloc standard',
    addBlock_one: 'Lier un bloc',
    addBlock_other: 'Lier des blocs',
    editBlock_one: 'Modifier la liaison',
    editBlock_other: 'Modifier les liaisons',
    showIncomingConnections: 'Afficher les liaisons entrantes',
    hideIncomingConnections: 'Masquer les liaisons entrantes',
    blockDoesNotExist: 'Impossible de récupérer ce bloc',
    edit: 'Modifier',
    delete: 'Supprimer',
    settings: 'Réglages',
    unknownBlockType: 'Type de bloc inconnu',
    notWorkingStandardBlockMetric:
      'Cette métrique nécessite certains paramètres non sélectionnés et sans valeur par défaut pour fonctionner.',
    notWorkingBlockMetric: "Cette métrique n'a pu être générée. Assurez vous que les paramètres dont elle dépend sont bien définis",
    folderContentLength:
      '$t(lego:label.blockTypesLength, { "count": {{blockTypesLength}} }) / $t(lego:label.standardBlocksLength, { "count": {{standardBlocksLength}} }) / $t(lego:label.foldersLength, { "count": {{foldersLength}} })',
    withOutgoingConnection: 'Avec liaison sortante',
    withIncomingConnection: 'Avec liaison entrante',
    uniqueConnectionFilled: 'Cette connexion unique est déjà remplie',
    seeBlock: 'Voir le bloc',
    setAsDefault: 'Mettre par défaut',
    unsetAsDefault: 'Ne plus mettre par défaut',
  },
  connectionType: {
    [ConnectionType.UNIQUE]: 'Unique',
    [ConnectionType.MULTIPLE]: 'Multiple',
  },
  panelTab: {
    [BlockTypePanelTab.INFORMATIONS]: 'Informations',
    [BlockTypePanelTab.STANDARD_BLOCKS]: 'Blocs standards',
    [StandardBlockPanelTab.BLOCKS]: 'Blocs',
  },
  metadataType: {
    [MetadataType.STRING]: 'Chaîne de caractère',
    [MetadataType.OPTION]: 'Liste à choix',
    [MetadataType.NUMBER]: 'Valeur',
  },
  metadataListName: {
    [MetadataListName.STANDARD]: 'Catalogue',
    [MetadataListName.BLOCK]: 'Bloc',
  },
  select: {
    [`metricCategory_${MetricCategory.METRIC}`]: 'Calcul',
    [`metricCategory_${MetricCategory.DISCREPANCY}`]: 'Discordance',
    [`metricCategory_${MetricCategory.TECHNICAL}`]: 'Métier',
  },
};
