import { Button, Stack } from '@mui/material';
import { FC, MouseEventHandler, useCallback } from 'react';

import { TagUsagesInfo, TagUsagesKey, UUID } from '@dametis/core';

import { TagTab } from 'types/tags';
import { useTagEditStore } from 'zustand/stores/tagEdit';

import ItemUsageLabel from './ItemUsageLabel';

export interface ItemUsagesButtonProps {
  tagId: UUID;
  usages: TagUsagesInfo;
}

const ItemUsagesButton: FC<ItemUsagesButtonProps> = ({ tagId, usages }) => {
  const setSelectedTagIds = useTagEditStore(state => state.setSelectedTagIds);

  const handleOpenDependencies = useCallback<MouseEventHandler<HTMLButtonElement>>(
    event => {
      event.stopPropagation();
      setSelectedTagIds([tagId], TagTab.DEPENDENCIES);
    },
    [setSelectedTagIds, tagId],
  );

  return (
    <Button size="small" sx={{ minWidth: 'auto' }} onClick={handleOpenDependencies}>
      <Stack alignItems="center" direction="row" spacing={1}>
        {Object.entries(usages).map(([entityType, entities]) => (
          <ItemUsageLabel key={entityType} count={entities.length} usageKey={entityType as TagUsagesKey} />
        ))}
      </Stack>
    </Button>
  );
};

export default ItemUsagesButton;
