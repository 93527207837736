import { EntitiesGroupsView } from 'components/UI/Entities/EntitiesGroups/EntitiesGroupsViewPicker/useEntitiesGroupsViewLocalStorage';
import { EntityType } from 'types/entity';

export default {
  title: {
    favoriteEntities: 'My favorites',
    myEntities: {
      [EntityType.ALARM]: 'My alarms',
      [EntityType.PLAYGROUND]: 'My playgrounds',
      [EntityType.PROJECT]: 'My projects',
      [EntityType.REPORT]: 'My reports',
      [EntityType.SYNOPTIC]: 'My synoptics',
    },
    sharedEntities: 'Shared',
    privateEntities: 'Private',
    untaggedEntities: 'Without tag',
    quickAccess: 'Quick access',
    filters: 'Filters',
    groupBy: 'Group by',
    sortBy: 'Sort by',
    settings: 'Settings',
    leftDrawer: 'Filters',
    layout: 'View',
    rightDrawer: 'Quick access',
    noTags: 'Without tag',
    noOwner: 'Without owner',
  },
  groupBy: {
    all: 'All',
    sharings: 'Sharings',
    tags: 'Tags',
    owner: 'Owner',
  },
  sortBy: {
    nameAsc: 'Name A-Z',
    nameDesc: 'Name Z-A',
    createdAtAsc: 'Oldly created',
    createdAtDesc: 'Recently created',
    updatedAtAsc: 'Oldly updated',
    updatedAtDesc: 'Recently updated',
  },
  placeholder: {
    groupBy: 'Group by',
    sortBy: 'Sort by',
    owners: 'Search user...',
  },
  tooltip: {
    expandAll: 'Expand all',
    collapseAll: 'Collapse all',
    hideFilters: 'Hide filters',
    displayFilters: 'Display filters',
    open: 'Open',
    close: 'Close',
    sortByOrder: {
      asc: 'Ascending',
      desc: 'Descending',
    },
    reset: 'Reset',
  },
  text: {
    createdBy: 'Created by {{name}}',
    updatedAt: 'Updated {{date}}',
    noEntity: 'No element',
    loading: 'Loading',
    emptyGroup: 'No entities in this group',
    noResults: 'Your search returned no results',
    noShortcuts: 'No shortcuts',
  },
  button: {
    backToTop: 'Top of the page',
    closeLeftPanel: 'Close filters',
    openLeftPanel: 'Filters',
    leftPanel: 'Filters',
    closeRightPanel: 'Close quick access',
    openRightPanel: 'Quick access',
    rightPanel: 'Quick access',
    expandAll: 'Expand all',
    collapseAll: 'Collapse all',
    reset: 'Reset',
  },
  label: {
    search: 'Search',
    tags: 'Tags',
    groupBy: 'Group by',
    sortBy: 'Sort by',
    allEntities: 'All entities',
    me: 'Me',
    owners: 'Owner',
    favorites: 'Favorites',
  },
  view: {
    [EntitiesGroupsView.TILES]: 'Tiles',
    [EntitiesGroupsView.GRID]: 'Grid',
  },
  toast: {
    defaultGridViewChanged: 'Default view changed.',
  },
};
