import { CachedOutlined, InfoOutlined, TroubleshootOutlined } from '@mui/icons-material';
import {
  Alert,
  Button,
  CircularProgress,
  Divider,
  Grid2,
  IconButton,
  MenuItem,
  Popover,
  Select,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from '@mui/material';
import { intlFormatDistance } from 'date-fns';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { HealthStatus, StatPeriod, VariableKind } from '@dametis/core';

import { Dametis } from 'assets/icons/Dametis';
import { Damientis } from 'assets/icons/Damientis';
import Counter from 'components/UI/Counter/Counter';
import TypographyEllipse from 'components/UI/TypographyEllipse/TypographyEllipse';
import { getFormattedValue } from 'components/UI/UnitPicker/functions/getFormattedValue';
import { getLocale } from 'localization';
import { useDispatch, useSelector } from 'store';
import { fetchHealth } from 'store/actions/health';
import { fetchStats } from 'store/actions/stats';

import HealthDetailsModal from './HealthDetailsModal';
import StatsDetailsModal from './StatsDetailsModal';

const HealthPopper: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const health = useSelector(state => state.auth.siteHealth);
  const loading = useSelector(state => state.auth.siteHealth.loading);
  const stats = useSelector(state => state.auth.archStats);
  const variables = useSelector(state => state.variables.byId);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [healthDetailsOpen, setHealthDetailOpen] = useState<boolean>(false);
  const [statsDetailsOpen, setStatsDetailOpen] = useState<boolean>(false);
  const [statsPeriod, setStatsPeriod] = useState<StatPeriod>(StatPeriod.speed1m);

  const displayDamientis = useMemo(() => process.env.NODE_ENV === 'development', []);
  const open = useMemo(() => anchorEl !== null, [anchorEl]);
  const variableCount: Record<VariableKind | 'total', number> = useMemo(
    () =>
      Object.values(variables).reduce((acc, current) => ({ ...acc, [current.kind]: (acc[current.kind] ?? 0) + 1, total: acc.total + 1 }), {
        total: 0,
        [VariableKind.CALCULATED]: 0,
        [VariableKind.CONSTANT]: 0,
        [VariableKind.MANUAL]: 0,
        [VariableKind.REAL]: 0,
      }),
    [variables],
  );
  const statsValues = useMemo<{
    data: string;
    previousData: string;
    sync: string;
    heartbeat: string;
  } | null>(() => {
    if (stats.dataManager === null) {
      return null;
    }

    return {
      data: getFormattedValue({
        value: Object.values(stats.dataManager.data).reduce((acc, value) => {
          return acc + value[statsPeriod];
        }, 0),
      }),
      previousData: getFormattedValue({
        value: Object.values(stats.dataManager.previousData).reduce((acc, value) => {
          return acc + value[statsPeriod];
        }, 0),
      }),
      sync: getFormattedValue({
        value: Object.values(stats.dataManager.sync).reduce((acc, value) => {
          return acc + value[statsPeriod];
        }, 0),
      }),
      heartbeat: getFormattedValue({
        value: stats.dataManager.heartbeat[statsPeriod],
      }),
    };
  }, [stats.dataManager, statsPeriod]);

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleOpenHealthDetails = useCallback(() => {
    setHealthDetailOpen(true);
  }, []);

  const handleCloseHealthDetails = useCallback(() => {
    setHealthDetailOpen(false);
  }, []);

  const handleOpenStatsDetails = useCallback(() => {
    setStatsDetailOpen(true);
  }, []);

  const handleCloseStatsDetails = useCallback(() => {
    setStatsDetailOpen(false);
  }, []);

  const handleRefresh = useCallback(async () => {
    await dispatch(fetchHealth(true));
    await dispatch(fetchStats());
  }, [dispatch]);

  return (
    <>
      {health.health !== null && <HealthDetailsModal health={health.health} open={healthDetailsOpen} onClose={handleCloseHealthDetails} />}
      {stats.dataManager !== null && (
        <StatsDetailsModal
          open={statsDetailsOpen}
          setStatsPeriod={setStatsPeriod}
          stats={stats.dataManager}
          statsPeriod={statsPeriod}
          onClose={handleCloseStatsDetails}
        />
      )}
      <Tooltip title={t('global:text.health.title')}>
        <Button
          color="icon"
          sx={{
            minWidth: 'unset',
            p: 1,
            color: theme => (health?.health?.healthStatus === HealthStatus.HEALTHY ? undefined : theme.palette.warning.light),
          }}
          onClick={handleClick}
        >
          {loading ? <CircularProgress size={24} /> : <InfoOutlined color="inherit" />}
        </Button>
      </Tooltip>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}
      >
        <Stack direction="column" divider={<Divider flexItem orientation="horizontal" />} p={2} spacing={2} width={360}>
          <Stack alignItems="center" direction="row" justifyContent="flex-start" spacing={1}>
            <SvgIcon component={displayDamientis ? Damientis : Dametis} fontSize="medium" />
            <Typography variant="h4">{displayDamientis ? `MyDamientis` : `MyDametis`}</Typography>
          </Stack>
          <Stack direction="column" spacing={1}>
            <Alert severity={health.health === null || health.health.healthStatus !== HealthStatus.HEALTHY ? 'warning' : 'success'}>
              {health.health === null || health.health.healthStatus !== HealthStatus.HEALTHY
                ? t('global:text.health.systemsUnhealthy')
                : t('global:text.health.systemsHealthy')}
            </Alert>
            <Stack alignItems="center" direction="row" justifyContent="space-between">
              <Typography variant="caption">
                {health.health &&
                  t('global:text.health.lastChecked', {
                    duration: intlFormatDistance(health.health.lastChecked, new Date(), { numeric: 'always', locale: getLocale().code }),
                  })}
              </Typography>
              <Stack direction="row">
                <Tooltip title={t('tooltip.refresh')}>
                  <IconButton size="small" onClick={handleRefresh}>
                    {loading ? <CircularProgress size={20} /> : <CachedOutlined fontSize="small" />}
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('tooltip.details')}>
                  <IconButton size="small" onClick={handleOpenHealthDetails}>
                    <TroubleshootOutlined fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
          </Stack>

          <Stack direction="column" spacing={1}>
            <Typography variant="h5">
              {t('variables:title.variables')} <Counter count={variableCount.total} />
            </Typography>
            <Grid2 container spacing={2}>
              <Grid2 size={3}>
                <Typography>{variableCount[VariableKind.REAL]}</Typography>
                <Typography variant="body2">
                  {t(`variables:kind.${VariableKind.REAL}`, { count: variableCount[VariableKind.REAL] })}
                </Typography>
              </Grid2>
              <Grid2 size={3}>
                <Typography>{variableCount[VariableKind.CALCULATED]}</Typography>
                <Typography variant="body2">
                  {t(`variables:kind.${VariableKind.CALCULATED}`, { count: variableCount[VariableKind.CALCULATED] })}
                </Typography>
              </Grid2>
              <Grid2 size={3}>
                <Typography>{variableCount[VariableKind.CONSTANT]}</Typography>
                <Typography variant="body2">
                  {t(`variables:kind.${VariableKind.CONSTANT}`, { count: variableCount[VariableKind.CONSTANT] })}
                </Typography>
              </Grid2>
              <Grid2 size={3}>
                <Typography>{variableCount[VariableKind.MANUAL]}</Typography>
                <Typography variant="body2">
                  {t(`variables:kind.${VariableKind.MANUAL}`, { count: variableCount[VariableKind.MANUAL] })}
                </Typography>
              </Grid2>
            </Grid2>
          </Stack>

          <Stack direction="column" spacing={1}>
            <Stack alignItems="center" direction="row" justifyContent="space-between">
              <Typography variant="h5">{t('stats:title.stats')}</Typography>
              <Select<StatPeriod>
                disableUnderline
                value={statsPeriod}
                variant="standard"
                onChange={event => {
                  setStatsPeriod(event.target.value as StatPeriod);
                }}
              >
                <MenuItem value={StatPeriod.speed1m}>1m</MenuItem>
                <MenuItem value={StatPeriod.speed5m}>5m</MenuItem>
                <MenuItem value={StatPeriod.speed15m}>15m</MenuItem>
                <MenuItem value={StatPeriod.total24h}>24h</MenuItem>
              </Select>
            </Stack>

            <Button
              disabled={stats.dataManager === null}
              sx={{ padding: 0.5, margin: -0.5, textTransform: 'none', textAlign: 'initial' }}
              onClick={handleOpenStatsDetails}
            >
              <Grid2 container spacing={2} width="100%">
                <Grid2 size={3}>
                  <Typography variant="body2">{t(`stats:label.data`)}</Typography>
                  <Stack alignItems="baseline" direction="row" flexWrap="wrap" spacing={0.25}>
                    <TypographyEllipse>{statsValues?.data ?? '-'}</TypographyEllipse>
                    {statsPeriod !== StatPeriod.total24h && <Typography variant="caption">{t('stats:label.unit')}</Typography>}
                  </Stack>
                </Grid2>
                <Grid2 size={3}>
                  <Typography variant="body2">{t(`stats:label.previousData`)}</Typography>
                  <Stack alignItems="baseline" direction="row" flexWrap="wrap" spacing={0.25}>
                    <TypographyEllipse>{statsValues?.previousData ?? '-'}</TypographyEllipse>
                    {statsPeriod !== StatPeriod.total24h && <Typography variant="caption">{t('stats:label.unit')}</Typography>}
                  </Stack>
                </Grid2>
                <Grid2 size={3}>
                  <Typography variant="body2">{t(`stats:label.sync`)}</Typography>
                  <Stack alignItems="baseline" direction="row" flexWrap="wrap" spacing={0.25}>
                    <TypographyEllipse>{statsValues?.sync ?? '-'}</TypographyEllipse>
                    {statsPeriod !== StatPeriod.total24h && <Typography variant="caption">{t('stats:label.unit')}</Typography>}
                  </Stack>
                </Grid2>
                <Grid2 size={3}>
                  <Typography variant="body2">{t(`stats:label.heartbeat`)}</Typography>
                  <Stack alignItems="baseline" direction="row" flexWrap="wrap" spacing={0.25}>
                    <TypographyEllipse>{statsValues?.heartbeat ?? '-'}</TypographyEllipse>
                    {statsPeriod !== StatPeriod.total24h && <Typography variant="caption">{t('stats:label.unit')}</Typography>}
                  </Stack>
                </Grid2>
              </Grid2>
            </Button>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};

export default HealthPopper;
