import { AddOutlined } from '@mui/icons-material';
import { Divider, InputLabel, Stack, Typography } from '@mui/material';
import { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockTypeMetadata, UUID } from '@dametis/core';

import { createBlockTypeMetadata } from 'components/Lego/helpers/blockType/createBlockTypeMetadata';
import ActionButton from 'components/UI/Buttons/ActionButton/ActionButton';

import MetadataForm from './MetadataForm';

export enum MetadataListName {
  STANDARD = 'standard',
  BLOCK = 'block',
}

export interface MetadataListsStepProps {
  metadataList: BlockTypeMetadata[];
  setMetadataList: Dispatch<SetStateAction<BlockTypeMetadata[]>>;
  listNameByMetadataId: Record<UUID, MetadataListName>;
  setListNameByMetadataId: Dispatch<SetStateAction<Record<UUID, MetadataListName>>>;
}

const MetadataListsStep: FC<MetadataListsStepProps> = ({
  metadataList,
  setMetadataList,
  listNameByMetadataId,
  setListNameByMetadataId,
}) => {
  const { t } = useTranslation('lego');

  const handleAddMetadata = useCallback(() => {
    const newMetadata = createBlockTypeMetadata();
    setMetadataList(state => [...state, newMetadata]);
    setListNameByMetadataId(state => ({ ...state, [newMetadata.uuid]: MetadataListName.STANDARD }));
  }, [setMetadataList, setListNameByMetadataId]);

  const handleDeleteMetadata = useCallback(
    (metadataIndex: number, metadataId: UUID) => () => {
      setMetadataList(state => state.filter((_metadata, index) => index !== metadataIndex));
      setListNameByMetadataId(state => {
        const { [metadataId]: removed, ...newState } = state;
        return newState;
      });
    },
    [setMetadataList, setListNameByMetadataId],
  );

  const handleChangeMetadata = useCallback(
    (metadataIndex: number) => (newValue: BlockTypeMetadata) => {
      setMetadataList(state => state.map((metadata, index) => (index === metadataIndex ? newValue : metadata)));
    },
    [setMetadataList],
  );

  const handleChangeListName = useCallback(
    (metadataId: UUID) => (newListName: MetadataListName) => {
      setListNameByMetadataId(state => ({
        ...state,
        [metadataId]: newListName,
      }));
    },
    [setListNameByMetadataId],
  );

  return (
    <Stack gap={1} width={1}>
      <Stack alignItems="flex-start" direction="row" justifyContent="space-between">
        <InputLabel>{t('label.metadata')}</InputLabel>
        <ActionButton startIcon={<AddOutlined />} onClick={handleAddMetadata}>
          {t('button.add')}
        </ActionButton>
      </Stack>
      {metadataList.length > 0 && (
        <Stack gap={1}>
          {metadataList.map((metadata, index) => (
            <>
              <MetadataForm
                key={metadata.uuid}
                listName={listNameByMetadataId[metadata.uuid]}
                value={metadata}
                onChange={handleChangeMetadata(index)}
                onChangeListName={handleChangeListName(metadata.uuid)}
                onDelete={handleDeleteMetadata(index, metadata.uuid)}
              />
              {index !== metadataList.length - 1 && <Divider />}
            </>
          ))}
        </Stack>
      )}
      {metadataList.length === 0 && (
        <Typography align="center" p={2} variant="subtitle2">
          {t('text.noMetadata')}
        </Typography>
      )}
    </Stack>
  );
};

export default MetadataListsStep;
