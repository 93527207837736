import { ProjectTemplateCategoryType, ProjectTemplateFieldType, RatingType, SelectType } from '@dametis/core';

export default {
  title: {
    template: 'My templates',
    editForm: 'Edit template',
    newTemplate: 'New template',
    duplicateTemplate: 'Duplicate template',
    createTemplateModal: 'New template',
    editTemplateModal: 'Edit template',
  },
  button: {
    newTemplate: 'Template',
    create: 'Create',
    save: 'Save',
    share: 'Share',
    edit: 'Edit',
    duplicate: 'Duplicate',
    cancel: 'Cancel',
    continue: 'Continue',
    delete: 'Delete',
    add: 'Add',
    close: 'Close',
    next: 'Next',
    previous: 'Previous',
    done: 'Done',
    goToProjects: 'See related projets',
    addCriteria: 'Criterion',
    addThreshold: 'Threshold',
    addThresholdRange: 'Add a threshold range.',
  },
  label: {
    name: 'Name',
    description: 'Description',
    fields: 'Fields',
    categories: 'Sections',
    fieldType: {
      [ProjectTemplateFieldType.STRING]: 'Simple text',
      [ProjectTemplateFieldType.TEXTAREA]: 'Textarea',
      [ProjectTemplateFieldType.NUMBER]: 'Number',
      [ProjectTemplateFieldType.DATE]: 'Date',
      [ProjectTemplateFieldType.SELECT]: 'Choice select',
      // [ProjectTemplateFieldType.PICTOPICKER]: 'Pictogram picker',
    },
    inputSpecs: {
      min: 'Minimum',
      max: 'Maximum',
      from: 'Min date',
      to: 'Max date',
    },
    ratingOptions: 'Options',
    ratingType: {
      [`variant_${RatingType.OPEN_GRADE}`]: 'Grade',
      [`variant_${RatingType.WEIGHTED_CRITERION}`]: 'Weighted criterion',
    },
    score: 'Score',
    formula: 'Score formula',
    maxScore: 'Global score =',
    selectType: {
      [`variant_${SelectType.DROPDOWN}`]: 'Dropdown list',
      [`variant_${SelectType.FLAT}`]: 'Flat list',
    },
    multipleChoice: 'Allow multiple choice',
    relatedProjects: 'Related projects ({{count}})',
    thresholds: {
      min: 'From',
      max: 'To',
      thresholdValue: 'Threshold label',
    },
  },
  placeholder: {
    name: 'Name',
    number: 'Number ',
    options: 'Options',
    option: 'Option',
    text: 'Text',
    statusStep: 'Step ',
    taskStatus: 'Status ',
    rating: {
      name: 'Name',
      min: 'Min',
      max: 'Max',
      importance: 'Importance',
      options: 'Example: Low, Medium, High',
    },
  },
  text: {
    addFirstTemplate: 'Add first template',
    preview: 'Preview',
    options: 'Options',
    option: 'Option',
    value: 'value',
    settingsDone: 'Settings done',
    globalGrade: 'Global grade = ',
    thresholds: 'Thresholds',
    criteria: 'Criteria',
    noCriteria: 'No criterion',
    noThreshold: 'No threshold',
    noEditIfProjects: 'You cannot edit the template because it has related projects.',
    noDeleteIfProjects: 'You cannot delete the template because it has related projects.',
    overlap: 'Thresholds intersect, this threshold will not be considered.',
  },
  stepper: {
    layout: 'Sections',
    settings: 'Settings',
  },
  helperText: {
    [`variant_${ProjectTemplateCategoryType.TASKS}`]: "Define the available statuses to follow the progress of a project's tasks.",
    [`variant_${ProjectTemplateCategoryType.STATUS}`]: 'Define the milestones of your projects.',
    [`variant_${ProjectTemplateCategoryType.RATING}`]: 'Define rating criteria for your projects.',
    [`variant_${RatingType.WEIGHTED_CRITERION}`]: 'Defined options from the lowest to the highest impact, separated by commas.',
  },
  tooltip: {
    datePick: 'Pick a date',
    clear: 'Clear',
    deleteCustomField: 'Delete field',
    deleteOption: 'Delete option',
    deleteCriteria: 'Delete criteria',
    deleteThreshold: 'Delete threshold',
    deleteStatusStep: 'Delete step',
    statusLimit: 'You have reached the maximum of 6 statuses.',
    taskStatusLimit: 'You have reached the maximum of 4 tasks statuses.',
  },
  toast: {
    duplicateSuccess: 'Template duplicated successfully.',
    createSuccess: 'Template created successfully.',
    editSuccess: 'Template edited successfully.',
    deleteSuccess: 'Template deleted successfully.',
  },
};
