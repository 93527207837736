import { GridView } from 'types/grid';

export default {
  title: {
    playgrounds: 'Playgrounds',
    myPlaygrounds: 'Mes playgrounds',
    sharedPlaygrounds: 'Partagés avec moi',
    privatePlaygrounds: 'Privés',
    newPlayground: 'Nouveau playground',
    editPlayground: 'Modifier le playground',
    duplicatePlayground: 'Dupliquer le playground',
    createTemplate: 'Nouveau modèle de playground',
    editTemplate: 'Modifier le modèle de playground',
  },
  button: {
    newPlayground: 'Playground',
    cancel: 'Annuler',
    create: 'Créer',
    save: 'Enregistrer',
    edit: 'Modifier',
    delete: 'Supprimer',
    duplicate: 'Dupliquer',
    share: 'Partager',
    editTags: 'Éditer les tags',
    createPlayground: 'Créez un playground vierge',
  },
  text: {
    tag: 'Tags',
    shortCreatedAt: 'Créé',
    shortUpdatedAt: 'Modifié',
    updatedAt: 'Modifié {{date}}',
    me: 'moi',
    sharedBy: 'Partagé par {{name}}',
    ownedBy: 'Appartient à {{name}}',
    noPlaygrounds: "Vous n'avez pas encore de playground",
    copy: 'Copie',
    name: 'Nom',
    owner: 'Propriétaire',
    tabs: 'Onglets',
    public: 'Public',
    private: 'Privé',
    shared: 'Partagé',
    view: {
      [GridView.TILE]: 'Vue tuile',
      [GridView.LIST]: 'Vue liste',
    },
    duplicated: ' - Copie',
    allPlaygrounds: 'Tous les playgrounds',
  },
  input: {
    label: {
      newPlayground: 'Nom',
      type: 'Type',
      tags: 'Tags',
    },
  },
  form: {
    label: {
      confidentiality: 'Confidentialité',
      applyToAllBlocks: 'Appliquer ce modèle à...',
    },
    value: {
      confidentiality_private: 'Privé',
      confidentiality_shared: 'Partagé',
      applyToAllBlocks_one: 'Uniquement le bloc',
      applyToAllBlocks_all: 'Tous les blocs du type du bloc',
    },
    tooltip: {
      owner_shared: 'Si cette option est cochée, ce playground sera visible par les autres utilisateurs',
      owner_private: 'Si cette option est cochée, ce playground sera seulement visible par vous ',
      not_owner: "Vous ne pouvez pas changer la confidentialité de ce playground car vous n'en êtes pas le propriétaire",
    },
  },
  tooltip: {
    createPlayground: 'créer un playground',
    renamePlayground: 'renommer le playground',
    editTags: 'modifier les tags du playground',
    duplicatePlayground: 'modifier le playground',
    sharePlayground: 'partager le playground',
    deletePlayground: 'supprimer le playground',
  },
  toast: {
    create: {
      success: 'Le playground a bien été créé.',
      fail: 'Une erreur est survenue durant la création du playground.',
    },
    save: {
      success: 'Le playground a bien été enregistré.',
      fail: 'Une erreur est survenue durant la sauvegarde du playground.',
    },
    delete: {
      success: 'Le playground a bien été supprimé.',
      fail: 'Une erreur est survenue durant la suppression du playground..',
    },
    duplicate: {
      success: 'Le playground a bien été dupliqué.',
      fail: 'Une erreur est survenue durant la duplication du playground.',
    },
  },
};
