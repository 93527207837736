import Tracker, { SanitizeLevel } from '@openreplay/tracker';

import { GroupInfo, SiteInfo, UserInfo } from '@dametis/core';

import { OpenReplayEvent } from './types';

const isDemoHostname = (hostname: string): boolean => {
  const result = hostname.match(/^demo\d*.dametis.com$/);
  return result !== null;
};
const isProdHostname = (hostname: string): boolean => {
  return hostname === 'my.dametis.com';
};

export const openReplayTracker = new Tracker({
  projectKey: isDemoHostname(window.location.hostname) ? 'dE6klkyk8Fky5xGsKE5e' : 'j4Dv6UDzKSGXJBNpfBjZ',
  ingestPoint: `https://${window.location.hostname}/ingest`,
  __DISABLE_SECURE_MODE: window.location.hostname === 'localhost',
  defaultInputMode: 0,
  obscureInputEmails: false,
  obscureTextEmails: false,
  obscureInputDates: false,
  obscureInputNumbers: false,
  domSanitizer: (node: Element) => {
    const type = node.getAttribute('type');
    const elementClassNames = node.classList;

    if (type === 'password' || elementClassNames.contains('password')) {
      return SanitizeLevel.Hidden;
    }

    return SanitizeLevel.Plain;
  },
});
const shouldStartOpenReplay = isProdHostname(window.location.hostname) || isDemoHostname(window.location.hostname);

export const openReplayEvent = (event: OpenReplayEvent, payload?: Record<string, any>): void => {
  if (!openReplayTracker.isActive()) return;
  openReplayTracker.event(event, payload);
};

export const openReplay = async (
  user: UserInfo | null,
  selectedGroup?: GroupInfo | null,
  selectedSite?: SiteInfo | null,
): Promise<void> => {
  if (!shouldStartOpenReplay) return;
  if (!openReplayTracker.isActive()) {
    await openReplayTracker.start();
  }
  if (user !== null && user !== undefined) {
    openReplayTracker.setUserID(user.email);
    openReplayTracker.setMetadata('uuid', user.uuid);
  }
  if (selectedGroup !== null && selectedGroup !== undefined) {
    openReplayTracker.setMetadata('groupName', selectedGroup.name);
  }
  if (selectedSite !== null && selectedSite !== undefined) {
    openReplayTracker.setMetadata('siteName', selectedSite.name);
  }
};
