import { OpcoStatuses } from '@dametis/core';

export default {
  button: {
    cancel: 'Annuler',
    create: 'Créer',
    save: 'Enregistrer',
    yesDelete: 'Oui, supprimer',
    updateSteamMixTemplate: 'Sélectionner le modèle',
    createSteamMix: 'Créer un mix vapeur',
    downloadDataEntryTemplate: "Télécharger le modèle de fichier d'entrée de données",
    downloadFile: 'Télécharger le fichier',
    runCalculation: 'Exécuter le calcul',
    generateReport: 'Générer le rapport',
    regenerateReport: 'Regénérer le rapport',
    openReport: 'Ouvrir le rapport',
    downloadResults: 'Télécharger les résultats',
  },
  input: {
    name: 'Nom',
    year: 'Année',
    pastWeeks: 'Semaines passées',
  },
  select: {
    dataEntryTemplate: 'Sélectionner un modèle prérempli',
    empty: 'Vide',
  },
  text: {
    year: 'Année {{year}}',
    noFilling: "Le fichier d'entrée de données ne sera pas prérempli.",
    filling: "Le fichier d'entrée de données sera prérempli avec les données réelles du {{startAt}} au {{endAt}}.",
    noSteamMixes: 'Aucun mix vapeur',
    steamMixRunning:
      "Calcul en cours, veuillez recharger votre page dans quelques minutes. Pour un modèle annuel, cela prendra environ 2 minutes. Pour un modèle pluriannuel, cela prendra jusqu'à 15 minutes.",
    steamMixError: "Une erreur s'est produite lors de l'exécution du calcul.",
    confirmSteamMixDeletion: 'Êtes-vous sûr de vouloir supprimer {{name}} ?',
    createdAt: 'Créé le {{date}}',
    [`status_${OpcoStatuses.RUNNING}`]: 'En cours',
    [`status_${OpcoStatuses.SUCCESS}`]: 'Terminé',
    [`status_${OpcoStatuses.ERROR}`]: 'Erreur',
    [`status_${OpcoStatuses.WAITING}`]: 'En attente',
  },
  title: {
    steamMixes: 'Mix vapeur',
    addSteamMix: 'Ajouter un mix vapeur',
    downloadDataEntry: "Télécharger le fichier d'entrée de données",
    importDataEntry: "Importer le fichier d'entrée de données",
    steamMix: 'Mix vapeur',
    deleteSteamMix: 'Supprimer le mix vapeur',
    editName: 'Modifier le mix vapeur',
  },
  tooltip: {
    createSteamMix: 'Créer un mix vapeur',
    deleteSteamMix: 'Supprimer le mix vapeur',
    updateSteamMixTemplate: 'Sélectionner le modèle',
    editSteamMix: 'Modifier le mix vapeur',
    saveSteamMix: 'Enregistrer le mix vapeur',
  },
  toast: {
    create: {
      success: 'Le mix vapeur a bien été créé.',
      fail: 'Une erreur est survenue durant la création du mix vapeur.',
    },
    save: {
      success: 'Le mix vapeur a bien été enregistré.',
      fail: 'Une erreur est survenue durant la sauvegarde du mix vapeur.',
    },
    delete: {
      success: 'Le mix vapeur a bien été supprimé.',
      fail: 'Une erreur est survenue durant la suppression du mix vapeur.',
    },
  },
};
