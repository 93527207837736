export default {
  title: {
    stats: 'Point insertion statistics',
    details: 'Detailed statistics',
  },
  label: {
    data: 'Data',
    previousData: 'Past',
    sync: 'Syncs',
    heartbeat: 'HeartBeats',
    unit: '/s',
    period: 'Period',
    name: 'name',
    protocol: 'Protocol',
  },
};
