import {
  AccountTree,
  AccountTreeOutlined,
  Dashboard,
  DashboardOutlined,
  EnergySavingsLeaf,
  EnergySavingsLeafOutlined,
  Home,
  HomeOutlined,
  MonitorHeart,
  MonitorHeartOutlined,
  Notifications,
  NotificationsOutlined,
  Receipt,
  ReceiptOutlined,
  ScatterPlot,
  ScatterPlotOutlined,
  Settings,
  SettingsOutlined,
  Work,
  WorkOutline,
} from '@mui/icons-material';

import { UUID } from '@dametis/core';

import { Blocks } from 'assets/icons/Blocks';
import { BlocksOutlined } from 'assets/icons/BlocksOutlined';
import { Playground } from 'assets/icons/Playground';
import { PlaygroundOutlined } from 'assets/icons/PlaygroundOutlined';
import { SteamMixOutlined } from 'assets/icons/SteamMixOutlined';
import { User } from 'types/auth';
import { DrawerActionType, LastEntityType, Route, RouteScope } from 'types/drawer';

import DrawerActionsList from './SecondaryDrawerContents/DrawerActionsList';
import LastEntitiesList from './SecondaryDrawerContents/LastEntities';
import SubRoutes from './SecondaryDrawerContents/SubRoutes';
import { useLastPlaygrounds, useLastProjects, useLastReports, useLastSynoptics } from './hooks';

export const routes: Route[] = [
  {
    name: 'home',
    icon: HomeOutlined,
    activeIcon: Home,
    url: '/',
    scopes: [RouteScope.SITE, RouteScope.GROUP],
  },
  {
    name: 'lego',
    icon: BlocksOutlined,
    activeIcon: Blocks,
    url: '/lego',
    permission: 'canAccessLego',
    scopes: [RouteScope.SITE],
    Secondary: ({ route }) => (
      <SubRoutes
        route={route}
        subRoutes={[
          {
            name: 'blocks',
            url: '/lego',
            scopes: [RouteScope.SITE],
          },
          {
            name: 'blockTypes',
            url: '/blockTypes',
            scopes: [RouteScope.SITE],
          },
        ]}
      />
    ),
  },
  {
    name: 'synoptics',
    icon: AccountTreeOutlined,
    activeIcon: AccountTree,
    url: '/synoptics',
    permission: 'canAccessSynoptic',
    scopes: [RouteScope.SITE],
    Secondary: ({ route }) => {
      const lastSynoptics = useLastSynoptics();
      return (
        <>
          <SubRoutes
            route={route}
            subRoutes={[
              {
                name: 'synoptics',
                url: '/synoptics',
                touchOnly: true,
                scopes: [RouteScope.SITE],
              },
            ]}
          />
          <LastEntitiesList baseUrl="/synoptics" entities={lastSynoptics} route={route} type={LastEntityType.SYNOPTICS} />
        </>
      );
    },
  },
  {
    name: 'playgrounds',
    icon: PlaygroundOutlined,
    activeIcon: Playground,
    url: '/playgrounds',
    permission: 'canAccessPlayground',
    Secondary: ({ route }) => {
      const lastPlaygrounds = useLastPlaygrounds();
      return (
        <>
          <SubRoutes
            route={route}
            subRoutes={[
              {
                name: 'playgrounds',
                url: '/playgrounds',
                touchOnly: true,
                scopes: [RouteScope.SITE, RouteScope.GROUP],
              },
            ]}
          />
          <LastEntitiesList baseUrl="/playgrounds" entities={lastPlaygrounds} route={route} type={LastEntityType.PLAYGROUNDS} />
          <DrawerActionsList route={route} type={DrawerActionType.PLAYGROUNDS} />
        </>
      );
    },
    scopes: [RouteScope.SITE, RouteScope.GROUP],
  },
  {
    name: 'reports',
    icon: DashboardOutlined,
    activeIcon: Dashboard,
    url: '/reports',
    permission: 'canAccessReport',
    Secondary: ({ route }) => {
      const lastReports = useLastReports();
      return (
        <>
          <SubRoutes
            route={route}
            subRoutes={[
              {
                name: 'reports',
                url: '/reports',
                touchOnly: true,
                scopes: [RouteScope.SITE, RouteScope.GROUP],
              },
            ]}
          />
          <LastEntitiesList baseUrl="/reports" entities={lastReports} route={route} type={LastEntityType.REPORTS} />
          <DrawerActionsList route={route} type={DrawerActionType.REPORTS} />
        </>
      );
    },
    scopes: [RouteScope.SITE, RouteScope.GROUP],
  },
  {
    name: 'energies',
    icon: EnergySavingsLeafOutlined,
    activeIcon: EnergySavingsLeaf,
    url: '/energies',
    permission: 'canAccessEnergy',
    scopes: [RouteScope.SITE],
  },
  {
    name: 'billing',
    icon: ReceiptOutlined,
    activeIcon: Receipt,
    url: '/billing',
    permission: 'canAccessBilling',
    scopes: [RouteScope.SITE],
    Secondary: ({ route }) => (
      <SubRoutes
        route={route}
        subRoutes={[
          {
            name: 'bills',
            url: 'billing/bills',
            scopes: [RouteScope.SITE],
          },
          {
            name: 'contracts',
            url: 'billing/contracts',
            scopes: [RouteScope.SITE],
          },
        ]}
      />
    ),
  },
  {
    name: 'projects',
    icon: WorkOutline,
    activeIcon: Work,
    url: '/projects',
    permission: 'canAccessProject',
    scopes: [RouteScope.SITE, RouteScope.GROUP],
    Secondary: ({ route }) => {
      const lastProjects = useLastProjects();
      return (
        <>
          <SubRoutes
            route={route}
            subRoutes={[
              {
                name: 'projects',
                url: '/projects/list',
                // scopes: [RouteScope.SITE],
                scopes: [RouteScope.SITE, RouteScope.GROUP],
              },
              {
                name: 'template',
                url: '/projects/template',
                permission: 'canEditProjectTemplate',
                scopes: [RouteScope.SITE],
              },
              {
                name: 'tasks',
                url: '/projects/tasks',
                permission: 'canAccessProject',
                scopes: [RouteScope.SITE, RouteScope.GROUP],
              },
            ]}
          />
          <LastEntitiesList baseUrl="/projects" entities={lastProjects} route={route} type={LastEntityType.PROJECTS} />
        </>
      );
    },
  },
  {
    name: 'alarms',
    icon: NotificationsOutlined,
    activeIcon: Notifications,
    url: '/alarms',
    permission: 'canAccessAlarm',
    scopes: [RouteScope.SITE],
    Secondary: ({ route }) => (
      <SubRoutes
        route={route}
        subRoutes={[
          {
            name: 'activeAlarms',
            url: '/alarms/active',
            scopes: [RouteScope.SITE],
          },
          {
            name: 'alarmsHistory',
            url: '/alarms/history',
            scopes: [RouteScope.SITE],
          },
          {
            name: 'settings',
            url: '/alarms/settings',
            scopes: [RouteScope.SITE],
          },
        ]}
      />
    ),
  },
  {
    name: 'configuration',
    icon: SettingsOutlined,
    activeIcon: Settings,
    url: '/configuration',
    permission: 'canAccessConfiguration',
    scopes: [RouteScope.SITE, RouteScope.GROUP],
    Secondary: ({ route }) => (
      <SubRoutes
        route={route}
        subRoutes={[
          {
            name: 'variables',
            url: '/configuration/variables',
            scopes: [RouteScope.SITE, RouteScope.GROUP],
          },
          {
            name: 'devices',
            url: '/configuration/devices',
            scopes: [RouteScope.SITE],
          },
          {
            name: 'boxes',
            url: '/configuration/boxes',
            scopes: [RouteScope.GROUP],
          },
          {
            name: 'group',
            url: '/group',
            check: (user: User, selectedGroupId: UUID) => {
              if (user.acl.HasAnyGlobalRoles(['SITE_ADMIN', 'SUPER_ADMIN', 'EMaaS'])) {
                return true;
              }
              const groupAcl = user.acl.FindGroupAcl(selectedGroupId);
              return groupAcl !== undefined && groupAcl.HasAnyRole(['SITE_ADMIN', 'SUPER_ADMIN', 'EMaaS']);
            },
            scopes: [RouteScope.GROUP],
          },
          {
            name: 'site',
            url: '/site',
            scopes: [RouteScope.SITE],
          },
          {
            name: 'batches',
            url: '/configuration/batches',
            permission: 'canAccessBatch',
            scopes: [RouteScope.SITE],
          },
          {
            name: 'models',
            url: '/models',
            permission: 'canAccessModel',
            scopes: [RouteScope.SITE],
          },
          {
            name: 'tags',
            url: '/configuration/tags',
            permission: 'canAccessTag',
            scopes: [RouteScope.SITE],
          },
        ]}
      />
    ),
  },
  {
    name: 'data',
    icon: ScatterPlotOutlined,
    activeIcon: ScatterPlot,
    url: '/data',
    // permission: 'canAccessData',
    // permission: 'canEditConfiguration',
    scopes: [RouteScope.SITE],
    Secondary: ({ route }) => (
      <SubRoutes
        route={route}
        subRoutes={[
          {
            name: 'fileImport',
            url: '/data/import',
            permission: 'canEditConfiguration',
            scopes: [RouteScope.SITE],
          },
          {
            name: 'export',
            url: '/data/export',
            scopes: [RouteScope.SITE],
          },
          {
            name: 'manualEntry',
            url: '/data/manualEntry',
            permission: 'canEditConfiguration',
            scopes: [RouteScope.SITE],
          },
          {
            name: 'advancedEntry',
            url: '/data/correction',
            permission: 'canAccessAdvancedEntry',
            scopes: [RouteScope.SITE],
          },
        ]}
      />
    ),
  },
  {
    name: 'monitoring',
    icon: MonitorHeartOutlined,
    activeIcon: MonitorHeart,
    url: '/monitoring',
    scopes: [RouteScope.SITE],
    permission: 'canAccessDamco',
  },
  {
    name: 'steamMixes',
    icon: SteamMixOutlined,
    activeIcon: SteamMixOutlined,
    url: '/steamMixes',
    permission: 'canAccessOpco',
    scopes: [RouteScope.SITE],
  },
];
