import {
  DEFAULT_DEVICE_HEARTBEAT_FREQUENCY,
  DEFAULT_DEVICE_LOOPTIME,
  DEFAULT_VARIABLE_DATA_DELAY,
  DEFAULT_VARIABLE_FREQUENCY,
  ModbusTcpDeviceConfig,
} from '@dametis/core';

export const createModbusTcpDeviceConfig = ({
  host = '',
  port = 502,
  pauseAfterRequest = 100,
  loopTime = DEFAULT_DEVICE_LOOPTIME,
  maxUnusedValue = 10,
  maxRegisterToReadByRequest = 100,
  invertBytes = false,
  invertWords = false,
  invertDoubleWords = false,
  uid = 1,
  deviceHeartbeatFrequency = DEFAULT_DEVICE_HEARTBEAT_FREQUENCY,
  defaultVariableFrequency = DEFAULT_VARIABLE_FREQUENCY,
  defaultVariableDataDelay = DEFAULT_VARIABLE_DATA_DELAY,
}: Partial<ModbusTcpDeviceConfig> = {}): ModbusTcpDeviceConfig => ({
  host,
  port,
  pauseAfterRequest,
  loopTime,
  maxUnusedValue,
  maxRegisterToReadByRequest,
  invertBytes,
  invertWords,
  invertDoubleWords,
  uid,
  deviceHeartbeatFrequency,
  defaultVariableFrequency,
  defaultVariableDataDelay,
});
