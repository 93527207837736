import { Box, Dialog, DialogContent, Stack, ToggleButtonGroup, Typography } from '@mui/material';
import { DataGridPremium, GridColDef, useGridApiRef } from '@mui/x-data-grid-premium';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DataManagerStats, ProtocolKind, StatPeriod } from '@dametis/core';

import SmallToggleButton from 'components/UI/SmallToggleButton/SmallToggleButton';
import { getFormattedValue } from 'components/UI/UnitPicker/functions/getFormattedValue';
import { onColumnHeaderClick, onColumnHeaderDoubleClick } from 'functions/dataGrid/autoresize';
import { useDevices } from 'store/api/devices';

interface DeviceStatsRow {
  id: string;
  name: string;
  protocol: ProtocolKind;
  dataStat: number;
  previousDataStat: number;
  syncStat: number;
}

interface Props {
  open: boolean;
  onClose: () => void;
  stats: DataManagerStats;
  statsPeriod: StatPeriod;
  setStatsPeriod: (statsPeriod: StatPeriod) => void;
}

const StatsDetailsModal: FC<Props> = ({ open, onClose, stats, statsPeriod, setStatsPeriod }) => {
  const { t } = useTranslation('stats');
  const [data, setData] = useState<{
    columns: GridColDef[];
    rows: DeviceStatsRow[];
  }>({
    columns: [],
    rows: [],
  });

  const apiRef = useGridApiRef();
  const { data: devices } = useDevices();

  const setGrid = useCallback(() => {
    const columns: GridColDef<DeviceStatsRow>[] = [
      {
        field: 'protocol',
        headerName: t('label.protocol'),
        width: 100,
        valueGetter: (_, row) => row.protocol,
        renderCell: ({ value }) => <>{value}</>,
      },
      {
        field: 'name',
        headerName: t('label.name'),
        flex: 1,
        minWidth: 200,
        valueGetter: (_, row) => row.name,
        renderCell: ({ value }) => <>{value}</>,
      },
      {
        field: 'dataStat',
        headerName: t('label.data'),
        width: 100,
        valueGetter: (_, row) => row.dataStat,
        renderCell: ({ value }) => <>{getFormattedValue({ value, baseUnit: statsPeriod !== StatPeriod.total24h ? '/s' : undefined })}</>,
      },
      {
        field: 'previousDataStat',
        headerName: t('label.previousData'),
        width: 100,
        valueGetter: (_, row) => row.previousDataStat,
        renderCell: ({ value }) => <>{getFormattedValue({ value, baseUnit: statsPeriod !== StatPeriod.total24h ? '/s' : undefined })}</>,
      },
      {
        field: 'syncStat',
        headerName: t('label.sync'),
        width: 100,
        valueGetter: (_, row) => row.syncStat,
        renderCell: ({ value }) => <>{getFormattedValue({ value, baseUnit: statsPeriod !== StatPeriod.total24h ? '/s' : undefined })}</>,
      },
    ];
    const rows: DeviceStatsRow[] =
      devices === undefined
        ? []
        : devices.map(device => {
            return {
              id: device.uuid,
              name: device.name,
              protocol: device.protocol,
              dataStat: stats.data[device.uuid] ? stats.data[device.uuid][statsPeriod] : 0,
              previousDataStat: stats.previousData[device.uuid] ? stats.previousData[device.uuid][statsPeriod] : 0,
              syncStat: stats.sync[device.uuid] ? stats.sync[device.uuid][statsPeriod] : 0,
            };
          });
    setData({ columns, rows });
  }, [devices, stats, statsPeriod, t]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    setGrid();
  }, [devices, setGrid]);

  useEffect(() => {
    const savedResize = apiRef.current.resize;
    let resizing: NodeJS.Timeout | null = null;
    apiRef.current.resize = () => {
      if (resizing !== null) {
        clearTimeout(resizing);
      }
      resizing = setTimeout(savedResize, 10);
    };
    apiRef.current.resize();
  }, [apiRef]);

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
      <DialogContent>
        <Stack alignItems="flex-start" direction="row" justifyContent="space-between" mb={2}>
          <Typography variant="h6">{t('title.details')}</Typography>
          <ToggleButtonGroup
            exclusive
            color="primary"
            size="small"
            value={statsPeriod}
            onChange={(_, period) => {
              if (period !== null) {
                setStatsPeriod(period);
              }
            }}
          >
            <SmallToggleButton sx={{ p: theme => `${theme.spacing(0.75)}!important`, marginInline: 1 }} value={StatPeriod.speed1m}>
              1m
            </SmallToggleButton>
            <SmallToggleButton sx={{ p: theme => `${theme.spacing(0.75)}!important`, marginInline: 1 }} value={StatPeriod.speed5m}>
              5m
            </SmallToggleButton>
            <SmallToggleButton sx={{ p: theme => `${theme.spacing(0.75)}!important`, marginInline: 1 }} value={StatPeriod.speed15m}>
              15m
            </SmallToggleButton>
            <SmallToggleButton sx={{ p: theme => `${theme.spacing(0.75)}!important`, marginInline: 1 }} value={StatPeriod.total24h}>
              24h
            </SmallToggleButton>
          </ToggleButtonGroup>
        </Stack>
        <Box height={500}>
          <DataGridPremium
            apiRef={apiRef}
            {...data}
            disableRowSelectionOnClick
            columnHeaderHeight={32}
            rowHeight={30}
            onColumnHeaderClick={onColumnHeaderClick}
            onColumnHeaderDoubleClick={onColumnHeaderDoubleClick(apiRef)}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default StatsDetailsModal;
