import { Dict, TagTreeUsageInfo, UUID } from '@dametis/core';

import { ShortTagTreeUsageInfo } from 'types/tags';

export interface ShortNestedTag {
  uuid: string;
  parentId: string | null;
  children: ShortNestedTag[];
}

export const flattenShortNestedTag = (tag: ShortNestedTag): ShortNestedTag[] => {
  const result: ShortNestedTag[] = [tag];

  tag.children.forEach(child => {
    result.push(...flattenShortNestedTag(child));
  });

  return result;
};

export const flattenTagTrees = (tags: TagTreeUsageInfo[]): TagTreeUsageInfo[] => {
  const result: TagTreeUsageInfo[] = [];

  tags.forEach(tag => {
    result.push(tag);
    result.push(...flattenTagTrees(tag.children));
  });

  return result;
};

export const flattenShortTagTrees = (
  tagIds: UUID[],
  trees: ShortNestedTag[],
  tagsById: Dict<ShortTagTreeUsageInfo>,
): ShortTagTreeUsageInfo[] => {
  const result: ShortTagTreeUsageInfo[] = [];

  const addTagAndChildren = (tag: ShortNestedTag) => {
    result.push(tagsById[tag.uuid]);
    tag.children.forEach(child => addTagAndChildren(child));
  };

  tagIds.forEach(tagId => {
    const foundTag = findShortNestedTag(trees, tagId);
    if (foundTag) {
      addTagAndChildren(foundTag);
    }
  });

  return result;
};

export const simplifyTags = (tag: TagTreeUsageInfo): ShortNestedTag => ({
  uuid: tag.uuid,
  parentId: tag.parentId,
  children: tag.children.map(simplifyTags),
});

export const findShortNestedTag = (trees: ShortNestedTag[], tagId: UUID): ShortNestedTag | null => {
  for (let index = 0; index < trees.length; index += 1) {
    const tree = trees[index];

    if (tree.uuid === tagId) {
      return tree;
    }

    const foundInChildren = findShortNestedTag(tree.children, tagId);
    if (foundInChildren) {
      return foundInChildren;
    }
  }

  return null;
};

export const updateShortNestedTag = (currentTag: ShortNestedTag, tagId: UUID, body: Partial<ShortNestedTag>): ShortNestedTag => {
  if (currentTag.uuid === tagId) {
    return { ...currentTag, ...body };
  }
  const updatedChildren = currentTag.children.map(child => updateShortNestedTag(child, tagId, body));

  return { ...currentTag, children: updatedChildren };
};

export const createShortNestedTag = (currentTag: ShortNestedTag, parentId: UUID, newTag: ShortNestedTag, index: number): ShortNestedTag => {
  if (currentTag.uuid === parentId) {
    return {
      ...currentTag,
      children: currentTag.children.toSpliced(index, 0, newTag),
    };
  }

  const updatedChildren = currentTag.children.map(child => createShortNestedTag(child, parentId, newTag, index));

  return { ...currentTag, children: updatedChildren };
};

export const deleteShortNestedTag = (currentTag: ShortNestedTag, tagId: UUID): ShortNestedTag | null => {
  if (currentTag.uuid === tagId) {
    return null;
  }

  const updatedChildren = currentTag.children.map(child => deleteShortNestedTag(child, tagId)).filter(item => item !== null);

  return { ...currentTag, children: updatedChildren };
};

export const deleteShortNestedTags = (currentTag: ShortNestedTag, tagIds: UUID[]): ShortNestedTag | null => {
  if (tagIds.includes(currentTag.uuid)) {
    return null;
  }

  const updatedChildren = currentTag.children.map(child => deleteShortNestedTags(child, tagIds)).filter(item => item !== null);

  return { ...currentTag, children: updatedChildren };
};

export const buildTreesFromShortNestedTags = (trees: ShortNestedTag[], tagsById: Dict<ShortTagTreeUsageInfo>): TagTreeUsageInfo[] => {
  const transformNode = (node: ShortNestedTag): TagTreeUsageInfo | null => {
    const tagInfo = tagsById[node.uuid];
    if (!tagInfo) {
      return null;
    }

    return {
      ...tagInfo,
      parentId: node.parentId,
      children: node.children.map(transformNode).filter(child => child !== null),
    };
  };

  return trees.map(transformNode).filter(node => node !== null);
};
