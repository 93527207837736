import { TabType } from '@dametis/core';

import { ThresholdDirection } from '../../types';

export default {
  title: {
    playground: 'Playground',
    playground_withName: 'Playground « {{name}} »',
    template: 'Modèle de playground',
    template_withName: 'Modèle de playground « "{{name}} »',
    xAxes: 'Axes X',
    yAxes: 'Axes Y',
    xAxis: 'Axe X',
    yAxis: 'Axe Y',
    drawArea: 'Zones',
    maths: 'Mathématiques',
    create: 'Créer',
    home: 'Choisissez un premier onglet à ajouter à ce playground :',
    noTabs: 'Aucun onglet',
    variableStats: 'Statistiques',
    variableSettings: 'Paramètres',
    variableStyle: 'Style',
    variableThreshold: 'Seuil',
    variableCovariances: 'Variables influentes',
    regression: 'Régression',
    regression_NONE: 'Aucune',
    regression_LINEAR: 'Régression linéaire',
    regression_POLYNOMIAL2: 'Régression polynomiale X²',
    regression_POLYNOMIAL3: 'Régression polynomiale X³',
    regression_POLYNOMIAL4: 'Régression polynomiale X⁴',
    regression_EXPONENTIAL: 'Régression exponentielle',
    regression_LOGARITHMIC: 'Régression logarithmique',
    export: 'Export',
    data: 'Données',
    subperiods: 'Sous-périodes',
    tabQueryLanguage: "Paramètres de l'onglet « {{name}} »",
    createModel: 'Nouveau modèle',
    editModel: 'Modifier le modèle',
    customPeriods: 'Périodes personnalisées',
    addCommentToVar: 'Ajouter un commentaire à la variable « {{var}} »',
  },
  subtitle: {
    variables: 'Variables',
    timeRange: 'Intervalle de temps',
    selectedVariable: '{{name}}',
    home1: 'ou cliquez sur le bouton',
    home2: 'pour les autres types.',
    variable: 'Variable',
    linearRegFormula: 'Équation',
    linearRegR2: 'r²',
    rules: 'Règles',
    addTyVariable: 'Ajoutez une première variable',
    addBarVariable: 'Ajoutez une première variable',
    addHistogramVariable: 'Sélectionnez une variable',
    addYXyVariable: "Ajoutez une première variable à l'axe Y",
    addXXyVariable: "Sélectionnez une variable pour l'axe X",
    noChart: 'Modifiez ce playground pour ajouter des variables',
    exportFormat: 'Format du fichier',
    minValue: 'min : {{min}}',
    maxValue: 'max : {{max}}',
  },
  tab: {
    data: 'Données',
    tools: 'Outils',
    statistics: 'Statistiques',
    threshold: 'Seuil',
    modelisation: 'Modélisation',
    customPeriods: 'Périodes',
  },
  button: {
    edit: 'Modifier',
    save: 'Enregistrer',
    saved: 'Enregistré',
    saveAs: 'Dupliquer',
    delete: 'Supprimer',
    openInPlayground: 'Playground',
    previewBatch: 'Aperçu',
    createTab: 'Créer',
    create: 'Créer',
    cancel: 'Annuler',
    download: 'Télécharger',
    configuration: 'Configuration',
    addVariable: 'Ajouter',
    addArea: 'Ajouter',
    addStyledRule: 'Règle',
    addStyleConfiguration: 'Liste',
    createCalculatedVariable: 'Variable calculée',
    closeQueryLanguageDialog: 'Ok',
    createModel: 'Nouveau modèle',
    saveModel: 'Enregistrer',
    exportModel: 'Exporter ce modèle',
    addPeriod: 'Ajouter',
    share: 'Partager',
    editCovariances: 'Modifier la liste',
    saveStyleConfiguration: 'Liste',
    png: 'PNG',
  },
  text: {
    addTabOfType: 'Ajouter un onglet de type :',
    tabTypes_XY_CHART: 'Graphique XY',
    tabTypes_TY_CHART: 'Graphique temporel',
    tabTypes_BAR_CHART: 'Diagramme à barres',
    tabTypes_PIE_CHART: 'Diagramme circulaire',
    tabTypes_HISTOGRAM: 'Histogramme',
    tabTypes_LINEAR_REG: 'Régression linéaire',
    tabTypes_LIVE_CHART: 'Graphique en direct',
    tabTypes_MULTI_LINEAR_REG: 'Régression linéaire multiple',
    tabTypes_TABLE: 'Tableau de valeurs',
    [`tabTypes_${TabType.NONE}`]: 'Aucun',
    noVariables: 'Pas de variables',
    noVariable: 'Pas de variable',
    xIndex: "L'index est utilisé comme axe X",
    noStyledRules: 'Pas de règles de style',
    mean: 'Moyenne',
    min: 'Min',
    max: 'Max',
    integral: 'Intégrale',
    stddev: 'Écart type',
    gap: 'Écart',
    thresholdValue: 'Valeur',
    thresholdDelay: 'Délais',
    thresholdNb: 'Nombre dépassements',
    thresholdTime: 'Durée totale dépassements',
    thresholdIntegral: 'Intégrale',
    threshold: {
      [ThresholdDirection.ABOVE]: 'Au dessus',
      [ThresholdDirection.UNDER]: 'Au dessous',
    },
    from: 'Début',
    fromDate: 'Début : {{date}}',
    to: 'Fin',
    toDate: 'Fin : {{date}}',
    date: 'Date',
    newGroup: 'Nouveau groupe',
    newPlayground: 'Nouveau playground',
    newModel: 'Nouveau modèle',
    duration: 'Durée',
    noModels: 'Aucun modèle',
    multipleBatchTY: 'Périodes multiples',
    modelHelper: 'Les régressions linéaires multiples sont désormais disponibles sur la nouvelle page ',
    commentDate: 'Le {{date}}',
    restrictedEdition: 'Vous pouvez modifier mais vous ne pouvez pas enregistrer vos modifications',
  },
  input: {
    label: {
      name: 'Nom',
      period: 'Période',
      formula: 'Formule',
      groupBy: 'Pas',
      from: 'Début',
      to: 'Fin',
      customTimeRange: 'Personnalisé pour cet onglet',
      previewMin: 'min',
      previewMax: 'max',
      step: 'Pas',
      placeholderVariable: 'Sélectionner une variable...',
      xXyVariable: "Variable de l'axe X",
      yXyVariable: "Variables de l'axe Y",
      selectAxis: 'Sélectionner un axe',
      areaRangeLeft: 'Gauche ',
      areaRangeRight: 'Droite ',
      histogramVariable: 'Variable',
      batch: 'Batch',
      min: 'min',
      Min: 'Min',
      max: 'max',
      Max: 'Max',
      format: 'Format',
    },
    placeholder: {
      previewMin: 'auto',
      previewMax: 'auto',
      step: 'auto',
      selectBatch: 'Sélectionner...',
      excel: 'Excel',
    },
    value: {
      noVariable: 'Aucune variable',
      queryLanguageDisabledTitle: 'Un onglet pour le bloc',
      queryLanguageDisabledDescription: 'Par défaut. Vous pourrez sélectionner les variables du bloc.',
      queryLanguageEnabledTitle: 'Un onglet par enfant du bloc',
      queryLanguageEnabledDescription:
        "Pendant l'édition, vous pourrez sélectionner les variables des enfants du bloc. Après avoir enregistré, l'onglet se démultipliera pour chaque enfant du bloc.",
      ofAllTypes: 'Tous les enfants',
      onlyOfType: 'Seulement de type...',
      standard: 'Variable',
      index: 'Index',
    },
    helperText: {
      groupBy: 'Sélectionner une sous-période',
      batch: 'Sélectionner un batch',
    },
  },
  tooltip: {
    createPlayground: 'créer le playground',
    savePlayground: 'sauvegarder le playground',
    editPlayground: 'modifier le playground',
    duplicatePlayground: 'dupliquer le playground',
    sharePlayground: 'partager le playground',
    deletePlayground: 'supprimer le playground',
    timeRangeDisabledFor_customTimeRange:
      "L'intervalle de temps global est désactivé pour cet onglet à cause de l'intervalle de temps personnalisé activé dans Données > Axe X.",
    timeRangeDisabledFor_customPeriod:
      "L'intervalle de temps global est désactivé pour cet onglet à cause de l'intervalle de temps personnalisé activé dans Périodes > Périodes personnalisées.",
    zoomOut: 'Dézoomer',
    showTooltips: 'Afficher les info-bulles',
    hideTooltips: 'Cacher les info-bulles',
    showYAxis: 'Afficher les ordonnées',
    hideYAxis: 'Cacher les ordonnées',
    disconnectNulls: 'Conserver les trous de données',
    connectNulls: 'Remplir les trous de données',
    connectNullsTemporaryUnavailable: 'Cette fonctionnalité est temporairement indisponible',
    fullscreen: 'Mode plein écran',
    exitFullscreen: 'Quitter le mode plein écran',
    addBarVariable: 'Ajouter une première variable',
    addTyVariable: 'Ajouter une première variable',
    addHistogramVariable: 'Sélectionner une variable',
    addYXyVariable: "Ajouter une première variable à l'axe Y",
    addXXyVariable: "Sélectionner une variable pour l'axe X",
    dragDrop: 'Glisser-déposer',
    closeTab: "Fermer l'onglet",
    cantCreateTab: 'Vous ne pouvez pas créer plus de 5 nouveaux onglets',
    displayGroup: 'Afficher le groupe',
    hideGroup: 'Masquer le groupe',
    hideVariable: 'Masquer la variable',
    hideVariableTy: `Masquer la variable sur l'aperçu graphique`,
    displayVariable: 'Afficher la variable',
    disableVariable: 'Désactiver la variable',
    enableVariable: 'Activer la variable',
    displayVariableTy: `Afficher la variable sur l'aperçu graphique`,
    deleteVariable: 'Supprimer la variable',
    duplicateVariable: 'Dupliquer la variable',
    undoPeriod: 'Revenir à la période sélectionnée précédente',
    redoPeriod: 'Aller à la période sélectionnée suivante',
    editVariable: 'Modifier la variable',
    deleteTab: "Supprimer l'onglet",
    queryLanguageEnabled: 'Un onglet par enfant du bloc. Paramétrer...',
    queryLanguageDisabled: 'Un onglet pour le bloc. Paramétrer...',
    deletePeriod: 'Supprimer la période',
    editPeriod: 'Modifier la période',
    addVariableToChart: 'Ajouter la variable au graphique',
    deleteInfluencingVariable: 'Retirer la variable de la liste',
    openInPlayground: 'Ouvrir dans un playground',
    addStyledRule: 'Ajouter une règle',
    saveStyleConfiguration: 'Enregistrer ces règles dans une liste',
    addStyleConfiguration: 'Utiliser une liste de règles',
    fromBarChart: 'Transformer en un graphique linéaire',
    fromLineChart: 'Transformer en un graphique en barres',
    groupingDisablesExport: 'Les lignes groupées ne peuvent pas être exportées',
  },
  alert: {
    liveChartCustomTimeRange: "L'intervalle de temps d'un graphique en direct n'est pas modifiable et correspond aux 5 dernières minutes.",
    computationStats_CUSTOMPERIOD: 'Les statistiques ne sont pas disponibles en mode multiperiodes.',
    modelisation: "La modélisation n'est pas disponible pour un calcul.",
    threshold: "L'analyse de seuil n'est pas disponible pour un calcul.",
  },
  xgrid: {
    noData: 'Aucune sous-période',
    footerPagination: 'Sous-périodes par page',
    footerTotalRun: 'Total sous-périodes',
  },
};
