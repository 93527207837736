import { NestedTag } from '@dametis/core';

export enum ColumnElementType {
  NEW = 'new',
  EXISTING = 'existing',
}

export interface ColumnElement<T extends ColumnElementType = ColumnElementType> {
  tag: NestedTag;
  type: T;
}

export type ExistingColumnElement = ColumnElement<ColumnElementType.EXISTING>;

export const IsExistingColumnElement = (element: ColumnElement): element is ExistingColumnElement =>
  element.type === ColumnElementType.EXISTING;

export type NewColumnElement = ColumnElement<ColumnElementType.NEW>;

export const IsNewColumnElement = (element: ColumnElement): element is NewColumnElement => element.type === ColumnElementType.NEW;
