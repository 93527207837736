import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, dialogClasses } from '@mui/material';
import { FC, FocusEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockInfo, BlockTypeInfo, ShortStandardBlockInfo, ShortcutCategory } from '@dametis/core';

import AdvancedTextField, { AdvancedTextFieldProps } from 'components/UI/Inputs/AdvancedTextField/AdvancedTextField';
import getEntitiesById from 'functions/getEntitiesById';
import { useBlockTypes } from 'store/api/blockTypes';
import { useBlocks } from 'store/api/blocks';
import { useStandardBlocks } from 'store/api/standardBlocks';

import FolderExplorer, { FolderExplorerProps } from './FolderExplorer';
import { getExplorerItemName } from './getExplorerItemName';
import { getExplorerItemPath } from './getExplorerItemPath';
import { EntitiesByCategory, ExplorerItem } from './types';

const blockTypesEmptyArray: BlockTypeInfo[] = [];
const standardBlocksEmptyArray: ShortStandardBlockInfo[] = [];
const blocksEmptyArray: BlockInfo[] = [];

export interface FolderExplorerPickerProps extends Omit<FolderExplorerProps, 'value' | 'onChange'> {
  canSelectNone?: boolean;
  value: ExplorerItem | null;
  onChange: (newSelectedItem: ExplorerItem | null) => void | Promise<void>;
  isValid?: (selectedItem: ExplorerItem | null) => boolean;
  isEditing?: boolean;
  label?: AdvancedTextFieldProps['label'];
  advancedTextFieldProps?: Omit<AdvancedTextFieldProps, 'label' | 'editing' | 'variant'>;
}

const FolderExplorerPicker: FC<FolderExplorerPickerProps> = ({
  canSelectNone = false,
  value,
  onChange,
  isEditing = true,
  label = undefined,
  isValid = undefined,
  advancedTextFieldProps = {},
  rootFolder,
  ...props
}) => {
  const { t } = useTranslation('lego');

  const { data: blockTypes = blockTypesEmptyArray } = useBlockTypes();
  const { data: standardBlocks = standardBlocksEmptyArray } = useStandardBlocks();
  const { data: blocks = blocksEmptyArray } = useBlocks();

  const [selectedItem, setSelectedItem] = useState<ExplorerItem | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const path = useMemo(() => getExplorerItemPath(rootFolder, selectedItem), [rootFolder, selectedItem]);

  const isSubmitDisabled = useMemo(() => (isValid ? !isValid(selectedItem) : false), [isValid, selectedItem]);

  const blockTypesById = useMemo(() => getEntitiesById<BlockTypeInfo>(blockTypes), [blockTypes]);
  const standardBlocksById = useMemo(() => getEntitiesById<ShortStandardBlockInfo>(standardBlocks), [standardBlocks]);
  const blocksById = useMemo(() => getEntitiesById<BlockInfo>(blocks), [blocks]);

  const entitiesByCategory: EntitiesByCategory = useMemo(
    () => ({
      [ShortcutCategory.BLOCK_TYPE]: blockTypesById,
      [ShortcutCategory.STANDARD_BLOCK]: standardBlocksById,
      [ShortcutCategory.BLOCK]: blocksById,
    }),
    [blockTypesById, standardBlocksById, blocksById],
  );

  const inputValue = useMemo(
    () => `/${path.map(item => getExplorerItemName(item, entitiesByCategory, t)).join('/')}`,
    [path, entitiesByCategory, t],
  );

  const handleOpenDialog: FocusEventHandler<HTMLInputElement> = useCallback(
    event => {
      event.currentTarget.blur();
      setSelectedItem(value ?? null);
      setIsDialogOpen(true);
    },
    [value],
  );

  const handleCloseDialog = useCallback(() => {
    setIsDialogOpen(false);
    setSelectedItem(value ?? null);
  }, [value]);

  const handleChangeInput = useCallback(() => {}, []);

  const handleSelectNone = useCallback(() => {
    setIsDialogOpen(false);
    onChange(null);
  }, [onChange]);

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true);
    await onChange(selectedItem);
    setIsSubmitting(false);
    setIsDialogOpen(false);
  }, [onChange, selectedItem]);

  const handleExplorerItemChange = useCallback((newSelectedItem: ExplorerItem) => {
    setSelectedItem(newSelectedItem);
  }, []);

  useEffect(() => {
    setSelectedItem(value ?? null);
  }, [value]);

  return (
    <>
      <AdvancedTextField
        editing={isEditing}
        focused={false}
        label={label}
        value={inputValue}
        onChange={handleChangeInput}
        onFocus={handleOpenDialog}
        {...advancedTextFieldProps}
      />
      <Dialog
        fullWidth
        maxWidth="md"
        open={isDialogOpen}
        sx={{ [`& .${dialogClasses.paper}`]: { height: 1, maxHeight: 500 } }}
        onClose={handleCloseDialog}
      >
        <DialogContent sx={{ p: 0 }}>
          <FolderExplorer rootFolder={rootFolder} value={selectedItem} onChange={handleExplorerItemChange} {...props} />
        </DialogContent>
        <DialogActions>
          <Button disabled={isSubmitting} onClick={handleCloseDialog}>
            {t('button.cancel')}
          </Button>
          {canSelectNone && (
            <LoadingButton loading={isSubmitting} onClick={handleSelectNone}>
              {t('button.selectNone')}
            </LoadingButton>
          )}
          <LoadingButton color="secondary" disabled={isSubmitDisabled} loading={isSubmitting} variant="contained" onClick={handleSubmit}>
            {t('button.select')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FolderExplorerPicker;
