import { Checkbox } from '@mui/material';
import { ChangeEventHandler, FC, MouseEventHandler, useCallback } from 'react';

import { StandardBlockParameter } from '@dametis/core';

import LegoParameterPreview, { LegoParameterPreviewProps } from 'components/Lego/UI/LegoParameterPreview/LegoParameterPreview';

export interface ParameterListElementProps extends LegoParameterPreviewProps<StandardBlockParameter> {
  onChangeIsSelected: (parameterBlockKey: string) => ChangeEventHandler<HTMLInputElement>;
}

const ParameterListElement: FC<ParameterListElementProps> = ({ parameter, onChangeIsSelected, sx, ...props }) => {
  const handleMouseDownCheckbox: MouseEventHandler<HTMLButtonElement> = useCallback(event => {
    event.stopPropagation();
  }, []);

  return (
    <>
      <LegoParameterPreview<StandardBlockParameter>
        parameter={parameter}
        sx={{ flexGrow: 1, transition: theme => theme.transitions.create(['opacity']), ...sx }}
        {...props}
      />
      <Checkbox checked={parameter.isSelected} onChange={onChangeIsSelected(parameter.uuid)} onMouseDown={handleMouseDownCheckbox} />
    </>
  );
};

export default ParameterListElement;
