import { SettingsOutlined } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem, Tooltip } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { IsBlockVariable, IsDataVariable, IsModelVariable } from '@dametis/core';
import { getSingleVariableCalculation } from '@dametis/mathjs';

import { useDispatch, useSelector } from 'store';
import { setMenuPosition } from 'store/slices/calculationMenu';

const ConfigurationOption: FC = () => {
  const { t } = useTranslation('calculationMenu');
  const dispatch = useDispatch();

  const calculation = useSelector(state => state.calculationMenu.calculation);

  const singleVariable = useMemo(() => (calculation ? getSingleVariableCalculation(calculation) : false), [calculation]);
  const isEnabled = useMemo(
    () => singleVariable && (IsDataVariable(singleVariable) || IsBlockVariable(singleVariable) || IsModelVariable(singleVariable)),
    [singleVariable],
  );

  const to = useMemo(() => {
    if (IsDataVariable(singleVariable)) {
      return `/configuration/variables?selectedVariables=${singleVariable.variableUuid}`;
    }
    if (IsBlockVariable(singleVariable)) {
      return `/lego/${singleVariable.blockId}`;
    }
    if (IsModelVariable(singleVariable)) {
      return `/models/${singleVariable.modelUuid}`;
    }
    return '';
  }, [singleVariable]);

  const label = useMemo(() => {
    if (IsDataVariable(singleVariable)) {
      return t('label.dataVariableConfiguration');
    }
    if (IsBlockVariable(singleVariable)) {
      return t('label.blockConfiguration');
    }
    if (IsModelVariable(singleVariable)) {
      return t('label.modelConfiguration');
    }
    return t('label.dataVariableConfiguration');
  }, [singleVariable, t]);

  const handleGoToConfiguration = useCallback(() => {
    dispatch(setMenuPosition(null));
  }, [dispatch]);

  if (to === null) {
    return null;
  }
  return (
    <Tooltip placement="right" title={!isEnabled ? t('tooltip.disableConfiguration') : undefined}>
      <MenuItem component={NavLink} disabled={!isEnabled} to={to} onClick={handleGoToConfiguration}>
        <ListItemIcon sx={{ mr: 2, minWidth: 'unset' }}>
          <SettingsOutlined fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={label} />
      </MenuItem>
    </Tooltip>
  );
};

export default ConfigurationOption;
