import { IsUpdateTagOperation, TagOperation } from '@dametis/core';

export const shouldSkipUpdate = (operationA: TagOperation, operationB: TagOperation) =>
  operationA !== undefined &&
  IsUpdateTagOperation(operationA) &&
  operationB !== undefined &&
  IsUpdateTagOperation(operationB) &&
  operationA.data.name !== undefined &&
  operationB.data.name !== undefined &&
  operationA.data.uuid === operationB.data.uuid;
