import {
  BlockTypeInfo,
  CreateStandardBlockBody,
  IsBlockTypeMetadataNumber,
  IsBlockTypeMetadataOption,
  IsBlockTypeMetadataString,
  MetadataScope,
  StandardBlockMetadataBody,
  StandardBlockUI,
} from '@dametis/core';

import { createStandardBlockConnectionBody } from './standardBlock/createStandardBlockConnection';
import { createStandardBlockMetricBody } from './standardBlock/createStandardBlockMetric';
import { createStandardBlockParameterBody } from './standardBlock/createStandardBlockParameter';

export const getStandardBlockBodyDataFromBlockType = (blockType: BlockTypeInfo): Partial<CreateStandardBlockBody> => {
  const ui: StandardBlockUI = { color: blockType.content.color };
  const parameters = (blockType.parameters ?? []).map(parameter => createStandardBlockParameterBody({ ...parameter, isSelected: true }));
  const metrics = (blockType.metrics ?? []).map(metric => createStandardBlockMetricBody({ ...metric, isSelected: true }));
  const connections = (blockType.connections ?? []).map(connection => createStandardBlockConnectionBody(connection));
  const standardMetadataList = (blockType.standardMetadataList ?? [])
    .map((standardMetadata): StandardBlockMetadataBody | null => {
      if (IsBlockTypeMetadataString(standardMetadata)) {
        return {
          ...standardMetadata,
          scope: MetadataScope.STANDARD_BLOCK,
          content: { ...standardMetadata.content, value: standardMetadata.content.defaultValue ?? '' },
        };
      }
      if (IsBlockTypeMetadataNumber(standardMetadata)) {
        return {
          ...standardMetadata,
          scope: MetadataScope.STANDARD_BLOCK,
          content: { ...standardMetadata.content, value: standardMetadata.content.defaultValue ?? 0 },
        };
      }
      if (IsBlockTypeMetadataOption(standardMetadata)) {
        return {
          ...standardMetadata,
          scope: MetadataScope.STANDARD_BLOCK,
          content: { ...standardMetadata.content, selectedOptionId: standardMetadata.content.defaultSelectedOptionId },
        };
      }
      return null;
    })
    .filter(el => el !== null);
  return {
    ui,
    parameters,
    metrics,
    connections,
    standardMetadataList,
  };
};
