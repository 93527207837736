import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import { Avatar, Badge, ButtonBase, Divider, Menu, MenuItem, badgeClasses } from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';

import getUserName from 'functions/getUserName';
import { sdk } from 'sdk';
import { useDispatch, useSelector } from 'store';
import { logout } from 'store/actions/auth';

import { getUserColor } from '../../../functions/color';

import useUserMenuStyles from './UserMenu.styles';

const UserMenu = () => {
  const { t } = useTranslation('header');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(state => state?.auth?.user);
  const userColor = getUserColor(user);

  const [menuOpen, setMenuOpen] = useState(false);
  const menuAnchorEl = useRef(null);

  const toggleMenu = () => setMenuOpen(prevMenuOpen => !prevMenuOpen);

  const onLogout = useCallback(async () => {
    try {
      await dispatch(logout());
      navigate('/login');
    } catch {
      //
    }
  }, [navigate, dispatch]);

  const deleteImpersonate = useCallback(async () => {
    await sdk.login.DeleteImpersonate();
    window.location.reload();
  }, []);

  const classes = useUserMenuStyles();
  return (
    <>
      <div className={classes.avatar_container}>
        <Badge
          badgeContent={
            <SupervisorAccountOutlinedIcon
              sx={theme => ({
                bgcolor: 'error.main',
                color: theme.palette.white,
                borderRadius: '12px',
                p: 0.25,
                fontSize: '20px',
              })}
            />
          }
          invisible={user?.realUser === undefined || user?.realUser === null}
          overlap="circular"
          sx={{ [`& .${badgeClasses.badge}`]: { pointerEvents: 'none' } }}
        >
          <Avatar
            ref={menuAnchorEl}
            className={classes.avatar}
            component={ButtonBase}
            style={{ backgroundColor: userColor }}
            onClick={toggleMenu}
          >
            {(user?.firstName ?? '').charAt(0).toUpperCase()}
            {(user?.lastName ?? '').charAt(0).toUpperCase()}
          </Avatar>
        </Badge>
      </div>
      <Menu anchorEl={menuAnchorEl.current} open={menuOpen} onClose={toggleMenu}>
        <MenuItem disabled>{getUserName(user ?? undefined)}</MenuItem>
        <MenuItem component={NavLink} to="/account" onClick={toggleMenu}>
          {t('userMenu.myAccount')}
        </MenuItem>
        <MenuItem component="a" href="https://dametis.atlassian.net/servicedesk/customer/portals" rel="noopener noreferrer" target="_blank">
          {t('userMenu.support')}
        </MenuItem>
        <Divider />
        {user?.realUser !== undefined && user?.realUser !== null && (
          <>
            <MenuItem onClick={deleteImpersonate}>{t('userMenu.stopImpersonation')}</MenuItem>
            <Divider />
          </>
        )}

        <MenuItem onClick={onLogout}>{t('userMenu.logOut')}</MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
