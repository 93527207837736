import { TagUsagesKey } from '@dametis/core';

import { TagTab } from 'types/tags';

const entityType: Record<TagUsagesKey, string> = {
  blocks: 'Blocks',
  playgrounds: 'Playgrounds',
  projects: 'Projects',
  reports: 'Reports',
  synoptics: 'Synoptics',
  variables: 'Variables',
  opcos: 'Vapor mixes',
  models: 'Models',
};

export default {
  title: {
    editTags: 'Tags',
    tags: 'Tags',
    tagsDetails: 'Tags details',
    tagEditing: 'Tag edition',
    actions: 'Available actions',
    selectedTags: 'Selected tags',
    column: {
      name: 'Name',
      dependencies: 'Dependencies',
    },
    entityType,
    tab: {
      [TagTab.TOOLS]: 'Tools',
      [TagTab.DEPENDENCIES]: 'Dependencies',
    },
  },
  label: {
    dependencies: 'Dependencies',
    newTag: 'New tag :',
    noTagFound: 'No tag found',
    searchingInAllTags: 'Searching in all tags',
    searchingIn: 'Searching in "{{name}}"',
    tags: 'Tags',
    searchTagsDescription: 'If multiple tags are selected, only items matching all criteria will be displayed (logical operator "AND")',
  },
  placeholder: {
    noSelectedTag: 'No selected tag',
    searchTag: 'Search tag...',
  },
  input: {
    tags: 'Tags',
    tagName: 'Tag name',
    targetTagName: 'Target tag name',
  },
  button: {
    tag: 'Tag',
    editTags: 'Edit tags',
    close: 'Close',
    cancel: 'Cancel',
    cancelChanges: 'Cancel',
    save: 'Save',
    addDependencies: 'Tag',
    create: 'Create',
    merge: 'Merge',
    moveTags: 'Move',
    moveToRoot: 'Move to root',
    deleteTags: 'Delete',
    confirmDelete: 'Yes, delete',
    validate: 'Validate',
    createTag: 'Create tag',
    createChildTag: 'Create child tag',
    editTag: 'Edit',
    mergeTags: 'Merge',
    deleteTag: 'Delete',
    deleteTag_one: 'Delete this tag',
    deleteTag_other: 'Delete these {{count}} tags',
    moveTag: 'Move',
    moveTag_one: 'Move this tag',
    moveTag_other: 'Move these {{count}} tags',
    undo: 'Undo',
    expandAll: 'Expand all',
    collapseAll: 'Collapse all',
    selectAll: 'Select all',
    unselectAll: 'Unselect all',
    noTags: 'Create your first tag',
  },
  tagAutocomplete: {
    newTag: 'New tag',
    unknownTag: 'Unknown tag',
    emptyTag: 'No tag selected',
  },
  text: {
    noDependency: 'No dependency',
    noOptions: 'No options',
    deleteTagsAdvice:
      'Deleting $t(text.thisDeletedTag, {"count": {{tagCount}} }) will remove it from $t(text.entity, {"count": {{dependenciesCount}} }). Please check these dependencies before deletion.',
    mergeTagsAdvice:
      'Merging $t(text.thisTag, {"count": {{tagCount}} }) will move $t(text.entity, {"count": {{dependenciesCount}} }) in target tag and remove source tags. Please check these dependencies before merging.',
    thisDeletedTag_one: 'this tag and its children',
    thisDeletedTag_other: 'these {{count}} tags and their children',
    thisTag_one: 'this tag',
    thisTag_other: 'these {{count}} tags',
    entity_one: '{{count}} entity',
    entity_other: '{{count}} entities',
    updatedAt: 'Updated {{date}}',
    tagSeparator: '▸',
    hasAnyChildSelected: 'At least one child selected',
    hasChildSelected_one: '{{count}} child selected',
    hasChildSelected_other: '{{count}} children selected',
    hasAnyChildDepthError: 'At least one child with error',
    hasChildError_one: '{{count}} error in children',
    hasChildError_other: '{{count}} errors in children',
    draggingTags: 'Move multiple tags',
    noTags: 'This site has no tags',
    unknownTag: 'Unknown tag',
  },
  error: {
    maxTagAllowed: 'Maximum number of tags reached',
    moveTags_one: 'An error occured during tag moving',
    moveTags_other: 'An error occured during tags moving',
    mergeTags_one: 'An error occured during tag merging',
    mergeTags_other: 'An error occured during tags merging',
    deleteTag_one: 'An error occured during tag deleting',
    deleteTag_other: 'An error occured during tags deleting',
    updateTag: 'An error occured during tag updating',
    createTag: 'An error occured during tag creation',
    undo: 'An error occured while undoing',
    maxDepthExceed: 'The tree cannot exceed 3 levels deep',
    errorInTags: 'Your tags contain errors',
    invalidName_zero: 'Tag name cannot be empty',
    invalidName_other: 'Tag name cannot be longer than {{max}} characters',
  },
  toast: {
    success: 'New tags saved.',
    error: 'An error occured during tags saving.',
    createTagSuccess: 'The tag was successfully created.',
    deleteTagSuccess_one: 'The tag was successfully deleted.',
    deleteTagSuccess_other: 'The tags were successfully deleted.',
    mergeTagsSuccess: 'The tags were successfully merged.',
    mergeTagsItselfFailure: 'Impossible to merge a tag with only itself',
    updateTagSuccess: 'The tag was successfully updated.',
  },
  modal: {
    cancelEdition: 'Are you sure ? You are closing edit mode.',
  },
  helper: {
    selectTag: 'Select tag',
    click: 'Click',
    moveTagsWithDnd: 'You can move tags with drag and drop',
  },
};
