import { AddCommentOutlined } from '@mui/icons-material';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateCommentInfo, IsDataVariable } from '@dametis/core';
import { getSingleVariableCalculation } from '@dametis/mathjs';

import CommentModal from 'components/UI/Comments/CommentModal/CommentModal';
import CommentsProvider from 'components/UI/Comments/context/CommentsContext';
import { calculationToString } from 'functions/calculationToString';
import makePermissionLabel from 'functions/makePermissionLabel';
import { usePermission } from 'hooks/usePermission';
import { useSelector } from 'store';
import { useCreateCommentMutation } from 'store/api/comments';
import { EntityType } from 'types/comment';

import CalculationMenuItem from '../CalculationMenuItem';

const AddCommentOption: FC = () => {
  const { t } = useTranslation('calculationMenu');

  const canEditComment = usePermission('canEditComment');

  const [createComment, { isLoading: isPosting }] = useCreateCommentMutation();

  const calculation = useSelector(state => state.calculationMenu.calculation);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const singleVariable = useMemo(() => (calculation ? getSingleVariableCalculation(calculation) : false), [calculation]);
  const isEnabled = useMemo(() => singleVariable && IsDataVariable(singleVariable), [singleVariable]);

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleCreateComment = useCallback(
    async (comment: CreateCommentInfo) => {
      if (!IsDataVariable(singleVariable)) {
        return;
      }
      await createComment({
        comment,
        entity: EntityType.VARIABLE,
        entityUuid: singleVariable.variableUuid,
      });
    },
    [singleVariable, createComment],
  );

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <>
      <CalculationMenuItem
        disabled={!isEnabled || isPosting || canEditComment}
        icon={AddCommentOutlined}
        label={t('label.addComment')}
        tooltip={!isEnabled ? t('tooltip.disableAddComment') : makePermissionLabel(t('label.addComment'), canEditComment)}
        tooltipProps={{ placement: 'right' }}
        onClick={handleOpenModal}
      />
      {IsDataVariable(singleVariable) && (
        <CommentsProvider entity={EntityType.VARIABLE} entityUuid={singleVariable.variableUuid}>
          <CommentModal
            datePicker
            isMessageRequired
            entity={EntityType.VARIABLE}
            entityUuid={singleVariable.variableUuid}
            open={isModalOpen}
            title={t('label.addCommentToVar', { var: calculationToString(calculation) })}
            onClose={handleCloseModal}
            onCreate={handleCreateComment}
          />
        </CommentsProvider>
      )}
    </>
  );
};

export default AddCommentOption;
