import { KeyboardArrowRight, Tag } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { DragEventHandler, FC, memo, useCallback, useMemo } from 'react';

import SimpleTagChip from 'components/UI/TagChip/SimpleTagChip';
import { useTagEditStore } from 'zustand/stores/tagEdit';

import { ShortNestedTag, findShortNestedTag, flattenShortNestedTag } from '../../helpers/shortNestedTag';
import { MAX_TAG_DEPTH, MAX_TAG_NAME_LENGTH } from '../TagsList';

import ItemActions from './ItemActions';
import ItemDropzone from './ItemDropzone';
import ItemLabel from './ItemLabel';
import ItemPaper, { ItemPaperProps } from './ItemPaper';
import StateIndicators from './StateIndicators';

export interface TagsListItemProps extends Omit<ItemPaperProps, 'isSelected'> {
  shortTag: ShortNestedTag;
  isExpanded: boolean;
  index: number;
  depth?: number;
}

const TagsListItem: FC<TagsListItemProps> = ({ shortTag, isExpanded, isExpandable, index, depth = 1, ...props }) => {
  const tag = useTagEditStore(state => state.editor.tagsById[shortTag.uuid]);
  const trees = useTagEditStore(state => state.editor.trees);
  const isEditing = useTagEditStore(state => state.isEditing);
  const selectedTagIds = useTagEditStore(state => state.selectedTagIds);
  const setDraggedTagIds = useTagEditStore(state => state.setDraggedTagIds);
  const disabledDropIds = useTagEditStore(state => state.disabledDropIds);
  const dragImageElement = useTagEditStore(state => state.dragImageElement);
  const setDisabledDropIds = useTagEditStore(state => state.setDisabledDropIds);

  const isSelected = useMemo(() => selectedTagIds.includes(shortTag.uuid), [shortTag.uuid, selectedTagIds]);

  const allChildrenIds = useMemo(
    () =>
      flattenShortNestedTag(shortTag)
        .map(child => child.uuid)
        .filter(childId => childId !== shortTag.uuid),
    [shortTag],
  );

  const isDropDisabled = useMemo(
    () => shortTag.parentId !== null && disabledDropIds.includes(shortTag.parentId),
    [disabledDropIds, shortTag.parentId],
  );

  const isMaxDepthWithChildren = useMemo(() => depth === MAX_TAG_DEPTH && shortTag.children.length > 0, [shortTag.children.length, depth]);
  const hasNameError = useMemo(() => (tag && tag?.name.length > MAX_TAG_NAME_LENGTH) || tag?.name.length === 0, [tag]);
  const isMaxDepthExceeded = useMemo(() => depth > MAX_TAG_DEPTH, [depth]);

  const hasError = useMemo(
    () => isMaxDepthExceeded || isMaxDepthWithChildren || hasNameError,
    [isMaxDepthExceeded, isMaxDepthWithChildren, hasNameError],
  );

  const handleDragStart: DragEventHandler<HTMLDivElement> = useCallback(
    event => {
      const newDraggedTagIds = !selectedTagIds.includes(shortTag.uuid) ? [...selectedTagIds, shortTag.uuid] : selectedTagIds;

      const disabledDropTags = newDraggedTagIds.reduce<ShortNestedTag[]>((result, tagId) => {
        const foundedTag = findShortNestedTag(trees, tagId);
        if (foundedTag) {
          result.push(foundedTag);
        }
        return result;
      }, []);

      const newDisabledDropIds = disabledDropTags.flatMap(flattenShortNestedTag).map(child => child.uuid);

      if (dragImageElement && newDraggedTagIds.length > 1) {
        event.dataTransfer.setDragImage(dragImageElement, 0, 0);
      }
      setTimeout(() => {
        setDraggedTagIds(newDraggedTagIds);
        setDisabledDropIds(newDisabledDropIds);
      }, 0);
    },
    [shortTag, setDraggedTagIds, selectedTagIds, setDisabledDropIds, dragImageElement, trees],
  );

  const handleDragEnd = useCallback(() => {
    setDraggedTagIds([]);
    setDisabledDropIds([]);
  }, [setDraggedTagIds, setDisabledDropIds]);

  return (
    <Box sx={{ position: 'relative' }}>
      {!isExpanded && <StateIndicators allChildrenIds={allChildrenIds} depth={depth} shortTag={shortTag} />}
      <ItemPaper
        draggable={isEditing}
        hasError={hasError}
        isDropDisabled={isDropDisabled}
        isExpandable={isExpandable}
        isSelected={isSelected}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        {...props}
      >
        <Stack alignItems="center" direction="row" flex={1} justifyContent="space-between" spacing={1}>
          <Stack alignItems="center" direction="row" spacing={1}>
            {isExpandable && <KeyboardArrowRight fontSize="small" sx={{ transform: `rotate(${isExpanded ? 90 : 0}deg)` }} />}
            {tag && isEditing && <ItemLabel color={tag.color} name={tag.name} tagId={tag.uuid} />}
            {tag && !isEditing && <SimpleTagChip color={tag.color ?? undefined} icon={Tag} name={tag.name} size="small" />}
          </Stack>
          {tag && <ItemActions tagId={tag.uuid} usages={tag.usages} />}
        </Stack>
      </ItemPaper>
      {!isDropDisabled && <ItemDropzone index={index} isExpanded={isExpanded} parentId={shortTag.parentId} tagId={shortTag.uuid} />}
    </Box>
  );
};

export default memo(TagsListItem);
