import { AccordionProps, AccordionSummary, ListItem, ListItemButton, ListItemText, Radio, Skeleton, useTheme } from '@mui/material';
import { FC, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PhysicalQuantity } from '@dametis/core';

import { PhysicalQuantityConfig, getPhysicalQuantitiesSorted } from 'config';
import { openReplayEvent } from 'openreplay/openreplay';
import { OpenReplayEvent } from 'openreplay/types';
import { useDispatch } from 'store';
import { useVncStore } from 'zustand/stores/vnc';

import Counter from '../../../UI/Counter/Counter';
import PhysicalQuantityChip from '../../../UI/PhysicalQuantity/PhysicalQuantityChip';
import { PropsContext } from '../../context';

import { FilterElementProps } from './Filters';
import { FilterAccordion, FilterAccordionDetails, FilterAccordionSummaryStack, FilterList, FilterListSubheader } from './styled';

interface SortedPQ extends PhysicalQuantityConfig {
  key: PhysicalQuantity;
  enabled: boolean;
}

const PhysicalQuantitiesFilters: FC<FilterElementProps> = ({ expanded, setExpanded, accordionsNumber }) => {
  const { t } = useTranslation('vnc');
  const dispatch = useDispatch();
  const theme = useTheme();

  const disabled = useContext(PropsContext).disableFilters.includes('physicalQuantities');

  const physicalQuantities = useVncStore(state => state.filters.physicalQuantities);
  const loadingFilters = useVncStore(state => state.loadingFilters);
  const setFiltersValues = useVncStore(state => state.setFiltersValues);
  const getResults = useVncStore(state => state.getResults);

  const sortedPQ = useMemo<SortedPQ[]>(
    () =>
      getPhysicalQuantitiesSorted(t, theme)
        .filter(([name]) => physicalQuantities[name] !== undefined)
        .map(([key, config]) => ({ key: key as PhysicalQuantity, ...config, enabled: physicalQuantities[key] ?? false })),
    [physicalQuantities, t, theme],
  );
  const truePQCount = useMemo(() => Object.values(physicalQuantities).filter(Boolean).length, [physicalQuantities]);

  const changeExpanded = useCallback<NonNullable<AccordionProps['onChange']>>(
    (event, isExpanded) => {
      setExpanded(isExpanded ? 'physicalQuantities' : false);
    },
    [setExpanded],
  );

  const togglePQ = useCallback(
    (pQ: SortedPQ | null) => async () => {
      if (pQ?.enabled === true) return;
      const newFilters = Object.keys(physicalQuantities).reduce<Partial<Record<PhysicalQuantity, boolean>>>((acc, pQ2) => {
        acc[pQ2 as PhysicalQuantity] = pQ2 === pQ?.key;
        return acc;
      }, {});
      setFiltersValues({ physicalQuantities: newFilters });
      openReplayEvent(OpenReplayEvent.VNC_FILTER_PHYSICAL_QUANTITY);
      await dispatch(getResults());
    },
    [dispatch, getResults, physicalQuantities, setFiltersValues],
  );

  return (
    <FilterAccordion
      expanded={expanded}
      filterAccordionsNumber={accordionsNumber}
      loadingFilters={loadingFilters}
      onChange={changeExpanded}
    >
      <AccordionSummary>
        <FilterAccordionSummaryStack>
          <FilterListSubheader>{t('accordion.physicalQuantities')}</FilterListSubheader>
          {truePQCount > 0 && <Counter count={truePQCount} />}
        </FilterAccordionSummaryStack>
      </AccordionSummary>
      <FilterAccordionDetails>
        <FilterList>
          <ListItem
            disablePadding
            secondaryAction={<Radio checked={truePQCount === 0} disabled={disabled} edge="end" tabIndex={-1} onClick={togglePQ(null)} />}
          >
            <ListItemButton disabled={disabled} selected={truePQCount === 0} onClick={togglePQ(null)}>
              <ListItemText>{t('list.text.physicalQuantitiesAll')}</ListItemText>
            </ListItemButton>
          </ListItem>
          {loadingFilters
            ? [50, 86, 67, 91, 96, 66, 78, 97, 92, 48, 85, 94, 93, 71, 99].map(s => (
                <ListItem key={s} secondaryAction={<Radio disabled edge="end" />}>
                  <ListItemButton>
                    <ListItemText>
                      <Skeleton variant="rounded" width={`${s}%`} />
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              ))
            : sortedPQ.map(pQ => (
                <ListItem
                  key={pQ.key}
                  disablePadding
                  secondaryAction={<Radio checked={pQ.enabled} disabled={disabled} edge="end" tabIndex={-1} onClick={togglePQ(pQ)} />}
                >
                  <ListItemButton disabled={disabled} selected={pQ.enabled} onClick={togglePQ(pQ)}>
                    <ListItemText
                      primary={<PhysicalQuantityChip physicalQuantity={pQ.key} />}
                      primaryTypographyProps={{ component: 'div' }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
        </FilterList>
      </FilterAccordionDetails>
    </FilterAccordion>
  );
};

export default PhysicalQuantitiesFilters;
