import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Alert,
  Collapse,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import sqlFormatter from '@sqltools/formatter';
import {
  ChangeEvent,
  ChangeEventHandler,
  Dispatch,
  FC,
  MouseEventHandler,
  SetStateAction,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useResizeObserver } from 'usehooks-ts';

import { DeviceQueryStrategy, QueryStrategy, SQLDeviceConfig, SQLDialect, SyncDelay, SyncPoint } from '@dametis/core';

import AdvancedTextFieldWithMask from 'components/UI/AdvancedTextFieldWithMask/AdvancedTextFieldWithMask';
import DateTimeFormatPicker from 'components/UI/DateTimeFormatPicker/DateTimeFormatPicker';
import MaskFormat from 'components/UI/InputMask/MaskFormat';
import AdvancedNumberTextField from 'components/UI/Inputs/AdvancedTextField/AdvancedNumberTextField';
import AdvancedTextField from 'components/UI/Inputs/AdvancedTextField/AdvancedTextField';

import {
  createBulkQueryStrategy,
  createDefaultQueryStrategy,
  createSQLBigQDeviceConfig,
  createSQLDefaultDeviceConfig,
  createTemplateQueryStrategy,
} from '../../../helpers/createSQLDeviceConfig';
import FormPaper, { FormLocation } from '../../FormPaper';
import SyncDelayInput from '../MQTTSettingsForm/SyncDelayInput';

import DefaultVariablesPicker from './DefaultVariablesPicker';
import TemplateVariablesPicker from './TemplateVariablesPicker';

export interface SQLSettingsFormProps {
  sql: SQLDeviceConfig;
  setSQL: Dispatch<SetStateAction<SQLDeviceConfig>>;
  syncPoint?: SyncPoint;
  onChangeSyncPoint?: (newSyncPoint: SyncPoint) => void;
  syncDelay?: SyncDelay;
  onChangeSyncDelay?: (newSyncDelay: SyncDelay) => void;
  isEditing?: boolean;
  location?: `${FormLocation}`;
}

const SQLSettingsForm: FC<SQLSettingsFormProps> = ({
  sql,
  setSQL,
  syncPoint = undefined,
  onChangeSyncPoint = undefined,
  syncDelay = undefined,
  onChangeSyncDelay = undefined,
  isEditing = true,
  location = 'modal',
}) => {
  const { t } = useTranslation('devices');

  const [isPasswordHidden, setIsPasswordHidden] = useState<boolean>(true);
  const [isSQLFormattingEnable, setIsSQLFormattingEnable] = useState<boolean>(false);

  const availableDialects = useMemo(() => Object.values(SQLDialect), []);
  const availableQueryStrategies = useMemo(() => Object.values(QueryStrategy), []);

  const papersContainerRef = useRef<HTMLDivElement | null>(null);

  const { width: papersContainerWidth = 0 } = useResizeObserver({
    ref: papersContainerRef,
  });

  const isSyncPointFieldAvailable = useMemo(
    () => syncPoint !== undefined && onChangeSyncPoint !== undefined,
    [syncPoint, onChangeSyncPoint],
  );

  const isSyncDelayFieldAvailable = useMemo(
    () => syncDelay !== undefined && onChangeSyncDelay !== undefined,
    [syncDelay, onChangeSyncDelay],
  );

  const availableSyncPoints: SyncPoint[] = useMemo(() => Object.values(SyncPoint), []);

  const availableQueryVariables: string[] = useMemo(() => {
    const { mode } = sql.queryStrategy;
    if (mode !== QueryStrategy.BULK && mode !== QueryStrategy.TEMPLATE) {
      return [];
    }
    return [
      '#NOW',
      ...(mode === QueryStrategy.TEMPLATE
        ? ['#REFERENCE', ...sql.queryStrategy.variables.map(queryStrategyVariable => `#${queryStrategyVariable}`)]
        : []),
    ];
  }, [sql.queryStrategy]);

  const handleChangeDialect: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (event.target.value === SQLDialect.BIGQUERY) {
        setSQL(state => createSQLBigQDeviceConfig({ ...state, dialect: SQLDialect.BIGQUERY }));
      } else {
        setSQL(state =>
          createSQLDefaultDeviceConfig({ ...state, dialect: event.target.value as Exclude<SQLDialect, SQLDialect.BIGQUERY> }),
        );
      }
    },
    [setSQL],
  );

  const handleChangeLoopTime = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, newValue: number) => {
      if (!setSQL) {
        return;
      }
      setSQL(state => ({ ...state, loopTime: newValue }));
    },
    [setSQL],
  );

  const handleChangeSyncPoint: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (onChangeSyncPoint) {
        onChangeSyncPoint(event.target.value as SyncPoint);
      }
    },
    [onChangeSyncPoint],
  );

  const handleChangeSyncDelay = useCallback(
    (newSyncDelay: SyncDelay) => {
      if (onChangeSyncDelay) {
        onChangeSyncDelay(newSyncDelay);
      }
    },
    [onChangeSyncDelay],
  );

  const handleChangeTimeZone: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setSQL(state => ({ ...state, timeZone: event.target.value }));
    },
    [setSQL],
  );

  const handleChangeDateFormat: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setSQL(state => ({ ...state, dateFormat: event.target.value }));
    },
    [setSQL],
  );

  const handleChangeHost: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (!setSQL) {
        return;
      }
      setSQL(state => ({ ...state, host: event.target.value }));
    },
    [setSQL],
  );

  const handleChangePort = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, newValue: number) => {
      if (!setSQL) {
        return;
      }
      setSQL(state => ({ ...state, port: newValue }));
    },
    [setSQL],
  );

  const handleChangeDatabase: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setSQL(state => ({ ...state, database: event.target.value }));
    },
    [setSQL],
  );

  const handleChangeUsername: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setSQL(state =>
        state.dialect !== SQLDialect.BIGQUERY
          ? { ...state, credentials: { ...(state.credentials ?? { password: '' }), username: event.target.value } }
          : state,
      );
    },
    [setSQL],
  );

  const handleTogglePasswordVisibility: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    setIsPasswordHidden(state => !state);
  }, []);

  const handleChangePassword: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setSQL(state =>
        state.dialect !== SQLDialect.BIGQUERY
          ? { ...state, credentials: { ...(state.credentials ?? { username: '' }), password: event.target.value } }
          : state,
      );
    },
    [setSQL],
  );

  const handleChangeQueryStrategyMode: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setSQL(state => {
        const newQueryStrateryMode = event.target.value as QueryStrategy;
        if (newQueryStrateryMode === QueryStrategy.DEFAULT) {
          return { ...state, queryStrategy: createDefaultQueryStrategy() };
          // return { ...state, queryStrategy: createDefaultQueryStrategy({ propertyMatcher: state.queryStrategy.propertyMatcher }) };
        }
        if (newQueryStrateryMode === QueryStrategy.TEMPLATE) {
          return { ...state, queryStrategy: createTemplateQueryStrategy() };
          // return {
          //   ...state,
          //   queryStrategy: createTemplateQueryStrategy({
          //     propertyMatcher: state.queryStrategy.propertyMatcher,
          //     ...(state.queryStrategy.mode !== QueryStrategy.DEFAULT && { query: state.queryStrategy.query }),
          //   }),
          // };
        }
        return { ...state, queryStrategy: createBulkQueryStrategy() };
      });
    },
    [setSQL],
  );

  const handleChangeQueryStrategyPropertyMatcherValue: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setSQL(state => {
        const newQueryStratery = {
          ...state.queryStrategy,
          propertyMatcher: { ...state.queryStrategy.propertyMatcher, value: event.target.value },
        } as DeviceQueryStrategy;
        return {
          ...state,
          queryStrategy: newQueryStratery,
        };
      });
    },
    [setSQL],
  );

  const handleChangeQueryStrategyPropertyMatcherDate: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setSQL(state => {
        const newQueryStratery = {
          ...state.queryStrategy,
          propertyMatcher: { ...state.queryStrategy.propertyMatcher, date: event.target.value },
        } as DeviceQueryStrategy;
        return {
          ...state,
          queryStrategy: newQueryStratery,
        };
      });
    },
    [setSQL],
  );

  const handleChangeQueryStrategyPropertyMatcherReference: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setSQL(state => {
        const newQueryStratery = {
          ...state.queryStrategy,
          propertyMatcher: { ...state.queryStrategy.propertyMatcher, reference: event.target.value },
        } as DeviceQueryStrategy;
        return {
          ...state,
          queryStrategy: newQueryStratery,
        };
      });
    },
    [setSQL],
  );

  const handleChangeQueryStrategyQuery: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setSQL(state => {
        const newQueryStratery = { ...state.queryStrategy, query: event.target.value as QueryStrategy };
        return { ...state, queryStrategy: newQueryStratery };
      });
    },
    [setSQL],
  );

  const handleChangeDefaultQueryStrategyVariables = useCallback(
    (newVariables: Record<string, string>) => {
      setSQL(state => {
        if (state.queryStrategy.mode === QueryStrategy.DEFAULT) {
          const newQueryStratery = { ...state.queryStrategy, variables: newVariables };
          return { ...state, queryStrategy: newQueryStratery };
        }
        return state;
      });
    },
    [setSQL],
  );

  const handleChangeTemplateQueryStrategyVariables = useCallback(
    (newVariables: string[]) => {
      setSQL(state => {
        if (state.queryStrategy.mode === QueryStrategy.TEMPLATE) {
          const newQueryStratery = { ...state.queryStrategy, variables: newVariables };
          return { ...state, queryStrategy: newQueryStratery };
        }
        return state;
      });
    },
    [setSQL],
  );

  const handleToggleSQLFormatting = useCallback(() => {
    setIsSQLFormattingEnable(state => !state);
  }, []);

  return (
    <>
      <FormPaper location={location} sx={{ p: 1.5 }}>
        <Typography mb={1.5} variant="h6">
          {t('title.acquisition')}
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <AdvancedTextField
              fullWidth
              select
              editing={isEditing}
              label={t('label.dialect')}
              value={sql.dialect}
              onChange={handleChangeDialect}
            >
              {availableDialects.map(dialect => (
                <MenuItem key={dialect} value={dialect}>
                  {dialect}
                </MenuItem>
              ))}
            </AdvancedTextField>
          </Grid>
          <Grid item xs={6}>
            <AdvancedNumberTextField
              fullWidth
              required
              description={t('description.loopTime')}
              editing={isEditing}
              InputProps={{ inputProps: { step: 100, min: 1000 } }} // SLE: check that // SLE: this step does not count in validator
              label={t('label.loopTime')}
              parser={inputValue => parseInt(inputValue, 10)}
              value={sql.loopTime}
              onChange={handleChangeLoopTime}
            />
          </Grid>
          {isSyncPointFieldAvailable && (
            <Grid item xs={papersContainerWidth > 400 ? 6 : 12}>
              <AdvancedTextField
                fullWidth
                select
                editing={isEditing}
                label={t('label.syncPoint')}
                value={syncPoint}
                onChange={handleChangeSyncPoint}
              >
                {availableSyncPoints.map(availableSyncPoint => (
                  <MenuItem key={availableSyncPoint} value={availableSyncPoint}>
                    {availableSyncPoint}
                  </MenuItem>
                ))}
              </AdvancedTextField>
            </Grid>
          )}
          {isSyncDelayFieldAvailable && syncDelay && (
            <Grid item xs={papersContainerWidth > 400 ? 6 : 12}>
              <SyncDelayInput isEditing={isEditing} label={t('label.syncDelay')} value={syncDelay} onChange={handleChangeSyncDelay} />
            </Grid>
          )}
        </Grid>
        <Collapse in={sql.dialect !== SQLDialect.BIGQUERY}>
          <Grid container pt={1} spacing={1}>
            <Grid item xs={papersContainerWidth > 400 ? 4 : 6}>
              <AdvancedTextFieldWithMask
                fullWidth
                required
                editing={isEditing}
                label={t('label.ip')}
                maskedInputProps={MaskFormat.ip}
                value={sql.dialect !== SQLDialect.BIGQUERY ? sql.host : ''}
                onChange={handleChangeHost}
              />
            </Grid>
            <Grid item xs={papersContainerWidth > 400 ? 2 : 6}>
              <AdvancedNumberTextField
                fullWidth
                required
                editing={isEditing}
                InputProps={{ inputProps: { min: 0 } }} // SLE: check that
                label={t('label.port')}
                parser={inputValue => parseInt(inputValue, 10)}
                value={sql.dialect !== SQLDialect.BIGQUERY ? sql.port : 0}
                onChange={handleChangePort}
              />
            </Grid>
            <Grid item xs={papersContainerWidth > 400 ? 6 : 12}>
              <AdvancedTextField
                fullWidth
                required
                editing={isEditing}
                label={t('label.database')}
                value={sql.dialect !== SQLDialect.BIGQUERY ? sql.database : ''}
                onChange={handleChangeDatabase}
              />
            </Grid>
            <Grid item xs={papersContainerWidth > 400 ? 6 : 12}>
              <AdvancedTextField
                fullWidth
                required
                editing={isEditing}
                label={t('label.username')}
                value={sql.dialect !== SQLDialect.BIGQUERY ? (sql.credentials?.username ?? '') : ''}
                onChange={handleChangeUsername}
              />
            </Grid>
            <Grid item xs={papersContainerWidth > 400 ? 6 : 12}>
              <AdvancedTextField
                fullWidth
                required
                autoComplete="new-password"
                editing={isEditing}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title={isPasswordHidden ? t('tooltip.hidePassword') : t('tooltip.seePassword')}>
                        <IconButton edge="end" size="large" onClick={handleTogglePasswordVisibility}>
                          {isPasswordHidden ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                label={t('label.password')}
                type={isPasswordHidden ? 'password' : 'text'}
                value={sql.dialect !== SQLDialect.BIGQUERY ? (sql.credentials?.password ?? '') : ''}
                onChange={handleChangePassword}
              />
            </Grid>
          </Grid>
        </Collapse>
      </FormPaper>
      <FormPaper location={location} sx={{ p: 1.5 }}>
        <Typography mb={1.5} variant="h6">
          {t('title.mode')}
        </Typography>
        <Stack gap={1}>
          <AdvancedTextField
            fullWidth
            select
            editing={isEditing}
            label={t('label.mode')}
            value={sql.queryStrategy.mode}
            onChange={handleChangeQueryStrategyMode}
          >
            {availableQueryStrategies.map(strategy => (
              <MenuItem key={strategy} value={strategy}>
                {t(`queryStrategyMode.${strategy}`)}
              </MenuItem>
            ))}
          </AdvancedTextField>
          <Alert severity="info">{t(`helper.queryStrategyMode.${sql.queryStrategy.mode}`)}</Alert>
        </Stack>
      </FormPaper>

      <Grid ref={papersContainerRef} container spacing={1.5}>
        <Grid item xs={papersContainerWidth > 400 ? 6 : 12}>
          <FormPaper location={location} sx={{ p: 1.5, height: 1 }}>
            <Typography mb={1.5} variant="h6">
              {t('title.propertyMatcher')}
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <AdvancedTextField
                  fullWidth
                  required
                  editing={isEditing}
                  label={t('label.date')}
                  value={sql.queryStrategy.propertyMatcher.date}
                  onChange={handleChangeQueryStrategyPropertyMatcherDate}
                />
              </Grid>
              <Grid item xs={12}>
                <AdvancedTextField
                  fullWidth
                  required
                  editing={isEditing}
                  label={t('label.value')}
                  value={sql.queryStrategy.propertyMatcher.value}
                  onChange={handleChangeQueryStrategyPropertyMatcherValue}
                />
              </Grid>
              {sql.queryStrategy.mode === QueryStrategy.BULK && (
                <Grid item xs={12}>
                  <AdvancedTextField
                    fullWidth
                    required
                    editing={isEditing}
                    label={t('label.reference')}
                    value={sql.queryStrategy.mode === QueryStrategy.BULK ? sql.queryStrategy.propertyMatcher.reference : ''}
                    onChange={handleChangeQueryStrategyPropertyMatcherReference}
                  />
                </Grid>
              )}
            </Grid>
          </FormPaper>
        </Grid>
        <Grid item xs={papersContainerWidth > 400 ? 6 : 12}>
          <FormPaper location={location} sx={{ p: 1.5, height: 1 }}>
            <Typography mb={1.5} variant="h6">
              {t('title.date')}
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {/* SLE: ask for change with TimeZone picker from FileImport */}
                <AdvancedTextField
                  fullWidth
                  required
                  editing={isEditing}
                  label={t('label.timeZone')}
                  value={sql.timeZone}
                  onChange={handleChangeTimeZone}
                />
              </Grid>
              <Grid item xs={12}>
                <DateTimeFormatPicker
                  fullWidth
                  required
                  editing={isEditing}
                  label={t('label.dateFormat')}
                  value={sql.dateFormat}
                  onChange={handleChangeDateFormat}
                />
              </Grid>
            </Grid>
          </FormPaper>
        </Grid>
      </Grid>

      <Collapse unmountOnExit in={sql.queryStrategy.mode === QueryStrategy.DEFAULT}>
        <FormPaper location={location} sx={{ p: 1.5 }}>
          <DefaultVariablesPicker
            isEditing={isEditing}
            value={sql.queryStrategy.mode === QueryStrategy.DEFAULT ? sql.queryStrategy.variables : {}}
            onChange={handleChangeDefaultQueryStrategyVariables}
          />
        </FormPaper>
      </Collapse>
      <Collapse unmountOnExit in={sql.queryStrategy.mode === QueryStrategy.TEMPLATE}>
        <FormPaper location={location} sx={{ p: 1.5 }}>
          <TemplateVariablesPicker
            isEditing={isEditing}
            value={sql.queryStrategy.mode === QueryStrategy.TEMPLATE ? sql.queryStrategy.variables : []}
            onChange={handleChangeTemplateQueryStrategyVariables}
          />
        </FormPaper>
      </Collapse>
      <Collapse unmountOnExit in={sql.queryStrategy.mode !== QueryStrategy.DEFAULT}>
        <FormPaper location={location} sx={{ p: 1.5 }}>
          {isEditing && (
            <>
              <Typography mb={1.5} variant="h6">
                {t('title.query')}
              </Typography>
              <AdvancedTextField
                fullWidth
                multiline
                required
                editing={isEditing}
                helperText={`${t('text.availableQueryVariables')} ${availableQueryVariables.join(', ')}`}
                margin="none"
                minRows={2}
                sx={{ mt: 1 }}
                value={sql.queryStrategy.mode !== QueryStrategy.DEFAULT ? sql.queryStrategy.query : ''}
                onChange={handleChangeQueryStrategyQuery}
              />
            </>
          )}
          {!isEditing && (
            <>
              <Stack alignItems="center" direction="row" gap={1} mb={1.5}>
                <Typography variant="h6">{t('title.query')}</Typography>
                {sql.queryStrategy.mode !== QueryStrategy.DEFAULT && (
                  <FormHelperText component="div" sx={{ m: 0 }} variant="outlined">
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <Link onClick={handleToggleSQLFormatting}>
                      {t(`label.${isSQLFormattingEnable ? 'disableSQLFormatting' : 'enableSQLFormatting'}`)}
                    </Link>
                  </FormHelperText>
                )}
              </Stack>
              {sql.queryStrategy.mode !== QueryStrategy.DEFAULT && (
                <>
                  {isSQLFormattingEnable && (
                    <Paper sx={{ px: 1, py: 0.5 }}>
                      <Typography component="pre" sx={{ fontFamily: 'monospace' }}>
                        {sqlFormatter.format(sql.queryStrategy.query)}
                      </Typography>
                    </Paper>
                  )}
                  {!isSQLFormattingEnable && <Typography>{sql.queryStrategy.query}</Typography>}
                </>
              )}
              {sql.queryStrategy.mode === QueryStrategy.DEFAULT && <></>}
            </>
          )}
        </FormPaper>
      </Collapse>
    </>
  );
};

export default SQLSettingsForm;
