import { useTheme } from '@mui/material';
import { DefaultTheme } from '@mui/styles';
import { TFunction } from 'i18next';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BatchInfo,
  BlockInfo,
  BlockTypeInfo,
  DataVariable,
  IsAlarmVariable,
  IsAliasVariable,
  IsBatchVariable,
  IsBlockTypeMetricVariable,
  IsBlockTypeParameterVariable,
  IsBlockVariable,
  IsCalculationVariable,
  IsDataVariable,
  IsModelVariable,
  IsPointsVariable,
  ListVariableInfo,
  ModelInfo,
  ModelKeyField,
  ShortAlarmInfo,
  ShortAliasInfo,
  VarCalc,
} from '@dametis/core';

import { getPhysicalQuantities } from 'config';
import { calculationToString } from 'functions/calculationToString';
import { isModelExplanatoryVariable } from 'functions/isModelExplanatoryVariable';
import { numberToSubscript } from 'functions/numberToSubscript';
import store, { useSelector } from 'store';
import { useAlarms } from 'store/api/alarms';
import { useAliases } from 'store/api/aliases';
import { useBlockTypes } from 'store/api/blockTypes.ts';
import { useBlocks } from 'store/api/blocks';
import { useModels } from 'store/api/models';
import { exhaustiveCheck } from 'types';

import { GetVarCalcKind, VarCalcKinds } from '../functions/tada/helpers.ts';

const emptyArray: [] = [];

export const useVariableUnit = (varCalc: DataVariable) => {
  const variables = useSelector(state => state.variables.byId);

  return useMemo(() => variables?.[varCalc.variableUuid]?.unit ?? '', [variables, varCalc]);
};

export const useGetVariableName = (): ((varCalc: VarCalc) => string) => {
  const { t } = useTranslation();

  const variables = useSelector(state => state.variables.byId);
  const batches = useSelector(state => state.batch.flattenBatches);

  const { data: alarms = emptyArray } = useAlarms();
  const { data: aliases = emptyArray } = useAliases();
  const { data: blocks = emptyArray } = useBlocks();
  const { data: blockTypes = emptyArray } = useBlockTypes();
  const { data: models = emptyArray } = useModels();

  return useCallback(
    (varCalc: VarCalc): string => {
      return getVariableName(
        varCalc,
        {
          variables,
          blocks,
          blockTypes,
          models,
          alarms,
          batches,
          aliases,
        },
        t,
      );
    },
    [alarms, aliases, batches, blockTypes, blocks, models, t, variables],
  );
};

export const useVariableName = (varCalc: VarCalc): string => {
  const getVarName = useGetVariableName();

  return useMemo(() => getVarName(varCalc), [getVarName, varCalc]);
};

/**
 * @deprecated use useVariableName instead
 */
export const getVariableName = (
  varCalc: VarCalc,
  {
    variables,
    blocks,
    blockTypes,
    models,
    alarms,
    batches,
    aliases,
  }: {
    variables: Record<string, ListVariableInfo>;
    blocks: BlockInfo[];
    blockTypes: BlockTypeInfo[];
    models: ModelInfo[];
    alarms: ShortAlarmInfo[];
    batches: BatchInfo[];
    aliases: ShortAliasInfo[];
  },
  t: TFunction,
): string => {
  const state = store.getState();

  if (IsDataVariable(varCalc)) {
    const variable = variables[varCalc.variableUuid];
    if (!variable) return t('global:text.unknownVariable');
    return `${variable.name}${variable.unit.length ? ` (${variable.unit})` : ''}`;
  }
  if (IsBlockVariable(varCalc)) {
    const { blockId } = varCalc;
    const block = blocks.find(storeBlock => storeBlock.uuid === blockId);
    if (!block) {
      return t('global:text.unknownBlock');
    }
    const variable = [...block.parameters, ...block.metrics].find(variable => variable.uuid === varCalc.blockKey);
    if (!variable) {
      return t('global:text.unknownVariable');
    }
    return t('lego:text.blockVariableWithPath', { block: block.name, variable: variable.name });
  }
  if (IsBlockTypeParameterVariable(varCalc) || IsBlockTypeMetricVariable(varCalc)) {
    return (
      state.variables.notSavedIdByName[varCalc.blockKey] ??
      blockTypes.flatMap(blockType => [...blockType.parameters, ...blockType.metrics]).find(variable => variable.uuid === varCalc.blockKey)
        ?.name ??
      t('global:text.unknownVariable')
    );
  }
  if (IsModelVariable(varCalc)) {
    const { modelUuid } = varCalc;
    const model = models.find(storeModel => storeModel.uuid === modelUuid);
    if (!model) return t('global:text.unknownModel');
    if (isModelExplanatoryVariable(varCalc)) {
      const variableIndex = model.xVars.findIndex(xVar => xVar.uuid === varCalc.modelXVarUuid);
      if (variableIndex < 0) return t('global:text.unknownVariable');
      const variableName = getVariableName(
        model.xVars[variableIndex].variable,
        {
          variables,
          blocks,
          blockTypes,
          models,
          alarms,
          batches,
          aliases,
        },
        t,
      );
      return t(`models:text.modelVariableWithPath.${ModelKeyField.X_VAR}`, {
        variableIndex: numberToSubscript(variableIndex + 1),
        variableName,
        model: model.name,
      });
    }
    return t(`models:text.modelVariableWithPath.${varCalc.modelKey}`, { model: model.name });
  }
  if (IsAlarmVariable(varCalc)) {
    const alarm = alarms.find(storeAlarm => storeAlarm.uuid === varCalc.alarmUuid);
    if (!alarm) return t('global:text.unknownVariable');
    return alarm.name;
  }
  if (IsBatchVariable(varCalc)) {
    const batch = batches.find(storeBatch => storeBatch.uuid === varCalc.batchUuid);
    if (!batch) return t('global:text.unknownBatch');
    return batch.name;
  }
  if (IsAliasVariable(varCalc)) {
    const alias = aliases.find(storeAlias => storeAlias.uuid === varCalc.aliasUuid);
    if (!alias) return t('global:text.unknownVariable');
    return alias.name;
  }
  if (IsCalculationVariable(varCalc) || IsPointsVariable(varCalc)) {
    return calculationToString(varCalc);
  }
  exhaustiveCheck(varCalc);
  return t('global:text.unknownVariable');
};

export const useVariableColor = (varCalc: VarCalc | null | undefined, defaultColor?: string): string => {
  const theme = useTheme();

  const variables = useSelector(state => state.variables.byId);

  const { data: blocks = emptyArray } = useBlocks();
  const { data: models = emptyArray } = useModels();

  return useMemo(
    () => getVariableColor(varCalc, { variables, blocks, models }, theme, defaultColor),
    [blocks, defaultColor, models, theme, varCalc, variables],
  );
};

/**
  @deprecated use useVariableColor instead
 */
export const getVariableColor = (
  varCalc: VarCalc | null | undefined,
  { variables, blocks }: { variables: Record<string, ListVariableInfo>; blocks: BlockInfo[]; models: ModelInfo[] },
  theme: DefaultTheme,
  defaultColor?: string,
): string => {
  const defCol = defaultColor ?? theme.palette.primary.main;
  if (!varCalc) return defCol;
  const physicalQuantities = getPhysicalQuantities(undefined, theme);
  if (IsDataVariable(varCalc)) {
    const variable = variables[varCalc.variableUuid];
    if (!variable) return defCol;
    return physicalQuantities[variable?.physicalQuantity]?.color;
  }
  if (IsBlockVariable(varCalc)) {
    const { blockId } = varCalc;
    const { blockKey } = varCalc;
    const block = blocks.find(storeBlock => storeBlock.uuid === blockId);
    if (!block) return defCol;
    const elements = [...block.parameters, ...block.metrics];
    const physicalQuantity = elements.find(elem => elem.uuid === blockKey)?.physicalQuantity;
    return (physicalQuantity && physicalQuantities[physicalQuantity]?.color) || defCol;
  }
  if (IsModelVariable(varCalc)) {
    return defCol;
  }
  return defCol;
};

export const useVariableKind = (varCalc: VarCalc): VarCalcKinds => {
  return useMemo(() => {
    const variableKind = GetVarCalcKind(varCalc);
    if (!variableKind) throw new Error();
    return variableKind;
  }, [varCalc]);
};
