import { Paper, Stack } from '@mui/material';
import { FC } from 'react';

import { ShortTagInfo } from '@dametis/core';

import { useTagsDict } from 'hooks/useTagsDict';
import { useSelector } from 'store';

import { BlankTooltip, BlankTooltipProps } from '../BlankTooltip/BlankTooltip';
import NestedTagChip from '../TagChip/NestedTagChip';

export interface NestedTagTooltipProps extends Omit<BlankTooltipProps, 'title'> {
  tags: ShortTagInfo[];
}

const NestedTagTooltip: FC<NestedTagTooltipProps> = ({ tags, ...props }) => {
  const siteId = useSelector(state => state.auth.selectedSite?.uuid);
  const { tagsDict } = useTagsDict(siteId);

  return (
    <BlankTooltip
      PopperProps={{ placement: 'bottom-start' }}
      title={
        <Paper elevation={8} sx={{ p: 1 }}>
          <Stack direction="row" gap={0.5}>
            {tags.map(tag => (
              <NestedTagChip
                key={tag.uuid}
                displayPath
                className="tagNode"
                pathPosition="row"
                sx={{ fontSize: 11 }}
                tag={tagsDict[tag.uuid]}
              />
            ))}
          </Stack>
        </Paper>
      }
      {...props}
    />
  );
};

export default NestedTagTooltip;
