import {
  FileDeviceSourceType,
  HttpAuthenticationType,
  MQTTPropertyMatcherType,
  MessageSecurityMode,
  OpcUaConnectionMode,
  QueryStrategy,
  UserTokenType,
} from '@dametis/core';

import { HTTP_AUTHENTICATION_TYPE_NONE } from 'components/Configuration/Devices/CreateDeviceModal/ProtocolSettingsForm/HttpSettingsForm/HttpAuthenticationForm';
import { PanelTab } from 'components/Configuration/Devices/DeviceRightPanel/DeviceRightPanel';

export default {
  title: {
    createDeviceModal: 'New device',
    connections: 'Connections',
    importedVariables: 'Imported variables',
    mcoSettings: 'Monitoring - Alert threshold',
    acquisition: 'Acquisition',
    jqTransformer: 'JQ transformer',
    propertyMatcher: 'Properties matching',
    date: 'Date',
    authentification: 'Authentification',
    connection: 'Connection',
    mode: 'Mode',
    query: 'Query',
    queries: 'Queries',
    noDelete: 'Unable to delete',
    brandDevicePicker: '$t(global:protocol.{{protocol}}) brand devices',
  },
  stepper: {
    common: 'Common',
    settings: 'Settings',
    variables: 'Variables',
  },
  label: {
    name: 'Name',
    protocol: 'Protocol',
    alarm: 'Alarm',
    brandDevice: 'Brand device',
    host: 'Host',
    ip: 'IP address',
    port: 'Port',
    uid: 'Unit ID',
    loopTime: 'Loop time (ms)',
    pauseAfterRequest: 'Pause',
    maxRegisterToReadByRequest: 'Max reg.',
    maxUnusedValue: 'Unused reg.',
    invertBytes: 'Invert bytes',
    invertWords: 'Invert words',
    invertDoubleWords: 'Invert double words',
    connectMethod: 'Connection method',
    endpoint: 'URL',
    securityPolicy: 'Security policy',
    securityMode: 'Security mode',
    userTokenType: 'Authentification type',
    userName: 'Username',
    password: 'Password',
    connectionNumber: 'Connection nº{{number}}',
    prefix: 'Prefix',
    suffix: 'Suffix',
    offset: 'Offset',
    addressOffset: 'Address offset',
    defaultVariableDataDelay: 'Expected frequency for data feedback',
    deviceHeartbeatFrequency: 'Expected frequency between two equipment runs',
    defaultVariableFrequency: 'Expected frequency between two points (by default)',
    instance: 'Instance',
    cron: 'Cron expression (UTC)',
    timeout: 'Timeout',
    method: 'HTTP Method',
    contentType: 'Content type',
    syncPoint: 'Sync. point',
    syncDelay: 'Sync. delay',
    filterPastPoints: 'Filter past points',
    endpoints: 'URLs',
    jqTransformer: 'JQ transformer',
    reference: 'Reference',
    date: 'Date',
    value: 'Value',
    timeZone: 'Time zone',
    dateFormat: 'Format',
    type: 'Type',
    username: 'Username',
    key: 'Key',
    path: 'Path',
    dialect: 'Dialect',
    database: 'Database',
    mode: 'Mode',
    query: 'Query',
    variables: 'Variables',
    rack: 'Rack',
    slot: 'Slot',
    localTSAP: 'Local TSAP',
    remoteTSAP: 'Remote TSAP',
    indicativeValues: 'Indicative values',
    logLevel: 'Log level',
    address: 'Adress',
    nodeId: 'Node ID',
    request: 'Request',
    id: 'ID',
    unit: 'Unit',
    disableSQLFormatting: 'Disable formatting',
    enableSQLFormatting: 'Enable formatting',
    fileImport: 'Import configuration',
    source: 'Data source',
    useSSL: 'Secured connection',
    accessKey: 'Access Key ID',
    secretKey: 'Secret Access Key',
    bucket: 'Bucket',
    internalSftpCreds: 'Your Dametis SFTP credentials',
    topic: 'Topic',
    uuid: 'UUID',
  },
  toast: {
    copyToClipboard: 'The value has been copied in the clipboard',
  },
  placeholder: {
    value: 'Value',
    key: 'Key',
    name: 'Name',
    defaultValue: 'Default value',
  },
  description: {
    addressOffset: 'Variables address offset',
    loopTime: 'Total loop time, in ms',
    pauseAfterRequest: 'Break time after each request, in ms',
    maxRegisterToReadByRequest: 'Maximal number of registers that could be read by request',
    maxUnusedValue: 'Maximal unused registers allowed to read to prevent generation of multiple requests',
  },
  button: {
    close: 'Close',
    previous: 'Previous',
    next: 'Next',
    create: 'Create',
    addConnection: 'Add connexion',
    downloadJSONTemplate: 'JSON Template',
    downloadCSVTemplate: 'CSV Template',
    upload: 'Upload',
    add: 'Add',
    edit: 'Edit',
    disable: 'Disable',
    enable: 'Enable',
    delete: 'Delete',
    cancel: 'Cancel',
    save: 'Save',
    select: 'Select',
    configuration: 'Configuration',
    refresh: 'Refresh',
    sourceType: {
      [FileDeviceSourceType.FILESYSTEM]: 'File',
      [FileDeviceSourceType.MINIO]: 'Minio / S3',
      [FileDeviceSourceType.SFTP]: 'SFTP',
      [FileDeviceSourceType.SFTP_INTERNAL]: 'Dametis SFTP',
    },
  },
  text: {
    noSelection: 'No selection',
    noBrandDevice: 'No brand device',
    noEndpoints: 'No endpoints',
    noVariable: 'No variable',
    availableQueryVariables: 'Available variables :',
    noDelete_one: 'Unable to delete this device while it has a variable.',
    noDelete_other: 'Unable to delete this device while it has variables ({{count}} associated variables).',
    default: 'Default',
    variables: 'Variables',
    deviceDisabled: 'Disabled device.',
    brandDeviceVariablesLength_zero: 'No variable',
    brandDeviceVariablesLength_one: '1 variable',
    brandDeviceVariablesLength_other: '{{count}} variables',
    unknownType: 'Unknown type',
    unknownBrand: 'Unknown brand',
    chooseTemplateBeforeAcquisitionSettings: 'Choose a protocol to access to acquisition settings and variables import',
    noFileImportsAvailable: 'No configuration available',
    noFileImport: 'No configuration',
  },
  tooltip: {
    hidePassword: 'Hide password',
    seePassword: 'See password',
    hideSecretKey: 'Hide secret access key',
    seeSecretKey: 'See secret access key',
    delete: 'Delete',
    copy: 'Copy',
    copyToClipboard: 'Copy to clipboard',
    editDevice: 'edit this device',
    disableDevice: 'disable this device',
    enableDevice: 'enable this device',
    deleteDevice: 'delete this device',
  },
  opcUaConnectionMode: {
    [OpcUaConnectionMode.HOST_AND_PORT]: 'Host & Port',
    [OpcUaConnectionMode.ENDPOINT]: 'URL',
  },
  securityPolicy: {
    Invalid: 'Invalid',
    None: 'None',
  },
  messageSecurityMode: {
    [`messageSecurityMode_${MessageSecurityMode.Invalid}`]: 'Invalid',
    [`messageSecurityMode_${MessageSecurityMode.None}`]: 'None',
    [`messageSecurityMode_${MessageSecurityMode.Sign}`]: 'Sign',
    [`messageSecurityMode_${MessageSecurityMode.SignAndEncrypt}`]: 'Sign and encrypt',
  },
  userTokenType: {
    [`userTokenType_${UserTokenType.UserName}`]: 'Username',
    [`userTokenType_${UserTokenType.Certificate}`]: 'Certificate',
    [`userTokenType_${UserTokenType.Anonymous}`]: 'Anonymous',
    [`userTokenType_${UserTokenType.IssuedToken}`]: 'Issued token',
    [`userTokenType_${UserTokenType.Invalid}`]: 'Invalid',
  },
  httpAuthenticationType: {
    [HttpAuthenticationType.BASIC]: 'Basic',
    [HttpAuthenticationType.AUTHORIZATION_HEADER]: 'Authorization header',
    [HttpAuthenticationType.QUERY_PARAM]: 'Query param',
    [HTTP_AUTHENTICATION_TYPE_NONE]: 'No authentification',
  },
  queryStrategyMode: {
    [QueryStrategy.DEFAULT]: 'Default',
    [QueryStrategy.BULK]: 'Bulk',
    [QueryStrategy.TEMPLATE]: 'Template',
  },
  mqttPropertyMatcherType: {
    [MQTTPropertyMatcherType.MAP_ON_PAYLOAD]: 'Message',
    [MQTTPropertyMatcherType.MAP_ON_TOPIC]: 'Topic',
  },
  slotInfo: {
    S71200CPU: '0 or 1',
    S71500CPU: '0 or 1',
    CP343: '0 (or see equipment configuration)',
  },
  helper: {
    queryStrategyMode: {
      [QueryStrategy.DEFAULT]:
        'In default mode, the requests are defined in the variables of the device. It is also possible to create global variables that can be used in these requests.',
      [QueryStrategy.BULK]:
        'In bulk mode, the request is defined in the device and is global to each variables, the variables will be retrieve with their reference.',
      [QueryStrategy.TEMPLATE]:
        'In template mode, the request is defined in the device and contains template variables which are populated during the configuration of the variable of the device.',
    },
    mqttPropertyMatcherType: {
      [MQTTPropertyMatcherType.MAP_ON_PAYLOAD]:
        'In Message mode, the reference is contained in the message and identified by property matching.',
      [MQTTPropertyMatcherType.MAP_ON_TOPIC]: 'In Topic mode, each variable has its topic as reference.',
    },
  },
  logLevel: {
    error: 'Error',
    warn: 'Warn',
    info: 'Info',
    verbose: 'Verbose',
    debug: 'Debug',
    skip: 'Skip',
  },
  panelTab: {
    [PanelTab.INFORMATIONS]: 'Informations',
    [PanelTab.REAL_VARIABLES]: 'Real Variables',
    [PanelTab.CREDENTIALS]: 'Credentials',
  },
  dropzone: {
    dropFile: 'Drop a document to upload',
    or: 'or',
    browse: 'Browse',
    acceptedFiles: 'Accepted files : .csv, .json',
    dropFileHere: 'Drop your file here',
    fileNotAllowed: 'This type of file is not accepted',
    csvTemplate: '.csv template',
    jsonTemplate: '.json template',
  },
  columns: {
    name: 'Name',
    lastPointValue: 'Last point',
    unit: 'Unit',
    lastPointDate: 'Date',
  },
  dataGrid: {
    noRowsLabel: 'No variable',
    footerTotalRows: 'Total variables :',
  },
};
