import { useMemo } from 'react';

import { NestedTag, NestedTagTrees_Flatten, NestedTag_BuildTrees, TagTreeInfo, UUID } from '@dametis/core';

import getEntitiesById from 'functions/getEntitiesById';
import { useSelector } from 'store';
import { useTagTrees } from 'store/api/tags';

const emptyTagTrees: TagTreeInfo[] = [];

export const useTagsDict = (
  siteId?: UUID,
): {
  tagsDict: Record<UUID, NestedTag>;
  nestedTagTrees: NestedTag[];
  isFetching: boolean;
  isLoading: boolean;
  flatTagTrees: NestedTag[];
} => {
  const defaultSiteId = useSelector(state => state.auth.selectedSite?.uuid);

  const { data: tagTrees = emptyTagTrees, isFetching, isLoading } = useTagTrees({ siteId: siteId ?? defaultSiteId });

  const buildedTagTrees = useMemo(() => NestedTag_BuildTrees(tagTrees), [tagTrees]);
  const flatTagTrees = useMemo(() => NestedTagTrees_Flatten(buildedTagTrees), [buildedTagTrees]);
  const tagsDict = useMemo(() => getEntitiesById<NestedTag>(flatTagTrees), [flatTagTrees]);

  return { tagsDict, nestedTagTrees: buildedTagTrees, isFetching, isLoading, flatTagTrees };
};
