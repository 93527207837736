import { Paper, useTheme } from '@mui/material';
import { FC, useMemo } from 'react';

import { BlockTypeConnection } from '@dametis/core';

import { setColorLightness } from 'functions/color';
import getEntitiesById from 'functions/getEntitiesById';
import { useBlockTypes } from 'store/api/blockTypes';

import ConnectionPreviewTitle from '../ConnectionPreviewTitle';

const emptyArray: [] = [];

export interface BlockTypeConnectionPreviewProps {
  connection: BlockTypeConnection;
}

const BlockTypeConnectionPreview: FC<BlockTypeConnectionPreviewProps> = ({ connection }) => {
  const theme = useTheme();

  const { data: blockTypes = emptyArray } = useBlockTypes();

  const blockTypesById = useMemo(() => getEntitiesById(blockTypes), [blockTypes]);

  const blockType = useMemo(
    () => (connection.blockTypeId === null ? null : blockTypesById[connection.blockTypeId]),
    [blockTypesById, connection.blockTypeId],
  );
  const color = useMemo(() => (blockType ? blockType.content.color : theme.palette.text.primary), [blockType, theme]);

  return (
    <Paper sx={{ background: setColorLightness(color, 93) }}>
      <ConnectionPreviewTitle blockType={blockType} connection={connection} />
    </Paper>
  );
};

export default BlockTypeConnectionPreview;
