import {
  BlockInfo,
  CalculationVariable,
  IsAliasVariable,
  IsBatchVariable,
  IsBlockVariable,
  IsDataVariable,
  IsModelVariable,
  ModelInfo,
  ModelKeyField,
  Operator,
  ShortVariableInfo,
  UUID,
} from '@dametis/core';
import { getSingleVariableCalculation } from '@dametis/mathjs';
import { UnitName } from '@dametis/unit';

import store from 'store';
import { selectBlocks } from 'store/api/blocks';
import { selectModelsResult } from 'store/api/models';

import { IsStoreRlmModel } from '../components/Model/types';
import { selectAliases } from '../store/api/aliases';

import { isModelExplanatoryVariable } from './isModelExplanatoryVariable';

// operateurs qui peuvent changer l'unit ; eg. INTEGRAL de kW devient kWH (pas de détection atm !)
const changingUnitOperators = [Operator.COUNT, Operator.INTEGRAL, Operator.DERIVATIVE, Operator.DISTINCT];

export const getCalculationUnitInEntities = (
  calculation: CalculationVariable,
  variableByIds: Record<UUID, ShortVariableInfo>,
  models: ModelInfo[],
  blocks: BlockInfo[],
  checkCalculationUnit = true,
): string | undefined => {
  // cas particulier : on peut sous condition changer l'unité depuis le VNC grâce à la propriété 'unit' de CalculationVariable
  if (checkCalculationUnit && calculation?.unit !== undefined && calculation.unit !== null) return calculation.unit.trim();
  // le seul cas ou on peut facilement détecter l'unit est le cas d'une seule dépendance dans la calculation
  const variable = getSingleVariableCalculation(calculation);
  if (variable === false) return undefined;
  // Si on a un opérateur qui change l'unit, on ne peut pas deviner l'unit
  if (
    (calculation.operator && changingUnitOperators.includes(calculation.operator)) ||
    (variable.operator && changingUnitOperators.includes(variable.operator))
  )
    return undefined;
  // l'unit d'une calculation avec une seule DataVariable est l'unit de cette celle-ci
  if (IsDataVariable(variable)) {
    return variableByIds[variable.variableUuid]?.unit;
  }
  // l'unit d'une calculation avec une seule BlockVariable est l'unit du blockKey associée
  if (IsBlockVariable(variable)) {
    const block = blocks.find(blockStore => blockStore.uuid === variable.blockId);
    if (!block) return undefined;
    const elements = [...block.parameters, ...block.metrics];
    return elements.find(element => element.uuid === variable.blockKey)?.unit;
  }
  // l'unit d'une calculation avec une seule ModelVariable est l'unit de la yVar (si la ModelVariable est la réelle, la modélisée, ou l'écart absolu)
  if (
    IsModelVariable(variable) &&
    (variable.modelKey === ModelKeyField.MODEL ||
      variable.modelKey === ModelKeyField.Y_VAR ||
      variable.modelKey === ModelKeyField.ABSOLUTE_GAP)
  ) {
    const model = models.find(modelStore => modelStore.uuid === variable.modelUuid);
    if (!IsStoreRlmModel(model)) return undefined;
    if (!model.yVar) throw new Error();
    const yVar = getSingleVariableCalculation(model.yVar.variable);
    if (yVar === false) return undefined;
    return getCalculationUnitInEntities(yVar, variableByIds, models, blocks, checkCalculationUnit);
  }
  // l'unit d'une calculation avec une seule ModelVariable qui est l'écart relatif est un pourcentage
  if (IsModelVariable(variable) && variable.modelKey === ModelKeyField.RELATIVE_GAP) {
    return UnitName.PERCENT;
  }
  // l'unit d'une calculation avec une seule ModelVariable qui est explicative de son modèle est sa variable unit
  if (IsModelVariable(variable) && isModelExplanatoryVariable(variable)) {
    return variableByIds[variable.modelKey]?.unit;
  }
  // l'unit d'une calculation avec une seule BatchVariable est undefined (techniquement bool 0 ou 1)
  if (IsBatchVariable(variable)) {
    return undefined;
  }
  // l'unit d'une calculation avec une seule AliasVariable est l'unit de la calculation de l'alias
  if (IsAliasVariable(variable)) {
    const { data: aliases = [] } = selectAliases()(store.getState());
    const alias = aliases.find(alias => alias.uuid === variable.aliasUuid);
    if (!alias) return undefined;
    return getCalculationUnit(alias.calculation);
  }
  return undefined;
};

export const getCalculationUnit = (
  calculation: CalculationVariable | null | undefined,
  checkCalculationUnit = true,
): string | undefined | undefined => {
  if (calculation === null || calculation === undefined) return undefined;

  const state = store.getState();
  const variableByIds = state.variables.byId;
  const { data: blocks = [] } = selectBlocks()(state);
  const { data: models = [] } = selectModelsResult()(state);

  return getCalculationUnitInEntities(calculation, variableByIds, models, blocks, checkCalculationUnit);
};
