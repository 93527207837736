import { AllTagUsagesKeys, ArrayToDict, EmptyTagUsages, TagUsagesInfo } from '@dametis/core';

export const getTagsUsages = (tags: { usages: TagUsagesInfo }[]): TagUsagesInfo =>
  AllTagUsagesKeys.reduce<TagUsagesInfo>((acc, usageKey) => {
    acc[usageKey] = Object.values(
      ArrayToDict(
        tags.flatMap(tag => tag.usages[usageKey]),
        entity => entity.uuid,
      ),
    );

    return acc;
  }, EmptyTagUsages());
