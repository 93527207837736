import { v4 as uuidv4 } from 'uuid';

import { MetricCategory, PhysicalQuantity, StandardBlockMetric, StandardBlockMetricBody } from '@dametis/core';

import { createCalculationVariable } from 'functions/createCalculationVariable';

export const createStandardBlockMetric = ({
  uuid = uuidv4(),
  name = '',
  unit = '',
  physicalQuantity = PhysicalQuantity.NONE,
  calculation = createCalculationVariable(),
  category = MetricCategory.METRIC,
  isVirtual = false,
  isSelected = false,
}: Partial<StandardBlockMetric>): StandardBlockMetric => ({
  uuid,
  name,
  unit,
  physicalQuantity,
  calculation,
  category,
  isVirtual,
  isSelected,
});

export const createStandardBlockMetricBody = ({
  uuid = uuidv4(),
  name = '',
  unit = '',
  physicalQuantity = PhysicalQuantity.NONE,
  calculation = createCalculationVariable(),
  category = MetricCategory.METRIC,
  isVirtual = false,
  isSelected = false,
}: Partial<StandardBlockMetricBody>): StandardBlockMetricBody => ({
  uuid,
  name,
  unit,
  physicalQuantity,
  calculation,
  category,
  isVirtual,
  isSelected,
});
