import { ReportType, StyleFormat, TextAlign, WidgetType } from '@dametis/core';

import { GridView } from '../../types/grid';
import { WidgetCategories } from '../../types/reports';

export default {
  title: {
    reports: 'Reports',
    myReports: 'My reports',
    generatedReports: 'Auto-generated reports',
    sharedReports: 'Shared with me',
    privateReports: 'Private',
    newReport: 'New report',
    editReport: 'Edit report',
    duplicateReport: 'Duplicate report',
    report: 'Report',
    report_withName: 'Report "{{name}}"',
    deleteReport: 'Delete report "{{name}}" ?',
    home: 'Choose the type of report:',
    comments: 'Comments',
    flows: 'Flows',
    nodes: 'Nodes',
    newFlow: 'New flow',
    styles: 'Styles',
    createReport: 'Create a report',
  },
  button: {
    cancel: 'Cancel',
    create: 'Create',
    createReport: 'Create a blank report',
    createReportFromModel: 'Create a report from another',
    edit: 'Edit',
    rename: 'Rename',
    save: 'Save',
    saveAs: 'Duplicate',
    close: 'Close',
    delete: 'Delete',
    duplicate: 'Duplicate',
    newReport: 'Report',
    yes: 'Yes',
    no: 'No',
    variable: 'Variable',
    print: 'Print',
    cantPrint: 'You cannot print in edit mode',
    addWidget: 'Add the widget',
    addSankeyFlow: 'Add',
    createSankeyFlow: 'Create',
    addVariable: 'Add a variable',
    addPlotBand: 'Add',
    addBatch: 'Add a batch',
    addStyledRule: 'Add',
    addText: 'Add text',
    addFlow: 'Add flow',
    addImage: 'Add image',
    addShortcut: 'Add shortcut',
    addPlayground: 'Add playground',
    share: 'Share',
    add: 'Add',
    remove: 'Remove',
    editTags: 'Edit tags',
    addComment: 'Add comment',
    duplicateWidget: 'Dupliquer le widget',
    deleteWidget: 'Supprimer le widget',
    insertColumn: 'Insert a column',
    insertRow: 'Insert a row',
    deleteColumn: 'Delete the column',
    deleteRow: 'Delete the row',
    saveStyleConfiguration: 'List',
    addStyleConfiguration: 'List',
    select: 'Select',
  },
  subtitle: {
    playground: 'Playground',
    playgroundTab: 'Tab',
    noPlaygroundTabSelected: 'No tab selected',
    minValue: 'min : {{min}}',
    maxValue: 'max : {{max}}',
    shownVariables: 'Shown variables',
  },
  placeholder: {
    title: 'Title',
    select: 'Select',
    reportName: 'Report name',
    text: 'Text',
    label: 'Label (not required)',
    value: 'Value',
    searchWidget: 'Search a widget',
    selectBatch: 'Select...',
    placeholder: 'Write something...',
  },
  allWidgets: {
    [WidgetCategories.CHARTS]: 'Charts',
    [WidgetCategories.TABLES]: 'Tables',
    [WidgetCategories.BASICS]: 'Basics',
    [WidgetCategories.PRETTY]: 'Minimalist charts',
    [WidgetType.LINE]: {
      name: 'Line chart',
      description: 'Displays a line chart.',
    },
    [WidgetType.BAR]: {
      name: 'Bar chart',
      description: 'Displays a bar chart.',
    },
    [WidgetType.PRETTY_BAR]: {
      name: 'Pretty bar chart',
      description: 'Displays a pretty bar chart.',
    },
    [WidgetType.PIE]: {
      name: 'Pie chart',
      description: 'Displays a pie chart.',
    },
    [WidgetType.PRETTY_PIE]: {
      name: 'Pretty pie chart',
      description: 'Displays a pretty pie chart.',
    },
    [WidgetType.CHRONO]: {
      name: 'Chronogram',
      description: 'Displays a chronogram.',
    },
    [WidgetType.PLAYGROUND]: {
      name: 'Playground',
      description: "Displays a playground's tab.",
    },
    [WidgetType.AMM]: {
      name: 'Average min max',
      description: 'Displays the average, minimum and maximum value of a variable.',
    },
    [WidgetType.PMY]: {
      name: 'Period MTD YTD',
      description: 'Displays the month-to-date and year-to-date value of a variable.',
    },
    staticTable: {
      cell_average: 'Average',
      cell_min: 'Min',
      cell_max: 'Max',
      cell_period: 'Period',
      cell_mtd: 'MTD',
      cell_ytd: 'YTD',
    },
    [WidgetType.TABLE]: {
      name: 'Custom table',
      description: 'Displays a custom table.',
    },
    [WidgetType.SUBPERIODS]: {
      name: 'Sub-period table',
      description: 'Displays a table of sub-periods.',
    },
    [WidgetType.TEXT]: {
      name: 'Text',
      description: 'Displays a custom text.',
    },
    [WidgetType.VARIABLE]: {
      name: 'Variable',
      description: "Displays a variable's value.",
    },
    [WidgetType.DATE]: {
      name: 'Date',
      description: "Displays the report's date",
    },
    [WidgetType.IMAGE]: {
      name: 'Image',
      description: 'Displays an image of your choice.',
    },
    [WidgetType.GAUGE]: {
      name: 'Gauge chart',
      description: 'Displays a gauge chart',
    },
    [WidgetType.SPLINE]: {
      name: 'Spline chart',
      description: 'Displays a spline chart.',
    },
    count: {
      name: 'Counter',
      description: 'Displays a counter.',
    },
    [WidgetType.SHORTCUTS]: {
      name: 'Shortcuts',
      description: 'Displays a shortcut list.',
    },
    [WidgetType.LINEAR_GAUGE]: {
      name: 'Linear gauge',
      description: 'Displays a linear gauge.',
    },
    periodic_comments: {
      name: 'Comments',
      description: 'Displays comments for the current period.',
    },
    [WidgetType.SANKEY]: {
      name: 'Sankey',
      description: 'Displays a sankey chart.',
    },
  },
  text: {
    widgets: 'Widgets',
    noReports: 'You have no reports yet',
    noReportInSite: 'No report available in this site',
    name: 'Name',
    type: 'Type',
    tag: 'Tags',
    shortCreatedAt: 'Created',
    shortUpdatedAt: 'Updated',
    updatedAt: 'Updated {{date}}',
    sharedBy: 'Shared by {{name}}',
    ownedBy: 'Owned by {{name}}',
    me: 'me',
    rows: 'rows',
    emptyRow: 'Empty row',
    displayLabel: 'Display labels',
    headerFormat: 'Header format',
    columns: 'columns',
    titleRow: 'Format cells as titles on the first row',
    titleCol: 'Format cells as titles on the first column',
    variable: 'Variable',
    batch: 'Batch',
    text: 'Text',
    macrofield: 'Macro field',
    color: 'Color',
    picto: 'Icon',
    editCell: 'Edit cell',
    errorName: 'Please set a name',
    unit: 'Unit ',
    autoUnit: 'Best unit',
    fontSize: 'Font size',
    small: 'Small',
    medium: 'Medium',
    large: 'Large',
    auto: 'Auto',
    showSubPeriods: 'Display sub-period data',
    nothingToDisplay: 'Nothing to display',
    total: 'Total',
    subperiodTotal_week: 'Week',
    subperiodTotal_month: 'Month',
    subperiodTotal_year: 'Year',
    data: 'Data',
    style: 'Style',
    plotBands: 'Plot Bands',
    noSelectedData: 'No selected data',
    noDefinedPlotBand: 'Undefined plot band',
    needMinAndMax: 'You need to set MIN and MAX',
    noAutoScale: 'Cannot automatically set scale with a math computation.',
    noStyledRules: 'No styled rules',
    rules: 'Rules',
    newReport: 'New report',
    noShortcut: 'No shortcut',
    copy: 'Copy',
    search: 'Search...',
    users: 'Users',
    list: 'List',
    public: 'Public',
    private: 'Private',
    shared: 'Shared',
    view: {
      [GridView.TILE]: 'Tile view',
      [GridView.LIST]: 'List view',
    },
    commentList: 'Comments list',
    noAssociatedProject: 'No associated project',
    noComment: 'No comment',
    commentsPeriod: {
      from: 'From {{from}}',
      to: 'to {{to}}',
    },
    commentsPagination: {
      nbComments_one: 'One comment',
      nbComments_other: '{{count}} comments',
      linkedTo_one: 'linked to',
      linkedTo_other: 'linked to',
      nbProjects_one: 'one project',
      nbProjects_other: '{{count}} projects',
      message_withProject:
        '$t(text.commentsPagination.nbComments, { "count": {{nbComments}} }) $t(text.commentsPagination.linkedTo, { "count": {{nbComments}} }) $t(text.commentsPagination.nbProjects, { "count": {{nbProjects}} })',
      message_withoutProject: '$t(text.commentsPagination.nbComments, { "count": {{nbComments}} })',
    },
    sortBy: 'sort by',
    types: {
      [ReportType.WEEK]: 'Weekly reports',
      [ReportType.MONTH]: 'Monthly reports',
      [ReportType.YEAR]: 'Annual reports',
      [ReportType.CUSTOM]: 'Custom reports',
    },
    noSite: 'No site',
    noSelectedSite: 'No selected site',
    editWidget: 'Edit widget',
    noText: 'No text',
    noVariable: 'No variable',
    noBatch: 'No batch',
    noImage: 'No image',
    noFlows: 'No flows',
    noPlayground: 'No playground',
    emptyFlows: 'No flows',
    emptyNodes: 'No nodes',
    flowDescription: 'Here you can define nodes and flows',
    nodeDescription: 'Flows use their source node configuration',
    noSelectedPlayground: 'No selected playground',
    noVariables: 'No variables',
    newGroup: 'New group',
    allReports: 'All reports',
  },
  image: {
    label: 'Image',
    button: 'Import an image',
    fitOption: 'Fit option',
    contain: 'Contain',
    cover: 'Cover',
  },
  input: {
    label: {
      newReport: 'Name',
      yes: 'Yes',
      no: 'No',
      format: {
        title: 'Format',
        [StyleFormat.BOLD]: 'Bold',
        [StyleFormat.ITALIC]: 'Italic',
        [StyleFormat.UNDERLINE]: 'Underlined',
      },
      align: {
        [TextAlign.LEFT]: 'Left',
        [TextAlign.CENTER]: 'Center',
        [TextAlign.RIGHT]: 'Right',
      },
      fontSize: 'Size',
      dataToDisplay: 'Display',
      reversed: 'Reversed colors',
      isLinkToPlayground: 'Open a playground',
      displayDuration: 'Display duration',
      displayHours: 'Display time',
      displayPeriod: 'Display current period',
      displayPagination: 'Display pagination',
      displayByProjects: 'Grouped by projects',
      displayOnlyOnCurrentPeriod: 'Display comments on current period',
      tags: 'Tags',
      type: 'Type',
      row: 'Label',
      data: 'Data',
      batch: 'Batch',
      source: 'Source',
      destination: 'Destination',
      unit: 'Unit',
      valueFormat: 'Format',
      name: 'Name',
      newNode: 'New node',
      displayLegend: 'Display legend',
      custom: 'Custom',
    },
    helperText: {
      batch: 'Select a batch',
      variable: 'Select a variable',
      existingName: 'Already used',
    },
    placeholder: {
      playgroundTab: 'Select a tab...',
      noPlaygroundTabs: 'No tab available',
    },
  },
  tooltip: {
    createReport: 'create a report',
    renameReport: 'rename this report',
    editReportTags: 'edit tags of this report',
    saveReport: 'save this report',
    editReport: 'edit this report',
    duplicateReport: 'duplicate this report',
    printReport: 'print this report',
    shareReport: 'share this report',
    undoReportChanges: 'undo changes on this report',
    deleteReport: 'delete this report',
    fontColor: 'Text color',
    fillColor: 'Background color',
    borderColor: 'Border color',
    picto: 'Pictogram',
    generatedReport: 'Auto-generated report cannot be edited or deleted',
    playgroundTabDisabled: 'Unavailable in a report',
    editComment: 'Edit comment',
    deleteComment: 'Delete comment',
    playground: 'Open a playground',
    openLink: 'Open link',
    addStyledRule: 'Add a rule',
    saveStyleConfiguration: 'Save these rules in a list',
    addStyleConfiguration: 'Use a list of rules',
    selectPlayground: 'Select playground',
    removePlayground: 'Remove playground',
    editVariable: 'Edit variable',
    deleteVariable: 'Delete variable',
  },
  form: {
    label: {
      shortcut: 'Shortcut',
      confidentiality: 'Confidentiality',
    },
    value: {
      confidentiality_private: 'Private',
      confidentiality_shared: 'Shared',
    },
    tooltip: {
      owner_shared: 'If this option is checked, this report be visible for all the users',
      owner_private: 'If this option is checked, this report will be only visible by you ',
      not_owner: 'You cannot change the confidentiality of this report because you are not the owner',
    },
  },
  types: {
    week: 'Weekly report',
    month: 'Monthly report',
    year: 'Annual report',
    custom: 'Custom report',
  },
  select: {
    reader: 'Reader',
    writer: 'Writer',
  },
  helperText: {
    reloadPlayground1: 'Edited?',
    reloadPlayground2: 'Reload.',
  },
  toast: {
    selectedReportCorrupted: 'The report selected is corrupted.',
    create: {
      success: 'The report was successfully created.',
      failure: 'An error occured while creating this report.',
    },
    save: {
      success: 'The report was successfully saved.',
      failure: 'An error occured while saving this report.',
    },
    delete: {
      success: 'The report was successfully deleted.',
      failure: 'An error occured while deleting this report.',
    },
    duplicate: {
      success: 'The report was successfully duplicated.',
      failure: 'An error occured while duplicating this report.',
    },
    print: {
      failure: 'An error occured while printing this report.',
    },
    subPeriodGroupByError: 'Sub-period widget is limited to 12 columns.',
  },
  error: {
    imageUpload_DEFAULT: 'Something wrong happened while importing your image. Please try again.',
    imageUpload_TOO_BIG: 'Your image size is more than 5MB. Please try again with a smaller image.',
  },
  tab: {
    data: 'Settings',
    format: 'Format',
    style: 'Style',
    shortcut: 'Shortcut',
  },
  label: {
    title: 'Title',
    subtitle: 'Subtitle',
    tags: 'Tags',
  },
};
