import { Checkbox, ListItem, ListItemAvatar, ListItemButton, ListItemButtonProps, ListItemText, Stack, Tooltip } from '@mui/material';
import { FC, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import PhysicalQuantityAvatar from 'components/UI/PhysicalQuantity/PhysicalQuantityAvatar';
import TypographyEllipse from 'components/UI/TypographyEllipse/TypographyEllipse.tsx';
import { PropsContext } from 'components/VNC/context';
import { getPhysicalQuantities } from 'config';
import { useSelector } from 'store';
import { useVncStore } from 'zustand/stores/vnc';

import { AreSameVarCalc } from '../../../../../functions/tada/helpers.ts';
import { TypographyNoBreak } from '../TypographyNoBreak';

export interface BlockElementVariableProps extends ListItemButtonProps {
  variableId: string;
}

const BlockElementVariable: FC<BlockElementVariableProps> = ({ variableId, ...props }) => {
  const { t } = useTranslation('vnc');

  const { multiple, defaultVariableOperator, defaultGlobalOperator } = useContext(PropsContext);

  const variablesById = useSelector(state => state.variables.byId);

  const selection = useVncStore(state => state.selection);
  const someSelected = useVncStore(state => Boolean(state.selection.length));
  const addToSelection = useVncStore(state => state.addToSelection);
  const removeByUuidFromSelection = useVncStore(state => state.removeByUuidFromSelection);

  const selected = useMemo(() => selection.some(varCalc => AreSameVarCalc(varCalc, { variableUuid: variableId })), [selection, variableId]);

  const variable = useMemo(() => variablesById[variableId], [variableId, variablesById]);

  const physicalQuantities = useMemo(() => getPhysicalQuantities(), []);
  const color = useMemo(() => physicalQuantities[variable?.physicalQuantity]?.color, [physicalQuantities, variable?.physicalQuantity]);

  const toggleElementInSelection = useCallback(() => {
    const calculation = { variableUuid: variableId, operator: defaultVariableOperator ?? defaultGlobalOperator ?? undefined };
    if (selected) {
      removeByUuidFromSelection(calculation);
    } else {
      addToSelection(calculation);
    }
  }, [addToSelection, defaultGlobalOperator, defaultVariableOperator, removeByUuidFromSelection, selected, variableId]);

  if (!variable) {
    return null;
  }

  return (
    <ListItem
      disablePadding
      secondaryAction={
        multiple ? (
          <Tooltip placement="right" title={t(selected ? 'tooltip.removeFromSelection' : 'tooltip.addToSelection')}>
            <Checkbox
              checked={selected}
              edge="end"
              sx={[color !== undefined && { color: `${color} !important` }]}
              onClick={toggleElementInSelection}
            />
          </Tooltip>
        ) : undefined
      }
    >
      <ListItemButton {...props} disableGutters selected={selected} onClick={someSelected ? toggleElementInSelection : props.onClick}>
        <Stack alignItems="left" justifyContent="center" spacing={0.5} width="100%">
          <Stack alignItems="center" direction="row">
            <ListItemAvatar>
              <PhysicalQuantityAvatar physicalQuantity={variable.physicalQuantity} size="default" />
            </ListItemAvatar>
            <ListItemText
              primary={
                <TypographyEllipse noWrap variant="h6">
                  {variable.name}
                </TypographyEllipse>
              }
            />
          </Stack>
          <TypographyNoBreak variant="caption">{t('list.text.variable')}</TypographyNoBreak>
        </Stack>
      </ListItemButton>
    </ListItem>
  );
};

export default BlockElementVariable;
