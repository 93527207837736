import { useMemo } from 'react';
import type { Entries } from 'type-fest';

import { ModelInfo } from '@dametis/core';
import { getSingleVariableCalculation } from '@dametis/mathjs';

import { useGetVariableName } from 'hooks/useVariableProp';

export interface CategorizedModels {
  others: ModelInfo[];
  [key: string]: ModelInfo[];
}

export const useCategorizedModels = (models: ModelInfo[], disableSort = false) => {
  const getVariableName = useGetVariableName();

  const categories = useMemo(() => {
    return models.reduce<CategorizedModels>(
      (acc, cur) => {
        if (!cur.yVar) {
          acc.others.push(cur);
          return acc;
        }
        const singleVariable = getSingleVariableCalculation(cur.yVar.variable);
        if (!singleVariable) {
          acc.others.push(cur);
          return acc;
        }
        const singleVariableName = getVariableName(singleVariable);
        acc[singleVariableName] ??= [];
        acc[singleVariableName].push(cur);
        return acc;
      },
      {
        others: [],
      },
    );
  }, [models, getVariableName]);

  const sortedCategories = useMemo(() => {
    const { others, ...categoriesWithoutOthers } = categories;
    let sorted: Entries<CategorizedModels> = Object.entries(categoriesWithoutOthers).toSorted(([a], [b]) => a.localeCompare(b));
    if (!disableSort) {
      sorted = sorted.map(([key, value]) => [key, value.toSorted((a, b) => a.name.localeCompare(b.name))]);
    }
    return sorted;
  }, [categories, disableSort]);

  return useMemo(
    () => [...sortedCategories, ...(categories.others.length ? [['others', categories.others] as const] : [])],
    [sortedCategories, categories.others],
  );
};
