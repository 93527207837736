import i18next from 'i18next';
import { cloneDeep } from 'lodash-es';

import { AUTO_GROUPBY, AnyGroupBy, CalculationVariable, NewUUID, Period, PromiseLimit, RegressionResult } from '@dametis/core';

import DaArearangeSeries from 'classes/DaCharts/DaArearangeSeries';
import { DaAxis } from 'classes/DaCharts/DaAxis';
import { BOOST_POINTS_THRESHOLD } from 'classes/DaCharts/DaChart';
import DaLineSeries from 'classes/DaCharts/DaLineSeries';
import DaScatterSeries from 'classes/DaCharts/DaScatterSeries';
import { getCalculationUnit } from 'functions/getCalculationUnit';
import { getGroupBy } from 'functions/tada/getGroupBy';
import { PLAYGROUND_TARGET_POINTS, TADA_MAX_POINTS } from 'functions/tada/tada';
import { localizedFormat } from 'localization/localizedDateFns';
import { sdk } from 'sdk';
import { TypedThunk } from 'store';
import { displaySdkErrorToast } from 'store/actions/toasts';
import { getBoxesMaxLoopTime, selectBoxes } from 'store/api/boxes';
import {
  xychartAddXvariable,
  xychartAddYvariable,
  xychartDeleteXvariable,
  xychartDeleteYvariable,
  xychartUpdateGroupby,
  xychartUpdateYvariable,
} from 'store/slices/playground';

import { ITab, IXyChart, IXyChartProps, IXyVariable, IsXyChartTab, isXVariableIndex } from '../../types';
import { createChart, setDaChart } from '../Chart';
import { chartToPng, getStoreTab } from '../shared';

import { exportXyVariable, getStoreXXyVariable, getStoreYXyVariable, getXyVariableData, updateYXyVariable } from './XyVariable';

export const initYXyVariableSeries =
  (tab: ITab<IXyChart>, variable: IXyVariable, yAxis: DaAxis): TypedThunk<void> =>
  (dispatch, getState) => {
    const { withYAxisVisible } = getState().playground;
    const storeTab = dispatch(getStoreTab(tab.uuid));
    const storeVariable = dispatch(getStoreYXyVariable(variable, tab.uuid));
    if (!IsXyChartTab(storeTab) || !storeVariable) return;
    try {
      const visible = storeVariable?.visible === undefined ? true : storeVariable.visible;
      const daSeries = storeTab.chart.daChart.addSeries({
        name: variable.name,
        yAxis,
        color: variable.color,
        // apiUnit: getCalculationUnit(variable.expression),
        unit: variable.unit ?? getCalculationUnit(variable.expression),
        style: {
          type: 'scatter',
        },
        pretty: false,
        custom: {
          styledRules: storeVariable.styledRules,
        },
        hidden: !visible,
        min: variable.min,
        max: variable.max,
        labels: {
          enabled: withYAxisVisible,
        },
      }) as DaScatterSeries;
      const regressionSeries = storeTab.chart.daChart.addSeries({
        name: `(${i18next.t('playground:title.regression')}) ${variable.name}`,

        // apiUnit: getCalculationUnit(variable.expression),
        unit: variable.unit ?? getCalculationUnit(variable.expression),
        yAxis: daSeries.yAxis,
        lineWidth: 1,
        color: variable.color,
        tooltip: {
          valueSuffix: variable.unit,
        },
        hidden: !visible || !storeVariable.regression,
        style: {
          type: 'line',
        },
        dashStyle: 'Solid',
        marker: {
          radius: 2,
          symbol: 'circle',
        },
        states: {
          inactive: {
            opacity: 1,
          },
          hover: {
            lineWidthPlus: 0,
          },
        },
      }) as DaLineSeries;
      const areaRangeSeries = variable?.areaRangeParams
        ? variable?.areaRangeParams.map(
            areaParam =>
              storeTab.chart.daChart.addSeries({
                type: 'arearange',
                // unit: getCalculationUnit(variable.expression),
                selectedUnit: variable.unit ?? getCalculationUnit(variable.expression),
                style: {
                  type: 'arearange',
                },
                uuid: areaParam.uuid,
                name: areaParam.name,
                color: areaParam.color,
                area: true,
                yAxis: daSeries.yAxis,
                hidden: !visible,
                disableBoost: true,
              }) as DaArearangeSeries,
          )
        : [];
      dispatch(
        xychartUpdateYvariable({
          tabUuid: tab.uuid,
          variableUuid: variable.uuid,
          options: { daSeries, regressionSeries, areaRangeSeries },
        }),
      );
    } catch (err) {
      console.error(err);
    }
  };

export const addYXyVariableToDaChart =
  (tab: ITab<IXyChart>, variable: IXyVariable): TypedThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const storeTab = dispatch(getStoreTab(tab.uuid));
    const storeVariable = dispatch(getStoreYXyVariable(variable, tab.uuid));
    if (!IsXyChartTab(storeTab) || !storeVariable) return;
    const { withYAxisVisible } = getState().playground;
    const yAxis =
      storeTab.chart.yVariables?.length && storeTab.chart.yVariables[0].daSeries?.yAxis
        ? storeTab.chart.yVariables[0].daSeries.yAxis
        : storeTab.chart.daChart.addAxis({
            color: '#92a4b0',
            id: NewUUID(),
            pretty: false,
            labelsEnabled: withYAxisVisible,
          });
    dispatch(initYXyVariableSeries(tab, storeVariable, yAxis));
    if (isXVariableIndex(storeTab.chart.xVariable)) {
      await dispatch(fetchXyChartData(storeTab));
    } else {
      await getXyVariableData(variable, storeTab);
    }
  };

export const addXXyVariableToDaChart =
  (tab: ITab<IXyChart>): TypedThunk<void> =>
  dispatch => {
    const storeTab = dispatch(getStoreTab(tab.uuid));
    const storeVariable = dispatch(getStoreXXyVariable(storeTab.uuid));
    if (!IsXyChartTab(storeTab) || !storeVariable) return;
    try {
      storeTab.chart.daChart.setUnit(
        isXVariableIndex(storeVariable) ? null : storeVariable.unit,
        isXVariableIndex(storeVariable) ? null : getCalculationUnit(storeVariable.expression),
      );
    } catch (err) {
      console.error(err);
    }
  };

export const createXyChart = ({
  daChart = null,
  yVariables = [],
  xVariable = null,
  groupBy = AUTO_GROUPBY,
}: IXyChartProps = {}): IXyChart => {
  const chart = createChart({ daChart }) as IXyChart;
  return { ...chart, yVariables, xVariable, groupBy };
};

export const exportXyChart = async (chart: IXyChart): Promise<IXyChart> => {
  if (!chart) return null;
  const { daChart, yVariables, xVariable } = chart;
  const preview = await chartToPng(daChart);
  return {
    ...chart,
    preview,
    daChart: null,
    yVariables: yVariables.map(exportXyVariable),
    xVariable: isXVariableIndex(xVariable) ? xVariable : exportXyVariable(xVariable),
  };
};

export const getXyChartData =
  (tab: ITab<IXyChart>): TypedThunk<Promise<void>> =>
  async dispatch => {
    await dispatch(fetchXyChartData(tab));
  };

// ACTIONS

export const addYXyVariableToXyChart =
  (tab: ITab<IXyChart>, variable: IXyVariable): TypedThunk<Promise<void>> =>
  async dispatch => {
    const storeTab = dispatch(getStoreTab(tab.uuid));
    dispatch(xychartAddYvariable(variable));
    if (IsXyChartTab(storeTab)) {
      await dispatch(addYXyVariableToDaChart(storeTab, variable));
      storeTab.chart.daChart.redraw();
    }
  };

export const deleteYXyVariableFromXyChart =
  (chart: IXyChart, variable: IXyVariable): TypedThunk<void> =>
  dispatch => {
    variable.areaRangeSeries?.forEach(areaRangeSerie => chart.daChart.removeSeries(areaRangeSerie));
    chart.daChart.removeSeries(variable.regressionSeries);
    chart.daChart.removeSeries(variable.daSeries);
    dispatch(xychartDeleteYvariable(variable));
    chart.daChart.redraw();
  };

export const addXXyVariableToXyChart =
  (tab: ITab<IXyChart>, variable: IXyChart['xVariable']): TypedThunk<Promise<void>> =>
  async dispatch => {
    dispatch(xychartAddXvariable(variable));
    await dispatch(fetchXyChartData(tab));
    dispatch(addXXyVariableToDaChart(tab));
    tab.chart.daChart.redraw();
  };

export const deleteXXyVariableFromXyChart =
  (tab: ITab<IXyChart>): TypedThunk<Promise<void>> =>
  async dispatch => {
    await PromiseLimit.do(
      tab.chart.yVariables,
      async v => {
        v.daSeries.setData([]);
        v.regressionSeries.setData([]);
        v.areaRangeSeries?.forEach(areaRangeSerie => areaRangeSerie.setData([]));
        await dispatch(updateYXyVariable(v, { regressionParams: null }, tab));
      },
      { settle: true },
    );
    dispatch(xychartDeleteXvariable());
    tab.chart.daChart.redraw();
  };

export const fetchYVariableRegressionData = async (
  groupId: string,
  variable: IXyVariable,
  period: Period,
  xExp: CalculationVariable,
): Promise<RegressionResult> => {
  if (!variable.regression) {
    return {
      formula: '',
      points: [],
      coefficients: [],
      r2: null,
      r2adjusted: null,
      mse: null,
      rmse: null,
      cvrmse: null,
      msle: null,
      rmsle: null,
      mape: null,
      smape: null,
    };
  }
  const { data } = await sdk.data.Regression(groupId, {
    x: [{ ...xExp, period: period.Raw() }],
    y: { ...variable.expression, period: period.Raw() },
    regressionType: variable.regression,
    pivot: 'x',
  });
  return data;
};

export const fetchXyChartData =
  (currentTab: ITab<IXyChart>): TypedThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const tab = dispatch(getStoreTab(currentTab.uuid));
    if (!IsXyChartTab(tab)) return;
    const { groupBy, xVariable, yVariables } = tab.chart;
    const {
      auth: {
        selectedGroup: { uuid: groupId, sites },
        selectedSite,
      },
      period: {
        present: { period },
      },
    } = getState();
    if (!xVariable || !yVariables?.length) return;

    const { data: boxes = [] } = selectBoxes()(getState());
    const maxLoopTime = getBoxesMaxLoopTime(boxes);
    const timeZone = selectedSite ? selectedSite.timeZone : sites?.at(0).timeZone || undefined;
    let autoGroupByReplacement = groupBy;
    if (autoGroupByReplacement === undefined || autoGroupByReplacement === AUTO_GROUPBY) {
      autoGroupByReplacement = getGroupBy(new Date(period.from), new Date(period.to), null, maxLoopTime, PLAYGROUND_TARGET_POINTS);
    }
    const xExp: CalculationVariable = isXVariableIndex(xVariable)
      ? cloneDeep({
          ...{
            exp: 'index',
            vars: {
              var_0: {
                exp: yVariables.map((_, i) => `var_${i}`).join(' + '),
                vars: yVariables.reduce((acc, xVar, index) => {
                  return { ...acc, [`var_${index}`]: xVar.expression };
                }, {}),
              },
            },
            autoGroupByReplacement,
            maxPoints: PLAYGROUND_TARGET_POINTS,
          },
          period: period.Raw(),
          timeZone,
        })
      : cloneDeep({ ...xVariable.expression, autoGroupByReplacement, period: period.Raw(), timeZone, maxPoints: TADA_MAX_POINTS });
    const yExp: CalculationVariable[] = yVariables.map(v =>
      cloneDeep({ ...v.expression, autoGroupByReplacement, period: period.Raw(), timeZone, maxPoints: TADA_MAX_POINTS }),
    );
    try {
      const { data } = await sdk.data.XY(groupId, {
        x: xExp,
        y: yExp,
      });
      // const singleXVar = getSingleVariableCalculation(xExp);
      // if (!isXVariableIndex(xVariable) && xVariable.unit == null && IsDataVariable(singleXVar)) {
      //   const { unit: xUnit } = byId[singleXVar.variableUuid];
      //   await dispatch(updateXXyVariable(xVariable, { unit: xUnit }, tab));
      // }
      await PromiseLimit.do(
        yVariables,
        async (yVar, i) => {
          const storeVariable = dispatch(getStoreYXyVariable(yVar, tab.uuid));
          if (!storeVariable) return;
          const parsedYVariable: IXyVariable = {
            ...storeVariable,
            expression: { ...storeVariable.expression, autoGroupByReplacement, timeZone, maxOutputPoints: TADA_MAX_POINTS },
          };
          const { daSeries, regressionSeries, areaRangeSeries } = parsedYVariable;
          try {
            let dataMin: number;
            let dataMax: number;
            if (data.points[i].length > BOOST_POINTS_THRESHOLD) {
              daSeries.addboostData(
                data.points[i].map(p => {
                  if (dataMin === undefined || p.x < dataMin) dataMin = p.x;
                  if (dataMax === undefined || p.x > dataMax) dataMax = p.x;
                  return [p.x, p.y, localizedFormat(new Date(p.time), 'eee PPpp')];
                }),
              );
            } else {
              daSeries.addData(
                data.points[i].map(p => {
                  if (dataMin === undefined || p.x < dataMin) dataMin = p.x;
                  if (dataMax === undefined || p.x > dataMax) dataMax = p.x;
                  return { x: p.x, y: p.y, custom: { time: localizedFormat(new Date(p.time), 'eee PPpp') } };
                }),
              );
            }
            areaRangeSeries?.forEach(areaRangeSerie => {
              const areaParam = parsedYVariable.areaRangeParams?.find(p => p.uuid === areaRangeSerie.uuid);
              if (areaParam) {
                areaRangeSerie.setData([
                  [dataMin, Number(areaParam.left.min), Number(areaParam.left.max)],
                  [dataMax, Number(areaParam.right.min), Number(areaParam.right.max)],
                ]);
              }
            });
            const { points, ...regressionParams } = await fetchYVariableRegressionData(groupId, parsedYVariable, period, xExp);
            regressionSeries.addData(points);
            await dispatch(
              updateYXyVariable(
                parsedYVariable,
                {
                  regressionParams: {
                    ...regressionParams,
                    r2: regressionParams.r2,
                  },
                  daSeries,
                  regressionSeries,
                  areaRangeSeries,
                },
                tab,
              ),
            );
          } catch (err) {
            console.error(err);
            dispatch(displaySdkErrorToast(err));
          }
        },
        { settle: true },
      );
    } catch (err) {
      console.error(err);
    }
  };

export const setDaChartToXyChart =
  (tab: ITab<IXyChart>, container: HTMLDivElement): TypedThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const { withYAxisVisible } = getState().playground;
    const daChart = dispatch(
      setDaChart(tab.chart, container, {
        type: 'scatter',
        periodZoom: false,
        legend: false,
        height: null,
      }),
    );

    const yAxis = daChart.addAxis({
      color: '#92a4b0',
      id: NewUUID(),
      pretty: false,
      min: tab.chart.yVariables?.length ? tab.chart.yVariables[0].min : null,
      max: tab.chart.yVariables?.length ? tab.chart.yVariables[0].max : null,
      labelsEnabled: withYAxisVisible,
    });
    tab.chart.yVariables.map(v => dispatch(initYXyVariableSeries(tab, v, yAxis)));
    await dispatch(fetchXyChartData(tab));
    dispatch(addXXyVariableToDaChart(tab));
    daChart.redraw();
  };

export const updateXyChartGroupBy =
  (tab: ITab<IXyChart>, groupBy: AnyGroupBy): TypedThunk<Promise<void>> =>
  async dispatch => {
    dispatch(xychartUpdateGroupby(groupBy));
    await dispatch(fetchXyChartData(tab));
    tab.chart.daChart.redraw();
  };
