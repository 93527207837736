import { GridLocaleText } from '@mui/x-data-grid-premium';

import { MQTTPropertyMatcherType } from '@dametis/core';

import { CsvParserStep } from 'components/Configuration/Variables/ImportCalculatedVariablesModal/flam';
import { BoxVersionStatus } from 'types/box';

export default {
  title: {
    variables: 'Variables',
    architecture: 'Arquitectura',
    export: 'Exportar',
    newVariable: 'Nueva variable',
    editVariable: 'Modificar la variable',
    editVariable_other: 'Modificar {{count}} variables',
    variableDetails: 'Detalles de la variable',
    newDevice: 'Nuevo equipo',
    editDevice: 'Modificar un equipo',
    deleteDevice_one: 'Vaciar un equipo',
    exportVariables: 'Variables',
    exportPeriodicity: 'Periodo',
    editExp: 'Modificación de una variable calculada',
    editExpLabel: '¿Cuándo deben aplicarse los cambios?',
    noDelete: 'Eliminación imposible',
    newBox: 'Nueva caja',
    groupSettings: 'Parámetros del grupo',
    groupBoxes: 'Cajas',
    createList: 'Nueva lista',
    renameList: 'Renombrar la lista',
    preview: 'Vista previa',
    importCalculatedVariablesModal: 'Importar variables calculadas',
    editBoxAlertThresholds: 'Editar umbrales de alerta',
    connections: 'Conexiones',
    exploration: 'Exploración',
    accessibility: 'Accesibilidad',
  },
  text: {
    requests: 'Consultas',
    modbusTcp: 'Modbus',
    opcUa: 'OpcUa',
    sql: 'SQL',
    mqtt: 'MQTT',
    file: 'Archivo',
    s7: 'S7',
    http: 'HTTP',
    bacnet: 'BACnet',
    calculated: 'Calculada',
    real: 'Adquirida',
    manual: 'Manual',
    constant: 'Constante',
    options: 'Opciones',
    number: 'Número',
    string: 'Cadena de caracteres',
    boolean: 'Booleano',
    selectedVariables_one: 'una variable seleccionada',
    selectedVariables_other: '{{count}} variables seleccionadas',
    deleteDevice: '¿Estás seguro de que deseas vaciar {{name}}?',
    customName: 'Equipo Libre',
    previewDisabled: 'Vista previa temporalmente desactivada para las tablas transponibles con más de 100 000 puntos',
    uploadFileError: {
      incorrectType: 'Tipo de archivo incorrecto, solo se aceptan JSON y CSV.',
      parsingError: 'Se produjo un error durante el procesamiento del archivo, por favor inténtalo de nuevo más tarde.',
      invalidStructure: 'El archivo CSV es inválido, descarga una plantilla para verificar la estructura requerida.',
    },
    allDevices: 'Todos los equipos',
    dateStart: 'Fecha de inicio',
    dateEnd: 'Fecha de fin',
    interval: 'Intervalo',
    unit: 'Unidad',
    lastSync: 'Última sincronización: {{datetime}}',
    version: {
      [BoxVersionStatus.SUCCESS]: 'Tu caja Dametis está sincronizada',
      [BoxVersionStatus.DEFAULT]: 'La caja Dametis está en proceso de sincronización',
      [BoxVersionStatus.WARNING]: 'Tu caja Dametis está actualmente fuera de línea. Se sincronizará en su próxima conexión.',
      [BoxVersionStatus.ERROR]: 'La sincronización con la caja Dametis ha fallado. Por favor, contacta al soporte.',
    },
    deleteVariable:
      'La eliminación de esta variable puede afectar el funcionamiento de las herramientas (Informes, Sinópticos, Playgrounds, etc...) que la utilizan. Verifica su uso antes de vaciarla.',
    noOptions: 'Ninguna opción',
    clear: 'Vaciar',
    close: 'Cerrar',
    variables: 'Variables',
    open: 'Abrir',
    addTagOption: 'Añadir la opción « {{name}} »',
    editExp: {
      confirm: 'los datos de {{name}} se calcularán con:',
      name: 'esta variable',
      dateConfirm: 'a partir del {{date}},',
    },
    thisVar: 'esta variable',
    oldExp: 'Expresión anterior',
    newExp: 'Nueva expresión',
    editExpConfirm: {
      date: 'a partir del {{date}},',
      name: 'los datos de {{name}} se calcularán con:',
    },
    from: {
      now: 'Los datos ya calculados hasta ahora se conservarán (expresión anterior).\nLa nueva expresión tendrá efecto para todos los nuevos datos.',
      date: 'Los datos ya calculados hasta la fecha seleccionada se conservarán (expresión anterior).\nLa nueva expresión tendrá efecto para todos los datos a partir de esa fecha.',
      first: 'Todos los datos serán recalculados con la nueva expresión.',
    },
    noDelete_one: 'Imposible vaciar este equipo mientras tenga variables asociadas ({{count}} variable asociada).',
    noDelete_other: 'Imposible vaciar este equipo mientras tenga variables asociadas ({{count}} variables asociadas).',
    realVariableInfo: 'Una variable adquirida es una variable cuyos datos se reciben de un equipo',
    calculatedVariableInfo: 'Una variable calculada es una variable basada en otras variables, y cuya expresión determina su valor',
    manualVariableInfo: 'Una variable manual es una variable cuyos valores se definirán mediante una importación manual',
    constantVariableInfo: 'Una variable constante es una variable cuyo valor no cambia',
    variablesImport: 'Importar variables',
    importVariablesInfo: 'Carga un archivo de variables siguiendo uno de los siguientes modelos:',
    importFileTemplatesInfo_one: 'Carga un archivo siguiendo el siguiente modelo:',
    importFileTemplatesInfo_other: 'Carga un archivo siguiendo uno de los siguientes modelos:',
    notAvailable: 'Opción no disponible',
    identification: 'Identificación',
    dialect: 'Dialecto',
    group: 'Grupo/Sitio',
    hardwareInfo: 'Material',
    lanUsine: 'Lan Fábrica',
    lanOut: 'Lan Salida',
    stepper: {
      step: 'Etapa',
      step2: 'Rellena la información',
      step3: 'Configuración IP',
      step4: 'Rutas personalizadas (opcional)',
    },
    customRoute: 'Ruta personalizada',
    users: 'Usuarios',
    noUser: 'Sin usuarios.',
    form: 'Formulario',
    import: 'Importar',
    canNotChangeKind: 'No puedes cambiar el tipo de una variable duplicada.',
    noSelectedVariable: 'Ninguna variable seleccionada.',
    activities: 'Actividades',
    lastModification: 'última modificación {{date}}',
    csvExample: {
      name: 'EJEMPLO',
      description: 'suprimir esta línea',
      utilities: 'separar tus tags por /',
    },
    thisMonth: 'Este mes',
    thisWeek: 'Esta semana',
    connectedWithoutInterruption_zero: 'Conectado sin interrupción',
    connectedWithoutInterruption_other: 'Desconectado durante {{duration}}',
    poweredWithoutInterruption_zero: 'Alimentado sin interrupción',
    poweredWithoutInterruption_other: 'No alimentado durante {{duration}}',
    boxStatisticTitle: 'Estado de la caja desde {{from}} hasta {{to}}',
    noVariableSelected: 'Ninguna variable seleccionada',
    noVariableList: 'Ninguna lista de variables',
    selectList: 'Seleccionar una lista',
    importList: 'Listas de importación',
    exportList: 'Listas de exportación',
    invalidExport: 'Vista previa no disponible: selecciona una lista de variables y un periodo válido',
    invalidExportVar: 'Vista previa no disponible: ninguna lista de variables seleccionada',
    invalidExportPeriod: 'Vista previa no disponible: ningún periodo válido seleccionado',
    boxHealthCheckProgress: 'Comprobando el estado de la caja...',
    boxSetupProgress: 'Preparando la caja...',
    selectFile: 'Suelta un archivo o haz clic para elegir uno',
    dropFile: 'Suelta el archivo aquí',
    fileNotAllowed: 'Este tipo de archivo no está permitido',
    successCreateCalculatedVariable: 'Variables calculadas importadas con éxito',
    errorCreateCalculatedVariable: 'Se produjo un error durante la importación',
    errors_one: '1 error',
    errors_other: '{{count}} errores',
    endpoints: 'URLs',
    authentication: 'Autenticación',
    jqTransformer: 'Transformador JQ',
    date: 'Fecha',
    propertyMatcher: 'Correspondencia de propiedades',
    endpoint: 'URL',
    acquisition: 'Adquisición',
    availableVariables: '{{count}} / {{total}} variables disponibles',
    damcoBoxDelay: 'Frecuencia esperada: {{label}}',
    monitoring: 'Monitorización - Umbral de alertas',
    connection: 'Conexión',
    templateVariables: 'Variables de plantilla',
    sqlModeExplanation: {
      DEFAULT:
        'En el modo por defecto, se define una consulta por variable. También se pueden crear variables globales utilizables en estas consultas',
      BULK: 'En el modo Bulk, se define una consulta global en el equipo, luego cada variable se recupera mediante su referencia.',
      TEMPLATE:
        'En el modo Plantilla, se define una consulta global en el equipo, esta consulta contiene variables de plantilla que se completarán al configurar las variables.',
    },
    availableTemplateVariables: 'Variables disponibles',
    globalVariables: 'Variables globales',
    noBox: 'Ninguna caja',
    accessVariableLists: 'Acceder a las listas de variables',
    newList: 'Nueva lista',
    localDate: 'Fecha local',
    value: 'Valor',
    mqttPropertyMatcherExplanation: {
      [MQTTPropertyMatcherType.MAP_ON_TOPIC]: 'En el modo Topic, cada variable tiene su tema como referencia.',
      [MQTTPropertyMatcherType.MAP_ON_PAYLOAD]:
        'En el modo Mensaje, la referencia está contenida en el mensaje y se identifica mediante la coincidencia de propiedades.',
    },
    startExploration: 'Iniciar una sesión de exploración',
    explorationExplanation: 'El módulo de exploración brinda la capacidad de explorar dispositivos dentro del contexto de la caja.',
    connectBeforeExplore: 'Conéctese antes de explorar el servidor.',
    createVariables: 'Crear variables',
    exploration: 'Exploración',
    fetch: 'Recuperar',
    accessibilityInfo: 'Verifique la accesibilidad del host antes de continuar.',
  },
  input: {
    name: 'Nombre',
    uuid: 'UUID',
    lastValue: 'Último valor',
    reference: 'Referencia',
    description: 'Descripción',
    utilities: 'Utilidades',
    defaultValue: 'Valor por defecto',
    unit: 'Unidad',
    nodeId: 'Node Id',
    address: 'Dirección',
    offset: 'Desplazamiento',
    offsetDescription: 'Desplazamiento de la dirección de las variables',
    offsetData: 'Desplazamiento',
    loopTime: 'Bucle (ms)',
    loopTimeDescription: 'Tiempo total del bucle, en ms',
    pauseAfterRequest: 'Pausa',
    pauseAfterRequestDescription: 'Tiempo de pausa entre dos consultas modbus, en ms',
    maxRegisterToReadByRequest: 'Palabras máx.',
    maxRegisterToReadByRequestDescription: 'Número máximo de palabras que una consulta puede recuperar',
    maxUnusedValue: 'Palabras inútiles',
    maxUnusedValueDescription: 'Número máximo de palabras inútiles permitidas para el diseño de las consultas',
    dataType: 'Tipo',
    function: 'Función',
    transformer: 'Transformador',
    transformerType: 'Tipo de transformador',
    transformerValue: 'Valor del transformador',
    physicalQuantity: 'Magnitud física',
    kind: 'Tipo de variable',
    comment: 'Comentario',
    favorite: 'Favorito',
    accumulate: 'Acumulador',
    warning: 'Alerta',
    expression: 'Expresión',
    params: 'Expresión',
    modbusTcp: 'Modbus',
    sql: 'SQL',
    mqtt: 'MQTT',
    file: 'Archivo',
    opcUa: 'OpcUa',
    enabled: 'Activado',
    alarm: 'Alarma',
    value: 'Valor',
    progress: 'Progresión',
    version: 'Versión',
    type: 'Tipo',
    hostModbusTcp: 'IP',
    hostOpcUa: 'Anfitrión',
    hostSQL: 'IP',
    endpointMode: 'Con un endpoint',
    responseTime: 'Tiempo de respuesta',
    port: 'Puerto',
    rack: 'Rack',
    slot: 'Slot',
    slotInfo: {
      S71200CPU: '0 o 1',
      S71500CPU: '0 o 1',
      CP343: '0 (o ver la configuración del material)',
    },
    timeout: 'Timeout',
    localTSAP: 'TSAP local',
    remoteTSAP: 'TSAP remoto',
    protocol: 'Protocolo',
    uid: 'ID de unidad',
    device: 'Equipo',
    variables: 'Variables',
    prefix: 'Prefijo',
    suffix: 'Sufijo',
    transformerType_multiplier: 'Multiplicador',
    transformerType_linearInterpolation: 'Interpolación lineal',
    bit: 'Bit',
    search: 'Buscar...',
    searchDevicePlaceholder: 'Búsqueda por nombre/IP...',
    invertDoubleWords: 'Doble palabras invertidas',
    invertWords: 'Palabras invertidas',
    invertBytes: 'Bytes invertidos',
    sensorName: 'Nombre del sensor',
    periodicityUnit: {
      seconds: 'Segundos',
      minutes: 'Minutos',
      hours: 'Horas',
      days: 'Días',
      weeks: 'Semanas',
    },
    nowLabel: 'Ahora',
    dateLabel: 'Desde una fecha',
    firstLabel: 'Desde el inicio',
    none: 'Ninguno',
    placeholderUtilities: 'Utilidad',
    placeholderPQ: 'Tamaño físico',
    placeholderKind: 'Tipo de variable',
    endpoint: 'Endpoint',
    securityPolicy: 'Política de seguridad',
    securityMode: 'Modo de seguridad',
    userIdentityInfo: {
      type: 'Tipo',
      userName: 'Nombre de usuario',
      password: 'Contraseña',
      certificateData: 'Certificado',
      privateKey: 'Clave privada',
    },
    credentials: {
      username: 'Nombre de usuario',
      password: 'Contraseña',
    },
    dialect: 'Dialecto',
    request: 'Consulta',
    machineId: 'Máquina ID',
    group: 'Grupo',
    site: 'Sitio',
    mode: 'Modo',
    ip: 'Ip',
    mask: 'Máscara',
    gateway: 'Pasarela',
    lan: 'Lan',
    subnet: 'Subred',
    emptySite: 'Todos los sitios',
    transpose: 'Transponer',
    shortHost: 'Alias Nombre de host',
    vpnIp: 'Ip VPN',
    vpnPort: 'Puerto SSH',
    rsshPort: 'Puerto RSSH',
    sshClientAlive: 'Mantener el cliente SSH conectado',
    addresses: 'Rango de direcciones ip',
    mtu: 'MTU',
    nameservers: 'Servidores de nombres',
    from: 'Desde',
    to: 'Hasta',
    via: 'Vía',
    boxName: 'Nombre de la caja',
    boxSetupIp: 'IP local de la caja',
    date: 'Fecha',
    timeZone: 'Time Zone',
    dateFormat: 'Formato de fecha',
    NONE: 'Ninguno',
    AUTHORIZATION_HEADER: 'Authorization Header',
    BASIC: 'Básico',
    QUERY_PARAM: 'Query param',
    password: 'Contraseña',
    username: 'Contraseña',
    key: 'Nombre del parámetro',
    result: 'Resultado',
    authorizationHeader: 'Authorization Header',
    cron: 'Expresión cron (UTC)',
    method: 'Método HTTP',
    path: 'Ruta',
    schema: 'Esquema',
    id: 'ID',
    instance: 'Instancia',
    filterPastPoints: 'Filtrar los puntos pasados',
    syncPoint: 'Punto de Sincronización',
    contentType: 'Tipo de contenido',
    excel: 'Excel',
    boxDataMaxDelay: 'Frecuencia esperada entre dos envíos de datos de la caja',
    deviceHeartbeatFrequency: 'Frecuencia esperada entre dos ejecuciones del equipo',
    defaultVariableFrequency: 'Frecuencia esperada entre dos puntos de una variable (por defecto)',
    defaultVariableDataDelay: 'Frecuencia esperada para la realimentación de datos',
    mute: 'Sordina',
    database: 'Base de datos',
    queryStrategy: {
      BULK: 'Bulk',
      DEFAULT: 'Por defecto',
      TEMPLATE: 'Plantilla',
    },
    variable: 'Variable',
    variableKey: 'Clave',
    logLevel: 'Registro',
    host: 'Anfitrión',
    topic: 'Topic',
    propertyMatcherType: {
      [MQTTPropertyMatcherType.MAP_ON_TOPIC]: 'Topic',
      [MQTTPropertyMatcherType.MAP_ON_PAYLOAD]: 'Mensaje',
    },
    count: 'Conde',
  },
  button: {
    ok: 'Ok',
    cancel: 'Cancelar',
    applyToAll: 'Aplicar a todas',
    duplicate: 'Duplicar',
    recompute: 'Recalcular',
    rename: 'Renombrar',
    create: 'Crear',
    new: 'Nuevo',
    save: 'Guardar',
    edit: 'Modificar',
    test: 'Probar',
    variable: 'Variable',
    newDevice: 'Equipo',
    delete: 'Suprimir',
    disable: 'Desactivar',
    enable: 'Activar',
    yes: 'Sí',
    no: 'No',
    uploadFileLabel: 'Subir un Archivo',
    uploadFile: 'JSON / CSV',
    downloadFileTemplateLabel: 'Descargar un ejemplo {{type}}',
    downloadFileTemplate: 'Modelo {{type}}',
    selectAll: 'Seleccionar todo',
    deselectAll: 'Deseleccionar todo',
    download: 'Descargar',
    add: 'Añadir',
    addVariable: 'Variable',
    addList: 'Lista',
    refresh: 'Actualizar',
    next: 'Siguiente',
    back: 'Anterior',
    confirm: 'Confirmar',
    exportVariables: 'dametis_{{group}}_{{site}}_{{date}}',
    newBox: 'Caja',
    scan: 'Escáner',
    close: 'Cerrar',
    hideInactiveUsers: 'Ocultar usuarios inactivos',
    hideDameticianUsers: 'Ocultar usuarios de Dametis',
    hideHiddenUsers: 'Ocultar usuarios ocultos',
    clearAllSelection: 'Vaciar',
    import: 'Importar',
    previous: 'Confirmar',
    downloadTemplateCsv: 'Descargar un ejemplo .csv',
    syncBox: 'Forzar la sincronización',
    run: 'Iniciar',
    startSession: 'Iniciar sesión',
    createVariable_one: 'Crear {{count}} variable',
    createVariable_other: 'Crear {{count}} variables',
    connect: 'Conectar',
    readBuffer: 'Leer búfer',
    start: 'Iniciar',
    checkPort: 'Comprobar puerto',
  },
  helper: {
    favorite: 'Especificar si esta variable es importante',
    accumulate: 'Especificar si esta variable es un contador (cada valor se suma al anterior)',
    alarm: 'Especificar si un problema en este equipo debe alertar a los equipos de DAMETIS',
    warning: 'Especificar un problema de configuración',
    hasWarning: 'Problema de configuración',
    editedVariable_one: '{{count}} modificación no guardada',
    editedVariable_other: '{{count}} modificaciones no guardadas',
    indicativeValues: 'Valores indicativos',
  },
  xgrid: {
    noRowsLabel: 'Ninguna variable',
    noRowsLabelImport: 'Ningún dato',
    noDevicesLabel: 'Ningún equipo',
    errorOverlayDefaultLabel: 'Se produjo un error.',
    toolbarDensity: 'Presentación',
    toolbarDensityLabel: 'Presentación',
    toolbarDensityCompact: 'Compacto',
    toolbarDensityStandard: 'Estándar',
    toolbarDensityComfortable: 'Amplio',
    toolbarColumns: 'Columnas',
    toolbarColumnsLabel: 'Mostrar selector de columnas',
    toolbarFilters: 'Filtros',
    toolbarFiltersLabel: 'Mostrar filtros',
    toolbarFiltersTooltipHide: 'Ocultar filtros',
    toolbarFiltersTooltipShow: 'Mostrar filtros',
    // toolbarFiltersTooltipActive: (count) => `${count} active filter(s)`,
    columnsPanelTextFieldLabel: 'Encuentra una columna',
    columnsPanelTextFieldPlaceholder: 'Nombre de columna',
    columnsPanelDragIconLabel: 'Reorganizar columnas',
    columnsPanelShowAllButton: 'Mostrar todo',
    columnsPanelHideAllButton: 'Ocultar todo',
    filterPanelAddFilter: 'Añadir filtro',
    filterPanelDeleteIconLabel: 'Suprimir',
    filterPanelOperators: 'Operador',
    filterPanelOperatorAnd: 'Y',
    filterPanelOperatorOr: 'O',
    filterPanelColumns: 'Columna',
    columnMenuLabel: 'Menú',
    columnMenuShowColumns: 'Mostrar columnas',
    columnMenuFilter: 'Filtrar',
    columnMenuHideColumn: 'Ocultar',
    columnMenuUnsort: 'No ordenar',
    columnMenuSortAsc: 'Ordenar de forma ascendente',
    columnMenuSortDesc: 'Ordenar de forma descendente',
    // columnHeaderFiltersTooltipActive: (count) => `${count} active filter(s)`,
    columnHeaderFiltersLabel: 'Mostrar filtros',
    columnHeaderSortIconLabel: 'Ordenar',
    // footerRowSelected: (count) =>
    //   count !== 1
    //     ? `${count.toLocaleString()} rows selected`
    //     : `${count.toLocaleString()} row selected`,
    footerTotalRows: 'Variables totales:',
    footerPaginationRowsPerPage: 'Variables por página:',
    footerTotalRowsImport: 'Datos totales:',
    footerTotalDevices: 'Equipos totales:',
    footerPaginationDevicesPerPage: 'Equipos por página:',
    filterOperatorContains: 'contiene',
    filterOperatorEquals: 'es igual a',
    filterOperatorStartsWith: 'comienza por',
    filterOperatorEndsWith: 'terminado por',
    filterOperatorIs: 'es',
    filterOperatorNot: 'no es',
    // filterOperatorAfter: 'es antes de',
    // filterOperatorOnOrAfter: 'es en o antes de',
    // filterOperatorBefore: 'Valor',
    // filterOperatorOnOrBefore: 'Valor del filtro',
    filterPanelInputLabel: 'Valor',
    filterPanelInputPlaceholder: 'Valor del filtro',
    toolbarExport: 'Exportar',
    toolbarExportLabel: 'Exportar',
    toolbarExportCSV: 'Descargar el CSV',
    toolbarExportExcel: 'Descargar en formato Excel',
    pinToLeft: 'Fijar a la izquierda',
    pinToRight: 'Fijar a la derecha',
    unpin: 'Desfijar',
  } as Partial<GridLocaleText>,
  graph: {
    internet: 'Internet',
    power: 'Alimentación',
  },
  tooltip: {
    createBox: 'crear un equipo',
    createDevice: 'editar variable',
    exportVariables: 'exportar variables',
    editBoxAlertThresholds: 'Editar umbrales de alerta',
    editDevice: 'Editar equipo',
    resetDevice: 'Cambiar de equipo',
    emptyVariableList: 'Vaciar lista de variables',
    editVariables: 'modificar variables',
    addTemplateVariable: 'Añadir variable de plantilla',
    editExp: 'modificar valores antiguos',
    deleteList: 'Vaciar lista',
    saveList: 'Guardar lista',
    createList: 'Crear lista',
    seePassword: 'Ver contraseña',
    hidePassword: 'Ocultar contraseña',
    copy: 'Copiar',
    addEndpoint: 'Añadir URL',
    addGlobalVariable: 'Añadir variable global',
  },
  messageSecurityMode: {
    Invalid: 'Inválido',
    None: 'Ninguno',
    Sign: 'Firmado',
    SignAndEncrypt: 'Firmado y cifrado',
  },
  securityPolicy: {
    None: 'Ninguno',
    Invalid: 'Inválido',
  },
  userTokenType: {
    Anonymous: 'Anónimo',
    Certificate: 'Certificado',
    UserName: 'Usuario',
  },
  toast: {
    successRecompute: 'Solicitud tomada en cuenta, nuevo cálculo en curso...',
    copyToClipboard: 'UUID de la variable copiado al portapapeles.',
  },
  boxItem: {
    boxVersion: 'Versión',
    kernelVersion: 'Kernel',
    lanUsine: 'LAN FÁBRICA',
    lanOut: 'LAN OUT',
    ip: 'IP',
    gateway: 'Pasarela',
    undefined: 'Indefinido',
    mode: 'Modo',
  },
  grid: {
    interface: 'Interfaz',
    from: 'Desde',
    to: 'Hasta',
    via: 'Vía',
    priority: 'Prioridad (MTU)',
  },
  error: {
    noSelectedDevice: 'Debes seleccionar el equipo antes de importar variables',
  },
  tagAutocomplete: {
    newTag: 'Nueva tag',
  },
  legend: {
    connected: 'Conectado',
    partiallyDisconnected: 'Desconexión parcial',
    disconnected: 'Desconectado',
  },
  table: {
    columns: {
      variable: 'Variable',
      date: 'Fecha',
    },
  },
  stepper: {
    importing: 'Importación',
    creating: 'Creación',
  },
  loader: {
    [CsvParserStep.PARSING]: 'Analizando el archivo...',
    [CsvParserStep.BUILDING]: 'Creando las variables...',
    [CsvParserStep.POSTING]: 'Añadiendo a la base de datos...',
  },
};
