import type { Options } from 'highcharts';
import Highcharts from 'highcharts/es-modules/masters/highcharts.src';
import 'highcharts/es-modules/masters/highcharts-more.src';
import 'highcharts/es-modules/masters/modules/boost.src';
import 'highcharts/es-modules/masters/modules/networkgraph.src';
import 'highcharts/es-modules/masters/modules/no-data-to-display.src';
import 'highcharts/es-modules/masters/modules/sankey.src';
import HighchartsReact, { HighchartsReactRefObject } from 'highcharts-react-official';
import { PropsWithChildren, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DaChartProvider from './DaChartContext';
import { getDaChartOptions } from './daChartOptions';

export interface DaChartProps {
  daChartOptions?: Partial<Options>;
  height?: number;
}

const DaChart = forwardRef<HighchartsReactRefObject, PropsWithChildren<DaChartProps>>(({ daChartOptions, children, height }, ref) => {
  const chartRef = useRef<HighchartsReactRefObject>(null);
  useImperativeHandle(ref, () => {
    return chartRef.current;
  }, []);
  const { t } = useTranslation('dachart');

  const [highcharts, setHighcharts] = useState<HighchartsReactRefObject | null>(null);

  const options = useMemo(() => getDaChartOptions({}, t), [t]);

  const intervalRef = useRef<ReturnType<typeof setTimeout>>(null);
  const resizeObserver = useRef<ResizeObserver>(null);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (chartRef.current) {
        setHighcharts(chartRef.current);
        clearInterval(intervalRef.current);
      }
    }, 100);
  }, []);

  useEffect(() => {
    if (highcharts?.chart) {
      highcharts.chart.update(daChartOptions, true);
    }
  }, [daChartOptions, highcharts?.chart]);

  useEffect(() => {
    resizeObserver.current = new ResizeObserver(() => {
      if (highcharts?.chart) {
        highcharts.chart.reflow();
      }
    });
    resizeObserver.current.observe(chartRef.current.container.current);
    return () => {
      resizeObserver.current.disconnect();
    };
  }, [highcharts?.chart]);

  return (
    <DaChartProvider highcharts={highcharts}>
      <HighchartsReact
        ref={chartRef}
        containerProps={height ? { style: { height } } : undefined}
        highcharts={Highcharts}
        options={options}
      />
      {children}
    </DaChartProvider>
  );
});

DaChart.defaultProps = {
  daChartOptions: {},
  height: undefined,
};

DaChart.displayName = 'DaChart';

export default DaChart;

// export default memo(DaChart, ({ daChartOptions: oldDaChartOptions }, { daChartOptions: nextDaChartOptions }) =>
//   deepEqual(oldDaChartOptions, nextDaChartOptions),
// );
