import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Alert, Grid, IconButton, InputAdornment, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import {
  ChangeEvent,
  ChangeEventHandler,
  Dispatch,
  FC,
  MouseEventHandler,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useResizeObserver } from 'usehooks-ts';

import {
  IsMQTTPasswordAuthentication,
  IsMQTTPayloadPropertyMatcher,
  MQTTDeviceConfig,
  MQTTPropertyMatcherType,
  SyncDelay,
} from '@dametis/core';

import { createMQTTPropertyMatcher } from 'components/Configuration/Devices/helpers/createMQTTDeviceConfig';
import DateTimeFormatPicker from 'components/UI/DateTimeFormatPicker/DateTimeFormatPicker';
import AdvancedNumberTextField from 'components/UI/Inputs/AdvancedTextField/AdvancedNumberTextField';
import AdvancedTextField from 'components/UI/Inputs/AdvancedTextField/AdvancedTextField';

import FormPaper, { FormLocation } from '../../FormPaper';

import SyncDelayInput from './SyncDelayInput';

export interface MQTTSettingsFormProps {
  mqtt: MQTTDeviceConfig;
  setMQTT: Dispatch<SetStateAction<MQTTDeviceConfig>>;
  syncDelay?: SyncDelay;
  onChangeSyncDelay?: (newSyncDelay: SyncDelay) => void;
  isEditing?: boolean;
  location?: `${FormLocation}`;
}

const MQTTSettingsForm: FC<MQTTSettingsFormProps> = ({
  mqtt,
  setMQTT,
  syncDelay = undefined,
  onChangeSyncDelay = undefined,
  isEditing = true,
  location = 'modal',
}) => {
  const { t } = useTranslation('devices');

  const [jqTransformerInputValue, setJQTransformerInputValue] = useState<string>('');
  const [isPasswordHidden, setIsPasswordHidden] = useState<boolean>(true);

  const papersContainerRef = useRef<HTMLDivElement | null>(null);

  const isSyncDelayFieldAvailable = useMemo(
    () => syncDelay !== undefined && onChangeSyncDelay !== undefined,
    [syncDelay, onChangeSyncDelay],
  );

  const { width: papersContainerWidth = 0 } = useResizeObserver({
    ref: papersContainerRef,
  });

  const handleChangeHost: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setMQTT(state => ({ ...state, host: event.target.value }));
    },
    [setMQTT],
  );

  const handleChangePort = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, newValue: number) => {
      setMQTT(state => ({ ...state, port: newValue }));
    },
    [setMQTT],
  );

  const handleChangeTopic: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setMQTT(state => ({ ...state, topic: event.target.value }));
    },
    [setMQTT],
  );

  const handleTogglePasswordVisibility: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    setIsPasswordHidden(state => !state);
  }, []);

  const handleChangeUsername: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setMQTT(state => ({ ...state, authentication: { ...state.authentication, username: event.target.value } }));
    },
    [setMQTT],
  );

  const handleChangePassword: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setMQTT(state => ({ ...state, authentication: { ...state.authentication, password: event.target.value } }));
    },
    [setMQTT],
  );

  const handleChangeJQTransformer: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setJQTransformerInputValue(event.target.value);
      setMQTT(state => ({ ...state, jqTransformer: event.target.value.length === 0 ? null : event.target.value }));
    },
    [setMQTT],
  );

  const handleChangePropertyMatcherType: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setMQTT(state => ({ ...state, propertyMatcher: createMQTTPropertyMatcher(event.target.value as MQTTPropertyMatcherType) }));
    },
    [setMQTT],
  );

  const handleChangePropertyMatcherReference: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setMQTT(state => ({ ...state, propertyMatcher: { ...state.propertyMatcher, reference: event.target.value } }));
    },
    [setMQTT],
  );

  const handleChangePropertyMatcherDate: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setMQTT(state => ({ ...state, propertyMatcher: { ...state.propertyMatcher, date: event.target.value } }));
    },
    [setMQTT],
  );

  const handleChangePropertyMatcherValue: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setMQTT(state => ({ ...state, propertyMatcher: { ...state.propertyMatcher, value: event.target.value } }));
    },
    [setMQTT],
  );

  const handleChangeTimeZone: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setMQTT(state => ({ ...state, timeZone: event.target.value }));
    },
    [setMQTT],
  );

  const handleChangeDateFormat: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setMQTT(state => ({ ...state, dateFormat: event.target.value }));
    },
    [setMQTT],
  );

  const handleChangeSyncDelay = useCallback(
    (newSyncDelay: SyncDelay) => {
      if (onChangeSyncDelay) {
        onChangeSyncDelay(newSyncDelay);
      }
    },
    [onChangeSyncDelay],
  );

  useEffect(() => {
    setJQTransformerInputValue(`${mqtt.jqTransformer ?? ''}`);
  }, [mqtt]);

  return (
    <>
      <FormPaper location={location} sx={{ p: 1.5 }}>
        <Typography mb={1.5} variant="h6">
          {t('title.acquisition')}
        </Typography>
        <Grid ref={papersContainerRef} container spacing={1}>
          <Grid item xs={papersContainerWidth > 400 ? 4 : 6}>
            <AdvancedTextField
              fullWidth
              required
              editing={isEditing}
              label={t('label.host')}
              value={mqtt.host}
              onChange={handleChangeHost}
            />
          </Grid>
          <Grid item xs={papersContainerWidth > 400 ? 2 : 6}>
            <AdvancedNumberTextField
              fullWidth
              required
              editing={isEditing}
              InputProps={{ inputProps: { min: 0 } }}
              label={t('label.port')}
              parser={inputValue => parseInt(inputValue, 10)}
              value={mqtt.port}
              onChange={handleChangePort}
            />
          </Grid>
          <Grid item xs={papersContainerWidth > 400 ? 6 : 12}>
            <AdvancedTextField
              fullWidth
              required
              editing={isEditing}
              label={t('label.topic')}
              value={mqtt.topic}
              onChange={handleChangeTopic}
            />
          </Grid>
          {IsMQTTPasswordAuthentication(mqtt.authentication) && (
            <>
              <Grid item xs={papersContainerWidth > 400 ? 6 : 12}>
                <AdvancedTextField
                  fullWidth
                  required
                  editing={isEditing}
                  label={t('label.username')}
                  value={mqtt.authentication.username}
                  onChange={handleChangeUsername}
                />
              </Grid>
              <Grid item xs={papersContainerWidth > 400 ? 6 : 12}>
                <AdvancedTextField
                  fullWidth
                  required
                  autoComplete="new-password"
                  editing={isEditing}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title={isPasswordHidden ? t('tooltip.hidePassword') : t('tooltip.seePassword')}>
                          <IconButton edge="end" size="large" onClick={handleTogglePasswordVisibility}>
                            {isPasswordHidden ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  label={t('label.password')}
                  type={isPasswordHidden ? 'password' : 'text'}
                  value={mqtt.authentication.password}
                  onChange={handleChangePassword}
                />
              </Grid>
            </>
          )}
          {isSyncDelayFieldAvailable && syncDelay && (
            <Grid item xs={6}>
              <SyncDelayInput isEditing={isEditing} label={t('label.syncDelay')} value={syncDelay} onChange={handleChangeSyncDelay} />
            </Grid>
          )}
        </Grid>
      </FormPaper>
      <FormPaper location={location} sx={{ p: 1.5 }}>
        <Typography mb={1.5} variant="h6">
          {t('title.jqTransformer')}
        </Typography>
        <AdvancedTextField
          fullWidth
          multiline
          editing={isEditing}
          // label={t('label.jqTransformer')}
          margin="none"
          minRows={2}
          value={jqTransformerInputValue}
          onChange={handleChangeJQTransformer}
        />
      </FormPaper>
      <FormPaper location={location} sx={{ p: 1.5 }}>
        <Typography mb={1.5} variant="h6">
          {t('title.mode')}
        </Typography>
        <Stack gap={1}>
          <AdvancedTextField
            fullWidth
            select
            editing={isEditing}
            value={mqtt.propertyMatcher.type}
            onChange={handleChangePropertyMatcherType}
          >
            {Object.values(MQTTPropertyMatcherType).map(type => (
              <MenuItem key={type} value={type}>
                {t(`mqttPropertyMatcherType.${type}`)}
              </MenuItem>
            ))}
          </AdvancedTextField>
          <Alert severity="info">{t(`helper.mqttPropertyMatcherType.${mqtt.propertyMatcher.type}`)}</Alert>
        </Stack>
      </FormPaper>
      <Grid ref={papersContainerRef} container spacing={1.5}>
        <Grid item xs={papersContainerWidth > 400 ? 6 : 12}>
          <FormPaper location={location} sx={{ p: 1.5, height: 1 }}>
            <Typography mb={1.5} variant="h6">
              {t('title.propertyMatcher')}
            </Typography>
            <Grid container spacing={1}>
              {IsMQTTPayloadPropertyMatcher(mqtt.propertyMatcher) && (
                <Grid item xs={12}>
                  <AdvancedTextField
                    fullWidth
                    required
                    editing={isEditing}
                    label={t('label.reference')}
                    value={mqtt.propertyMatcher.reference}
                    onChange={handleChangePropertyMatcherReference}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <AdvancedTextField
                  fullWidth
                  required
                  editing={isEditing}
                  label={t('label.date')}
                  value={mqtt.propertyMatcher.date}
                  onChange={handleChangePropertyMatcherDate}
                />
              </Grid>
              <Grid item xs={12}>
                <AdvancedTextField
                  fullWidth
                  required
                  editing={isEditing}
                  label={t('label.value')}
                  value={mqtt.propertyMatcher.value}
                  onChange={handleChangePropertyMatcherValue}
                />
              </Grid>
            </Grid>
          </FormPaper>
        </Grid>
        <Grid item xs={papersContainerWidth > 400 ? 6 : 12}>
          <FormPaper location={location} sx={{ p: 1.5, height: 1 }}>
            <Typography mb={1.5} variant="h6">
              {t('title.date')}
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <AdvancedTextField
                  fullWidth
                  required
                  editing={isEditing}
                  label={t('label.timeZone')}
                  value={mqtt.timeZone}
                  onChange={handleChangeTimeZone}
                />
              </Grid>
              <Grid item xs={12}>
                <DateTimeFormatPicker
                  fullWidth
                  required
                  editing={isEditing}
                  label={t('label.dateFormat')}
                  value={mqtt.dateFormat}
                  onChange={handleChangeDateFormat}
                />
              </Grid>
            </Grid>
          </FormPaper>
        </Grid>
      </Grid>
    </>
  );
};

export default MQTTSettingsForm;
