import { cloneDeep } from 'lodash-es';
import { useMemo } from 'react';

import { BlockInfo } from '@dametis/core';

import { useBlocks } from 'store/api/blocks';
import { BidirectionalBlockInfo } from 'types/lego';

const getChildrenBlocks = (block: BidirectionalBlockInfo): BidirectionalBlockInfo[] => [
  ...block.children,
  ...block.children.flatMap(b => getChildrenBlocks(b)),
];

const getBidirectionalBlocks = (blocks: BlockInfo[]): BidirectionalBlockInfo[] => {
  const freeBlocks: BidirectionalBlockInfo[] = cloneDeep(blocks).map(block => ({ ...block, children: [], allChildren: [] }));
  freeBlocks.forEach(freeBlock => {
    freeBlock.parent = freeBlocks.find(block => block.uuid === freeBlock.parentId);
    freeBlock.children = freeBlocks.filter(block => block.parentId === freeBlock.uuid);
  });
  freeBlocks.forEach(freeBlock => {
    freeBlock.allChildren = getChildrenBlocks(freeBlock);
  });
  return freeBlocks;
};

const useBidirectionalBlocks = (argBlocks?: BlockInfo[]) => {
  const { data: siteBlocks, ...props } = useBlocks();

  const blocks = useMemo(() => argBlocks ?? siteBlocks, [argBlocks, siteBlocks]);

  return useMemo(() => ({ data: blocks ? getBidirectionalBlocks(blocks) : undefined, ...props }), [blocks, props]);
};

export default useBidirectionalBlocks;
