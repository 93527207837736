import { DrawerActionName } from 'types/drawer';

export default {
  menu: {
    item: {
      mostRecent_zero: 'No recently visited',
      mostRecent_one: 'Recently visited',
      mostRecent_other: 'Recently visited',
      actions_zero: 'No quick action',
      actions_one: 'Quick action',
      actions_other: 'Quick actions',
    },
    actions: {
      [DrawerActionName.NEW_PLAYGROUND_WITH_TY_CHART]: 'Timeline chart',
      [DrawerActionName.NEW_REPORT_CUSTOM_PERIOD]: 'Custom report',
    },
  },
  toast: {
    responsiveDrawerDisabled: 'Responsive menu disabled.',
    resetResponsiveDrawer: 'Reset',
  },
  tooltip: {
    removeEntity: 'Remove from list',
  },
  home: {
    button: 'Home',
  },
  energies: {
    button: 'Energies',
  },
  analysis: {
    button: 'Analysis',
  },
  synoptics: {
    button: 'Synoptics',
    synoptics: 'Synoptics',
  },
  lego: {
    button: 'Blocks',
    blocks: 'Block diagram',
    blockTypes: 'Catalog',
  },
  alarms: {
    button: 'Alarms',
    alarms: 'Log',
    activeAlarms: 'Active alarms',
    alarmsHistory: 'History',
    settings: 'Settings',
  },
  reports: {
    button: 'Reports',
    reports: 'Reports',
  },
  models: {
    button: 'Models',
  },
  playgrounds: {
    button: 'Playgrounds',
    playgrounds: 'Playgrounds',
  },
  projects: {
    button: 'Projects',
    projects: 'Projects list',
    template: 'Templates',
    tasks: 'Tasks',
  },
  acquisition: {
    button: 'Acquisition',
    box: 'Boxes',
    settings: 'Settings',
  },
  configuration: {
    button: 'Configuration',
    button_short: 'Config',
    variables: 'Variables',
    macros: 'Macros',
    devices: 'Devices',
    boxes: 'Boxes',
    group: 'Group',
    site: 'Site',
    batches: 'Batches',
    models: 'Models',
    tags: 'Tags',
  },
  data: {
    button: 'Data',
    button_short: 'Data',
    manualEntry: 'Manual entry',
    advancedEntry: 'Correction',
    fileImport: 'Import',
    export: 'Export',
    history: 'History',
  },
  backup: {
    button: 'Backup',
  },
  boxes: {
    button: 'Boxes',
  },
  users: {
    button: 'Users',
  },
  sites: {
    button: 'Sites',
  },
  groups: {
    button: 'Groups',
  },
  variables: {
    button: 'Variables',
  },
  billing: {
    button: 'Billing',
    bills: 'Bills',
    contracts: 'Contracts',
  },
  activities: {
    button: 'Activities',
  },
  debuggus: {
    button: 'Debuggus',
  },
  exploration: {
    button: 'Exploration',
  },
  dataHistory: {
    button: 'Data History',
    button_short: 'History',
  },
  misc: {
    button: 'Miscellaneous',
    button_short: 'Misc',
  },
  maintenance: {
    button: 'Maintenance',
    button_short: 'Maintenance',
  },
  monitoring: {
    button: 'Monitoring',
    beta: 'Beta',
  },
  authentications: {
    button: 'Authentications',
    button_short: 'Auth',
  },
  steamMixes: {
    button: 'Vapor Mix',
  },
};
