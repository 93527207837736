import { FmdGood } from '@mui/icons-material';
import { Chip, ChipProps, styled } from '@mui/material';

import { ShortSiteInfo } from '@dametis/core';

interface Props extends Omit<ChipProps, 'label' | 'icon'> {
  site: ShortSiteInfo;
}

const SiteChip = styled(({ site, ...props }: Props) => <Chip {...props} icon={<FmdGood />} label={site.name} />)({});

export default SiteChip;
