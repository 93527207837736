import { useMemo } from 'react';

import { BidirectionalBlockInfo } from 'types/lego';

import useBidirectionalBlocks from './useBidirectionalBlocks';

const emptyArray: [] = [];

export const useBlockPath = (blockId: string | null) => {
  const { data: blocks = emptyArray } = useBidirectionalBlocks();

  return useMemo(() => {
    const path: BidirectionalBlockInfo[] = [];
    let currentBidirectionalBlock = blocks.find(block => block.uuid === blockId);
    while (currentBidirectionalBlock) {
      path.unshift(currentBidirectionalBlock);
      currentBidirectionalBlock = currentBidirectionalBlock.parent;
    }
    return path;
  }, [blockId, blocks]);
};
