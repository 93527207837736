import { PopperProps as MuiPopperProps, Tooltip, TooltipProps } from '@mui/material';
import { FC } from 'react';

export interface BlankTooltipProps extends TooltipProps {
  PopperProps?: Partial<MuiPopperProps>;
}

export const BlankTooltip: FC<BlankTooltipProps> = ({ title, children, PopperProps = {}, ...props }: BlankTooltipProps) => (
  <Tooltip
    disableInteractive={false}
    slotProps={{
      tooltip: { sx: { backgroundColor: 'transparent', p: 0, maxWidth: 'none' } },
      popper: { ...PopperProps },
    }}
    title={title}
    {...props}
  >
    {children}
  </Tooltip>
);
