import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { IconButton, ListSubheader, Stack, Tooltip } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockMetric } from '@dametis/core';

import BlockElementMetric from './BlockElementMetric';

export interface BlockListMetricProps {
  blockId: string;
  metrics: BlockMetric[];
  onSelect: (metric: BlockMetric) => void;
}

export const BlockListMetric: FC<BlockListMetricProps> = ({ blockId, metrics, onSelect }) => {
  const { t } = useTranslation('vnc');

  const [isCalculationVisible, setIsCalculationVisible] = useState<boolean>(false);

  const toggleCalculationVisibility = useCallback(() => {
    setIsCalculationVisible(!isCalculationVisible);
  }, [isCalculationVisible]);

  const makeSelect = useCallback(
    (metric: BlockMetric) => () => {
      onSelect(metric);
    },
    [onSelect],
  );

  if (metrics.length === 0) {
    return null;
  }

  return (
    <li>
      <ul>
        <Stack alignItems="center" direction="row" spacing={0.5}>
          <ListSubheader disableGutters>{t('subtitle.metricsWithCategory', { context: metrics[0].category })}</ListSubheader>
          <Tooltip
            placement="right"
            title={t(isCalculationVisible ? 'tooltip.metricsCalcOff' : 'tooltip.metricsCalcOn', { count: metrics.length })}
          >
            <IconButton size="small" onClick={toggleCalculationVisibility}>
              {isCalculationVisible ? <VisibilityOutlined fontSize="inherit" /> : <VisibilityOffOutlined fontSize="inherit" />}
            </IconButton>
          </Tooltip>
        </Stack>
        {metrics.map(metric => (
          <BlockElementMetric
            key={metric.uuid}
            blockId={blockId}
            calcVisible={isCalculationVisible}
            disabled={!metric.templatedCalculation}
            element={metric}
            onClick={makeSelect(metric)}
          />
        ))}
      </ul>
    </li>
  );
};
