import { Card, FormLabel, ListItemIcon, ListItemText, MenuItem, MenuList, Stack, TextField, Typography, capitalize } from '@mui/material';
import { ChangeEventHandler, FC, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { GroupByUnion, IsTimeGroupBy, Operator, OperatorArgs, VarCalc } from '@dametis/core';

import { stopPropagation } from 'functions/stopPropagation';
import { openReplayEvent } from 'openreplay/openreplay';
import { OpenReplayEvent } from 'openreplay/types';

import { filterAllowedOperators } from '../../../../../functions/tada/getOperator';
import GroupByInput from '../../../../UI/GroupByInput/GroupByInput';
import { PropsContext } from '../../../context';
import { compareOperators } from '../../../props';

interface Props {
  variable: Partial<VarCalc>;
  onVariableChange: (variable: Partial<VarCalc>) => void;
  global: boolean;
}

const OperatorsList: FC<Props> = ({ variable, onVariableChange, global }) => {
  const { t } = useTranslation('vnc');

  const {
    defaultVariableOperator,
    defaultGlobalOperator,
    globalOperatorOptions,
    variableOperatorOptions,
    excludeVariableOperatorOptions,
    excludeGlobalOperatorOptions,
  } = useContext(PropsContext);

  const operators = useMemo(
    () =>
      filterAllowedOperators(
        (global ? globalOperatorOptions : variableOperatorOptions).filter(
          operator => !(global ? excludeGlobalOperatorOptions : excludeVariableOperatorOptions).includes(operator),
        ),
        variable.groupBy,
      )
        .map(op => ({
          type: op,
          name: capitalize(t('global:operator.op', { context: op })),
          shortName: t('global:operator.opShort', { context: op }),
        }))
        .sort((a, b) => compareOperators(a.type, b.type)),
    [
      global,
      globalOperatorOptions,
      variableOperatorOptions,
      variable.groupBy,
      excludeGlobalOperatorOptions,
      excludeVariableOperatorOptions,
      t,
    ],
  );

  const operatorArgs = useCallback((operator: Operator): OperatorArgs => {
    if (operator === Operator.MOVING_AVERAGE) return { period: '1h' };
    if (operator === Operator.INDEX) return { index: 0 };
    return {};
  }, []);

  const changeOperator = useCallback(
    (operator: Operator) => () => {
      openReplayEvent(OpenReplayEvent.VNC_SLATE_OPERATOR_CHANGING);
      if (operator !== variable.operator) {
        onVariableChange({
          operator,
          operator_args: operatorArgs(operator),
          ...(operator === Operator.MOVING_AVERAGE && { groupBy: '1h' }),
        });
      }
    },
    [variable.operator, onVariableChange, operatorArgs],
  );

  const changeMovingAverageOperatorArgs = useCallback(
    (period: GroupByUnion<true, false, false, false, false>) => {
      onVariableChange({ operator_args: { period } });
    },
    [onVariableChange],
  );

  const changeIndexOperatorArgs = useCallback<ChangeEventHandler<HTMLInputElement>>(
    event => {
      const parsedValue = parseInt(event.target.value, 10);
      onVariableChange({ operator_args: { index: Number.isFinite(parsedValue) ? parsedValue : undefined } });
    },
    [onVariableChange],
  );

  return (
    <Stack flex={1} spacing={0.5} sx={{ overflow: 'hidden' }}>
      <FormLabel>{t('list.subheader.operator')}</FormLabel>
      <MenuList sx={{ flex: 1, overflowY: 'auto' }}>
        {operators.map(({ type, name, shortName }) => (
          <MenuItem
            key={type}
            selected={(variable.operator ?? Operator.HISTORY) === type}
            sx={{ m: 0, width: 1 }}
            onClick={changeOperator(type)}
          >
            <ListItemIcon sx={theme1 => ({ minWidth: `${theme1.spacing(8)} !important` })}>
              <Typography variant="overline">{shortName}</Typography>
            </ListItemIcon>
            <ListItemText
              primary={`${name} ${
                type === ((global ? defaultGlobalOperator : defaultVariableOperator) ?? Operator.HISTORY) ? t('list.text.byDefault') : ''
              }`}
              secondary={
                <>
                  {type === Operator.MOVING_AVERAGE &&
                    IsTimeGroupBy(variable.operator_args?.period) &&
                    variable.operator === Operator.MOVING_AVERAGE && (
                      <Card sx={{ p: 1.75 }} onMouseDown={stopPropagation}>
                        <GroupByInput
                          disableBatch
                          groupBy={variable.operator_args.period}
                          label={t('input.label.opArgsPeriod')}
                          onGroupByChange={changeMovingAverageOperatorArgs}
                        />
                      </Card>
                    )}
                  {type === Operator.INDEX && variable.operator_args?.index !== undefined && variable.operator === Operator.INDEX && (
                    <Card sx={{ p: 1.75 }} onMouseDown={stopPropagation}>
                      <TextField
                        margin="dense"
                        name="Index"
                        sx={{ width: '12rem' }}
                        type="number"
                        value={variable.operator_args?.index}
                        variant="outlined"
                        onChange={changeIndexOperatorArgs}
                      />
                    </Card>
                  )}
                </>
              }
              secondaryTypographyProps={{ component: 'div' }}
            />
          </MenuItem>
        ))}
      </MenuList>
    </Stack>
  );
};

export default OperatorsList;
