import { Stack, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FolderInfo, ShortStandardBlockInfo } from '@dametis/core';

import { DeployedCodeOutlined } from 'assets/icons/DeployedCodeOutlined';
import { getStandardBlockPath } from 'components/Lego/helpers/getStandardBlockPath';
import TypographyEllipse from 'components/UI/TypographyEllipse/TypographyEllipse';
import { useReadGlobalRootFolderQuery } from 'store/api/globalFolders';

export interface StandardBlockInnerPreviewProps {
  standardBlock: ShortStandardBlockInfo;
}

const StandardBlockInnerPreview: FC<StandardBlockInnerPreviewProps> = ({ standardBlock }) => {
  const { t } = useTranslation('lego');

  const { data: globalRootFolder = null } = useReadGlobalRootFolderQuery();

  const parsedGlobalRootFolder: FolderInfo | null = useMemo(
    () => (globalRootFolder ? { ...globalRootFolder, name: t('lego:text.factory') } : null),
    [globalRootFolder, t],
  );

  const foldersPath = useMemo(
    () => (parsedGlobalRootFolder ? getStandardBlockPath(parsedGlobalRootFolder, standardBlock.uuid) : []),
    [parsedGlobalRootFolder, standardBlock.uuid],
  );

  const path = useMemo(() => foldersPath.map(folder => folder.name).join(' › '), [foldersPath]);

  return (
    <Stack alignItems="center" direction="row" gap={1}>
      <DeployedCodeOutlined sx={{ color: standardBlock.ui.color }} />
      <Stack flexGrow={1} width={0}>
        <TypographyEllipse variant="caption">{path}</TypographyEllipse>
        <Typography>{standardBlock.name}</Typography>
      </Stack>
    </Stack>
  );
};

export default StandardBlockInnerPreview;
