import { Alert, Box, Collapse, Stack, StackProps, styled } from '@mui/material';
import { FC, MouseEventHandler, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { setColorLightness } from 'functions/color';
import { useTagEditStore } from 'zustand/stores/tagEdit';

import { ShortNestedTag } from '../../helpers/shortNestedTag';
import { MAX_TAG_DEPTH } from '../TagsList';

import CreateTagItem from './CreateTagItem';
import TagsListItem from './TagsListItem';

interface ChildrenContainerProps extends StackProps {
  depth: number;
}

const ChildrenContainer = styled(Stack, {
  shouldForwardProp: propName => propName !== 'depth',
})<ChildrenContainerProps>(({ theme, depth }) => ({
  marginLeft: theme.spacing(2),
  paddingLeft: theme.spacing(3),
  gap: theme.spacing(1),
  borderLeft: `2px solid ${depth < MAX_TAG_DEPTH ? theme.palette.divider : setColorLightness(theme.palette.error.light, 95)}`,
  marginTop: theme.spacing(1),
}));

export interface ItemContainerProps {
  shortTag: ShortNestedTag;
  index: number;
  depth?: number;
}

const ItemContainer: FC<ItemContainerProps> = ({ shortTag, index, depth = 1 }) => {
  const { t } = useTranslation('tags');

  const isEditing = useTagEditStore(state => state.isEditing);
  const isExpanded = useTagEditStore(state => !!state.expandedItems[shortTag.uuid]);
  const toggleExpandedItem = useTagEditStore(state => state.toggleExpandedItem);

  const isExpandable = useMemo(() => shortTag.children.length > 0 || isEditing, [shortTag.children, isEditing]);
  const isMaxDepthWithChildren = useMemo(() => depth === MAX_TAG_DEPTH && shortTag.children.length > 0, [shortTag.children.length, depth]);
  const toggleSelectedTagId = useTagEditStore(state => state.toggleSelectedTagId);

  const handleClickPaper = useCallback<MouseEventHandler<HTMLDivElement>>(
    event => {
      if (isEditing && (event.metaKey || event.ctrlKey)) {
        toggleSelectedTagId(shortTag.uuid);
      } else if (isExpandable) {
        toggleExpandedItem(shortTag.uuid);
      }
    },
    [isEditing, isExpandable, toggleSelectedTagId, shortTag.uuid, toggleExpandedItem],
  );

  return (
    <Box>
      <TagsListItem
        depth={depth}
        index={index}
        isExpandable={isExpandable}
        isExpanded={isExpanded}
        shortTag={shortTag}
        onClick={handleClickPaper}
      />
      {isExpandable && (
        <Collapse unmountOnExit in={isExpanded}>
          <ChildrenContainer depth={depth}>
            {shortTag.children.map((child, childIndex) => (
              <ItemContainer key={child.uuid} depth={depth + 1} index={childIndex} shortTag={child} />
            ))}
            {isEditing && (
              <Box>
                <CreateTagItem index={shortTag.children.length} parentId={shortTag.uuid} />
              </Box>
            )}
            {isMaxDepthWithChildren && <Alert severity="error">{t('error.maxDepthExceed', { max: MAX_TAG_DEPTH })}</Alert>}
          </ChildrenContainer>
        </Collapse>
      )}
    </Box>
  );
};

export default memo(ItemContainer);
