import { ActivityType, ActivityVerb } from '@dametis/core';

const verbs: Record<ActivityVerb, string> = {
  [ActivityVerb.CREATE]: 'a créé',
  [ActivityVerb.RESTORE]: 'a restauré',
  [ActivityVerb.DUPLICATE]: 'a dupliqué',
  [ActivityVerb.TRANSFER]: 'a transféré',
  [ActivityVerb.UPDATE]: 'a modifié',
  [ActivityVerb.DELETE]: 'a supprimé',
  [ActivityVerb.UPLOAD]: 'a publié',
  [ActivityVerb.IMPORT]: 'a importé',
  [ActivityVerb.SIGNIN_PASSWORD]: "s'est connecté avec un mot de passe",
  [ActivityVerb.SIGNIN_INVITATION]: "s'est connecté avec une invitation",
  [ActivityVerb.SIGNIN_SSO]: "s'est connecté avec du SSO",
  [ActivityVerb.LOGOUT]: "s'est déconnecté",
  [ActivityVerb.CREATE_INVITATION]: 'a créé une invitation',
  [ActivityVerb.FINALIZE_INVITATION]: 'a finalisé son invitation',
  [ActivityVerb.LOST_PASSWORD]: 'a perdu son mot de passe',
  [ActivityVerb.RESET_PASSWORD]: 'a redéfini son mot de passe',
  [ActivityVerb.ANONYMOUSLY_DUPLICATE]: 'a ete dupliquer anonymement',
  [ActivityVerb.SIGNIN_WEBAUTHN]: "s'est connecte avec WebAuthn",
  [ActivityVerb.CALCULUS_ARMAGEDDON]: 'a déclenché un Armageddon Calculus',
  [ActivityVerb.SCORE_ARMAGEDDON]: 'a déclenché un Armageddon Scores',
  [ActivityVerb.DAMCO_ARMAGEDDON]: 'a déclenché un Armageddon Damco',
  [ActivityVerb.IMPERSONATE]: "a pris l'identité d'un utilisateur",
  [ActivityVerb.MOVE]: 'a déplacé',
  [ActivityVerb.MERGE]: 'a fusionné',
};

const types: Record<ActivityType, string> = {
  [ActivityType.GROUP]: 'le groupe',
  [ActivityType.SITE]: 'le site',
  [ActivityType.BOX]: 'la box',
  [ActivityType.DEVICE]: "l'équipement",
  [ActivityType.PLAYGROUND]: 'le playground',
  [ActivityType.REPORT]: 'le rapport',
  [ActivityType.SYNOPTIC]: 'le synoptique',
  [ActivityType.VARIABLE]: 'la variable',
  [ActivityType.USER]: "l'utilisateur",
  [ActivityType.ALARM]: "l'alarme",
  [ActivityType.PROJECT]: 'le projet',
  [ActivityType.ASSET]: 'un document',
  [ActivityType.TASK]: 'une tâche',
  [ActivityType.BATCH]: 'un batch',
  [ActivityType.MODEL]: 'un modèle',
  [ActivityType.COMMENT]: 'un commentaire',
  [ActivityType.BLOCK]: 'un block',
  [ActivityType.BLOCK_TYPE]: 'un type de block',
  [ActivityType.STYLE_CONFIGURATION]: 'une configuration de style',
  [ActivityType.ALIAS]: 'un alias',
  [ActivityType.IMAGE]: 'une image',
  [ActivityType.TAG]: 'un tag',
  [ActivityType.OPCO]: 'un mix vapeur',
  [ActivityType.SYNOPTIC_WIDGET]: 'un widget de Synoptic',
  [ActivityType.FILEIMPORT]: 'un import de fichier',
  [ActivityType.STANDARD_BLOCK]: 'un block standard',
  [ActivityType.CALENDAR]: 'un calendrier',
  [ActivityType.INVITATION]: 'une invitation',
  [ActivityType.VARIABLE_DATA]: 'des données de la variable',
  [ActivityType.CUSTOMJOB]: 'un job personnalisé',
};

export default {
  title: {
    activityDetails: "Détails de l'activité",
  },
  type: verbs,
  object: types,
  text: {
    activities: 'Activités',
    label: '{{firstName}} {{lastName}} $t(type.{{type}}) $t(object.{{object}})',
    noActivities: 'Aucune activité enregistrée',
    displayOnlyChangedFields: "N'afficher que les champs modifiés.",
    variable: {
      noUpdatedVariableFields: 'Aucune modification',
      updatedVariableFields_zero: ' et $t(configuration:input.{{field}})',
      updatedVariableFields_one: ', $t(configuration:input.{{field}}) et un autre champ',
      updatedVariableFields_other: ', $t(configuration:input.{{field}}) et {{count}} autres champ',
    },
  },
  button: {
    close: 'Fermer',
    restore: 'Restaurer',
    order: {
      newest: 'Des récents aux anciens',
      oldest: 'Des anciens aux récents',
    },
  },
  toast: {
    restoreVariableSuccess: 'Variable restaurée.',
  },
};
