import type { ParseError as PapaParseError } from 'papaparse';
import type { ErrorCode as DropzoneErrorCode } from 'react-dropzone';

import { CSVDelimiter, ColumnType, FileImportStatus, FileParseErrorCodes, ReferencedBy, VirtualColumnElementType } from '@dametis/core';

import { MappingView } from 'components/Data/FileImport/Home/CreateFileImportModal/MappingSettings/MappingSettings';
import { SourceValue } from 'components/Data/FileImport/UI/SourceValuePicker/SourceValuePicker';

export default {
  title: {
    fileImport: 'Data import',
    createFileConfigModal: 'New configuration',
    createFileImportModal: 'Upload documents',
    settings: 'Settings',
    virtualColumns: 'Virtual columns',
    createVirtualColumnModal: 'New virtual column',
    dateTime: 'Date & heure',
    reference: 'Variables references',
    values: 'Values',
    value: 'Value',
    preview: 'Preview',
    configurations: 'Configurations',
    files: 'Files',
    selectedFiles: 'Selected documents',
    mapping: 'Columns mapping',
    skippedColumns: 'Skipped columns',
    saveFileImportModal: 'What do you want to do ?',
    uploadsPanel: 'Uploads',
    fixedVariables: 'Permanent variables',
    informations: 'Informations',
    errors: 'Errors',
    promptBeforeClosing: 'Are you sure ?',
  },
  button: {
    createFileConfig: 'Configuration',
    close: 'Close',
    next: 'Next',
    previous: 'Previous',
    add: 'Add',
    create: 'Create',
    refresh: 'Refresh',
    refreshLong: 'Refresh preview',
    upload: 'Upload',
    see: 'See',
    importData: 'Import data',
    save: 'Save',
    uploads: 'Uploads',
    cancel: 'Cancel',
    validate: 'Validate',
    closeCanceling: 'Cancel',
    confirmCanceling: 'Close without saving',
  },
  label: {
    referencedBy: 'How are the variables in your documents referenced ?',
    typicalFile: 'Typical file',
    delimiter: 'Delimiter',
    jq: 'JQ transformer',
    chooseFileConfig: 'Which configuration you want to import your documents ?',
    name: 'Name',
    description: 'Description',
    elements: 'Elements',
    dateTimeSource: 'Column',
    dateTimeFormat: 'Source format',
    dateTimeZone: 'Source timezone',
    referenceSource: 'Source',
    valuesSource: 'Source',
    valueSource: 'Source',
    skippedColumnWithIndex: 'Skipped column {{index}}',
    startLine: 'Values start',
    stopLine: 'Values end',
    associatedFiles_zero: 'No associated file',
    associatedFiles_one: '{{count}} associated file',
    associatedFiles_other: '{{count}} associated files',
    [`useHeader_${ReferencedBy.COLUMNS}`]: 'Documents use header containing variables references',
    [`useHeader_${ReferencedBy.ROWS}`]: 'Documents use header',
    ignoreEmptyLines: 'Ignore empty lines',
    chooseExistingFileImport: 'Choose existing configuration',
    existingFileImport: 'Existing configurations',
    uploadsInProgress_zero: 'No upload in progress',
    uploadsInProgress_one: 'One upload in progress',
    uploadsInProgress_other: '{{count}} uploads un progress',
    doImportData: 'Import data from these documents',
    doSaveFileImport: 'Save this import configuration',
    createdAt: 'Creation',
    updatedAt: 'Update',
    size: 'Size',
    owner: 'Owner',
    charset: 'Charset',
    globalErrors_zero: 'No global error',
    globalErrors_one: '{{count}} global error',
    globalErrors_other: '{{count}} global errors',
    rowWithNumber_zero: 'Row {{number}} (no error)',
    rowWithNumber_one: 'Row {{number}} ({{count}} error)',
    rowWithNumber_other: 'Row {{number}} ({{count}} errors)',
    variable: 'Variable',
  },
  referencedBy: {
    [ReferencedBy.COLUMNS]: 'By columns',
    [ReferencedBy.ROWS]: 'By rows',
  },
  helper: {
    referencedBy: {
      [ReferencedBy.COLUMNS]: 'Variables are referenced in your documents value column headers.',
      [ReferencedBy.ROWS]: 'Variables are referenced in each value ligne in your documents.',
    },
    dateTimeFormatDescription: 'Find format documentation ',
    dateTimeFormatRedirection: 'here.',
    chooseConfigurationBeforeFiles: 'You have to select a configuration befire choosing files to import.',
    createConfigurationBeforeFiles: 'You have to create a first import configuration to start sending your files.',
    typicalFile: 'Choose a typical file for this configuration. It will be used to set imported documents parsing definition.',
    virtualColumns:
      'Virtual columns allow you to simulate columns in your documents from its content. Example : the virtual column "date and time" is create from merging columns "date" and "time".',
    fixedVariables:
      'Permanently associating a variable with a column allows the use of documents without headers or with incorrect variable reference.',
  },
  csvDelimiter: {
    [CSVDelimiter.AUTO]: 'Auto',
    [CSVDelimiter.COMMA]: '","',
    [CSVDelimiter.SEMICOLON]: '";"',
    [CSVDelimiter.SPACE]: 'Space',
    [CSVDelimiter.TAB]: 'Tabulation',
  },
  tooltip: {
    seeMore: 'See {{count}} other configurations',
    createFileConfig: 'New configuration',
    deleteConfig: 'Delete configuration',
    cannotDeleteConfig: 'You cannot delete this configuration because it is used by a device',
    seeFiles: 'See documents',
    correspondingVariable: 'Corresponding variable : {{variableName}}',
    notCorrespondingVariable: 'Reference "{{reference}}" does not seem to match any site variable',
    duplicatedReference: 'The reference "{{reference}}" is duplicated, which can result in falsy import',
    view: {
      [MappingView.COLUMNS]: 'Settings by columns',
      [MappingView.SOURCES]: 'Settings by sources',
    },
    jqDesc: 'JQ is a language for advanced manipulation of the data in your file.',
    docLink: 'Documentation',
  },
  virtualColumnElementType: {
    [VirtualColumnElementType.COLUMN]: 'Column',
    [VirtualColumnElementType.TEXT]: 'Text',
    [VirtualColumnElementType.SEPARATOR]: 'Separator',
  },
  columnType: {
    [ColumnType.REAL_BY_INDEX]: 'Existing columns (by number)',
    [ColumnType.REAL_BY_HEADER]: 'Existing columns (by name)',
    [ColumnType.VIRTUAL]: 'Virtual columns',
  },
  importStatusLabel: {
    [FileImportStatus.IMPORTED]: 'Data imported with success',
    [FileImportStatus.READY]: 'Data in this document does not appear to have been imported',
    [FileImportStatus.FAILED]: 'Import failed, an error occured',
    [FileImportStatus.IN_PROGRESS]: 'Import data from the current document in progress',
    missingFile: 'This document is no longer available',
    unknownStatus: 'Unknown status',
  },
  column: {
    notSelected: 'Not selected',
    skipped: 'Skipped',
    valueSourceDefault: 'Value (by default)',
    skippedSourceDefault: 'Skipped (by default)',
    dateTimeSource: 'Date & time',
    referenceSource: 'Variables references',
    valuesSource: 'Values',
    valueSource: 'Value',
    valuesSourceWithIndex: 'Values {{index}}',
  },
  stepper: {
    csvConfig: 'Documents',
    mapping: 'Values',
  },
  text: {
    noVirtualColumn: 'No virtual column.',
    noVirtualColumnElement: 'No element',
    separator: '"{{value}}"',
    realByIndexColumn: 'Column nº{{index}}',
    realByHeaderColumn: 'Colonne "{{name}}"',
    virtualColumn: 'Colonne "{{name}}"',
    separatorMustFollowColumnOrText: 'A separator must follow a Column or Text element',
    virtualColumnNameIsAlreayUsed: 'An other virtual column has this name',
    noDateTimeZoneFound: 'No time zone found',
    noDateTimeFormatFound: 'No format found',
    useOption: 'Use "{{option}}"',
    lineNumber: 'Line nº',
    lineNumberWithValue: 'ligne nº{{value}}',
    noPreview: 'No preview',
    noConfiguration: 'No configuration',
    globalParseErrors: 'Parsing errors',
    documentContainsErrors: 'This document contains errors',
    alreadyUploadedFile: 'Already being processed file : {{fileName}}',
    noFile: 'No document',
    noSkippedColumn: 'No skipped column',
    importFromNewFileImport: 'Upload documents from a new configuration',
    chooseExistingFileImport: 'Choose an existing configuration',
    unknownSize: 'Unknown size',
    noUpload: 'No upload',
    noFixedVariable: 'No permanent variable',
    loadingForms: 'Loading columns...',
    dateTimeFormatExample: 'Ex: {{example}}',
    unknownParseError: 'Unknown error',
    promptBeforeClosing: "You don't have saved this configuration.",
    unknownUser: 'Unknown user',
    configUsed: 'This Configuration is used by the device ',
    emptyJq: 'None',
  },
  dropzone: {
    dropFiles: 'Drop one or more documents here',
    or: 'or',
    browse: 'Browse',
    acceptedFiles: 'Accepted files:',
    maxSize: 'Maximum size: {{size}}MB',
    dropFilesHere: 'Drop your documents here',
    fileNotAllowed: 'This type of file is not allowed',
  },
  toast: {
    uploadInProgress_one: 'Uploading document in progress.',
    uploadInProgress_other: 'Uploading documents in progress.',
    uploadInProgressWithRedirect_one: 'Uploading document in progress, you can check its progress on the associated configuration page.',
    uploadInProgressWithRedirect_other:
      'Uploading documents in progress, you can check their progress on the associated configuration page.',
    fileImportSaved: 'Configuration saved',
    deleteFileImportSuccess: 'Configuration deleted successfully.',
  },
  sourceValue: {
    [`${SourceValue.DEFAULT}_useHeader_${ReferencedBy.COLUMNS}`]: 'Value (from variable reference)', // default referenced by rows useHeader
    [`${SourceValue.DEFAULT}_${ReferencedBy.COLUMNS}`]: 'Skipped (défaut)', // default referenced by rows not useHeader
    [`${SourceValue.DEFAULT}_useHeader_${ReferencedBy.ROWS}`]: 'Skipped (défaut)', // default referenced by rows useHeader
    [`${SourceValue.DEFAULT}_${ReferencedBy.ROWS}`]: 'Skipped (défaut)', // default referenced by rows notUseHeader
    [SourceValue.DATE_TIME]: 'Date & time',
    [SourceValue.REFERENCE]: 'Reference',
    [SourceValue.VALUE]: 'Value',
    [SourceValue.SKIPPED_COLUMN]: 'Skipped column',
    [SourceValue.FIXED_VARIABLE]: 'Value (from variable selection)',
  },
  errors: {
    jqError: 'Your JQ code contains errors.',
    fileParse: {
      [FileParseErrorCodes.InvalidDate]: 'Invalid date or format',
      [FileParseErrorCodes.InvalidValue]: 'Invalid value',
      [FileParseErrorCodes.InvalidReference]: 'Invalid reference',
      [FileParseErrorCodes.MissingColumn]: 'Missing column',
      [FileParseErrorCodes.MissingReference]: 'Missing reference',
    } as Record<FileParseErrorCodes, string>,
    papaParse: {
      MissingQuotes: 'Missing quotes',
      UndetectableDelimiter: 'Undetectable delimiter',
      TooFewFields: 'Too few fields',
      TooManyFields: 'Too many fields',
      InvalidQuotes: 'Invalid quotes',
    } as Record<PapaParseError['code'], string>,
    upload: {
      'file-too-large': 'File exceed allowed maximum size',
      'file-too-small': 'File too small',
      'file-invalid-type': 'This type of file is not allowed',
      'too-many-files': 'Too many files selected',
    } as Record<DropzoneErrorCode, string>,
  },
};
