import { GroupTypes } from 'components/Synoptic/types';

import { GridView } from '../../types/grid';

export default {
  title: {
    synoptics: 'Synoptics',
    allSynoptics: 'All synoptics',
    mySynoptics: 'My synoptics',
    sharedSynoptics: 'Shared with me',
    privateSynoptics: 'Private',
    synoptic: 'Synoptic',
    newSynoptic: 'New synoptic',
    deleteSynoptic: 'Delete synoptic?',
    editSynoptic: 'Edit synoptic',
    duplicateSynoptic: 'Duplicate synoptic',
    cellPicker: 'Add an element',
    device: 'Device',
    value: 'Value',
    light: 'Status light',
    gauge: 'Gauge',
    text: 'Text area',
    richtext: 'Text area',
    activeAlarms: 'Active alarms',
    link: 'Link',
    room: 'Area',
    image: 'Custom image',
    linkTips: 'Tips!',
    shortcut: 'Shortcut',
    custom: 'Custom element',
    addLink: 'Add link',
    imageVariantWithIndex: 'Variant {{index}}',
    selection: 'Multiple selection',
    createSynoptic: 'Create a synoptic',
    alarmLight: 'Alarm light',
    content: 'Content',
    borderSettings: 'Zone settings',
    border: 'Border',
    zone: 'Zone',
  },
  subtitle: {
    ports: 'Ports',
    noMatchesFound: 'No matching items',
    imageVariants: 'Variants',
    data: 'Data',
    plotBands: 'Plot bands',
    alarm: 'Alarm',
    colors: 'Colors',
  },
  button: {
    newSynoptic: 'Synoptic',
    cancel: 'Cancel',
    create: 'Create',
    createSynoptic: 'Create a blank synoptic',
    createSynopticFromModel: 'Create a synoptic from another',
    save: 'Save',
    edit: 'Edit',
    rename: 'Rename',
    delete: 'Delete',
    duplicate: 'Duplicate',
    no: 'No',
    yes: 'Yes!',
    closeRightPanel: 'Close',
    closeMenu: 'Close',
    goToConfiguration: 'Configuration',
    goToPlayground: 'Playground',
    addCustomElement: 'Custom element',
    addPort: 'Add',
    addStyledRule: 'Add',
    addVariable: 'Add',
    addPlotBand: 'Add',
    share: 'Share',
    addImageVariant: 'Add',
    editTags: 'Edit tags',
    saveStyleConfiguration: 'List',
    addStyleConfiguration: 'List',
    select: 'Select',
    change: 'Change',
    uploadImage: 'Upload image',
  },
  input: {
    label: {
      global: 'Global',
      newSynoptic: 'Name',
      link: 'Links',
      color: 'Pipe color',
      deviceName: 'Device name',
      deviceColor: 'Color',
      valueLabel: 'Value name',
      valueLabelDisplay: 'Show on the element',
      valueStroke: 'Color',
      colors: 'Colors',
      colorOn: 'DrawerState 1',
      colorOff: 'DrawerState 0',
      colorUndefined: 'Undefined state',
      valueIcon: 'Icon',
      invert: 'Invert',
      lightLabel: 'Status light name',
      gaugeLabel: 'Gauge name',
      textLabel: 'Content',
      textFontSize: 'Font size',
      linkType: 'Link type',
      roomLabel: 'Area name',
      customElementName: 'Element name',
      image: 'Picture',
      name: 'Name',
      shortcut: 'Shortcut',
      alarmLightName: 'Alarm light name',
      verticalAlignment: 'Alignment',
      borderStyle: 'Style',
      borderWidth: 'Size',
      borderRadius: 'Rounding',
      borderColor: 'Border color',
      fillColor: 'Background',
      tags: 'Tags',
    },
    placeholder: {
      deviceName: 'Not required',
      valueLabel: 'Not required',
      valueIcon: 'Not required',
      lightLabel: 'Not required',
      gaugeLabel: 'Not required',
      textLabel: 'Required',
      roomLabel: 'Not required',
      customElementName: 'Required',
      search: 'Search...',
      alarmLightName: 'Not required',
    },
    value: {
      noIcon: 'None',
      none: 'None',
    },
    helperText: {
      imageMaxSize: 'Image size musts be less than 1MB.',
    },
  },
  error: {
    imageUpload_DEFAULT: 'Something wrong happened while importing your image. Please try again.',
    imageUpload_TOO_BIG: 'Your image size is more than 5MB. Please try again with a smaller image.',
    fetchingValueData: 'Error while fetching data',
    portsError: 'Percentage must be between 0 and 100',
    nameRequired: 'The element name is required',
  },
  text: {
    [`group_${GroupTypes.ELEMENTS}`]: 'Basic elements',
    [`group_${GroupTypes.EQUIPMENT}`]: 'Devices',
    [`group_${GroupTypes.GLOBAL}`]: 'Global elements',
    [`group_${GroupTypes.CUSTOM}`]: 'My elements',
    updatedAt: 'Updated {{date}}',
    me: 'me',
    noSynoptics: 'You have no synoptics yet',
    noImage: 'No image',
    noPorts: 'No ports',
    noImageVariants: 'No variants',
    noSelectedData: 'No data selected',
    port: 'Port',
    state: 'State',
    action: 'Action',
    name: 'Name',
    type: 'Type',
    tag: 'Tags',
    shortCreatedAt: 'Created',
    shortUpdatedAt: 'Updated',
    addCell: 'Elements',
    sharedBy: 'Shared by {{name}}',
    ownedBy: 'Owned by {{name}}',
    link_coldWaterPipe: 'Cold water pipe',
    link_hotWaterPipe: 'Hot water pipe',
    link_veryHotWaterPipe: 'Very hot water pipe',
    link_coldAirPipe: 'Cold air pipe',
    link_electricCable: 'Electric cable',
    link_gasPipe: 'Gas pipe',
    link_customPipe: 'Custom pipe',
    device_boiler: 'Boiler',
    device_chiller: 'Chiller',
    device_counter: 'Counter',
    device_flowMeter: 'Flow meter',
    device_heatExchanger: 'Heat exchanger',
    device_pumpL: 'Pump (left to right)',
    device_pumpR: 'Pump (right to left)',
    device_tankV: 'Tank',
    device_tankBigV: 'Large tank',
    device_valve: 'Valve',
    device_valveT: '3-way valve',
    device_compressorAbc1: 'Compressor ABC (1)',
    device_compressorAbc2: 'Compressor ABC (2)',
    device_compressorAf1: 'Compressor AF (1)',
    device_compressorAf2: 'Compressor AF (2)',
    device_sullair: 'Sullair',
    device_airCompressor: 'Air compressor',
    device_coolingTowerPhoto: 'Cooling tower (photo)',
    device_coolingTower: 'Cooling tower',
    device_evaporativeCondenserPhoto: 'Evaporative condenser (photo)',
    device_evaporativeCondenser: 'Evaporative condenser',
    device_steamBoilerPhoto: 'Steam boiler (photo)',
    device_steamBoilerPhoto2: 'Steam boiler (photo 2)',
    device_steamBoiler: 'Steam boiler',
    device_tankH: 'Horizontal tank',
    device_tankH2: 'Horizontal tank (2)',
    device_barrel: 'Barrel',
    device_adsorptionDryer: 'Adsorption dryer',
    device_refrigerationDryer: 'Refrigeration dryer',
    device_transformer: 'Transformer',
    device_coldCompressor: 'Cold compressor',
    device_CompressorHPInterior: 'Compressor HP Interior',
    device_CompressorHPInterior2: 'Compressor HP Interior 2',
    device_CompressorLP: 'Compressor LP',
    device_CentrifugalCompressorWithRecovery: 'Centrifugal Compressor With Recovery',
    device_CentrifugalCompressorWithoutRecovery: 'Centrifugal Compressor Without Recovery',
    device_AbsorptionDryer: 'Absorption Dryer',
    device_RefrigerationDryer: 'Refrigeration Dryer',
    device_ColdBottleHorizontal: 'Horizontal Cold Bottle',
    device_ColdBottleVertical: 'Vertical Cold Bottle',
    device_PistonCompressorWithConsole: 'Piston Compressor with Console',
    device_PistonCompressorWithoutConsole: 'Piston Compressor without Console',
    device_ScrewCompressor: 'Screw Compressor',
    device_AirCooler: 'Air Cooler',
    device_EvaporativeCondenser: 'Evaporative Condenser 1',
    device_EvaporativeCondenser2: 'Evaporative Condenser 2',
    device_ExpansionValve: 'Expansion Valve',
    device_AirCooledCondenser: 'Air Cooled Condenser 1',
    device_AirCooledCondenser2: 'Air Cooled Condenser 2',
    device_Chiller: 'Chiller 1',
    device_Chiller2: 'Chiller 2',
    device_Duct: 'Duct',
    device_BarrelOutletOne: 'Barrel 1 Outlet',
    device_BarrelOutletTwo: 'Barrel 2 Outlets',
    device_BarrelOutletThree: 'Barrel 3 Outlets',
    device_BarrelOutletFour: 'Barrel 4 Outlets',
    device_HotWaterBoiler: 'Hot Water Boiler',
    device_StandardChimneyHalfFace: 'Standard Chimney Half Face',
    device_StandardChimney: 'Standard Chimney',
    device_ChimneyWithEco: 'Chimney with Eco',
    device_SteamBoilerWithChimney: 'Steam Boiler with Chimney',
    device_SteamBoilerWithoutChimney: 'Steam Boiler without Chimney',
    device_Degasser: 'Degasser',
    device_HotWaterHeater: 'Hot Water Heater',
    device_MudPumpLeft: 'Left Mud Pump',
    device_MudPumpRight: 'Right Mud Pump',
    device_DistributionPumpLeft: 'Left Distribution Pump',
    device_DistributionPumpRight: 'Right Distribution Pump',
    device_PumpHorizontalLeft: 'Horizontal Pump Left',
    device_NH3Pump: 'NH3 Pump',
    device_PumpVertical: 'Vertical Pump',
    device_ValveTwoPort: 'Two Port Valve',
    device_ValveThreePort: 'Three Port Valve',
    device_ButterflyValve: 'Butterfly Valve',
    device_WaterSoftener: 'Water Softener',
    device_WaterMeter: 'Water Meter',
    device_GasMeter: 'Gas Meter',
    device_OtherMeter: 'Other Meter',
    device_FlowMeter: 'Flow Meter',
    device_TubularHeatExchangerLeft: 'Left Tubular Heat Exchanger',
    device_TubularHeatExchangerRight: 'Right Tubular Heat Exchanger',
    device_ReverseOsmosisUnit: 'Reverse Osmosis Unit',
    device_SolarPanelInclined: 'Solar Panel Inclined',
    device_SolarPanel: 'Solar Panel',
    device_Tanks: 'Tanks 1',
    device_Tanks2: 'Tanks 2',
    device_MilkSilo: 'Milk Silo',
    device_BiologicalTreatment: 'Biological Treatment',
    device_NaturalDischarge: 'Natural Discharge',
    device_Drilling: 'Drilling',
    device_Desanding: 'Desanding',
    device_Screening: 'Screening 1',
    device_Screening2: 'Screening 2',
    device_AirTankVertical: 'Vertical Air Tank',
    device_AirTankHorizontal: 'Horizontal Air Tank',
    device_Centrifuge: 'Centrifuge',
    device_Clarification: 'Clarification',
    device_Settling: 'Settling',
    device_BarrelDepartureAddition: 'Barrel Departure Addition',
    device_StatusIndicatorLight: 'Status Indicator Light',
    face_Front: '2D',
    face_Side: '3D',
    cell_value: 'Value',
    cell_gauge: 'Gauge',
    cell_light: 'Status light',
    cell_text: 'Text area',
    cell_richtext: 'Text area',
    cell_activeAlarms: 'Active alarms',
    cell_room: 'Area',
    cell_image: 'Custom image',
    cell_shortcut: 'Shortcut to ...',
    cell_alarmLight: 'Alarm light',
    linkTips: 'You can click on a link to add an anchor point. And you can double click on this point to remove it.',
    icon_water: 'Cold water',
    icon_hotWater: 'Hot water',
    icon_veryHotWater: 'Very hot water',
    icon_fire: 'Fire',
    icon_electricity: 'Electricity',
    icon_air: 'Air',
    noStyledRules: 'No styled rules',
    rules: 'Rules',
    copy: 'Copy',
    public: 'Public',
    private: 'Private',
    shared: 'Shared',
    view: {
      [GridView.TILE]: 'Tile view',
      [GridView.LIST]: 'List view',
    },
    cartHelper: 'You can easily add variable to your cart by right-clicking on the widget.',
    selectedElements: 'items selected.',
    view_Front: '2D',
    view_Side: '3D',
    alarmNotFound: 'Alarm not found',
    noSelectedAlarm: 'No alarm selected',
    allSynoptics: 'All synoptics',
  },
  form: {
    label: {
      confidentiality: 'Confidentiality',
    },
    value: {
      confidentiality_private: 'Private',
      confidentiality_shared: 'Shared',
    },
    tooltip: {
      owner_shared: 'If this option is checked, this synoptic be visible for all the users',
      owner_private: 'If this option is checked, this synoptic will be only visible by you ',
      not_owner: 'You cannot change the confidentiality of this synoptic because you are not the owner',
    },
  },
  tooltip: {
    createSynoptic: 'create a synoptic',
    editSynoptic: 'edit this synoptic',
    saveSynoptic: 'save this synoptic',
    renameSynoptic: 'rename this synoptic',
    editTags: 'edit tags of this synoptic',
    duplicateSynoptic: 'duplicate this synotic',
    shareSynoptic: 'share this synoptic',
    deleteSynoptic: 'delete this synoptic',
    undo: 'Undo',
    redo: 'Redo',
    toggleGridOn: 'Enable magnetism',
    toggleGridOff: 'Disable magnetism',
    sortZIndexes: 'Reset elements order',
    zoomOut: 'Zoom out',
    zoomIn: 'Zoom in',
    zoomToFit: 'Maximise',
    toggleFullScreen: 'Fullscreen mode',
    deleteImageVariant: 'Delete',
    alreadyInCart: 'This variable is already in cart',
    variableNotValid: 'Invalid variable',
    showPorts: 'Show ports',
    hidePorts: 'Hide ports',
    cartButton_zero: 'Cart',
    cartButton_one: 'Cart ({{count}} variable)',
    cartButton_other: 'Cart ({{count}} variables)',
    addStyledRule: 'Add a rule',
    saveStyleConfiguration: 'Save these rules in a list',
    addStyleConfiguration: 'Use a list of rules',
    setWidgetGlobal: 'set widget global',
    cannotAddPlotBand: 'You must enter a valid min and max to add a plot band',
    rotateLeft: 'Rotate left',
    rotateRight: 'Rotate right',
    flipVertically: 'Flip vertically',
    flipHorizontally: 'Flip horizontally',
    moveToBack: 'Move to latter plane',
    moveToBackByOne: 'Move backward',
    moveToFrontByOne: 'Move forward',
    moveToFront: 'Move to former plane',
    clone: 'Clone',
    delete: 'Delete',
    returnLinks: 'Reverse the direction',
    linear: 'Linear',
    longDash: 'Long dash',
    smallDash: 'Small dash',
    points: 'Points',
    top: 'Top',
    center: 'Center',
    bottom: 'Bottom',
    selectAlarm: 'Select an alarm',
    removeAlarm: 'Remove alarm',
  },
  alert: {
    cantEditWhenYet: "You will be able to edit variants' rules once the element placed.",
    canNoLongerEditDo: "You can no longer edit variants' images after their creation.",
    reuseImage: 'Your image will be added to the list of your elements',
  },
  cart: {
    title: 'Cart',
    variables: 'Variables',
    noVariables: 'No variables',
    button: {
      variable: 'Variable',
      clear: 'Empty cart',
      open: 'Open in playground',
    },
  },
  toast: {
    create: {
      success: 'The synoptic was successfully created.',
      failure: 'An error occured while creating this synoptic.',
    },
    save: {
      success: 'The synoptic was successfully saved.',
      failure: 'An error occured while saving this synoptic.',
    },
    delete: {
      success: 'The synoptic was successfully deleted.',
      failure: 'An error occured while deleting this synoptic.',
    },
    duplicate: {
      success: 'The synoptic was successfully duplicated.',
      failure: 'An error occured while duplicating this synoptic.',
    },
  },
  select: {
    view: 'View',
    view_Front: '2D',
    view_Side: '3D',
  },
};
