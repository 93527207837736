import { RefObject, useCallback, useEffect, useState } from 'react';

const useIsEllipsed = (
  ref: RefObject<HTMLDivElement>,
  maxWidth?: number,
  options?: { preAction?: () => void | Promise<void>; postAction?: (isEllipsed: boolean) => void | Promise<void> },
) => {
  const [isEllipsed, setIsEllipsed] = useState<boolean>(false);

  const handleResize = useCallback(async () => {
    if (ref.current) {
      await options?.preAction?.();
      const wantedWidth = maxWidth || ref.current.clientWidth;
      const currentWidth = ref.current.style.width;
      ref.current.style.width = 'max-content';
      const realWidth = ref.current.clientWidth;
      ref.current.style.width = currentWidth;
      const newIsEllipsed = wantedWidth < realWidth;
      setIsEllipsed(newIsEllipsed);
      await options?.postAction?.(newIsEllipsed);
    }
  }, [ref, options, maxWidth]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleResize);
    if (ref.current) {
      resizeObserver.observe(ref.current);
      return () => {
        resizeObserver.disconnect();
      };
    }
    void handleResize();
    return undefined;
  }, [handleResize, ref]);

  return isEllipsed;
};

export const useIsVerticalEllipsed = (ref: RefObject<HTMLDivElement>) => {
  const [isEllipsed, setIsEllipsed] = useState<boolean>(false);

  const handleResize = useCallback(async () => {
    if (ref.current) {
      const wantedHeight = ref.current.clientHeight;
      ref.current.style.display = 'block';
      const realHeight = ref.current.clientHeight;
      ref.current.style.display = '';
      const newIsEllipsed = wantedHeight < realHeight;
      setIsEllipsed(newIsEllipsed);
    }
  }, [ref]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleResize);
    if (ref.current) {
      resizeObserver.observe(ref.current);
      return () => {
        resizeObserver.disconnect();
      };
    }
    void handleResize();
    return undefined;
  }, [handleResize, ref]);

  return isEllipsed;
};

export default useIsEllipsed;
