import { StoreApi } from 'zustand';

import {
  CreateTagOperationData,
  DeleteTagOperationData,
  Dict,
  EmptyTagUsages,
  MergeTagOperationData,
  MoveTagOperationData,
  TagOperation,
  TagUsagesInfo,
  UUID,
  UpdateTagOperationData,
} from '@dametis/core';

import { getTagsUsages } from 'components/Configuration/Tags/helpers/getTagsUsages';
import {
  ShortNestedTag,
  createShortNestedTag,
  deleteShortNestedTags,
  findShortNestedTag,
  updateShortNestedTag,
} from 'components/Configuration/Tags/helpers/shortNestedTag';
import { shouldSkipUpdate } from 'components/Configuration/Tags/helpers/shouldSkipUpdate';
import { ShortTagTreeUsageInfo, TagTab } from 'types/tags';
import { SwannEditor } from 'zustand/states/tagEdit';
import { TagEditStore } from 'zustand/stores/tagEdit';

const getNewIndex = (tags: ShortNestedTag[], deletedTagIds: UUID[], targetIndex: number): number => {
  let newIndex = 0;
  for (let treesIndex = 0; treesIndex < tags.length; treesIndex += 1) {
    if (treesIndex === targetIndex) {
      break;
    }
    const element = tags[treesIndex];
    if (!deletedTagIds.includes(element.uuid)) {
      newIndex += 1;
    }
  }
  return newIndex;
};

export interface TagEditActions {
  setSelectedTagIds: (selectedTagIds: UUID[], selectedTab?: TagTab) => void;
  toggleSelectedTagId: (selectedTagId: UUID, selectedTab?: TagTab) => void;
  toggleExpandedItem: (itemId: UUID) => void;
  setExpandedItems: (expandedItems: Dict<boolean>) => void;
  setDeletedTagIds: (deletedTagIds: UUID[]) => void;
  setSelectedTab: (selectedTab: TagTab) => void;
  setIsEditing: (isEditing: boolean) => void;
  setDraggedTagIds: (draggedTagIds: UUID[]) => void;
  setDragImageElement: (dragImageElement: HTMLDivElement | null) => void;
  setDisabledDropIds: (disabledDropIds: UUID[]) => void;
  setEditor: (editor: SwannEditor) => void;
  setPastEditors: (editors: SwannEditor[]) => void;
  resetLastPastEditor: () => void;
  setEditorTag: (data: UpdateTagOperationData) => void;
  setEditorTagUsages: (tagId: UUID, usages: TagUsagesInfo) => void;
  addEditorTag: (data: CreateTagOperationData) => void;
  deleteEditorTags: (data: DeleteTagOperationData) => void;
  moveEditorTags: (data: MoveTagOperationData) => void;
  mergeEditorTags: (data: MergeTagOperationData) => void;
  setEditorOperations: (operations: TagOperation[]) => void;
  addEditorOperation: (operation: TagOperation) => void;
}

export const createTagEditActions = (set: StoreApi<TagEditStore>['setState']): TagEditActions => ({
  setSelectedTagIds: (selectedTagIds, selectedTab) => {
    set({ selectedTagIds, selectedTab: selectedTab ?? TagTab.TOOLS });
  },
  toggleSelectedTagId: (selectedTagId, selectedTab) => {
    set(state => {
      return state.selectedTagIds.includes(selectedTagId)
        ? { ...state, selectedTagIds: state.selectedTagIds.filter(id => id !== selectedTagId) }
        : { ...state, selectedTagIds: [...state.selectedTagIds, selectedTagId] };
    });
    set({ selectedTab: selectedTab ?? TagTab.TOOLS });
  },
  setExpandedItems: expandedItems => {
    set({ expandedItems });
  },
  toggleExpandedItem: itemId => {
    set(state => ({ expandedItems: { ...state.expandedItems, [itemId]: !state.expandedItems[itemId] } }));
  },
  setDeletedTagIds: deletedTagIds => {
    set({ deletedTagIds });
  },
  setSelectedTab: selectedTab => {
    set({ selectedTab });
  },
  setIsEditing: isEditing => {
    set({ isEditing });
  },
  setDraggedTagIds: draggedTagIds => {
    set({ draggedTagIds });
  },
  setDragImageElement: dragImageElement => {
    set({ dragImageElement });
  },
  setDisabledDropIds: disabledDropIds => {
    set({ disabledDropIds });
  },
  setEditor: editor => {
    set({ editor });
  },
  setPastEditors: pastEditors => {
    set({ pastEditors });
  },
  resetLastPastEditor: () => {
    set(state => {
      if (state.pastEditors.length === 0) {
        return state;
      }
      const lastPastEditor = state.pastEditors[state.pastEditors.length - 1];
      // skip all rename operations on the same uuid in pastEditors
      const lastOperationIndex = lastPastEditor.operations.findLastIndex(operation => {
        return !shouldSkipUpdate(operation, lastPastEditor.operations[lastPastEditor.operations.length - 1]);
      });
      const newLastPastEditor = state.pastEditors[lastOperationIndex + 1];
      return { editor: newLastPastEditor, pastEditors: state.pastEditors.slice(0, lastOperationIndex + 1) };
    });
  },
  setEditorTag: ({ uuid, entities, ...body }) => {
    set(state => ({
      pastEditors: [...state.pastEditors, state.editor],
      editor: {
        ...state.editor,
        tagsById: { ...state.editor.tagsById, [uuid]: { ...state.editor.tagsById[uuid], ...body } },
      },
    }));
  },
  setEditorTagUsages: (uuid, usages) => {
    set(state => ({
      pastEditors: [...state.pastEditors, state.editor],
      editor: {
        ...state.editor,
        tagsById: { ...state.editor.tagsById, [uuid]: { ...state.editor.tagsById[uuid], usages } },
      },
    }));
  },
  addEditorTag: data => {
    const newShortTag: ShortNestedTag = { uuid: data.uuid, parentId: data.parentId, children: [] };
    const newTagTreeUsage: ShortTagTreeUsageInfo = { ...data, usages: EmptyTagUsages(), canEdit: true };

    set(state => ({
      pastEditors: [...state.pastEditors, state.editor],
      editor: {
        ...state.editor,
        tagsById: { ...state.editor.tagsById, [data.uuid]: newTagTreeUsage },
        trees:
          data.parentId === null
            ? state.editor.trees.toSpliced(data.index ?? 0, 0, newShortTag)
            : state.editor.trees.map(tree => createShortNestedTag(tree, data.parentId as string, newShortTag, data.index ?? 0)),
      },
    }));
  },
  deleteEditorTags: ({ tagIds }) => {
    set(state => ({
      pastEditors: [...state.pastEditors, state.editor],
      editor: {
        ...state.editor,
        trees: state.editor.trees.map(tree => deleteShortNestedTags(tree, tagIds)).filter(item => item !== null),
        tagsById: Object.keys(state.editor.tagsById)
          .filter(tagId => !tagIds.includes(tagId))
          .reduce<Dict<ShortTagTreeUsageInfo>>((result, tagId) => {
            result[tagId] = state.editor.tagsById[tagId];
            return result;
          }, {}),
      },
    }));
  },
  moveEditorTags: ({ tagIds, targetTagId, index }) => {
    set(state => {
      // On crée les nouveaux tags en se basant sur les tagIds et en mettant à jour leur parentId et leurs tags enfants (si ils ont été déplacé aussi)
      const foundTags = tagIds.reduce<ShortNestedTag[]>((result, tagId) => {
        const foundTag = findShortNestedTag(state.editor.trees, tagId);
        if (foundTag !== null) {
          result.push({
            ...foundTag,
            parentId: targetTagId,
            children: foundTag.children.filter(child => !tagIds.includes(child.uuid)),
          });
        }
        return result;
      }, []);
      // On crée la nouvelle arborescence en supprimant d'abord les tags déplacés
      const newTrees = state.editor.trees.map(tree => deleteShortNestedTags(tree, tagIds)).filter(item => item !== null);
      if (targetTagId !== null) {
        // Si les tags déplacés ont un parent (et ne sont donc pas déplacés à la racine) on doit aussi mettre à jour le champ "children" de ce tag parent.
        const parent = findShortNestedTag(state.editor.trees, targetTagId);
        const newParent = findShortNestedTag(newTrees, targetTagId);
        if (parent === null || newParent === null) {
          return state;
        }
        const newChildren = [...newParent.children];
        // On insère les tags déplacés à la position index dans le champ "children" du tag parent.
        // getNewIndex() permet de calculer la position correcte dans la racine en tenant compte des tags déplacés.
        newChildren.splice(getNewIndex(parent.children, tagIds, index), 0, ...foundTags);
        return {
          pastEditors: [...state.pastEditors, state.editor],
          editor: { ...state.editor, trees: newTrees.map(tree => updateShortNestedTag(tree, targetTagId, { children: newChildren })) },
        };
      }
      // Si les tags déplacés n'ont pas de parent (et sont donc déplacés à la racine) on insère les tags déplacés à la position index dans la racine.
      // getNewIndex() permet de calculer la position correcte dans la racine en tenant compte des tags déplacés.
      newTrees.splice(getNewIndex(state.editor.trees, tagIds, index), 0, ...foundTags);
      return {
        pastEditors: [...state.pastEditors, state.editor],
        editor: { ...state.editor, trees: newTrees },
      };
    });
  },
  mergeEditorTags: ({ tagIds, targetTagId }) => {
    set(state => {
      // Tags by Id
      const targetTreeUsage = state.editor.tagsById[targetTagId];
      const tagsTreeUsage = tagIds.map(tagId => state.editor.tagsById[tagId]);
      const newUsages = getTagsUsages([targetTreeUsage, ...tagsTreeUsage]);

      const newTagsById = Object.values(state.editor.tagsById).reduce<Dict<ShortTagTreeUsageInfo>>((result, tag) => {
        if (!tagIds.includes(tag.uuid)) {
          if (tag.uuid === targetTagId) {
            result[tag.uuid] = { ...tag, usages: newUsages };
          } else {
            result[tag.uuid] = tag;
          }
        }
        return result;
      }, {});

      // Trees
      const children = tagIds.reduce<ShortNestedTag[]>((result, tagId) => {
        const foundedTag = findShortNestedTag(state.editor.trees, tagId);
        if (foundedTag) {
          result.push(...foundedTag.children.filter(child => !tagIds.includes(child.uuid)));
        }
        return result;
      }, []);

      const cleanedTrees = state.editor.trees.map(tree => deleteShortNestedTags(tree, tagIds)).filter(item => item !== null);
      const targetTag = findShortNestedTag(cleanedTrees, targetTagId);
      const newChildren = [...(targetTag?.children ?? []), ...children];
      const newTrees = cleanedTrees.map(tree => updateShortNestedTag(tree, targetTagId, { children: newChildren }));

      return {
        pastEditors: [...state.pastEditors, state.editor],
        editor: { ...state.editor, trees: newTrees, tagsById: newTagsById },
      };
    });
  },
  setEditorOperations: operations => {
    set(state => ({ editor: { ...state.editor, operations } }));
  },
  addEditorOperation: operation => {
    set(state => ({ editor: { ...state.editor, operations: [...state.editor.operations, operation] } }));
  },
});
