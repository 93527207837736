import { Dispatch, SetStateAction } from 'react';

import { UUID } from '@dametis/core';

import { useLocalStorage } from 'hooks/useLocalStorage';

export enum EntitiesGroupsView {
  TILES = 'tiles',
  GRID = 'grid',
}

export const ENTITIES_GROUPS_VIEW_STORAGE_KEY = 'entitiesGroupsView';

export const useEntitiesGroupsViewLocalStorage = (
  userId: UUID,
): [EntitiesGroupsView, Dispatch<SetStateAction<EntitiesGroupsView>>, () => void] =>
  useLocalStorage<EntitiesGroupsView>(ENTITIES_GROUPS_VIEW_STORAGE_KEY, EntitiesGroupsView.TILES, { userId });
