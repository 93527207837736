import { Dict, TagOperation, UUID } from '@dametis/core';

import { ShortNestedTag } from 'components/Configuration/Tags/helpers/shortNestedTag';
import { ShortTagTreeUsageInfo, TagTab } from 'types/tags';

export interface SwannEditor {
  trees: ShortNestedTag[];
  tagsById: Dict<ShortTagTreeUsageInfo>;
  operations: TagOperation[];
}

export const swannEditorDefault: SwannEditor = {
  trees: [],
  tagsById: {},
  operations: [],
};

export interface TagEditState {
  selectedTagIds: UUID[];
  deletedTagIds: UUID[];
  expandedItems: Dict<boolean>;
  selectedTab: TagTab;
  isEditing: boolean;
  draggedTagIds: UUID[];
  dragImageElement: HTMLDivElement | null;
  disabledDropIds: UUID[];
  editor: SwannEditor;
  pastEditors: SwannEditor[];
}

export const initialTagEditState: TagEditState = {
  selectedTagIds: [],
  expandedItems: {},
  deletedTagIds: [],
  selectedTab: TagTab.TOOLS,
  isEditing: false,
  draggedTagIds: [],
  dragImageElement: null,
  disabledDropIds: [],
  editor: swannEditorDefault,
  pastEditors: [],
};
