export default {
  text: {
    createdBy: 'Créé par {{name}}',
    createdAt: 'Créé {{date}}',
    updatedAt: 'Modifié {{date}}',
    me: 'moi',
    notFound: 'Élément introuvable',
  },
  action: {
    addToFavorite: 'Ajouter aux favoris',
    removeFromFavorite: 'Retirer des favoris',
    openInNew: 'Ouvrir dans un nouvel onglet',
    rename: 'Renommer',
    renameAndTag: 'Renommer et taguer',
    edit: 'Modifier',
    duplicate: 'Dupliquer',
    share: 'Partager',
    delete: 'Supprimer',
  },
  tooltip: {
    notFound: "L'élément que vous cherchez n'existe pas, ou vous n'y avez pas accès",
  },
};
