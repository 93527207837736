import { Descendant } from 'slate';

import {
  CalculationVariable,
  CreateBlockTypeBody,
  DataVariable,
  ListVariableInfo,
  Operator,
  SourceEntity,
  Symbols,
  TinyVariableSearchInfo,
  UUID,
  UpdateBlockTypeBody,
  VarCalc,
} from '@dametis/core';

import { AvailableVncFilters, VncFilters } from 'zustand/states/vnc';

import { GroupByInputProps } from '../../UI/GroupByInput/GroupByInput';

export const symbolsRegex = new RegExp(
  Object.values(Symbols)
    .map(s => s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
    .join('|'),
);
export const allowedSymbolsInAlpha = [Symbols.LEFT_PARENTHESIS, Symbols.RIGHT_PARENTHESIS, Symbols.ASSIGN, Symbols.SEMICOLON];

// PROPS

export type VncProps = {
  value: CalculationVariable;
  onChange: (calcVar: CalculationVariable) => unknown | Promise<unknown>;
  sourceCategory: SourceEntity['category'];
  sourceUuid?: SourceEntity['uuid'] | null;
  multiple?: boolean;
  selection?: VarCalc[];
  onSelectionChange?: ((selection: VarCalc[]) => unknown | Promise<unknown>) | null;
  listTab?: ListTab;
  defaultVariableOperator?: Operator | null;
  variableOperatorOptions?: Operator[];
  excludeVariableOperatorOptions?: Operator[];
  defaultGlobalOperator?: Operator | null;
  globalOperatorOptions?: Operator[];
  excludeGlobalOperatorOptions?: Operator[];
  disableMaths?: boolean;
  butKeepVariableMenu?: boolean;
  availableFilters?: Partial<AvailableVncFilters>;
  defaultFilters?: Partial<VncFilters>;
  disableFilters?: (keyof VncFilters)[];
  calculatedVariableMode?: boolean;
  standardBlockMetricMode?: boolean;
  unPostedBlockType?: CreateBlockTypeBody | UpdateBlockTypeBody | null;
  editingBlockTypeMetricUuid?: UUID | null;
  disableLego?: boolean;
  disableModels?: boolean;
  covarianceVariable?: DataVariable | null;
  unitPicker?: boolean;
  output?: 'singleValue' | 'multipleValues';
  GroupByInputProps?: Omit<GroupByInputProps, 'groupBy' | 'onGroupByChange'>;
  disableCreateAlias?: boolean;
};

// LIST

export const enum ListTab {
  HISTORY = 'history',
  VARIABLES = 'variables',
  BLOCKS = 'blocks',
  BLOCK_CHILDREN = 'blockChildren',
  MODELS = 'models',
}

export interface HistoryItem {
  id: string;
  slate: Descendant[];
  group: UUID;
  site: UUID;
  createdAt: Date;
}

export type VariableResult = ListVariableInfo & Pick<TinyVariableSearchInfo, 'score' | 'dismissed' | 'sScore' | 'highlight'>;
