import i18next from 'i18next';
import { cloneDeep } from 'lodash-es';
import { Dispatch } from 'react';
import io, { Socket } from 'socket.io-client';

import {
  BoxStatusStreamingResult,
  CalculationVariable,
  Operator,
  StreamingBodyKind,
  SubscribeDataRoomsBody,
  SubscribeDataRoomsResult,
  SubscribeSyncProgressResult,
  SyncBoxProgress,
  SyncTimeStreamingResult,
  TadaApiResponse,
  Timestamp,
  UnsubscribeAllDataRoomsResult,
  UnsubscribeDataRoomsBody,
  UnsubscribeDataRoomsResult,
  UnsubscribeSyncProgressResult,
} from '@dametis/core';

import store from 'store';
import { addToast } from 'store/slices/toast';

import { ToastSeverity } from '../types';

import { addLevel } from './tada/helpers';

export const initSocketIo = (): Socket => {
  const { dispatch }: { dispatch: Dispatch<any> } = store;
  let syncTimeInterval: ReturnType<typeof setTimeout> | null = null;
  let online = true;

  const socket = io({
    path: '/api/v1/streaming',
    // only websocket for now as we dont support sticky sessions
    transports: ['websocket'],
  });

  return socket
    .on('error', err => {
      console.warn(err.message);
    })
    .on('connect_error', err => {
      console.warn(err.message);
    })
    .on('connect', () => {
      syncTimeInterval = setInterval(async () => {
        const res = await syncTime();
        // rtd should be always > 0
        // check rtd < 10000 to avoid asymmetric delays
        if (Math.abs(res.offset) > 25000 && Math.abs(res.rtd) < 10000) {
          if (online) {
            dispatch(
              addToast({
                severity: ToastSeverity.WARNING,
                message: i18next.t('toast:errorTime'),
                persist: true,
              }),
            );
            online = false;
          }
        } else {
          online = true;
        }
      }, 5000);
    })
    .on('disconnect', reason => {
      // reconnect even if server force disconnect
      if (reason === 'io server disconnect') {
        setTimeout(() => socket.connect(), 5000);
      }
      if (syncTimeInterval) clearInterval(syncTimeInterval);
    });
};

export const getSocket = (): Socket => {
  const state = store.getState();
  const { socket } = state.auth;
  if (!socket) throw new Error();
  return socket;
};

export const isSocketConnected = (): boolean => store.getState().auth.socket?.connected ?? false;

export const boxStatus = (boxId: string): Promise<BoxStatusStreamingResult> => {
  const state = store.getState();
  const { socket } = state.auth;
  if (!socket) throw new Error();
  return new Promise<BoxStatusStreamingResult>(resolve => {
    socket.emit(
      StreamingBodyKind.BOX_STATUS,
      {
        kind: StreamingBodyKind.BOX_STATUS,
        data: {
          boxId,
        },
      },
      resolve,
    );
  });
};

export const syncTime = async (): Promise<{ offset: number; rtd: number }> => {
  const state = store.getState();
  const { socket } = state.auth;
  if (!socket) throw new Error();
  const data: SyncTimeStreamingResult & { t4: Date } = await new Promise(resolve => {
    socket.emit(
      StreamingBodyKind.SYNC_TIME,
      {
        kind: StreamingBodyKind.SYNC_TIME,
        data: {
          t1: new Date(),
        },
      },
      (response: SyncTimeStreamingResult) => {
        resolve({
          ...response,
          t4: new Date(),
        });
      },
    );
  });

  const t1 = new Date(data.t1).getTime();
  const t2 = new Date(data.t2).getTime();
  const t3 = new Date(data.t3).getTime();
  const t4 = data.t4.getTime();
  const rtd = t4 - t1 - (t3 - t2);
  const offset = (t2 - t1 + (t3 - t4)) / 2;

  return {
    offset,
    rtd,
  };
};

export const subscribeToDataRooms = (calculationVariables: CalculationVariable[]): Promise<(string | null)[]> => {
  const state = store.getState();
  const { socket } = state.auth;
  const selectedGroup = state.auth.selectedGroup!;
  if (!socket) throw new Error();
  const variables = cloneDeep(calculationVariables).map(variable => {
    variable.timestampReplacement = Timestamp.KEEP;
    return addLevel(variable, { operator: Operator.LAST });
  });
  return new Promise(resolve => {
    socket.emit(
      StreamingBodyKind.SUBSCRIBE_DATA_ROOM,
      {
        kind: StreamingBodyKind.SUBSCRIBE_DATA_ROOM,
        data: { calculationVariables: variables, groupId: selectedGroup.uuid },
      } as SubscribeDataRoomsBody,
      (response: SubscribeDataRoomsResult) => {
        resolve(response.map(res => res.roomId));
      },
    );
  });
};

export const subscribeSyncProgress = async (boxId: string, onProgress: (progress: SyncBoxProgress) => void): Promise<void> => {
  const state = store.getState();
  const { socket } = state.auth;
  if (!socket) {
    throw new Error(`Socket is null or undefined`);
  }

  const roomId = await new Promise<string | null>(resolve => {
    socket.emit(
      StreamingBodyKind.SUBSCRIBE_SYNC_PROGRESS,
      { kind: StreamingBodyKind.SUBSCRIBE_SYNC_PROGRESS, data: { boxId } },
      (response: SubscribeSyncProgressResult) => resolve(response.roomId),
    );
  });

  if (roomId === null) {
    throw Error('RoomId is null');
  }
  socket.removeAllListeners(roomId);
  socket.on(roomId, onProgress);
};

export const unsubscribeFromDataRooms = (rooms: { id: string; listener?: (data?: TadaApiResponse) => unknown }[]): Promise<boolean> => {
  const state = store.getState();
  const { socket } = state.auth;
  if (!socket) throw new Error();
  rooms.forEach(room => {
    if (room.listener) {
      socket.off(room.id, room.listener);
    }
  });

  const zeroListenerRooms = rooms.filter(room => socket.listeners(room.id).length === 0);

  return new Promise(resolve => {
    socket.emit(
      StreamingBodyKind.UNSUBSCRIBE_DATA_ROOM,
      {
        kind: StreamingBodyKind.UNSUBSCRIBE_DATA_ROOM,
        data: zeroListenerRooms.map(room => ({ roomId: room.id })),
      } as UnsubscribeDataRoomsBody,
      (response: UnsubscribeDataRoomsResult) => {
        resolve(response.unsubscribed);
      },
    );
  });
};

export const unsubscribeFromAllDataRooms = (): Promise<boolean> => {
  const state = store.getState();
  const { socket } = state.auth;
  if (!socket) throw new Error();
  return new Promise<boolean>(resolve => {
    socket.emit(
      StreamingBodyKind.UNSUBSCRIBE_ALL_DATA_ROOMS,
      {
        kind: StreamingBodyKind.UNSUBSCRIBE_ALL_DATA_ROOMS,
      },
      (response: UnsubscribeAllDataRoomsResult) => {
        resolve(response.unsubscribed);
      },
    );
  });
};

export const unsubscribeSyncProgress = async (boxId: string): Promise<void> => {
  const state = store.getState();
  const { socket } = state.auth;
  if (!socket) throw new Error();

  const result = await new Promise<UnsubscribeSyncProgressResult | null>(resolve => {
    socket.emit(
      StreamingBodyKind.UNSUBSCRIBE_SYNC_PROGRESS,
      { kind: StreamingBodyKind.UNSUBSCRIBE_SYNC_PROGRESS, data: { boxId } },
      (response: UnsubscribeSyncProgressResult) => resolve(response),
    );
  });
  if (result === null || !result.unsubscribed) {
    throw Error(`Unsubscribing from box ${boxId} syncProgress failed`);
  }
  socket.removeAllListeners(result.roomId);
};
