import { Paper, Popper, PopperProps, Stack, Typography } from '@mui/material';
import { FC, Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { HotkeyCallback, useHotkeys, useHotkeysContext } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-key-enum';
import { useEventListener } from 'usehooks-ts';

import { NestedTag, NestedTagTrees_Flatten, NestedTag_BuildTrees, NestedTag_Path, ShortTagInfo, TagTreeInfo, UUID } from '@dametis/core';

import { MAX_TAG_NAME_LENGTH } from 'components/Configuration/Tags/TagsList/TagsList';
import useFuse from 'hooks/useFuse';
import { usePermission } from 'hooks/usePermission';
import { useSelector } from 'store';
import { useTagTrees } from 'store/api/tags';
import { HotKeysScope } from 'types/hotkeys';

import IsCreatingLoader from './IsCreatingLoader';
import TagsColumn from './TagsColumn';
import { createNestedTag } from './createNestedTag';
import { ColumnElement, ColumnElementType, IsExistingColumnElement, IsNewColumnElement } from './types';

const tagTreesEmptyArray: TagTreeInfo[] = [];

export const TAG_PICKER_HOTKEYS_OPTIONS = { scopes: HotKeysScope.TAG_PICKER, enableOnFormTags: true, preventDefault: true };

export interface TagPopoverProps extends Omit<PopperProps, 'open'> {
  isOpen: boolean;
  setIsOpen: (newIsOpen: boolean) => void;
  selectedTags?: ShortTagInfo[];
  onSelectTag?: (selectedTag: NestedTag) => void;
  searchFilterValue?: string;
  tagTrees?: TagTreeInfo[];
  onCreateTag?: (newTag: NestedTag) => Promise<NestedTag | null> | NestedTag | null;
  disabledTagIds?: UUID[];
}

const TagPopover: FC<TagPopoverProps> = ({
  isOpen,
  setIsOpen,
  selectedTags = [],
  onSelectTag = undefined,
  searchFilterValue = '',
  tagTrees: userTagTrees = undefined,
  onCreateTag = undefined,
  disabledTagIds = [],
  sx,
  ...props
}) => {
  const { t } = useTranslation('tags');

  const canEditTag = usePermission('canEditTag');

  const { enableScope, disableScope } = useHotkeysContext();

  const siteId = useSelector(state => state.auth.selectedSite?.uuid);

  const { data: tagTrees = tagTreesEmptyArray } = useTagTrees({ siteId });

  const [isMouseOnPaper, setIsMouseOnPaper] = useState<boolean>(false);
  const [isMouseMoving, setIsMouseMoving] = useState<boolean>(false);
  const [isHotkeysEnabled, setIsHotkeysEnabled] = useState<boolean>(false);
  const [unfilteredCurrentTag, setUnfilteredCurrentTag] = useState<NestedTag | null>(null);
  const [filteredCurrentTag, setFilteredCurrentTag] = useState<NestedTag | null>(null);
  const [isCreatingTag, setIsCreatingTag] = useState<boolean>(false);
  const [isDeepSearchDisabled, setIsDeepSearchDisabled] = useState<boolean>(false);

  const mouseMoveDebounceRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const paperRef = useRef<HTMLDivElement | null>(null);

  const displayCreateTagButton = useMemo(
    () => canEditTag && !!onCreateTag && searchFilterValue.length <= MAX_TAG_NAME_LENGTH,
    [onCreateTag, searchFilterValue, canEditTag],
  );

  const buildedTagTrees = useMemo(() => NestedTag_BuildTrees(userTagTrees ?? tagTrees), [userTagTrees, tagTrees]);

  const isFiltered = useMemo(() => searchFilterValue.trim().length > 0, [searchFilterValue]);

  const unfilteredCurrentTagPath = useMemo(
    () => (unfilteredCurrentTag !== null ? NestedTag_Path(unfilteredCurrentTag) : []),
    [unfilteredCurrentTag],
  );

  const unfilteredCurrentTagPathIds = useMemo(() => unfilteredCurrentTagPath.map(tag => tag.uuid), [unfilteredCurrentTagPath]);

  const selectedTagIds = useMemo(() => selectedTags.map(selectedTag => selectedTag.uuid), [selectedTags]);

  const flatTagTrees = useMemo(() => NestedTagTrees_Flatten(buildedTagTrees), [buildedTagTrees]);

  const flatUnfilteredCurrentTags = useMemo(
    () => (unfilteredCurrentTag && !isDeepSearchDisabled ? NestedTagTrees_Flatten(unfilteredCurrentTag.children) : flatTagTrees),
    [flatTagTrees, unfilteredCurrentTag, isDeepSearchDisabled],
  );

  const filteredCurrentTags = useFuse<NestedTag>(flatUnfilteredCurrentTags, searchFilterValue, {
    keys: [{ name: 'name' }, { name: ['parent', 'name'] }],
  });

  const createNestedTagBody = useMemo(
    () =>
      createNestedTag({
        name: searchFilterValue,
        parent: unfilteredCurrentTag,
        parentId: unfilteredCurrentTag?.uuid ?? null,
      }),
    [searchFilterValue, unfilteredCurrentTag],
  );

  const filteredCurrentColumn: ColumnElement[] = useMemo(
    () => [
      ...(displayCreateTagButton ? [{ type: ColumnElementType.NEW, tag: createNestedTagBody }] : []),
      ...filteredCurrentTags.map(tag => ({ type: ColumnElementType.EXISTING, tag })),
    ],
    [createNestedTagBody, filteredCurrentTags, displayCreateTagButton],
  );

  const columns = useMemo(
    () =>
      unfilteredCurrentTagPath.reduce<ColumnElement[][]>(
        (result, tag) => {
          result.push(
            tag.children
              .map(child => ({ type: ColumnElementType.EXISTING, tag: child }))
              .toSorted((a, b) => a.tag.normalizedName.localeCompare(b.tag.normalizedName)),
          );
          return result;
        },
        [
          buildedTagTrees
            .map(tagTree => ({ type: ColumnElementType.EXISTING, tag: tagTree }))
            .toSorted((a, b) => a.tag.normalizedName.localeCompare(b.tag.normalizedName)),
        ],
      ),
    [unfilteredCurrentTagPath, buildedTagTrees],
  );

  const unfilteredCurrentColumn = useMemo(() => columns[columns.length - (unfilteredCurrentTag ? 2 : 1)], [columns, unfilteredCurrentTag]);

  const currentTag = useMemo(
    () => (isFiltered ? filteredCurrentTag : unfilteredCurrentTag),
    [isFiltered, unfilteredCurrentTag, filteredCurrentTag],
  );

  const setCurrentTag = useMemo(
    () => (isFiltered ? setFilteredCurrentTag : setUnfilteredCurrentTag),
    [isFiltered, setUnfilteredCurrentTag, setFilteredCurrentTag],
  );

  const currentColumn = useMemo(
    () =>
      isFiltered
        ? filteredCurrentColumn
        : unfilteredCurrentColumn.toSorted((a, b) => a.tag.normalizedName.localeCompare(b.tag.normalizedName)),
    [isFiltered, filteredCurrentColumn, unfilteredCurrentColumn],
  );

  const focusedIndex = useMemo(
    () => currentColumn.findIndex(element => element.tag.uuid === currentTag?.uuid),
    [currentColumn, currentTag],
  );

  const handleKeyEscape = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === Key.Escape && isOpen) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
        setIsOpen(false);
      }
    },
    [isOpen, setIsOpen],
  );

  const handleKeyUp: HotkeyCallback = useCallback(() => {
    const currentTagIndex = currentColumn.findIndex(column => column.tag.uuid === currentTag?.uuid);
    const newIndex = (currentTagIndex >= 0 ? currentTagIndex : currentColumn.length) - 1;
    const newSelectedColumn = newIndex >= 0 ? currentColumn[newIndex] : currentColumn[currentColumn.length - 1];
    setCurrentTag(newSelectedColumn.tag);
    setIsDeepSearchDisabled(false);
  }, [currentColumn, currentTag, setCurrentTag]);

  const handleKeyDown: HotkeyCallback = useCallback(() => {
    const currentTagIndex = currentColumn.findIndex(column => column.tag.uuid === currentTag?.uuid);
    const newIndex = currentTagIndex + 1;
    const newSelectedColumn = newIndex < currentColumn.length ? currentColumn[newIndex] : currentColumn[0];
    setCurrentTag(newSelectedColumn.tag);
    setIsDeepSearchDisabled(false);
  }, [currentColumn, currentTag, setCurrentTag]);

  const handleKeyLeft: HotkeyCallback = useCallback(() => {
    if (!isFiltered) {
      setUnfilteredCurrentTag(prevUnfilteredTag => prevUnfilteredTag?.parent ?? null);
      setIsDeepSearchDisabled(false);
    }
  }, [isFiltered]);

  const handleKeyRight: HotkeyCallback = useCallback(() => {
    if (!isFiltered) {
      setUnfilteredCurrentTag(prevUnfilteredTag => {
        if (prevUnfilteredTag && prevUnfilteredTag.children.length > 0) {
          return prevUnfilteredTag.children.toSorted((a, b) => a.normalizedName.localeCompare(b.normalizedName))[0];
        }
        if (!prevUnfilteredTag) {
          const lastColumn = columns[columns.length - 1];
          return lastColumn[0]?.tag ?? null;
        }
        return prevUnfilteredTag;
      });
      setIsDeepSearchDisabled(false);
    }
  }, [columns, isFiltered]);

  const createTag = useCallback(
    async (newTag: NestedTag) => {
      if (!onCreateTag) {
        return;
      }
      setIsCreatingTag(true);
      const createdTag = await onCreateTag(newTag);
      if (onSelectTag && createdTag) {
        onSelectTag(createdTag);
      }
      setIsCreatingTag(false);
    },
    [onSelectTag, onCreateTag],
  );

  const handleKeyEnter: HotkeyCallback = useCallback(() => {
    if (currentTag) {
      const isNewTag = currentTag.uuid === createNestedTagBody.uuid;
      if (isNewTag) {
        void createTag(currentTag);
      }
      if (!isNewTag && onSelectTag && !disabledTagIds.includes(currentTag.uuid)) {
        onSelectTag(currentTag);
      }
    }
  }, [currentTag, onSelectTag, disabledTagIds, createNestedTagBody.uuid, createTag]);

  const handleClickElement = useCallback(
    (element: ColumnElement) => () => {
      if (IsNewColumnElement(element) && !isCreatingTag) {
        void createTag(element.tag);
      }
      if (IsExistingColumnElement(element) && onSelectTag) {
        onSelectTag(element.tag);
      }
    },
    [onSelectTag, createTag, isCreatingTag],
  );

  const handleMouseOverItem = useCallback(
    (element: ColumnElement) => () => {
      if (isMouseMoving) {
        setCurrentTag(element.tag);
        setIsDeepSearchDisabled(true);
      }
    },
    [setCurrentTag, isMouseMoving],
  );

  const handleMouseLeaveColumns = useCallback(() => {
    setUnfilteredCurrentTag(null);
    setFilteredCurrentTag(null);
    setIsDeepSearchDisabled(false);
  }, []);

  const handleMouseMove = useCallback((event: MouseEvent) => {
    if (mouseMoveDebounceRef.current) {
      clearTimeout(mouseMoveDebounceRef.current);
    }
    setIsMouseMoving(true);
    if (paperRef.current) {
      setIsMouseOnPaper(paperRef.current.contains(event.target as Node));
    }
    mouseMoveDebounceRef.current = setTimeout(() => {
      setIsMouseMoving(false);
    }, 100);
  }, []);

  useEffect(() => {
    if (isOpen) {
      enableScope(HotKeysScope.TAG_PICKER);
      setIsHotkeysEnabled(true);
      setUnfilteredCurrentTag(null);
      setFilteredCurrentTag(null);
      setIsDeepSearchDisabled(false);
    } else {
      disableScope(HotKeysScope.TAG_PICKER);
      setIsHotkeysEnabled(false);
    }
  }, [isOpen, enableScope, disableScope]);

  useEffect(() => {
    if (searchFilterValue.length > 0) {
      setFilteredCurrentTag(null);
    }
  }, [searchFilterValue]);

  useHotkeys(Key.ArrowUp, handleKeyUp, { ...TAG_PICKER_HOTKEYS_OPTIONS, enabled: isHotkeysEnabled && !isCreatingTag });
  useHotkeys(Key.ArrowDown, handleKeyDown, { ...TAG_PICKER_HOTKEYS_OPTIONS, enabled: isHotkeysEnabled && !isCreatingTag });
  useHotkeys(Key.ArrowLeft, handleKeyLeft, { ...TAG_PICKER_HOTKEYS_OPTIONS, enabled: isHotkeysEnabled && !isCreatingTag });
  useHotkeys(Key.ArrowRight, handleKeyRight, { ...TAG_PICKER_HOTKEYS_OPTIONS, enabled: isHotkeysEnabled && !isCreatingTag });
  useHotkeys(Key.Enter, handleKeyEnter, { ...TAG_PICKER_HOTKEYS_OPTIONS, enabled: isHotkeysEnabled && !isCreatingTag });

  useEventListener('keydown', handleKeyEscape);
  useEventListener('mousemove', handleMouseMove);

  return (
    <>
      <Popper
        open={isOpen}
        placement="bottom-start"
        sx={{ zIndex: theme => theme.zIndex.drawer - 1, position: 'relative', ...sx }}
        {...props}
      >
        {isCreatingTag && <IsCreatingLoader />}
        <Paper ref={paperRef} elevation={8} onMouseLeave={handleMouseLeaveColumns}>
          <>
            {!isFiltered && (
              <Stack direction="row">
                {columns.map((column, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Fragment key={index}>
                    {column.length > 0 && (
                      <TagsColumn
                        canAccessChildren
                        chipProps={{ displayPath: false, pathPosition: 'row', variant: 'default' }}
                        disabledScrolling={isMouseOnPaper || isMouseMoving}
                        disabledTagIds={disabledTagIds}
                        elements={column}
                        focusedIndex={index === columns.length - 2 ? focusedIndex : -1}
                        isHovered={element => unfilteredCurrentTagPathIds.includes(element.tag.uuid)}
                        selectedTagIds={selectedTagIds}
                        onClickElement={handleClickElement}
                        onMouseOverElement={handleMouseOverItem}
                      />
                    )}
                  </Fragment>
                ))}
              </Stack>
            )}
            {isFiltered && (
              <>
                <Typography lineHeight={1} p={1}>
                  {unfilteredCurrentTag && !isDeepSearchDisabled
                    ? t('label.searchingIn', { name: unfilteredCurrentTag.name })
                    : t('label.searchingInAllTags')}
                </Typography>
                <TagsColumn
                  canAccessChildren={false}
                  chipProps={{ displayPath: true, pathPosition: 'column', variant: 'default' }}
                  disabledScrolling={isMouseOnPaper || isMouseMoving}
                  disabledTagIds={disabledTagIds}
                  elements={filteredCurrentColumn}
                  focusedIndex={focusedIndex}
                  isHovered={element => filteredCurrentTag?.uuid === element.tag.uuid}
                  selectedTagIds={selectedTagIds}
                  onClickElement={handleClickElement}
                  onMouseOverElement={handleMouseOverItem}
                />
              </>
            )}
          </>
        </Paper>
      </Popper>
    </>
  );
};

export default TagPopover;
