import { useMemo } from 'react';

import { CreateTagBody, PromiseLimit, TagEditorBody, TagInfo, TagTreeInfo, TagTreeUsageInfo, UUID } from '@dametis/core';

import { sdk } from 'sdk';
import { RootState, useSelector } from 'store';

import { api } from './index';

export interface CorporateTagTreeInfo extends TagTreeInfo {
  siteId: UUID;
}

const tagsApi = api.injectEndpoints({
  endpoints: build => ({
    readTagTrees: build.query<TagTreeInfo[], { siteId?: UUID } | void>({
      queryFn: async (arg, { getState }) => {
        const state = getState() as RootState;
        const siteId = (arg && arg.siteId) ?? state.auth.selectedSite?.uuid;
        if (!siteId) {
          return { data: [] };
        }
        try {
          const { data } = await sdk.tag.ListTrees(siteId);
          return { data };
        } catch (error) {
          console.error(error);
          return { error };
        }
      },
      providesTags: ['TagTrees'],
    }),
    readCorporateTagTrees: build.query<CorporateTagTreeInfo[], { siteIds?: UUID[] } | void>({
      queryFn: async (arg, { getState }) => {
        const state = getState() as RootState;
        const siteIds = (arg && arg.siteIds) ?? state.auth.selectedGroup?.sites.map(site => site.uuid) ?? [];

        try {
          const data = (
            await PromiseLimit.do(siteIds, async siteId => (await sdk.tag.ListTrees(siteId)).data.map(tagTree => ({ ...tagTree, siteId })))
          ).flat();
          return { data };
        } catch (error) {
          console.error(error);
          return { error };
        }
      },
      providesTags: ['TagTrees'],
    }),
    readTagTreesUsages: build.query<TagTreeUsageInfo[], { siteId?: UUID } | void>({
      queryFn: async (arg, { getState }) => {
        const state = getState() as RootState;
        const siteId = (arg && arg.siteId) ?? state.auth.selectedSite?.uuid;
        if (!siteId) {
          return { data: [] };
        }
        try {
          const { data } = await sdk.tag.ListTreesUsagesOfSite(siteId);
          return { data };
        } catch (error) {
          console.error(error);
          return { error };
        }
      },
      providesTags: ['TagTreesUsages'],
    }),
    updateTagTreesUsages: build.mutation<void | undefined, TagEditorBody>({
      queryFn: async (body, { getState }) => {
        const state = getState() as RootState;
        const siteId = state.auth.selectedSite?.uuid;
        try {
          if (siteId === undefined) {
            return { data: undefined };
          }
          const { data } = await sdk.tag.TagEditor(siteId, body);
          return { data };
        } catch (error) {
          console.error(error);
          return { error };
        }
      },
      invalidatesTags: (_, error) => {
        if (error) {
          return [];
        }
        return ['TagTrees', 'TagTreesUsages', 'Blocks', 'BlockTypes', 'Playgrounds', 'Reports', 'Synoptics', 'SteamMixes', 'Models'];
      },
    }),
    createTag: build.mutation<TagInfo, CreateTagBody>({
      queryFn: async (body, { getState }) => {
        const state = getState() as RootState;
        const siteId = state.auth.selectedSite?.uuid;
        try {
          if (!siteId) throw new Error('No site ID');
          const { data } = await sdk.tag.Create(siteId, body);
          return { data };
        } catch (error) {
          console.error(error);
          return { error };
        }
      },
      invalidatesTags: ['TagTrees', 'TagTreesUsages'],
    }),
  }),
});

export const {
  useReadTagTreesQuery,
  useReadCorporateTagTreesQuery,
  useReadTagTreesUsagesQuery,
  useUpdateTagTreesUsagesMutation,
  useCreateTagMutation,
} = tagsApi;

export const selectTagTreesUsages: typeof tagsApi.endpoints.readTagTreesUsages.select = () => tagsApi.endpoints.readTagTreesUsages.select();

export const useTagTrees: typeof tagsApi.useReadTagTreesQuery = (arg, options) => {
  const siteId = useSelector(state => state.auth.selectedSite?.uuid);

  const data = tagsApi.useReadTagTreesQuery(arg ?? { siteId }, { skip: !siteId, ...options });
  return data;
};

export const useCorporateTagTrees: typeof tagsApi.useReadCorporateTagTreesQuery = (arg, options) => {
  const sites = useSelector(state => state.auth.selectedGroup?.sites);

  const siteIds = useMemo(() => (sites ?? []).map(site => site.uuid), [sites]);

  const data = tagsApi.useReadCorporateTagTreesQuery(arg ?? { siteIds }, { skip: !siteIds, ...options });
  return data;
};

export const useTagTreesUsages: typeof tagsApi.useReadTagTreesUsagesQuery = (arg, options) => {
  const siteId = useSelector(state => state.auth.selectedSite?.uuid);

  const data = tagsApi.useReadTagTreesUsagesQuery(arg ?? { siteId }, { skip: !siteId, ...options });
  return data;
};
