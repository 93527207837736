import { BlockTypeMetricBody, MetricCategory, NewUUID } from '@dametis/core';

import { createCalculationVariable } from 'functions/createCalculationVariable';

export const createBlockTypeMetricBody = ({
  uuid = NewUUID(),
  unit = '',
  name = '',
  calculation = createCalculationVariable(),
  category = MetricCategory.METRIC,
  isVirtual = true,
}: Partial<BlockTypeMetricBody> = {}): BlockTypeMetricBody => ({
  uuid,
  unit,
  name,
  calculation,
  category,
  isVirtual,
});
