import { Accordion, AccordionDetails, AccordionSummary, Link, Typography } from '@mui/material';
import i18next from 'i18next';
import { Component, ErrorInfo, ReactNode } from 'react';

import errorImg from 'assets/images/errors/error-crash.svg';
import { openReplayTracker } from 'openreplay/openreplay';

import ErrorPageTemplate from './ErrorPageTemplate';

export interface ErrorBoundaryProps {
  children: ReactNode;
}

export interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState(oldState => ({ ...oldState, error }));
    openReplayTracker.handleError(error);
    console.error({ error, errorInfo });
  }

  render() {
    const { children } = this.props;
    const { hasError, error } = this.state;
    if (hasError) {
      return (
        <ErrorPageTemplate
          image={errorImg}
          text={
            <>
              {i18next.t('errors:text.crash1')}{' '}
              <Link href="https://dametis.atlassian.net/servicedesk/customer/portals" rel="noopener noreferrer" target="_blank">
                {i18next.t('errors:text.crash2')}
              </Link>
            </>
          }
          title={i18next.t('errors:title.crash')}
        >
          <Accordion sx={{ bgcolor: 'grey.100', boxShadow: 'none' }}>
            <AccordionSummary>
              <Typography variant="overline">{i18next.t('errors:button.learnMore')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <Typography variant="body1">{error?.message}</Typography>
                <Typography variant="body2">{error?.stack}</Typography>
              </div>
            </AccordionDetails>
          </Accordion>
        </ErrorPageTemplate>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
