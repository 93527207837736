import { Alert, Grid, Typography } from '@mui/material';
import { ChangeEvent, ChangeEventHandler, Dispatch, FC, SetStateAction, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useResizeObserver } from 'usehooks-ts';

import { S7DeviceConfig } from '@dametis/core';

import ExampleTable from 'components/Data/FileImport/UI/ExampleTable';
import AdvancedTextFieldWithMask from 'components/UI/AdvancedTextFieldWithMask/AdvancedTextFieldWithMask';
import MaskFormat from 'components/UI/InputMask/MaskFormat';
import AdvancedNumberTextField from 'components/UI/Inputs/AdvancedTextField/AdvancedNumberTextField';

import FormPaper, { FormLocation } from '../../FormPaper';

import { getRackInfos } from './getRackInfos';
import { getSlotInfos } from './getSlotInfos';

export interface S7SettingsFormProps {
  s7: S7DeviceConfig;
  setS7: Dispatch<SetStateAction<S7DeviceConfig>>;
  isEditing?: boolean;
  location?: `${FormLocation}`;
}

const S7SettingsForm: FC<S7SettingsFormProps> = ({ s7, setS7, isEditing = true, location = 'modal' }) => {
  const { t } = useTranslation('devices');

  const slotInfos = useMemo(() => getSlotInfos(t), [t]);
  const rackInfos = useMemo(() => getRackInfos(), []);

  const paperRef = useRef<HTMLDivElement | null>(null);

  const { width: paperWidth = 0 } = useResizeObserver({
    ref: paperRef,
  });

  const handleChangeHost: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (!setS7) {
        return;
      }
      setS7(state => ({ ...state, host: event.target.value }));
    },
    [setS7],
  );

  const handleChangePort = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, newValue: number) => {
      if (!setS7) {
        return;
      }
      setS7(state => ({ ...state, port: newValue }));
    },
    [setS7],
  );

  const handleChangeLoopTime = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, newValue: number) => {
      if (!setS7) {
        return;
      }
      setS7(state => ({ ...state, loopTime: newValue }));
    },
    [setS7],
  );

  const handleChangeRack = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, newValue: number) => {
      if (!setS7) {
        return;
      }
      setS7(state => ({ ...state, rack: newValue }));
    },
    [setS7],
  );

  const handleChangeSlot = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, newValue: number) => {
      if (!setS7) {
        return;
      }
      setS7(state => ({ ...state, slot: newValue }));
    },
    [setS7],
  );

  const handleChangeTimeout = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, newValue: number | null) => {
      if (!setS7) {
        return;
      }
      setS7(state => ({ ...state, timeout: newValue !== null ? newValue : undefined }));
    },
    [setS7],
  );

  const handleChangeLocalTSAP = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, newValue: number | null) => {
      if (!setS7) {
        return;
      }
      setS7(state => ({ ...state, localTSAP: newValue !== null ? newValue : undefined }));
    },
    [setS7],
  );

  const handleChangeRemoteTSAP = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, newValue: number | null) => {
      if (!setS7) {
        return;
      }
      setS7(state => ({ ...state, remoteTSAP: newValue !== null ? newValue : undefined }));
    },
    [setS7],
  );

  return (
    <FormPaper ref={paperRef} location={location} sx={{ p: 1.5 }}>
      <Typography mb={1.5} variant="h6">
        {t('title.acquisition')}
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={paperWidth > 400 ? 6 : 8}>
          <AdvancedTextFieldWithMask
            fullWidth
            required
            editing={isEditing}
            label={t('label.ip')}
            maskedInputProps={MaskFormat.ip}
            value={s7.host ?? ''}
            onChange={handleChangeHost}
          />
        </Grid>
        <Grid item xs={paperWidth > 400 ? 3 : 4}>
          <AdvancedNumberTextField
            fullWidth
            required
            editing={isEditing}
            InputProps={{ inputProps: { min: 0 } }} // SLE: check that
            label={t('label.port')}
            parser={inputValue => parseInt(inputValue, 10)}
            value={s7.port}
            onChange={handleChangePort}
          />
        </Grid>
        <Grid item xs={paperWidth > 400 ? 3 : 12}>
          <AdvancedNumberTextField
            fullWidth
            required
            description={t('description.loopTime')}
            editing={isEditing}
            InputProps={{ inputProps: { step: 100, min: 1000 } }} // SLE: check that // SLE: this step does not count in validator
            label={t('label.loopTime')}
            parser={inputValue => parseInt(inputValue, 10)}
            value={s7.loopTime}
            onChange={handleChangeLoopTime}
          />
        </Grid>
        <Grid item xs={6}>
          <AdvancedNumberTextField
            fullWidth
            required
            description={
              <Alert elevation={8} icon={false} severity="info">
                <Typography align="center" mb={0.5} sx={{ color: theme => theme.palette.primary.dark }} variant="h6">
                  {t('label.indicativeValues')}
                </Typography>
                <ExampleTable header={[]} rows={rackInfos.map(rackInfo => [rackInfo.key, rackInfo.value])} />
              </Alert>
            }
            editing={isEditing}
            label={t('label.rack')}
            labelWithDescriptionProps={{
              tooltipProps: { componentsProps: { tooltip: { sx: { backgroundColor: 'transparent', p: 0 } } } },
            }}
            parser={inputValue => parseInt(inputValue, 10)}
            value={s7.rack}
            onChange={handleChangeRack}
          />
        </Grid>
        <Grid item xs={6}>
          <AdvancedNumberTextField
            fullWidth
            required
            description={
              <Alert elevation={8} icon={false} severity="info">
                <Typography align="center" mb={0.5} sx={{ color: theme => theme.palette.primary.dark }} variant="h6">
                  {t('label.indicativeValues')}
                </Typography>
                <ExampleTable header={[]} rows={slotInfos.map(slotInfo => [slotInfo.key, slotInfo.value])} />
              </Alert>
            }
            editing={isEditing}
            label={t('label.slot')}
            labelWithDescriptionProps={{
              tooltipProps: { componentsProps: { tooltip: { sx: { backgroundColor: 'transparent', p: 0 } } } },
            }}
            parser={inputValue => parseInt(inputValue, 10)}
            value={s7.slot}
            onChange={handleChangeSlot}
          />
        </Grid>
        <Grid item xs={paperWidth > 400 ? 4 : 6}>
          <AdvancedNumberTextField
            fullWidth
            isNullable
            editing={isEditing}
            label={t('label.timeout')}
            parser={inputValue => parseInt(inputValue, 10)}
            placeholder={t('placeholder.defaultValue')}
            value={s7.timeout !== undefined ? s7.timeout : null}
            onChange={handleChangeTimeout}
          />
        </Grid>
        <Grid item xs={paperWidth > 400 ? 4 : 6}>
          <AdvancedNumberTextField
            fullWidth
            isNullable
            editing={isEditing}
            label={t('label.localTSAP')}
            parser={inputValue => parseInt(inputValue, 10)}
            placeholder={t('placeholder.defaultValue')}
            value={s7.localTSAP !== undefined ? s7.localTSAP : null}
            onChange={handleChangeLocalTSAP}
          />
        </Grid>
        <Grid item xs={paperWidth > 400 ? 4 : 6}>
          <AdvancedNumberTextField
            fullWidth
            isNullable
            editing={isEditing}
            label={t('label.remoteTSAP')}
            parser={inputValue => parseInt(inputValue, 10)}
            placeholder={t('placeholder.defaultValue')}
            value={s7.remoteTSAP !== undefined ? s7.remoteTSAP : null}
            onChange={handleChangeRemoteTSAP}
          />
        </Grid>
      </Grid>
    </FormPaper>
  );
};

export default S7SettingsForm;
