import { Box, ClickAwayListener, FormLabel, InputBase, Stack, inputBaseClasses } from '@mui/material';
import { ChangeEventHandler, FC, KeyboardEventHandler, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-key-enum';

import { NestedTag } from '@dametis/core';

import LabelWithDescription from '../Inputs/AdvancedTextField/LabelWithDescription';

import CorporateTagPopover from './CorporateTagPopover';
import TagPickerInput, { TagPickerInputProps } from './TagPickerInput';
import TagPopover, { TagPopoverProps } from './TagPopover';

export interface SearchTagPickerProps {
  value: NestedTag[];
  onChange: (newValue: NestedTag[]) => Promise<void> | void;
  label?: string;
  description?: string;
  variant?: TagPickerInputProps['variant'];
  tagPopoverProps?: Partial<TagPopoverProps>;
  isCorporate?: boolean;
}

const SearchTagPicker: FC<SearchTagPickerProps> = ({
  value: selectedTags,
  onChange,
  label = undefined,
  description = undefined,
  variant = 'outlined',
  tagPopoverProps = {},
  isCorporate = false,
}) => {
  const { t } = useTranslation('tags');

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchFilterValue, setSearchFilterValue] = useState<string>('');

  const anchorEl = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleClickLabel = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  const handleClickAway = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleFocusInput = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(event => {
    if (anchorEl.current && event.key === Key.Escape) {
      event.stopPropagation();
      event.currentTarget.blur();
      setIsOpen(false);
    }
  }, []);

  const handleChangeSearchFilterValue: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    setSearchFilterValue(event.target.value);
  }, []);

  const handleSelectTag = useCallback(
    async (newTag: NestedTag) => {
      const foundedTag = selectedTags.find(selectedTag => selectedTag.uuid === newTag.uuid);
      if (!foundedTag) {
        await onChange([...selectedTags, newTag]);
        setIsOpen(false);
        setSearchFilterValue('');
      } else {
        await onChange(selectedTags.filter(selectedTag => selectedTag.uuid !== newTag.uuid));
        setIsOpen(false);
        setSearchFilterValue('');
      }
    },
    [selectedTags, onChange],
  );

  return (
    <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleClickAway}>
      <Box>
        <Stack gap={0.5}>
          {label && (
            <FormLabel focused={isOpen} onClick={handleClickLabel}>
              {description ? <LabelWithDescription description={description} label={label} /> : label}
            </FormLabel>
          )}
          <TagPickerInput ref={anchorEl} variant={variant}>
            <InputBase
              autoComplete="off"
              inputRef={inputRef}
              placeholder={t('placeholder.searchTag')}
              size="small"
              sx={{
                mx: variant === 'filter' ? 1 : 0,
                [`& .${inputBaseClasses.input}`]: { padding: 0, height: 22 },
              }}
              value={searchFilterValue}
              onChange={handleChangeSearchFilterValue}
              onFocus={handleFocusInput}
              onKeyDown={handleKeyDown}
            />
          </TagPickerInput>
        </Stack>
        {/* <TextField
          ref={anchorEl}
          autoComplete="off"
          placeholder={t('placeholder.searchTag')}
          value={searchFilterValue}
          onChange={handleChangeSearchFilterValue}
          onFocus={handleFocusInput}
          onKeyDown={handleKeyDown}
          {...textFieldProps}
        /> */}
        {isCorporate ? (
          <CorporateTagPopover
            anchorEl={anchorEl.current}
            isOpen={isOpen}
            searchFilterValue={searchFilterValue}
            selectedTags={selectedTags}
            setIsOpen={setIsOpen}
            sx={{ zIndex: theme => theme.zIndex.modal + 1 }}
            onSelectTag={handleSelectTag}
            {...tagPopoverProps}
          />
        ) : (
          <TagPopover
            anchorEl={anchorEl.current}
            isOpen={isOpen}
            searchFilterValue={searchFilterValue}
            selectedTags={selectedTags}
            setIsOpen={setIsOpen}
            sx={{ zIndex: theme => theme.zIndex.modal + 1 }}
            onSelectTag={handleSelectTag}
            {...tagPopoverProps}
          />
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default SearchTagPicker;
