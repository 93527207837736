import { MathFunctionInputName, MathFunctionName, MathFunctionType, MetricCategory, Operator } from '@dametis/core';

import { InfluentVariablesState } from 'components/VNC/components/Filters/InfluentVariablesFilters';

import { UserHelperName } from '../../types/userHelpers';
import { Mode } from '../en/vnc';

const name: Record<MathFunctionName, string> = {
  AC_Debit: 'Flujo',
  AC_PointDeRoseeSousPression: 'PuntoDeRocíoBajoPresión',
  AC_ConsommationSpecifique: 'ConsumoEspecífico',
  EAU_Debit: 'Flujo',
  EAU_MasseVolumique: 'Densidad',
  FROID_TemperatureSaturation: 'TemperaturaDeSaturación',
  FROID_TemperatureHumide: 'TemperaturaHúmeda',
  FROID_PuissanceFluideThermique: 'PotenciaDelFluidoTérmico', // ce calcul devrai être masqué
  THERMIQUE_DeltaT: 'DeltaT',
  THERMIQUE_DebitToutLiquide: 'FlujoTotalDeLíquido',
  THERMIQUE_DebitToutGaz: 'FlujoTotalDeGas',
  VAP_Debit: 'Flujo',
  VAP_DebitSurchauffee: 'FlujoSobrecalentado',
  VAP_Enthalpie: 'Entalpía',
  VAP_RendementCombustion: 'RendimientoDeLaCombustión',
  BORNESUP: 'LímiteSuperior',
  BORNEINF: 'LímiteInferior',
  ONOFF: 'EncendidoApagado',
  BORNES: 'Límites',
  INFO_Bit: 'ExtractBit',
  EXTRACT_Byte: 'ExtractByte',
  MERGE_64_Bit: 'Merge64Bit',
  IF: 'Si',
  getDate: 'ExtraerFecha',
  time: 'time',
  now: 'now',
};

const description: Record<MathFunctionName, string> = {
  AC_Debit: 'Calcula el flujo de aire acondicionado',
  AC_PointDeRoseeSousPression: 'Calcula el punto de rocío bajo presión',
  AC_ConsommationSpecifique: 'Calcula el consumo específico de aire acondicionado',
  EAU_Debit: 'Calcula el flujo de agua',
  EAU_MasseVolumique: 'Calcula la densidad del agua',
  FROID_TemperatureSaturation: 'Calcula la temperatura de saturación de un fluido refrigerante',
  FROID_TemperatureHumide: 'Calcula la temperatura húmeda en función de la temperatura seca y la humedad relativa',
  FROID_PuissanceFluideThermique: 'Calcula la potencia térmica de un fluido refrigerante', // ce calcul devrai être masqué
  THERMIQUE_DeltaT: 'Calcula la diferencia de temperatura entre dos fluidos',
  THERMIQUE_DebitToutLiquide: 'Calcula el flujo de líquido para un diafragma',
  THERMIQUE_DebitToutGaz: 'Calcula el flujo de gas para un diafragma',
  VAP_Debit: 'Calcula el flujo de vapor saturado para un diafragma',
  VAP_DebitSurchauffee: 'Calcula el flujo de vapor sobrecalentado para un diafragma',
  VAP_Enthalpie: 'Calcula la entalpía del vapor saturado',
  VAP_RendementCombustion: 'Cálculo del rendimiento de la combustión a partir de las pérdidas de gases de combustión',
  BORNESUP: 'Retorna el valor limitado al umbral inferior dado',
  BORNEINF: 'Retorna el valor limitado al umbral superior dado',
  ONOFF: 'Retorna 0 si el valor es inferior al umbral, de lo contrario retorna 1',
  BORNES: 'Retorna el valor limitado entre los umbrales inferior y superior dados',
  INFO_Bit: 'Retorna el estado del bit especificado en el valor dado (0 o 1)',
  EXTRACT_Byte: 'Devuelve el byte en la posición especificada en el valor dado',
  MERGE_64_Bit: 'Combina dos enteros de 32 bits en un solo entero de 64 bits',
  IF: 'En función del valor de la condición, retorna el resultado del argumento correspondiente',
  getDate: 'Extrae una parte de una fecha',
  time: 'Retorna la fecha de los puntos en una variable (unix timestamp)',
  now: 'Retorna la fecha actual (unix timestamp)',
};

const inputsName: Record<MathFunctionInputName, string> = {
  temperature: 'temperatura',
  pressure: 'presión',
  power: 'potencia',
  flow: 'flujo',
  delta_pressure: 'presión diferencial',
  threshold_delta_pressure: 'umbral de presión diferencial',
  diameter_in: 'diámetro del orificio',
  diameter_out: 'diámetro de la tubería',
  reynolds: 'número de Reynolds',
  altitude: 'altitud',
  atmospheric_pressure: 'presión atmosférica',
  quantity: 'cantidad',
  fluid: 'fluido',
  intake_air_temperature: 'temperatura del aire de aspiración',
  flue_gas_temperature: 'temperatura de gases de combustión',
  dry_temperature: 'temperatura seca',
  relative_humidity: 'humedad relativa',
  volume_flow_rate: 'flujo volumétrico',
  delta_temperature: 'temperatura diferencial',
  mass_density: 'densidad',
  hot_temperature: 'temperatura caliente',
  cold_temperature: 'temperatura fría',
  viscosity: 'viscosidad cinemática',
  value: 'valor',
  value_true: 'valor si verdadero',
  value_false: 'valor si falso',
  threshold: 'umbral',
  threshold_min: 'umbral alto',
  threshold_max: 'umbral bajo',
  condition: 'condición',
  bit: 'bit',
  byte: 'byte',
  low_32_bit: '32 bits bajos',
  high_32_bit: '32 bits altos',
  heat_capacity: 'capacidad calorífica específica',
  fuel_type: 'tipo de fuel',
  oxygen_levels: 'nivel de oxígeno',
  datetime: 'fecha',
  datetimeKey: 'parte de la fecha',
  variable: 'variable',
};

const inputsDescription: Partial<Record<`${MathFunctionName}_${MathFunctionInputName}`, string>> = {
  AC_Debit_temperature: 'Temperatura del aire',
  AC_Debit_pressure: 'Presión del aire',
  AC_Debit_delta_pressure: 'Medición de la presión diferencial',
  AC_Debit_threshold_delta_pressure: 'Umbral de diferencia de presión',
  AC_Debit_diameter_in: 'Diámetro del orificio del diafragma',
  AC_Debit_diameter_out: 'Diámetro interior del tubo',
  AC_Debit_reynolds: '0 devuelve el caudal, 1 devuelve el número de Reynolds',
  AC_Debit_altitude: 'Altitud en metros, por defecto 0',
  AC_PointDeRoseeSousPression_temperature: 'Punto de rocío',
  AC_PointDeRoseeSousPression_pressure: 'Presión del aire',
  AC_PointDeRoseeSousPression_atmospheric_pressure: 'Presión atmosférica',
  AC_ConsommationSpecifique_pressure: 'Potencia de la central eléctrica o del compresor',
  AC_ConsommationSpecifique_quantity: 'Flujo de aire comprimido',
  EAU_Debit_temperature: 'Temperatura del agua',
  EAU_Debit_delta_pressure: 'Medición de la presión diferencial',
  EAU_Debit_threshold_delta_pressure: 'Umbral de diferencia de presión',
  EAU_Debit_diameter_in: 'Diámetro del orificio del diafragma',
  EAU_Debit_diameter_out: 'Diámetro interior del tubo',
  EAU_Debit_reynolds: '0 devuelve el caudal, 1 devuelve el número de Reynolds',
  EAU_MasseVolumique_temperature: 'Temperatura del agua',
  FROID_TemperatureSaturation_fluid: 'Tipo de fluido refrigerante (0: Amoníaco, 1: R22, 2: R12, 3: R502, 4: R134A)',
  FROID_TemperatureSaturation_pressure: 'Presión de refrigerante',
  FROID_TemperatureHumide_dry_temperature: 'Temperatura seca',
  FROID_TemperatureHumide_relative_humidity: 'Densidad del fluido refrigerante',
  FROID_TemperatureHumide_atmospheric_pressure: 'Umbral de diferencia de presión',
  FROID_PuissanceFluideThermique_fluid: 'Tipo de fluido refrigerante (0: Amoníaco, 1: R22, 2: R12, 3: R502, 4: R134A)',
  FROID_PuissanceFluideThermique_volume_flow_rate: 'Flujo volumétrico',
  FROID_PuissanceFluideThermique_delta_temperature: 'Diferencia de temperatura',
  FROID_PuissanceFluideThermique_mass_density: 'Densidad de masa del refrigerante',
  FROID_PuissanceFluideThermique_heat_capacity: 'Capacidad térmica específica del refrigerante',
  THERMIQUE_DeltaT_hot_temperature: 'Temperatura del fluido caliente',
  THERMIQUE_DeltaT_cold_temperature: 'Temperatura del fluido frío',
  THERMIQUE_DebitToutLiquide_temperature: 'Temperatura del fluido',
  THERMIQUE_DebitToutLiquide_pressure: 'Presión de fluido',
  THERMIQUE_DebitToutLiquide_delta_pressure: 'Medición de la presión diferencial',
  THERMIQUE_DebitToutLiquide_threshold_delta_pressure: 'Umbral de diferencia de presión',
  THERMIQUE_DebitToutLiquide_diameter_in: 'Diámetro del orificio del diafragma',
  THERMIQUE_DebitToutLiquide_diameter_out: 'Diámetro interior del tubo',
  THERMIQUE_DebitToutLiquide_mass_density: 'Densidad del fluido',
  THERMIQUE_DebitToutLiquide_viscosity: 'Viscosidad del fluido',
  THERMIQUE_DebitToutLiquide_reynolds: '0 devuelve el caudal, 1 devuelve el número de Reynolds',
  THERMIQUE_DebitToutGaz_temperature: 'Temperatura del fluido',
  THERMIQUE_DebitToutGaz_pressure: 'Presión del fluido',
  THERMIQUE_DebitToutGaz_delta_pressure: 'Medición de la presión diferencial',
  THERMIQUE_DebitToutGaz_threshold_delta_pressure: 'Umbral de diferencia de presión',
  THERMIQUE_DebitToutGaz_diameter_in: 'Diámetro del orificio del diafragma',
  THERMIQUE_DebitToutGaz_diameter_out: 'Diámetro interior del tubo',
  THERMIQUE_DebitToutGaz_mass_density: 'Densidad del fluido',
  THERMIQUE_DebitToutGaz_viscosity: 'Viscosidad del fluido',
  THERMIQUE_DebitToutGaz_reynolds: '0 devuelve el caudal, 1 devuelve el número de Reynolds',
  VAP_Debit_pressure: 'Presión del vapor',
  VAP_Debit_delta_pressure: 'Medición de la presión diferencial',
  VAP_Debit_threshold_delta_pressure: 'Umbral de diferencia de presión',
  VAP_Debit_diameter_in: 'Diámetro del orificio del diafragma',
  VAP_Debit_diameter_out: 'Diámetro interior del tubo',
  VAP_Debit_reynolds: '0 devuelve el caudal, 1 devuelve el número de Reynolds',
  VAP_DebitSurchauffee_pressure: 'Presión del vapor',
  VAP_DebitSurchauffee_temperature: 'Temperatura del vapor',
  VAP_DebitSurchauffee_delta_pressure: 'Medición de la presión diferencial',
  VAP_DebitSurchauffee_threshold_delta_pressure: 'Umbral de diferencia de presión',
  VAP_DebitSurchauffee_diameter_in: 'Diámetro del orificio del diafragma',
  VAP_DebitSurchauffee_diameter_out: 'Diámetro interior del tubo',
  VAP_DebitSurchauffee_reynolds: '0 devuelve el caudal, 1 devuelve el número de Reynolds',
  VAP_Enthalpie_pressure: 'Presión del vapor',
  VAP_RendementCombustion_fuel_type: 'Tipo de fuel',
  VAP_RendementCombustion_intake_air_temperature: 'Temperatura del aire de aspiración',
  VAP_RendementCombustion_flue_gas_temperature: 'Temperatura de gases de combustión',
  VAP_RendementCombustion_oxygen_levels: 'Nivel de oxígeno',
  BORNESUP_value: 'Valor a limitar',
  BORNESUP_threshold: 'Umbral superior',
  BORNEINF_value: 'Valor a limitar',
  BORNEINF_threshold: 'Umbral inferior',
  ONOFF_value: 'Valor a comparar con el umbral',
  ONOFF_threshold: 'Umbral de comparación',
  BORNES_value: 'Valor a limitar',
  BORNES_threshold_min: 'Umbral inferior',
  BORNES_threshold_max: 'Umbral superior',
  INFO_Bit_value: 'Valor que contiene los bits',
  INFO_Bit_bit: 'Posición del bit a verificar (0 a 15)',
  IF_condition: 'Condición a comprobar',
  IF_value_true: 'Valor a devolver si la condición es verdadera.',
  IF_value_false: 'Valor a devolver si la condición es falsa.',
  getDate_datetime: 'Fecha en milisegundos',
  getDate_datetimeKey:
    "Valores posibles : 'second' / 'minute' / 'hour' / 'day' / 'week' / 'weekDay' / 'daysInMonth' / 'month' / 'dayOfYear' / 'year'",
};

const operatorDescription: Record<`OP_${Operator}_${Mode}`, string> = {
  OP_MIN_GLOBAL: 'Retorna el punto con el valor mínimo. Por lo tanto, un solo valor.',
  OP_MIN_GROUP_BY: 'Retorna, para cada subperíodo, el punto con el valor mínimo. Por lo tanto, un valor para cada subperíodo.',
  OP_MIN_PTP: '--',
  OP_MAX_GLOBAL: 'Retorna el punto con el valor máximo. Por lo tanto, un solo valor.',
  OP_MAX_GROUP_BY: 'Retorna, para cada subperíodo, el punto con el valor máximo. Por lo tanto, un valor para cada subperíodo.',
  OP_MAX_PTP: '--',
  OP_GAP_GLOBAL: 'Retorna el punto con el valor máximo. Por lo tanto, un solo valor.',
  OP_GAP_GROUP_BY: 'Retorna, para cada subperíodo, el punto con el valor máximo. Por lo tanto, un valor para cada subperíodo.',
  OP_GAP_PTP: '',
  OP_FIRST_GLOBAL: 'Retorna el último punto. Por lo tanto, un solo valor.',
  OP_FIRST_GROUP_BY: 'Retorna, para cada subperíodo, el primer punto. Por lo tanto, un valor para cada subperíodo.',
  OP_FIRST_PTP: '--',
  OP_LAST_GLOBAL: 'Retorna el ultimo punto. Por lo tanto, un solo valor.',
  OP_LAST_GROUP_BY: 'Retorna, para cada subperíodo, el último punto. Por lo tanto, un valor para cada subperíodo.',
  OP_LAST_PTP: '--',
  OP_INDEX_GLOBAL: 'Retorna el punto en el índice dado. Por lo tanto, un solo valor.',
  OP_INDEX_GROUP_BY: 'Retorna, para cada subperíodo, el punto en el índice dado. Por lo tanto, un valor para cada subperíodo.',
  OP_INDEX_PTP: '--',
  OP_HISTORY_GLOBAL: 'Retorna todos los puntos.',
  OP_HISTORY_GROUP_BY: '--',
  OP_HISTORY_PTP: '--',
  OP_MEAN_GLOBAL: 'Retorna el promedio del valor de los puntos. Por lo tanto, un solo valor.',
  OP_MEAN_GROUP_BY: 'Retorna, para cada subperíodo, el promedio del valor de los puntos. Por lo tanto, un valor para cada subperíodo.',
  OP_MEAN_PTP: '--',
  OP_ARITHMETIC_MEAN_GLOBAL: 'Retorna el promedio aritmético del valor de los puntos. Por lo tanto, un solo valor.',
  OP_ARITHMETIC_MEAN_GROUP_BY:
    'Retorna, para cada subperíodo, el promedio aritmético del valor de los puntos. Por lo tanto, un valor para cada subperíodo.',
  OP_ARITHMETIC_MEAN_PTP: '--',
  OP_TIME_WEIGHTED_MEAN_GLOBAL: '',
  OP_TIME_WEIGHTED_MEAN_GROUP_BY: '',
  OP_TIME_WEIGHTED_MEAN_PTP: '',
  OP_DELTA_GLOBAL: 'Retorna la diferencia entre el valor del primero y el último. Por lo tanto, un solo valor.',
  OP_DELTA_GROUP_BY: 'Retorna, para cada subperíodo, la integral (base de tiempo 1h). Por lo tanto, un valor para cada subperíodo.',
  OP_DELTA_PTP:
    'Retorna, para cada punto de la variable, la diferencia entre el punto actual y su anterior. Por lo tanto, un valor para cada punto de la variable.',
  OP_COUNT_GLOBAL: 'Retorna el número de puntos presentes. Por lo tanto, un solo valor.',
  OP_COUNT_GROUP_BY: 'Retorna, para cada subperíodo, el número de puntos presentes. Por lo tanto, un valor para cada subperíodo.',
  OP_COUNT_PTP: '',
  OP_INTEGRAL_GLOBAL: 'Retorna la integral (base de tiempo 1h). Por lo tanto, un solo valor.',
  OP_INTEGRAL_GROUP_BY: 'Retorna, para cada subperíodo, la integral (base de tiempo 1h). Por lo tanto, un valor para cada subperíodo.',
  OP_INTEGRAL_PTP:
    'Retorna, para cada punto de la variable, la integral calculada a partir del punto actual y su anterior. Por lo tanto, un valor para cada punto de la variable.',
  OP_TRAPEZOIDAL_INTEGRAL_GLOBAL: '--',
  OP_TRAPEZOIDAL_INTEGRAL_GROUP_BY: '--',
  OP_TRAPEZOIDAL_INTEGRAL_PTP: '--',
  OP_LEFT_RECTANGLE_INTEGRAL_GLOBAL: '--',
  OP_LEFT_RECTANGLE_INTEGRAL_GROUP_BY: '--',
  OP_LEFT_RECTANGLE_INTEGRAL_PTP: '--',
  OP_DERIVATIVE_GLOBAL: 'Retorna la derivada, es decir, el delta dividido por la base de tiempo (1h). Por lo tanto, un solo valor.',
  OP_DERIVATIVE_GROUP_BY:
    'Retorna, para cada subperíodo, la derivada, es decir, el delta dividido por la base de tiempo (1h). Por lo tanto, un valor para cada subperíodo.',
  OP_DERIVATIVE_PTP:
    'Retorna, para cada punto de la variable, la derivada, es decir, el delta dividido por la base de tiempo (1h). Por lo tanto, un valor para cada punto de la variable.',
  OP_STDDEV_GLOBAL: 'Retorna la desviación estándar del valor de los puntos. Por lo tanto, un solo valor.',
  OP_STDDEV_GROUP_BY:
    'Retorna, para cada subperíodo, la desviación estándar del valor de los puntos. Por lo tanto, un valor para cada subperíodo.',
  OP_STDDEV_PTP: '--',
  OP_MOVING_AVERAGE_GLOBAL: '--',
  OP_MOVING_AVERAGE_GROUP_BY:
    'Retorna, para cada subperíodo, el promedio móvil del valor de los puntos durante la duración especificada (promedio móvil del período). Por lo tanto, un valor para cada subperíodo.',
  OP_MOVING_AVERAGE_PTP: '--',
  OP_SUM_GLOBAL: 'Retorna la suma de los valores de los puntos. Por lo tanto, un solo valor.',
  OP_SUM_GROUP_BY: 'Retorna, para cada subperíodo, la suma de los valores de los puntos. Por lo tanto, un valor para cada subperíodo.',
  OP_SUM_PTP: '--',
  OP_DISTINCT_GLOBAL: 'Retorna el conjunto de puntos eliminando los valores duplicados. Por lo tanto, un valor para cada punto.',
  OP_DISTINCT_GROUP_BY: '--',
  OP_DISTINCT_PTP: '--',
  OP_TIME_GLOBAL:
    'Retorna la valor de la fecha de inicio del período (formato Unix timestamp en milisegundos). Por lo tanto, un solo valor.',
  OP_TIME_GROUP_BY:
    'Retorna la valor de la fecha de inicio de cada subperíodo (formato Unix timestamp en milisegundos). Por lo tanto, un valor para cada subperíodo',
  OP_TIME_PTP: 'Retorna, para cada punto de la variable, la valor de la fecha. Por lo tanto, un valor para cada punto de la variable.',
  OP_GROUP_BY_DURATION_GLOBAL: 'Retorna la duración del período (en milisegundos). Por lo tanto, un solo valor.',
  OP_GROUP_BY_DURATION_GROUP_BY: 'Retorna la duración de cada subperíodo (en milisegundos). Por lo tanto, un valor para cada subperíodo.',
  OP_GROUP_BY_DURATION_PTP:
    'Retorna, para cada punto de la variable, la duración entre el punto actual y su anterior (en milisegundos). Por lo tanto, un valor para cada punto de la variable.',
  OP_LINEAR_CADENCE_GLOBAL:
    'Retorna la valor linealizado entre el punto anterior al período y el primero del período. Por lo tanto, un solo valor.',
  OP_LINEAR_CADENCE_GROUP_BY:
    'Lineariza la curva creando un punto interpolado para cada subperíodo. Por lo tanto, un valor para cada subperíodo.',
  OP_LINEAR_CADENCE_PTP: '--',
};

export default {
  alert: {
    ignoreSymbols1: 'Escribe',
    ignoreSymbols2: 'para ignorar los símbolos matemáticos.',
    disableMaths: 'En este contexto sólo puedes elegir una variable.',
    tadaError: 'Se ha producido un error.',
    tadaOk: '¡Ok!',
    tadaWarningSingleValue: 'Esta operación devuelve {{counter}} resultados en este período, en lugar de uno en este contexto.',
    tadaWarningMultipleValues: 'Esta operación solo devuelve un resultado en este período, en lugar de varios en este contexto.',
    forbiddenOperators_one: 'No puedes usar el operador {{operators, list}} en este contexto.',
    forbiddenOperators_other: 'No puedes usar los operadores {{operators, list}} en este contexto.',
  },
  accordion: {
    physicalQuantities: 'Magnitud física',
    utilities: 'Utilidades',
    sites: 'Sitios y corporates',
    kinds: 'Tipos',
    blockTypes: 'Tipos de bloque',
    standardBlocks: 'Bloques estándar',
    tags: 'Tags',
    influentVariables: 'Variables influyentes',
    advanced: 'Parámetros avanzados',
  },
  button: {
    cancel: 'Cancelar',
    ok: 'OK',
    clearAllSelection: 'Vaciar el alias',
    deleteHistoryItem: 'Suprimir del historial',
    function: 'Función',
    closeVariableMenu: 'Cerrar',
    saveHistoryItemAsAlias: 'Crear un alias desde este cálculo',
    clearUnit: 'Borrar la unidad',
    deleteAliasItem: 'Todos los tipos de variables',
    createAlias: 'Crear un alias...',
    updateAliasItem: 'Modificar el alias',
    slate: 'Campo de texto',
    json: 'JSON',
  },
  list: {
    text: {
      variable: 'Variable',
      history: 'Cálculo',
      model: 'Cálculo',
      isCorporate: 'Corporate',
      physicalQuantitiesAll: 'Todas las magnitudes físicas',
      kindsAll: 'Todos los tipos de variables',
      influentVariablesAll: 'Todas las variables',
      byDefault: '(por defecto)',
    },
    subheader: {
      operator: 'Operador',
      sameEntity_one: 'Creado desde $t(shortcut:name.{{category}}_this, { "count": 1 })',
      sameEntity_other: 'Creados desde $t(shortcut:name.{{category}}_this, { "count": 1 })',
      sameCategory_one: 'Creado desde $t(shortcut:name.{{category}}_a, { "count": 1 })',
      sameCategory_other: 'Creados desde $t(shortcut:name.{{category}}_a, { "count": 1 })',
      others_one: 'Otro',
      others_other: 'Otros',
    },
  },
  input: {
    label: {
      data: 'Datos',
      opArgsPeriod: 'Período del promedio móvil',
      aliasName: 'Nombre',
      aliasSlate: 'Cálculo',
      aliasSource: 'Creado desde',
      aliasOwner: 'Por',
      fill: 'Rellenar los valores faltantes con',
      flags: 'Opciones',
      accumulate: 'Acumular los valores',
      timestamp: 'Marca de tiempo de los valores',
    },
    adornment: {
      nickname: 'alias',
    },
    helperText: {
      addNickname: '¿Demasiado largo?',
      removeNickname: 'Quitar apodo',
    },
    placeholder: {
      input: 'Variable o cálculo',
      input_disableMaths: 'Variable',
      searchTag: 'Buscar...',
    },
    value: {
      fillPrevious: 'Valor anterior (por defecto)',
      fillNull: 'Sin valor',
      fillNull2: '¡Atención! Esto puede romper tu cálculo.',
      fillLinear: 'No rellenar',
      fillNone: 'Fin del subperíodo',
      fillZero: 'Ceros',
      timestampUndefined: 'Dependiendo del contexto',
      timestampUndefined2: 'Inicio del subperíodo',
      timestampStart: 'Inicio del subperíodo',
      timestampEnd: 'Fin del subperíodo',
      timestampMiddle: 'Medio del subperíodo',
      timestampKeep: 'Mantener la marca de tiempo del punto',
      timestampEx: 'ej:',
    },
  },
  subtitle: {
    parameters: 'Parámetros',
    metrics: 'Métricas',
    [`metricsWithCategory_${MetricCategory.METRIC}`]: 'Métricas de cálculo',
    [`metricsWithCategory_${MetricCategory.DISCREPANCY}`]: 'Métricas de discrepancias',
    [`metricsWithCategory_${MetricCategory.TECHNICAL}`]: 'Métricas técnicas',
    variables: 'Variables',
    folders: 'Carpetas',
    blocks: 'Bloques',
    noVariables: 'Sin variables correspondientes a esta búsqueda',
    noHistory: 'Historial vacío',
    resultsFor: 'Resultados para « {{search}} »',
    allVariablesByUpdateDate: 'Todas las variables modificadas recientemente',
    allInfluentVariables: 'Variables influyentes de {{name}}',
    multipleSelection: 'Selección múltiple',
    explanatoryVariables: 'Variables explicativas',
    noAliases: 'Puedes crear tu primer alias desde tu cálculo o desde el historial',
    noAliasesForSearch: 'No hay alias para « {{search}} »',
  },
  text: {
    noBlockType: 'Sin tipo de bloque',
    noBlock: 'Sin bloque',
    noFolder: 'Sin carpeta',
    selectedVariables_one: '{{count}} variable seleccionada',
    selectedVariables_other: '{{count}} variables seleccionadas',
    unNamedMetric: '(Anónimo)',
    unknownId: 'Variable desconocida',
    unknownBlock: 'Bloque desconocido',
    noParameter: 'Sin parámetros',
    noMetric: 'Sin métricas',
    noVariable: 'Sin variable',
    noDisplayedUtilities: 'Sin selección',
    noUtility: 'Sin resultados',
    noDisplayedTagFilters: 'Sin seleccionado',
    noTagFilter: 'Sin resultados',
    noExplainedVariable: 'Sin variable explicada',
    noModel: 'Sin modelo',
    modelVariable: 'Variable modelada',
    modelCalculation: 'Ecuación',
    explainedVariable: 'Variable modelada',
    noExplanatoryVariable: 'Sin variable explicativa',
    modelAbsoluteGap: 'Diferencia absoluta',
    modelAbsoluteGapFormula: 'variable modelada - variable real',
    modelRelativeGap: 'Diferencia relativa',
    modelRelativeGapFormula: '(variable modelada - variable real) / variable real',
    begin: 'Inicio: {{date}}',
    end: 'Fin: {{date}}',
    groupBy: 'Subperíodos: {{value}}',
    linkedModels: '{{number}} modelos asociados',
    selectAll: 'Seleccionar todo',
    availableParameter: ', desde',
    opOrGroupBy: 'Operador ? Subperíodos ?',
    unitNa: 'n/d',
    noUnit: 'Sin unidad',
    home: 'Inicio',
    filteredWith: 'Filtrar con:',
    fromSource: ', desde',
    createdBy: ', por',
    createdIn: ' en',
  },
  title: {
    selection: 'selección',
    aliases: 'Alias',
    history: 'Historial',
  },
  tooltip: {
    createAlias: 'crear un alias',
    addToSelection: 'Agregar a la selección',
    removeFromSelection: 'Quitar',
    variableMenu: 'Parámetros de la variable',
    rightClickToOpenVariableMenu1: 'Clic derecho',
    rightClickToOpenVariableMenu2: 'en la variable para abrir sus parámetros',
    calcVarHasHistoryOp: 'Dado que su cálculo tiene un operador Valor, el período se ha reducido a una semana.',
    periodIsFuture: 'Dado que parte del período está en el futuro, se ha reducido a los últimos 7 días.',
    clear: 'Suprimir',
    metricsCalcOn_one: 'Mostrar detalles de la métrica',
    metricsCalcOn_other: 'Mostrar detalles de las métricas',
    metricsCalcOff_one: 'Ocultar detalles de la métrica',
    metricsCalcOff_other: 'Ocultar detalles de las métricas',
    openPopper: 'Expandir este campo',
    closePopper: 'Cerrar',
  },
  tab: {
    history: 'Historial y alias',
    variables: 'Variables',
    lego: 'Bloques',
    models: 'Modelos',
  },
  functions: {
    name,
    description,
    type: {
      number: 'numero',
      string: 'cadena de caracteres',
      boolean: 'booleano',
    } as Record<MathFunctionType, string>,
    inputs: {
      name: inputsName,
      description: inputsDescription,
    },
  },
  operators: {
    title: '{{groupBy}} + {{operator}} =',
    context: 'En este contexto, el operador {{ op }} ',
    operatorDescription,
  },
  helper: {
    [UserHelperName.VNC_VARIABLE_MENU_OPERATOR]: 'Los operadores disponibles dependen del tipo de subperíodos que elijas.',
  },
  influentVariablesState: {
    [InfluentVariablesState.HIDDEN]: 'Variables ocultas',
    [InfluentVariablesState.VISIBLE]: 'Variables relevantes',
  },
  dialog: {
    title: {
      updateAlias: 'Modificar un alias',
      createAlias: 'Crear un alias',
    },
    button: {
      cancel: 'Cancelar',
      updateAlias: 'Modificar',
      createAlias: 'Crear',
    },
  },
  monaco: {
    loading: 'Cargando...',
  },
};
